
import axios from 'axios';


export const api_list_mytasks = data => dispatch => {
    //first
    console.log("api list mytasks",data);
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=mytasks&action=list_tasks`,
        data
      )
     //.then(res => res.data.content)
     // .then(data => dispatch(getObservationsMaster(data)));
  };

export const api_loadEditRecord = data => dispatch => {
    //first
    console.log("api list mytasks",data);
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=mytasks&action=load_edit_record`,
        data
      )
     //.then(res => res.data.content)
     // .then(data => dispatch(getObservationsMaster(data)));
  };
export const api_delete_task = data => dispatch => {
    //first

    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=mytasks&action=delete_task`,
        data
      )
     //.then(res => res.data.content)
     // .then(data => dispatch(getObservationsMaster(data)));
  };

  export const api_add_mytask = data => dispatch => {
    //first
    //console.log(data);
    
    return axios    
    .post(
      `${process.env.REACT_APP_BASE_URL}api=mytasks&action=add_task`,
      data
    )
     //.then(res => res.data.content)
     // .then(data => dispatch(getObservationsMaster(data)));
  };
