import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import {api_visitnotes_list_options} from './../../../../DS/DS.VisitsNotes';
import { connect } from "react-redux";
import Showhelp from './../../../showHelp';

class cardiovascular extends Component {
    state = { 
        list_options: {}
      }
      componentDidMount() {
        this.props.visitnotes_list_options().then(res => {
          //   console.log("addPatientForm addPatientForm",res);
          this.setState({ list_options: res.data.content });
          console.log(this.state.list_options);
        });
      }
    render() {
        return (
            <Popup trigger={<a  title="Cardiovascular" className="collapsed" aria-expanded="false"><span className="cardiovascular"></span></a>} position="right center"   modal
            closeOnDocumentClick={false}>
             {close => (
               <div style={{ width:'800px', margin:'0px auto'}} >
                 <div  style={{ width:'800px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>
                     <div className="modal-header">
                      <button type="button" className="close" style={{ marginTop: '0px',fontSize: '25px' }} onClick={() => close()} data-dismiss="modal" aria-label="Close">×
                      </button>
                      <span>Cardiovascular <Showhelp gotohelp="cardiovascular_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Cardiovascular</h4> */}
                    </div>
                    <div className="modal-body" style={{ border:'0px' }}>
                    <h4 class="ml-3">History:</h4>

                    <div className="col-sm-12">
                  <div className="col-sm-1 col-sm-02">
                            <label>Chest Pain</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.ChestPain === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "ChestPain")}
                    /> Yes</label>
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.ChestPain === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "ChestPain")}
                    /> No</label>

                        </div>
                        <div className="col-sm-2 ">
                            <label>Orthospnoea</label>
                        </div>
                        <div className="col-sm-2">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Orthospnoea === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "Orthospnoea")}
                    /> Yes</label>
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Orthospnoea === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "Orthospnoea")}
                    /> No</label>
                        </div>
                </div>

     <div className="col-sm-12">
                  <div className="col-sm-1 col-sm-02">
                            <label>Dyspnoea</label>
                        </div>
                        <div className="col-sm-2">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Dyspnoea === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "Dyspnoea")}
                    /> Yes</label> 
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Dyspnoea === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "Dyspnoea")}
                    /> No</label>

                        </div>
                        <div className="col-sm-2 ">
                            <label>Swollen ankles</label>
                        </div>
                        <div className="col-sm-2">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.SwollenAnkles === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "SwollenAnkles")}
                    /> Yes</label>
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.SwollenAnkles === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "SwollenAnkles")}
                    /> No</label>

                        </div>
                    
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-1 col-sm-02">
                            <label>Nocturnal Dyspnoea</label>
                        </div>
                        <div className="col-sm-2">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.fever === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "fever")}
                    /> Yes</label> 
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.fever === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "fever")}
                    /> No</label>
                        </div>

                        <div className="col-sm-2 ">
                            <label>Claudication</label>
                        </div>
                        <div className="col-sm-4 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.Claudication == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "Cardiovascular", "Claudication")}
                    /> Right</label> 
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.Claudication == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "Cardiovascular", "Claudication")}
                    /> Left</label>
                      <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both"
                      checked={this.props.vals.Claudication == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "Cardiovascular", "Claudication")}
                    /> Both</label>
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.Claudication == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "Cardiovascular", "Claudication")}
                    /> Neither</label>
                        </div>
                    
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-1 col-sm-02">
                            <label>Palpitations</label>
                        </div>
                        <div className="col-sm-2">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Palpitations === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "Palpitations")}
                    /> Yes</label>
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Palpitations === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "Palpitations")}
                    /> No</label>

                        </div>
                    
                </div>

                <h4 class="ml-3">Examination:</h4>
                <div className="col-sm-12 ">
                            <div className="col-sm-2 pr-0 mt-2">
                            <label>JVP:</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                            <input type="text" className="form-control form-control-sm mt-0" style={{width: '70px', float:'left'}} 
                            value={this.props.vals_examination.JVP}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "JVP")}
                            /> 
                            <label style={{textAlign: 'center', width:'20px', float: 'left'}} className="mt-1">cm</label>
                        </div>

                        <div className="col-sm-2">
                            <label>Ankle oedema:</label>
                        </div>

                        <div className="col-sm-2 pl-0">
                            <select style={{width:"100%"}}  className="mt-0"
                            value={this.props.vals_examination.AnkleOedema}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "AnkleOedema")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.ankle_oedema != null &&
                          this.state.list_options.ankle_oedema.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>

                        </div>

            <div className="col-sm-12 ">
                            <div className="col-sm-2 pr-0 mt-2">
                            <label>Apex beat:</label>
                        </div>
                        <div className="col-sm-2 pr-0 mt-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.ApexBeat}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "ApexBeat")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.apex_beat != null &&
                          this.state.list_options.apex_beat.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2 pr-0">
                            <label>intercostal space</label>
                        </div>

                        <div className="col-sm-4 pl-0">
                            <input type="text" style={{width: '70px', float:'left'}}  className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.IntercostalSpace}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "IntercostalSpace")}
                            /> 
                            <label style={{textAlign: 'center', marginLeft:'10px', float: 'left'}} className="mt-1">cm from the midline</label>
                        </div>
                        </div>

            <div className="col-sm-12 ">
                            <div className="col-sm-2 pr-0 mt-2">
                            <label>Hearts sounds:</label>
                        </div>
                        <div className="col-sm-2 pr-0 mt-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.HeartsSounds}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "HeartsSounds")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.hearts_sounds != null &&
                          this.state.list_options.hearts_sounds.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2 mt-2">
                        <label class="checkbox-inline"><input type="checkbox"  /> No mumers</label>
                        </div>

                    </div>

                    
                    <h4 class="ml-3">Mumers:</h4>
                    <div className="col-sm-12 ">
                           <div className="col-sm-2 pr-0 mt-2">
                            <label>Volume</label>
                        </div>
                        <div className="col-sm-2 pr-0 mt-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.Volume}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Volume")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Volume != null &&
                          this.state.list_options.Volume.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-1 pr-0 mt-2">
                            <label>Description</label>
                        </div>
                        <div className="col-sm-2 pr-0 mt-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.HeartsSounds}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "HeartsSounds")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.hearts_sounds != null &&
                          this.state.list_options.hearts_sounds.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-1 pr-0 mt-2">
                            <label>Radiation</label>
                        </div>
                        <div className="col-sm-2 pr-0 mt-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.Radiation}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Radiation")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Radiation != null &&
                          this.state.list_options.Radiation.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2 " >
                    </div>
                 </div>
                 <div className="col-sm-12 ">
                 <div className="col-sm-2 pr-0" style={{textAlign:'left'}}>
                            <label style={{marginTop: '10px'}}>Carotid bruit</label>
                        </div>
                 <div className="col-sm-4 pr-0">
                 <label className="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.CarotidBruit == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "CarotidBruit")}
                    /> Right</label>
                    <label className="checkbox-inline">
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.CarotidBruit == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "CarotidBruit")}
                    /> Left</label>
                    <label className="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.CarotidBruit == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "CarotidBruit")}
                    /> Neither</label>
                          
                           

                        </div>
                        </div>
            <div className="col-sm-12 ">
                            <div className="col-sm-2 pr-0 mt-2">
                            <label>Peripheral pulses:</label>
                        </div>

                        <div class="col-sm-10 pr-0">

                        <div className="col-sm-12 ">
                        <div className="col-sm-1 pl-0 mt-2">
                            <label>Right</label>
                        </div>
                        <div className="col-sm-2 pl-0 mt-0">
                            <label>left</label>
                        </div>
                        <div className="col-sm-1 ">
                    </div>
                    <div className="col-sm-2 ">
                            <label>Right</label>
                        </div>
                        <div className="col-sm-2 ">
                            <label>left</label>
                        </div>
                 </div>
                 
                        <div className="col-sm-12 ">

                            <div className="col-sm-1 pl-0 mt-2">
                            <label>Brachial</label>
                        </div>

                        <div className="col-sm-2 pl-0 mt-0">
                            <select style={{width:"100%"}} class="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.Brachialright}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Brachialright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option!= null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2 pl-0 mt-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.Brachialleft}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Brachialleft")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option1 != null &&
                          this.state.list_options.Brachial_option1.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-1 pl-0 mt-2">
                            <label>Femoral</label>
                        </div>
                        <div className="col-sm-2 pl-0 mt-0">
                            <select style={{width:"100%"}}  className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.Femoralright}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Femoralright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2 pl-0 mt-0">
                            <select style={{width:"100%"}}  className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.Femoral}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Femoral")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
               </div>

                       <div className="col-sm-12 ">
                            <div className="col-sm-1 pl-0 mt-2">
                            <label>Radial</label>
                        </div>

                        <div className="col-sm-2 pl-0 mt-0">
                            <select style={{width:"100%"}}   className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.Radialright}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Radialright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2 pl-0 mt-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.Radial}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Radial")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-1 pl-0 mt-2">
                            <label>Popliteal</label>
                        </div>
                        <div className="col-sm-2 pl-0 mt-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.Poplitealright}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Poplitealright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2 pl-0 mt-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.Popliteal}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Popliteal")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>

                 </div>

                       <div className="col-sm-12 ">
                        <div className="col-sm-1 pl-0 mt-2">
                            <label>PT</label>
                        </div>
                        <div className="col-sm-2 pl-0 mt-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.PTright}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "PTright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2 pl-0 mt-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.PT}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "PT")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>

                        <div className="col-sm-1 pl-0 mt-2">
                            <label>DP</label>
                        </div>
                        <div className="col-sm-2 pl-0 mt-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.DPright}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "DPright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2 pl-0 mt-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.DP}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "DP")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                   </div>

             </div>
             </div>
             </div>

                 <div className="modal-footer"   >
                      <button type="button" className="btn btn-primary btn-xs" style={{padding:'6px 12px', fontSize:'14px',width:'auto',height:'auto'}} data-dismiss="modal"  onClick={e => 
                    {this.props.handleSaveOptions(e, "cardiovascular");
                    close();}
                    }>Save</button>
                      <button type="button" className="btn btn-primary btn-xs" style={{padding:'6px 12px', fontSize:'14px',width:'auto',height:'auto'}} onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>

                    </div>
      </div>

)}
</Popup>
         );
    }
}

const mapDispatchToProps = dispatch => {
    return {
      visitnotes_list_options: data => dispatch(api_visitnotes_list_options(data)),
      
    };
  };
  
  export default connect(null, mapDispatchToProps)(cardiovascular);