import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  api_investigations_master,
  api_patient_investigations,
  //api_add_patient_observations,
  api_add_patient_investigations
} from "../../../DS/DS.Observations";
import LineGraph from "./investigtaionChart";
import "./investigationsTable.css";

import InvestigationChart from "./investigtaionChart";
import Showhelp from './../../showHelp';

class InvestigationTable extends Component {
  state = {
    drId: 1,
    patientId: this.props.redux_patientId,
    observationAddData: [],
    isTable: true,
    selectedChart: [],
    setDateRange: "showall",
    selectedChartId: 1,
    showEditItem: '',
    showEditItemValue: ''
  };

  constructor(props) {
    super(props);
    this.addObservationData = this.addObservationData.bind(this);
    this.tableGraph = this.tableGraph.bind(this);
    this.loadChart = this.loadChart.bind(this);
  }
  componentDidMount() {
    document.getElementById("ivntable").scrollIntoView({
      behavior: 'smooth'
    });
    this.props.patient_observations({
      type: this.state.setDateRange,
      patient_id: this.state.patientId
    });
    this.props.observation_master();
  }
  componentWillReceiveProps(nextProps) {
    //    console.log('---------recieve props changed',nextProps);

    if (nextProps.redux_patientId !== this.props.redux_patientId) {
      this.setState({ patientId: nextProps.redux_patientId });

      this.props.patient_observations({
        type: this.state.setDateRange,
        patient_id: nextProps.redux_patientId
      });
      console.log("inside receive props", this.state.patientId);
    }
  }

  setDateRange(e, type) {
    e.preventDefault();
    this.setState({ setDateRange: type });
    this.props.patient_observations({
      type: type,
      patient_id: this.state.patientId
    });
    this.loadChartDirecly(this.state.selectedChartId);
  }
  tableGraph(show) {
    if (show === "graph") {
      this.setState({ isTable: false });
      this.loadChartDirecly(this.state.selectedChartId);
    } else {
      this.setState({ isTable: true });
    }
  }

  loadChartDirecly(id) {
    let selectedChart = [];
    //this.setState({selectedChart: []});
    if (this.props.patient_observations_data != undefined) {
      for (let i = 0; i < this.props.patient_observations_data.length; i++) {
        let item = this.props.patient_observations_data[i];
        console.log(item.observation_id, id);
        if (item.observation_id == id) {
          let arr = [item.date, item.value];
          selectedChart.push(arr);
          console.log(item, id);
        }
      }
    }
    this.setState({ selectedChart: selectedChart });
    console.log(this.state);
  }

  loadChart(e, id) {
    e.preventDefault();

    let selectedChart = [];
    //this.setState({selectedChart: []});
    if (this.props.patient_observations_data != undefined) {
      for (let i = 0; i < this.props.patient_observations_data.length; i++) {
        let item = this.props.patient_observations_data[i];

        if (item.observation_id === id) {
          let arr = [item.date, item.value];
          selectedChart.push(arr);
        }
      }
    }
    this.setState({ selectedChart: selectedChart });
    this.setState({ selectedChartId: id });
    console.log(this.state);
  }
  updateObservationAddData(e, day, observationId) {
    let items = this.state.observationAddData;
    let inputValue = e.target.value;
    this.setState({showEditItemValue: e.target.value})
    let obj = {
      investigationId: observationId,
      value: inputValue,
      patientId: this.state.patientId,
      date: day
    };
    let found = 0;
    for (let i = 0; i < items.length; i++) {
      console.log(items[i]);
      console.log(items[i].observationId);
      if (items[i].observationId == observationId) {
        items[i].value = inputValue;
        found = 1;
        break;
      } else {
        found = 0;
      }
    }
    if (found === 0) {
      items.push(obj);
    }

    this.setState({
      observationAddData: items
    });
    console.log("on changing values", this.state);
  }

  addObservationData() {
    let addarr = 
      this.state.observationAddData;
      
    
    // let addarr = {
    //   observation: this.state.observationAddData,
    //   patientId: this.state.patientId
    // };

    this.props.add_patient_observations(addarr).then(res => {
      this.props.patient_observations({
        type: "showall",
        patient_id: this.state.patientId
      });

    });
    this.setState({showEditItem:''});
    this.setState({showEditItemValue:''});

    this.setState({ observationAddData: [] });
    
  }

  toggleEditMode = (value, id) => {
    //alert(id);
    this.setState({showEditItem: id});
    this.setState({showEditItemValue: value});

  }

  renderObservationColumnData(date, observationId, a3, a4) {
    let cellData = "";
    let found = 0;
    let EditingObservation = '';

    for (let i = 0; i < this.props.patient_observations_data.length; i++) {
      let item = this.props.patient_observations_data[i];
      // console.log(
      //   "investigationtables",
      //   date,
      //   observationId,
      //   item.investigation_id,
      //   item.date,
      //   item
      // );
      if (item.date === date && item.investigation_id === observationId) {
        cellData = item.value;
        found = 1;
        console.log(item.date, date, item.name);
        break;
      } else {
        found = 0;
      }
    }
    if (found === 0) {
      cellData = <span>&nbsp;</span>;
    }

    return <td
       onClick={e => 
      this.state.showEditItem != date+'_'+observationId && this.toggleEditMode(EditingObservation.value, date+'_'+observationId)
      }>
      {this.state.showEditItem == date+'_'+observationId && 
      <input 
      
      value={this.state.showEditItemValue} 
      onBlur = {this.addObservationData} 
      onChange={e => this.updateObservationAddData(e, date, observationId) }
      onKeyPress={event => {
        
        if(event.key === "Enter"){
          this.addObservationData();
        } 
          
        
      }}
      />}
      {this.state.showEditItem != date+'_'+observationId && cellData}
     
      
      </td>;
  }

  render() {
    return (
      <div
        id="notesdiv3"
        className="col-md-12"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <div className="panel panel-default">
          <div
          id= "ivntable"
            className="panel-heading"
            style={{
              float: "left",
              width: "100%",
              padding: "4px 0px 3px 10px"
            }}
          >
            <div
              className="col-lg-12 col-xl-12 "
              style={{ padding: '5px 10px' }}
            >
              <h4
                className="panel-title head-title"
                style={{
                  float: "left",
                  width: "100%",
                  display: "inline",
                  marginRight: 10
                }}
              >
                <a
                  onClick={() => this.tableGraph("table")}
                  id="obsactiv"
                  style={{
                    float: "left",
                    fontSize: 16,
                    paddingRight: 20
                  }}
                  onclick="observationsOpennew();"
                  title="Click to see graph"
                  className="collapsed pointer"
                  aria-expanded="false"
                >
                  <span className={this.state.isTable ? "boldd" : ""}>
                    Result Values {" "}
                  </span>
                </a>
                 <a
                  onClick={() => this.tableGraph("graph")}
                  id="graphactiv"
                  style={{ float: "left", fontSize: 16, paddingLeft: 20 }}
                  onclick="observationsClose();"
                  title="Click to see graph"
                  className="collapsed pointer"
                  aria-expanded="false"
                >
                  <span className={!this.state.isTable ? "boldd" : ""}>
                    Graphs
                  </span>
                </a> 
              </h4>
              <div
                style={{
                  width: "auto",
                  height: 30,
                  position: "absolute",
                  right: 15,
                  top: 5
                }}
              >
                <span className="makescreen">
                  <a
                    href="#"
                    title="Make This Default Screen"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    <i
                      className="fa fa-star"
                      aria-hidden="true"
                      style={{ fontSize: 12, marginRight: 5 }}
                    />
                  </a>
                </span>
                <a
                  href="#"
                  title="Email"
                  onclick="recentObs3();"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-envelope"
                    aria-hidden="true"
                    style={{ fontSize: 12, marginRight: 5 }}
                  />
                </a>
                <a
                  href="#"
                  title="Print"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-print"
                    aria-hidden="true"
                    style={{ fontSize: 12, marginRight: 5 }}
                  />
                </a>
                {/*<a href="#" title="Export XLs"><i class="fas fa-file-excel-o" aria-hidden="true" style="font-size:12px; margin-right:5px;"></i></a>*/}
                <a
                  title="Export PDF"
                  onclick="recentVisitmini();"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fa fa-file-pdf-o"
                    aria-hidden="true"
                    style={{ marginTop: "-15px", fontSize: 12 }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="form-group row" style={{ marginTop: 10 }}>
            <div className="col-sm-10 pl-0">
              <div
                className="col-sm-2 pl-0">
                <label className="col-form-label text-left">
                  View Investigations from:
                </label>
              </div>
              <div
                className="col-sm-6"
                style={{ marginTop: '10px' }}
              >
              
              

              <button
                onClick={e => this.setDateRange(e, "oneyear")}
                id="session-timeout-dialog-keepalive"
                style={{
                  marginBottom: 5,
                  borderRadius: "5px",
                  fontSize: '12px',
                  marginRight: '10px'
                }}
                type="button"
                className={
                  this.state.setDateRange === "oneyear"
                    ? " selectedBg check-male"
                    : " btn-primary check-male"
                }
              >
                Last 1 Year
              </button>
            
              <button
                onClick={e => this.setDateRange(e, "twoyear")}
                id="session-timeout-dialog-keepalive"
                style={{
                  marginBottom: 5,
                  borderRadius: "5px",
                  fontSize: '12px',
                  marginRight: '10px'
                }}
                type="button"
                className={
                  this.state.setDateRange === "twoyear"
                    ? " selectedBg check-male"
                    : " btn-primary check-male"
                }
              >
                Last 2 Years
              </button>

              <button
                  onClick={e => this.setDateRange(e, "showall")}
                  id="session-timeout-dialog-keepalive"
                  style={{
                    marginBottom: 5,
                    borderRadius: "5px",
                    fontSize: '12px',
                    marginRight: '10px'
                  }}
                  type="button"
                  className={
                    this.state.setDateRange === "showall"
                      ? " selectedBg check-male"
                      : " btn-primary check-male"
                  }
                >
                  Show All
                </button>


              </div>
            </div>
          </div>
        </div>

        {this.state.isTable && (
          <div
            className="col-lg-12 classic-tabs"
            style={{
              float: "left",
              width: "100%",
              paddingLeft: 15,
              paddingRight: 0,
              marginTop: 0
            }}
          >
            <div
              id="observations_details"
              className="col-lg-2 classic-tabs"
              style={{
                float: "left",
                paddingLeft: 0,
                paddingRight: 0,
                marginTop: 0,
                display: "block",
                width: '100%',
                overflowX:'scroll'
              }}
            >
              <table
                className="  observation  table-bordered"
                style={{ backgroundColor: "#f4f4f4" }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#cccccc" ,color: 'white'}}>
                    <th style={{ padding: 5, minWidth: "200px" }}>All</th>

                    {this.props.patient_observations_dates != undefined &&
                    this.props.patient_observations_dates.length > 0
                      ? this.props.patient_observations_dates.map(date => (
                          <th style={{ padding: 5, minWidth: "100px" }}>
                            {moment(date).format("DD/MM/YYYY")}
                          </th>
                        ))
                      : ""}
                  </tr>
                </thead>
                <tbody>
                  {this.props.observation_master_data != undefined &&
                  this.props.observation_master_data.length > 0
                    ? this.props.observation_master_data.map(observation => (
                        <tr style={{ textAlign: "center" }}>
                          <td style={{ width: "130px", textAlign: "left" }}>
                            {" "}
                            {observation.name}  
                          </td>

                          {this.props.patient_observations_dates != undefined &&
                          this.props.patient_observations_dates.length > 0
                            ? this.props.patient_observations_dates.map(date =>
                                this.renderObservationColumnData(
                                  date,
                                  observation.investigations_id,
                                  this.state.patientId,
                                  this.state.drId
                                )
                              )
                            : ""}
                        </tr>
                      ))
                    : ""}

                   
                </tbody>
              </table>
            </div>
            <div className="col-lg-6"> </div>
          </div>
        )}
        {!this.state.isTable && (
          <div className="row">
            <div
              className="col-lg-2 classic-tabs"
              style={{
                float: "left",
                paddingLeft: 0,
                paddingRight: 0,
                marginTop: 0,
                marginLeft: "25px"
              }}
            >
              <table
                className="calendar    observation  table-bordered"
                style={{ backgroundColor: "#f4f4f4" }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#cccccc",color:'black' }}>
                    <th style={{ padding: 5, width: 200 }}>
                      &nbsp;
                      <a
                        onclick="observationsOpen();"
                        className="collapsed"
                        aria-expanded="false"
                      >
                        All
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.observation_master_data != undefined &&
                  this.props.observation_master_data.length > 0
                    ? this.props.observation_master_data
                        .filter(observation => observation.isBelow === "0")
                        .map(observation => (
                          <tr
                            onClick={e =>
                              this.loadChart(e, observation.observations_id)
                            }
                          >
                            <td
                              className={
                                observation.observations_id ==
                                this.state.selectedChartId
                                  ? "selectedTdBg "
                                  : ""
                              }
                            >
                              <a
                                href="#"
                                title="Click to see graph"
                                className="collapsed"
                                aria-expanded="false"
                              >
                                {" "}
                                {observation.name}
                              </a>
                            </td>
                          </tr>
                        ))
                    : ""}

                  {this.props.observation_master_data != undefined &&
                  this.props.observation_master_data.length > 0
                    ? this.props.observation_master_data
                        .filter(observation => observation.isBelow === "1")
                        .map(observation => (
                          <tr
                            onClick={e =>
                              this.loadChart(e, observation.observations_id)
                            }
                          >
                            <td
                              className={
                                observation.observations_id ==
                                this.state.selectedChartId
                                  ? "selectedTdBg "
                                  : ""
                              }
                            >
                              <a
                                href="#"
                                title="Click to see graph"
                                className="collapsed"
                                aria-expanded="false"
                              >
                                {" "}
                                {observation.name}
                              </a>
                            </td>
                          </tr>
                        ))
                    : ""}
                </tbody>
              </table>
            </div>
            
            <div
              className="col-lg-6"
              style={{ float: "left", marginTop: "4px" }}
            >
              {" "}
              <InvestigationChart
                key={this.state.selectedChart}
                selectedChart={this.state.selectedChart}
              />{" "}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    observation_master: () => dispatch(api_investigations_master()),
    patient_observations: data => dispatch(api_patient_investigations(data)),
    add_patient_observations: data => dispatch(api_add_patient_investigations(data))
  };
};
const mapStateToProps = state => {
  console.log(state);

  let observation_master_data = state.getObservations.investigations_master
    ? state.getObservations.investigations_master
    : [];
  let patient_observations_data =
    state.getObservations.patient_investigations !== undefined
      ? state.getObservations.patient_investigations.patient_investigations
      : [];
  let patient_observations_dates =
    state.getObservations.patient_investigations !== undefined
      ? state.getObservations.patient_investigations.dates
      : [];
  let redux_patientId =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].patient_id
      : "";

  return {
    observation_master_data,
    patient_observations_data,
    patient_observations_dates,
    redux_patientId
  };
}; 

export default connect(mapStateToProps, mapDispatchToProps)(InvestigationTable);
