import React, { Component } from 'react';
import './style.css'
import {api_get_condition,api_add_family,api_get_family,api_delete_relationship} from '../../../DS/DS.FamilyHistory';
import { connect } from 'react-redux';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import FamilyRelationship from './addRelationship';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import { confirmAlert } from 'react-confirm-alert';
import Showhelp from './../../showHelp';

class FamilyHistory extends Component {
    constructor(props)
    {
        super(props)
        this.state={
            disableAll:false,
            has_history:'',
            disableisalivef:true,
            disableisalivem:true,
            is_alive_m:1,
            is_alive_f:1,
            death_condition_m:'',
            all_condition:'',
            death_condition_f:'',
            death_age_f:'',
            death_age_m:'',
            m_breast:'',
            m_colon:'',
            m_dep:'',
            m_diab:'',
            m_heart:'',
            m_hyp:'',
            m_stroke:'',
            f_breast:'',
            f_colon:'',
            f_dep:'',
            f_diab:'',
            f_heart:'',
            f_hyp:'',
            f_stroke:'',
            other_comments:'',
            relationmodal:false,
            selected_id:'',
            selected_length:0,
            key:0,
            showfor:'',
            crud:{
                jsonGridVersion: "v2.0",
                grid: true,
                viewgrid: {
                  isVisible:true,
                  isPagination: true,
                  isFiltering: true,
                  isSelection:true,
                  deleteFunction:this.deleteClick,
                  isDateGroup:false,
                  isYearSeparator:false,
                  dateCol:'',
                  groupDateSelection:false,
                  cellClicked:'',
                  baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                  query: {
                    tb: "patients_family_relationship_tb",
                    cols: [
                      "relationship_id",
                      "relationship",
                      "relationship_condition",
                      "other_info",
                      "name",
                      "relation_name"
                      
                    ],
                   where: [
                      {
                        type: "and",
                        key: "patient_id",
                        operator: "=",
                        value: this.props.redux_patientId
                   },
                   {
                    type: "and",
                    key: "patients_family_relationship_tb.isDeleted",
                    operator: "=",
                    value: 0
                  }],
                  joins:[
                    {
                      joinType: "inner",
                      joinTable: "condition_master_tb",
                      joinCondition: "condition_master_tb.condition_id = patients_family_relationship_tb.relationship_condition"
                    },
                        {
                          joinType: "inner",
                          joinTable: "relationship_tb",
                          joinCondition: "relationship_tb.relation_id = patients_family_relationship_tb.relationship"
                        }],
                    primary_key:"relationship_id",
                  },
                  viewtabletitle: "",
                  columns: [
                    {name: "relation_name", title: "Relationship" },
                    {name: "name", title: "Condition" },
                    {name: "other_info", title: "Comments" }
                  
                  ],
                  rows: [],
                  sortinit: [{ columnName: "relationship_id", direction: "desc" }],
                  pagesize: 10,
                  getAllIds:false,
                  getallId:'',
                  highlightprev:false
                }
              }
        }
    }
    componentWillMount=()=>
    {
        this.props.apiGetCondition().then(
            req=>
            {this.setState({all_condition:req.data.content})}
        )
        const pdata={patient_id:this.props.redux_patientId}
        this.props.apiGetHistory(pdata).then(
            req=>
            {
                if(req.data.status=="Success")
                {
                    this.setState({
                    has_history:req.data.content[0].has_history,
                    is_alive_m:req.data.content[0].is_alive_m,
                    is_alive_f:req.data.content[0].is_alive_f,
                    death_condition_m:req.data.content[0].death_condition_m,
                    death_condition_f:req.data.content[0].death_condition_f,
                    death_age_f:req.data.content[0].death_age_f,
                    death_age_m:req.data.content[0].death_age_m,
                    m_breast:req.data.content[0].m_breast,
                    m_colon:req.data.content[0].m_colon,
                    m_dep:req.data.content[0].m_dep,
                    m_diab:req.data.content[0].m_diab,
                    m_heart:req.data.content[0].m_heart,
                    m_hyp:req.data.content[0].m_hyp,
                    m_stroke:req.data.content[0].m_stroke,
                    f_breast:req.data.content[0].f_breast,
                    f_colon:req.data.content[0].f_colon,
                    f_dep:req.data.content[0].f_dep,
                    f_diab:req.data.content[0].f_diab,
                    f_heart:req.data.content[0].f_heart,
                    f_hyp:req.data.content[0].f_hyp,
                    f_stroke:req.data.content[0].f_stroke,
                    other_comments:req.data.content[0].other_comments
                    })
                }
            }
        )
    }
   checkBoxHandler=(e,actionfor)=>{
        if(actionfor=='unknown')
        {
            if(e.target.checked)
            {
                this.setState({disableAll:true,has_history:1,disableisalivef:true,disableisalivem:true})
                this.setState({
                    is_alive_m:'',
                    is_alive_f:'',
                    death_condition_m:'',
                    death_condition_f:'',
                    death_age_f:'',
                    death_age_m:'',
                    m_breast:'',
                    m_colon:'',
                    m_dep:'',
                    m_diab:'',
                    m_heart:'',
                    m_hyp:'',
                    m_stroke:'',
                    f_breast:'',
                    f_colon:'',
                    f_dep:'',
                    f_diab:'',
                    f_heart:'',
                    f_hyp:'',
                    f_stroke:'',
                })
            }
            else
            {
                this.setState({disableAll:false,has_history:0,disableisalivef:false,disableisalivem:false})
            }

        }
        if(actionfor=='no history')
        {
            if(e.target.checked)
            {
                this.setState({disableAll:true,has_history:2,disableisalivef:true,disableisalivem:true})
                this.setState({
                    is_alive_m:'',
                    is_alive_f:'',
                    death_condition_m:'',
                    death_condition_f:'',
                    death_age_f:'',
                    death_age_m:'',
                    m_breast:'',
                    m_colon:'',
                    m_dep:'',
                    m_diab:'',
                    m_heart:'',
                    m_hyp:'',
                    m_stroke:'',
                    f_breast:'',
                    f_colon:'',
                    f_dep:'',
                    f_diab:'',
                    f_heart:'',
                    f_hyp:'',
                    f_stroke:'',
                })
            }
            else
            {
                this.setState({disableAll:false,has_history:0,disableisalivef:false,disableisalivem:false})
            }
        }
        if(actionfor=='is_alive_m_y')
        {
            if(e.target.checked)
            {
                this.setState({disableisalivem:true,is_alive_m:1})
            }
            else
            {
                this.setState({disableisalivem:false,is_alive_m:0})
            }
        }
        if(actionfor=='is_alive_m_n')
        {
            if(e.target.checked)
            {
                this.setState({disableisalivem:false,is_alive_m:2})
            }
            else
            {
                this.setState({disableisalivem:true,is_alive_m:0})
            }
        }
        if(actionfor=='is_alive_f_y')
        {
            if(e.target.checked)
            {
                this.setState({disableisalivef:true,is_alive_f:1})
            }
            else
            {
                this.setState({disableisalivef:false,is_alive_f:0})
            }
        }
        if(actionfor=='is_alive_f_n')
        {
            if(e.target.checked)
            {
                this.setState({disableisalivef:false,is_alive_f:2})
            }
            else
            {
                this.setState({disableisalivef:true,is_alive_f:0})
            }
        }
        if(actionfor=='m_breast')
        {
            if(e.target.checked)
            {
                this.setState({m_breast:1})
            }
            else
            {
                this.setState({m_breast:0})
            }
        }
        if(actionfor=='m_colon')
        {
            if(e.target.checked)
            {
                this.setState({m_colon:1})
            }
            else
            {
                this.setState({m_colon:0})
            }
        }
        if(actionfor=='m_dep')
        {
            if(e.target.checked)
            {
                this.setState({m_dep:1})
            }
            else
            {
                this.setState({m_dep:0})
            }
        }
        if(actionfor=='m_diab')
        {
            if(e.target.checked)
            {
                this.setState({m_diab:1})
            }
            else
            {
                this.setState({m_diab:0})
            }
        }
        if(actionfor=='m_heart')
        {
            if(e.target.checked)
            {
                this.setState({m_heart:1})
            }
            else
            {
                this.setState({m_heart:0})
            }
        }
        if(actionfor=='m_hyp')
        {
            if(e.target.checked)
            {
                this.setState({m_hyp:1})
            }
            else
            {
                this.setState({m_hyp:0})
            }
        }
        if(actionfor=='m_stroke')
        {
            if(e.target.checked)
            {
                this.setState({m_stroke:1})
            }
            else
            {
                this.setState({m_stroke:0})
            }
        }
        if(actionfor=='f_breast')
        {
            if(e.target.checked)
            {
                this.setState({f_breast:1})
            }
            else
            {
                this.setState({f_breast:0})
            }
        }
        if(actionfor=='f_colon')
        {
            if(e.target.checked)
            {
                this.setState({f_colon:1})
            }
            else
            {
                this.setState({f_colon:0})
            }
        }
        if(actionfor=='f_dep')
        {
            if(e.target.checked)
            {
                this.setState({f_dep:1})
            }
            else
            {
                this.setState({f_dep:0})
            }
        }
        if(actionfor=='f_diab')
        {
            if(e.target.checked)
            {
                this.setState({f_diab:1})
            }
            else
            {
                this.setState({f_diab:0})
            }
        }
        if(actionfor=='f_heart')
        {
            if(e.target.checked)
            {
                this.setState({f_heart:1})
            }
            else
            {
                this.setState({f_heart:0})
            }
        }
        if(actionfor=='f_hyp')
        {
            if(e.target.checked)
            {
                this.setState({f_hyp:1})
            }
            else
            {
                this.setState({f_hyp:0})
            }
        }
        if(actionfor=='f_stroke')
        {
            if(e.target.checked)
            {
                this.setState({f_stroke:1})
            }
            else
            {
                this.setState({f_stroke:0})
            }
        }
        
   }
   saveClickHandler=()=>
   {
       const values={
           is_alive_f:this.state.is_alive_f,
           is_alive_m:this.state.is_alive_m,
           has_history:this.state.has_history,
           death_condition_m:this.state.death_condition_m,
           death_condition_f:this.state.death_condition_f,
           patient_id:this.props.redux_patientId,
           doc_id:this.props.redux_docId,
           death_age_f:this.state.death_age_f,
           death_age_m:this.state.death_age_m,
           m_breast:this.state.m_breast,
           m_colon:this.state.m_colon,
           m_dep:this.state.m_dep,
           m_diab:this.state.m_diab,
           m_heart:this.state.m_heart,
           m_hyp:this.state.m_hyp,
           m_stroke:this.state.m_stroke,
           f_breast:this.state.f_breast,
           f_colon:this.state.f_colon,
           f_dep:this.state.f_dep,
           f_diab:this.state.f_diab,
           f_heart:this.state.f_heart,
           f_hyp:this.state.f_hyp,
           f_stroke:this.state.f_stroke,
           other_comments:this.state.other_comments
       }
       this.props.apiAddFamilyHistory({values}).then(req=>
        {
            NotificationManager.success("Family history saved")
        })
   }
   checkSelected=(actionfor)=>
   {
       if(actionfor=='Add')
       {
           if(this.state.selected_length>0)
           {
            alert("Please deselect the record to proceed")
           }
           else
           {
                this.setState({relationmodal:true,showfor:actionfor})
           }
       }
       if(actionfor=='Edit')
       {
           if(this.state.selected_length==1)
           {
           
            this.setState({relationmodal:true,showfor:actionfor})
           }
           else
           {
            alert("Please select single record to proceed")
           }
       }
   }
   deleteClick = (GselectedInId,inAction,GselectedInlength) =>
   {
       if(inAction=='selection')
       {
         this.state.selected_id=GselectedInId;
         this.state.selected_length=GselectedInlength;
 
       }
       else if(inAction=="delete")
       {
               if (this.state.selected_length> 0) {
                 confirmAlert({
                   title: '',
                   message: 'You are about to delete relatioship record. Do you want to proceed ? ',
                   buttons: [
                   {
                       label: 'Yes',
                       onClick: () => {
                         this.props.apiDelete({id:this.state.selected_id}).then(
                           req=>this.reloadPage(),
                       NotificationManager.success(
                       'Relationship record deleted successfully',
                       )
                     );
                   },
               },
               {
                 label: 'No',
                 onClick: () => { this.reloadPage()},
               },
             ],
           });
         } else {
           NotificationManager.error('Please select relationship record(s) to be removed from the list');
         }
         
       }
   }
   reloadPage=()=>
   {
       this.setState({key:Math.random(),relationmodal:false,showfor:''})
   }
    render() {
        return (
            
            <div className="col-sm-12 pl-0 pr-0">
<h5 class="col-sm-12 sheadtitle">Family History</h5>

 {/*}   <div className="col-sm-12 pl-0 pr-0">
                <h5 class="sheadtitle">Family History</h5>
                    
        </div>*/}
<div className="col-sm-12">
  <div className="col-sm-6 pl-0 pr-0">
    <div className="row  mt-3">
      <div className="col-sm-2 cus-wid">
        <label> General </label>
      </div>
      <div className="col-sm-10 pr-0">

        <label onChange={(e)=>this.checkBoxHandler(e,'unknown')} className="checkbox-inline"> 
            <input type="checkbox" name="has_family"  checked={this.state.has_history==1 ? 'checked' : ''} /> Unknown (e.g Adopted) 
        </label>

        <label onChange={(e)=>this.checkBoxHandler(e,'no history')} className="checkbox-inline"> 
            <input type="checkbox"  name="has_family" checked={this.state.has_history==2 ? 'checked' : ''}/> No Significant History 
        </label>
      </div>
    </div>
    <div className="row mt-2">
      <div className="col-sm-2 cus-wid mt-3">
        <label> Mother Alive? </label>
      </div>
      <div className="col-sm-2 mt-3">
        <label  className="checkbox-inline"><input type="checkbox" disabled={this.state.disableAll}
                            name="is_alive_m"  onChange={(e)=>this.checkBoxHandler(e,'is_alive_m_y')}
                            value={this.state.is_alive_m} checked={this.state.is_alive_m==1 ? 'checked' :''}/> Yes </label>
        <label  className="checkbox-inline"> <input type="checkbox" disabled={this.state.disableAll}
                            name="is_alive_m"  onChange={(e)=>this.checkBoxHandler(e,'is_alive_m_n')}
                            value={this.state.is_alive_m} checked={this.state.is_alive_m==2 ? 'checked' :''}/> No </label>
      </div>
      <div className="col-sm-4"><input type="text" class="form-control" disabled={this.state.disableisalivem}
                            value={this.state.death_age_m} onChange={(e)=>this.setState({death_age_m:e.target.value})}/></div>
      <div className="col-sm-4"><select style={{width:"100%",height:""}} disabled={this.state.disableisalivem}
                                 value={this.state.death_condition_m} onChange={(e)=>this.setState({death_condition_m:e.target.value})}>
                                    <option value='0'></option>
                                    {this.state.all_condition != undefined && this.state.all_condition.length> 0 && this.state.all_condition.map((condi) => <option key={condi.condition_id} value={condi.condition_id}>{condi.name}</option>)}
                                </select>
      </div>
    </div>
    <div className="row mt-1">
      <div className="col-sm-2 cus-wid mt-3">
        <label> Father Alive? </label>
      </div>
      <div className="col-sm-2 mt-3">
        <label className="checkbox-inline">
        <input type="checkbox" disabled={this.state.disableAll}
                                name="is_alive_m"  onChange={(e)=>this.checkBoxHandler(e,'is_alive_f_y')}
                                value={this.state.is_alive_f} checked={this.state.is_alive_f==1 ? 'checked' :''}/> Yes 
        </label>
        
        <label className="checkbox-inline">
        <input type="checkbox" disabled={this.state.disableAll}
                                name="is_alive_m"  onChange={(e)=>this.checkBoxHandler(e,'is_alive_f_n')}
                                value={this.state.is_alive_f} checked={this.state.is_alive_f==2 ? 'checked' :''}/> No 
        </label>
      </div>
      <div className="col-sm-4"><input type="text" class="form-control" disabled={this.state.disableisalivef}
                            value={this.state.death_age_f} onChange={(e)=>this.setState({death_age_f:e.target.value})} /></div>
      <div className="col-sm-4"><select class="form-control" disabled={this.state.disableisalivef}
                            value={this.state.death_condition_f} onChange={(e)=>this.setState({death_condition_f:e.target.value})}>
                            <option value='0'></option>
                                    {this.state.all_condition != undefined && this.state.all_condition.length> 0 && this.state.all_condition.map((condi) => <option key={condi.condition_id} value={condi.condition_id}>{condi.name}</option>)}
                              
                            </select>
      </div>
    </div>
    <div className="col-sm-12 pl-0 mb-3">
      <label style={{fontWeight: 'bold', fontSize: 16}}>Significant Family History:</label>
    </div>
    <div className="col-sm-6">
      <div className="row">
        <div className="col-sm-12">
          <label> <b>Mother: </b></label>
        </div>
        <div className="col-sm-10 pr-0">
        <label className="checkbox-inline" onChange={(e)=>this.checkBoxHandler(e,'m_diab')}>
                                    <input type="checkbox" disabled={this.state.disableAll}
                                    value={this.state.m_diab} onChange={(e)=>this.setState({m_diab:e.target.value})}
                                    checked={this.state.m_diab==1 ? 'checked' :''}/> Diabetes 
                                </label>
        <label className="checkbox-inline"  onChange={(e)=>this.checkBoxHandler(e,'m_hyp')}>
                                    <input type="checkbox" disabled={this.state.disableAll}
                                    value={this.state.m_hyp} onChange={(e)=>this.setState({m_hyp:e.target.value})}
                                    checked={this.state.m_hyp==1 ? 'checked' :''}/> Hypertension 
                                </label>
        <label className="checkbox-inline" onChange={(e)=>this.checkBoxHandler(e,'m_heart')}>
                                    <input type="checkbox" disabled={this.state.disableAll}
                                    value={this.state.m_heart} onChange={(e)=>this.setState({m_heart:e.target.value})}
                                    checked={this.state.m_heart==1 ? 'checked' :''}/> Heart Disease 
                                </label>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-10 pr-0">
        <label className="checkbox-inline" onChange={(e)=>this.checkBoxHandler(e,'m_stroke')}>
                                    <input type="checkbox" disabled={this.state.disableAll} 
                                    value={this.state.m_stroke} onChange={(e)=>this.setState({m_stroke:e.target.value})}
                                    checked={this.state.m_stroke==1 ? 'checked' :''}/> Stroke 
                                </label>
            <label className="checkbox-inline" onChange={(e)=>this.checkBoxHandler(e,'m_colon')}>
                                    <input type="checkbox" disabled={this.state.disableAll} 
                                    value={this.state.m_colon} onChange={(e)=>this.setState({m_colon:e.target.value})}
                                    checked={this.state.m_colon==1 ? 'checked' :''}/> Colon Cancer 
                                </label>
            <label className="checkbox-inline" onChange={(e)=>this.checkBoxHandler(e,'m_dep')}>
                                    <input type="checkbox"  disabled={this.state.disableAll}
                                     value={this.state.m_dep} onChange={(e)=>this.setState({m_dep:e.target.value})} 
                                     checked={this.state.m_dep==1 ? 'checked' :''}/> Depression 
                                </label>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-10 pr-0">
        <label className="checkbox-inline" onChange={(e)=>this.checkBoxHandler(e,'m_breast')}>
                                    <input type="checkbox" disabled={this.state.disableAll}
                                    value={this.state.m_breast} onChange={(e)=>this.setState({m_breast:e.target.value})}
                                    checked={this.state.m_breast==1 ? 'checked' :''}/> Breast Cancer 
                                </label>
        </div>
      </div>
    </div>
    <div className="col-sm-6">		
      <div className="row mt-2">
        <div className="col-sm-12">
          <label><b> Father: </b></label>
        </div>
        <div className="col-sm-10 pr-0">
        <label className="checkbox-inline" onChange={(e)=>this.checkBoxHandler(e,'f_diab')}>
                                    <input type="checkbox" disabled={this.state.disableAll}
                                    value={this.state.f_diab} onChange={(e)=>this.setState({f_diab:e.target.value})}
                                    checked={this.state.f_diab==1 ? 'checked' :''}/> Diabetes 
                                </label>
        <label className="checkbox-inline" onChange={(e)=>this.checkBoxHandler(e,'f_hyp')}>
                                    <input type="checkbox" disabled={this.state.disableAll}
                                    value={this.state.f_hyp} onChange={(e)=>this.setState({f_hyp:e.target.value})}
                                    checked={this.state.f_hyp==1 ? 'checked' :''}/> Hypertension 
                                </label>
        <label className="checkbox-inline" onChange={(e)=>this.checkBoxHandler(e,'f_heart')}>
                                    <input type="checkbox" disabled={this.state.disableAll}
                                    value={this.state.f_heart} onChange={(e)=>this.setState({f_heart:e.target.value})}
                                    checked={this.state.f_heart==1 ? 'checked' :''}/> Heart Disease 
                                </label>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-10 pr-0">
        <label className="checkbox-inline" onChange={(e)=>this.checkBoxHandler(e,'f_stroke')}>
                                    <input type="checkbox" disabled={this.state.disableAll}
                                    value={this.state.f_stroke} onChange={(e)=>this.setState({f_stroke:e.target.value})}
                                    checked={this.state.f_stroke==1 ? 'checked' :''}/> Stroke
                                </label>
        <label className="checkbox-inline" onChange={(e)=>this.checkBoxHandler(e,'f_colon')}>
                                    <input type="checkbox" disabled={this.state.disableAll}
                                    value={this.state.f_colon} onChange={(e)=>this.setState({f_colon:e.target.value})}
                                    checked={this.state.f_colon==1 ? 'checked' :''}/> Colon Cancer 
                                </label>
        <label className="checkbox-inline" onChange={(e)=>this.checkBoxHandler(e,'f_dep')}>
                                    <input type="checkbox" disabled={this.state.disableAll}
                                    value={this.state.f_dep} onChange={(e)=>this.setState({f_dep:e.target.value})}
                                    checked={this.state.f_dep==1 ? 'checked' :''}/> Depression 
                                </label>
        </div>
      </div>
    </div>
  </div>
  <div className="col-sm-6 pl-0 pr-0">
    <div className="row mt-1">
      <div className="col-sm-12">
        <div className="col-sm-12 pl-0" style={{marginBottom: 5, marginTop: 5}}>
          <button id="session-timeout-dialog-logout" type="button" data-toggle="modal" data-target="#significant-add" onClick={()=>this.checkSelected('Add')} className="btn btn-primary btn-xs">Add</button>
          <button id="session-timeout-dialog-logout" type="button" data-toggle="modal" data-target="#significant-edit" onClick={()=>this.checkSelected('Edit')} className="btn btn-primary btn-xs">Edit</button>
          <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={()=>this.deleteClick('','delete','')}>Delete</button>
        </div>
        <div className="currentrx">
          <div style={{width: '100%', height: 200}}>
            {/*<table style={{margin:0}}>
                            <tr>
                                <th width="150px">Relationship</th>
                                <th width="200px">Condition</th>
                                <th width="500px">Comment</th>
                            </tr>
                            <tr>
                                <td style={{padding:'10px'}}>abc</td>
                                <td style={{padding:'10px'}}>xyz</td>
                                <td style={{padding:'10px'}}>123</td>
                            </tr>
                            </table>*/}
                            <CommonGrid
                            crud={this.state.crud}
                            key={this.state.key}
                          /> 
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
                        <div className="col-sm-12">
                            <label style={{visibility:'hidden'}}>for partioning</label>
                        </div>
                    </div>
  <div className="col-sm-12"><grammarly-extension data-grammarly-shadow-root="true" className="cGcvT" style={{position: 'absolute', top: 0, left: 0, pointerEvents: 'none'}} />
    <div className="col-sm-12 pl-0" style={{marginBottom: 5}}>
      <label> Other Comments: </label>
    </div>
    <textarea rows="4" cols="" style={{padding:'10px',border:'1px solid rgb(221, 221, 221)',width:'100%'}}
                            value={this.state.other_comments} onChange={(e)=>this.setState({other_comments:e.target.value})}></textarea>
  </div>
  <div className="row">
    <div className="col-sm-12" style={{marginBottom: 5, marginTop: 5, textAlign: 'right'}}>
      <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={()=> this.saveClickHandler()}>Save</button>
      <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs">Cancel</button>
    </div>
  </div>
  {(this.state.relationmodal) ?
                <FamilyRelationship showmodal={this.state.relationmodal} showfor={this.state.showfor} sid={this.state.selected_id} changeKey={this.reloadPage}/> : ''}
</div>

            </div>

         );
    }
}
const mapDispatchToProps = dispatch => {
    return {
            apiGetCondition: () => dispatch(api_get_condition()),
            apiAddFamilyHistory:(data) => dispatch(api_add_family(data)),
            apiGetHistory:data=>dispatch(api_get_family(data)),
            apiDelete:data=>dispatch(api_delete_relationship(data))
            };
  };
  const mapStateToProps = state => {
     let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
 
   return {
     redux_patientId,redux_docId
   };
 };

export default connect(
mapStateToProps,
mapDispatchToProps
) (FamilyHistory);
