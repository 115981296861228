import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
//import './addPatientHistory.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import {wflow} from './../../../DS/general';
import axios from 'axios';
import
{
  api_getClinical,
  api_updateClinical
} from '../../../DS/DS.Clinical';
 import Viewer from 'react-viewer';
 import cicon from "../../../assets/img/cicon.png";
class AddClinicalRecord extends Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      userId:1 ,//props.patientId,
      confidential: 0,
      startDate: '',
      dateShow: false,
      selectedFile: null,
      open:false,
      calendardate:'',
      description:'',
      details:'',
      recordId:props.sid,
      selectedClinical:[],
        isChecked: false,
    };
  }
 componentWillMount()
 {
    this.setState({open:this.props.showform})
    this.props.getClinicalRecord({ params: {recordid: this.state.recordId}}).then(clinicalrec => {
   //   alert(new Date(clinicalrec.data.content[0].image_date))
      this.setState(
           { selectedClinical: clinicalrec.data.content,startDate:new Date(clinicalrec.data.content[0].image_date)}

           );
           this.handleDate(clinicalrec.data.content[0].image_date)
      });
 }

  handleChange(event) {
    console.log(event.target.value)
    //this.setState({description: event.target.value});
  }
  onChangeHandler=event=>{
      this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })
}
onClickHandler=()=>
{
    // const data = new FormData() 
     //data.append('image',this.state.selectedFile)
    //console.log(this.state.selectedFile.name)
  //  alert(this.state.startDate.getDate());
   // this.state.startDate.setDate(this.state.startDate.getDate()+1)
   if(this.state.isChecked==true)
     {
      this.state.isChecked=1
     }
     else
     {
      this.state.isChecked=0  
     }
    const clinicalData={
        description:this.state.description,
        record_date:this.state.startDate,
        details:this.state.details,
        confidential:this.state.isChecked,
        recordid:this.state.recordId,

  }
        this.props.apiUpdateClinical(clinicalData).then(
            res=> this.props.changeKey() ,
             NotificationManager.success(
                    'Selected clinical record modified',

                 /* this.state = {
                    confidential: 0,
                    startDate: '',
                    dateShow: false,
                    selectedFile: null,  
                    open:false,
                    calendardate:'',
                    description:'',
                    details:'',
                    selectedClinical:[],
                    dateShow: true,
                    startDate:'',
                    isChecked: false,
                    },*/
                   // this.props.changeKey()

                  //  this.props.changeKey()
                    ) 
        )
  
}
handleDate = date => {
        console.log(date);
        this.setState({
          startDate:new Date(date)//.format('dd/MM/yyyy')
        });
    //    alert(this.state.startDate)
        if(moment(new Date(date)).format('dd/MM/yyyy') == moment(new Date()).format('dd/MM/yyyy')){

          this.setState({
            dateShow: true,
          });
        }else {
          this.setState({
            dateShow: false,
          });
        }
      };
 
      toggleChange = () => {
        this.setState({
          isChecked: !this.state.isChecked,
        });
      }
      focusTextInput() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        // this.textInput.current.focus();
        document.getElementById("editclinical").focus();
        //document
        // this.textInput.focus();
      }
  render() {
    return (
      <Popup
       open={this.state.open}
       modal
       closeOnDocumentClick={false}
        // trigger={<button id="session-timeout-dialog-logout" data-toggle="modal" data-target="#autofill" type="button" class="btn btn-default" style={{float:'left', margin: '-4px  5px 0px 0px', width:'auto'}}>Edit</button>} position="right center"   modal
      >
        {close => (
          <div style={{ width:'650px', marginLeft:'75px', backgroundColor:'#FFF', float:'left'}}>
            <div className="modal-header " >
                 <button type="button" className="close"  onClick={() => {this.props.changeKey()}} data-dismiss="modal" aria-label="Close" style={{position: 'inherit', right: '10px', fontSize: '25px'}}>
                  <span aria-hidden="true">×</span>
                </button>
                <span>Clinical Images </span>
             
              </div>
              <div className="modal-body newpostpopup" style={{border:'0px'}}>
              {/*} <div class="row" style={{marginTop: '30px'}}>
                <div class="col-md-3">
                  Image
                </div>
                <div class="col-md-9">
                  <input type="file" name="file" onChange={this.onChangeHandler}/>
                </div>
               
               </div>*/}
                <div className="row"style={{padding:'20px'}}>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-2 cus-wid"><label>Date</label></div>
                            <div className="col-md-4">
                                 <DatePicker
                                        className="datePicker"
                                     //   value= {this.state.StartDate}
                                        selected={this.state.startDate}
                                        onChange={this.handleDate}
                                        dateFormat="dd/MM/yyyy"
                                        id="editclinical"
                                    />    
                                     <label
                                      className="label-input100"
                                      style={{ marginRight: "0px", marginBottom: "0px", fontSize: "12px", paddingBottom: "0px", paddingTop: "0px", position: "absolute", marginLeft: "-30px" }}
                                    >
                                    <a onClick={this.focusTextInput} href="#">
                                      {<img src={cicon} />}
                                    </a>
                                </label>    
                                { /*this.state.selectedClinical !=undefined && this.state.selectedClinical.map((item,i) => (
                                    <DatePicker
                                        className="datePicker"
                                        value= {() => this.setState({ startDate: item.image_date })}
                                        selected={
                                        this.state.dateShow 
                                        ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                        : Date.parse(this.state.startDate)
                                        }
                                        onChange={this.handleDate}
                                    />
                                      ))*/}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-2 mt-3 pr-0 cus-wid">
                            <label>Description</label>
                            </div>
                            <div class="col-md-9">
                                    { this.state.selectedClinical !=undefined && this.state.selectedClinical.map((item,i) => (
                                        <input type="text" class="form-control" style={{width:"40%"}} onChange={(e) => this.setState({ description: e.target.value })} defaultValue= {item.description}/>
                                     ))}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-2 cus-wid"><label>Details</label></div>
                            <div class="col-md-9">
                                { this.state.selectedClinical !=undefined && this.state.selectedClinical.map((item,i) => (
                                    <textarea
                                        style={{
                                        float: 'left',
                                        width: '100%',
                                        height: 100,
                                        border: '1px solid #cccccc',
                                        }}
                                        onChange={e =>
                                            this.setState({ details: e.target.value })
                                        }
                                        defaultValue={item.details}
                                    />
                                ))}               
                            </div>
                        </div>
                        <div class="row">
                        <div className="col-sm-2 cus-wid"> </div>
                            <div class="col-sm-9 pl-0">
                                                {/*<label><input type="checkbox" value="" style={{margin: '8px 0px 3px 0px', float:'left'}}
                                value={this.state.confidential}/>
                                <span style={{marginTop:'5px', marginRight:'10px', float:'left'}}>Confidential</span></label>*/}
                                <label className="col-sm-4 col-form-label form-check-label">
                                    { this.state.selectedClinical !=undefined && this.state.selectedClinical.map((item,i) => (
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="confidential"
                                            checked={this.state.isChecked} onChange={this.toggleChange}
                                         />))} &nbsp;Confidential
                                </label>
                             </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                    {/*{ this.state.selectedClinical !=undefined && this.state.selectedClinical.map((item,i) => (
                          <Viewer
                            visible="true"
                            images={[{src: `${process.env.REACT_APP_IMAGE_BASE_URL}controller/images/`+item.image_name, alt: 'test img'}]}
                          />
                    ))}*/}
                    </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary btn-xs"
                 onClick={()=>{this.onClickHandler() ;close(); }}
              >
                Save
              </button>
              
              <button
                type="button"
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
                onClick={() => {close();this.props.changeKey();}}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    getClinicalRecord: recordid =>
      dispatch(api_getClinical(recordid)),
       apiUpdateClinical: data => dispatch(api_updateClinical(data))
   };
};

const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';

  return {
    redux_patientId
  };
};
export default connect(
    mapStateToProps,
  mapDispatchToProps
)(AddClinicalRecord);

//export default AddClinicalRecord;
