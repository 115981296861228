import React, { Component } from 'react';
import { connect } from 'react-redux';
import './medication.css';
import axios from 'axios';
import Popup from "reactjs-popup";
import {
    api_getMeds,
    api_getOnGoing,
    api_getOneOff,
    api_getAllMeds,
    api_addMedication,
    api_deleteMedication,
    api_listpast,
    api_get_drugs,
    api_dosage_data,
    api_get_edit_data
   } from '../../../DS/DS.Medications';
import Allergies from "../allergies/allergies";
import ListAllergy from "../allergies/listAllergy";
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import Viewallergy from '../allergies/viewallergy';
import {api_get_drug_detail} from '../../../DS/DS.Medications';
import Showhelp from './../../showHelp';

class MedicationPop extends Component {

  constructor(props){
    super(props)
    this.delMed=this.delMed.bind(this);
   // alert("IN")
    this.state = {
      //ongoing treatment
      selectedFood:'', 
      open:this.props.showForm,
    //longterm treatment
      patientId: this.props.redux_patientId,
      doctorId: "",
      showHideSidenav:'show',
      filteredData: [],
      meds1: [],
      meds2: [],
      allMed: [],
      selectedDrug: 0,
      selectedDrugName:'',
      selectedDose: '',
      selectedQuantity: '',
      selectedRepeat:'',
      selectedType:'',
      reasonVisit:'',
      selectedReason: '',
      pattern:0,
      selected:'',
      checkedBox: [],
      cur_date: '',
      past:[],
      key:0,
      searchedDrugs:[],
      dosage_data:[],
      GselectedInId:'',
      GselectedInlength:'',
      AselectedInId:'',
      AselectedInlength:'',
      isaction:'',
      drugs:[],
      query: '',
      selectedProduct:'',
      favorite_id:"",
      dGroup:"Product Name",
      allergies:[],
      al_query:"",
      reactions:[],
      re_query:"",
      allergy:"",
      reaction:"",
      severity:"Low",
      comments:"",
      drugdetails:[],
      rpttext:'',
      qtytext:'',
      reg24:false,
      pbprivate:0,
      allowbrand:true,
      generatenote:''
      ,
      all_drugdetails:[],

      
    };   

    this.handleSubmit = this.handleSubmit.bind(this);
    //this.handleChange = this.handleChange.bind(this);
  }

  handleInputChange = () => {    
    this.setState({
      al_query: this.search.value
    })
    document.getElementById('allergies_list').style.display = 'block';  
  }
  handleInputChange1 = () => {    
    this.setState({
      re_query: this.search1.value
    })
    document.getElementById('reactions_list').style.display = 'block';  
  }
  handleMenuItemClick = (event) => {
    //alert(event.target.textContent);
    //alert(event.target.getAttribute('id'));
    document.getElementById('searchAllergy').value = event.target.textContent;
    document.getElementById('allergies_list').style.display = 'none';    
   // document.getElementById('searchAllergy').textContent = event.target.textContent; 
   this.setState({
    allergy: event.target.getAttribute('id')
  }) 
  };
  handleMenuItemClick1 = (event) => {
    document.getElementById('searchReaction').value = event.target.textContent;
    document.getElementById('reactions_list').style.display = 'none';   
    this.setState({
      reaction: event.target.textContent,
      
    })  
   // document.getElementById('searchAllergy').textContent = event.target.textContent;  
  };
  
  open() {
    this.setState({showModal: true});
  }

  close() {
    this.setState({showModal: false});
  }

  // get_drugs(){
  //   fetch("http://dev46.fiveminutes.in/supadoc/api/project/?api=womenHistory&action=get_drugs")
  //       .then(res => res.json())
  //       .then(json => {
  //         this.setState({            
  //           drugs:json,            
  //         })
  //       });
  // }

  addFav(){
    const data = new FormData();
    data.set('drug_id', this.state.selectedDrug);
    fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=add_favorite`, {
      method: 'POST',   
      body: data,
    });
    //alert(this.state.selectedDrug);
    
    fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=get_drugs`)
        .then(res => res.json())
        .then(json => {
          this.setState({            
            drugs:json,            
          })
        });
    alert("Added Successfully");

  }

  

  handleSubmit(event) {
//alert("OMSAI");
  }

 

  // proFilter(){
  //   alert("OMSAI");
  // }

  componentWillMount() {

    this.props.apiGetOnGoing({patient_id: this.state.patientId, doctor_id: 1}).then(res=>{
        this.setState({meds1: res.data.content});
      });

    this.props.apiGetOneOff({patient_id: this.state.patientId, doctor_id: 1}).then(res=>{
         this.setState({meds2: res.data.content});
      });

    this.props.apiGetAllMeds().then(res=>{
         this.setState({allMed: res.data.content});
      });

    this.props.apiListPast({patient_id: this.state.patientId}).then(res=>{
      this.setState({past: res.data.content});
      console.log(res.data.content);
    });
    // console.log(this.state.past);

  }
  componentWillReceiveProps(nextProps) {
    //    console.log('---------recieve props changed',nextProps);

        if (nextProps.redux_patientId!==this.props.redux_patientId) {
          this.setState({ patientId: nextProps.redux_patientId });

          this.props.apiGetOnGoing({patient_id: nextProps.redux_patientId, doctor_id: 2}).then(res=>{
            this.setState({meds1: res.data.content});
          });

        this.props.apiGetOneOff({patient_id: nextProps.redux_patientId, doctor_id: 2}).then(res=>{
             this.setState({meds2: res.data.content});
          });
        this.props.apiListPast({patient_id: nextProps.redux_patientId}).then(res=>{
          this.setState({past: res.data.content});
         });

        }
  }
   addData() {

        if(this.state.selectedDrug == "" ||
        this.state.selectedProduct == "" ||
        this.state.selectedDose == "" ||
        this.state.selectedQuantity == "" ||
        this.state.selectedReason == "" ||
        this.state.selectedRepeat == "" ||
        this.state.selectedType == "" ){
          NotificationManager.error('Please select all mandatory fields');
          return false;
        }
    const selectedValues = {
      // patient_id: this.state.patientId,
      // doctor_id: 1,
      // script_item: this.state.selectedDrug,
      // dose: this.state.selectedDose,
      // Frequncy: this.state.selectedQuantity,
      // Reason: this.state.selectedReason,
      // repeats: this.state.selectedRepeat,
      // Reason_visit:this.state.reasonVisit,
      // script_date: this.getCurrentDate(),
      // on_going_status: 1

    }
    console.log(selectedValues);
//alert(this.state.selectedDrug);
    
    const data = new FormData();
        data.set('patientId', this.state.patientId);
        data.set('doctorId', this.props.redux_docId);
        data.set('drugId', this.state.selectedDrug);
        data.set('productName', this.state.selectedProduct);
        data.set('dose', this.state.selectedDose);     
        data.set('frequency', this.state.selectedQuantity);
        data.set('reason', this.state.selectedReason);
        data.set('repeat', this.state.rpttext);
        data.set('treatmentType', this.state.selectedType);
        data.set('reasonVisit', this.state.reasonVisit);
        data.set('pattern', this.state.pattern);
        data.set('scriptDate', this.getCurrentDate());
        data.set('food', this.state.selectedFood);
        data.set('restriction', this.state.restrictions);
        data.set('quantity', this.state.qtytext);
        data.set('prescribe_as', this.state.pbprivate);
        data.set('regulation_24', this.state.reg24);
        data.set('allow_brand', this.state.allowbrand);
        data.set('generate_note', this.state.mednote);
        


if(this.state.medication_id == ""){
    fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=add_medication`, {
      method: 'POST',   
      body: data,
    });
    NotificationManager.success('Medication added successfully');
    //alert("Medication added successfully ");
  }else{
    //alert(this.state.reasonVisit+"/"+this.state.medication_id);
    data.set('id', this.state.medication_id);
    fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=update_medication`, {
      method: 'POST',   
      body: data,
    });
    NotificationManager.success('Medication updated successfully');
    //alert("Medication updated successfully ");
  }

    // if(this.state.selectedType === "Long Term"){
    //   var elmnt = document.getElementById("ltgrid");
    //   }else{
    //   var elmnt = document.getElementById("otgrid");
    //   }
      var elmnt = document.getElementById("ltgrid");
      elmnt.scrollIntoView();

      this.props.apiAddMedication(selectedValues).then(req=>this.changeKey())
      /*this.props.apiGetOnGoing({patient_id: this.state.patientId, doctor_id: 1}).then(res =>{
        this.setState({meds1: res.data.content});
      });

    this.props.apiGetOneOff({patient_id: this.state.patientId, doctor_id: 1}).then(res =>{
         this.setState({meds2: res.data.content});
      });*/
  //  axios.post('http://localhost/SupaDoc/supa-doc-server/project/?api=medication&action=add_medication',selectedValues)
  }
  
  
  handleCheckbox = () => {
    console.log("handle check");
    //this.state.checkedBox = e.target.name;
    //this.setState({ checkedBox: this.state.checkedBox });

  }
  editMed() {

  }

  onRowClicked = clickedId => {        
    console.log("al", clickedId);        
  };

  

  selAll=(AselectedInId,inAction,AselectedInlength)=>{
    if(inAction=='selection')
    {
      this.state.AselectedInId=AselectedInId;
      this.state.AselectedInLength=AselectedInlength;      
    }else if(inAction=='save'){
     // alert(this.state.AselectedInId+"/"+this.state.AselectedInLength);
      const data = new FormData();
        data.set('patientId', this.props.redux_patientId);
        // data.set('allergy_id', this.state.AselectedInId);
        // data.set('all_length', this.state.AselectedInLength);
        if(this.state.allergy === "" || this.state.reaction === "" || this.state.severity === ""){
          NotificationManager.error('Please select Allergy, Reaction and Severity');
          //alert("Please select Allergy, Reaction and Severity");
          return false;
        }

        data.set('allergy', this.state.allergy);
        data.set('reaction', this.state.reaction);
        data.set('severity', this.state.severity);
        data.set('comments', this.state.comments);
        // alert(this.state.severity);
        // return false;
        
      fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=add_allergy`, {
        method: 'POST',   
        body: data,
      });
      this.setState({showModal:false}); 
      NotificationManager.success('Added allergy successfully');
      //alert("Added allergy successfully");
    }
  }
  delMed=(GselectedInId,inAction,GselectedInlength)=>{
   // alert(inAction)
    if(inAction=='selection')
    {
      this.state.selectedInId=GselectedInId;
      this.state.selectedInLength=GselectedInlength;
    }
    else if(inAction=="delete")
    {
            if (this.state.selectedInLength> 0) {
              confirmAlert({
                title: 'Delete record',
                message: 'Do you want to delete the record?',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                     // alert(this.state.selectedInId);
                      const data = new FormData();
                           data.set('id', this.state.selectedInId);
                            fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=delete_medications`, {
                              method: 'POST',   
                              body: data,
                            });
                            this.setState({ key: Math.random() });
                  //     this.props.apiDeleteMedication({medications_id:this.state.selectedInId}).then(
                  //       req=>this.changeKey(),
                  //   NotificationManager.success(
                  //   'Record deleted',
                  //   //this.changeKey()
                  //   )
                  // );
                },
            },
            {
              label: 'No',
              //onClick: () => { this.updateGridIn()},
              onClick: () => {}
            },
          ],
        });
      } else {
        NotificationManager.error('Please select a record to delete');
      }
      
    }else if(inAction=="edit")
    {
      if(this.state.selectedInLength == 0){
        NotificationManager.error('Please select a record to Edit');
      }else if(this.state.selectedInLength == 1){

        const apiUrl = `${process.env.REACT_APP_BASE_URL}api=womenHistory&action=get_medication`;
        const formData = new FormData();
        formData.append('id', this.state.selectedInId);
        const options = {
          method: 'POST',
          body: formData
        }
        fetch(apiUrl, options)
        .then(res => res.json())
        .then(
          (result) => {  
            //alert(result[0].drug_name+"/"+result[0].product_name);   
            //alert(result[0].medication_id+"/"+result[0].repeat_m+"/"+result[0].frequency);             
            console.log(">>>>>>>>>>>"+result);
            this.setState({
              medication_id:result[0].medication_id,
              selectedDrug:result[0].drug_id,
              selectedProduct:result[0].product_name,
              selectedDose:result[0].dose,     
              selectedQuantity:result[0].frequency,
              selectedReason:result[0].reason,
              selectedRepeat:result[0].repeat_m,
              selectedType:result[0].treatment_type,
              reasonVisit:result[0].reason_visit,
              selectedDrugName:result[0].drug_name,
              pattern:result[0].pattern,
              food:result[0].food,
              restrictions:result[0].restriction,
              qtytext:result[0].quantity,
              pbprivate:result[0].prescribe_as,
              reg24:result[0].regulation_24,
              allowbrand:result[0].allow_brand,
              generatenote:result[0].generate_note,
              rpttext:result[0].repeat_m,
              mednote:result[0].generate_note,

              // wh_id:result[0].id,
              // performed_date:new Date(result[0].performed_date),
              // performed_by:result[0].performed_by,
              // test:result[0].test,
              // unsatisfactory_spec:result[0].unsatisfactory_spec,
              // endoservical:result[0].endoservical,
              // hpv_changes:result[0].hpv_changes,
              // hpv16:result[0].hpv16,
              // hpv18:result[0].hpv18,
              // hpv:result[0].hpv,
              // result:result[0].result,
              // lbc_result:result[0].lbc_result,                  
              // other_info:result[0].other_info
                        
                          
            })
         //   this.setState({drugdetails})
            //alert(this.state.reasonVisit)
           document.getElementById("drug_list").value = result[0].drug_id;
           document.getElementById("product_list").value = result[0].product_name;
           document.getElementById("dose_list").value = result[0].dose;
           document.getElementById("quantity_list").value = result[0].frequency;
           document.getElementById("repeat_list").value = result[0].repeat_m;
                     
          this.toggleSidenav('add'); 
          },
          (error) => {
            alert(error);
            this.setState({ error });
          }
        )
        

       // alert(this.state.selectedInId); 
      // this.setState({ key: Math.random() });
       }else{
        NotificationManager.error('You cant edit more than one row');
       }
      
    }else if(inAction=="add"){
      //alert("Add");
      this.setState({
        medication_id:"",
        selectedDrug:"",
        selectedProduct:"",
        selectedDose:"",     
        selectedQuantity:"",
        selectedReason:"Script",
        selectedRepeat:"",
        selectedType:"Long term",
        reasonVisit:"",
        selectedDrugName:"Acetaminophen",
        pattern:0
      })
      document.getElementById("drug_list").value = 1;
      document.getElementById("product_list").value = "";
      document.getElementById("dose_list").value = "";
      document.getElementById("quantity_list").value = "";
      document.getElementById("repeat_list").value = "";
      //alert(this.state.reasonVisit);
      this.setState({ key: Math.random() });
      
      this.toggleSidenav('add');

    }else if(inAction=="moveUp"){

      if (this.state.selectedInLength> 0) {
        const data = new FormData();
        data.set('id', this.state.selectedInId);
        data.set('treatmentType', "Long term");
        fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=move_medications`, {
          method: 'POST',   
          body: data,
        });        
        NotificationManager.success('Medication updated successfully');
        this.setState({ key: Math.random() });
      } else {
        NotificationManager.error('Please select a record to move');
      }

    }else if(inAction=="moveDown"){

      if (this.state.selectedInLength> 0) {
        const data = new FormData();
        data.set('id', this.state.selectedInId);
        data.set('treatmentType', "One time");
        fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=move_medications`, {
          method: 'POST',   
          body: data,
        });        
        NotificationManager.success('Medication updated successfully');
        this.setState({ key: Math.random() });
      } else {
        NotificationManager.error('Please select a record to move');
      }


    }

    
  }
  getCurrentDate(separator='-'){

    var today = new Date();
    return today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

    // let newDate = new Date()
    // let date = newDate.getDate();
    // let month = newDate.getMonth() + 1;
    // let year = newDate.getFullYear();
    // return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`;
    }
  onsearchchange=(e)=>
  {
    this.setState({
      query: e.target.value
    })
    // alert(e.target.value);
    // this.state.searchedDrugs=[];
    // this.props.getdrugs({search:e.target.value}).then(req=>{
    //   this.setState({searchedDrugs:req.data.content})
    // })
  }

  handleFieldValueChanges(key, value) {    
    this.setState({
      [key]: value
    });



    if(value === "Favourites"){
      this.setState({
        favorite_id: "1"
      });
    }else{
      this.setState({
        favorite_id: ""
      });
    }
  }

  componentDidMount(){
    fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=get_drugs`)
        .then(res => res.json())
        .then(json => {
          this.setState({            
            drugs:json,            
          })
        });
    //this.get_drugs();
    fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=get_allergies`)
        .then(res => res.json())
        .then(json => {
          this.setState({            
            allergies:json,            
          })
        });
        this.setState({   
        reactions :
        [{"reaction_name":"Muscle pains"},
        {"reaction_name":"Pruritus"},
        {"reaction_name":"Urticana"},
        {"reaction_name":"Weight gain"},
        {"reaction_name":"Drowsiness"},
        {"reaction_name":"Abdominal Pain"},
        {"reaction_name":"Agtation"},
        {"reaction_name":"Angloedema"},
        {"reaction_name":"Back pain"},
        {"reaction_name":"Candidiasis"},
        {"reaction_name":"Constipation"},
        {"reaction_name":"Convulsion"},
        {"reaction_name":"Cough"}]  
        })  

  }


  getdrugdetails=(e)=>
  {
   // alert(e.target.value);
    this.setState({
      selectedDrug:e.target.value,
      selectedProduct:"",
      selectedDrugName:e.target.selectedOptions[0].text      
    });
     document.getElementById("drug_list").value = e.target.value;
    const apiUrl = `${process.env.REACT_APP_BASE_URL}api=womenHistory&action=recent_medication`;
    const formData = new FormData();
    formData.append('drug_id', e.target.value);
    const options = {
      method: 'POST',
      body: formData
    }
    fetch(apiUrl, options)
    .then(res => res.json())
    .then(
      (result) => {  
        //alert(result[0].drug_name+"/"+result[0].product_name);   
        //alert(result[0].medication_id+"/"+result[0].repeat_m+"/"+result[0].frequency);             
        console.log(">>>>>>>>>>>"+result);
        this.setState({
          //selectedDrug:result[0].drug_id,
          selectedProduct:result[0].product_name,
          selectedDose:result[0].dose,     
          selectedQuantity:result[0].frequency,
          //selectedReason:result[0].reason,
          selectedRepeat:result[0].repeat_m,
          //selectedType:result[0].treatment_type,
          reasonVisit:result[0].reason_visit,
          //selectedDrugName:result[0].drug_name
                    
                      
        })
        //alert(this.state.reasonVisit)
       //document.getElementById("drug_list").value = result[0].drug_id;
     //  document.getElementById("product_list").value = result[0].product_name;
       //document.getElementById("dose_list").value = result[0].dose;
       //document.getElementById("quantity_list").value = result[0].frequency;
       //document.getElementById("repeat_list").value = result[0].repeat_m;
                 
      
      },
      (error) => {
        alert(error);
        this.setState({ error });
      }
    )

    this.props.getDrugDetail({drug_id:e.target.value}).then(res =>{
      console.log("**********",res.data.content[0])
      this.setState({all_drugdetails:res.data.content,qtyreadonly:true})
      if(res.data.content[0].restrictions=='PBS'){
          this.setState({pbprivate:1})
      }
      else{
        this.setState({pbprivate:2})
      }
    })


  }
  changeKey=()=>
  {
    this.setState({key:Math.random()})
  }

  checkbps=(val)=>
  {
    this.setState({rpttext:val.target.value})
    if(this.state.drugdetails.restrictions=='PBS')
    {
      confirmAlert({
        title: '',
        message: 'Would you like to prescribe this item as an Authority',
        buttons: [
        {
            label: 'Yes',
            onClick: () => {
              this.setState({pbprivate:2})
           // NotificationManager.success('Clinical record(s) deleted successfully',);
        },
       },
     {
      label: 'No',
      onClick: () => { this.setState({pbprivate:1})},
      },
     ],
    });
    }
  }
  showCMI= ()=>{
    confirmAlert({
      title: '',
      message: 'MIMS integration in progress',
      buttons: [
      {
          label: 'Ok',
          onClick: () => {
       
      },
     },
   ],
  });
  }
  render(){
      return(
        <Popup
        open={this.state.open}
        closeOnDocumentClick={false}
         position="right center"   modal
         id="medicationpanel"
         
       >
         {close => (
           <div style={{ width:'1000px', backgroundColor:'#FFF', float:'left'}} >
            <div className="modal-header " style={{ backgroundColor: '#f4f4f4', padding: '5px 15px'}}>
            <button type="button" className="close" style={{position: 'absolute', right: '10px', fontSize: '25px'}} data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}>
                    <span aria-hidden="true">×</span>
                </button>
            <h5 className="col-sm-12 sheadtitle" style={{textAlign:'left'}}>Add New Rx <Showhelp gotohelp="add_new_rx_help"/> </h5>
                   
            </div>  
            <div className="modal-body">
                <form>

                <div className="col-sm-12">
                      <div class="col-sm-6" style={{marginTop:'15px', textAlign:'left'}}>
                          <label className="radio-inline"><input type="radio" name="optcheckbox" /> Product Name</label>
                          <label className="radio-inline"><input type="radio" name="optcheckbox" /> Therapuetic Class</label>
                            <label className="radio-inline"><input type="radio" name="optcheckbox" /> Custom</label>
                            <label className="radio-inline"><input type="radio" name="optcheckbox" /> Favourites</label>
                      </div>
                      </div>

                      <div class="col-sm-12">
                        <div class="col-sm-6">
                        <label class="label-input100"><span style={{color:'#000', float:'left', marginTop:'15px'}}> Search</span> <input type="text" class="form-control form-control-sm" value="" style={{padding: '5px 10px', marginLeft:'10px', width:'360px', float:'left'}} /></label>			
                        </div>

                      </div>


                <div className="col-sm-8 pr-0">  
                        <div className="col-sm-3 pr-0 pl-0">
                              <label className="col-sm-12 col-form-label text-left" style={{paddingLeft: 5, fontWeight: 'bold', margin:'0px 0'}}>Drug  name: <span style={{color:'red'}}>*</span></label>
                                <select name="select" id="drug_list" multiple style={{ width: '100%', marginRight: 5, fontWeight: 'normal', border: '1px solid #ccc', marginTop: 7, marginBottom: 0, padding: '5px 10px', height: 150}} 
                                  onChange={(e) => this.getdrugdetails(e)}>
                                    {this.state.drugs!=undefined && this.state.drugs.filter(drug => drug.drug_name.toLowerCase().includes(this.state.query) && drug.fav_drs.includes(this.state.favorite_id)).map(drug=>(
                                        <option value={drug.drug_id}>{drug.drug_name}</option>))}
                                </select>
                                <div className="col-sm-12 pr-0" style={{paddingLeft: 0, marginTop: 10}}>
                                  <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{padding: '3px 4px', marginRight:'10px' }} onClick={e => this.addFav()}>+ Favourites</button>
                                  <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs"  style={{padding: '3px 4px', marginRight:'0px' }} onClick={e => this.open()}>+ Allergies</button>
                                </div>
                        </div>
                        

                          <div className="col-sm-3 pl-0 pr-0">
                               <label className="col-sm-12 col-form-label text-left" style={{paddingLeft: 5, fontWeight: 'bold', margin:'0px 0'}}>Frequency: <span style={{color:'red'}}>*</span></label>
                                <div className="col-sm-12" style={{paddingRight: 0}}>
                                  <select id="dose_list" style={{ width: '100%', marginRight: 5, fontWeight: 'normal', border: '1px solid #ccc', marginTop: 7, marginBottom: 0, padding: '5px 10px', height: 150}} 
                                  onChange={(e) => this.setState({ selectedQuantity: e.target.value })} multiple>
                                    <option value="Twice a day">Twice a day</option>
                                    <option value="In the evening">In the evening</option>
                                    <option value="1 p.r.n.">1 p.r.n.</option>
                                    <option value="Daily">Daily</option>
                                    <option value="One puff daily">One puff daily</option>
                                    <option value="2 p.r.n.">2 p.r.n.</option>
                                    <option value="2 Twice a day">2 Twice a day</option>
                                    <option value="1 take one stat">1 take one stat.</option>
                                  </select>
                                  <div className="col-sm-12" style={{paddingLeft: 0, marginTop: 10}}>
                                   <label class="radio-inline">
                                    <input type="radio" name="Reason" id="Reason" value="Script" onChange={(e) => this.setState({ selectedReason: "Script" })}
                                      checked={this.state.selectedReason == "Script"}  />&nbsp;Script&nbsp;
                                   </label>
                                   <label class="radio-inline">
                                       <input type="radio" name="Reason" id="Reason" value="Other" onChange={(e) => this.setState({ selectedReason: "Other" })}
                                        checked={this.state.selectedReason == "Other"} />&nbsp;Other
                                    </label>
                                  </div>
                                </div>      
                           </div>
                           <div className="col-sm-3 pl-0 pr-0">
                              <label className="col-sm-12 col-form-label text-left" style={{paddingLeft: 5, fontWeight: 'bold', margin:'0px 0'}}>Dose: <span style={{color:'red'}}>*</span></label>
                                <div className="col-sm-12" style={{paddingRight: 0}}>
                                  <select id="quantity_list" multiple style={{ width: '100%', marginRight: 5, fontWeight: 'normal', border: '1px solid #ccc', marginTop: 7, marginBottom: 0, padding: '5px 10px', height: 150}} 
                                  //value={this.state.selectedQuantity}
                                  onChange={(e) => this.setState({ selectedDose: e.target.value })} >                                  
                                  {/* <option value="">Select</option> */}
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="1x140 dose">1x140 dose</option>
                                    <option value="1x30g">1x30g</option>
                                  </select>
                                  
                                <div className="col-sm-12" style={{paddingLeft: 0, marginTop: 10}}>
                               <label>
                                <input type="checkbox" name="pattern" id="pattern" value='1'
                                onChange={e =>
                                  this.handleFieldValueChanges(
                                    "pattern",
                                    e.target.checked?1:0
                                  )
                                }
                                checked={this.state.pattern == 1}
                                 />Save Combination</label>
                                </div>
                                </div>
                            </div>
                           {/*} <div className="col-sm-1" style={{paddingRight: 0}}>
                              <label className="col-sm-12 col-form-label text-left" style={{marginTop:'10px'}}>Repeat: <span style={{color:'red'}}>*</span></label>
                                <div className="col-sm-12">
                                  <select id="repeat_list" style={{float: 'right', width: '100%', marginRight: 5, border: '1px solid #ccc', fontWeight: 'normal', marginTop: 7, marginBottom: 0, padding: '5px 10px', height: 150}}
                                  //value={this.state.selectedRepeat}
                                  onChange={(e) => this.setState({ selectedRepeat: e.target.value, rpttext:e.target.value })} multiple>
                                  {/* <option value="">Select</option> 
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>  
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                  </select>
                              </div>*
                              </div>*/}
                                <div className="col-sm-3 pl-0 pr-0">
                              <label className="col-sm-12 col-form-label text-left" style={{marginTop:'10px'}}>Food: <span style={{color:'red'}}>*</span></label>
                                <div className="col-sm-12">
                                  <select id="repeat_list" style={{float: 'right', width: '100%', marginRight: 5, border: '1px solid #ccc', fontWeight: 'normal', marginTop: 7, marginBottom: 0, padding: '5px 10px', height: 150}}
                                  onChange={(e) => this.setState({ selectedFood: e.target.value })} multiple>
                                    <option value="1">Before meals</option>
                                    <option value="2">with meals</option>
                                    <option value="3">after meals</option>
                                    <option value="4">on an empty stomach</option>
                                    <option value="5">without regard to meals</option>
                                  </select>
                                </div>
                            </div>
                              
                              </div>
                              <div className="col-sm-4 pl-0 pr-0">
                              <div className="col-sm-12 pl-0">
                              <label className="col-sm-12 col-form-label text-left" style={{paddingLeft: 5, fontWeight: 'bold', margin:'0px 0'}}>Product Name: <span style={{color:'red'}}>*</span></label>
                              <div className="col-sm-12 pl-0 pr-0" style={{float:'left', width:'100%', minHeight:'150px'}}>
                                   {/*} <select id="product_list" style={{padding: '.0rem .5rem', height: 150,marginTop: 7, border: '1px solid #ccc', borderBottom: '1px solid #ccc', fontSize: 12,width: '100%'}} 
                                    onChange={(e) => this.setState({ selectedProduct: e.target.value })} multiple >
                                    <option value="">Select</option>
                                        <option >{this.state.selectedDrugName} 50</option>
                                        <option >{this.state.selectedDrugName} 150</option>
                                        <option >{this.state.selectedDrugName} 250</option>
                                        <option >{this.state.selectedDrugName} Syrup</option>
                                    </select>*/}
                                      <table className="calendar table table-bordered tablestyle">
                                      <tr style={{backgroundColor:'#f4f4f4'}}>
                                          <th>
                                            Product name
                                          </th>
                                          <th>
                                             Restrictions
                                          </th>
                                          <th>
                                             Quantity
                                          </th>
                                          <th>
                                             Repeats
                                          </th>
                                      </tr>
                                      {this.state.all_drugdetails!='' && this.state.all_drugdetails.map(detaildata=>(
                                        <tr style={{cursor:'pointer'}}  onClick={(e) => this.setState({selectedProduct:detaildata.drug_id,restrictions:detaildata.restrictions,qtytext:detaildata.quantity,qtyreadonly:false,rpttext:detaildata.repeats})}>
                                            <td>{detaildata.drugdetails} {detaildata.prod_type}</td>
                                            <td>{detaildata.restrictions}</td>
                                            <td>{detaildata.quantity}</td>
                                            <td>{detaildata.repeats}</td>
                                        </tr>
                                      ))}
                                  </table>
                                    <div className="col-sm-12" style={{paddingLeft: 0, marginTop: 10}}>
                                    <label class="radio-inline">
                                    <input type="radio" name="TrType" id="TrType" value="Long term" onChange={(e) => this.setState({ selectedType: "Long term" })}
                                        checked={this.state.selectedType == "Long term"}  />&nbsp;Long term
                                    </label>
                                    <label class="radio-inline">
                                    <input type="radio" name="TrType" id="TrType" value="One time" onChange={(e) => this.setState({ selectedType: "One time" })}
                                        checked={this.state.selectedType == "One time"} />&nbsp;One time</label>
                                    </div>
                                </div>
                          </div>
                            
                    </div>{/**col-sm-12 main div */}
                    <div className="col-sm-12" style={{padding:'10px'}}>
                        <div className="col-sm-1 mt-2">
                            <label>Restrictions : {this.state.drugdetails!=undefined ? this.state.restrictions: ''}</label>
                        </div> 
                        <div className="col-sm-1 mt-2 pr-0 text-right">
                          <label className="col-form-label" >Quantity:</label>
                          </div>
                          <div className="col-sm-2">
                          <input type="text" onChange={(e)=>this.setState({qtytext:e.target.value})} value={this.state.qtytext}/>      
                        </div> 
                        <div className="col-sm-1 mt-2 pr-0 text-right">
                          <label className="col-form-label" >Repeats:</label>
                          </div>
                          <div className="col-sm-2">
                          <input  type="text" onBlur={(e)=>this.checkbps(e)} value={this.state.rpttext} onChange={(e)=>this.setState({rpttext:e.target.value})}/>      
                        </div>    
                        <div className="col-sm-2">
                          <label><input type="checkbox" onChange={(e)=>this.setState({reg24:e.target.checked})} value={this.state.reg24} checked={this.state.reg24=="true"?true:''}/> Regulation 24</label>
                        </div>        

                    </div>
                    <div className="col-sm-12">
                        <div className="col-sm-2 pl-0 pr-0 mt-2">
                          <label>Prescribe as:</label>
                        {this.state.restrictions=='PBS'? 
                          <label className="label-input100" style={{marginRight: 0, marginBottom: 1, fontSize: 12, paddingBottom: 0, paddingTop: 0,color:'black'}}>
                              <input type="radio"  name="pbpriv"  value="1" checked={this.state.pbprivate == 1} onClick={()=>this.setState({pbprivate:1})}/>&nbsp;PBS</label>
                        :''}
                          <label className="label-input100" style={{marginRight: 0, marginBottom: 1, fontSize: 12, paddingBottom: 0, paddingTop: 0,color:'black'}}>
                            <input type="radio"  name="pbpriv" value="2" checked={this.state.pbprivate == 2} onClick={()=>this.setState({pbprivate:2})}/>&nbsp;Private </label>
                        </div>
                         <div className="col-sm-2 mt-2 pl-0 pr-0 text-left">
                         <label><input type="checkbox" onChange={(e)=>this.setState({allowbrand:e.target.checked})} value={this.state.allowbrand} checked={this.state.allowbrand=="true"?true:''}/> Allow brand substitution</label>

                        </div>
                        <div className="col-sm-1 mt-2 pr-0 pl-0 text-left">
                            <label>PBS Listing :&nbsp;</label>
                        </div>
                        <div className="col-sm-2">
                            <input type="text" readOnly value={this.state.restrictions}/>
                        </div>   
                     </div>
                     <div className="col-sm-12">
                        <label className="col-sm-1 pl-0 pr-0"> Generate Note:</label> &nbsp;
                      <div className="col-sm-3"><input type="text"   value={this.state.mednote} onChange={(e)=>this.setState({mednote:e.target.value})}/>
                      </div>
                      <div className="col-sm-3"> <select  id="generatenote" name="generatenote" onChange={(e) => this.setState({ generatenote: e.target.value,mednote:e.target.value})} value={this.state.generatenote}>
                          <option value="Medication prescribed">Medication prescribed</option>
                          <option value="Medication started in hospital">Medication started in hospital</option>
                          <option value="Medication started by specialist">Medication started by specialist</option>
                          <option value="Medication started by patient">Medication started by patient</option>
                          <option value="Medication started else where">Medication started else where</option>
                          <option value="Sample pack given">Sample pack given</option>
                        </select></div>
                        </div>
                        <div className="col-sm-12">
                      <div className="col-sm-12" style={{paddingLeft: 0, float: 'right', marginTop: 10}}>
                                  <div className="col-sm-12" style={{paddingLeft: 0}}>
                                    <textarea name="reason_visit" style={{float: 'left', width: '100%', height:'100px', border: '1px solid #ccc', padding: 10, fontWeight: 'normal', marginTop: 0}} placeholder="Reason for prescription" value={this.state.reasonVisit}  
                                      onChange={(e) => this.setState({ reasonVisit: e.target.value })}/>
                                  </div>
                                 
                                  <div className="col-sm-12" style={{paddingLeft: 0}}>
                                  <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={()=>this.showCMI()}>CMI</button>
                                  <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={()=>this.showCMI()} >Product Information</button>
                                
                                    <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={this.addData.bind(this)}>Save & Print</button>
                                    {this.state.isaction=='add' ? 
                                      <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs"  onClick={this.addData.bind(this)}>Save</button>
                                    : <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs"  onClick={this.addData.bind(this)}>Update</button>}
                                  </div>
                                </div>
                      </div>   
                      </form>

                </div>{/**Modal body */}
                <div className="modal-footer">
                 <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs"  onClick={()=>{this.props.changeKey()}}>Close</button>
                             
                </div>
            
            </div>
      )}
      </Popup>
      )
  }

  
  toggleSidenav(action) {
    this.setState({isaction:action})
    var css = (this.state.showHideSidenav === "hidden") ? "show" : "show";
    this.setState({"showHideSidenav":css});
    if(action=='edit')
    {
        this.props.geteditdata({medication_id:this.state.selectedInId}).then(req=>
         {
           this.setState({
            //selectedQuantity:req.data.content[0].qunatity

           })
           //console.log(req.data.content[0].quantity)
         }
        )
    }
    else
    {
      this.setState({
        //selectedQuantity:''

       })
    }
    setTimeout(window.scrollTo(0,document.body.scrollHeight), 3000);
  }
}


const mapDispatchToProps = dispatch => {
  return {
    getMeds: () => dispatch(api_getMeds()) ,
    apiGetOnGoing: data => dispatch(api_getOnGoing(data)),
    apiGetOneOff: data => dispatch(api_getOneOff(data)),
    apiGetAllMeds: data => dispatch(api_getAllMeds(data)),
    apiAddMedication: data => dispatch(api_addMedication(data)),
    apiDeleteMedication: data => dispatch(api_deleteMedication(data)),
    apiListPast: data => dispatch(api_listpast(data)),
    getdrugs:data=>dispatch(api_get_drugs(data)),
    getDrugData:data=>dispatch(api_dosage_data(data)),
    geteditdata:data=>dispatch(api_get_edit_data(data)),
    getDrugDetail:data=>dispatch(api_get_drug_detail(data))

  };
};


const mapStateToProps = state => {

  let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
  let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
    
return {
  redux_patientId,redux_docId
};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicationPop);

//api_getOnGoing,api_getOneOff,api_getAllMeds,api_addMedication,api_deleteMedication export default Medications;
