import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import {
    NotificationContainer,
    NotificationManager,
  } from 'react-notifications';
  import { confirmAlert } from 'react-confirm-alert';
  import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
import AttendenceReplies from './attendenceReplies';
import {api_get_settings} from '../../../DS/DS.Reminders';
import {update_setting} from '../../../DS/DS.AppointmentReminder';

class AppointmentReminders extends Component {
    constructor(props)
    {
        super(props);
        this.state={
            disable1:true,
            disable2:true,
            disable3:true,
            alreadySet:0,
            checkreplies:'',
            confirmauto:'',
            addModal:false,
            key:0,
            withinlast:0,
            withindays:'',
            std_apt:0,
            long_apt:0,
            new_patient:0,
            exition:0,
            procedured:0,
            cervical_screen:0,
            immunisation:0,
            antenatal_visit:0,
            accupunture:0,
            health_assesment:0,
            driving_medical:0,
            drug_rep:0,
            dva_medical:0,
            home_visit:0,
            hosp_visit:0,
            insurance_medical:0,
            internet:0,
            meeting:0,
            nursing_home:0,
            other:0,
            tele_conf:0,
            tele_consultant:0,
            worker_comps:0
        }
    }
    componentWillMount=()=>
    {
      const settname={setting_name:'reminder_sent'}
      this.props.apigetsettings(settname).then(res=>{
       this.setState({alreadySet:res.data.content[0].setting_value})
       if(res.data.content[0].setting_value==1)
       {
           this.setState({disable1:false})
       }
      })
  
      const settname1={setting_name:'within_last'}
      this.props.apigetsettings(settname1).then(res=>{
       this.setState({withinlast:res.data.content[0].setting_value})
      })

      const settname2={setting_name:'last_value'}
      this.props.apigetsettings(settname2).then(res=>{
          if(res.data.content[0].setting_value==0)
          {
            this.setState({withindays:''})
          }
          else{
            this.setState({withindays:res.data.content[0].setting_value})
          }
      })
      const settname3={setting_name:'check_replies'}
      this.props.apigetsettings(settname3).then(res=>{
            this.setState({checkreplies:res.data.content[0].setting_value})
            if(res.data.content[0].setting_value==1)
            {
                this.setState({disable2:false})
            }
      })
      const settname4={setting_name:'auto_answer'}
      this.props.apigetsettings(settname4).then(res=>{
            this.setState({confirmauto:res.data.content[0].setting_value})
            if(res.data.content[0].setting_value==1)
            {
                this.setState({disable3:false})
            }
      })
      const settname5={setting_name:'std_apt'}
      this.props.apigetsettings(settname5).then(res=>{
       this.setState({std_apt:res.data.content[0].setting_value})
      })
      const settname6={setting_name:'long_apt'}
      this.props.apigetsettings(settname6).then(res=>{
       this.setState({long_apt:res.data.content[0].setting_value})
      })
      const settname7={setting_name:'new_patient'}
      this.props.apigetsettings(settname7).then(res=>{
       this.setState({new_patient:res.data.content[0].setting_value})
      })
      const settname8={setting_name:'exition'}
      this.props.apigetsettings(settname8).then(res=>{
       this.setState({exition:res.data.content[0].setting_value})
      })
      const settname9={setting_name:'procedured'}
      this.props.apigetsettings(settname9).then(res=>{
       this.setState({procedured:res.data.content[0].setting_value})
      })
      const settname10={setting_name:'cervical_screen'}
      this.props.apigetsettings(settname10).then(res=>{
       this.setState({cervical_screen:res.data.content[0].setting_value})
      })
      const settname11={setting_name:'immunisation'}
      this.props.apigetsettings(settname11).then(res=>{
       this.setState({immunisation:res.data.content[0].setting_value})
      })
      const settname12={setting_name:'antenatal_visit'}
      this.props.apigetsettings(settname12).then(res=>{
       this.setState({antenatal_visit:res.data.content[0].setting_value})
      })
      const settname13={setting_name:'accupunture'}
      this.props.apigetsettings(settname13).then(res=>{
       this.setState({accupunture:res.data.content[0].setting_value})
      })
      const settname14={setting_name:'health_assesment'}
      this.props.apigetsettings(settname14).then(res=>{
       this.setState({health_assesment:res.data.content[0].setting_value})
      })
      const settname15={setting_name:'driving_medical'}
      this.props.apigetsettings(settname15).then(res=>{
       this.setState({driving_medical:res.data.content[0].setting_value})
      })
      const settname16={setting_name:'drug_rep'}
      this.props.apigetsettings(settname16).then(res=>{
       this.setState({drug_rep:res.data.content[0].setting_value})
      })
      const settname17={setting_name:'dva_medical'}
      this.props.apigetsettings(settname17).then(res=>{
       this.setState({dva_medical:res.data.content[0].setting_value})
      })
      const settname18={setting_name:'home_visit'}
      this.props.apigetsettings(settname18).then(res=>{
       this.setState({home_visit:res.data.content[0].setting_value})
      })
      const settname19={setting_name:'hosp_visit'}
      this.props.apigetsettings(settname19).then(res=>{
       this.setState({hosp_visit:res.data.content[0].setting_value})
      })
      const settname20={setting_name:'insurance_medical'}
      this.props.apigetsettings(settname20).then(res=>{
       this.setState({insurance_medical:res.data.content[0].setting_value})
      })
      const settname21={setting_name:'internet'}
      this.props.apigetsettings(settname21).then(res=>{
       this.setState({internet:res.data.content[0].setting_value})
      })
      const settname22={setting_name:'meeting'}
      this.props.apigetsettings(settname22).then(res=>{
       this.setState({meeting:res.data.content[0].setting_value})
      })
      const settname23={setting_name:'nursing_home'}
      this.props.apigetsettings(settname23).then(res=>{
       this.setState({nursing_home:res.data.content[0].setting_value})
      })
      const settname24={setting_name:'other'}
      this.props.apigetsettings(settname24).then(res=>{
       this.setState({other:res.data.content[0].setting_value})
      })
      const settname25={setting_name:'tele_conf'}
      this.props.apigetsettings(settname25).then(res=>{
       this.setState({tele_conf:res.data.content[0].setting_value})
      })
      const settname26={setting_name:'tele_consultant'}
      this.props.apigetsettings(settname26).then(res=>{
       this.setState({tele_consultant:res.data.content[0].setting_value})
      })
      const settname27={setting_name:'worker_comps'}
      this.props.apigetsettings(settname27).then(res=>{
       this.setState({worker_comps:res.data.content[0].setting_value})
      })
    }
    enable1=(e)=>
    {
        if(e==true)
        {
            this.setState({disable1:false,alreadySet:1})
        }
        else{
            this.setState({disable1:true,alreadySet:0,withinlast:0,withindays:''})
        }
    }
    enable2=(e)=>
    {
        if(e==true)
        {
            this.setState({disable2:false,checkreplies:1})
        }
        else{
            this.setState({disable2:true,checkreplies:0,confirmauto:0,disable3:true})
        }
    }
    enable3=(e)=>
    {
        if(e==true)
        {
            this.setState({disable3:false,confirmauto:1})
        }
        else{
            this.setState({disable3:true,confirmauto:0})
        }
    }
    reloadKey=()=>
    {
        this.setState({addModal:false,key:Math.random()})
    }
    saveSetting=()=>
  {
      const values={
        reminder_sent:this.state.alreadySet,
        within_last:this.state.withinlast,
        last_value:this.state.withindays,
        check_replies:this.state.checkreplies,
        auto_answer:this.state.confirmauto,
        std_apt:this.state.std_apt,
        long_apt:this.state.long_apt,
        new_patient:this.state.new_patient,
        exition:this.state.exition,
        procedured:this.state.procedured,
        cervical_screen:this.state.cervical_screen,
        immunisation:this.state.immunisation,
        antenatal_visit:this.state.antenatal_visit,
        accupunture:this.state.accupunture,
        health_assesment:this.state.health_assesment,
        driving_medical:this.state.driving_medical,
        drug_rep:this.state.drug_rep,
        dva_medical:this.state.dva_medical,
        home_visit:this.state.home_visit,
        hosp_visit:this.state.hosp_visit,
        insurance_medical:this.state.insurance_medical,
        internet:this.state.internet,
        meeting:this.state.meeting,
        nursing_home:this.state.nursing_home,
        other:this.state.other,
        tele_conf:this.state.tele_conf,
        tele_consultant:this.state.tele_consultant,
        worker_comps:this.state.worker_comps,
      }
    this.props.updatesetting({values}).then(
        req=>{
            NotificationManager.success("Settings modified successfully");
        }
    )
  }
    render()
    {
    return (
        <div>
            <AdminHeader /><Leftsidemenu />
            <NotificationContainer />
            <section className="content-header" style={{marginLeft: '240px'}}>
                <h1>Appointment Reminders</h1>
                <ol className="breadcrumb">
                <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                <li className="active">Appointment Reminders</li>
                </ol>
            </section>
            <section className="content" style={{marginLeft: '240px'}}>
                <div className="row">
                <div className="col-xs-12">
                    <div className="box">
                        <div className="col-xs-12" style={{margin:'0px auto', float:'none'}}>
                            <div className="box-body">
                                <div className="row">		
                                    <label className="col-sm-12 col-form-label text-left" style={{fontSize:'16px', fontWeight:'bold'}}> Default appointment reminder search options </label>
                                </div>
                                <div className="row m-b-0">
                                    <div className="col-sm-12">
                                        <div className="border-checkbox-section">
                                            <div className="border-checkbox-group border-checkbox-group-default">
                                                <label className="border-checkbox-label" for="checkbox0" style={{marginTop:'20px'}}>
                                                    <input className="border-checkbox" type="checkbox" id="checkbox0" value={this.state.alreadySet} checked={this.state.alreadySet==1 ? "checked" :''} 
                                                    onChange={(e)=>this.enable1(e.target.checked)}/>
                                                    &nbsp;  Exclude appointments that already have reminder sent 
                                                </label>
                                            </div>
                                        </div>			
                                    </div>
                                </div>
                                <div className="row m-b-0">
                                    <div className="col-sm-12">
                                        <div className="border-checkbox-section">
                                            <div className="border-checkbox-group border-checkbox-group-default">
                                                <label className="border-checkbox-label" for="checkbox1" style={{float:'left'}}>
                                                    <input className="border-checkbox" type="checkbox" id="checkbox1" disabled={this.state.disable1} value={this.state.withinlast}
                                                    checked={this.state.withinlast==1 ? "checked" :''}  onChange={(e)=>this.setState({withinlast:e.target.checked})}/>
                                                    &nbsp; Exclude appointments made within the last &nbsp; &nbsp;
                                                </label>
                                                <span>
                                                    <input type="text" style={{float:'left', width:'10%'}} className="form-control days-input" disabled={this.state.disable1} value={this.state.withindays} 
                                                    onChange={(e)=>this.setState({withindays:e.target.value})}/> 
                                                </span>
                                                <label> &nbsp; &nbsp; days </label> 
                                            </div>
                                        </div>			
                                    </div>
                                </div>
                             {/*}   <div className="form-group row m-b-0">
                                    <div className="col-sm-5">
                                        <div className="row">
                                            <div className="form-group">
                                                <div className="col-sm-4"> 
                                                    <label> Include appointment type : </label>
                                                </div>
                                                <div className="col-sm-8 cursor">
                                                    <form>
                                                        <input type="text" name="text" className="form-control" value=""/>
                                                    </form>
                                                </div>
                                            </div>	
                                        </div> 

                                    </div>
    </div>*/}
                                <div className="row row-distance">
                                    <div className="col-sm-5"> 
                                        <div className="include-appointment-type-block">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox2"> 
                                                            <input className="border-checkbox" type="checkbox" id="checkbox2" value={this.state.std_apt}
                                                            checked={this.state.std_apt==1 ?'checked' :''} onChange={(e)=>this.setState({std_apt:e.target.checked})} />
                                                            {/*&nbsp; <img src="dist/img/standard.png" width="15px;"/>*/} &nbsp; Standard appt 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox3"> 
                                                            <input className="border-checkbox" type="checkbox" id="checkbox3" value={this.state.long_apt}
                                                            checked={this.state.long_apt==1 ?'checked' :''} onChange={(e)=>this.setState({long_apt:e.target.checked})} />
                                                            {/* &nbsp; <img src="dist/img/long.png" width="15px;"/>*/} &nbsp; Long appt 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox4">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox4" value={this.state.new_patient}
                                                            checked={this.state.new_patient==1 ?'checked' :''} onChange={(e)=>this.setState({new_patient:e.target.checked})} />
                                                             {/*    &nbsp; <img src="dist/img/new-patient.png" width="20px;"/>*/}  &nbsp; New patient 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox5">
                                                                <input className="border-checkbox" type="checkbox" id="checkbox5" value={this.state.exition}
                                                            checked={this.state.exition==1 ?'checked' :''} onChange={(e)=>this.setState({exition:e.target.checked})} />
                                                              {/*       &nbsp; <img src="dist/img/excision.png" width="15px;"/> */} &nbsp; Exition 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox6"> 
                                                            <input className="border-checkbox" type="checkbox" id="checkbox6" value={this.state.procedured}
                                                            checked={this.state.procedured==1 ?'checked' :''} onChange={(e)=>this.setState({procedured:e.target.checked})} />
                                                          {/*   &nbsp; <img src="dist/img/procedure.png" width="15px;"/> */}&nbsp; Procedure 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox7"  >
                                                            <input className="border-checkbox" type="checkbox" id="checkbox7" value={this.state.cervical_screen}
                                                            checked={this.state.cervical_screen==1 ?'checked' :''} onChange={(e)=>this.setState({cervical_screen:e.target.checked})}/>
                                                          {/*   &nbsp; <img src="dist/img/cervical-screening.png" width="20px;"/>*/} &nbsp; Cervical screening 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default" >
                                                        <label className="border-checkbox-label" for="checkbox8">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox8"  value={this.state.immunisation}
                                                            checked={this.state.immunisation==1 ?'checked' :''} onChange={(e)=>this.setState({immunisation:e.target.checked})}/>
                                                          {/*   &nbsp; <img src="dist/img/immunisation.png" width="15px;"/> */}&nbsp; Immunisation 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox10">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox10" value={this.state.antenatal_visit}
                                                            checked={this.state.antenatal_visit==1 ?'checked' :''} onChange={(e)=>this.setState({antenatal_visit:e.target.checked})}/>
                                                           {/*          &nbsp; <img src="dist/img/antenatal-visit.png" width="20px;"/> */}&nbsp; Antenatal visit 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox11">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox11" value={this.state.accupunture}
                                                            checked={this.state.accupunture==1 ?'checked' :''} onChange={(e)=>this.setState({accupunture:e.target.checked})}/>
                                                          {/*   &nbsp; <img src="dist/img/pin.png" width="15px;"/> */}&nbsp; Acupuncture 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox12">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox12" value={this.state.health_assesment}
                                                            checked={this.state.health_assesment==1 ?'checked' :''} onChange={(e)=>this.setState({health_assesment:e.target.checked})}/>
                                                          {/*           &nbsp; <img src="dist/img/health-assessment.png" width="15px;"/> */}&nbsp; Health Assessment 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox29"> 
                                                            <input className="border-checkbox" type="checkbox" id="checkbox29" value={this.state.driving_medical}
                                                            checked={this.state.driving_medical==1 ?'checked' :''} onChange={(e)=>this.setState({driving_medical:e.target.checked})}/>
                                                          {/*   &nbsp; <img src="dist/img/diving-medical.png" width="15px;"/> */}&nbsp; Diving Medical
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox28">
                                                             <input className="border-checkbox" type="checkbox" id="checkbox28" value={this.state.drug_rep}
                                                            checked={this.state.drug_rep==1 ?'checked' :''} onChange={(e)=>this.setState({drug_rep:e.target.checked})}/>
                                                           {/*  &nbsp; <img src="dist/img/drug-rep.png" width="20px;"/> */} &nbsp; Drug rep 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox27">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox27" value={this.state.dva_medical}
                                                            checked={this.state.dva_medical==1 ?'checked' :''} onChange={(e)=>this.setState({dva_medical:e.target.checked})}/>
                                                             {/*&nbsp; <img src="dist/img/DVA-medical.png" width="15px;"/>*/}  &nbsp; DVA Medical 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox26"> 
                                                            <input className="border-checkbox" type="checkbox" id="checkbox26" value={this.state.home_visit}
                                                            checked={this.state.home_visit==1 ?'checked' :''} onChange={(e)=>this.setState({home_visit:e.target.checked})}/>
                                                           {/*          &nbsp; <img src="dist/img/home-visit.png" width="15px;"/> */}&nbsp; Home Visit  
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox25">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox25" value={this.state.hosp_visit}
                                                            checked={this.state.hosp_visit==1 ?'checked' :''} onChange={(e)=>this.setState({hosp_visit:e.target.checked})}/>
                                                        {/*     &nbsp; <img src="dist/img/hospital-visit.png" width="20px;"/>*/} &nbsp; Hospital Visit 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox24">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox24" value={this.state.insurance_medical}
                                                            checked={this.state.insurance_medical==1 ?'checked' :''} onChange={(e)=>this.setState({insurance_medical:e.target.checked})}/>
                                                       {/*  &nbsp; <img src="dist/img/insurance-medical.png" width="15px;"/> */}&nbsp; Insurance Medical 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox23">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox23" value={this.state.internet}
                                                            checked={this.state.internet==1 ?'checked' :''} onChange={(e)=>this.setState({internet:e.target.checked})}/>
                                                     {/*    &nbsp; <img src="dist/img/internet.png" width="20px;"/> */}&nbsp; Internet 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox14">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox14" value={this.state.meeting}
                                                            checked={this.state.meeting==1 ?'checked' :''} onChange={(e)=>this.setState({meeting:e.target.checked})}/>
                                                       {/*      &nbsp; <img src="dist/img/meeting.png" width="15px;"/>*/} &nbsp; Meeting 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox15">
                                                        <input className="border-checkbox" type="checkbox" id="checkbox15" value={this.state.nursing_home}
                                                            checked={this.state.nursing_home==1 ?'checked' :''} onChange={(e)=>this.setState({nursing_home:e.target.checked})}/>
                                                      {/*   &nbsp; <img src="dist/img/nursing-home.png" width="15px;"/> */}&nbsp; Nursing Home 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox16">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox16" value={this.state.other}
                                                            checked={this.state.other==1 ?'checked' :''} onChange={(e)=>this.setState({other:e.target.checked})}/>
                                                       {/*      &nbsp; <img src="dist/img/other.png" width="15px;"/> */}&nbsp; Other 
                                                         </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox17">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox17" value={this.state.tele_conf}
                                                            checked={this.state.tele_conf==1 ?'checked' :''} onChange={(e)=>this.setState({tele_conf:e.target.checked})}/>
                                                       {/*      &nbsp; <img src="dist/img/tele-conference.png" width="15px;"/>*/} &nbsp; TeleConference 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox18">
                                                        <input className="border-checkbox" type="checkbox" id="checkbox18" value={this.state.tele_consultant}
                                                            checked={this.state.tele_consultant==1 ?'checked' :''} onChange={(e)=>this.setState({tele_consultant:e.target.checked})}/>
                                                     {/*        &nbsp; <img src="dist/img/telehealth-consultant.png" width="15px;"/> */}&nbsp; Telehealth Consultant
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox19">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox19" value={this.state.worker_comps}
                                                            checked={this.state.worker_comps==1 ?'checked' :''} onChange={(e)=>this.setState({worker_comps:e.target.checked})}/>
                                                          {/*   &nbsp; <img src="dist/img/worker-comps.png" width="15px;"/>*/} &nbsp; Worker Comps 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-distance"> </div>
                                <div className="row row-distance"> </div>
                                <div className="row">		
                                    <label className="col-sm-12 col-form-label text-left" style={{fontSize:'16px', fontWeight:'bold'}}> Appointment attendance automation </label>
                                </div>
                                <div className="row m-b-0">
                                    <div className="col-sm-12">
                                        <div className="border-checkbox-section">
                                            <div className="border-checkbox-group border-checkbox-group-default">
                                                <label className="border-checkbox-label" for="checkbox20">
                                                    <input className="border-checkbox" type="checkbox" id="checkbox20" value={this.state.checkreplies} onChange={(e)=>this.enable2(e.target.checked)}
                                                    checked={this.state.checkreplies==1 ? 'checked' : ''}/>
                                                    &nbsp;  Check for replies to Appointment Reminders 
                                                    <span className="red"> (Replies are retrieved by Bp Service every 5 minutes) </span> 
                                                </label>
                                            </div>
                                        </div>			
                                    </div>
                                </div>
                                <div className="row m-b-0">
                                    <div className="col-sm-12">
                                        <div className="border-checkbox-section">
                                            <div className="border-checkbox-group border-checkbox-group-default">
                                                <label className="border-checkbox-label" for="checkbox21">
                                                    <input className="border-checkbox" type="checkbox" id="checkbox21" disabled={this.state.disable2} value={this.state.confirmauto} onChange={(e)=>this.enable3(e.target.checked)}
                                                    checked={this.state.confirmauto==1 ? 'checked' : ''}/>
                                                        &nbsp; Automatically confirm or decline appointment attendance when the patient replies with certain phrases <br/>
                                                        (Default attendance replies are YES and NO)
                                                    </label>
                                            </div>
                                        </div>			
                                    </div>
                                </div>
                                <div className="row m-b-0">
                                    <div className="col-sm-12">
                                        <button type="button" className="btn btn-default" data-toggle="modal" data-target="#configure-appointment-btn" style={{marginTop:'10px'}}
                                        disabled={this.state.disable3} onClick={()=>this.setState({addModal:true})}> Configure appointment attendance replies </button>	
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="modal-footer">
                            <div className="row">
                                <div className="col-sm-10"> </div>
                                <div className="col-sm-2">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.saveSetting()}>Save</button>
                                    <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close">Cancel</button> 
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(this.state.addModal) ?
                <AttendenceReplies showModal={this.state.addModal}  changeKey={this.reloadKey}/> :''
                }
            </section>
           
        </div>
    )}
}

const mapDispatchToProps = dispatch => {
    return {
        updatesetting:data=>dispatch(update_setting(data)),
        apigetsettings:(data)=>dispatch(api_get_settings(data)),

            };
  };    
  const mapStateToProps = state => {
     let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
 
   return {
   redux_docId
   };
 };
 export default connect(mapStateToProps,mapDispatchToProps) (AppointmentReminders);