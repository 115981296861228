import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {api_add_cancel_reason,api_list_reason,api_update_cancel_reason} from '../../../DS/DS.ListPage';
class AddCancellation extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        cancellation_reason:'',
        errors:[],
    }
  }
  componentWillMount=()=>
  {
    if(this.props.popFor=='Edit')
    {
        const getData={tbl:'appointment_cancel_reason_tb',pk:'cancel_reason_id',ids:this.props.ids}
        this.props.listCancelReason(getData).then(
            res=>{
                this.setState({cancellation_reason:res.data.content.reason_name})
            }
        )
    }
  }
  handleValidation(){
    let errors = {};
    let formIsValid = true;
  
    //Reason name
    if(!this.state.cancellation_reason){
       formIsValid = false;
       errors["cancellation_reason"] = "Cannot be empty";
    }
     this.setState({errors: errors});
    return formIsValid;
  }
  onClickHandler=(e)=>
  {
    e.preventDefault();
    if(this.handleValidation()){
        const reasonData={
                reason:this.state.cancellation_reason,
                user_id:this.props.redux_userId,
              }
                this.props.addCancelReason(reasonData).then(
                  req=>
                  {
                        if(req.data.status=="Duplicate"){
                           
                             NotificationManager.error('Duplicate cancellation reason record cannot be created',)
                        }
                        else if(req.data.status=="Success")
                        {
                          
                             NotificationManager.success('New cancellation reason created',)
                        }
                        else{
                            
                            NotificationManager.error('Error encountered while creating record',)
                        }
                        this.props.reloadPage()  
                  }
                         
                     
                      
              );
    }
} 
updateRecord=(e)=>
{
    e.preventDefault();
    if(this.handleValidation()){
        const reasonData={
                reason:this.state.cancellation_reason,
                userid:this.props.redux_userId,
                ids:this.props.ids
              }
                this.props.UpdateCancelReason(reasonData).then(
                  req=>{
                    if(req.data.status=="Duplicate"){
                       
                         NotificationManager.error('Duplicate cancellation reason record cannot be created',)
                    }
                    else if(req.data.status=="Success")
                    {
                      
                         NotificationManager.success('Cancellation reason modified',)
                    }
                    else{
                        
                        NotificationManager.error('Error encountered while modifying record',)
                    }
                    this.props.reloadPage()  
              }
              );
    }
}
 render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
            <div>
               <div className="modal-header">
                            <span className="modal-title-2" id="exampleModalLabel"> Reason for cancellation  </span>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.reloadPage()}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <div className="col-sm-2"> 
                                            <label> Reason : </label>
                                        </div>
                                        <div className="col-sm-10 cursor">
                                            <input type="text" name="text" className="form-control" value={this.state.cancellation_reason} onChange={(e)=>this.setState({cancellation_reason:e.target.value})}/>
                                            <span className="error" style={{color:'red'}}>{this.state.errors["cancellation_reason"]}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="row">
                                <div className="col-sm-8"> </div>
                                    <div className="col-sm-4">
                                        {this.props.popFor=='Add' ?
                                        <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.onClickHandler(e)}>Save</button>
                                        : <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.updateRecord(e)}>Save</button>}
                                        <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.reloadPage()}>Cancel</button> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </Popup>
        )}
    }    

const mapDispatchToProps = dispatch => {
    return {
        addCancelReason: data => dispatch(api_add_cancel_reason(data)),
        listCancelReason : data => dispatch(api_list_reason(data)),
        UpdateCancelReason: data => dispatch(api_update_cancel_reason(data))
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(AddCancellation);
  
