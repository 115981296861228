import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import {api_visitnotes_list_options} from './../../../../DS/DS.VisitsNotes';
import { connect } from "react-redux";
import Showhelp from './../../../showHelp';

class gynae extends Component {
  state = { 
    list_options: {}
  }
  componentDidMount() {
    this.props.visitnotes_list_options().then(res => {
      //   console.log("addPatientForm addPatientForm",res);
      this.setState({ list_options: res.data.content });
      console.log(this.state.list_options);
    });
  }
    render() {
        return (
            <Popup trigger={<a  title="Gynae" className="collapsed" aria-expanded="false"><span className="gynae"></span></a>} position="right center"   modal
            closeOnDocumentClick={false}
           >
             {close => (
              <div style={{ width:'800px', margin:'0px auto'}} >
                 <div className="eyelay" style={{ width:'800px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>

               <div className="modal-header" 
              //  style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
               >

                      <button type="button" className="close" style={{ marginTop: '0px',fontSize: '25px' }} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span> Gyane <Showhelp gotohelp="gyane_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Eye</h4> */}
                    </div>
                    <div className="modal-body" style={{ border:'0px' }}>
                        <div className="col-sm-12">
						<h4 style={{}}>History:</h4>
						</div>


                        <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Pv bleeding</label>
                        </div>
                        <div className="col-sm-3 pr-0">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.PvBleeding === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PvBleeding")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.PvBleeding === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PvBleeding")}
                    /> No</label>
                      &nbsp; &nbsp;
                    </div>
                    <div className="col-sm-3 pr-0">
                            <label>Pv discharge</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.PvDischarge === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PvDischarge")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.PvDischarge === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PvDischarge")}
                    /> No</label>
                      &nbsp; &nbsp;
                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Amenorrhoea</label>
                        </div>
                        <div className="col-sm-3 pr-0">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Amenorrhoea === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "Amenorrhoea")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Amenorrhoea === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "Amenorrhoea")}
                    /> No</label>
                      &nbsp; &nbsp;
                        </div>
                    <div className="col-sm-3 pr-0">
                            <label>Dysmenorrhoea</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Dysmenorrhoea === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "Dysmenorrhoea")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Dysmenorrhoea === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "Dysmenorrhoea")}
                    /> No</label>
                      &nbsp; &nbsp;
                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Vulval Itch</label>
                        </div>
                        <div className="col-sm-3 pr-0">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.VulvalItch === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "VulvalItch")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.VulvalItch === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "VulvalItch")}
                    /> No</label>
                      &nbsp; &nbsp;
                        </div>
                 
                    <div className="col-sm-3 pr-0">
                            <label>Intermenstrutal Bleeding</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.IntermenstrutalBleeding === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "IntermenstrutalBleeding")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.IntermenstrutalBleeding === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "IntermenstrutalBleeding")}
                    /> No</label>
                      &nbsp; &nbsp;
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Post-Coital Bleeding </label>
                        </div>
                        <div className="col-sm-3 pr-0">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.PostCoitalBleeding === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PostCoitalBleeding")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.PostCoitalBleeding === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PostCoitalBleeding")}
                    /> No</label>
                      &nbsp; &nbsp;
                        </div>
                   
                    <div className="col-sm-3 pr-0">
                            <label>Post Menopausal Bleeding</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.PostMenopausalBleeding === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PostMenopausalBleeding")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.PostMenopausalBleeding === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PostMenopausalBleeding")}
                    /> No</label>
                      &nbsp; &nbsp;
                      </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                    <label> Dyspareunia</label>
                  </div>
                  <div className="col-sm-3 pr-0">
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Dyspareunia === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "Dyspareunia")}
                    /> Yes</label> 
                      &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Dyspareunia === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "Dyspareunia")}
                    /> No</label>
                   </div>
                  <div className="col-sm-3 pr-0">
                    <label> Unprotected Intercourse</label>
                  </div>
                  <div className="col-sm-3 pr-0">
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.UnprotectedIntercourse === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "UnprotectedIntercourse")}
                    /> Yes</label> 
                      &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.UnprotectedIntercourse === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "UnprotectedIntercourse")}
                    /> No</label>
                   </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                    <label>Heavy Periods</label>
                  </div>
                  <div className="col-sm-3 pr-0">
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.HeavyPeriods === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "HeavyPeriods")}
                    /> Yes</label> 
                      &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.HeavyPeriods === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "HeavyPeriods")}
                    /> No</label>
                   </div>
              
                  <div className="col-sm-3 pr-0">
                    <label>Irregular Period</label>
                  </div>
                  <div className="col-sm-3 pr-0">
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.IrregularPeriod === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "IrregularPeriod")}
                    /> Yes</label> 
                      &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.IrregularPeriod === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "IrregularPeriod")}
                    /> No</label>
                   </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                    <label>Pregnancy Test Positive</label>
                  </div>
                  <div className="col-sm-3 pr-0">
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.PregnancyTestPositive === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PregnancyTestPositive")}
                    /> Yes</label> 
                      &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.PregnancyTestPositive === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PregnancyTestPositive")}
                    /> No</label>
                   </div>
                </div>

				<div className="col-sm-12">
                <h4 style={{}}>Examination:</h4>
				</div>

                
               
               <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Vaginal Discharge</label>
                        </div>
                        <div className="col-sm-3 pr-0">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.VaginalDischarge == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "VaginalDischarge")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox" 
                      value="No"
                      checked={this.props.vals_examination.VaginalDischarge == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "VaginalDischarge")}
                    /> No</label>
                      &nbsp; &nbsp;
                    </div>
                    
                    <div className="col-sm-3 pr-0">
                            <label>Cervix Suspicious</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.CervixSuspicious == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CervixSuspicious")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.CervixSuspicious == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CervixSuspicious")}
                    /> No</label>
                      &nbsp; &nbsp;
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>CST Performed</label>
                        </div>
                        <div className="col-sm-3 pr-0">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.CSTPerformed == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CSTPerformed")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.CSTPerformed == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CSTPerformed")}
                    /> No</label>
                      &nbsp; &nbsp;
                        </div>
                    <div className="col-sm-3 pr-0">
                            <label>Cervical Polyp</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.CervicalPolyp == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CervicalPolyp")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.CervicalPolyp == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CervicalPolyp")}
                    /> No</label>
                      &nbsp; &nbsp;
                  
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Cervical Ectopy</label>
                        </div>
                        <div className="col-sm-3 pr-0">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.CervicalEctopy == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CervicalEctopy")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Pterygium == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CervicalEctopy")}
                    /> No</label>
                      &nbsp; &nbsp;
                        </div>
                    <div className="col-sm-3 pr-0">
                            <label>Cystocele</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Cystocele == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "Cystocele")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Cystocele == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "Cystocele")}
                    /> No</label>
                      &nbsp; &nbsp;
                  
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Rectocele</label>
                        </div>
                        <div className="col-sm-3 pr-0">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Rectocele == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "Rectocele")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Rectocele == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "Rectocele")}
                    /> No</label>
                      &nbsp; &nbsp;
                        </div>
                
                  <div className="col-sm-3 pr-0">
                            <label>Bulky Uterus</label>
                        </div>
                        <div className="col-sm-3 pr-0">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.BulkyUterus == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "BulkyUterus")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.BulkyUterus == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "BulkyUterus")}
                    /> No</label>
                      &nbsp; &nbsp;
                        </div>

                  <div className="col-sm-3 pr-0">
                            <label>Uterine Tenderness</label>
                        </div>
                        <div className="col-sm-3 pr-0">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.UterineTenderness == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "UterineTenderness")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.UterineTenderness == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "UterineTenderness")}
                    /> No</label>
                      &nbsp; &nbsp;
                        </div>

                  <div className="col-sm-3 pr-0">
                            <label>Retroverted Uterus</label>
                        </div>
                        <div className="col-sm-3 pr-0">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.RetrovertedUterus == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "RetrovertedUterus")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.RetrovertedUterus == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "RetrovertedUterus")}
                    /> No</label>
                      &nbsp; &nbsp;
                 
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Forniceal Tenderness</label>
                        </div>
                        <div className="col-sm-3 pr-0">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.FornicealTenderness == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "FornicealTenderness")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.FornicealTenderness == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "FornicealTenderness")}
                    /> No</label>
                      &nbsp; &nbsp;
                  
                        </div>

                  <div className="col-sm-3 pr-0">
                            <label>Forniceal Mass</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.FornicealMass == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "FornicealMass")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.FornicealMass == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "FornicealMass")}
                    /> No</label>

                        </div>

                      
      




            </div>

</div>


                <div className="modal-footer"   >
                      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"
                       onClick={e => 
                        {this.props.handleSaveOptions(e, "gynae");
                        close();}
                        }
                        >Save</button>
                      <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>
                    </div>

            </div>

)}
</Popup>
         );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    visitnotes_list_options: data => dispatch(api_visitnotes_list_options(data)),
    
  };
};

export default connect(null, mapDispatchToProps)(gynae);