import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import {api_visitnotes_list_options} from './../../../../DS/DS.VisitsNotes';
import { connect } from "react-redux";
import Showhelp from './../../../showHelp';

class eye extends Component {
  state = { 
    list_options: {}
  }
  componentDidMount() {
    this.props.visitnotes_list_options().then(res => {
      //   console.log("addPatientForm addPatientForm",res);
      this.setState({ list_options: res.data.content });
      console.log(this.state.list_options);
    });
  }
    render() {
        return (
            <Popup trigger={<a  title="Eye" className="collapsed" aria-expanded="false"><span className="eye"></span></a>} position="right center"   modal
            closeOnDocumentClick={false}
           >
             {close => (
                <div style={{ width:'800px', margin:'0px auto'}} >
                 <div className="eyelay" style={{ width:'800px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>

               <div className="modal-header" 
              //  style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
               >

                      <button type="button" className="close" style={{marginTop: '0px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span> EYE <Showhelp gotohelp="eye_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Eye</h4> */}
                    </div>
                    <div className="modal-body" style={{ border:'0px' }}>
                        
                    <div className="col-sm-12">
                        <h4 style={{}}>History:</h4>
                        </div>


                        <div className="col-sm-12">
                  <div className="col-sm-2 pr-0">
                            <label>Visual deterioration</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.VisualDeterioration === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.VisualDeterioration === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.VisualDeterioration === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                    /> Neither</label>
                        </div>
                    <div className="col-sm-2 ">
                            <label>Visual loss</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.VisualLoss === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "VisualLoss")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.VisualLoss === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "VisualLoss")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.VisualLoss === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "VisualLoss")}
                    /> Neither</label>

                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Something in eye</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.SomethingInEye === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "SomethingInEye")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.SomethingInEye === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "SomethingInEye")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.SomethingInEye === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "SomethingInEye")}
                    /> Neither</label>
                        </div>
                    <div className="col-sm-2 ">
                            <label>Itchy eye</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.ItcyEye === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "ItcyEye")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.ItcyEye === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "ItcyEye")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.ItcyEye === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "ItcyEye")}
                    /> Neither</label>

                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Red eye</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.RedEye === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "RedEye")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.RedEye === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "RedEye")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.RedEye === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "RedEye")}
                    /> Neither</label>
                        </div>
                    <div className="col-sm-2 ">
                            <label>Sore eye</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.SoreEye === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "SoreEye")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.SoreEye === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "SoreEye")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.SoreEye === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "SoreEye")}
                    /> Neither</label>

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Watering eye </label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.WateringEye === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "WateringEye")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.WateringEye === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "WateringEye")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.WateringEye === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "WateringEye")}
                    /> Neither</label>
                        </div>
                    <div className="col-sm-2 ">
                            <label>Eye discharge</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.EyeDischarge === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "EyeDischarge")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.EyeDischarge === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "EyeDischarge")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.EyeDischarge === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "EyeDischarge")}
                    /> Neither</label>

                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label> Double vision </label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.DoubleVision === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "DoubleVision")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.DoubleVision === "No"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "DoubleVision")}
                    /> No</label>

                        </div>

                        <div className="col-sm-4">


                        </div>
                </div>


                <div className="col-sm-12">
                <h4 style={{}}>Examination:</h4>
                </div>

                <div className="col-sm-12 ">
                            <div className="col-sm-2 pr-0 mt-2">
                            <label>Visual acuity:</label>
                        </div>

                        <div className="col-sm-2 pr-0 mt-2">
                        <label>Without glasses:</label>
                        </div>
                        <div className="col-sm-1 pr-0 mt-2">
                        <label>Right</label>
                        </div>
                        <div className="col-sm-1 pl-0 pr-0 ">
                        <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                        value={this.props.vals_examination.withoutglassesright}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "withoutglassesright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.withoutglasses_right != null &&
                          this.state.list_options.withoutglasses_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                          
                            </select>
                        </div>
                        <div className="col-sm-1 pr-0 mt-2">
                        <label>Left</label>
                        </div>
                        <div className="col-sm-1 pl-0 pr-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.withoutglassesLeft}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "withoutglassesLeft")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.withoutglasses_Left != null &&
                          this.state.list_options.withoutglasses_Left.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>

                        <div className="col-sm-1 pr-0 mt-2">
                        <label>Both</label>
                        </div>

                        <div className="col-sm-1 pl-0 pr-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.withoutglassesboth}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "withoutglassesboth")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.withoutglasses_right != null &&
                          this.state.list_options.withoutglasses_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>


               </div>
               <div className="col-sm-12 ">

                            <div className="col-sm-2 pr-0 mt-2">

                        </div>

                        <div className="col-sm-2 pr-0 mt-2">
                        <label>With glasses:</label>
                        </div>
                        <div className="col-sm-1 pr-0 mt-2">
                        <label>Right</label>
                        </div>
                        <div className="col-sm-1 pl-0 pr-0">
                        <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                        value={this.props.vals_examination.withglassesright}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "withglassesright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.withoutglasses_right != null &&
                          this.state.list_options.withoutglasses_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-1 pr-0 mt-2">
                        <label>Left</label>
                        </div>
                        <div className="col-sm-1 pl-0 pr-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.withglassesLeft}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "withglassesLeft")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.withoutglasses_right != null &&
                          this.state.list_options.withoutglasses_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>

                        <div className="col-sm-1 pr-0 mt-2">
                        <label>Both</label>
                        </div>

                        <div className="col-sm-1 pl-0 pr-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.withglassesboth}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "withglassesboth")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.withoutglasses_right != null &&
                          this.state.list_options.withoutglasses_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>


               </div>
               <div className="col-sm-12 ">

                            <div className="col-sm-2 pr-0 mt-2">

                        </div>

                        <div className="col-sm-2 pr-0 mt-2">
                        <label>Foreign body:</label>
                        </div>
                        <div className="col-sm-1 pr-0 mt-2">
                        <label>Right</label>
                        </div>
                        <div className="col-sm-1 pl-0 pr-0">
                        <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                        value={this.props.vals_examination.Foreignbody}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "withglassesright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.foreignbody_right != null &&
                          this.state.list_options.foreignbody_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-1 pr-0 mt-2">
                        <label>Left</label>
                        </div>
                        <div className="col-sm-1 pl-0 pr-0">
                            <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                            value={this.props.vals_examination.foreignbody_right}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "foreignbodyright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.foreignbody_right != null &&
                          this.state.list_options.foreignbody_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>



               </div>


               <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Redness</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Redness == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Redness")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox" 
                      value="Left"
                      checked={this.props.vals_examination.Redness == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Redness")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.Redness == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Redness")}
                    /> Neither</label>
                        </div>
                    <div className="col-sm-2 ">
                            <label>Discharge</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Discharge == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Discharge")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Discharge == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Discharge")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Discharge == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Discharge")}
                    /> Neither</label>
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Swelling</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Swelling == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Swelling")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Swelling == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Swelling")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Swelling == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Swelling")}
                    /> Neither</label>
                        </div>
                    <div className="col-sm-2 ">
                            <label>Ulcer</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Ulcer == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Ulcer")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Ulcer == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Ulcer")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Ulcer == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Ulcer")}
                    /> Neither</label>

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Pterygium</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Pterygium == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Pterygium")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Pterygium == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Pterygium")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Pterygium == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Pterygium")}
                    /> Neither</label>
                        </div>
                    <div className="col-sm-2 ">
                            <label>Ingrowing lashes</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.IngrowingLashes == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "IngrowingLashes")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.IngrowingLashes == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "IngrowingLashes")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.IngrowingLashes == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "IngrowingLashes")}
                    /> Neither</label>

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Ectropion</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Ecropion == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Ecropion")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Ecropion == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Ecropion")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Ecropion == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Ecropion")}
                    /> Neither</label>
                        </div>
                  <div className="col-sm-2 ">
                            <label>Entropion</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Entropion == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Entropion")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Entropion == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Entropion")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Entropion == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Entropion")}
                    /> Neither</label>
                        </div>

                  <div className="col-sm-2 ">
                            <label>Hyphaema</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Hyphaema == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Hyphaema")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Hyphaema == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Hyphaema")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Hyphaema == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Hyphaema")}
                    /> Neither</label>
                        </div>

                  <div className="col-sm-2 ">
                            <label>Cataract</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Cataract == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Cataract")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Cataract == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Cataract")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Cataract == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Cataract")}
                    /> Neither</label>
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Papilloedema</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Papilloedema == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Papilloedema")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Papilloedema == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Papilloedema")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Papilloedema == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Papilloedema")}
                    /> Neither</label>
                        </div>

                  <div className="col-sm-2 ">
                            <label>Colour vision</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Normal"
                      checked={this.props.vals_examination.ColourVision == "Normal"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "ColourVision")}
                    /> Normal </label>
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Abnormal"
                      checked={this.props.vals_examination.ColourVision == "Abnormal"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "ColourVision")}
                    /> Abnormal</label>

                        </div>

                      
                        
                </div>


                <div className="col-sm-12 ">

                         <div className="col-sm-2 pr-0 mt-2">
                         <label>Retinopathy:</label>
                     </div>

                     <div className="col-sm-1 pr-0 mt-2">
                     <label>Right:</label>
                     </div>

                     <div className="col-sm-1 pl-0 pr-0">
                     <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                     value={this.props.vals_examination.Retinopathyright}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "Retinopathyright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Retinopathy_right != null &&
                          this.state.list_options.Retinopathy_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                         </select>
                     </div>
                     <div className="col-sm-1 pr-0 mt-2">
                     <label>Left</label>
                     </div>
                     <div className="col-sm-1 pl-0 pr-0">
                         <select style={{width:"100%"}} className="form-control form-control-sm mt-0"
                         value={this.props.vals_examination.Retinopathyleft}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "Retinopathyleft")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Retinopathy_right != null &&
                          this.state.list_options.Retinopathy_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                         </select>
                     </div>






            </div>

</div>


                <div className="modal-footer"   >
                      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"
                       onClick={e => 
                        {this.props.handleSaveOptions(e, "eye");
                        close();}
                        }
                        >Save</button>
                      <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>

                    </div>
            </div>

)}
</Popup>
         );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    visitnotes_list_options: data => dispatch(api_visitnotes_list_options(data)),
    
  };
};

export default connect(null, mapDispatchToProps)(eye);