
import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import {api_add_new_Pregnancy,api_get_pregnancy_no,api_get_pregnancy_data} from '../../../DS/DS.ObstetricHistory'
import { api_getDoctors } from '../../../DS/DS.Appointments';
import BabyDetails from './babyDetails';
import Showhelp from './../../showHelp';


class PregnancyOutcomeDetails extends Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            open:this.props.showmodal,
            babyDetails:false,
            key:0,
            outdesc:'',
            selectedplacenta:0,
            selectedperineum:0,
            selectedonset:0,
            selectedloss:0,
            selectedDelivery:0,
            selectedaug:0,
            selectedana:0,
            stage1:'8 hrs',
            stage2:'4 hrs',
            stage3:'7 mins',
            prevData:this.props.followup,
            antid:''
        }
    }
    componentWillMount=()=>{
        if(this.props.followup.pregnancy_id!='')
        {
        this.props.getPregnancyData({id:this.props.followup.pregnancy_id}).then(res=>{
            this.setState({outdesc:res.data.content[0].PregnancyNote,
            selectedplacenta:res.data.content[0].Placenta,
            selectedperineum:res.data.content[0].Perineum,
            selectedonset:res.data.content[0].Onset,
            selectedloss:res.data.content[0].BloodLoss,
            selectedDelivery:res.data.content[0].delivery,
            selectedaug:res.data.content[0].Augmentation,
            selectedana:res.data.content[0].Analgesia,
            stage1:res.data.content[0].Stage1,
            stage2:res.data.content[0].Stage2,
            stage3:res.data.content[0].Stage3,
            })
        })
    }
    }
    closePopUp=()=>
    {
        this.setState({key:Math.random(),babyDetails:false})
    }
    closeAll=()=>
    {
        this.setState({babyDetails:false})
        this.props.closeAll()
    }
    render(){
        return(
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div style={{ width:'600px', margin:'0px auto'}}>
                <div style={{ width:'600px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                    <div className="popup-title">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}  style={{ position: "inherit", right: "10px", fontSize: '25px' }}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <span>Pregnancy outcome <Showhelp gotohelp="pregnancy_outcome_details_help"/></span></div>
                    </div>
                   <div className="modal-body newpostpopup" style={{ fontSize: "14px" }}>
                        <div className="col-sm-12" style={{marginBottom: 10, textAlign: 'left'}}>
                            <div className="row">
                                <div className="col-sm-2 pt-3"><label className="col-form-label text-left">Length:</label></div>
                                <div className="col-sm-10 pl-0">
                                    <div className="col-sm-2 pt-3 pr-0"><label className="col-form-label text-left">Stage 1:</label></div>
                                    <div className="col-sm-2 pr-0 pl-0"><input type="text" name="search_condition" className="form-control" value={this.state.stage1} onChange={(e)=>this.setState({stage1:e.target.value})} /></div>
                                    <div className="col-sm-2 pt-3 pr-0"><label className="col-form-label text-left">Stage 2:</label></div>
                                    <div className="col-sm-2 pr-0 pl-0"><input type="text" name="search_condition" className="form-control" value={this.state.stage2} onChange={(e)=>this.setState({stage2:e.target.value})}/></div>
                                    <div className="col-sm-2 pt-3 pr-0"><label className="col-form-label text-left">Stage 3:</label></div>
                                    <div className="col-sm-2 pr-0 pl-0"><input type="text" name="search_condition" className="form-control" value={this.state.stage3} onChange={(e)=>this.setState({stage3:e.target.value})}/></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 pt-3"><label className="col-form-label text-left">Delivery:</label></div>
                                <div className="col-sm-8">
                                        <select class="form-control" value={this.state.selectedDelivery} onChange={(e)=>this.setState({selectedDelivery:e.target.value})}>
                                            <option value="0">Select</option>
                                            <option value="Normal vaginal">Normal vaginal</option>
                                            <option value="Breech vaginal">Breech vaginal</option>
                                            <option value="Rotation forceps">Rotation forceps</option>
                                            <option value="Forceps">Forceps</option>
                                            <option value="Vacuum extraction">Vacuum extraction</option>
                                            <option value="Elective caesarean section">Elective caesarean section</option>
                                            <option value="Emergency caesarean section">Emergency caesarean section</option>
                                            
                                        </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 pt-3"><label className="col-form-label text-left">Onset:</label></div>
                                <div className="col-sm-3">
                                    <select class="form-control" value={this.state.selectedonset} onChange={(e)=>this.setState({selectedonset:e.target.value})}>
                                        <option value="0">Select</option>
                                        <option value="Spontaneous">Spontaneous</option>
                                        <option value="A.R.M">A.R.M</option>
                                        <option value="Vaginal prostaglanding">Vaginal prostaglanding</option>
                                    </select>
                                </div>
                                <div className="col-sm-2 pt-3"><label className="col-form-label text-left">Augmentatiom:</label></div>
                                <div className="col-sm-3">
                                    <select class="form-control" value={this.state.selectedaug} onChange={(e)=>this.setState({selectedaug:e.target.value})}>
                                        <option value="0">Select</option>
                                        <option value="Nil">Nil</option>
                                        <option value="Syntocinon infusion">Syntocinon infusion</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 pt-3"><label className="col-form-label text-left">Analgesia:</label></div>
                                <div className="col-sm-3">
                                    <select class="form-control" value={this.state.selectedana} onChange={(e)=>this.setState({selectedana:e.target.value})}>
                                        <option value="0">Select</option>
                                        <option value="Nil">Nil</option>
                                        <option value="NO2 only">NO2 only</option>
                                        <option value="Narcotics">Narcotics</option>
                                        <option value="Epidural">Epidural</option>
                                    </select>
                                </div>
                                <div className="col-sm-2 pt-3"><label className="col-form-label text-left">Perineum:</label></div>
                                <div className="col-sm-3">
                                    <select class="form-control" value={this.state.selectedperineum} onChange={(e)=>this.setState({selectedperineum:e.target.value})}>
                                        <option value="0">Select</option>
                                        <option value="Intact">Intact</option>
                                        <option value="Episiotomy">Episiotomy</option>
                                        <option value="1st degree tear">1st degree tear</option>
                                        <option value="2nd degree tear">2nd degree tear</option>
                                        <option value="3rd degree tear">3rd degree tear</option>
                                        <option value="4th degree tear">4th degree tear</option>

                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 pt-3"><label className="col-form-label text-left">Placenta:</label></div>
                                <div className="col-sm-3">
                                    <select class="form-control" value={this.state.selectedplacenta} onChange={(e)=>this.setState({selectedplacenta:e.target.value})}>
                                        <option value="0">Select</option>
                                        <option value="Spontaneous deivery">Spontaneous deivery</option>
                                        <option value="Episiotomy">Episiotomy</option>
                                        <option value="Manual removal">Manual removal</option>
                                        <option value="Manual removal">Retained</option>
                                        
                                    </select>
                                </div>
                                <div className="col-sm-2 pt-3"><label className="col-form-label text-left">Blood loss:</label></div>
                                <div className="col-sm-3">
                                    <select class="form-control" value={this.state.selectedloss} onChange={(e)=>this.setState({selectedloss:e.target.value})}>
                                        <option value="0">Select</option>
                                        <option value="less then 500ml"> less then 500ml</option>
                                        <option value="500 - 1000ml">500 - 1000ml</option>
                                        <option value="more then 1000ml">more then 1000ml</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 pt-1"><label className="col-form-label text-left">Anti-D:</label></div>
                                <div className="col-sm-1 pt-1 pl-0 pr-0 ml-2"><label><input type="checkbox" name="search_condition" value="1" onChange={(e)=>this.setState({antid:e.target.value})}
                                checked={this.state.antid==1?'checked' :''}/> Yes</label></div>
                                <div className="col-sm-2 pt-1 pl-0"><label><input type="checkbox" name="search_condition" value="2" onChange={(e)=>this.setState({antid:e.target.value})}
                                checked={this.state.antid==2?'checked' :''}/> No</label></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 pt-3"><label className="col-form-label text-left">Notes:</label></div>
                                <div className="col-sm-8 mt-3"><textarea style={{width: '100%', height: 80, float: 'left', border: '1px solid rgb(221, 221, 221)', padding: 10}} defaultValue={""} onChange={(e)=>this.setState({outdesc:e.target.value})} value={this.state.outdesc}/></div>
                            </div>
                        </div>
                        {this.state.babyDetails ?
                            <BabyDetails showmodal={this.state.babyDetails} changeKey={this.closePopUp} prevpregdetailsprev={this.props.followup} followup={this.state} closeAll={this.closeAll}/>:''}
                  
                    </div>
                    <div className="modal-footer">
                        <button type="button"  onClick={() =>this.props.changeKey()} className="btn btn-primary btn-xs" data-dismiss="modal">Back</button>
                        <button type="button"  onClick={() => this.setState({babyDetails:true})} className="btn btn-primary btn-xs" data-dismiss="modal">Next</button>
                        <button type="button"  onClick={() => this.props.changeKey()} className="btn btn-primary btn-xs" data-dismiss="modal">Cancel</button>
                    </div>      
                </div>   
                </div>     
            )}     
        </Popup>
        )}
    }   
    const mapDispatchToProps = dispatch => {
        return {
            getPregnancyData: (data) => dispatch(api_get_pregnancy_data(data)),
               };
        };
    const mapStateToProps = state => {
        let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
        let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
     
      return {
        redux_patientId,redux_docId
      };
     };
     
     export default connect(
     mapStateToProps,mapDispatchToProps
     )
   (PregnancyOutcomeDetails); 