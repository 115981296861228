import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import {api_get_doctors,api_add_provider,api_get_data,api_update_data} from '../../../DS/DS.BankAccount';
class AddProviderAccount extends Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            open:this.props.showmodal,
            key:0,
            all_doc:[],
            selected_doc:0,
            bank_name:1,
            account_name:'',
            account_number:'',
            bsb_number:'',
            mid:''

        }
    }
    componentWillMount=()=>
    {
       this.props.getDoctors().then(
          req=> {
            if(req.data.content!='')
            {
               this.setState({all_doc:req.data.content,selected_doc:req.data.content[0].doctor_id})

            }
         }
       )
       if(this.props.sid!='')
       {
          const provideId={ids:this.props.sid}
          this.props.getData(provideId).then(
             req=>
             {
                this.setState({selected_doc:req.data.content[0].provider,bank_name:req.data.content[0].bank_name,
               account_name:req.data.content[0].account_name,account_number:req.data.content[0].account_no
               ,bsb_number:req.data.content[0].bsb_no,mid:req.data.content[0].mid})
             }
          )
       }

    }
    saveClickHandler=()=>
    {

       if(this.props.sid=='')
       {
         const values={
            provide:this.state.selected_doc,
            bank:this.state.bank_name,
            accnm:this.state.account_name,
            accno:this.state.account_number,
            bsb:this.state.bsb_number,
            mid:this.state.mid,
            logby:this.props.redux_docId,
            account:2
         }

            this.props.saveRecord(values).then(
               req=>{
                  if(req.data.status=="Success")
                  {
                     NotificationManager.success('New provider account created successfully')
                  }
                  if(req.data.status=="Duplicate")
                  {
                     NotificationManager.error("Duplicate record cannot be created")
                  }
                  if(req.data.status=="Error")
                  {
                     NotificationManager.error("'Error encountered while creating record")
                  }
                  this.props.changeKey();
               }

            )
       }
       else
       {
         const values={
            provide:this.state.selected_doc,
            bank:this.state.bank_name,
            accnm:this.state.account_name,
            accno:this.state.account_number,
            bsb:this.state.bsb_number,
            mid:this.state.mid,
            logby:this.props.redux_docId,
            ids:this.props.sid
         }
         this.props.updateRecord(values).then(
            req=>{
               if(req.data.status=="Success")
               {
                  NotificationManager.success('Provider account details modified')
               }
               if(req.data.status=="Duplicate")
               {
                  NotificationManager.error("Duplicate record cannot be created")
               }
               if(req.data.status=="Error")
               {
                  NotificationManager.error("'Error encountered while creating record")
               }
               this.props.changeKey();
            }

         )
       }
    }
   render (){
      return(
          <Popup open={this.state.open} closeOnDocumentClick={false} position="right center"   modal>
         {close => (
           <div>
            <div class="modal-header">
               <span class="modal-title-2" id="exampleModalLabel"> Bank account details  </span>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}>
                  <span aria-hidden="true">×</span>
               </button>
            </div>
            <div class="modal-body">
               <div class="row row-distance">
                  <div class="col-sm-11">
                     <div class="form-group">
                        <div class="col-sm-4">
                           <label> Provider : </label>
                        </div>
                        <div class="col-sm-8 cursor">
                           <select class="dropdown-box" onChange={(e)=>this.setState({selected_doc:e.target.value})} value={this.state.selected_doc}>
                                {this.state.all_doc != undefined && this.state.all_doc.length> 0 && this.state.all_doc.map((doc) =>
                                    <option key={doc.doctor_id} value={doc.doctor_id}>Dr. {doc.Firstname} {doc.Lastname}</option>)}

                           </select>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-1"></div>
               </div>
               <div class="row row-distance">
                  <div class="col-sm-11">
                     <div class="form-group">
                        <div class="col-sm-4">
                           <label> Bank name : </label>
                        </div>
                        <div class="col-sm-8 cursor">
                           <select class="dropdown-box">
                              <option value="1"> Medical Bank of Australia </option>
                           </select>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-1"></div>
               </div>
               <div class="row row-distance">
               </div>
               <div class="row row-distance">
               </div>
               <div class="row row-distance">
                  <div class="col-sm-11">
                     <div class="form-group">
                        <div class="col-sm-4">
                           <label> Account name : </label>
                        </div>
                        <div class="col-sm-8 cursor">
                           <input type="text" name="text" class="form-control" value={this.state.account_name} onChange={(e)=>this.setState({account_name:e.target.value})}/>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-1"></div>
               </div>
               <div class="row row-distance">
                  <div class="col-sm-11">
                     <div class="form-group">
                        <div class="col-sm-4">
                           <label> Account number : </label>
                        </div>
                        <div class="col-sm-6 cursor">
                           <input type="text" name="text" class="form-control" value={this.state.account_number} onChange={(e)=>this.setState({account_number:e.target.value})}/>
                        </div>
                        <div class="col-sm-2"> </div>
                     </div>
                  </div>
                  <div class="col-sm-1"></div>
               </div>
               <div class="row row-distance">
                  <div class="col-sm-11">
                     <div class="form-group">
                        <div class="col-sm-4">
                           <label> BSB Number : </label>
                        </div>
                        <div class="col-sm-6 cursor">
                           <input type="text" name="text" class="form-control" value={this.state.bsb_number} onChange={(e)=>this.setState({bsb_number:e.target.value})}/>
                        </div>
                        <div class="col-sm-2"> </div>
                     </div>
                  </div>
                  <div class="col-sm-1"></div>
               </div>
               <div class="row row-distance">
                  <div class="col-sm-11">
                     <div class="form-group">
                        <div class="col-sm-4">
                           <label> Tyro Merchant ID (MID) : </label>
                        </div>
                        <div class="col-sm-6 cursor">
                           <input type="text" name="text" class="form-control" value={this.state.mid} onChange={(e)=>this.setState({mid:e.target.value})}/>
                        </div>
                        <div class="col-sm-2"> </div>
                     </div>
                  </div>
                  <div class="col-sm-1"></div>
               </div>
            </div>
            <div class="modal-footer">
               <div class="row">
                  <div class="col-sm-8"> </div>
                  <div class="col-sm-4">
                     <button type="button" class="btn btn-default" data-dismiss="modal" onClick={()=>this.saveClickHandler()}>Save</button>
                     <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}>Cancel</button>
                  </div>
               </div>
            </div>
         </div>
       )}
       </Popup>
   )}
}
const mapDispatchToProps = dispatch => {
   return {
            getDoctors: () => dispatch(api_get_doctors()),
            saveRecord:(data)=>dispatch(api_add_provider(data)),
            getData:(data)=>dispatch(api_get_data(data)),
            updateRecord:(data)=>dispatch(api_update_data(data))
           };
 };
 const mapStateToProps = state => {
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';

  return {
  redux_docId
  };
};
export default connect(mapStateToProps,mapDispatchToProps) (AddProviderAccount);
