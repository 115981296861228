import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import AccountDetails from './AccountDetails';
class Select extends React.Component {
  constructor(props){
    super();
    this.state = {
      tech: 'select',
      tech1:'',
      tech2:''
    };
  }
  handleChange(e){
    this.setState({
      tech: e.target.id
    })
  }
   handleClick(e){
    this.setState({
      tech1: this.state.tech
    })
  }
  render(){
    return (
      <div>
        <ul id="lang" key={this.state.tech}  id={this.state.tech}>
         
          <li onClick={this.handleChange.bind(this)}  id="Angular">Angular</li>
          <li onClick={this.handleChange.bind(this)} id="Bootstrap">Bootstrap</li>
          <li onClick={this.handleChange.bind(this)} id="React">React</li>
        </ul>
      
        <h2>{this.state.tech1}</h2>
        <button onClick={this.handleClick.bind(this)} id="React">React</button>
      </div>
    )
  }
}

class AddNotes extends Component {
  constructor(props){
    super(props);
    this.state = {
      tech: '',
      tech1: ''
    };
    this.handleClick = this.handleClick.bind(this);
  }
 
  handleChange(e){
    this.setState({
      tech: e.target.id
    })
  }
  handleClick(e){
    this.setState({
      tech1: this.state.tech
    })
  }
  render() { 
  return ( 
      <Popup trigger={ <a title="Account"  className="collapsed btn btn-default" aria-expanded="false">...</a>} position="right center" modal
      closeOnDocumentClick={false}>
       {close => (
      <div>  
         <div className="modal-header" style={{width: '100%', backgroundColor: '#f4f4f4', padding: '5px 15px'}}>
    <h5 style={{backgroundColor: '#f4f4f4', width: '100%', padding: '5px 10px', fontSize: '16px', marginBottom: '0px'}}>Add Item</h5>
    <button type="button" className="close" data-dismiss="modal" onClick={()=>close()} aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
    <div className="modal-body">
      <div style={{float: 'left', width: '100%', height: 'auto'}}>
       
                <div className="col-sm-12" style={{paddingLeft: '0px'}}> 
             
      
        <div>
        <ul id="lang" key={this.state.tech}  id={this.state.tech}>
         
          <li onClick={this.handleChange.bind(this)}  id="Angular">Angular</li>
          <li onClick={this.handleChange.bind(this)} id="Bootstrap">Bootstrap</li>
          <li onClick={this.handleChange.bind(this)} id="React">React</li>
        </ul>
        
        <h2>{this.state.tech1}</h2>
           </div>  
       </div>     
      </div>
    </div>
    <div className="modal-footer"  style={{marginTop: '190px'}}>
    <div>


      </div>
     
      <button type="button"  className="btn btn-default" data-dismiss="modal"
     onClick={() => { this.handleClick()}} id="Insert">Insert</button>
    
      <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>close()}>Cancel</button>
    </div>
  </div>
     )}
     </Popup>
  );
}
}
 


 
export default AddNotes;