import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    api_add_correspondence,
    api_get_recieved,
    api_get_sent
} from '../../../DS/DS.Correspondence';
//import Files from 'react-files';
import { api_get_title,api_contact_category } from '../../../DS/DS.Correspondence';
import {
    NotificationContainer,
    NotificationManager,
  } from 'react-notifications';
//import "react-datepicker/dist/react-datepicker.css"
import SearchDoctors from './searchDoctors';
import Showhelp from './../../showHelp';

class AddAddress extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            addressLine1:'',
            addressLine2:'',
            city:'',
            postcode:'',
            fax:'',
            phone:'',
            healthid:''

           
        }
    }
    componentDidMount(){
        this.setState({open:this.props.showmodal})
     //   alert(this.props.add);
        if(this.props.add=='')
        {
            this.setState({
                addressLine1:this.props.sendData.addressLine1,
                addressLine2:this.props.sendData.addressLine2,
                city:this.props.sendData.city,
                postcode:this.props.sendData.postcode,
                fax:this.props.sendData.fax,
                phone:this.props.sendData.phone,
                healthid:this.props.sendData.health_identifier
            })
        }
        
    }
    render() {
        return ( 
        <Popup 
        position="right center"   modal
        closeOnDocumentClick={false}
        open={this.state.open}
        >
         {close => (
                <div style={{ width:'700px', margin:'0px auto'}}>
                <div style={{ width:'700px', marginLeft:'20px', backgroundColor:'#FFF', float:'left'}}>
                  <div className="modal-header" style={{width: '100%',height: '20',padding:'0 15px 0 15px'}}>
                  <h6 style={{float:'left', fontSize: '16px'}}>Add Contact Address</h6>
                  <Showhelp gotohelp="add_address_help"/>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{marginTop:'10px'}}>
                            <span aria-hidden="true" onClick={()=>{this.props.changeKey('');close();}}>×</span>
                        </button>
                  </div>
                  <div className="modal-body" style={{ float:'left', border: "0px" }}>
                    <div className="col-sm-12" id="Graphdetails">
				        <div className="form-group row">				
						    <label className="col-sm-2">Address line 1:</label>
							<div className="col-sm-9 mt-2 pl-0">
							    <input type="text" className="form-control" value={this.state.addressLine1} onChange={(e)=>{this.setState({addressLine1:e.target.value})}}/>
							</div>
						</div>
				        <div className="form-group row">				
							<label className="col-sm-2">Address line 2:</label>
							<div className="col-sm-9 mt-2 pl-0">
						    	<input type="text" className="form-control" value={this.state.addressLine2} onChange={(e)=>{this.setState({addressLine2:e.target.value})}}/>
							</div>
						</div>
				        <div className="form-group row">				
							<label className="col-sm-2">City/Suburb:</label>
							<div className="col-sm-9 mt-2 pl-0">
							    <input type="text" className="form-control" value={this.state.city} onChange={(e)=>{this.setState({city:e.target.value})}}/>
							</div>
						</div>
				        <div className="form-group row">				
							<label className="col-sm-2">Postcode:</label>
							<div className="col-sm-9 mt-2 pl-0">
							    <input type="text" className="form-control" value={this.state.postcode} onChange={(e)=>{this.setState({postcode:e.target.value})}}/>
							</div>
						</div>
				        <div className="form-group row">				
							<label className="col-sm-2">Phone:</label>
							<div className="col-sm-3 pl-0">
						    	<input type="text" className="form-control" value={this.state.phone} onChange={(e)=>{this.setState({phone:e.target.value})}}/>
							</div>
    						<label className="col-sm-1">Fax:</label>
							<div className="col-sm-3 pl-0">
							    <input type="text" className="form-control" value={this.state.fax} onChange={(e)=>{this.setState({fax:e.target.value})}}/>
							</div>
						</div>
				        <div className="form-group row">				
							<label className="col-sm-2 pr-0">Health Identifier:</label>
							<div className="col-sm-3 pl-0">
							    <input type="text" className="form-control" value={this.state.healthid} onChange={(e)=>{this.setState({healthid:e.target.value})}}/>
							</div>
						</div>
				        <div className="form-group row">
				            <div className="col-sm-2"></div>
						    <div className="col-sm-10">
							    <input type="checkbox" value="" style={{margin: '8px 0px 3px 0px', float:'left'}}/>
                                <span style={{marginTop:'5px', marginRight:'10px', fontSize:'12px', float:'left'}}>&nbsp; Accepts CDA eReferrals</span>
							</div>
						</div>
                    </div> 
                    <div className="modal-footer" style={{float:'left', width:'100%', marginTop:'25px'}}>
                        {this.props.add=='add' ?
                        <button type="button" className="btn btn-primary btn-xs" onClick={()=>{this.props.changeKey(this.state);close();}}>Add</button>:
                        <button type="button" className="btn btn-primary btn-xs" onClick={()=>{this.props.changeKey(this.state,this.props.sendId);close();}}>Update</button>}
		                <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" onClick={()=>{this.props.changeKey('');close();}}>Cancel</button>
                    </div>
                </div>
            </div>
            </div>
                
          )}
          </Popup>)
    }
}

const mapDispatchToProps = (dispatch) => {
	return {
      
    
  }
}

export default connect () (AddAddress);
