import React, { Component } from 'react';
import Popup from 'reactjs-popup';
    import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import { time } from 'highcharts';
import SearchDoctors from '../Correspondence/searchDoctors'
import {api_add_diabetic} from '../../../DS/DS.EPC'
import Showhelp from './../../showHelp';

class AnotherValues extends Component {
   constructor(props)
   {
       super(props);
       this.state={
           open:this.props.showmodal,
           observations:new Date(),
           disableObservation:true,
           footExam:true,
           fundusShow:true,
           hb1ac:true,
           cholesterol:true,
           hdl:true,
           ldl:true,
           creati:true,
           trigly:true,
           egfr:true,
           albumin:true,
           micro:true,
           endo:true,
           dieti:true,
           ophtha:true,
           pedia:true,
           diabe:true,
           height:'',
           waist:'',
           weight:'',
           bsl:'',
           bp_sitting:'',
           bp_sleeping:'',
           fasting:0,
           foot_date:new Date(),
           right_deform:0,
           left_deform:0,
           right_ulcer:0,
           left_ulcer:0,
           right_neuro:0,
           left_neuro:0,
           right_pulses:0,
           left_pulses:0,
           fundus_date:new Date(),
           fundus_right:'',
           fundus_left:'',
           hbac_val:'',
           hbac_date:new Date(),
           hbac_select:'mmol/mol',
           cholesterol_val:'',
           cholesterol_date:new Date(),
           ldl_date:new Date(),
           ldl_val:'',
           trigly_val:'',
           trigly_date:new Date(),
           hdl_val:'',
           hdl_date:new Date(),
           creati_val:'',
           creati_date:new Date(),
           egfr_val:'',
           egfr_date:new Date(),
           albumin_val:'',
           albumin_date:new Date(),
           micro_val:'',
           micro_date:new Date(),
           micro_select:'mg/mmol',
           endo_date:new Date(),
           endo_doc:'',
           dieti_date:new Date(),
           dieti_doc:'',
           ophtha_date:new Date(),
           ophtha_doc:'',
           pedia_date:new Date(),
           pedia_doc:'',
           diabe_date:new Date(),
           diabe_doc:'',
           searchDoc:false,
           selected_doc_id:'',
           from_guid:'',
           clicked:'',
           doctor_id:this.props.redux_docId,
           patient_id:this.props.redux_patientId



           
       }
   }

   SaveAll=()=>
   {
    this.props.closeAll()
   }

 

   enableDates=(valFor,val)=>
    {
        if(valFor=='observation' && val==true){
            this.setState({disableObservation:false})
        }
        if(valFor=='observation' && val==false){
            this.setState({disableObservation:true})
        }

        if(valFor=='footExam' && val==true){
            this.setState({footExam:false})
        }
        if(valFor=='footExam' && val==false){
            this.setState({footExam:true})
        }

        if(valFor=='fundus' && val==true){
            this.setState({fundusShow:false})
        }
        if(valFor=='fundus' && val==false){
            this.setState({fundusShow:true})
        }

        if(valFor=='hb1ac' && val==true){
            this.setState({hb1ac:false})
        }
        if(valFor=='hb1ac' && val==false){
            this.setState({hb1ac:true})
        }

        if(valFor=='cholesterol' && val==true){
            this.setState({cholesterol:false})
        }
        if(valFor=='cholesterol' && val==false){
            this.setState({cholesterol:true})
        }

        if(valFor=='hdl' && val==true){
            this.setState({hdl:false})
        }
        if(valFor=='hdl' && val==false){
            this.setState({hdl:true})
        }

        if(valFor=='ldl' && val==true){
            this.setState({ldl:false})
        }
        if(valFor=='ldl' && val==false){
            this.setState({ldl:true})
        }

        if(valFor=='creati' && val==true){
            this.setState({creati:false})
        }
        if(valFor=='creati' && val==false){
            this.setState({creati:true})
        }

        if(valFor=='trigly' && val==true){
            this.setState({trigly:false})
        }
        if(valFor=='trigly' && val==false){
            this.setState({trigly:true})
        }

        if(valFor=='egfr' && val==true){
            this.setState({egfr:false})
        }
        if(valFor=='egfr' && val==false){
            this.setState({egfr:true})
        }

        if(valFor=='albumin' && val==true){
            this.setState({albumin:false})
        }
        if(valFor=='albumin' && val==false){
            this.setState({albumin:true})
        }

        if(valFor=='micro' && val==true){
            this.setState({micro:false})
        }
        if(valFor=='micro' && val==false){
            this.setState({micro:true})
        }

        if(valFor=='endo' && val==true){
            this.setState({endo:false})
        }
        if(valFor=='endo' && val==false){
            this.setState({endo:true})
        }

        if(valFor=='diabe' && val==true){
            this.setState({diabe:false})
        }
        if(valFor=='diabe' && val==false){
            this.setState({diabe:true})
        }

        if(valFor=='ophtha' && val==true){
            this.setState({ophtha:false})
        }
        if(valFor=='ophtha' && val==false){
            this.setState({ophtha:true})
        }

        if(valFor=='pedia' && val==true){
            this.setState({pedia:false})
        }
        if(valFor=='pedia' && val==false){
            this.setState({pedia:true})
        }

        if(valFor=='dieti' && val==true){
            this.setState({dieti:false})
        }
        if(valFor=='dieti' && val==false){
            this.setState({dieti:true})
        }
    }

    addSelectedDoc=(id)=>
    {
        if(this.state.clicked=='dieti')
        {
            this.setState({selected_doc_id:id.contact_id,dieti_doc:id.display+''+id.first_name+'.'+id.surname})
        }
        if(this.state.clicked=='endo')
        {
            this.setState({selected_doc_id:id.contact_id,endo_doc:id.display+''+id.first_name+'.'+id.surname})
        }
        if(this.state.clicked=='optha')
        {
            this.setState({selected_doc_id:id.contact_id,ophtha_doc:id.display+''+id.first_name+'.'+id.surname})
        }
        if(this.state.clicked=='pedi')
        {
            this.setState({selected_doc_id:id.contact_id,pedia_doc:id.display+''+id.first_name+'.'+id.surname})
        }
        if(this.state.clicked=='diabe')
        {
            this.setState({selected_doc_id:id.contact_id,diabe_doc:id.display+''+id.first_name+'.'+id.surname})
        }
        
        this.setState({searchDoc:false})
       
    }

    handleDate=(valfor,val)=>
    {
        console.log("&&&&&&&&&&&&&&&&&&&&&&&&",val)
        if(valfor=='observation')
        {
            this.setState({observations:val})
        }
        if(valfor=='foot')
        {
            this.setState({foot_date:val})
        }
        if(valfor=='fundus')
        {
            this.setState({fundus_date:val})
        }
        if(valfor=='hba')
        {
            this.setState({hbac_date:val})
        }
        if(valfor=='cholestrol')
        {
            this.setState({cholesterol_date:val})
        }
        if(valfor=='trigly')
        {
            this.setState({trigly_date:val})
        }
        if(valfor=='hdl')
        {
            this.setState({hdl_date:val})
        }
        if(valfor=='ldl')
        {
            this.setState({ldl_date:val})
        }
        if(valfor=='creati')
        {
            this.setState({creati_date:val})
        }
        if(valfor=='egfr')
        {
            this.setState({egfr_date:val})
        }
        if(valfor=='albumi')
        {
            this.setState({albumin_date:val})
        }
        if(valfor=='micro')
        {
            this.setState({micro_date:val})
        }
        if(valfor=='endo')
        {
            this.setState({endo_date:val})
        }
        if(valfor=='dieti')
        {
            this.setState({dieti_date:val})
        }
        if(valfor=='optha')
        {
            this.setState({ophtha_date:val})
        }
        if(valfor=='pedia')
        {
            this.setState({pedia_date:val})
        }
        if(valfor=='diabe')
        {
            this.setState({diabe_date:val})
        }

    }
    saveData=()=>
    {
        this.props.saveDiabetic(this.state).then(res=>
            {
                this.props.closeAll();
            })
    }
    render() {
        return ( 
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div style={{ width:'1000px', margin:'0px auto'}}> 
                <div style={{ width:'1000px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>   
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                        <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px', fontSize:'16px',marginBottom:'0px'}}> Diabetic cycle care <Showhelp gotohelp="select_date_help"/> </h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.closeAll()} style={{color:'black'}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ fontSize: "14px" }}>
                        <div className="col-sm-12" style={{marginBottom: '10', height:'100px'}}>
                            <div className="form-group row">
                                <div className="col-sm-10">
                                    <div className="form-group row">
                                        <label>Enter the date that the last cycle was completed: </label>
                                    </div>
                                    <div className="form-group row">
                                      
                                        <div className="col-sm-2">
                                        <DatePicker
                                         className="datePicker"
                                         onChange={(e)=>this.handleDate('observation',e)}
                                         dateFormat="dd/MM/yyyy"
                                         id="clinicalcalendar" 
                                         selected={
                                                this.state.dateShow
                                                    ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                    : Date.parse(this.state.observations)
                                                }
                                            
                                        />  
                                        </div>
                                    </div>
                                </div>
                            </div>
                               
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button"   className="btn btn-primary btn-xs" data-dismiss="modal" 
                    onClick={()=>this.props.saveData(this.state.observations)}>Save
                    </button>
                    <button type="button"  className="btn btn-primary btn-xs" data-dismiss="modal"  onClick={()=>this.props.closeAll()}>
                        Cancel
                    </button>
                </div>   
            </div>
            </div>
        )}
    </Popup>
   )}
}   

const mapDispatchToProps = (dispatch) => {
	return {
        saveDiabetic: data => dispatch(api_add_diabetic(data)),
  }
}
const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
  
  return {
    redux_patientId,redux_docId
  };
  };
export default connect (mapStateToProps, mapDispatchToProps) (AnotherValues);

