import React from "react";
import Popup from "reactjs-popup";
//import { MenuItem } from 'react-contextmenu';
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { api_getDoctors } from "../../DS/DS.Appointments";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
class ViewAppointmentText extends React.Component {
  state = {
    details: []
  };

  render() {
    console.log(this.props);
    return (
      <Popup
        style={{ zIndex: "99999" }}
        open={this.props.open}
        modal
        closeOnDocumentClick={false}
      >
        <div>
          <div className="popup-title">
            <span> View Text </span>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => this.props.close()}>
                ×
              </span>
            </button>

            <div className="modal-body addinvestigation">
              <div className="col-sm-12">
                <div className="col-sm-12">
                  <div className="row form-group">
                    <table
                      className=" table table-bordered"
                      style={{ float: "left", width: "100%" }}
                    >
                      <thead>
                        <tr style={{ backgroundColor: "#f4f4f4" }}>
                          <th>Patient Name</th>
                          <th>Doctor </th>
                          <th> Date & Time </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.currentcellappointments != undefined &&
                          this.props.currentcellappointments.length > 0 &&
                          this.props.currentcellappointments.map(
                            thissingleappointment => (
                              <tr>
                                <td>{thissingleappointment.Firstname}</td>
                                <td>{thissingleappointment.drname}</td>
                                <td>{thissingleappointment.start_time}</td>
                              </tr>
                            )
                          )}

                        {this.props.currentcellappointments != undefined &&
                          this.props.currentcellappointments.Firstname != "" &&
                          this.props.currentcellappointments.length ==
                            undefined && (
                            <tr>
                              <td>
                                {this.props.currentcellappointments.Firstname}
                              </td>
                              <td>
                                {this.props.currentcellappointments.drname}
                              </td>
                              <td>
                                {" "}
                                {this.props.currentcellappointments.start_time}
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={e => {
                  this.props.close(e);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getDoctors: () => dispatch(api_getDoctors())
  };
};
export default connect(null, mapDispatchToProps)(ViewAppointmentText);
//export default MoveItem;
