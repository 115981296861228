import React, { Component } from "react";
import Header from "../../header";
import Footer from "../../footer";
import Grid from "../../crudcomponent/combinedcrud";
import { dropDown, dropdown1 } from "../../../DS/services";
import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import './listPage.css';
import {api_delete_selection} from '../../../DS/DS.ListPage';
import AddCancellation from './addCancellation';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import {NotificationContainer,NotificationManager} from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import AddAppoinmentType from './addAppointmentType'; 
import AddCeaseMedication from './addCeaseMedication';
import AddCategory from './addCategory';
import AddDocument from './addDocumentType';
import AddContactNote from './addContactNote';
import CategoryCleanUp from './categoryCleanUp';
import DocumentCleanUp from './documentCleanUp';
class ListPage extends Component {
    constructor(props)
    {
        super(props);
        this.state={
            reloadKey:0,
            //cancel reason states
            addCancelReason:false,
            editCancelReason:false,
            cancelSelected:'',
            cancelSelectedLength:-1,
            //appointment states
            addAppoinmentType:false,
            appoinmentTypeSelected:'',
            appoinmentTypeSelectedLength:-1,
            editAppoinType:false,
            showdeleted: 0,
            appointmentTypeCrud:"",
            //cease medication states
            addCeaseMedication:false,
            ceaseMedicationSelected:'',
            ceaseMedicationSelectedLength:-1,
            editCeaseMedication:false,
            //category states
            addCategory:false,
            categorySelected:'',
            categorySelectedLength:-1,
            editCategory:false,
            openCategoryCleanUp:false,
            categoryCleanUP:false,
            //document states
            addDocument:false,
            documentSelected:'',
            documentSelectedLength:-1,
            editDocument:false,
            documentCleanUp:false,
            //contact note states
            addNote:false,
            noteSelected:'',
            noteSelectedLength:-1,
            editNote:false,
            cancellationCrud: {
                jsonGridVersion: "v2.0",
                grid: true,
                viewgrid: {
                  isVisible:true,
                  isPagination: true,
                  isFiltering: true,
                  isSelection:true,
                  deleteFunction:this.removeCancelReason,
                  isDateGroup:false,
                  isYearSeparator:false,
                  dateCol:'',
                  groupDateSelection:false,
                  cellClicked:'',
                  baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                  query: {
                    tb: "appointment_cancel_reason_tb",
                    cols: [
                      "cancel_reason_id",
                      "reason_name",
                    ],
                   where: [
                      {
                        type: "and",
                        key: "isDeleted",
                        operator: "=",
                        value: 0
                   }
                   ],
                    primary_key:"cancel_reason_id",
                  },
                  viewtabletitle: "",
                  columns: [
                    {name: "reason_name", title: "Cancellation Reason" },
                  ],
                  rows: [],
                  sortinit: [{ columnName: "cancel_reason_id", direction: "desc" }],
                  pagesize: 10,
                  getAllIds:false,
                  getallId:''
                }
              },
            
              ceaseMedicationCrud: {
                jsonGridVersion: "v2.0",
                grid: true,
                viewgrid: {
                  isVisible:true,
                  isPagination: true,
                  isFiltering: true,
                  isSelection:true,
                  deleteFunction:this.removeCeaseMedication,
                  isDateGroup:false,
                  isYearSeparator:false,
                  dateCol:'',
                  groupDateSelection:false,
                  cellClicked:'',
                  baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                  query: {
                    tb: "cease_medication_reason",
                    cols: [
                      "cease_id",
                      "cease_name",
                    
                    ],
                   where: [
                      {
                        type: "and",
                        key: "isDeleted",
                        operator: "=",
                        value: 0
                   }
                   ],
                    primary_key:"cease_id",
                  },
                  viewtabletitle: "",
                  columns: [
                    {name: "cease_name", title: "Reason for ceasing medication" }
                   
                  ],
                  rows: [],
                  sortinit: [{ columnName: "cease_id", direction: "desc" }],
                  pagesize: 10,
                  getAllIds:false,
                  getallId:''
                }
              },
              categoryCrud: {
                jsonGridVersion: "v2.0",
                grid: true,
                viewgrid: {
                  isVisible:true,
                  isPagination: true,
                  isFiltering: true,
                  isSelection:true,
                  deleteFunction:this.removeCategory,
                  isDateGroup:false,
                  isYearSeparator:false,
                  dateCol:'',
                  groupDateSelection:false,
                  cellClicked:'',
                  baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                  query: {
                    tb: "contact_category",
                    cols: [
                      "category_id",
                      "contact_name",
                      "display",
                      "category_type"
                    
                    ],
                   where: [
                      {
                        type: "and",
                        key: "contact_category.isDeleted",
                        operator: "=",
                        value: 0
                   },
                   {
                       type:" and",
                       key:"field_name",
                       operator:"=",
                       value:"CATEGORY_TYPE"
                   }],
                   joins:[
            
                    {
                     joinType: "inner",
                     joinTable: "lookup_tb",
                     joinCondition: "contact_category.category_type = lookup_tb.value"
                   }]
                   ,
                    primary_key:"category_id",
                  },
                  viewtabletitle: "",
                  columns: [
                    {name: "contact_name", title: "Contact Category" },
                    {name: "display", title: "Type" }
                   
                  ],
                  rows: [],
                  sortinit: [{ columnName: "category_id", direction: "desc" }],
                  pagesize: 10,
                  getAllIds:false,
                  getallId:''
                }
              },
              documentCrud: {
                jsonGridVersion: "v2.0",
                grid: true,
                viewgrid: {
                  isVisible:true,
                  isPagination: true,
                  isFiltering: true,
                  isSelection:true,
                  deleteFunction:this.removeDocument,
                  isDateGroup:false,
                  isYearSeparator:false,
                  dateCol:'',
                  groupDateSelection:false,
                  cellClicked:'',
                  baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                  query: {
                    tb: "document_type",
                    cols: [
                      "type_id",
                      "document_name",
                      
                    
                    ],
                   where: [
                      {
                        type: "and",
                        key: "isDeleted",
                        operator: "=",
                        value: 0
                   }]
                   ,
                    primary_key:"type_id",
                  },
                  viewtabletitle: "",
                  columns: [
                    {name: "document_name", title: "Document Type" }
                    
                   
                  ],
                  rows: [],
                  sortinit: [{ columnName: "type_id", direction: "desc" }],
                  pagesize: 10,
                  getAllIds:false,
                  getallId:''
                }
              },
              noteCrud: {
                jsonGridVersion: "v2.0",
                grid: true,
                viewgrid: {
                  isVisible:true,
                  isPagination: true,
                  isFiltering: true,
                  isSelection:true,
                  deleteFunction:this.removeNote,
                  isDateGroup:false,
                  isYearSeparator:false,
                  dateCol:'',
                  groupDateSelection:false,
                  cellClicked:'',
                  baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                  query: {
                    tb: "contact_note_reason",
                    cols: [
                      "reason_id",
                      "reason_name",
                      
                    
                    ],
                   where: [
                      {
                        type: "and",
                        key: "isDeleted",
                        operator: "=",
                        value: 0
                   }]
                   ,
                    primary_key:"reason_id",
                  },
                  viewtabletitle: "",
                  columns: [
                    {name: "reason_name", title: "Contact Note Reason" }
                    
                   
                  ],
                  rows: [],
                  sortinit: [{ columnName: "reason_id", direction: "desc" }],
                  pagesize: 10,
                  getAllIds:false,
                  getallId:''
                }
              }
        }
    }
    componentDidMount=()=>
    {
     
    }

 componentWillMount=()=>
 {
  this.appointment_type_crud(0);
 }
 reloadPage=()=>{
     this.setState({
         addCancelReason:false, 
         addAppoinmentType:false,
         editCancelReason:false,
         editAppoinType:false,
         addCeaseMedication:false,
         editCeaseMedication:false,
         addCategory:false,
         editCategory:false,
         openCategoryCleanUp:false,
         addDocument:false,
         editDocument:false,
         addNote:false,
         editNote:false,
         categoryCleanUP:false,
        documentCleanUp:false})
     this.setState({
         cancelSelected:'',
         cancelSelectedLength:-1,
         appoinmentTypeSelected:'',
         appoinmentTypeSelectedLength:-1,
         ceaseMedicationSelected:'',
         ceaseMedicationSelectedLength:-1,
         categorySelected:'',
         categorySelectedLength:-1,
         documentSelected:'',
         documentSelectedLength:-1,
         noteSelected:'',
         noteSelectedLength:-1})
     this.setState({reloadKey:Math.random()})
 }
 //deleted cancelletion reasons
 removeCancelReason=(ids,action,totalLength )=>
 {
    if(action=='selection')
    {
      this.state.cancelSelected=ids;
      this.state.cancelSelectedLength=totalLength;
    }
    if(action=='delete')
    {
        if (this.state.cancelSelectedLength> 0) {
              const cancellationData={tb_name:'appointment_cancel_reason_tb',ids:this.state.cancelSelected,primary_key:'cancel_reason_id'}
              confirmAlert({
                title: '',
                message: 'You are about to delete cancellation reason(s). Do you want proceed?',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                    this.props.apiDelete(cancellationData).then(
                    res => this.reloadPage() ,
                    NotificationManager.success(
                    'Cancellation reason(s) deleted successfully',
                    )
                  );
                },
            },
            {
              label: 'No',
              onClick: () => { this.reloadPage()},
            },
          ],
        });
      } 
      else 
      {
        NotificationManager.error('Please select cancellation reason(s) to delete');
      }
    }
 }
 //edit cancelletion results
 editCancle=()=>
 {
     if(this.state.cancelSelectedLength ==1 )
     {
        this.setState({editCancelReason:true})
     }
     else
     {
         alert("Please select a Cancellation reason to modify")
     }
 }
 //delete appointment type
 removeAppoinmentType=(ids,action,totalLength )=>
 {
    if(action=='selection')
    {
      this.state.appoinmentTypeSelected=ids;
      this.state.appoinmentTypeSelectedLength=totalLength;
    }
    if(action=='delete')
    {
        if (this.state.appoinmentTypeSelectedLength> 0) {
              const appoinmentTypeData={tb_name:'appoinments_type',ids:this.state.appoinmentTypeSelected,primary_key:'appoinment_type_id'}
              confirmAlert({
                title: '',
                message: 'You are about to delete appointment type record(s). Do you want to proceed?',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                    this.props.apiDelete(appoinmentTypeData).then(
                    res => this.reloadPage() ,
                    NotificationManager.success(
                    'Appointment type record(s) deleted',
                    )
                  );
                },
            },
            {
              label: 'No',
              onClick: () => { this.reloadPage()},
            },
          ],
        });
      } 
      else 
      {
        NotificationManager.error('Please select appointment type record(s) to delete');
      }
    }
 }
 editAppoinmentType=(e)=>
 {
     if(this.state.appoinmentTypeSelectedLength ==1 )
     {
        this.setState({editAppoinType:true})
     }
     else
     {
         alert("Please select a appointment type record to modify")
     }
 }
 //delete cease medication
 removeCeaseMedication=(ids,action,totalLength )=>
 {
    if(action=='selection')
    {
    //    alert(ids)
      this.state.ceaseMedicationSelected=ids;
      this.state.ceaseMedicationSelectedLength=totalLength;
    }
    if(action=='delete')
    {
        if (this.state.ceaseMedicationSelectedLength> 0) {
              const ceaseMedicationData={tb_name:'cease_medication_reason',ids:this.state.ceaseMedicationSelected,primary_key:'cease_id'}
              confirmAlert({
                title: '',
                message: 'You are about to delete cease medication reason(s). Do you want to proceed?',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                    this.props.apiDelete(ceaseMedicationData).then(
                    res => this.reloadPage() ,
                    NotificationManager.success(
                    'Cease medication reason(s) deleted',
                    )
                  );
                },
            },
            {
              label: 'No',
              onClick: () => { this.reloadPage()},
            },
          ],
        });
      } 
      else 
      {
        NotificationManager.error('Please select cease medication reason(s) to delete');
      }
    }
 }
 editCeaseMedication=(e)=>
 {
     if(this.state.ceaseMedicationSelectedLength ==1 )
     {
        this.setState({editCeaseMedication:true})
     }
     else
     {
         alert("Please select cease medication reason to modify")
     }
 }
 //deleted cancelletion reasons
 removeCategory=(ids,action,totalLength )=>
 {
    if(action=='selection')
    {
      this.state.categorySelected=ids;
      this.state.categorySelectedLength=totalLength;
    }
    if(action=='delete')
    {
        if (this.state.categorySelectedLength> 0) {
              const categoryData={tb_name:'contact_category',ids:this.state.categorySelected,primary_key:'category_id'}
              confirmAlert({
                title: '',
                message: 'You are about to delete contact category record(s). Do you want to proceed?',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                    this.props.apiDelete(categoryData).then(
                    res => this.reloadPage() ,
                    NotificationManager.success(
                    'Contact category record(s) deleted',
                    )
                  );
                },
            },
            {
              label: 'No',
              onClick: () => { this.reloadPage()},
            },
          ],
        });
      } 
      else 
      {
        NotificationManager.error('Please select contact category record(s) to delete');
      }
    }
 }
 //edit cancelletion results
 editCategory=()=>
 {
     if(this.state.categorySelectedLength ==1 )
     {
        this.setState({editCategory:true})
     }
     else
     {
         alert("Please select a contact category record to modify")
     }
 }
  //deleted document
  removeDocument=(ids,action,totalLength )=>
  {
     if(action=='selection')
     {
       this.state.documentSelected=ids;
       this.state.documentSelectedLength=totalLength;
     }
     if(action=='delete')
     {
         if (this.state.documentSelectedLength> 0) {
               const documentData={tb_name:'document_type',ids:this.state.documentSelected,primary_key:'type_id'}
               confirmAlert({
                 title: '',
                 message: 'You are about to delete document type record(s). Do you want to proceed?',
                 buttons: [
                 {
                     label: 'Yes',
                     onClick: () => {
                     this.props.apiDelete(documentData).then(
                     res => this.reloadPage() ,
                     NotificationManager.success(
                     'Document type record(s) deleted',
                     )
                   );
                 },
             },
             {
               label: 'No',
               onClick: () => { this.reloadPage()},
             },
           ],
         });
       } 
       else 
       {
         NotificationManager.error('Please select document type record(s) to delete');
       }
     }
  }
  //edit Document 
  editDocument=()=>
  {
      if(this.state.documentSelectedLength ==1 )
      {
         this.setState({editDocument:true})
      }
      else
      {
          alert("Please select single record to edit")
      }
  }
  //deleted contact note reason
  removeNote=(ids,action,totalLength )=>
  {
     if(action=='selection')
     {
       this.state.noteSelected=ids;
       this.state.noteSelectedLength=totalLength;
     }
     if(action=='delete')
     {
         if (this.state.noteSelectedLength> 0) {
               const noteData={tb_name:'contact_note_reason',ids:this.state.noteSelected,primary_key:'reason_id'}
               confirmAlert({
                 title: '',
                 message: 'You are about to contact note reason(s). Do you want to proceed?',
                 buttons: [
                 {
                     label: 'Yes',
                     onClick: () => {
                     this.props.apiDelete(noteData).then(
                     res => this.reloadPage() ,
                     NotificationManager.success(
                     'Contact note reason(s) deleted',
                     )
                   );
                 },
             },
             {
               label: 'No',
               onClick: () => { this.reloadPage()},
             },
           ],
         });
       } 
       else 
       {
         NotificationManager.error('Please select contact note reason(s) to delete');
       }
     }
  }
  //edit contact note reason 
  editNote=()=>
  {
      if(this.state.noteSelectedLength ==1 )
      {
         this.setState({editNote:true})
      }
      else
      {
          alert("Please select contact note reason record to modify")
      }
  }
  handleDeleteCheckbox = e => {
    //alert(e.target.checked)
    if (e.target.checked) {
      this.setState({ showdeleted: 1 });
      this.appointment_type_crud(1);
     // alert("In if")
    } else {
      this.setState({showdeleted: 0 });
      this.appointment_type_crud(0);
   //   alert("In else")
    }
    this.reloadPage();
  };
  appointment_type_crud = showdeleted => {
    let returnjson = "";
    this.setState({ appointmentTypeCrud: "" });
   // alert(showdeleted)
    if (showdeleted == 0) {
      let appointmentTypeCrudwithoutDelete= {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible:true,
          isPagination: true,
          isFiltering: true,
          isSelection:true,
          deleteFunction:this.removeAppoinmentType,
          isDateGroup:false,
          isYearSeparator:false,
          dateCol:'',
          groupDateSelection:false,
          cellClicked:'',
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "appoinments_type",
            cols: [
              "appoinment_type_id",
              "name",
              "appointment_interval",
              "display",
              
            ],
           where: [
              {
                type: "and",
                key: "appoinments_type.isDeleted",
                operator: "=",
                value: 0
           },
           {
            type: "and",
            key: "field_name",
            operator: "=",
            value: "DELETE_STAT"
       }
           ],
           joins: [
            {
              joinType: "inner",
              joinTable: "lookup_tb",
              joinCondition:
                "lookup_tb.value = appoinments_type.isDeleted"
            }
          ],
            primary_key:"appoinment_type_id",
          },
          viewtabletitle: "",
          columns: [
            {name: "name", title: "Appointment Type" },
            {name: "appointment_interval", title: "Length"},
            {name: "display", title: "Deleted"}
          ],
          rows: [],
          sortinit: [{ columnName: "appoinment_type_id", direction: "desc" }],
          pagesize: 10,
          getAllIds:false,
          getallId:''
        }
      }
      returnjson = appointmentTypeCrudwithoutDelete;
      this.setState({ appointmentTypeCrud: appointmentTypeCrudwithoutDelete });
    }

    if (showdeleted == 1) {
      let appointmentTypeCrudwithDelete= {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible:true,
          isPagination: true,
          isFiltering: true,
          isSelection:true,
          deleteFunction:this.removeAppoinmentType,
          isDateGroup:false,
          isYearSeparator:false,
          dateCol:'',
          groupDateSelection:false,
          cellClicked:'',
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "appoinments_type",
            cols: [
              "appoinment_type_id",
              "name",
              "appointment_interval",
              "display",
              
            ],
           where: [
            
          {
                    type: "and",
                      key: "field_name",
                      operator: "=",
                      value: "DELETE_STAT"
            },
            {
              type: "or",
              key: "appoinments_type.isDeleted",
              operator: "!=",
              value: 0
           }
           ],
           joins: [
            {
              joinType: "inner",
              joinTable: "lookup_tb",
              joinCondition:
                "lookup_tb.value = appoinments_type.isDeleted"
            }
          ],
            primary_key:"appoinment_type_id",
          },
          viewtabletitle: "",
          columns: [
            {name: "name", title: "Appointment Type" },
            {name: "appointment_interval", title: "Length"},
            {name: "display", title: "Deleted"}
          ],
          rows: [],
          sortinit: [{ columnName: "appoinment_type_id", direction: "desc" }],
          pagesize: 5,
          getAllIds:false,
          getallId:''
        }
      }
      this.setState({ appointmentTypeCrud: appointmentTypeCrudwithDelete });

      returnjson = appointmentTypeCrudwithDelete;
    }

    console.log("pasthistory return json", returnjson, this.state.acute_crud);
    return returnjson;
  };
  addCheck=()=>
{
  alert("Please deselect the record to proceed")
}
  render() {
   return (
      <div>
          <AdminHeader /><Leftsidemenu />
          <NotificationContainer />
            <section className="content-header" style={{marginLeft: '240px'}}>
                <h1>
                    Lists
                </h1>
                <ol className="breadcrumb">
                    <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                    <li className="active">List</li>
                </ol>
            </section>
            {/* main section start */}
            <section className="content"  style={{marginLeft: '240px'}}>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="box">
                            <div className="col-xs-12" style={{margin:'0px auto', float:'none'}}>
                                <div className="box-body">
                                    <div className="col-sm-12 col-md-12 col-xl-12">
                                        <div id="pageContent">
                                            <div className="form-group row m-t-10">
                                                <div className="col-sm-12 col-md-6 col-xl-6">

                                                <div className="col-lg-12 m-t-60 m-t-10 add-edit-btn-groups">
                                                        <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#reason-for-ceasing-medication-add" onClick={(e)=>{this.state.ceaseMedicationSelectedLength >0 ?this.addCheck():this.setState({addCeaseMedication:true})}}> Add </button>
                                                        <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#reason-for-ceasing-medication-edit" onClick={(e)=> this.editCeaseMedication(e)}>Edit</button>
                                                        <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" onClick={(e)=>this. removeCeaseMedication('','delete','')}>Remove</button>
                                                     </div>{/*buttons end */}

                                                     <div className="col-lg-12 m-t-0 table-border2">
                                                        <div className="card" style={{overFlowY:'scroll', height:'150px'}}>
                                                            <div className="card-block">
                                                                {/*<table style={{width:'100%'}}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{padding:'5px 0px'}}>&nbsp;Reason for ceasing medication</th>
                                                                            <th style={{padding:'5px 0px'}}>&nbsp; </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>&nbsp;Ineffective</td>
                                                                            <td>&nbsp;</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>*/}
                                                                 <CommonGrid
                                                                    crud={this.state.ceaseMedicationCrud}
                                                                    key={this.state.reloadKey}
                                                                />            

                                                            </div>{/*cardblock end */}
                                                        </div>{/*card end */}
                                                    </div>{/*col-lg-12 m-t-0 end */}
                                                    
                                                     {this.state.addCeaseMedication ?
                                                    <AddCeaseMedication showModal={this.state.addCeaseMedication} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                {this.state.editCeaseMedication ?
                                                    <AddCeaseMedication showModal={this.state.editCeaseMedication} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.ceaseMedicationSelected}/> :''}
                                           
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-xl-6">
                                                <div className="col-lg-12 m-t-60 m-t-10 add-edit-btn-groups">
                                                        <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#contact-category-add" onClick={(e)=>{this.state.categorySelectedLength >0 ?this.addCheck():this.setState({addCategory:true})}}> Add </button>
                                                        <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#contact-category-edit" onClick={(e)=> this.editCategory(e)}> Edit </button>
                                                        <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" onClick={(e)=>this. removeCategory('','delete','')}>Remove</button>
                                                        <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#contact-category-clean-up" onClick={()=>this.setState({categoryCleanUP:true})}> Clean Up </button>
                                                    </div>
                                                    <div className="col-lg-12 m-t-10 table-border2">
                                                        <div className="card" style={{overFlowY:'scroll',height:'120px'}}>
                                                            <div className="card-block">
                                                               {/*} <table style={{width:'100%'}}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{padding: '5px 0px'}}>&nbsp;Contact category</th>
                                                                            <th style={{padding: '5px 0px'}}>&nbsp;Type</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>&nbsp;Aged Care Facility</td>
                                                                            <td>&nbsp;Institution</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>*/}
                                                              
                                                                <CommonGrid
                                                                    crud={this.state.categoryCrud}
                                                                    key={this.state.reloadKey}
                                                                />  
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    </div>
                                                    {this.state.addCategory ?
                                                    <AddCategory showModal={this.state.addCategory} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                    {this.state.editCategory ?
                                                    <AddCategory showModal={this.state.editCategory} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.categorySelected}/> :''}
                                                    {this.state.categoryCleanUP ?
                                                    <CategoryCleanUp showModal={this.state.categoryCleanUP} reloadPage={this.reloadPage}/>:''}
                                            </div>
                                            <div className="form-group row m-t-10">
                                                <div className="col-sm-12 col-md-6 col-xl-6">
                                                  <div className="col-lg-12 m-t-60 m-t-10 add-edit-btn-groups">
                                                        <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#document-type-add" onClick={(e)=>{this.state.documentSelectedLength >0 ?this.addCheck():this.setState({addDocument:true})}} > Add </button>
                                                        <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#document-type-edit" onClick={(e)=>this.editDocument(e)}> Edit </button>
                                                        <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" onClick={(e)=>this.removeDocument('','delete','')}>Remove</button>
                                                        <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#document-type-clean-up" onClick={(e)=>this.setState({documentCleanUp:true})}> Clean Up </button>
                                                    </div>
                                                    <div className="col-lg-12 m-t-0 table-border2">
                                                        <div className="card" style={{overFlowY:'scroll', height:'120px'}}>
                                                            <div className="card-block">
                                                               {/*} <table style={{width:'100%'}}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{padding: '5px 0px'}}>&nbsp;Document type</th>
                                                                            <th style={{padding: '5px 0px'}}>&nbsp;</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>&nbsp;Apex patient Registration</td>
                                                                            <td>&nbsp;</td>
                                                                        </tr>
                                                                    </tbody>
                                                    </table>*/}
                                                                <CommonGrid
                                                                    crud={this.state.documentCrud}
                                                                    key={this.state.reloadKey}
                                                                /> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    {this.state.addDocument ?
                                                    <AddDocument showModal={this.state.addDocument} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                    {this.state.editDocument ?
                                                    <AddDocument showModal={this.state.editDocument} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.documentSelected}/> :''}
                                                     {this.state.documentCleanUp ?
                                                    <DocumentCleanUp showModal={this.state.documentCleanUp} reloadPage={this.reloadPage}/>:''}
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-xl-6">
                                                <div className="col-lg-12 m-t-60 m-t-10 add-edit-btn-groups">
                                                        <div className="form-group row m-t-10">
                                                            <div className="col-sm-12 col-md-7 col-xl-7">
                                                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#appointment-type-add" onClick={(e)=>{this.state.appoinmentTypeSelectedLength >0 ?this.addCheck():this.setState({addAppoinmentType:true})}}> Add </button>
                                                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#appointment-type-edit" onClick={(e)=> this.editAppoinmentType(e)}> Edit </button>
                                                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" onClick={(e)=>this.removeAppoinmentType('','delete','')}>Remove</button>
                                                            </div>
                                                            <div className="col-sm-12 col-md-5 col-xl-5">
                                                                <div className="border-checkbox-section" style={{marginTop:'', marginBottom:''}}>
                                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                                    <label className="border-checkbox-label" htmlFor="checkbox0" onChange={e => this.handleDeleteCheckbox(e)}>
                                                                        <input className="border-checkbox" type="checkbox" id="checkbox0" Checked={this.state.showdeleted === "1" ? true : false}/>
                                                                        &nbsp; Show Deleted</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 m-t-0 table-border2">
                                                        <div className="card" style={{overFlowY:'scroll', height:'120px'}}>
                                                            <div className="card-block">
                                                               {/*} <table style={{width:'100%'}}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{padding: '5px 0px'}}>&nbsp;Appointment type</th>
                                                                            <th style={{padding: '5px 0px'}}>&nbsp;Length</th>
                                                                            <th style={{padding: '5px 0px'}}>&nbsp;Deleted</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <thead>
                                                                    </thead>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>&nbsp; <img src="dist/img/pin.png" width="15px;"/> Acupuncture</td>
                                                                        <td>&nbsp;15mins</td>
                                                                        <td>&nbsp;No</td>
                                                                      </tr>
                                                                    </tbody>
                                                                </table>*/}
                                                                 <CommonGrid
                                                                    crud={this.state.appointmentTypeCrud}
                                                                    key={this.state.reloadKey}
                                                                />


                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                {this.state.addAppoinmentType ?
                                                    <AddAppoinmentType showModal={this.state.addAppoinmentType} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                {this.state.editAppoinType ?
                                                    <AddAppoinmentType showModal={this.state.editAppoinType} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.appoinmentTypeSelected}/> :''}
                                            </div>
                                            <div className="form-group row m-t-0">
                                                <div className="col-sm-12 col-md-6 col-xl-6">
                                                <div className="col-lg-12 m-t-60 m-t-10 add-edit-btn-groups">
                                                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#reason-for-cancellation-add" onClick={(e)=>{this.state.cancelSelectedLength >0 ?this.addCheck():this.setState({addCancelReason:true})}}> Add </button>
                                                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#reason-for-cancellation-add" onClick={(e)=>this.editCancle()}> Edit </button>
                                                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" onClick={(e)=>this.removeCancelReason('','delete','')}>Remove</button>
                                                        </div>
                                                    <div className="col-lg-12 m-t-10 table-border2">
                                                        <div className="card" style={{overFlowY:'scroll',height:'120px'}}>
                                                                <div className="card-block">
                                                                    {/*<table style={{width:'100%'}}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{padding: '5px 0px'}}>&nbsp;Reason for cancelling appointment</th>
                                                                                <th style={{ padding: '5px 0px'}}>&nbsp;</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>&nbsp;Cannot Make It</td>
                                                                                <td>&nbsp;</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>*/}
                                                                     <CommonGrid
                                                                    crud={this.state.cancellationCrud}
                                                                    key={this.state.reloadKey}
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                        {/**For adding new cancellation reason */}
                                                        {this.state.addCancelReason ?
                                                            <AddCancellation showModal={this.state.addCancelReason} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                             {this.state.editCancelReason ?
                                                            <AddCancellation showModal={this.state.editCancelReason} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.cancelSelected}/> :''}

                                                    </div>
							                        <div className="col-sm-12 col-md-6 col-xl-6">
                                      <div className="col-lg-12 m-t-60 m-t-10 add-edit-btn-groups">
                                                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#contact-note-reason-add" onClick={(e)=>{this.state.noteSelectedLength >0 ?this.addCheck():this.setState({addNote:true})}}> Add </button>
                                                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#contact-note-reason-edit" onClick={(e)=>this.editNote()}> Edit </button>
                                                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" onClick={(e)=>this.removeNote('','delete','')}>Remove</button>
                                                        </div>
                                                        <div className="col-lg-12 m-t-10 table-border2">
                                                            <div className="card" style={{overFlowY:'scroll', height:'120px'}}>
                                                                <div className="card-block">
                                                                    {/*<table style={{width:'100%'}}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{padding: '5px 0px'}}>&nbsp; Contact Note Reason</th>
                                                                                <th style={{padding: '5px 0px'}}>&nbsp;</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>&nbsp;Appointment</td>
                                                                                <td>&nbsp;</td>
                                                                            </tr>
                                                                        </tbody>
                                                             </table>*/}
                                                             <CommonGrid
                                                                    crud={this.state.noteCrud}
                                                                    key={this.state.reloadKey}
                                                                />  
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                    </div>
                                                    {this.state.addNote ?
                                                            <AddContactNote showModal={this.state.addNote} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                             {this.state.editNote ?
                                                            <AddContactNote showModal={this.state.editNote} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.noteSelected}/> :''}

						                        </div>
						                    </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer modal-footer-bottom-margin">
                                        <div className="row">
                                            <div className="col-sm-10"> </div>
                                            <div className="col-sm-2">
                                                <button type="button" className="btn btn-default" data-dismiss="modal">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
    return {
        apiDelete:data =>dispatch(api_delete_selection(data))
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  
  
  export default connect(mapStateToProps,mapDispatchToProps)(ListPage);
