import React, { Component } from 'react';
import Jsonform from './jsonschemaform';
class Genform extends Component {
    state = {  }
    constructor()    {
        super();
        //this.dropdown = this.dropdown.bind(this);
    }
    /*dropdown =  () => {
        let url = 'http://localhost/ai/drafts_projects/supadoc_api/project/?api=general&action=d';
        let query = {
            tb : "patients",
            value : "user_id", 
            display : "Firstname"
            };
        let a =  {
                type: "string",
                enum: [1,2],
                enumNames: ["male", "female"], 
                default: "2"
                };    
        let queryString = url;
        fetch(queryString, {
            method: 'post',
            body: JSON.stringify(query)
            })
            .then(response => response.json())
            .then((data) => {
               a.enum = data.content.value;
               a.enumNames = data.content.display;
                console.log("dropdown obj"+a);
                return a;
            })
            .catch(() => {alert("error2"); return a});
             return a;   
    }*/

    render() { 
          
           
        return ( <div><Jsonform form={this.props.add_form}/> </div> );
    }
}
 
export default Genform;