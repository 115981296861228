import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { api_getTitle,api_getGender} from "../../../DS/DS.Appointments";
import DatePicker from "react-datepicker";
import {get_selected_states} from '../../../DS/DS.EPC'
import PastMedical from './pastMedical'
import Showhelp from './../../showHelp';

class riskFactors extends Component {
    constructor(props)
    {
        super(props)
        this.state={ 
            showpast:false,
            key:0
        }
    }

    closeAll=()=>
    {
        this.setState({showpast:false,key:Math.random()})
    }
    render() {
        return (
            <div className="col-sm-12 mb-1 mt-1">
                <div style={{padding:'10px 10px 10px 0', overflowY: 'auto' }}>
                    <h6>The following risk factors may lead </h6>
                    <h4>Dementia Risk Factors</h4>
                    <div className="box" style={{border:'1px solid'}}>
                        <div className="col-md-6"><label>Diabetes</label></div>
                        <div className="col-md-6"><label>Hyperlipidaemia</label></div>
                        <div className="col-md-6"><label>Excessive alcohol consumption</label></div>
                        <div className="col-md-6"><label>Head injury with loss of consciousness</label></div>
                        <div className="col-md-6"><label>Smoking</label></div>
                        <div className="col-md-6"><label>Acute onset of delusions or hallucinations</label></div>
                        <div className="col-md-6"><label>Hypertension</label></div>
                        <div className="col-md-6"><label>Parkinson's disease</label></div>
                        <div className="col-md-6"><label>Depression</label></div>
                        <div className="col-md-6"><label>Family history of dementia</label></div>
                         <button type="button" className="btn btn-primary btn-xs" onClick={(e)=>this.setState({showpast:true})}> Add</button>
                    </div>
                    <h4>Patient history-selected item will be included in the assessment document</h4>
                </div>
                {this.state.showpast ? 
                <PastMedical closeall={this.closeAll} open={this.state.showpast} />:''}
            </div>
        )}
}

export default riskFactors;