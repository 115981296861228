import React, { Component } from "react";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import moment from "moment";
//import "./myList.css";
//import CommonGrid from "../../crudcomponentV2/combinedcrud";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

import { confirmAlert } from "react-confirm-alert";
import Showhelp from '../../showHelp';
import SelectPatients from './selectPatients'
import {api_get_reminders_top,api_delete_reminder} from '../../../DS/DS.Reminders'
import ViewAppointment from './viewAppointment';

 class ReminderTop extends Component {
  constructor(props) {
    super(props);
  this.state = {
    open:this.props.open,
    key:0,
    showselect:false,
    select:[],
    reminderList:[],
    selectedindex:-1,
    selectedIds:'',
    showappointment:false,
    patienname:''
     }
 
}
closeSelect=(e)=>
{
    console.log("&&&&&&&&&&&&&&&",e)
    if(e!='')
    {
      
        this.props.getReminders({remFor:e}).then(res=>
            {
                this.setState({reminderList:res.data.content})
            })
    }
    else
    {
        this.setState({reminderList:[]})
    }
    this.setState({showselect:false})
   
}
openPop(a){
    this.setState({showselect:a})
  //  alert(a)
}
componentWillMount=()=>{

}
closeSelect1=(v)=>
{
    console.log("&&&&&&&&&&&&&&&&&&&&",v)
}
setTrClass=(id)=>{
    let classs = "";
    classs +=
      id == this.state.selectedindex
        ? " selectedBg"
        : "";
    return classs;
  };
  showSelectedContent(e, item, i) {
      const patientname=item.display +"." +item.Firstname+"." +item.Middlename+"." +item.Surname
    this.setState({ showSelectedContent: item,selectedindex: i,selectedIds:item.reminder_id, patienname:item.display +"" +item.Firstname+"" +item.Middlename+"" +item.Surname });
  }

  removeSelected=()=>
  {
    var array = [...this.state.reminderList]; // make a separate copy of the array
      array.splice(this.state.selectedindex, 1);
      this.setState({reminderList: array});
 //   this.setState({reminderList: this.state.reminderList.splice(this.state.selectedindex, 1)});
  }
  deleteReminder=()=>
  {
    if (this.state.selectedIds!='') {
          confirmAlert({
            title: '',
            message: 'You are about to delete the highlighted reminder. Do you want to proceed?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => {
                this.props.apiDeleteReminder({selectedIds:this.state.selectedIds}).then(
                res => { 
                    var array = [...this.state.reminderList]; // make a separate copy of the array
                    array.splice(this.state.selectedindex, 1);
                    this.setState({reminderList: array});
                    NotificationManager.success('Record deleted',)}
              );
            },
        },
        {
          label: 'No',
          onClick: () => { this.changeNote()},
        },
      ], 
    });
  } else {
    NotificationManager.error('Please select a record to delete');
  }
 }

 closePop=()=>
 {
     this.setState({showappointment:false})
 }
    render(){
        return(
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div style={{ width:'1000px', marginLeft:'-25px', backgroundColor:'#FFF', float:'left'}}>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                        <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px', fontSize:'16px',marginBottom:'0px'}}>Reminders <Showhelp gotohelp="reminders_help"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.close()} style={{color:'black'}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ fontSize: "14px" }}>
                        <div className="col-md-12 mt-3">
                            <div className="col-md-2">
                                <button type="button"  className="btn btn-primary btn-xs" onClick={()=>{this.openPop(true)}}>Select patients</button>
                            </div>
                            <div className="col-md-2">
                                <button type="button"  className="btn btn-primary btn-xs" onClick={()=>{this.removeSelected()}}>Remove from list</button>
                            </div>
                            <div className="col-md-2">
                                <button type="button"  className="btn btn-primary btn-xs" onClick={()=> this.deleteReminder()}>Delete reminder</button>
                            </div>
                            <div className="col-md-2">
                                <button type="button"  className="btn btn-primary btn-xs">Send reminder</button>
                            </div>
                            <div className="col-md-2">
                                <button type="button"  className="btn btn-primary btn-xs" onClick={()=>this.setState({showappointment:true})}>View appoitments</button>
                            </div>
                            <div className="col-md-2">
                                <button type="button"  className="btn btn-primary btn-xs">Follow up reminders</button>
                            </div>
                        </div>
                       

                        {/** reminder listing */}
                        <div className="col-md-12">
                         <div id="referralstlist" className="panel-collapse collapse" style={{float:'left', display:'block',  marginTop:'20px', width:'100%'}}>
			                <div className="panel panel-default" style={{float: 'left', width: '100%'}}>
                               {/*} <div className="panel-heading" style={{float: 'left', padding:'0px 15px', width: '100%'}}>
                                    <h4 className="panel-title" style={{float:'left', display:'inline', marginRight:'10px'}}>
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" style={{fontSize:'24px'}}>Reminders</a>
                                    </h4>              
                                    </div>*/}
                                <div style={{float:'left',width:'100%',overflow:'scroll'}}>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div style={{width:'100%',height:'232px',overflow: 'scroll'}}>
                                                        <table className="calendar table table-bordered" style={{float:'left', width:'2000px'}}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Reminder Reason</th>
                                                                    <th>Comment</th>
                                                                    <th>Due</th>
                                                                    <th>Doctor</th>
                                                                    <th>Location</th>
                                                                    <th>Address</th>
                                                                    <th>D.O.B</th>
                                                                    <th>Age</th>
                                                                    <th>Medicare No.</th>
                                                                    <th>Medicare Expiry</th>
                                                                    <th>File No.</th>
                                                                    <th>Home Phone</th>
                                                                    <th>Work Phone</th>
                                                                    <th>Mobile</th>
                                                                    <th>Email</th>
                                                                    <th>SMS consent</th>
                                                                    <th>Perferred method</th>
                                                                    <th>Date added</th>
                                                                    <th>Added by</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.reminderList!=undefined && this.state.reminderList.map((item,i)=>
                                                                <tr onClick={e =>
                                                                    this.showSelectedContent(e, item, i) } className={this.setTrClass(i)}>
                                                                    <td>{item.Firstname}{" "}{item.Middlename}{" "}{item.Surname}</td>
                                                                    <td>{item.content!='' ? item.content :item.reason}</td>
                                                                    <td></td>
                                                                    <td>{item.reminder_due}</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>{item.Address1}</td>
                                                                    <td>{item.DOB}</td>
                                                                    <td>{item.age}</td>
                                                                    <td>{item.Medicare_no}</td>
                                                                    <td>{item.expiry}</td>
                                                                    <td>{item.RecordNo}</td>
                                                                    <td>{item.home_phone}</td>
                                                                    <td>{item.work_pjone}</td>
                                                                    <td>{item.phone}</td>
                                                                    <td>{item.email_id}</td>
                                                                    <td>Outstanding</td>
                                                                    <td>Western Diagnostic Pathologu</td>
                                                                    <td>Outstanding</td>
                                                                    <td>Western Diagnostic Pathologu</td>
                                                                </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
			                </div>
                        </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                            <button type="button"  onClick={() => this.props.close()} className="btn btn-primary btn-xs" data-dismiss="modal">Close</button>
                    </div>   
                    {this.state.showselect ?
                        <SelectPatients showmod={this.state.showselect} close1={this.closeSelect}/>:''}

                    {this.state.showappointment ?
                    <ViewAppointment open={this.state.showappointment} close1={this.closePop} patient={this.state.patienname} id={this.state.showSelectedContent.patient_id}/> :''}   
                    </div>        
                    )}     
            </Popup>
        )}
    } 
    
    const mapDispatchToProps = dispatch => {
        return {
            getReminders: (data) => dispatch(api_get_reminders_top(data)),
            apiDeleteReminder:data=>dispatch(api_delete_reminder(data)),
        };
      };
      
      const mapStateToProps = state => {
        //console.log(state);
        let appointments = state.getDrAppointments.getAppointments
          ? state.getDrAppointments.getAppointments
          : [];
        let get_cut_copy_details = state.getDrAppointments.cut_copy_details
          ? state.getDrAppointments.cut_copy_details
          : {};
        let logged_info =
          state.getDrAppointments.logged_info != undefined
            ? state.getDrAppointments.logged_info
            : "";
      
        return {
          appointments,
          get_cut_copy_details,
          logged_info
        };
      };
      
      export default connect(mapStateToProps, mapDispatchToProps)(ReminderTop)

