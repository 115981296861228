import React from 'react';
//import ReactDOM from 'react-dom';
import { render } from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
//import './index.css';

import * as serviceWorker from './serviceWorker';
//redux
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducer/rootReducer';
//components
import App from './App'

//ReactDOM.render(<Mainpage />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// var loadScript = function(src) {
//   var tag = document.createElement('script');
//   tag.src = src;
//   tag.setAttribute("type", "text/javascript");
//   document.body.appendChild(tag);
// }
// loadScript("assets/bower_components/jquery/dist/jquery.min.js");
// loadScript("assets/bower_components/bootstrap/dist/js/bootstrap.min.js");
// loadScript("assets/bower_components/fastclick/lib/fastclick.js");
// loadScript("assets/dist/js/adminlte.min.js");
// loadScript("assets/bower_components/jquery-sparkline/dist/jquery.sparkline.min.js");
// loadScript("assets/plugins/jvectormap/jquery-jvectormap-1.2.2.min.js");
// loadScript("assets/plugins/jvectormap/jquery-jvectormap-world-mill-en.js");
// loadScript("assets/bower_components/jquery-slimscroll/jquery.slimscroll.min.js");
// loadScript("assets/bower_components/chart.js/Chart.js");

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
  )

  render(
    <Provider store={store}>
      <BrowserRouter >
      	<Route component={App} />
      </BrowserRouter>
    </Provider>, document.getElementById('root')
  )

  serviceWorker.unregister();
