import axios from 'axios';
//Add reminder reason
export const api_add_new_Pregnancy = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=obstetricHistory&action=add_new_Pregnancy`, data);
}

export const api_get_pregnancy_no = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=obstetricHistory&action=get_pregnancy_no`, data);
}


export const api_get_pregnancy_data = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=obstetricHistory&action=get_pregnancy_data`, data);
}

export const api_update_pregnancy = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=obstetricHistory&action=update_pregnancy`, data);
}

export const api_get_baby_data = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=obstetricHistory&action=get_baby_data`, data);
}

export const api_get_pregnancy =(data) =>dispatch =>{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=obstetricHistory&action=get_pregnancy`, data);
}

export const api_add_antenantal =(data) =>dispatch =>{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=obstetricHistory&action=add_antenantal`, data);
}


export const api_delete_pregnancy =(data) =>dispatch =>{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=obstetricHistory&action=delete_pregnancy`, data);
}

export const api_add_antenantal_result =(data) =>dispatch =>{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=obstetricHistory&action=add_antenantal_result`, data);
}
export const api_get_ante =(data) =>dispatch =>{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=obstetricHistory&action=api_get_ante`, data);
}
export const api_get_parity =(data) =>dispatch =>{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=obstetricHistory&action=get_parity`, data);
}
export const api_get_misscarriages =(data) =>dispatch =>{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=obstetricHistory&action=get_misscarriages`, data);
}
export const api_get_termination =(data) =>dispatch =>{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=obstetricHistory&action=get_termination`, data);
}
export const api_check_pregno =(data) =>dispatch =>{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=obstetricHistory&action=get_check_pregno`, data);
}
