import axios from 'axios';
 


export const api_careplan_item_validation = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=careplan_item_validation`,
      data
    );
   
};

export const api_list_referedby = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=list_referedby`,
      data
    );
   
};

export const api_delete_refered_by = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=delete_refered_by`,
      data
    );
   
};

export const api_add_refered_bydata = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=add_refered_by`,
      data
    );
   
};
export const api_savecreateaccount = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=api_savecreateaccount`,
      data
    );
   
};
export const api_list_careplans = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=list_careplans`,
      data
    );
   
};
export const api_list_service_notes = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=list_service_notes`,
      data
    );
   
};
export const api_delete_service_note = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=delete_service_note`,
      data
    );
   
};
export const api_list_appointment_care_plans = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=list_appointment_care_plans`,
      data
    );
   
};
export const api_add_create_account_item = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=add_create_account_item`,
      data
    );
   
};
export const api_create_account_line_item = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=api_create_account_line_item`,
      data
    );
   
};
export const api_useLastaccount = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=api_use_lastaccount`,
      data
    );
   
};
export const api_deleteCreateAccountItem = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=api_delete_create_account_item`,
      data
    );
   
};
export const api_deleteCreateAccountItems = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=api_delete_create_account_items`,
      data
    );
   
};
export const api_add_service_note = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=add_service_note`,
      data
    );
   
};
export const api_list_options = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=list_careplans`,
      data
    );
   
};