import React, { Component } from "react";
import Popup from "reactjs-popup";
import moment from "moment";
import {
  api_careplan_item_validation,
  api_list_careplans,
  api_list_appointment_care_plans
} from "./../../DS/DS.VisitnotesFinalizeVisit";
import {
  api_add_finalizevisit,
  api_saveFavCareplan,
  api_patientsCareplan
} from "./../../DS/DS.Appointments";
import { api_getreminder_reason } from "../../DS/DS.Reminders";
import Autocomplete from "react-autocomplete";
import {
  api_login,
  api_getUserInfo,
  api_setLoggedInfoRedux
} from "../../DS/DS.Users";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "./finalisevisit.css";
import { connect } from "react-redux";
import ServiceNotes from "./serviceNotes";

class finalisevisit extends Component {
  constructor(props, context) {
    super(props, context);

    // Set initial State
    this.state = {
      notes_to_reception: "",
      time: 0,
      start: 0,
      isOn: false,

      // Current value of the select field
      isActivetime: 1000,
      isSkiptime: 1000,
      pause: 0,
      servicenotes: false,
      selectedInput: "",
      written_servicenotes: [],
      otherReason: "",
      all_reminder_reasons: [],
      doctor_id: "",
      careplanMaster: [],
      patients_careplan: [],
      logged_info: [],
      message: "",
      mbsitem1: "",
      mbsitem2: "",
      mbsitem3: "",
      mbsitem4: "",
      value: "",
      patientId: "",
      reminders: [],
      note_to_reception: "",
      not_normal_aftercare: "",
      in_hospital: "",
      no_of_patients: "1",
      selected_appointment: [],

      // Data that will be rendered in the autocomplete
      autocompleteData: [
        {
          label: "Apple",
          value: 1
        },
        {
          label: "Microsoft",
          value: 2
        },
        {
          label: "Me, Myself and I",
          value: 3
        }
      ],
      autocompleteDataFull: [
        {
          label: "Apple",
          value: 1
        },
        {
          label: "Microsoft",
          value: 2
        },
        {
          label: "Me, Myself and I",
          value: 3
        }
      ]
    };
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
    // Bind `this` context to functions of the class
    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.getItemValue = this.getItemValue.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    if (this.state.patients_careplan.length > 0) {
      this.setState({ patients_careplan: [] });
    }
    this.startTimer();
    console.log("finalize", this.props.activetagPatientDetails);
  }
  startTimer() {
    // alert(this.state.time)
 //   alert(this.props.selected_appointment.withdoctor_time);
    let starttime = this.props.selected_appointment.withdoctor_time;
    //let starttime = "2020-03-13 08:00";
    var now = moment(new Date()); //todays date
    var end = moment(starttime); // another date
    var diff_duration = moment.duration(now.diff(end));
    var hours = diff_duration.asSeconds();
    // alert(
    //   this.secondsToHms(hours) +
    //     "diff" +
    //     diff_duration +
    //     " hours" +
    //     hours +
    //     "time now" +
    //     Date.now()
    // );

    this.setState({
      time: this.state.time,
      start: hours - this.state.time,
      isOn: true
    });
    //

    //alert("this.state.start"+ this.state.start);
    this.timer = setInterval(() => {
      //let duration = diff_duration - this.state.start
      //var hours = duration/1000;

      let starttime = this.props.selected_appointment.withdoctor_time;
      //let starttime = "2020-03-13 08:00";
      var now = moment(new Date()); //todays date
      var end = moment(starttime); // another date
      var diff_duration = moment.duration(now.diff(end));
      var hours = diff_duration.asSeconds();
      var differents_in_seconds = diff_duration.asSeconds();
      let duration = hours - this.state.start;
      let paused = hours - duration;
    //  console.log("***********************f", this.state.start)
     // console.log("MONITOR start", starttime, "now", new Date(), "gap", differents_in_seconds, "isOn", this.state.isOn,  "start & time", this.state.start, this.state.time );
      this.setState({
        time: duration,
        visitlength: this.secondsToHms(duration)
      });
    }, 1000);
  }
  stopTimer() {
    this.setState({ isOn: false });
    clearInterval(this.timer);
  }
  resetTimer() {
    this.setState({ time: 0 });
  }

  //   starttimer = () => {
  //     setInterval(() => {
  //         var now = moment(new Date()); //todays date

  //         let starttime = this.state.selected_appointment.withdoctor_time;
  //         //var end = moment("2020-1-5 0:00:00"); // another date
  //         var end = moment(starttime); // another date
  //         var duration = moment.duration(now.diff(end));
  //         var hours = duration.asSeconds();
  //         this.setState({
  //           visitlength: this.secondsToHms(hours)
  //         });
  //       }, this.timegap());
  //   }
  timegap = () => {
    return this.state.isActivetime * parseInt(this.state.pause);
  };

  toggleTimer = () => {
    if (this.state.pause == 0) {
      this.setState({ isActivetime: 0, isSkiptime: 1000 });
      clearInterval(this.starttimer);
    //  alert("is active 0");
    }
    if (this.state.pause == 1) {
      this.setState({ isActivetime: 1000, isSkiptime: 0 });
      // alert("is active 1");
    }

    this.setState({ pause: this.state.pause == 0 ? 1 : 0 });
  };
  getServiceNote = (servicenote, position) => {
    let written_servicenotes = this.state.written_servicenotes;
    let service_note_item = {
      position: position,
      servicenote: servicenote
    };
    let added = 0;
    for (let i = 0; i < written_servicenotes.length; i++) {
      if (written_servicenotes[i].position == position) {
        written_servicenotes[i].servicenote = servicenote;
        added = 1;
        break;
      }
    }
    if (added != 1) {
      written_servicenotes.push(service_note_item);
    }
    this.setState({ written_servicenotes: written_servicenotes });
  };
  getServiceNoteValue = position => {
    let written_servicenotes = this.state.written_servicenotes;
    let selectedNote = "";
    for (let i = 0; i < written_servicenotes.length; i++) {
      if (written_servicenotes[i].position == position) {
        selectedNote = written_servicenotes[i].servicenote;
        break;
      }
    }
    return selectedNote;
  };
  handleCheck = (e, key) => {
    this.setState({ [key]: e.target.checked?1:0 });
  };
  openServiceNotes = (e, i) => {
   // alert(i);
    this.setState({ servicenotes: true, selectedInput: i });
  };
  closeServiceNotes = () => {
    this.setState({ servicenotes: false, selectedInput: "" });
  };
  componentWillReceiveProps(nextProps) {
    //    console.log('---------recieve props changed',nextProps);

    if (
      nextProps.selectedPatientDetails !== this.props.selectedPatientDetails
    ) {
      this.setState({
        selectedPatientDetails: nextProps.selectedPatientDetails
      });
      this.setState({ patients_careplan: [] });
      this.setState({ reminders: [] });
      this.setState({ selected_appointment: nextProps.selected_appointment });
      this.setState({ logged_info: nextProps.logged_info });
      this.setState({
        doctor_id: nextProps.logged_info.content.details[0].doctor_id,
        mbsitem2: nextProps.logged_info.content.details[0].FavCarePlan1,
        mbsitem3: nextProps.logged_info.content.details[0].FavCarePlan2,
        mbsitem4: nextProps.logged_info.content.details[0].FavCarePlan3
      });
    }
  }

  componentWillMount() {
    this.props.api_getUserInfo().then(loginUser => {
      console.log("logindata", loginUser);
      this.props.setLoggedInfoRedux(loginUser);
    });

    this.props.listCareplans().then(res => {
      this.setState({ careplanMaster: res.data.content })
    console.log("************************",res.data.content)
    });

    this.props
      .patientsCareplan(this.state.selected_appointment.appointment_id)
      .then(res => this.setState({ patientsCareplan: res.data.content }));
    this.props.apireasonreminder().then(contData => {
      this.setState({ all_reminder_reasons: contData.data.content });
    });

    //    this.props.api_getUserInfo().then(loginUser => {
    //     console.log("logindata",loginUser);
    //     this.props.setLoggedInfoRedux(loginUser);
    //    });
  }
  saveFinalizeVisitClick = e => {

    if(this.state.patients_careplan.length>0){

    e.preventDefault();

    var finalizeParam = {
      dateSearchParam: {
        start_datetime:
          new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate()
      },
      doctorSearchParam: {
        doctor_guid: this.state.cdoctor,
        start_datetime:
          new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate() +
          " 06:00:00",
        end_datetime: new Date(new Date().setDate(new Date().getDate() + 6))
      },
      finalize: this.state
    };

    this.props.saveFinalizeVisit(finalizeParam);
    this.setState({written_servicenotes: [],patients_careplan: []});  

     
     
     

    // this.props.getDrDetails({drId: this.state.doctor_id}).then((res) => {

    // })
    NotificationManager.success("Visit Finalaization Completed.");

  } else {
    alert("Select atleast one careplan to proceed");
    return false;
  }
  };
  onChange(e) {
    // let content = this.state.autocompleteDataFull.filter(item => item.label.contains(e.target.value));
    // this.setState({autocompleteData:content});

    this.setState({
      value: e.target.value
    });

    console.log("The Input Text has changed to ", e.target.value);
  }
  ItemValidate1 = (e, key) => {
    this.setState({
      [key]: e.target.value
    });
  };
  ItemValidate2 = (e, field) => {
    let valid = false;
    let itemno = e.target.value;
    this.props.ItemValidation({ itemNo: e.target.value }).then(res => {
      let result = res.data.content[0];
      if (result != undefined && result.care_plan_id != undefined) {
        valid = true;
        let careplan = this.state.patients_careplan;
        careplan.push(result);
        this.setState({ patients_careplan: careplan });
        this.setState({ message: "" });

        if (field == "mbsitem1") {
          this.setState({ [field]: "" });
        } else {
          this.props
            .saveFavCareplan({
              field: field,
              doctor_id: this.state.doctor_id,
              fav: itemno
            })
            .then(() => {
              this.props.api_getUserInfo().then(loginUser => {
                console.log("logindata finalvisit", loginUser);
                this.props.setLoggedInfoRedux(loginUser);
              });
            });
        }
      } else {
        valid = false;
        this.setState({ message: "Item not found" });
        NotificationManager.error("Item Not Found!");
      }
    });
    return valid;
  };

  setReminder = (e, id, month) => {
    let reminders = this.state.reminders;
    let rem = { id: id, month: month };
    reminders.push(rem);
    this.setState({ reminders: reminders });
  };
  ItemValidate3 = (e, item) => {
    e.preventDefault();
    let careplan = this.state.patients_careplan;
    careplan.push(item);
    this.setState({ patients_careplan: careplan });
    this.setState({ message: "" });
  };

  removeItem = (e, id) => {
    //let careplan = this.state.patients_careplan.filter(item => item.care_plan_id !== id);
    e.preventDefault();
    this.setState({
      patients_careplan: this.state.patients_careplan.filter(
        item => item.care_plan_id !== id
      )
    });
  };
  onSelect(val) {
    this.setState({
      value: val
    });

    console.log("Option from 'database' selected : ", val);
  }
  renderItem(item, isHighlighted) {
    return (
      <div style={{ background: isHighlighted ? "lightgray" : "white" }}>
        {item.label}
      </div>
    );
  }
  getItemValue(item) {
    // You can obviously only return the Label or the component you need to show
    // In this case we are going to show the value and the label that shows in the input
    // something like "1 - Microsoft"
    return `${item.value} - ${item.label}`;
  }

  secondsToHms = d => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  };
  render() {
    let start =
      this.state.time == 0 ? (
        <button onClick={this.startTimer} style={{ marginTop:'10px'}}>
          {" "}
          <i class="fas fa-play"></i>{" "}
        </button>
      ) : null;
    let stop = this.state.isOn ? (
      <button onClick={this.stopTimer} style={{ marginTop:'10px'}}>
        {" "}
        <i class="fas fa-pause"></i>
      </button>
    ) : null;
    let reset =
      this.state.time != 0 && !this.state.isOn ? (
        <button onClick={this.resetTimer} style={{ marginTop:'10px'}}>
          {" "}
          <i class="fas fa-redo-alt"></i>{" "}
        </button>
      ) : null;
    let resume =
      this.state.time != 0 && !this.state.isOn ? (
        <button onClick={this.startTimer} style={{ marginTop:'10px'}}>
          {" "}
          <i class="fas fa-play"></i>{" "}
        </button>
      ) : null;
    return (
      <Popup
        style={{ minHeight: "500px" }}
        trigger={
          <button
            id="session-timeout-dialog-logout"
            disable={this.props.selected_appointment.status!="label-primary"?true:false}
            data-toggle="modal"
            data-target="#finalisevisit"
            type="button"
            className="btn btn-success btn-xs"
            style={{ float: "left", margin: "0px  10px 0px 0px" }}
          >
            Finalize Billing{" "}
          </button>
        }
        position="right center"
        modal
        closeOnDocumentClick={false}
        contentStyle={{}}
      >
        {close => (
          <div style={{ width:'100%', backgroundColor:'#FFF', float:'left'}}>
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() => close()}
                data-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                ×
              </button>
              <span>Finalize Billing</span>
            </div>

            <div
              className="modal-body newpostpopup"
              style={{ fontSize: "14px", border:'0px' }}
            >
              <div
                className="col-md-12"
                style={{
                  marginTop: "0px",
                  paddingLeft: "0px",
                  paddingRight: "0px"
                }}
              >
                <div className="col-sm-12 pr-0">
                  <div className="form-group row">
                    <div className="col-sm-12 pl-0">
                      <label className="col-sm-1 col-form-label text-left pl-0 pr-0">
                        Visit length:
                      </label>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          value={this.state.visitlength}
                          // defaultValue="3h 13m 34s"
                          name="search_condition"
                          className="form-control form-control-sm rq-form-elemen" style={{ float:'left', width:'90%'}}
                        />
                        {/* {this.state.pause == 0 && (
                          <button
                            title="Play"
                            onClick={e => this.toggleTimer(e)}
                          >
                            {" "}
                            <i class="fas fa-play-circle"></i>{" "}
                          </button>
                        )} */}
                        {/* <h3>timer: {this.state.time}</h3> */}
                        {start}
                        {resume}
                        {stop}
                       
                        {/* {this.state.pause != 0 && (
                          <button
                            title="Pause"
                            onClick={e => this.toggleTimer(e)}
                          >
                            {" "}
                            <i class="fas fa-pause-circle"></i>{" "}
                          </button>
                        )} */}
                      </div>

                      <div
                        className="col-sm-2 col-form-label text-right mt-2"
                        style={{
                          float: "right",
                          width: "100",
                          textAlign: "right"
                        }}
                      >
                        <select className="form-control">
                          <option> Direct Bill</option>
                        </select>
                      </div>
                      <div
                        className="col-sm-2 col-form-label pr-0"
                        style={{ float: "right", width: "120px", marginTop: '20px' }}
                      >
                        Account Type:
                      </div>
                    </div>
                    <div
                      className="col-sm-12 pl-0"
                      style={{ marginTop: "10px" }}
                    >
                      <label className="col-sm-1 col-form-label text-left pl-0 pr-0">
                        MBS Item:
                      </label>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          value={this.state.mbsitem1}
                          onKeyPress={event => {
                            if (event.key === "Enter") {
                              this.ItemValidate2(event, "mbsitem1");
                            }
                          }}
                          onChange={e => this.ItemValidate1(e, "mbsitem1")}
                          name="search_condition"
                          className="form-control form-control-sm rq-form-elemen"
                        />
                      </div>

                      <div className="col-sm-2"></div>
                      <div
                        className="col-sm-2 col-form-label text-right"
                        style={{
                          float: "right",
                          paddingLeft: "0px",
                          paddingRight: ""
                        }}
                      >
                        <a
                          href="http://supadoc.nanganallur.com/"
                          id="session-timeout-dialog-logout"
                          type="button"
                          className="btn btn-primary btn-xs collapsed"
                          target="_blank"
                          aria-expanded="false"
                        >
                          MBS Online
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 pl-0">
                    <div className="form-group row">
                      <div
                        className="col-sm-6 pl-0"
                        style={{ marginTop: "0px", width:'auto' }}
                      >
                        {this.state.careplanMaster != undefined &&
                          this.state.careplanMaster.length > 0 &&
                          this.state.careplanMaster
                            .filter(item => item.most_used == 1)
                            .map(item => (
                              <span style={{ marginRight: "5px" }}>
                                <a
                                  href="#"
                                  onClick={e => this.ItemValidate3(e, item)}
                                  style={{
                                    color: "#0000ff",
                                    textDecoration: "underline"
                                  }}
                                  className="collapsed"
                                  aria-expanded="false"
                                >
                                  {" "}
                                  {item.item_no}{" "}
                                </a>{" "}
                              </span>
                            ))}
                      </div>
                      <div className="col-sm-6" style={{ marginTop: "-10px" }}>
                        <div
                          className="col-sm-2"
                          style={{ paddingLeft: "0px" }}
                        >
                          <input
                            type="text"
                            name="search_condition"
                            className="form-control form-control-sm rq-form-elemen"
                            value={this.state.mbsitem2}
                            onKeyPress={event => {
                              if (event.key === "Enter") {
                                this.ItemValidate2(event, "mbsitem2");
                              }
                            }}
                            onChange={e => this.ItemValidate1(e, "mbsitem2")}
                          />
                        </div>
                        <div
                          className="col-sm-2"
                          style={{ paddingLeft: "0px", paddingRight: "0px" }}
                        >
                          <input
                            type="text"
                            name="search_condition"
                            className="form-control form-control-sm rq-form-elemen"
                            value={this.state.mbsitem3}
                            onKeyPress={event => {
                              if (event.key === "Enter") {
                                this.ItemValidate2(event, "mbsitem3");
                              }
                            }}
                            onChange={e => this.ItemValidate1(e, "mbsitem3")}
                          />
                        </div>
                        <div
                          className="col-sm-2"
                          style={{ paddingRight: "0px" }}
                        >
                          <input
                            type="text"
                            name="search_condition"
                            className="form-control form-control-sm rq-form-elemen"
                            value={this.state.mbsitem4}
                            onKeyPress={event => {
                              if (event.key === "Enter") {
                                this.ItemValidate2(event, "mbsitem4");
                              }
                            }}
                            onChange={e => this.ItemValidate1(e, "mbsitem4")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12" style={{ padding: "0px" }}>
                    <label className="col-form-label text-left">
                      Items to bill:
                    </label>
                  </div>
                  <div
                    style={{ float: "left", width: "100%", overflow: "auto" }}
                  >
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            <div
                              style={{
                                float: "left",
                                width: "100%",
                                height: "auto"
                              }}
                            >
                              <ServiceNotes
                                open={
                                  this.state.servicenotes &&
                                  this.state.selectedInput != ""
                                }
                                selectedInput={this.state.selectedInput}
                                closeServiceNotes={e =>
                                  this.closeServiceNotes()
                                }
                                getServiceNote={this.getServiceNote}
                              />
                              <table
                                className="calendar table table-bordered tablestyle"
                                style={{ width: "100%", fontSize: "14px" }}
                              >
                                <thead>
                                  <tr style={{ backgroundColor: "#f4f4f4" }}>
                                    {/* <th style={{textAlign: 'left',backgroundColor: '#f4f4f4'}}> Description</th> */}
                                    <th style={{ backgroundColor: "#f4f4f4" }}>
                                      {" "}
                                      Description{" "}
                                    </th>
                                    <th style={{ backgroundColor: "#f4f4f4" }}>
                                      Item No.
                                    </th>
                                    <th style={{ backgroundColor: "#f4f4f4" }}>
                                      Fee
                                    </th>
                                    <th style={{ backgroundColor: "#f4f4f4" }}>
                                      Rebate
                                    </th>
                                    <th style={{ backgroundColor: "#f4f4f4" }}>
                                      Service Notes
                                    </th>
                                    <th style={{ backgroundColor: "#f4f4f4" }}>
                                      Options
                                    </th>
                                  </tr>
                                </thead>
                                <tbody style={{ fontSize: "14px" }}>
                                  {this.state.patients_careplan.length > 0 &&
                                    this.state.patients_careplan.map(
                                      (item, i) => (
                                        <tr>
                                          {/* <td> <a href="#" data-toggle="modal" data-target="#invoicenotes" className="collapsed" aria-expanded="false"> {item.desc}</a></td> */}
                                          <td>
                                            {" "}
                                            <a
                                              href="#"
                                              data-toggle="modal"
                                              data-target="#invoicenotes"
                                              className="collapsed"
                                              aria-expanded="false"
                                            >
                                              {" "}
                                              {item.name}
                                            </a>
                                          </td>
                                          <td> {item.item_no} </td>
                                          <td> {item.price} </td>
                                          <td>{item.rebate} </td>
                                          <td>
                                            {" "}
                                            <input
                                              type="text"
                                              placeholder="Service Notes"
                                              value={this.getServiceNoteValue(
                                                i + 1
                                              )}
                                            />
                                            <button
                                              className="btn btn-primary btn-xs"
                                              onClick={e =>
                                                this.openServiceNotes(e, i + 1)
                                              }
                                            >
                                              {" "}
                                              +{" "}
                                            </button>
                                          </td>
                                          <td>
                                            {" "}
                                            <a
                                              href="#"
                                              onClick={e =>
                                                this.removeItem(
                                                  e,
                                                  item.care_plan_id
                                                )
                                              }
                                            >
                                              <i
                                                className="fas fa-trash-alt"
                                                aria-hidden="true"
                                                style={{
                                                  fontSize: 12,
                                                  marginRight: 5
                                                }}
                                              />
                                            </a>{" "}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      float: "left",
                      width: "100%",
                      overflow: "auto",
                      marginBottom: "0px"
                    }}
                  > 
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            <div
                              style={{
                                float: "left",
                                width: "100%",
                                height: "130px"
                              }}
                            >
                              <table
                                className="calendar table table-bordered tablestyle"
                                style={{ width: "100%" }}
                              >
                                <thead>
                                  <tr style={{ backgroundColor: "#f4f4f4" }}>
                                    <th style={{ textAlign: "left" }}>
                                      {" "}
                                      Reminders
                                    </th>
                                    <th>&nbsp;3 months</th>
                                    <th>&nbsp;6 months</th>
                                    <th>&nbsp;9 months</th>
                                    <th>&nbsp;12 months</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.all_reminder_reasons !=
                                    undefined &&
                                    this.state.all_reminder_reasons.map(
                                      (item, i) => (
                                        <tr>
                                          <td> {item.reason} </td>
                                          <td style={{ textAlign: "center" }}>
                                            <input
                                              type="checkbox"
                                              onClick={e =>
                                                this.setReminder(
                                                  e,
                                                  item.reason_id,
                                                  3
                                                )
                                              }
                                              name="drug"
                                            />
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            <input
                                              type="checkbox"
                                              name="drug"
                                              onClick={e =>
                                                this.setReminder(
                                                  e,
                                                  item.reason_id,
                                                  6
                                                )
                                              }
                                            />
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            <input
                                              type="checkbox"
                                              name="drug"
                                              onClick={e =>
                                                this.setReminder(
                                                  e,
                                                  item.reason_id,
                                                  9
                                                )
                                              }
                                            />
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            <input
                                              type="checkbox"
                                              name="drug"
                                              onClick={e =>
                                                this.setReminder(
                                                  e,
                                                  item.reason_id,
                                                  12
                                                )
                                              }
                                            />
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  <tr>
                                    {/* <td> <textarea style={{float: 'left', padding: '10px', border: '1px solid #ddd', width: '100%'}} defaultValue={""} /></td> */}
                                    <td>
                                      {" "}
                                      <input
                                        type="text"
                                        onChange={e =>
                                          this.setState({
                                            otherReason: e.target.value
                                          })
                                        }
                                      />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <input
                                        type="checkbox"
                                        name="drug"
                                        onClick={e => this.setReminder(e, 0, 3)}
                                      />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <input
                                        type="checkbox"
                                        name="drug"
                                        onClick={e => this.setReminder(e, 0, 6)}
                                      />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <input
                                        type="checkbox"
                                        name="drug"
                                        onClick={e => this.setReminder(e, 0, 9)}
                                      />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <input
                                        type="checkbox"
                                        name="drug"
                                        onClick={e =>
                                          this.setReminder(e, 0, 12)
                                        }
                                      />
                                    </td>
                                  </tr>
                                  {/* <tr>
                                                <td> Care Plans</td>
                                                <td style={{textAlign: 'center'}}><input type="checkbox" name="drug" onClick={e => this.setReminder(e, 2,3)}/></td>
                                                <td style={{textAlign: 'center'}}><input type="checkbox" name="drug" onClick={e => this.setReminder(e, 2,6)}/></td>
                                                <td style={{textAlign: 'center'}}><input type="checkbox" name="drug" onClick={e => this.setReminder(e, 2,9)}/></td>
                                                <td style={{textAlign: 'center'}}><input type="checkbox" name="drug" onClick={e => this.setReminder(e, 2,12)}/></td>
                                            </tr>
                                            <tr>
                                                <td> Care plan reviews</td>
                                                <td style={{textAlign: 'center'}}><input type="checkbox" name="drug" onClick={e => this.setReminder(e, 3,3)}/></td>
                                                <td style={{textAlign: 'center'}}><input type="checkbox" name="drug" onClick={e => this.setReminder(e, 3,6)}/></td>
                                                <td style={{textAlign: 'center'}}><input type="checkbox" name="drug" onClick={e => this.setReminder(e, 3,9)}/></td>
                                                <td style={{textAlign: 'center'}}><input type="checkbox" name="drug" onClick={e => this.setReminder(e, 3,12)}/></td>
                                            </tr>
                                            */}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="col-sm-8" style={{ padding: "0px" }}>
                    <label className="col-form-label text-left">
                      Note to reception:
                    </label>
                    <div
                      style={{
                        float: "left",
                        width: "100%",
                        overflow: "auto",
                        margintop: "10px"
                      }}
                    >
                      <div className="col-sm-12" style={{ padding: "0px" }}>
                        <textarea
                          style={{
                            float: "left",
                            padding: "10px",
                            border: "1px solid #ddd",
                            width: "100%"
                          }}
                          onChange={e =>
                            this.setState({
                              notes_to_reception: e.target.value
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-4"
                    style={{ marginTop: "20px", fontSize: "12px" }}
                  >
                    <div className="col-sm-12">
                      <input
                        type="checkbox"
                        className=""
                        style={{
                          float: "left",
                          width: "auto",
                          marginRight: "5px"
                        }}
                        value="1"
                        checked={
                          this.state.not_normal_aftercare ==
                          "1"
                            ? true
                            : false
                        }
                        onChange={e =>
                          this.handleCheck(e, "not_normal_aftercare")
                        }
                      />{" "}
                      Not normal aftercare
                    </div>
                    <div className="col-sm-12">
                      <input
                        type="checkbox"
                        className=""
                        style={{
                          float: "left",
                          width: "auto",
                          marginRight: "5px"
                        }}
                        value="1"
                        checked={
                          this.state.in_hospital == "1" ? true : false
                        }
                        onChange={e => this.handleCheck(e, "in_hospital")}
                      />{" "}
                      In hospital
                    </div>

                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="search_condition"
                        className=""
                        value={this.state.no_of_patients}
                        onChange={e => this.setState({no_of_patients: e.target.value})}
                        style={{
                          width: "50px",
                          float: "left",
                          marginTop: "10px",
                          marginLeft: ""
                        }}
                      />{" "}
                      &nbsp; &nbsp;{" "}
                      <p style={{ marginTop: "-3px", paddingLeft: "58px" }}>
                        No. of patients{" "}
                      </p>
                      &nbsp; &nbsp; &nbsp;
                    </div>
                  </div>
                  {/* <div className="col-sm-12" style={{padding: '0px', margin:'10px 0 0 0'}}>
                                <div className="col-sm-3" style={{padding: '0px', marginRight: '10px'}}>
                                <input type="text" name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                                </div>
                                <div className="col-sm-3" style={{padding: '0px', marginRight: '10px'}}>
                                <input type="text" name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                                </div>
                                <div className="col-sm-3" style={{padding: '0px'}}>
                                <input type="text"  name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                                </div>
                            </div> */}
                </div>
              </div>
            </div>

            <div className="modal-footer mt-0">
              <button
                type="button" className="btn btn-primary btn-xs"
                onClick={e => {
                  this.saveFinalizeVisitClick(e);
                  close();
                }}
              >
                Save
              </button>
              <button
                type="button" className="btn btn-primary btn-xs"
                onClick={e => {
                  this.saveFinalizeVisitClick(e);
                 this.props.toggleCreateaccount();
                  close();
                }}
              >
                Save & Open Create Account
              </button> 
              {/* <button type="button"  style={{padding:'6px 12px', fontSize:'14px',width:'',height:''}}className="btn btn-primary">Save &amp; Close</button> */}
              <button
                type="button" onClick={() => close()}
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ItemValidation: data => dispatch(api_careplan_item_validation(data)),
    patientsCareplan: data => dispatch(api_patientsCareplan(data)),
    listCareplans: data => dispatch(api_list_careplans(data)),
    saveFinalizeVisit: data => dispatch(api_add_finalizevisit(data)),
    apireasonreminder: () => dispatch(api_getreminder_reason()),
    api_login: data => dispatch(api_login(data)),
    setLoggedInfoRedux: data => dispatch(api_setLoggedInfoRedux(data)),
    api_getUserInfo: () => dispatch(api_getUserInfo()),
    saveFavCareplan: data => dispatch(api_saveFavCareplan(data)),
    list_appointment_care_plans: data =>
      dispatch(api_list_appointment_care_plans(data))
  };
};

const mapStateToProps = state => {
  let selected_appointment =
    state.getDrAppointments.selected_appointment != undefined
      ? state.getDrAppointments.selected_appointment
      : [];
  // let logged_info  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info:[];
  console.log(
    "selected_appointment",
    state.getDrAppointments.getPatientDetailst
  );
  let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : [];
  let selectedPatientDetails;
  if (patientDetails.length > 0) {
    selectedPatientDetails = patientDetails[0];
  } else {
    selectedPatientDetails = "";
  }
  console.log("finalizebilling SELECTED APPOINTMENT", selected_appointment);
  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";
      

  return {
    selectedPatientDetails,
    logged_info,
    selected_appointment,
    logged_info
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(finalisevisit);
//export default finalisevisit;
