import React, { Component } from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CommonGrid from "./../../crudcomponentV2/combinedcrud";
import { confirmAlert } from 'react-confirm-alert';
import AddProblem from './../../superadmin/manageCarePlans/addProblem';
import { api_list_problems } from "./../../../DS/DS.EPC";
import Showhelp from './../../showHelp';
// import cicon from "./../../../assets/img/cicon.png";
class ProblemPopup extends Component {
    constructor(props)
    {
        super(props)
        this.state={
            reloadKey:0,
        all_problems: [],
        addProblem:false,
         editProblem:false,
         problemSelected:'',
         problemSelectedLength:-1,

         problemCrud:
         {
           jsonGridVersion: "v2.0",
           grid: true,
           viewgrid: {
             isVisible:true,
             isPagination: true,
             isFiltering: true,
             isSelection:true,
             deleteFunction:this.removeProblem,
             isDateGroup:false,
             isYearSeparator:false,
             dateCol:'',
             cellClicked:'',
             baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
             query: {
               tb: "condition_master_tb",
               cols: [
                 "condition_id",
                 "name"
               ],
               where: [
                     {
                        type: "and",
                        key: "isDeleted",
                        operator: "=",
                        value: 0
                     },
            ],
               primary_key:"condition_id",
             },
             viewtabletitle: "",
             columns: [
               { name: "name", title: "Problem" }
             ],
             rows: [],
             sortinit: [{ columnName: "condition_id", direction: "desc" }],
             pagesize: 10,
             getAllIds:false,
             getallId:''
           }
   
         },
  };}



  componentDidMount(){
        this.list_problems();
    }

  editProblem=()=>
  {
   // alert("IN")
     if(this.state.problemSelectedLength ==1 )
     {
        this.setState({editProblem:true})
     }
     else
     {
         alert("Please select a record to modify")
     }
  }

  
handleInput = (e, key, value) => {
    this.setState({ [key]: value });
  }

  reloadPage=()=>{
    this.setState({
        addGoal:false, 
        editGoal:false,
        addTask:false,
        editTask:false,
        openTemplate:false,
        addProblem:false,
        editProblem:false
        
       })
    this.setState({
        goalSelected:'',
        goalSelectedLength:-1,
        taskSelected:'',
        taskSelectedLength:-1,
        problemSelected:'',
        problemSelectedLength:-1

       })
    this.setState({reloadKey:Math.random()})
 this.list_problems();
}
 
  removeProblem=(ids,action,totalLength)=>
  {
  if(action=='selection')
  {
    this.state.problemSelected=ids;
    this.state.problemSelectedLength=totalLength;
  }
  if(action=='delete')
  {
      if (this.state.problemSelectedLength> 0) {
            const deleteData={tb_name:'condition_master_tb',ids:this.state.problemSelected,primary_key:'condition_id'}
            confirmAlert({
              title: '',
              message: 'You are about to delete problem(s). Do you want to proceed?',
              buttons: [
              {
                  label: 'Yes',
                  onClick: () => {
                  this.props.apiDelete(deleteData).then(
                  res => this.reloadPage() ,
                  NotificationManager.success(
                  'Problem(s) deleted',
                  )
                );
              },
          },
          {
            label: 'No',
            onClick: () => { this.reloadPage()},
          },
        ],
      });
    } 
    else 
    {
      NotificationManager.error('Please select record(s) to be deleted');
    }
  }
 }

  addCheck=()=>
{
  alert("Please deselect the record to proceed")
}

apiRestoreDefault=(tbl)=>
{
   const restorData={tb:tbl}
   this.props.apiRestoreDefault(restorData).then(
     res=> this.reloadPage(),
     NotificationManager.success(
      'Previous records restored Successfully',
      )
   )
}

list_problems = () => {
    this.props.list_problems().then((res) => {
        console.log(res.data.content);
        this.setState({all_problems: res.data.content});
    })
    }
addProbleme = () => {
    //alert(this.state.custom_text);
    console.log("All Problems",this.state.all_problems);
    //alert(this.state.goalSelected);
    let pblm_selected_arr = this.state.problemSelected.split(',');
    
    let total_selected_problems = (this.state.custom_text!="" && this.state.custom_text!=undefined) ? this.state.custom_text+", ": "";
    let total_selected_problem_ids= "";
    for(let i = 0; i< this.state.all_problems.length; i++){
        for(let j =0; j<pblm_selected_arr.length; j++){
          if(this.state.all_problems[i].condition_id == pblm_selected_arr[j]){
              total_selected_problems += this.state.all_problems[i].name+ ", ";
              total_selected_problem_ids += this.state.all_problems[i].condition_id+ ", ";
              
          }
        }
        
    }
    this.props.addProblemEPC(total_selected_problems, total_selected_problem_ids);
    //alert(total_selected_goals);
}




  render() {
    return (
      <div class="invesvalue">
         <Popup open={this.props.open} modal closeOnDocumentClick={false}>
        <div style={{ width:'800px', marginLeft:'250px', backgroundColor:'#FFF', float:'left'}}>
        <div class="modal-header">
          <div className="popup-title">
            <span> Add Problem  <Showhelp gotohelp="problem_popup_values"/></span> 
           
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => this.props.close()}>
                ×
              </span>
            </button>
            </div>
            </div>

            <div className="modal-body addinvestigation ">
            <div
          className="col-sm-12"
       
          style={{ overflow: "auto" }}
          id="Graphdetails"
        >
          {/* <h5
            style={{
              backgroundColor: "#f4f4f4",
              padding: "5px 10px",
              marginBottom: 10
            }}
          >
             
          </h5> */}
          <div    id="ivntableadd" className="form-group row">
       
            {/* <label className="col-sm-3 col-form-label text-left">
              Family Name:
            </label>
            <div className="col-sm-3">
              <input type="text" className="form-control form-control-sm" />
            </div> */}
          </div>

          <div className="row">
            <div className="col-sm-3 mt-3 pr-0">
              <label>Enter Problem or Select From List:</label>
            </div>
            <div className="col-sm-9 pl-0">
              <input className="form-control form-control-sm rq-form-elemen" type="text" onChange={e => this.handleInput(e, "custom_text", e.target.value)}/ >
            </div>
          </div>

              <div className="form-group row">
      
                                                <div className="col-lg-12 m-t-60 mb-3" style={{textAlign:'right'}}>
		                                             <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary btn-xs" data-toggle="modal" data-target="#care-plan-goals-add-btn" onClick={(e)=>{this.state.problemSelectedLength > 0 ?this.addCheck():this.setState({addProblem:true})}}>Add</button>
		                                             <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary btn-xs" data-toggle="modal" data-target="#care-plan-goals-edit-btn" onClick={(e)=>this.editProblem()}>Edit</button>
                                                   <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary btn-xs" onClick={(e)=>this.removeProblem('','delete','')}>Remove</button>
                                               </div>
	                                                <div className="col-sm-12 col-md-12 col-xl-12 m-t-10 table-border">
	                                                   <div className="card" style={{paddingLeft: '0px', height:'200px', overFlowY:'auto' }}>
	                                                      <div className="card-block">
                                                         <CommonGrid
                                                                    crud={this.state.problemCrud}
                                                                    key={this.state.reloadKey}
                                                                />   
	                                                      </div>
	                                                   </div>
	                                                </div>
                                                  
                                                   {this.state.addProblem ?
                                                      <AddProblem showModal={this.state.addProblem} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                   {this.state.editProblem ?
                                                    <AddProblem showModal={this.state.editProblem} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.problemSelected}/> :''}
                                                </div> 
                                           
         
        

               
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-xs"
                onClick={(e) => {
                  this.addProbleme(e);
                }}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
                onClick={() => this.props.close()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popup>
        
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
 return {
    list_problems: data => dispatch(api_list_problems(data))
  };
};
const mapStateToProps = state => {
  
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProblemPopup);
