import axios from "axios";
import "./DS.Appointments";
import { async } from "q";
export const dropDown = (table, value, display, default_value) => {
  var obj = {};
  
  dropDowna(table, value, display, default_value)
  .then(data => {
   
    obj["type"] = "string";
    obj["default"] = default_value;
    obj["enum"] = data.enum;
    obj["enumNames"] = data.enumNames;
     
  })
   
  return obj;
};

const promiseTimeout = time => () =>
  new Promise(resolve => setTimeout(resolve, time));

export const dropDowna = async (table, value, display, default_value) => {
  let url = `${process.env.REACT_APP_BASE_URL}api=general&action=d`;
  let query = {
    tb: table,
    value: value,
    display: display,
    default_whr:default_value
  };
  var res = await axios.post(url, query);
  var data = await res.data;
  let a = {
    type: "string",
    default: default_value,
    enum: data.content.value,
    enumNames: data.content.display
  };
  console.log(a);
  return a;
};
export const addForm = async data => {
  let url = `${process.env.REACT_APP_BASE_URL}api=general&action=a`;
  var res = await axios.post(url, data);
  return res;
};

export const loadEditform = async data => {
  console.clear();
  console.log("services", data);
  let url = `${process.env.REACT_APP_BASE_URL}api=general&action=e`;
  var res = await axios.post(url, data);
  return res;
};

export const editForm = async data => {
  console.clear();
  console.log("services", data);
  let url = `${process.env.REACT_APP_BASE_URL}api=general&action=u`;
  var res = await axios.post(url, data);
  return res;
};

export const deleteItem = async data => {
  console.clear();
  console.log("services", data);
  let url = `${process.env.REACT_APP_BASE_URL}api=general&action=de`;
  var res = await axios.post(url, data);
  return res;
};

export const selectData= async data => {
  console.clear();
  console.log("services", data);
  let url = `${process.env.REACT_APP_BASE_URL}api=general&action=u`;
  var res = await axios.post(url, data);
  return res;
};