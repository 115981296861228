import React, { Component } from 'react';
import AddCorrespondence from './addCorrespondence';
import EditCorrespondence from './editCorrespondence';
import { connect } from 'react-redux';
import {
  api_get_correspondence,
  api_delete_correspondence,
  api_get_recieved,
  api_get_sent,
  api_list_selected,
  api_list_filtered_data,
  api_list_dist_category,
  api_list_dist_subject,
  api_list_dist_from,
  api_get_image,
  api_get_type
} from '../../../DS/DS.Correspondence';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import ViewCorrespondenceRecord from "./viewCorrespondenceRecord";
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import MoveLocation from "./moveLocation";
import AddCorrespondenceOut from "./addCorrespondenceOut"
import ViewClinicalRecord from './../clinical/viewClinicalRecord';
import CorrespondenceEditor from './correspondenceEditor';
class Correspondence extends Component{
  constructor(props){
    super(props);
    this.a = 0;
    this.b= 0;
    this.c = 0;
    this.filter = false;
    this.deleteCorrespondenceIn=this.deleteCorrespondenceIn.bind(this);
    this.deleteCorrespondenceOut=this.deleteCorrespondenceOut.bind(this);
    this.getAllId=this.getAllId.bind(this);
    this.updateInGrid = this.updateInGrid.bind(this);
    this.state = {
      crudin:
      {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible:true,
          isPagination: true,
          isFiltering: true,
          isSelection:true,
          deleteFunction:this.deleteCorrespondenceIn,
          isDateGroup:false,
          isYearSeparator:false,
          dateCol:'',
          cellClicked:this.showimage,
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "patients_correspondence",
            cols: [
              "patients_correspondence_id",
              "DATE_FORMAT(date_checked,'%d-%m-%Y') as date_checked",
              "subject",
              "category",
              "DATE_FORMAT(patients_correspondence.correspondence_date,'%d-%m-%Y') as correspondence_date",
              "details",
              "from_guid",
              "Firstname",
              "Middlename",
                            
            ],
           where: [
              {
                type: "and",
                key: "patient_id",
                operator: "=",
                value: this.props.redux_patientId
           },
           {
            type: "and",
            key: "patients_correspondence.isDeleted",
            operator: "=",
            value: 0
          },
          {
            type: "and",
            key: "isInOut",
            operator: "=",
            value: 0
          }, {
            type: "and",
            key: "patients_correspondence.isConfidencial",
            operator: "=",
            value: 0
          }],
           joins:[
            {
              joinType: "inner",
              joinTable: "doctor_tb",
              joinCondition: "patients_correspondence.from_guid = doctor_tb.doctor_id"
            },
          /*  {
              joinType: "inner",
              joinTable: "contacts",
              joinCondition: "patients_correspondence.to_guid = contacts.contact_id"
            }*/
           ],
           customCols: [  
            "CONCAT(Firstname,' ',surname) AS docname"
            
          ],
            primary_key:"patients_correspondence_id",
          },
          viewtabletitle: "",
          columns: [
            { name: "correspondence_date", title: "Date" },
            { name: "docname"  , title: "From" },
            { name: "category" , title: "Category" },
            { name: "subject"  , title: "Subject"},
            { name: "details"  , title: "Details" },
            { name: "date_checked" , title : "Date Checked"}
           
          ],
          rows: [],
          sortinit: [{ columnName: "patients_correspondence_id", direction: "desc" }],
          pagesize: 10
        }

      },
      crudout:
      {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible:true,
          isPagination: true,
          isFiltering: true,
          isSelection:true,
          deleteFunction:this.deleteCorrespondenceOut,
          isDateGroup:false,
          isYearSeparator:false,
          dateCol:'',
          cellClicked:this.showimage,
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "patients_correspondence",
            cols: [
              "patients_correspondence_id",
              "DATE_FORMAT(date_checked,'%d-%m-%Y') as date_checked",
              "subject",
              "category",
              "DATE_FORMAT(patients_correspondence.correspondence_date,'%d-%m-%Y') as correspondence_date",
              "details",
              "from_guid",
              "Firstname",
              "Middlename",
              
              
            ],
           where: [
              {
                type: "and",
                key: "patient_id",
                operator: "=",
                value: this.props.redux_patientId
           },
           {
            type: "and",
            key: "patients_correspondence.isDeleted",
            operator: "=",
            value: 0
          },
          {
            type: "and",
            key: "isInOut",
            operator: "=",
            value: 1
          },
          {
           type: "and",
           key: "patients_correspondence.isConfidencial",
           operator: "=",
           value: 0
         }],
           joins:[
            {
              joinType: "inner",
              joinTable: "doctor_tb",
              joinCondition: "patients_correspondence.from_guid = doctor_tb.doctor_id"
            },
           /* {
              joinType: "inner",
              joinTable: "contacts",
              joinCondition: "patients_correspondence.to_guid = contacts.contact_id"
            }*/
           ],
           customCols: [  
            "CONCAT(Firstname,' ',surname) AS docname"
            
          ],
            primary_key:"patients_correspondence_id",
          },
          viewtabletitle: "",
          columns: [ 
            { name: "correspondence_date", title: "Date" },
            { name: "docname"  , title: "From" },
            { name: "category" , title: "Category" },
            { name: "subject"  , title: "Subject"},
            { name: "details"  , title: "Details" },
            { name: "date_checked" , title : "Date Checked"}
           
          ],
          rows: [],
          sortinit: [{ columnName: "patients_correspondence_id", direction: "desc" }],
          pagesize: 10,
          getAllIds:true,
          getallId:this.getAllId
        }

      },

    corr_recieve : [],
    corr_sent: [],
    selectedRecieve: [],
    selectedSent: [],
    patient_id: '1',
    slectedCorres: [],
    sel_date:'',
    filtered: false,
    filter_type: [],
    distcat: [],
    distsub: [],
    distfrom: [],
    sub: '',
    cat: '',
    from: '',
    disable: true,
    keyIn:0,
    keyOut:0,
    selectedInId:-1,
    selectedInLength:0,
    selectedOutId:'',
    selectedOutLength:0,
    recordcorrespondencein:false,
    recordcorrespondenceout:false,
    tomove:false,
    editCorrespondence:false,
    addCorrespondence:false,
    opencoresout:false,
    imgname:'',
    showFull:false,
    editCorrespondenceout:false,
    showImg:false,
    tomoveout:false,
    csschange:[],
    showmovedimage:false,
    showmovednote:false,
    clickedId:''
  } 

   }
  
  componentWillMount(){
    //document.addEventListener("keydown", this.escFunction, false);
    this.state.styles = {
      changeto: {
          float:'left'
      }

    }
  }

  getAllId=(allids)=>
  {
    console.log(allids);
  }
  updateInGrid=()=>
  {
    this.setState({recordcorrespondencein:false,editCorrespondence:false,
      addCorrespondence:false,showImg:false})
    this.setState({keyIn:Math.random()})
    this.setState({showFull:false})

  }
  updateOutGrid=()=>
  {
    this.setState({recordcorrespondenceout:false,opencoresout:false})
    this.setState({keyOut:Math.random()})
  }
  deleteCorrespondenceIn = (GselectedInId,inAction,GselectedInlength) =>
  {
      if(inAction=='selection')
      {
        this.state.selectedInId=GselectedInId;
        this.state.selectedInLength=GselectedInlength;

      }
      else if(inAction=="delete")
      {
              if (this.state.selectedInLength> 0) {
                confirmAlert({
                  title: '',
                  message: 'You are about to delete received correspondence record. Do you want to proceed ? ',
                  buttons: [
                  {
                      label: 'Yes',
                      onClick: () => {
                        this.props.deleteCorrespondence({id:this.state.selectedInId,isinout:0}).then(
                          req=>this.updateInGrid(),
                      NotificationManager.success(
                      'Received correspondence deleted successfully',
                      //this.changeKey()
                      )
                    );
                  },
              },
              {
                label: 'No',
                onClick: () => { this.updateInGrid()},
              },
            ],
          });
        } else {
          NotificationManager.error('Please select received correspondence record(s) to be removed from the list');
        }
        
      }
  }
  deleteCorrespondenceOut = (selectedOutId,outAction,selectedOutlength) =>
  {
      if(outAction=='selection')
      {
        this.state.selectedOutId=selectedOutId;
        this.state.selectedOutLength=selectedOutlength;

      }
      else
      {
                if (this.state.selectedOutLength> 0) {
                  confirmAlert({
                    title: 'Delete record',
                    message: 'Do you want to delete the record?',
                    buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                          this.props.deleteCorrespondence({id:this.state.selectedOutId,isinout:1}).then(
                            req=>this.updateOutGrid(),
                            NotificationManager.success(
                            'Record deleted',
                            )
                        );
                    },
                },
                {
                  label: 'No',
                  onClick: () => { this.updateGridOut()},
                },
              ],
            });
          } else {
            NotificationManager.error('Please select a record to delete');
          }
      }
  }
  recordCorrespondenceIn =() =>
  {
    if(this.state.selectedInLength==1)
    {
      this.setState({recordcorrespondencein:true})
    }
    else
    {
        alert("Please select single record to proceed")
    }
   
  }
  
  recordCorrespondenceOut =() =>
  {
    this.setState({recordcorrespondenceout:true})
  }
  movelocation = () =>
  {
    if(this.state.selectedInLength > 0)
    {
      this.setState({tomove:true})
    }
    else
    {
      alert("Select record from correspondence received")
    }
    
  }
  updateBothGrid =() =>
  {
    this.setState({recordcorrespondenceout:false,keyOut:Math.random(),recordcorrespondencein:false,keyIn:Math.random(),tomove:false,tomoveout:false,showmovednote:false,showmovedimage:false})
   
  }
  addeditCorrespondence=(action)=>
  {
    if(action=='add')
    {
      this.setState()
    }
    else
    {

    }
  }
  showimage=(id,action)=>
  {
   // this.setState({showImg:false})
   //alert("IN")
    this.props.getimage({params:{recordid:id.patients_correspondence_id}}).then(req=>
        this.setState({imgname:req.data.content[0].image_name,showImg:true})
      )
  }
  changeImage=(e)=>
  {
   // alert(e)
    if(e==1)
    {
      this.setState({showFull:true})
    }
    else if(e==3)
    {
      this.state.styles = {
        changeto: {
            float:'right'
        },
  
      }
      this.setState({key:Math.random()})
    }
    else if(e==0)
    {
      this.state.styles = {
        changeto: {
            float:'left'
        },
  
      }
      this.setState({key:Math.random()})
    }
    else if(e==2)
    {
      this.viewImage();
    }
   // alert(this.state.styles.changeto)

  }
  viewImage=()=>
  {
   //  alert("IN",this.state.selectedRec)
     if(this.state.selectedInLength==1)
     {
        this.setState({showImg:true,clickedId:this.state.selectedInId})
     }
     else
     {
       alert("Please select a record to view correspondence image")
     }
  }
  escFunction(event){
    if(event.keyCode === 27) {
      //Do whatever when esc is pressed
    //  console.log(this.props)
     // this.updateInGrid();
    }
  }
  movelocationout = () =>
  {
    if(this.state.selectedOutLength > 0)
    {
      this.setState({tomoveout:true})
    }
    else
    {
      alert("Select record from correspondence sent to move")
    }
    
  }
  checkImage=()=>
  {
    if(this.state.selectedOutLength == 1)
    {
      const typeid={id:this.state.selectedOutId}
      this.setState({clickedId:this.state.selectedOutId})
       this.props.gettype(typeid).then(req=>
          {
            
            if(req.data.content[0].has_image==1)
            {
              this.setState({showmovedimage:true})
            }
            else
            {
              this.setState({showmovednote:true})
            }
          }
        )
        
    }
   else{
       alert("Plese select single record to view correspondence document")
   }
  }
  checkImageIn=()=>
  {
    if(this.state.selectedInLength == 1)
    {
      const typeid={id:this.state.selectedInId}
      this.setState({clickedId:this.state.selectedInId})
       this.props.gettype(typeid).then(req=>
          {
            
            if(req.data.content[0].has_image==1)
            {
              this.setState({showmovedimage:true})
            }
            else
            {
              this.setState({showmovednote:true})
            }
          }
        )
        
    }
   else{
       alert("Plese select single record to view correspondence document")
   }
  }

  
  render(){
    
        return(
           <div id="notesdiv6" className="col-md-12" style={{paddingLeft: 0, paddingRight: 0}}>
  <div className="panel panel-default mb-0">
    <div className="panel-heading panspace">
      <div className="col-lg-12 col-xl-12">
        <h4 className="panel-title">
          <a href="#" className="collapsed" aria-expanded="false"> Correspondence </a>
        </h4>
        <div style={{ width: "auto", height: 30, position: "absolute", right: 15, top: 0}}>
          <span className="makescreen"><a href="#" title="Make This Default Screen" className="collapsed" aria-expanded="false"><i className="fa fa-star panelicon" aria-hidden="true" /></a></span>
          <a href="#" title="Email" className="collapsed" aria-expanded="false"><i className="fas fa-envelope panelicon" aria-hidden="true" /></a>
          <a href="#" title="Print" className="collapsed" aria-expanded="false"><i className="fas fa-print panelicon" aria-hidden="true" /></a>
          {/*<a href="#" title="Export XLs"><i class="fas fa-file-excel-o" aria-hidden="true" style="font-size:12px; margin-right:5px;"></i></a>*/}
          <a title="Export PDF" onclick="recentVisitmini();" className="collapsed" aria-expanded="false"><i className="fa fa-file-pdf-o panelicon" aria-hidden="true" /></a>
        </div>
      </div>
    </div>
  </div>
  <div className="col-lg-12 classic-tabs" style={{float: 'left', width: '100%', marginTop: 5}}>
    <div className="row">
      <div className="col-xs-12 pl-0">
        <div className="col-sm-12" style={{paddingLeft: 0}}>
          <div className="col-sm-7" style={this.state.styles.changeto}>
            {(this.state.addCorrespondence) ? 
            <AddCorrespondence showmodal={this.state.addCorrespondence} patient_id={this.props.redux_patientId} changekey = {this.updateInGrid} sid=''/> : '' }
            {(this.state.editCorrespondence) ?
            <AddCorrespondence showmodal={this.state.editCorrespondence} patient_id={this.props.redux_patientId} changekey = {this.updateInGrid} sid={this.state.selectedInId}/>: ''}
           <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '0px 5px 0px 0px'}} onClick = {(e) => {this.setState({addCorrespondence:true})}}>Add</button>
            <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '0px 5px 0px 0px'}} onClick = {e => this.deleteCorrespondenceIn('','delete','')} >Delete</button>
            <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '0px 5px 0px 0px'}} onClick = {(e) => {this.setState({editCorrespondence:true})}}>Details</button>
            <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '0px 5px 0px 0px'}} onClick={(e) => this.checkImageIn()}>View Document</button>
            {/*<button id="session-timeout-dialog-logout" type="button" className="btn btn-default" style={{margin: '8px 5px 0px 0px', padding: '5px 10px'}}>Scan</button>
            */}<button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '0px 5px 0px 0px'}} onClick = {e => this.movelocation()}>Move Location</button>
            
            <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '0px 5px 0px 0px'}} onClick = {e => this.recordCorrespondenceIn()}>Record Note</button>
            {(this.state.recordcorrespondencein) ?
              <ViewCorrespondenceRecord sid={this.state.selectedInId} showNote={this.state.recordcorrespondencein} changeKey={this.updateInGrid} correspondencetype="Correspondence In" showbutton="yes"/> : ''}
           </div>
          <div className="col-sm-5" style={{float: 'left', marginLeft:'-5px'}}>
            <select placeholder="Subject" style={{float: 'left', width: 'auto', marginRight:'10px', marginTop:'0px', marginBottom: 0,}} onChange={(e)=>this.changeImage(e.target.value)}>
              <option value="0">Preview - Right</option>
              <option value="1">Preview - Bottom</option>
              <option value="2">Preview - Full</option>
              <option value="3">Preview - Left</option>
            </select>
            <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '3px 5px 0px 0px'}}>Send Email</button>
            {(this.state.tomove) ?
              <MoveLocation sid={this.state.selectedInId} showNote={this.state.tomove} changeKey={this.updateBothGrid} popfor="inData"/> : ''}
            {(this.state.showImg) ?
          <ViewClinicalRecord sid={this.state.selectedInId} showImg={this.state.showImg} changeKey={this.updateInGrid} showfor='correspondence'/> : ''}

          </div>
        </div>
        <div className="col-sm-7" style={this.state.styles.changeto}>
          <div className="col-sm-12 no-left-padding"  style={this.state.styles.changeto}>
            <div style={{float: 'left', width: '100%', marginTop:'0px', border:'1px'}} className="record-note-wrapper">
            <h5 class="sheadtitle">Communication Received</h5>

                <CommonGrid
                            handleButton={this.handleButton}
                            crud={this.state.crudin}
                            key={this.state.keyIn}
                          />
            </div>
          </div>
          <div className="col-sm-12" style={{paddingRight: 0, paddingLeft: 0, marginTop:'0px'}}>
          	            <div className="col-sm-12" style={{paddingLeft:'0'}}>
              <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '0px 5px 0px 0px'}} onClick={(e)=>{this.setState({opencoresout:true})}}>Add</button>
              <button  onClick = {e => this.deleteCorrespondenceOut('','delete','')} id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '0px 5px 0px 0px'}}>Delete</button>
              <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '0px 5px 0px 0px'}} onClick = {e => this.recordCorrespondenceOut()}>Record Note</button>
               <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '0px 5px 0px 0px'}} onClick = {(e) => {this.setState({editCorrespondenceout:true})}}>Details</button>
              <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '0px 5px 0px 0px'}}>Import</button>
              <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '0px 5px 0px 0px'}} onClick = {e => this.movelocationout()}>Move Location</button>
              <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '0px 5px 0px 0px'}} onClick={()=>this.checkImage()}>View Document</button>
             
            </div>
            {(this.state.recordcorrespondenceout) ?
              <ViewCorrespondenceRecord sid={this.state.selectedOutId} showNote={this.state.recordcorrespondenceout} changeKey={this.updateOutGrid} correspondencetype="Correspondence Out"/> : ''}
           {(this.state.opencoresout) ? 
           <CorrespondenceEditor showmodal={this.state.opencoresout}  changeKey={this.updateOutGrid} patient_id={this.props.redux_patientId} sid='' showfor=''/>
           :''}
           {(this.state.editCorrespondenceout) ?
            <AddCorrespondenceOut showmodal={this.state.editCorrespondenceout} patient_id={this.props.redux_patientId} changeKey = {this.updateOutGrid} sid={this.state.selectedOutId}/>: ''}
            <div style={{float: 'left', width: '100%', margintop:'5px', border:'1px'}} className="record-note-wrapper">
            <h5 class="sheadtitle">Communication Sent</h5>
                          <CommonGrid
                            handleButton={this.handleButton}
                            crud={this.state.crudout}
                            key={this.state.keyOut}
                          />
            </div>
          </div>
          {(this.state.tomoveout) ?
              <MoveLocation sid={this.state.selectedOutId} showNote={this.state.tomoveout} changeKey={this.updateBothGrid} popfor="outData"/> : ''}
            
        </div>
        <div className="col-sm-5" style={{paddingLeft: 0, paddingRight: 0}}>
          <div style={{width: '100%', height: '765px', overflowY: 'auto', marginTop:'0px', border:'1px solid #ddd',padding:'5px'}}>
          { this.state.showImg ?
            <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}Correspondence/`+this.state.imgname} style={{width: '100%'}} />:'No Preview' }
          </div>
        </div>			
        </div>
    </div>
  </div>
  {(this.state.showmovedimage) ?
          <ViewClinicalRecord sid={this.state.clickedId} showImg={this.state.showmovedimage} changeKey={this.updateBothGrid} showfor='correspondence'/> : ''}

  {(this.state.showFull) ?
          <ViewClinicalRecord sid={this.state.clickedId} showImg={this.state.showFull} changeKey={this.updateInGrid} showfor='correspondence' image={this.state.imgname}/> : ''}
   {(this.state.showmovednote) ? 
           <CorrespondenceEditor showmodal={this.state.showmovednote}  changeKey={this.updateBothGrid} patient_id={this.props.redux_patientId} sid={this.state.clickedId} showfor=''/>
           :''}
          
</div>

        )
    }
}
const mapDispatchToProps = (dispatch) => {
	return {
    get_correspondence: data => dispatch(api_get_correspondence(data)),
    get_sent: data => dispatch(api_get_sent(data)),
    get_recieved: data => dispatch(api_get_recieved(data)),
    deleteCorrespondence: data => dispatch(api_delete_correspondence(data)),
    list_selected: data => dispatch(api_list_selected(data)),
    list_filtered_data: data => dispatch(api_list_filtered_data(data)),
    list_dist_category: data => dispatch(api_list_dist_category(data)),
    list_dist_subject: data => dispatch(api_list_dist_subject(data)),
    list_dist_from: data => dispatch(api_list_dist_from(data)),
    getimage:data => dispatch(api_get_image(data)),
    gettype:data =>dispatch(api_get_type(data))
  }
}
const mapStateToProps = state => {

  let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';

return {
  redux_patientId
};
};

export default connect (mapStateToProps, mapDispatchToProps) (Correspondence);
