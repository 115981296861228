
import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import {api_add_antenantal_result,api_get_ante} from '../../../DS/DS.ObstetricHistory'
import PregnancyDetails from './pregnancyDetails'
import { api_getDoctors } from '../../../DS/DS.Appointments';
import AntenatalOther from './antenatalOther';
import Showhelp from './../../showHelp';

class AntenatalResult extends Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            open:this.props.showmodal,
            key:0,
            doc_id:this.props.redux_docId,
            patitne_id:this.props.redux_patientId,
            preg_id:this.props.id,
            firstvisit:new Date(),
            secondvisit:new Date(),
            thirdvisit:new Date(),
            forthvisit:new Date(),
            showfirst:true,
            showsecond:true,
            showthird:true,
            showforth:true,
            hb:'',
            bloodgroup:'',
            antibody:'',
            rubella:'',
            syphlis:'',
            cmv:'',
            hepab:'',
            hepac:'',
            hiv:'',
            urine:'',
            scndantibody:'',
            scndhb:'',
            diabe:'',
            gtt:'',
            thirdantibody:'',
            thirdhb:'',
            groupb:'',
            c1:false,
            c2:false,
            c3:false,
            c4:false,
            openanten:false,
            otherData:[]

        }
    }
    componentWillMount=()=>
    {
        this.props.getAnte({preg:this.state.preg_id}).then(res=>
            {
                if(res.data.content.length!==0)
                {
                    console.log("********************************",res.data.content.length)
                    this.setState({ 
                        firstvisit: moment(new Date(res.data.content[0].firstVisit)).format('YYYY/MM/DD'),
                        secondvisit: moment(new Date(res.data.content[0].secondvisit)).format('YYYY/MM/DD'),
                        thirdvisit: moment(new Date(res.data.content[0].thirdVisit)).format('YYYY/MM/DD'),
                        forthvisit: moment(new Date(res.data.content[0].forthVisit)).format('YYYY/MM/DD'),
                        showfirst:res.data.content[0].showfirst,
                        showsecond:res.data.content[0].showsecond,
                        showthird:res.data.content[0].showthird,
                        showforth:res.data.content[0].showforth,
                        hb:res.data.content[0].hb,
                        bloodgroup:res.data.content[0].bloodgroup,
                        antibody:res.data.content[0].antibody,
                        rubella:res.data.content[0].rubella,
                        syphlis:res.data.content[0].syphlis,
                        cmv:res.data.content[0].cmv,
                        hepab:res.data.content[0].hepab,
                        hepac:res.data.content[0].hepac,
                        hiv:res.data.content[0].hiv,
                        urine:res.data.content[0].urine,
                        scndantibody:res.data.content[0].secondantibody,
                        scndhb:res.data.content[0].secondhb,
                        diabe:res.data.content[0].diabetes,
                        gtt:res.data.content[0].gtt,
                        thirdantibody:res.data.content[0].thirdantibody,
                        thirdhb:res.data.content[0].thirdhb,
                        groupb:res.data.content[0].groupb,
                        c1:res.data.content[0].showfirst,
                        c2:res.data.content[0].showsecond,
                        c3:res.data.content[0].showthird,
                        c4:res.data.content[0].showforth})

                        if(res.data.content[0].showfirst=='1')
                        {
                            this.setState({showfirst:false})
                        }
                        if(res.data.content[0].showsecond=='1')
                        {
                            this.setState({showsecond:false})
                        }
                        if(res.data.content[0].showthird=='1')
                        {
                            this.setState({showthird:false})
                        }
                        if(res.data.content[0].showforth=='1')
                        {
                            this.setState({showforth:false})
                        }
                }
            })
    
    }
    saveAllData=()=>
    {
    
        console.log("&&&&&&&&&&&&&&&&&&&&&&",this.state)
        this.props.saveAnte(this.state).then(res=>
            {
                this.props.changeKey()
            })
    }

    enableDates=(valfor,val)=>
    {
        if(valfor==='first' && val===true)
        {
            this.setState({showfirst:false,c1:1})
        }
        else if(valfor==='first' && val===false)
        {
            this.setState({showfirst:true,c1:0})
        }
        if(valfor=='second' && val==true)
        {
            this.setState({showsecond:false,c2:1})
        }
        else if(valfor=='second' && val==false)
        {
            this.setState({showsecond:true,c2:0})
        }
        if(valfor=='third' && val==true)
        {
            this.setState({showthird:false,c3:1})
        }
        else if(valfor=='third' && val==false)
        {
            this.setState({showthird:true,c3:0})
        }
        if(valfor=='forth' && val==true)
        {
            this.setState({showforth:false,c4:1})
        }
        else if(valfor=='forth' && val==false)
        {
            this.setState({showforth:true,c4:0})
        }
    }
    handleDatefirst=(date)=>
    {
        this.setState({firstvisit:date})
    }
    handleDatesecond=(date)=>
    {
        this.setState({secondvisit:date})
    }
    handleDatethird=(date)=>
    {
        this.setState({thirdvisit:date})
    }
    handleDateforth=(date)=>
    {
        this.setState({forthvisit:date})
    }
    closeOther=(val)=>
    {
        console.log('&&&&&&&&&&&&&&&',val)
        if(val!='')
        {
            this.state.otherData.push({date:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(val.date),
        test:val.test,
    result:val.result,
storedate:val.date})
        }
        this.setState({openanten:false})
    }
    render() {
        return ( 
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div style={{ width:'800px', marginLeft:'200px', backgroundColor:'#FFF', float:'left'}}>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '0px 15px',color:'black'}}>
                        <h6 className="popup-title"> Antenatal results <Showhelp gotohelp="antenatal_result_help"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}  style={{ marginTop: "-28px", right: "20px" }}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ fontSize: "14px" }}>
                        <div className="col-sm-12" style={{marginBottom: '10', height:'500px', overflowY:'scroll'}}>
                            <div className="row mt-3">
                                <div className="col-sm-2">
                                    <label className="col-form-label text-left" style={{fontWeight:'bold', fontSize:'14px'}}>1st visit:</label>
                                </div>
                                <div className="col-sm-10">
                                    <div className="row">
                                        <div className="col-sm-1">
                                            <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('first',e.target.checked)} style={{marginTop:'25px'}}
                                            checked={this.state.c1==1? 'checked':''}
                                            /></div>

                                        <div className="col-sm-3">
                                        <DatePicker
                                         className="datePicker"
                                         onChange={this.handleDatefirst}
                                         dateFormat="dd/MM/yyyy"
                                         id="clinicalcalendar" 
                                         selected={
                                                this.state.dateShow
                                                    ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                    : Date.parse(this.state.firstvisit)
                                                }
                                                disabled={this.state.showfirst}
                                        />  
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-2 mt-3"><label className="col-form-label text-left">HB:</label></div>
                                        <div className="col-sm-3">
                                                <input type="text" name="search_condition" className="form-control" disabled={this.state.showfirst} 
                                                value={this.state.hb} onChange={(e)=>this.setState({hb:e.target.value})}/>
                                        </div>
                                        <div className="col-sm-2 mt-3"><label className="col-form-label text-left">Blood Group:</label></div>
                
                                        <div className="col-sm-4">
                                                <select disabled={this.state.showfirst} value={this.state.bloodgroup} onChange={(e)=>this.setState({bloodgroup:e.target.value})}>
                                                <option value=""></option>
                                                    <option value="O Positive">O Positive</option>
                                                    <option value="O Negative">O Negative</option>
                                                    <option value="A Positive">A Positive</option>
                                                    <option value="A Negative">A Negative</option>
                                                    <option value="B Positive">B Positive</option>
                                                    <option value="B Negative">B Negative</option>
                                                    <option value="AB Positive">AB Positive</option>
                                                    <option value="AB Negative">AB Negative</option>
                                                </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-2 mt-3 pr-0"><label className="col-form-label text-left">Antibody Screen:</label></div>
                                        <div className="col-sm-7">
                                            <input type="text" name="search_condition" className="form-control" disabled={this.state.showfirst}
                                            value={this.state.antibody} onChange={(e)=>this.setState({antibody:e.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2">
                                    <label className="col-form-label text-left" style={{fontWeight:'bold', fontSize:'14px'}}>Serology:</label>
                                </div>
                                <div className="col-sm-10">
                                    <div className="row">
                                        <div className="col-sm-2 mt-2"><label className="col-form-label text-left">Rubella:</label></div>
                                        <div className="col-sm-2">
                                            <select disabled={this.state.showfirst}  
                                            value={this.state.rubella} onChange={(e)=>this.setState({rubella:e.target.value})}>
                                            <option value=""></option>
                                                <option value="Immune">Immune</option>
                                                <option value="Non-Immune">Non-Immune</option>
                                                <option value="Low Immunity">Low Immunity</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-1 mt-3"><label className="col-form-label text-left">syphlis:</label></div>
                                        <div className="col-sm-3">
                                            <select disabled={this.state.showfirst}
                                            value={this.state.syphlis} onChange={(e)=>this.setState({syphlis:e.target.value})}>
                                            <option value=""></option>
                                                <option value="Positive">Positive</option>
                                                <option value="Negative">Negative</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-1 mt-3"><label className="col-form-label text-left">CMV:</label></div>
                                        <div className="col-sm-3">
                                            <select disabled={this.state.showfirst}
                                            value={this.state.cmv} onChange={(e)=>this.setState({cmv:e.target.value})}>
                                            <option value=""></option>
                                                <option value="Positive">Positive</option>
                                                <option value="Negative">Negative</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-2 mt-3"><label className="col-form-label text-left">Hepatitis B:</label></div>
                                        <div className="col-sm-2">
                                            <select disabled={this.state.showfirst}
                                            value={this.state.hepab} onChange={(e)=>this.setState({hepab:e.target.value})}>
                                            <option value=""></option>
                                                    <option value="Positive">Positive</option>
                                                    <option value="Negative">Negative</option>
                                                </select>
                                        </div>
                                        <div className="col-sm-1 mt-3 pr-0 pl-0"><label className="col-form-label text-left">HepatitisC:</label></div>
                                        <div className="col-sm-3">
                                        <select disabled={this.state.showfirst}
                                        value={this.state.hepac} onChange={(e)=>this.setState({hepac:e.target.value})}>
                                        <option value=""></option>
                                                <option value="Positive">Positive</option>
                                                <option value="Negative">Negative</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-1 mt-3"><label className="col-form-label text-left">HIV:</label></div>
                                        <div className="col-sm-3">
                                        <select disabled={this.state.showfirst}
                                        value={this.state.hiv} onChange={(e)=>this.setState({hiv:e.target.value})}>
                                        <option value=""></option>
                                                <option value="Positive">Positive</option>
                                                <option value="Negative">Negative</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-2 mt-3"><label className="col-form-label text-left">Urine M/C/S:</label></div>
                                        <div className="col-sm-10"><input type="text" name="search_condition" className="form-control" disabled={this.state.showfirst} 
                                        value={this.state.urine} onChange={(e)=>this.setState({urine:e.target.value})}/></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-sm-2">
                                <label className="col-form-label text-left" style={{fontWeight:'bold', fontSize:'14px'}}>26-28 Weeks:</label>
                            </div>
                            <div className="col-sm-10">
                                <div className="row">
                                    <div className="col-sm-1"><input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('second',e.target.checked)} 
                                    style={{marginTop:'25px'}}  checked={this.state.c2=='1'? 'checked':''}/></div>

                                    <div className="col-sm-3">
                                    <DatePicker
                                         className="datePicker"
                                         onChange={this.handleDatesecond}
                                         dateFormat="dd/MM/yyyy"
                                         id="clinicalcalendar" 
                                         selected={
                                                this.state.dateShow
                                                    ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                    : Date.parse(this.state.secondvisit)
                                                }
                                                disabled={this.state.showsecond}
                                        />  
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-2 mt-3"><label className="col-form-label text-left">Hb:</label></div>
                                    <div className="col-sm-3"><input type="text" name="search_condition" className="form-control"  disabled={this.state.showsecond}
                                    value={this.state.scndhb} onChange={(e)=>this.setState({scndhb:e.target.value})}/></div>
                                    <div className="col-sm-2 mt-3 pr-0"><label className="col-form-label text-left">Antibody screen:</label></div>
                                    <div className="col-sm-3"><input type="text" name="search_condition" className="form-control"  disabled={this.state.showsecond}
                                    value={this.state.scndantibody} onChange={(e)=>this.setState({scndantibody:e.target.value})}/></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-2 mt-3 pr-0"><label className="col-form-label text-left">Diabetes screen:</label></div>
                                    <div className="col-sm-3"><input type="text" name="search_condition" className="form-control" 
                                    disabled={this.state.showsecond} value={this.state.diabe} onChange={(e)=>this.setState({diabe:e.target.value})}/></div>
                                    <div className="col-sm-2 mt-3"><label className="col-form-label text-left">GTT:</label></div>
                                    <div className="col-sm-3"><input type="text" name="search_condition" className="form-control"  
                                    disabled={this.state.showsecond} value={this.state.gtt} onChange={(e)=>this.setState({gtt:e.target.value})}/></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2">
                                <label className="col-form-label text-left" style={{fontWeight:'bold', fontSize:'14px'}}>36 Weeks:</label>
                            </div>
                            <div className="col-sm-10">
                                <div className="row">
                                <div className="col-sm-1"><input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('third',e.target.checked)} 
                                style={{marginTop:'25px'}}  checked={this.state.c3=='1'? 'checked':''}/></div>

                                    <div className="col-sm-3">
                                    <DatePicker
                                         className="datePicker"
                                         onChange={this.handleDatethird}
                                         dateFormat="dd/MM/yyyy"
                                         id="clinicalcalendar" 
                                         selected={
                                                this.state.dateShow
                                                    ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                    : Date.parse(this.state.thirdvisit)
                                                }
                                                disabled={this.state.showthird}
                                        />  
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-2 mt-3"><label className="col-form-label text-left">Hb:</label></div>
                                    <div className="col-sm-3"><input type="text" name="search_condition" className="form-control"  
                                    value={this.state.thirdhb} onChange={(e)=>this.setState({thirdhb:e.target.value})} disabled={this.state.showthird}/></div>
                                    <div className="col-sm-2 mt-3 pr-0"><label className="col-form-label text-left">Antibody screen:</label></div>
                                    <div className="col-sm-3"><input type="text" name="search_condition" className="form-control"  
                                    value={this.state.thirdantibody} onChange={(e)=>this.setState({thirdantibody:e.target.value})} disabled={this.state.showthird}/></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        
                            <div className="col-sm-2">
                                <label className="col-form-label text-left" style={{fontWeight:'bold', fontSize:'14px'}}>Low vaginal swab:</label>
                            </div>
                        
                            <div className="col-sm-10">
                            <div className="col-sm-1"><input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('forth',e.target.checked)} 
                            style={{marginTop:'25px'}}  checked={this.state.c4=='1'? 'checked':''}/></div>

                                <div className="row">
                                    <div className="col-sm-5">
                                    <DatePicker
                                         className="datePicker"
                                         onChange={this.handleDateforth}
                                         dateFormat="dd/MM/yyyy"
                                         id="clinicalcalendar" 
                                         selected={
                                                this.state.dateShow
                                                    ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                    : Date.parse(this.state.forthvisit)
                                                }
                                                disabled={this.state.showforth}
                                        />  
                                    </div>
                                    <div className="col-sm-2 pr-0"><label className="col-form-label text-left">Group B Strep:</label></div>
                                    <div className="col-sm-3">
                                    <select disabled={this.state.showforth} value={this.state.groupb} onChange={(e)=>this.setState({groupb:e.target.value})}>
                                                <option value=""></option>
                                                <option value="Positive">Positive</option>
                                                <option value="Negative">Negative</option>
                                            </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2">
                                <label className="col-form-label text-left" style={{fontWeight:'bold', fontSize:'14px'}}>Imaging reports:</label>
                            </div>
                            <div className="col-sm-10">
                                <div className="row mb-3 mt-3">
                                    <div className="col-sm-10">
                                        <table style={{width: '100%'}}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div style={{width: '100%', height: 60}}>
                                                            <table className="table-bordered" style={{float: 'left', width: '100%'}}>
                                                                <thead>
                                                                    <tr style={{backgroundColor: 'rgb(244, 244, 244)'}}>
                                                                        <th>Date</th>
                                                                        <th>Test</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{overflowY: 'auto'}}>
                                                                    <tr>
                                                                        <td style={{height:'60px'}}></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-2">
                                        <button type="button"  onClick={() => this.setState({openanten:true})} className="btn btn-primary btn-xs" style={{width:'70px', marginBottom:'5px'}} data-dismiss="modal">Add</button>
                                        <button type="button"  className="btn btn-primary btn-xs" data-dismiss="modal" style={{width:'70px', marginBottom:'5px'}}>Edit</button>
                                        <button type="button"  className="btn btn-primary btn-xs" data-dismiss="modal" style={{width:'70px'}}>Delete</button>
                                    </div>     
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2">
                                <label className="col-form-label text-left" style={{fontWeight:'bold', fontSize:'14px'}}>Others:</label>
                            </div>
                            <div className="col-sm-10">
                                <div className="row mb-3 mt-3">
                                    <div className="col-sm-10">
                                        <table style={{width: '100%'}}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div style={{width: '100%', height: 60}}>
                                                            <table className="table-bordered" style={{float: 'left', width: '100%'}}>
                                                                <thead>
                                                                    <tr style={{backgroundColor: 'rgb(244, 244, 244)'}}>
                                                                        <th>Date</th>
                                                                        <th>Test</th>
                                                                        <th>Result</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{overflowY: 'auto'}}>
                                                                {this.state.otherData != undefined && this.state.otherData.length> 0 && this.state.otherData.map((address,i) =>
                                                                    <tr>
                                                                        <td style={{height:'60px'}}>{address.date}</td>
                                                                        <td style={{height:'60px'}}>{address.test}</td>
                                                                        <td style={{height:'60px'}}>{address.result}</td>
                                                                    </tr>
                                                                )}
                                     
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-2">
                                        <button type="button" onClick={() => this.setState({openanten:true})}  className="btn btn-primary btn-xs" style={{width:'70px', marginBottom:'5px'}} data-dismiss="modal">Add</button>
                                        <button type="button"  className="btn btn-primary btn-xs" data-dismiss="modal" style={{width:'70px'}}>Delete</button>
                                    </div>     
                                </div>
                            </div>
                        </div>
                        {this.state.openanten ?
                        <AntenatalOther showmodal={this.state.openanten} changeKey={this.closeOther}/> : ''}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button"  onClick={() => this.saveAllData()} className="btn btn-primary btn-xs" data-dismiss="modal">
                            Save
                    </button>
                    <button type="button"  onClick={() => this.props.changeKey()} className="btn btn-primary btn-xs" data-dismiss="modal">
                        Cancel
                    </button>
                </div>   
            </div>
        )}
    </Popup>
   )}
}   

const mapDispatchToProps = dispatch => {
    return {
            getDoctors: data => dispatch(api_getDoctors(data)),
            saveAnte:data=>dispatch(api_add_antenantal_result(data)),
            getAnte:data=>dispatch(api_get_ante(data))
           
            };
  };
  const mapStateToProps = state => {
   console.log(">>> doc data",state.getDrAppointments.logged_info)
     let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
 
   return {
     redux_patientId,redux_docId
   };
 };
  export default connect(mapStateToProps,mapDispatchToProps)(AntenatalResult);