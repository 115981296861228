import axios from 'axios';


export const api_DeleteImmunisation = (data) => dispatch => {
	console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=immunisations&action=delete_immunisations`, data);
}
export const api_list_patient_immunisations = (data) => dispatch => {
	console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=immunisations&action=list_patient_immunisations`, data);
}
export const api_add_immunisations = (data) => dispatch => {
	console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=immunisations&action=add_immunisations`, data);
}
export const api_get_immunisation = (data) => dispatch => {
	console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=immunisations&action=get_immunisation`, data);
}
export const api_addChildImmunisations = (data) => dispatch => {
	console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=immunisations&action=add_child_immunisations`, data);
}
export const api_edit_immunisations = (data) => dispatch => {
	console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=immunisations&action=edit_immunisations`, data);
}

export const api_list_vaccines = (data) => dispatch => {
	console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=immunisations&action=api_list_vaccines`, data);
}
export const api_updatePatientPreventiveHealth = (data) => dispatch => {
	console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=immunisations&action=update_preventive`, data);
}




