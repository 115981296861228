import React, { Component } from 'react';
import  Header from '../components/header';
import {connect} from 'react-redux';
import  {api_General } from './../DS/DS.Appointments'
import Grid from './crudcomponent/combinedcrud';
class ManageUsers extends Component {
    state = { 
        crud:{
            pagetitle: "",
            query:{
                tb : "patients",
                cols : [
                    "date", 
                    "start_time", 
                    "end_time"
                    ]
            },
            add_form: {
                isVisible : true,
            },
            viewgrid : {
                isVisible : true,
                isPagination : true,
                isFiltering : true,
                isActions : false,
                
                baseurl : 'http://localhost/ai/drafts_projects/supadoc_api/project/?api=general&action=q',
                query:{
                    tb : "patients",
                    cols : [
                        "Firstname", 
                        "user_id",
                        "patient_guid"
                        
                    ]
                },
                viewtabletitle : "List of Users",
                columns : [
                    { name: 'user_id', title: 'UID' },
                    { name: 'Firstname', title: 'First Name' },
                    { name: 'patient_guid', title: 'GUID' },
                    { name: 'action', title: 'Action' },
                  ],
                rows : [],
                sortinit : [{ columnName: 'Firstname', direction: 'asc' }],
                pagesize : 3,
                actions : [
                    {buttonClass : 'info', icon: "fa-eye", text: "", event: "onClick={this.handleOnClick}", function: "handleOnClick" },
                    {buttonClass : 'danger', icon: "fa-trash", text: "", event: "onClick", function: "deleteItem" }
                ]
             }
        }
     }
     componentDidMount(){
            var searchParam = {
                tb:this.state.crud.query.tb,
                cols:this.state.crud.query.cols
            }
            this.props.generalQuery(searchParam).then(res=>{
                let newState = Object.assign({}, this.state);
                newState.crud.viewgrid.rows = res.data.content;
                this.setState(newState);
            });
     }
    render() { 
        return ( <div><Header/> <h1> User Manangement Component </h1><Grid crud={this.state.crud}/></div> );
    }
}


const mapDespatchToProps = (dispatch) => {
    return {
      generalQuery : (searchParam) => dispatch(api_General(searchParam))
    }
    
  }
  

export default  connect(null, mapDespatchToProps) (ManageUsers);
