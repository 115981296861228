import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../../../showHelp';



class respiratory extends Component {
    state = {  }
    render() {
        return (
            <Popup trigger={<a  title="Respiratory" className="collapsed" aria-expanded="false"><span className="respiratory"></span></a>} position="right center"   modal
            closeOnDocumentClick={false}>
             {close => (
               <div style={{ width:'800px', margin:'0px auto'}} >
        <div className="respirat" style={{ width:'800px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>

                 <div className="modal-header" style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}>
                 <h5 className="visittitle"> Respiratory <Showhelp gotohelp="respiratory_help"/> </h5>
                      <button type="button" className="close" style={{ marginTop: '-28px',fontSize: '25px' }} onClick={() => close()} data-dismiss="modal" aria-label="Close">×</button>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Respiratory</h4> */}
                    </div>

                    <div className="modal-body" style={{ border:'0px' }}>
                    
                    <div className="col-sm-12">
                        <div className="col-sm-1 col-sm-02"><h5 class="mt-3 mb-3">History:</h5></div>
                        <div className="col-sm-2 pr-0"><label>&nbsp;</label></div>
                        <div className="col-sm-3"><h5 className="mt-3 mb-3">Examination:</h5></div>
                        <div className="col-sm-2 pr-0"><label>&nbsp;</label></div>
                    </div>
                    
                <div className="col-sm-12">
                  <div className="col-sm-1 col-sm-02">
                            <label>Cough</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Cough === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Cough")}
                    /> Yes</label>
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Cough === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Cough")}
                    /> No</label>
                        </div>

                        <div className="col-sm-3 ">
                            <label>Respiratory distress </label>
                        </div>
                        <div className="col-sm-2">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.respiratoryDistress === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "respiratoryDistress")}
                    /> Yes</label>
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.respiratoryDistress === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "respiratoryDistress")}
                    /> No</label>

                        </div>


                </div>

                <div className="col-sm-12">
                  <div className="col-sm-1 col-sm-02">
                            <label>Sputum</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Sputum === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Sputum")}
                    /> Yes</label>
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Sputum === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Sputum")}
                    /> No</label>

                        </div>

                        <div className="col-sm-3">
                            <label>Recession</label>
                        </div>
                        <div className="col-sm-2">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Recession === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Recession")}
                    /> Yes</label> 
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Recession === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Recession")}
                    /> No</label>
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-1 col-sm-02">
                            <label>Haemoptysis</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Haemoptysis === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Haemoptysis")}
                    /> Yes</label>
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Haemoptysis === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Haemoptysis")}
                    /> No</label>

                        </div>

                        <div className="col-sm-3">
                            <label>Using accessory muscles</label>
                        </div>
                        <div className="col-sm-2">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.UsingAccessoryMuscles === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "UsingAccessoryMuscles")}
                    /> Yes</label>
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.UsingAccessoryMuscles === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "UsingAccessoryMuscles")}
                    /> No</label>

                        </div>

                </div>



                <div className="col-sm-12">
                  <div className="col-sm-1 col-sm-02">
                            <label>Wheeze</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Wheeze === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Wheeze")}
                    /> Yes</label>
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Wheeze === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Wheeze")}
                    /> No</label>

                        </div>

                        <div className="col-sm-3">
                            <label>Reduces air entry</label>
                        </div>
                        <div className="col-sm-2">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.ReducesAirEntry === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "ReducesAirEntry")}
                    /> Yes</label>
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.ReducesAirEntry === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "ReducesAirEntry")}
                    /> No</label>
                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-1 col-sm-02">
                            <label>Pleutritic Pain</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Pleutritic === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Pleutritic")}
                    /> Yes</label>
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Pleutritic === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Pleutritic")}
                    /> No</label>
                        </div>
                        
                        <div className="col-sm-3">
                            <label>creps</label>
                        </div>
                        <div className="col-sm-2">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Creps === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Creps")}
                    /> Yes</label> 
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Creps === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Creps")}
                    /> No</label>
                        </div>

                </div>
                <div className="col-sm-12">
                  <div className="col-sm-1 col-sm-02">
                            <label>Childhood asthama</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.asthama === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "asthama")}
                    /> Yes</label> 
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.asthama === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "asthama")}
                    /> No</label>
                        </div>

                        <div className="col-sm-3">
                            <label>Rhonchi</label>
                        </div>
                        <div className="col-sm-2">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Rhonchi === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Rhonchi")}
                    /> Yes</label>
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Rhonchi === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Rhonchi")}
                    /> No</label>

                        </div>
                </div>


                <div className="col-sm-12 ">
                            <div className="col-sm-2 cus-wid pr-0 mt-2">
                            <label>Peak flow:  </label>
                        </div>
                        <div className="col-sm-2 pr-0">
                            <input type="text" className="form-control form-control-sm mt-0" style={{width: '70px', float:'left'}}
                            value={this.props.vals_examination.PeakFlow}
                            onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "PeakFlow")}
                            />
                        </div>
                        <div className="col-sm-2 pl-0">
                        <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal">Calculator</button>
                        </div>
                 </div>

                 <div className="col-sm-12 ">
                            <div className="col-sm-3 pr-0 mt-2">
                            <label>Chest measurement(cm):  </label>
                        </div>
                        <div className="col-sm-1 col-sm-01 pr-0 mt-2" style={{float: 'left',marginLeft: '-30px'}}>
                        <label>Inspiration  </label>
                        </div>
                        <div className="col-sm-2 pl-0">
                        <input type="text" className="form-control form-control-sm mt-0"
                        value={this.props.vals_examination.Inspiration}
                        onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Inspiration")}
                        />
                        </div>
 
                        <div className="col-sm-1 col-sm-01 pr-0 mt-2">
                        <label>Expiration </label>

                        </div>
                        <div className="col-sm-2 pl-0">
                        <input type="text"  className="form-control form-control-sm mt-0"
                        value={this.props.vals_examination.Expiration}
                        onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Expiration")}
                        />
                        </div>
                 </div>

             </div>

                 <div className="modal-footer"   >
                      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"
                      onClick={e => 
                        {this.props.handleSaveOptions(e, "respiratory");
                        close();}
                        }

                      >Save</button>
                      <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>
 </div>
 </div>




)}
</Popup>
         );
    }
}

export default respiratory;
