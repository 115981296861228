import axios from 'axios';
export const api_get_doctors = () => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=bankAccount&action=get_doctors`);
}
export const api_add_provider = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=bankAccount&action=add_provider`,data);
}
export const api_get_data = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=bankAccount&action=get_data`,data);
}
export const api_delete_provider= (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=bankAccount&action=delete_provider`,data);
}
export const api_update_data= (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=bankAccount&action=update_provider`,data);
}
export const api_save_setting= (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=bankAccount&action=save_setting`,data);
}
export const add_patient_account = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=bankAccount&action=add_patient_account`,data);
}

export const get_patient_account = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=bankAccount&action=get_patient_account`,data);
}


