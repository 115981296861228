import React from "react";
import Popup from "reactjs-popup";
import { MenuItem } from "react-contextmenu";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  api_getDoctors,
  api_modifyAppointments,
  api_getTimeslots
} from "../../DS/DS.Appointments";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import Showhelp from './../showHelp';
class MoveItem extends React.Component {
  state = {
    calendardate: "",
    doctors: [],
    selectedDoctor: "",
    selectedTime: "",
    timeslots: []
  };
  componentDidMount() {
    this.props.closeTabshow(1);
    this.props.getDoctors().then(doctors => {
      this.setState({ doctors: doctors.data.content });
    });
    this.props.getTimeslots().then(timeslots => {
      this.setState({ timeslots: timeslots.data.content });
    });
  }
  handleDate = date => {
    this.setState({ calendardate: date });
  };
  handleDr = e => {
    this.setState({ selectedDoctor: e.target.value });
  };

  handleTime = e => {
    this.setState({ selectedTime: e.target.value });
  };

  handleMove = () => {
    // alert( this.state.calendardate +
    //   " " +
    //   this.state.selectedTime); 


console.log( this.state.selectedTime, new Date() ,
new Date(moment(
  this.state.calendardate +
    " " +
    this.state.selectedTime)
));
    
   
      
if(this.state.selectedDoctor!=''&& this.state.calendardate!='' && this.state.selectedTime !=''){

 //  <
 // new Date(this.state.selected_startDate)

  if (
    new Date(moment().format("YYYY/MM/DD")) <=
    new Date(
      this.state.calendardate
      )
  ) {
   let modifyAppointmentParam = {
      action: "quickmove",
      drId: this.state.selectedDoctor,
      date: this.state.calendardate,
      time: this.state.selectedTime,
      appId: this.props.selected_thisappointment.appoinment_id,
      start_datetime:  
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate(),
      dateSearchParam: {
        start_datetime:  
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate()
      },
    doctorSearchParam: {
      doctor_guid: this.state.selectedDoctor,
      start_datetime:
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate() +
        " 06:00:00",
      end_datetime: new Date(new Date().setDate(new Date().getDate() + 6))
    }};
  


   
    this.props.modifyAppointments(modifyAppointmentParam);
    NotificationManager.success("Successfully Moved");
    this.props.close();

  } else {
    alert("Couldn't book appointment in Past Date/time.");
  }
}

else {
  alert("Please Provide all detail to Move");
 
   
} 


  
  };
  render() {
    console.log(this.props);
    return (
      <Popup open={this.props.open} modal closeOnDocumentClick={false}>
        <div>
          <div className="popup-title">
            <span> Move Item </span> <Showhelp gotohelp="appointment_move_popup"/>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => this.props.close()}>
                ×
              </span>
            </button>

            <div className="modal-body addinvestigation">
              <div className="col-sm-12">
                <div className="col-sm-12">
                  <div className="row form-group">
                    <label className="col-sm-4 col-form-label text-left">
                      Date ({moment(this.props.selected_thisappointment.date).format("DD/MM/YYYY")})
                    </label>
                    <div className="col-sm-12 cursor">
                      <DatePicker
                        //ref={this.textInput}

                        id="date-jumper"
                        selected={this.state.calendardate}
                        onChange={this.handleDate}
                        //showMonthDropdown
                        //useShortMonthInDropdown
                        // showYearDropdown
                        //dateFormatCalendar="MMMM"
                        //yearDropdownItemNumber={15}
                        //scrollableYearDropdown
                        // showTimeSelect
                        // dateFormat="d-mm-yyyy h:mm aa"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="row form-group">
                    <label className="col-sm-4 col-form-label text-left">
                      Time ({this.props.selected_starttime})
                    </label>
                    <div className="col-sm-12 cursor">
                      <select
                        className="form-control"
                        onChange={e => this.handleTime(e)}
                      >
                        <option value=""> Select Time </option>
                        {this.state.timeslots.map(timeslot => (
                          <option value={timeslot.ampm}>
                            {" "}
                            {timeslot.ampm}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="row form-group">
                    <label className="col-sm-6 col-form-label text-left">
                      Doctor ({'Dr.'+this.props.selected_thisappointment.drname})
                    </label>
                    <div className="col-sm-12 cursor">
                      <select
                        className="form-control"
                        onChange={e => this.handleDr(e)}
                      >
                        <option value=""> Select Doctor </option>
                        {this.state.doctors.map(doctor => (
                          <option key={doctor.doctor_id} value={doctor.doctor_id}>
                            {" "}
                            Dr {doctor.Firstname}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  this.handleMove();
                }}
              >
                Move
              </button>

              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => this.props.close()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getDoctors: () => dispatch(api_getDoctors()),
    modifyAppointments: modifyAppointmentParam =>
      dispatch(api_modifyAppointments(modifyAppointmentParam)),
    getTimeslots: () => dispatch(api_getTimeslots())
  };
};
export default connect(null, mapDispatchToProps)(MoveItem);
//export default MoveItem;
