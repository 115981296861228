import axios from 'axios';
 
export const api_list_goals = (data) => dispatch => {
	//console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=api_list_goals`, data);
}
 
export const api_list_problems = (data) => dispatch => {
	//console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=api_list_problems`, data);
}
export const api_list_tasks = (data) => dispatch => {
	//console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=api_list_tasks`, data);
}
export const api_selectepc = (data) => dispatch => {
	//console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=api_selectepc`, data);
}
export const apiDeleteEPC = (data) => dispatch => {
	//console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=apiDeleteEPC`, data);
}
export const api_addenchance = (data) => dispatch => {
	//console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=api_addenchance`, data);
}
 
export const api_list_options = (data) => dispatch => {
	//console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=api_list_options`, data);
}
 

export const api_last_careplan = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=api_last_careplan`, data);
}


export const api_add_asthama = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=add_asthma`, data);
}


export const api_fetch_asthma= (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=fetch_asthma`, data);
}

export const api_add_diabetic= (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=add_diabetic`, data);
}


export const api_get_diabetic= (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=get_diabetic`, data);
}


export const api_add_reminder= (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=add_reminder`, data);
}


export const api_get_country= () => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=get_country`);
}


export const get_selected_states= () => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=epc&action=get_selected_states`);
}


