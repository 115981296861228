import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../showHelp';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import {
  api_list_service_notes,
  api_add_service_note,
  api_delete_service_note,
} from './../../DS/DS.VisitnotesFinalizeVisit';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import tobaccoHistory from 'components/patientHistory/familySocialHistory/tobaccoHistory';
class serviceNotes extends Component {
  state = {
    serviceNoteText: '',
    service_notes_master: [],
    selectedServiceNotes: '',
  };
  componentDidMount() {
    this.props.list_service_notes({ serviceNoteText: '', type: this.props.type }).then(res => {
      this.setState({ service_notes_master: res.data.content });
    });
  }
  addServiceNotes = () => {
    if (this.state.serviceNoteText != '') {
      this.props
        .add_service_note({ serviceNoteText: this.state.serviceNoteText, type: this.props.type })
        .then(() => {
          this.setState({ serviceNoteText: '' });

          this.props.list_service_notes({ serviceNoteText: '', type: this.props.type }).then(res => {
            this.setState({ service_notes_master: res.data.content });
          });
        });

      NotificationManager.success('Service Note Added!');
    } else {
      alert(
        'Cannont add empty Service note, Please write something to proceed'
      );
    }
  };
  selectServiceNotes = item => {
    this.setState({ selectedServiceNotes: item });


    this.props.list_service_notes({ serviceNoteText: '', type: this.props.type }).then(res => {
      this.setState({ service_notes_master: res.data.content });
    });
    
    // let service_note_item = {
    //   position: this.props.selectedInput,
    //   servicenote: item,
    // };
    // this.props.getServiceNote(item, this.props.selectedInput);
  };
  searchServiceNotes = a => {
    this.props.list_service_notes(a).then(res => {
      this.setState({ service_notes_master: res.data.content });
    });
  };
  handleDelete = (e, id) => {
    confirmAlert({
      title: '',
      message:
        'You are about to delete a service note. Do you want to proceed?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props
              .delete_service_notes({ service_note_id: id })
              .then(() => {
                this.props
                  .list_service_notes({ serviceNoteText: '', type: this.props.type })
                  .then(res => {
                    this.setState({ service_notes_master: res.data.content });
                    if (
                      this.state.service_notes_master &&
                      this.state.service_notes_master.length > 0
                    ) {
                      const isSelectedValue = this.state.service_notes_master.indexOf(
                        this.props.selectedServiceNotesValue
                      );
                      if (isSelectedValue === -1) {
                        this.props.getServiceNote('', this.props.selectedInput);
                      }
                    }
                  });
              });
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  handlerSelectService = () => {
    this.props.getServiceNote(
      this.state.selectedServiceNotes,
      this.props.selectedInput
    );
    this.props.closeServiceNotes();
  };

  render() {
    return (
      <Popup
        onOpen={() => {
          this.setState({
            selectedServiceNotes: this.props.selectedServiceNotesValue,
          });
        }}
        open={this.props.open}
        close={this.props.closeServiceNotes}
        modal
        closeOnDocumentClick={false}
      >
        <div>
          <div className="modal-header">
            <div class="popup-title">
              <span> {this.props.type} Notes </span>
              <Showhelp gotohelp="diagnosis_popup" />
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  onClick={e => this.props.closeServiceNotes()}
                >
                  ×
                </span>
              </button>
            </div>
          </div>
          <div
            className="modal-body visit-notes-wrapper newpostpopup"
            style={{ overflow: 'scroll' }}
          >
            <div className="modal-body invotext" style={{padding: '20px'}}>
              <div
                className="col-sm-12"
                style={{
                  padding: '0px',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                <div className="row">
                  <div className="col-md-5">
                    <input class="form-control"
                      type="text"
                      value={this.state.serviceNoteText}
                      onChange={e =>
                        this.setState({ serviceNoteText: e.target.value })
                      }
                      placeholder={'Add New Service Notes'}
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <button
                      className="btn btn-primary btn-xs"
                      onClick={e => this.addServiceNotes(e)}
                    >
                      Add{' '}
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 mb-3">
                  <input  class="form-control"
                    type="text"
                    onChange={e =>
                      this.searchServiceNotes({
                        searchServiceNotes: e.target.value, type: this.props.type
                      })
                    }
                    placeholder={'Search'}
                  />
                </div>
                <div className="col-md-12">
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <table class="calendar table table-bordered tablestyle">
                      {this.state.service_notes_master != undefined &&
                        this.state.service_notes_master.map(v => (
                          <tr>
                            <td style={{ width: '400px' }}>
                              <label style={{ marginTop: '5px!important'}}>
                                <input
                                  type="radio"
                                  checked={
                                    this.state.selectedServiceNotes ==
                                    v.servicenote
                                  }
                                  onChange={e => {
                                    this.selectServiceNotes(
                                      // 'serivcenotes',
                                      v.servicenote
                                    );
                                  }}
                                />
                                &nbsp;{v.servicenote}
                                {/* {this.state.vaccines+' '+v.vaccine_id} */}
                              </label>
                              <i
                                className="fas fa-trash-alt"
                                aria-hidden="true"
                                style={{
                                  fontSize: 12,
                                  marginRight: 5,
                                  float: 'right',
                                  marginTop: '-14px',
                                  marginLeft: '-15px',
                                }}
                                onClick={e =>
                                  this.handleDelete(e, v.service_note_id)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="row">
              <div className="col-sm-6"></div>
              <div className="col-sm-6">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={e => this.handlerSelectService()} //this.props.closeServiceNotes()}
                >
                  Select Service Note{' '}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    //ItemValidation: data => dispatch(api_careplan_item_validation(data)),
    list_service_notes: data => dispatch(api_list_service_notes(data)),
    delete_service_notes: data => dispatch(api_delete_service_note(data)),
    add_service_note: data => dispatch(api_add_service_note(data)),
  };
};

const mapStateToProps = state => {
  let selected_appointment =
    state.getDrAppointments.selected_appointment != undefined
      ? state.getDrAppointments.selected_appointment
      : [];
  // let logged_info  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info:[];
  let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : [];
  let selectedPatientDetails;
  if (patientDetails.length > 0) {
    selectedPatientDetails = patientDetails[0];
  } else {
    selectedPatientDetails = '';
  }

  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : '';

  return {
    selectedPatientDetails,
    logged_info,
    selected_appointment,
    logged_info,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(serviceNotes);
