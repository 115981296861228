import React, { useState, useEffect, Component, useCallback } from "react";  
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import { loadEditform, deleteItem } from "./../../../DS/services";
import { confirmAlert } from "react-confirm-alert";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import {
  PagingState,
  CustomPaging,
  SortingState,
  FilteringState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  VirtualTable,
  TableFilterRow
} from "@devexpress/dx-react-grid-bootstrap4";
//import { Loading } from '../../../theme-sources/bootstrap4/components/loading';
//import './crudcomponent.css';

//const URL = 'https://js.devexpress.com/Demos/Mvc/api/DataGridWebApi/Orders';
const useForceUpdate2 = () =>   useState()[1];
function onSelectRow(row, isSelected, e) {
  if (isSelected) {
    alert(`You just selected '${row['name']}'`)
  }
}
 
const selectRowProp = {
  mode: 'checkbox',
  clickToSelect: true,
  unselectable: [2],
  selected: [1],
  onSelect: onSelectRow,
  bgColor: 'gold'
};

export default props => {
  
  const forceUpdate =  useForceUpdate2();
  
  
  const URL = props.viewgrid.baseurl;
  const [columns] = useState(props.viewgrid.columns);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  let pSize = 0;
  if (props.viewgrid.isPagination) {
    pSize = props.viewgrid.pagesize;
  } else {
    pSize = 1000;
  }
  const [pageSize] = useState(pSize);

  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lastQuery, setLastQuery] = useState();
  const [filters, setFilters] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "name", align: "right" },
    { columnName: "name", align: "right" }
  ]);
  const [sorting, setSorting] = useState(props.viewgrid.sortinit);
   
  const HighlightedCell = ({ value, style, ...restProps }) => (
    <Table.Cell
      {...restProps}
      style={{
        backgroundColor: value < 5000 ? "red" : undefined,
        ...style
      }}
    >
      {console.log(restProps.row.name)}
      <span
        style={{
          color: value < 5000 ? "white" : undefined
        }}
      >
        {props.viewgrid.actions.map((action, index) => (
          <button
            style={{marginRight:'5px'}}
            key={action.icon}
            type="button"
            className={`btn btn-xs btn-${action.buttonClass}`}
            onClick={() => {
              var custom_var = "restProps.row."+action.passParam;
            
              handleButtonItem(
                eval(custom_var),
                action.function,
                action.isDefault
              );
            }}
          >
            <i className={`fa ${action.icon}`} aria-hidden="true"></i>{" "}
            {action.text}
          </button>
        ))}
        {/*onClick = {() => this.props.handleButton(1, "toDO")} */}
      </span>
    </Table.Cell>
  );

  const Cell = props => {
    const { column } = props;
    if (column.name === "action") {
       
      return <HighlightedCell {...props} />;
    } else {
       
    }
    return <Table.Cell {...props} />;
  };

  const getQueryString_page = () =>
    `${URL}?take=${pageSize}&skip=${pageSize * currentPage}`;

  const getQueryString = () => {
    //let queryString = `${URL}?`;

    let queryString = `${URL}&`;

    const columnSorting = sorting[0];
    if (columnSorting) {
      const sortDirectionString =
        columnSorting.direction === "desc" ? " desc" : "";
      queryString = `${queryString}orderby=${columnSorting.columnName}${sortDirectionString}`;
    }

    let filter = filters
      .reduce((acc, { columnName, value }) => {
        acc.push(
          `["${columnName}", "contains", "${encodeURIComponent(value)}"]`
        );
        return acc;
      }, [])
      .join(',"and",');

    if (filters.length > 1) {
      filter = `[${filter}]`;
       const currentPage = 0;
    }

    //    return queryString;
    return `${queryString}&filter=${filter}&take=${pageSize}&skip=${pageSize *
      currentPage}`;
  };

  const [count, setCount] = useState(0);

  const loadData = () => {
   
    const queryString = getQueryString();
    if (queryString !== lastQuery && !loading) {
      console.log("inside if");
      setLoading(true);
      
      
      fetch(queryString, {
        method: "post",
        body: JSON.stringify(props.viewgrid.query)
      })
        .then(response => response.json())
        .then(data => {
         
          setRows(data.content);
          setTotalCount(data.totalCount);
          setLoading(false);
        })
        .catch(() => { setLoading(false)});
      setLastQuery(queryString);
      
    }
    else {
      console.log("inside else");
    }
  };

  useEffect(() => {console.log("use effect"); loadData()});
  
  const handleButtonItem = (selectedId, todo, isDefault) => {
    
    if (isDefault == true) {
      
      if (todo == "edit") {
        let editPostData = {
          prop: props.edit_form,
          selectedId: selectedId
        };
        loadEditform(editPostData).then(res => {
          props.showeditform(res.data.content);
        });
      }

      if (todo == "delete") {
        let deletePostData = {
          prop: props.delete,
          selectedId: selectedId
        };

        if(props.delete.isConfirmBeforeDelete == true){

        confirmAlert({
          title: props.delete.confirmationTitle,
          message: props.delete.confirmationMessage,
          buttons: [
            {
              label: "Yes",
              onClick: () => {
               deleting(deletePostData);
              }
            },
            {
              label: "No",
              onClick: () => {}
            }
          ]
        });
      } else {
        deleting(deletePostData);



      }
      
         
      }
    } else {
      //this.props.handleButton(selectedId, todo);
    }
  };
 const deleting = (deletePostData) => {
    deleteItem(deletePostData).then(res => {
      if (res.status === 200) {
        
        //forceUpdate();
       // useForceUpdate();
       // loadData();

       //loading new records
       const queryString = getQueryString();
       setLoading(true);

      
      fetch(queryString, {
        method: "post",
        body: JSON.stringify(props.viewgrid.query)
      })
        .then(response => response.json())
        .then(data => {
        
          setRows(data.content);
          setTotalCount(data.totalCount);
          setLoading(false);
        })
        .catch(() => { setLoading(false)});
      setLastQuery(queryString);
      
      NotificationManager.success(props.delete.successMessage);
       //  setCount(count + 1);
       //render();
       
      } else {
        NotificationManager.error(
          props.delete.errorMessage
        );
      }
    });
  }
  return (
    <div className="box">
    <div className="card" style={{ position: "relative" }}>
      <b> {props.viewgrid.viewtabletitle} </b>
      <NotificationContainer />
      <Grid rows={rows} selectRow={selectRowProp} columns={columns} className={'table', 'table-bordered', 'table-striped', 'dataTable', 'no-footer'}>
        {/*columnFilteringEnabled = {false}*/}
        <FilteringState
          columnFilteringEnabled={props.viewgrid.isFiltering}
          onFiltersChange={setFilters}
        />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
        />
        <CustomPaging totalCount={totalCount} />
        <SortingState sorting={sorting} onSortingChange={setSorting} />

        <VirtualTable columnExtensions={tableColumnExtensions} className={'table-striped'} />
        {props.viewgrid.isActions ? <Table className={'table-striped'} cellComponent={Cell} /> : <Table />}
        <TableHeaderRow showSortingControls={true} />
        {props.viewgrid.isFiltering && <TableFilterRow />}
        {props.viewgrid.isPagination && <PagingPanel />}
      </Grid>
      {/*loading && <Loading />*/}
    </div>
    </div>
  );
};
