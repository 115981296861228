import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import {api_search_occupation} from '../../../DS/DS.FamilyHistory';
import { selectData } from 'DS/services';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
class SearchEmployer extends Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            open:this.props.showmodal,
            selectedRadio:1,
            key:"3",
            crud:"",
            disable:false,
            searchedText:'',
            selectedLength:0,
            selectedId:''
           
        }
    }
    componentWillMount=()=>
    {
     this.radioClicked(1);   
    }

    radioClicked = radioId => {
        let returnjson = "";
        this.setState({ crud: "" });
        if (radioId == 1) {
          this.setState({disable:false})
          let patientcrud= {
            jsonGridVersion: "v2.0",
            grid: true,
            viewgrid: {
              isVisible:true,
              isPagination: true,
              isFiltering: true,
              isSelection:true,
              deleteFunction:this.patientClicked,
              isDateGroup:false,
              isYearSeparator:false,
              dateCol:'image_date',
              groupDateSelection:false,
              cellClicked:'',
              baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
              query: {
                tb: "patients",
                cols: [
                  "patient_id",
                  "Firstname",
                  "Surname",
                  "age",
                  "address1",
                  "DOB"
                  
                ],
               where: [
               {
                type: "and",
                key: "isDeleted",
                operator: "=",
                value: 0
              }],
              customCols: [  
               "CONCAT(Firstname,' ',Surname) AS patientname"
               
             ],
                primary_key:"patient_id",
              },
              viewtabletitle: "",
              columns: [
                {name: "patientname", title: "Name" },
                {name: "age", title: "Age" },
                {name: "address1", title: "Address" },
                {name: "DOB" , title: "DOB"}
              
              ],
              rows: [],
              sortinit: [{ columnName: "Firstname", direction: "asc" }],
              pagesize: 10,
              getAllIds:false,
              getallId:'',
              highlightprev:false
            }
          }
          returnjson = patientcrud;
          this.setState({ crud: patientcrud });
        }
        if (radioId == 2) {
         this.setState({disable:true})
          let accountholder= {
            jsonGridVersion: "v2.0",
            grid: true,
            viewgrid: {
              isVisible:true,
              isPagination: true,
              isFiltering: true,
              isSelection:true,
              deleteFunction:this.patientClicked,
              isDateGroup:false,
              isYearSeparator:false,
              dateCol:'image_date',
              groupDateSelection:false,
              cellClicked:'',
              baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
              query: {
                tb: "account_holder",
                cols: [
                  "holder_id",
                  "Firstname",
                  "Surname",
                  "address_line1",
                  
                ],
               where: [
              ],
              customCols: [  
               "CONCAT(Firstname,' ',Surname) AS accountname"
               
             ],
                primary_key:"holder_id",
              },
              viewtabletitle: "",
              columns: [
                {name: "accountname", title: "Name" },
                {name: "address_line1", title: "Address" },
                
                
              
              ],
              rows: [],
              sortinit: [{ columnName: "Firstname", direction: "asc" }],
              pagesize: 10,
              getAllIds:false,
              getallId:'',
              highlightprev:false
            }
          }
          console.log("radio id 2 data printed::::")
          console.log(accountholder)
          this.setState({ crud: accountholder });
      
          returnjson = accountholder;
         
        }
        if (radioId == 3) {
          this.setState({disable:true})
            let accountholder1= {
              jsonGridVersion: "v2.0",
              grid: true,
              viewgrid: {
                isVisible:true,
                isPagination: true,
                isFiltering: true,
                isSelection:true,
                deleteFunction:this.patientClicked,
                isDateGroup:false,
                isYearSeparator:false,
                dateCol:'image_date',
                groupDateSelection:false,
                cellClicked:'',
                baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                query: {
                  tb: "contacts",
                  cols: [
                    "contact_id",
                    "contact_name",
                    
                  ],
                 where: [
                 {
                  type: "and",
                  key: "contacts.isDeleted",
                  operator: "=",
                  value: 0
                }
                ],
                joins:[
                 {
                   joinType: "inner",
                   joinTable: "contact_category",
                   joinCondition: "contact_category.category_id = contacts.category_id"
                 }],
                customCols: [  
                 "CONCAT(first_name,' ',surname) AS contactname"
                 
               ],
                  primary_key:"contact_id",
                },
                viewtabletitle: "",
                columns: [
                  {name: "contactname", title: "Name" },
                  {name: "contact_name", title: "Category" },
                 
                ],
                rows: [],
                sortinit: [{ columnName: "contact_id", direction: "asc" }],
                pagesize: 10,
                getAllIds:false,
                getallId:'',
                highlightprev:false
              }
            }
            this.setState({ crud: accountholder1 });
        
            returnjson = accountholder1;
        }
          this.setState({selectedRadio:radioId})
          this.setState({key:Math.random()})  
        return returnjson;

      };
    patientClicked=(id,action,length)=>
    {
      if(action=='selection')
      {
        this.state.selectedId=id;
        this.state.selectedLength=length;
      }
    }
    checkboxHandler=()=>
    {

    }
    searchAll=(e)=>
    {

      
          if(this.state.selectedRadio==1)
          {  
            this.setState({searchedText:e.target.value})
            let returnjson = "";
            this.setState({ crud: "" });
            if (this.state.selectedRadio == 1) {
              let patientcrud= {
                jsonGridVersion: "v2.0",
                grid: true,
                viewgrid: {
                  isVisible:true,
                  isPagination: true,
                  isFiltering: true,
                  isSelection:true,
                  deleteFunction:this.patientClicked,
                  isDateGroup:false,
                  isYearSeparator:false,
                  dateCol:'image_date',
                  groupDateSelection:false,
                  cellClicked:'',
                  baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                  query: {
                    tb: "patients",
                    cols: [
                      "patient_id",
                      "Firstname",
                      "Surname",
                      "age",
                      "address1",
                      "DOB"
                      
                    ],
                   where: [
                   {
                    type: "and",
                    key: "isDeleted",
                    operator: "=",
                    value: 0
                  },
                  {
                    type: "and",
                    key: "Firstname",
                    operator: "like ",
                    value: e.target.value+'%'
                  }],
                  customCols: [  
                   "CONCAT(Firstname,' ',Surname) AS patientname"
                   
                 ],
                    primary_key:"patient_id",
                  },
                  viewtabletitle: "",
                  columns: [
                    {name: "patientname", title: "Name" },
                    {name: "age", title: "Age" },
                    {name: "address1", title: "Address" },
                    {name: "DOB" , title: "DOB"}
                  
                  ],
                  rows: [],
                  sortinit: [{ columnName: "Firstname", direction: "asc" }],
                  pagesize: 10,
                  getAllIds:false,
                  getallId:'',
                  highlightprev:false
                }
              }
              returnjson = patientcrud;
              this.setState({ crud: patientcrud });
              this.setState({key:Math.random()})  
              return returnjson;
          }
        }
          if(this.state.selectedRadio==2)
          {

          }
          if(this.state.selectedRadio==3)
          {

          }
    }
    checkSelected=()=>{
      if(this.state.selectedLength ==1)
      {
        const sendData={ids:this.state.selectedId,empfor:this.state.selectedRadio}
        this.props.changeKey(sendData);
      }
      else
      {
        alert("Please select single record to proceed")
      }
    }
   render() {
    return ( 
        <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
        {close => (
            <div> 
                <div className="modal-header">
                <h6 style={{float:'left', fontSize: '16px', padding:'5px 10px'}}> Occupation details </h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}>
                            <span aria-hidden="true">×</span>
                        </button>
                </div>
                <div className="modal-body occtext">
                <div className="col-lg-12 classic-tabs" style={{float:'left', width:'100%', marginTop:'5px'}}>
                    <div className="row">
                        <div className="col-sm-12 m-b-0 mt-3">
                            <div className="form-radio">
                                <form>
                                    <div className="radio radio-outline radio-inline" style={{ marginTop:'0px'}}>
                                        <label><input type="radio" name="commonradio" value="1" onClick={(e)=>this.radioClicked(e.target.value)}
                                        checked={this.state.selectedRadio==1 ? 'checked' :''}/>Name</label>
                                    </div>
                                    <div className="radio radio-outline radio-inline">
                                        <label><input type="radio" name="commonradio" value="2" onClick={(e)=>this.radioClicked(e.target.value)}
                                        checked={this.state.selectedRadio==2 ? 'checked' :''}/>Account holder</label>
                                    </div>
                                    <div className="radio radio-inline radio-disable">
                                        <label><input type="radio" name="commonradio" value="3" onClick={(e)=>this.radioClicked(e.target.value)}
                                        checked={this.state.selectedRadio==3 ? 'checked' :''}/>Contact</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-12 classic-tabs" style={{float:'left', width:'100%', marginTop:'5px'}}>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-xl-6 m-b-0">
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label text-right" style={{lineHeight:'.5', paddingRight:'0px', paddingLeft:'0px', marginRight:'0px', marginTop:'10px', fontSize:'12px'}}>Search For</label>
                                        <div className="col-sm-8">
                                            <input type="text" className="form-control" style={{padding:'.2rem .75rem'}} disabled={this.state.disable} onChange={(e)=>this.searchAll(e)} value={this.state.searchedText}/>
                                            <h6 style={{marginTop:'5px', fontSize:'12px', lineHeight:'14px'}}>Select a patient from the database</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-xl-6 m-b-0">
                                    <div className="border-checkbox-section" style={{marginTop:'10px', marginBottom:'10px'}}>
                                        <div className="border-checkbox-group border-checkbox-group-default">
                                            <label className="border-checkbox-label ininactive1 col-sm-4" >
                                              <input className="border-checkbox ininactive1" type="checkbox" id="ininactive1" disabled={this.state.disable} />
                                              &nbsp; Include inactive</label>
                                              <label className="border-checkbox-label indecease1 col-sm-4" >
                                              <input className="border-checkbox indecease1" type="checkbox" id="indecease1" disabled={this.state.disable} />
                                              &nbsp; Include deceased</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 m-t-1	0">
                            <div className="card" style={{paddingRight: '0px',paddingLeft: '0px', overFlow:'scroll', height:'250px',border:'1px solid #ccc'}}>
                                <div className="card-block">
                                <CommonGrid
                                crud={this.state.crud}
                                key={this.state.key}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-xs" onClick={()=>this.checkSelected()} > Save </button>
                        <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" onClick={()=>this.props.changeKey()}> Cancel </button>
                </div>
            </div>)}
        </Popup>
    )}
}

export default SearchEmployer;