import React, { Component } from 'react';
import { connect } from 'react-redux';
import './medication.css';
import axios from 'axios';
import Popup from "reactjs-popup";
import {
    api_getMeds,
    api_getOnGoing,
    api_getOneOff,
    api_getAllMeds,
    api_addMedication,
    api_deleteMedication,
    api_listpast,
    api_get_drugs,
    api_dosage_data,
    api_get_edit_data,
    api_get_patient_allergies
   } from '../../../DS/DS.Medications';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import {api_get_drug_detail} from '../../../DS/DS.Medications';
import Showhelp from './../../showHelp';

class Medications extends Component {

  constructor(props){
    super(props)
    this.delMed=this.delMed.bind(this);
   // alert("IN")
    this.state = {
      //ongoing treatment
      selectedFood:'', 
     
    //longterm treatment
    LongTerm: {
      jsonGridVersion: "v2.0",
      grid: true,
      viewgrid: {
        isVisible:true,
        isPagination: true,
        isFiltering: true,
        isSelection:true,
        deleteFunction:this. delMed,
        isDateGroup:false,
        isYearSeparator:false,
        dateCol:'',
        showModal: false,
        cellClicked:this.onRowClicked,
        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "medications_tb",
          cols: [
            "medication_id",
            "patient_id",
            "doctor_tb.doctor_id",
            "medications_tb.drug_id as drugId",
            "medications_tb.product_name as pName",
            "dose",
            "frequency",
            "reason",
            "repeat_m",
            "reason_visit",
            "DATE_FORMAT(s_date,'%d-%m-%Y') as s_date",
            "treatment_type",
            "drugs_tb.drug_name as drugName",
            "doctor_tb.Firstname as drName"
          ],          
          joins: [
            {
            joinType: "left",
            joinTable: "drugs_tb",
            joinCondition:                    
              "medications_tb.drug_id = drugs_tb.drug_id"
          },
          {
            joinType: "left",
            joinTable: "doctor_tb",
            joinCondition:                    
              "medications_tb.doctor_id = doctor_tb.doctor_id"
          }
          ],         
         where: [
            {
              type: "and",
              key: "patient_id",
              operator: "=",
              value: this.props.redux_patientId
         }
         /*,
         {
          type: "and",
          key: "treatment_type",
          operator: "=",
          value: "Long term"
          // type: "and",
          // key: "isDeleted",
          // operator: "=",
          // value: 0
     }*/],
          primary_key:"medication_id",
        },
        viewtabletitle: "",
        columns: [
          { name: "s_date", title: "Script Date" },
          { name: "drugName", title: "Drug Name" },
          { name: "dose", title: "Dose" },
          { name: "frequency", title: "Frequency" },
          { name: "repeat_m", title: "Repeats" },
          { name: "reason", title: "Reason" },
       
        ],
        rows: [],
        sortinit: [{ columnName: "medication_id", direction: "desc" }],
        pagesize: 10
      }
    },

     //onetime treatment
     TreatOne: {
      jsonGridVersion: "v2.0",
      grid: true,
      viewgrid: {
        isVisible:true,
        isPagination: true,
        isFiltering: true,
        isSelection:true,
        deleteFunction:this. delMed,
        isDateGroup:false,
        isYearSeparator:false,
        dateCol:'',
        cellClicked:this.onRowClicked,
        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "medications_tb",
          cols: [
            "medication_id",
            "patient_id",
            "doctor_id",
            "medications_tb.drug_id as drugId",
            "medications_tb.product_name as pName",
            "dose",
            "frequency",
            "reason",
            "repeat_m",
            "reason_visit",
            "DATE_FORMAT(s_date,'%d-%m-%Y') as s_date",
            "treatment_type",
            "drugs_tb.drug_name as drugName",
            "user_tb.name as drName"
            
          ],          
          joins: [
            {
            joinType: "left",
            joinTable: "drugs_tb",
            joinCondition:                    
              "medications_tb.drug_id = drugs_tb.drug_id"
          },
          {
            joinType: "left",
            joinTable: "user_tb",
            joinCondition:                    
              "medications_tb.doctor_id = user_tb.user_id"
          }
          ],
         where: [
            {
              type: "and",
              key: "patient_id",
              operator: "=",
              value: this.props.redux_patientId
         },{
          type: "and",
          key: "treatment_type",
          operator: "=",
          value: "One time"
          // type: "and",
          // key: "isDeleted",
          // operator: "=",
          // value: 0
     }],
          primary_key:"medication_id",
        },
        viewtabletitle: "",
        columns: [
          { name: "s_date", title: "Script Date" },
          { name: "drugName", title: "Drug Name" },
          { name: "dose", title: "Dose" },
          { name: "frequency", title: "Frequency" },
          { name: "repeat_m", title: "Repeats" },
          { name: "reason", title: "Reason" },
       
        ],
        rows: [],
        sortinit: [{ columnName: "medication_id", direction: "desc" }],
        pagesize: 10
      }
    },

    allergies_tb: {
      jsonGridVersion: "v2.0",
      grid: true,
      viewgrid: {
        isVisible:true,
        isPagination: true,
        isFiltering: true,
        isSelection:true,
        deleteFunction:this. selAll,
        isDateGroup:false,
        isYearSeparator:false,
        dateCol:'',
        showModal: false,
        cellClicked:this.onRowClicked,
        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "allergies_master",
          cols: [
            "allergy_id",
            "allergy_name",
            "extra_id"
          ],
          primary_key:"allergy_id",
        },
        viewtabletitle: "",
        columns: [
          // { name: "allergy_id", title: "Allergy Id" },
          { name: "allergy_name", title: "Allergy Name" },
          // { name: "extra_id", title: "Extra Id" },
       
        ],
        rows: [],
        sortinit: [{ columnName: "allergy_id", direction: "asc" }],
        pagesize: 10
      }
    },




      patientId: this.props.redux_patientId,
      doctorId: "",
      showHideSidenav:'show',
      filteredData: [],
      meds1: [],
      meds2: [],
      allMed: [],
      selectedDrug: 0,
      selectedDrugName:'',
      selectedDose: '',
      selectedQuantity: '',
      selectedRepeat:'',
      selectedType:'',
      reasonVisit:'',
      selectedReason: '',
      pattern:0,
      selected:'',
      checkedBox: [],
      cur_date: '',
      past:[],
      key:0,
      searchedDrugs:[],
      dosage_data:[],
      GselectedInId:'',
      GselectedInlength:'',
      AselectedInId:'',
      AselectedInlength:'',
      isaction:'',
      drugs:[],
      query: '',
      selectedProduct:'',
      favorite_id:"",
      dGroup:"Product Name",
      al_query:"",
      reactions:[],
      re_query:"",
      allergy:"",
      reaction:"",
      severity:"Low",
      comments:"",
      all_drugdetails:[],
      rpttext:'',
      qtytext:'',
      reg24:false,
      pbprivate:0,
      allowbrand:true,
      generatenote:'',
      allergies:"",
      qtyreadonly:true,
      alldrugs:[],
      showSelectedContent:[],
      selectedindex:-1
      
    };   

    this.handleSubmit = this.handleSubmit.bind(this);
    //this.handleChange = this.handleChange.bind(this);
  }

  handleInputChange = () => {    
    this.setState({
      al_query: this.search.value
    })
    document.getElementById('allergies_list').style.display = 'block';  
  }
  handleInputChange1 = () => {    
    this.setState({
      re_query: this.search1.value
    })
    document.getElementById('reactions_list').style.display = 'block';  
  }
  handleMenuItemClick = (event) => {
    //alert(event.target.textContent);
    //alert(event.target.getAttribute('id'));
    document.getElementById('searchAllergy').value = event.target.textContent;
    document.getElementById('allergies_list').style.display = 'none';    
   // document.getElementById('searchAllergy').textContent = event.target.textContent; 
   this.setState({
    allergy: event.target.getAttribute('id')
  }) 
  };
  handleMenuItemClick1 = (event) => {
    document.getElementById('searchReaction').value = event.target.textContent;
    document.getElementById('reactions_list').style.display = 'none';   
    this.setState({
      reaction: event.target.textContent,
      
    })  
   // document.getElementById('searchAllergy').textContent = event.target.textContent;  
  };
  
  open() {
    this.setState({showModal: true});
  }

  close() {
    this.setState({showModal: false});
  }
  addFav(){
    const data = new FormData();
    data.set('drug_id', this.state.selectedDrug);
    fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=add_favorite`, {
      method: 'POST',   
      body: data,
    });
    //alert(this.state.selectedDrug);
    
    fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=get_drugs`)
        .then(res => res.json())
        .then(json => {
          this.setState({            
            drugs:json,            
          })
        });
    alert("Added Successfully");

  }

  

  handleSubmit(event) {
  }
  componentWillMount() {

    this.props.apiGetOnGoing({patient_id: this.state.patientId, doctor_id: 1}).then(res=>{
        this.setState({meds1: res.data.content});
      });

    this.props.apiGetOneOff({patient_id: this.state.patientId, doctor_id: 1}).then(res=>{
         this.setState({meds2: res.data.content});
      });

    this.props.apiGetAllMeds().then(res=>{
         this.setState({allMed: res.data.content});
      });

    this.props.apiListPast({patient_id: this.state.patientId}).then(res=>{
      this.setState({past: res.data.content});
      console.log(res.data.content);
    });

    this.props.listallergies({p_id:this.state.patientId}).then(res=>{
      console.log("*******",res.data)
      this.setState({allergies:res.data.data})
    })

  }
  componentWillReceiveProps(nextProps) {

        if (nextProps.redux_patientId!==this.props.redux_patientId) {
          this.setState({ patientId: nextProps.redux_patientId });

          this.props.apiGetOnGoing({patient_id: nextProps.redux_patientId, doctor_id: 2}).then(res=>{
            this.setState({meds1: res.data.content});
          });

        this.props.apiGetOneOff({patient_id: nextProps.redux_patientId, doctor_id: 2}).then(res=>{
             this.setState({meds2: res.data.content});
          });
        this.props.apiListPast({patient_id: nextProps.redux_patientId}).then(res=>{
          this.setState({past: res.data.content});
         });

        }
  }
   addData() {

        if(this.state.selectedDrug == "" ||
        this.state.selectedProduct == "" ||
        this.state.selectedDose == "" ||
        this.state.selectedQuantity == "" ||
        this.state.selectedReason == "" ||
        this.state.selectedRepeat == "" ||
        this.state.selectedType == "" ){
          NotificationManager.error('Please select all mandatory fields');
          return false;
        }
    const selectedValues = {
    }
    console.log(selectedValues);
    
    const data = new FormData();
        data.set('patientId', this.state.patientId);
        data.set('doctorId', this.props.redux_docId);
        data.set('drugId', this.state.selectedDrug);
        data.set('productName', this.state.selectedProduct);
        data.set('dose', this.state.selectedDose);     
        data.set('frequency', this.state.selectedQuantity);
        data.set('reason', this.state.selectedReason);
        data.set('repeat', this.state.rpttext);
        data.set('treatmentType', this.state.selectedType);
        data.set('reasonVisit', this.state.reasonVisit);
        data.set('pattern', this.state.pattern);
        data.set('scriptDate', this.getCurrentDate());
        data.set('food', this.state.selectedFood);
        data.set('restriction', this.state.restrictions);
        data.set('quantity', this.state.qtytext);
        data.set('prescribe_as', this.state.pbprivate);
        data.set('regulation_24', this.state.reg24);
        data.set('allow_brand', this.state.allowbrand);
        data.set('generate_note', this.state.mednote);
        


if(this.state.medication_id == ""){
    fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=add_medication`, {
      method: 'POST',   
      body: data,
    });
    NotificationManager.success('Medication added successfully');
    //alert("Medication added successfully ");
  }else{
    //alert(this.state.reasonVisit+"/"+this.state.medication_id);
    data.set('id', this.state.medication_id);
    fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=update_medication`, {
      method: 'POST',   
      body: data,
    });
    NotificationManager.success('Medication updated successfully');
    //alert("Medication updated successfully ");
  }

    // if(this.state.selectedType === "Long Term"){
    //   var elmnt = document.getElementById("ltgrid");
    //   }else{
    //   var elmnt = document.getElementById("otgrid");
    //   }
      var elmnt = document.getElementById("ltgrid");
      elmnt.scrollIntoView();

      this.props.apiAddMedication(selectedValues).then(req=>this.changeKey())
      /*this.props.apiGetOnGoing({patient_id: this.state.patientId, doctor_id: 1}).then(res =>{
        this.setState({meds1: res.data.content});
      });

    this.props.apiGetOneOff({patient_id: this.state.patientId, doctor_id: 1}).then(res =>{
         this.setState({meds2: res.data.content});
      });*/
  //  axios.post('http://localhost/SupaDoc/supa-doc-server/project/?api=medication&action=add_medication',selectedValues)
  }
  
  
  handleCheckbox = () => {
    console.log("handle check");
    //this.state.checkedBox = e.target.name;
    //this.setState({ checkedBox: this.state.checkedBox });

  }
  editMed() {

  }

  onRowClicked = clickedId => {        
    console.log("al", clickedId);        
  };

  

  selAll=(AselectedInId,inAction,AselectedInlength)=>{
    if(inAction=='selection')
    {
      this.state.AselectedInId=AselectedInId;
      this.state.AselectedInLength=AselectedInlength;      
    }else if(inAction=='save'){
     // alert(this.state.AselectedInId+"/"+this.state.AselectedInLength);
      const data = new FormData();
        data.set('patientId', this.props.redux_patientId);
        // data.set('allergy_id', this.state.AselectedInId);
        // data.set('all_length', this.state.AselectedInLength);
        if(this.state.allergy === "" || this.state.reaction === "" || this.state.severity === ""){
          NotificationManager.error('Please select Allergy, Reaction and Severity');
          //alert("Please select Allergy, Reaction and Severity");
          return false;
        }

        data.set('allergy', this.state.allergy);
        data.set('reaction', this.state.reaction);
        data.set('severity', this.state.severity);
        data.set('comments', this.state.comments);
        // alert(this.state.severity);
        // return false;
        
      fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=add_allergy`, {
        method: 'POST',   
        body: data,
      });
      this.setState({showModal:false}); 
      NotificationManager.success('Added allergy successfully');
      //alert("Added allergy successfully");
    }
  }
  delMed=(GselectedInId,inAction,GselectedInlength)=>{
   // alert(inAction)
    if(inAction=='selection')
    {
      this.state.selectedInId=GselectedInId;
      this.state.selectedInLength=GselectedInlength;
    }
    else if(inAction=="delete")
    {
            if (this.state.selectedInLength> 0) {
              confirmAlert({
                title: 'Delete record',
                message: 'Do you want to delete the record?',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                     // alert(this.state.selectedInId);
                      const data = new FormData();
                           data.set('id', this.state.selectedInId);
                            fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=delete_medications`, {
                              method: 'POST',   
                              body: data,
                            });
                            this.setState({ key: Math.random() });
                  //     this.props.apiDeleteMedication({medications_id:this.state.selectedInId}).then(
                  //       req=>this.changeKey(),
                  //   NotificationManager.success(
                  //   'Record deleted',
                  //   //this.changeKey()
                  //   )
                  // );
                },
            },
            {
              label: 'No',
              //onClick: () => { this.updateGridIn()},
              onClick: () => {}
            },
          ],
        });
      } else {
        NotificationManager.error('Please select a record to delete');
      }
      
    }else if(inAction=="edit")
    {
      if(this.state.selectedInLength == 0){
        NotificationManager.error('Please select a record to Edit');
      }else if(this.state.selectedInLength == 1){

        const apiUrl = `${process.env.REACT_APP_BASE_URL}api=womenHistory&action=get_medication`;
        const formData = new FormData();
        formData.append('id', this.state.selectedInId);
        const options = {
          method: 'POST',
          body: formData
        }
        fetch(apiUrl, options)
        .then(res => res.json())
        .then(
          (result) => {  
            //alert(result[0].drug_name+"/"+result[0].product_name);   
            //alert(result[0].medication_id+"/"+result[0].repeat_m+"/"+result[0].frequency);             
            console.log(">>>>>>>>>>>"+result);
            this.setState({
              medication_id:result[0].medication_id,
              selectedDrug:result[0].drug_id,
              selectedProduct:result[0].product_name,
              selectedDose:result[0].dose,     
              selectedQuantity:result[0].frequency,
              selectedReason:result[0].reason,
              selectedRepeat:result[0].repeat_m,
              selectedType:result[0].treatment_type,
              reasonVisit:result[0].reason_visit,
              selectedDrugName:result[0].drug_name,
              pattern:result[0].pattern,
              food:result[0].food,
              restrictions:result[0].restriction,
              qtytext:result[0].quantity,
              pbprivate:result[0].prescribe_as,
              reg24:result[0].regulation_24,
              allowbrand:result[0].allow_brand,
              generatenote:result[0].generate_note,
              rpttext:result[0].repeat_m,
              mednote:result[0].generate_note,

              // wh_id:result[0].id,
              // performed_date:new Date(result[0].performed_date),
              // performed_by:result[0].performed_by,
              // test:result[0].test,
              // unsatisfactory_spec:result[0].unsatisfactory_spec,
              // endoservical:result[0].endoservical,
              // hpv_changes:result[0].hpv_changes,
              // hpv16:result[0].hpv16,
              // hpv18:result[0].hpv18,
              // hpv:result[0].hpv,
              // result:result[0].result,
              // lbc_result:result[0].lbc_result,                  
              // other_info:result[0].other_info
                        
                          
            })
         //   this.setState({drugdetails})
            //alert(this.state.reasonVisit)
           document.getElementById("drug_list").value = result[0].drug_id;
           document.getElementById("product_list").value = result[0].product_name;
           document.getElementById("dose_list").value = result[0].dose;
           document.getElementById("quantity_list").value = result[0].frequency;
           document.getElementById("repeat_list").value = result[0].repeat_m;
                     
          this.toggleSidenav('add'); 
          },
          (error) => {
            alert(error);
            this.setState({ error });
          }
        )
        

       // alert(this.state.selectedInId); 
      // this.setState({ key: Math.random() });
       }else{
        NotificationManager.error('You cant edit more than one row');
       }
      
    }else if(inAction=="add"){
      //alert("Add");
      this.setState({
        medication_id:"",
        selectedDrug:"",
        selectedProduct:"",
        selectedDose:"",     
        selectedQuantity:"",
        selectedReason:"Script",
        selectedRepeat:"",
        selectedType:"Long term",
        reasonVisit:"",
        selectedDrugName:"",
        pattern:0
      })
      document.getElementById("drug_list").value = 1;
      document.getElementById("product_list").value = "";
      document.getElementById("dose_list").value = "";
      document.getElementById("quantity_list").value = "";
      document.getElementById("repeat_list").value = "";
      //alert(this.state.reasonVisit);
      this.setState({ key: Math.random() });
      
      this.toggleSidenav('add');

    }else if(inAction=="moveUp"){

      if (this.state.selectedInLength> 0) {
        const data = new FormData();
        data.set('id', this.state.selectedInId);
        data.set('treatmentType', "Long term");
        fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=move_medications`, {
          method: 'POST',   
          body: data,
        });        
        NotificationManager.success('Medication updated successfully');
        this.setState({ key: Math.random() });
      } else {
        NotificationManager.error('Please select a record to move');
      }

    }else if(inAction=="moveDown"){

      if (this.state.selectedInLength> 0) {
        const data = new FormData();
        data.set('id', this.state.selectedInId);
        data.set('treatmentType', "One time");
        fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=move_medications`, {
          method: 'POST',   
          body: data,
        });        
        NotificationManager.success('Medication updated successfully');
        this.setState({ key: Math.random() });
      } else {
        NotificationManager.error('Please select a record to move');
      }


    }

    
  }
  getCurrentDate(separator='-'){

    var today = new Date();
    return today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

    // let newDate = new Date()
    // let date = newDate.getDate();
    // let month = newDate.getMonth() + 1;
    // let year = newDate.getFullYear();
    // return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`;
    }
  onsearchchange=(e)=>
  {
    this.setState({
      query: e.target.value
    })
    // alert(e.target.value);
    // this.state.searchedDrugs=[];
     this.props.getdrugs({search:e.target.value}).then(req=>{
       this.setState({alldrugs:req.data.content})
     })
  }

  handleFieldValueChanges(key, value) {    
    this.setState({
      [key]: value
    });



    if(value === "Favourites"){
      this.setState({
        favorite_id: "1"
      });
    }else{
      this.setState({
        favorite_id: ""
      });
    }
  }

  componentDidMount(){
   /* fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=get_drugs`)
        .then(res => res.json())
        .then(json => {
          this.setState({            
            drugs:json,            
          })
        });*/

    //this.get_drugs();
  this.props.getdrugs().then(
    res=> this.setState({alldrugs:res.data.content})
  )
        this.setState({   
        reactions :
        [{"reaction_name":"Muscle pains"},
        {"reaction_name":"Pruritus"},
        {"reaction_name":"Urticana"},
        {"reaction_name":"Weight gain"},
        {"reaction_name":"Drowsiness"},
        {"reaction_name":"Abdominal Pain"},
        {"reaction_name":"Agtation"},
        {"reaction_name":"Angloedema"},
        {"reaction_name":"Back pain"},
        {"reaction_name":"Candidiasis"},
        {"reaction_name":"Constipation"},
        {"reaction_name":"Convulsion"},
        {"reaction_name":"Cough"}]  
        })  

  }


  getdrugdetails=(e)=>
  {
   // alert(e.target.value);
    this.setState({
      selectedDrug:e.target.value,
      selectedProduct:"",
      selectedDrugName:e.target.selectedOptions[0].text,
      selectedindex:-1      
    });
    const apiUrl = `${process.env.REACT_APP_BASE_URL}api=womenHistory&action=recent_medication`;
    const formData = new FormData();
    formData.append('drug_id', e.target.value);
    const options = {
      method: 'POST',
      body: formData
    }
    fetch(apiUrl, options)
    .then(res => res.json())
    .then(
      (result) => {  
         console.log(">>>>>>>>>>>"+result);
        this.setState({
          selectedProduct:result[0].product_name,
          selectedDose:result[0].dose,     
          selectedQuantity:result[0].frequency,
          selectedRepeat:result[0].repeat_m,
          reasonVisit:result[0].reason_visit,
         })
      
      },
      (error) => {
        alert(error);
        this.setState({ error });
      }
    )

    this.props.getDrugDetail({drug_id:e.target.value}).then(res =>{
      console.log("**********",res.data.content[0])
      this.setState({all_drugdetails:res.data.content,qtyreadonly:true})
      if(res.data.content[0].restrictions=='PBS'){
          this.setState({pbprivate:1})
      }
      else{
        this.setState({pbprivate:2})
      }
    })

  }
  changeKey=()=>
  {
    this.setState({key:Math.random()})
  }

  checkbps=(val)=>
  {
    this.setState({rpttext:val.target.value})
    if(this.state.drugdetails.restrictions=='PBS')
    {
      confirmAlert({
        title: '',
        message: 'Would you like to prescribe this item as an Authority',
        buttons: [
        {
            label: 'Yes',
            onClick: () => {
              this.setState({pbprivate:2})
           // NotificationManager.success('Clinical record(s) deleted successfully',);
        },
       },
     {
      label: 'No',
      onClick: () => { this.setState({pbprivate:1})},
      },
     ],
    });
    }
  }
  showCMI= ()=>{
    confirmAlert({
      title: '',
      message: 'MIMS integration in progress',
      buttons: [
      {
          label: 'Ok',
          onClick: () => {
       
      },
     },
   ],
  });
  }
  setTrClass = (id) => {
    let classs = "";
    classs +=
      id == this.state.selectedindex
        ? " selectedBg "
        : "";
    return classs;
  };
  showSelectedContent(e, item, i) {
    this.setState({ showSelectedContent: item 
      ,selectedProduct:item.drug_id,restrictions:item.restrictions,qtytext:item.quantity,qtyreadonly:false,rpttext:item.repeats,
      selectedindex:i})



   
  }
  render(){
    var {reactions} = this.state;
    //var {allergies} = this.state;
    var {drugs} = this.state;
    var gridstyle = {
      padding: "10px",
      height: "50px"
    };
    return (
      <div>
        <div className="panel panel-default" style={{marginBottom:'unset'}}>
          <div className="panel-heading panspace">
            <div className="col-lg-12 col-xl-12">
              <h4 class="panel-title"><a href="#" className="collapsed" aria-expanded="false">Medications</a>
              <Showhelp gotohelp="medications_help"/>
              </h4>
              <div style={{width: 'auto', height: 30, position: 'absolute', right: 15, top:'0'}}>
                              <span className="makescreen"><a href="#" title="Make This Default Screen" className="collapsed" aria-expanded="false"><i className="fa fa-star panelicon" aria-hidden="true" /></a></span>
                <a href="#" title="Email" className="collapsed" aria-expanded="false"><i className="fas fa-envelope panelicon" aria-hidden="true"  /></a>
                <a href="#" title="Print" className="collapsed" aria-expanded="false"><i className="fas fa-print panelicon" aria-hidden="true" /></a>
                <a title="Export PDF" onclick="recentVisitmini();" className="collapsed" aria-expanded="false"><i className="fa fa-file-pdf-o panelicon" aria-hidden="true" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 classic-tabs" style={{float: 'left', width: '100%', marginTop: 5}}>
          <div style={{marginRight:"-15px;",marginLeft: "-15px;"}}>
            <div className="col-xs-12" style={{padding: 0}}>
              <nav>
                <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist" style={{margin:'20px 0', paddingBottom:'10px'}}>
                  <a className="nav-item nav-link active collapsed" style={{paddingRight: '10px',color:'black', fontSize:'16px', letterSpacing:'1px'}} id="nav-current-tab" data-toggle="tab" href="#nav-current" role="tab" aria-controls="nav-current" aria-selected="true" aria-expanded="true"><b>Current Rx</b></a>
                  <a className="nav-item nav-link collapsed" id="nav-prescriptions-tab" style={{color:'black'}}data-toggle="tab" href="#nav-prescriptions" role="tab" aria-controls="nav-prescriptions" aria-selected="false" aria-expanded="false"><b>Past Prescriptions</b></a>
                </div>
              </nav>
              <div className="tab-content px-3 px-sm-0" id="nav-tabContent">
                <div className="tab-pane fade active in" id="nav-current" role="tabpanel" aria-labelledby="nav-current-tab" style={{marginBottom: 10, marginTop: 10}}>
                  <div className="col-sm-4">
                    <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={(e)=>this.delMed('','add','')}>Add</button>
                    <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={(e)=>this.delMed('','edit','')}>Edit</button>
                    <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={(e)=>this.delMed('','delete','')}>Delete</button>
                    <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" >Run all checks</button>
                  </div>
                  <div className="col-sm-4" style={{textAlign: 'right',display:'none'}}>
                    <select placeholder="Subject" style={{float: 'right', width: '50%',margin:'5px 0 0 10px', fontWeight: 'normal', marginBottom: 0, padding: '5px 10px'}} onChange>
                      <option>{this.getCurrentDate()}</option>
                      <option>All</option>
                    </select>
                    <span style={{float: 'right', width: 'auto', marginLeft: 0, marginRight: 0, marginTop: '10px', fontWeight: 'normal', fontSize:'14px'}}>Script date:</span>
                  </div>
                  <div className="col-sm-4" style={{marginBottom: 10, marginTop: 7, textAlign: 'left'}}>
                    <span style={{float: 'left', width: '100%', marginLeft: 0, marginRight: 0, marginTop:'8px', fontSize: '12px', color: 'blue', marginTop: 0, fontWeight: 'normal'}}>
                      Tick the boxes of the items that you want to print
                    </span>
                    <span style={{float: 'left', width: '100%', marginLeft: 0, marginRight: 0, fontSize:'12px', color: 'red', marginTop: 0, fontWeight: 'normal'}}>
                      Items in red have been calculated to have been fully used.
                    </span>
                  </div>
                  <h5 className="col-sm-12 sheadtitle">Ongoing treatment</h5>
                    <div id="ltgrid" className="col-sm-12 pl-0 pr-0">
                      <div style={{float: 'left', width: '100%', overflow: 'auto', border:'0px solid #666'}}>
                      <CommonGrid
                            style={gridstyle}
                            handleButton={this.handleButton}
                            crud={this.state.LongTerm}
                            key={this.state.key}
                          />
                          {/* <CommonGrid
                            style={gridstyle}
                            handleButton={this.handleButton}
                            crud={this.state.crudon}
                            key={this.state.key}
                          /> */}
                    	
                      </div>
                    </div>
                    <div className="col-sm-12"  style={{margin: '10px auto', textAlign: 'center'}}>                        
                        <a title="Back to Summary" className="collapsed" aria-expanded="false" onClick={(e)=>this.delMed('','moveDown','')}>
                        <i className="fa fa-arrow-down" aria-hidden="true" style={{fontSize: 16}} /></a>
                        &nbsp;
                        <a title="Back to Summary" className="collapsed" aria-expanded="false" onClick={(e)=>this.delMed('','moveUp','')}>
                        <i className="fa fa-arrow-up" aria-hidden="true" style={{fontSize: 16}} /></a>
                        </div>  
                    <h5  className="col-sm-12 sheadtitle">One-off treatment</h5>
                      <div id="otgrid" className="col-sm-12 pl-0 pr-0">
                       
                        <div style={{float: 'left', width: '100%', overflow: 'auto', border:'0px solid rgb(102, 102, 102)'}}>
                        <CommonGrid
                            style={gridstyle}
                            handleButton={this.handleButton}
                            crud={this.state.TreatOne}
                            key={this.state.key}
                          />
                        </div>
                      </div>   
                      <form onSubmit={this.handleSubmit}>   
                                      
                      <div className={ `col-sm-12 ${this.state.showHideSidenav}` } id="opencurrentrx" style={{marginTop: 20, padding: '0px', height:'auto', overflowY: 'none', border:'1px solid #dddddd'}}>
                        <h5 className="col-sm-12 sheadtitle">Add New Rx </h5>
                        <div className="col-sm-12" style={{ padding:'20px 10px 10px 10px'}}>
                        <div >
                          <label className="label-input100" style={{marginRight: 0, marginBottom: 1, fontSize: 12, paddingTop: 0, paddingBottom: 0}}><input type="radio" name="dGroup"  checked={this.state.dGroup == "Product Name"} 
                          onChange={e =>
                            this.handleFieldValueChanges(
                              "dGroup",
                              "Product Name"
                            )
                          } 
                          />                          
                           Product Name </label>
                          <label className="label-input100" style={{marginRight: 0, marginBottom: 1, fontSize: 12, paddingBottom: 0, paddingTop: 0}}><input type="radio"  name="dGroup"  checked={this.state.dGroup == "Therapuetic class"} />Therapuetic class</label>
                          <label className="label-input100" style={{marginRight: 0, marginBottom: 1, fontSize: 12, paddingBottom: 0, paddingTop: 0}}><input type="radio"  name="dGroup" checked={this.state.dGroup == "Custom"} />Custom </label>
                          <label className="label-input100" style={{marginRight: 0, marginBottom: 1, fontSize: 12, paddingBottom: 0, paddingTop: 0}}><input type="radio"  name="dGroup" 
                          onChange={e =>
                            this.handleFieldValueChanges(
                              "dGroup",
                              "Favourites"
                            )
                          } 
                          checked={this.state.dGroup == "Favourites"}
                          /> 
                          
                           Favourites </label>
                           
                        <span style={{float:'right'}}>Allergies : <label  style={{float:'right',color:'red'}}> {this.state.allergies}</label></span>
                        </div>
                      </div>
                        <div className="col-sm-12 medallergy1" style={{ padding:'10px 0px'}}>
                          <div className="col-sm-5">
                            <label className="label-input100" style={{marginRight: 0, marginBottom: 0, fontSize: 12, paddingTop: 0, paddingBottom: 0}}>
                              <span style={{color: '#000', float: 'left', marginTop: 10}}> Search</span>  
                                <input type="text" className="form-control form-control-sm" defaultValue="" style={{padding: '5px 10px', marginLeft: 10,marginTop: 1, width: 320, float: 'left'}} 
                                  onChange={(e)=>this.onsearchchange(e)}/></label>
                          </div>
                        
                      </div>



                      {/** ADD NEW WORK FLOW */}
                      <div className="col-sm-12" style={{ padding:'20px'}}>
                            <div className="col-sm-2" style={{paddingLeft: 0}}>
                              <label className="col-sm-12 col-form-label text-left" style={{paddingLeft: 5, fontWeight: 'bold', margin:'10px 0'}}>Drug  name: <span style={{color:'red'}}>*</span></label>
                                <select name="select" id="drug_list" multiple className="form-control" style={{padding: '.0rem .5rem', height: '150px!important', border: '1px solid #ccc', borderBottom: '1px solid #ccc', fontSize: 12}} 
                                  onChange={(e) => this.getdrugdetails(e)}>
                                    {this.state.alldrugs!=undefined && this.state.alldrugs.map(drug=>(
                                        <option value={drug.drug_id}>{drug.drug_name}</option>))}
                                </select>
                                <div className="col-sm-12" style={{paddingLeft: 0, marginTop: 15}}>
                                  <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={e => this.addFav()}>+ Favourites</button>
                                  <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={e => this.open()}>+ Allergies</button>
                                </div>
                            </div>
                           
                           
                            <div className="col-sm-4" style={{paddingRight: 0}}>
                              <label className="col-sm-12 col-form-label text-left" style={{marginTop:'10px'}}>Product Name: <span style={{color:'red'}}>*</span></label>
                              <div className="col-sm-12" style={{paddingRight: 0}}>
                                {/*<select id="product_list" style={{padding: '.0rem .5rem', height: 150,marginTop: 7, border: '1px solid #ccc', borderBottom: '1px solid #ccc', fontSize: 12,width: '100%'}} 
                                  onChange={(e) => this.setState({ selectedProduct: e.target.value })} multiple >
                                  <option value="">Select</option>
                                    <option >{this.state.selectedDrugName} 50</option>
                                    <option >{this.state.selectedDrugName} 150</option>
                                    <option >{this.state.selectedDrugName} 250</option>
                                    <option >{this.state.selectedDrugName} Syrup</option>
                                  </select>*/}
                                  <table>
                                      <tr>
                                          <th>
                                            Product name
                                          </th>
                                          <th>
                                             Restrictions
                                          </th>
                                          <th>
                                             Quantity
                                          </th>
                                          <th>
                                             Repeats
                                          </th>
                                      </tr>
                                      {this.state.all_drugdetails!='' && this.state.all_drugdetails.map((detaildata,i)=>(
                                        <tr style={{cursor:'pointer'}} 
                                        onClick={e =>
                                          this.showSelectedContent(e, detaildata, i) }
                                        className={this.setTrClass(i)}>
                                            <td>{detaildata.drugdetails} {detaildata.prod_type}</td>
                                            <td>{detaildata.restrictions}</td>
                                            <td>{detaildata.quantity}</td>
                                            <td>{detaildata.repeats}</td>
                                        </tr>
                                      ))}
                                  </table>

                                <div style={{padding:'20px'}}>
                                 <label>
                                  <input type="radio" name="TrType" id="TrType" value="Long term" onChange={(e) => this.setState({ selectedType: "Long term" })}
                                    checked={this.state.selectedType == "Long term"}  />&nbsp;Long term
                                 </label>
                                 <label>
                                  <input type="radio" name="TrType" id="TrType" value="One time" onChange={(e) => this.setState({ selectedType: "One time" })}
                                    checked={this.state.selectedType == "One time"} />&nbsp;One time</label>
                                </div>
                              </div>
                          </div>
                            <div className="col-sm-2" style={{paddingRight: 0}}>
                               <label className="col-sm-12 col-form-label text-left" style={{marginTop:'10px'}}>Frequency: <span style={{color:'red'}}>*</span></label>
                                <div className="col-sm-12" style={{paddingRight: 0}}>
                                  <select id="dose_list" style={{ width: '100%', marginRight: 5, fontWeight: 'normal', border: '1px solid #ccc', marginTop: 7, marginBottom: 0, padding: '5px 10px', height: 150}} 
                                  onChange={(e) => this.setState({ selectedQuantity: e.target.value })} multiple>
                                    <option value="Twice a day">Twice a day</option>
                                    <option value="In the evening">In the evening</option>
                                    <option value="1 p.r.n.">1 p.r.n.</option>
                                    <option value="Daily">Daily</option>
                                    <option value="One puff daily">One puff daily</option>
                                    <option value="2 p.r.n.">2 p.r.n.</option>
                                    <option value="2 Twice a day">2 Twice a day</option>
                                    <option value="1 take one stat">1 take one stat.</option>
                                  </select>
                                  <div style={{padding:'20px'}}>
                                   <label>
                                    <input type="radio" name="Reason" id="Reason" value="Script" onChange={(e) => this.setState({ selectedReason: "Script" })}
                                      checked={this.state.selectedReason == "Script"}  />&nbsp;Script&nbsp;
                                   </label>
                                   <label>
                                       <input type="radio" name="Reason" id="Reason" value="Other" onChange={(e) => this.setState({ selectedReason: "Other" })}
                                        checked={this.state.selectedReason == "Other"} />&nbsp;Other
                                    </label>
                                  </div>
                                </div>      
                           </div>
                            <div className="col-sm-2" style={{paddingRight: 0}}>
                              <label className="col-sm-12 col-form-label text-left" style={{marginTop:'10px'}}>Dose: <span style={{color:'red'}}>*</span></label>
                                <div className="col-sm-12" style={{paddingRight: 0}}>
                                  <select id="quantity_list" multiple style={{ width: '100%', marginRight: 5, fontWeight: 'normal', border: '1px solid #ccc', marginTop: 7, marginBottom: 0, padding: '5px 10px', height: 150}} 
                                  //value={this.state.selectedQuantity}
                                  onChange={(e) => this.setState({ selectedDose: e.target.value })} >                                  
                                  {/* <option value="">Select</option> */}
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="1x140 dose">1x140 dose</option>
                                    <option value="1x30g">1x30g</option>
                                  </select>
                                  
                                <div style={{padding:'20px 10px'}}>
                               <label>
                                <input type="checkbox" name="pattern" id="pattern" value='1'
                                onChange={e =>
                                  this.handleFieldValueChanges(
                                    "pattern",
                                    e.target.checked?1:0
                                  )
                                }
                                checked={this.state.pattern == 1}
                                 />&nbsp;Save Combination</label>
                                </div>
                                </div>
                            </div>
                          {/*}  <div className="col-sm-1" style={{paddingRight: 0}}>
                              <label className="col-sm-12 col-form-label text-left" style={{marginTop:'10px'}}>Repeat: <span style={{color:'red'}}>*</span></label>
                                <div className="col-sm-12">
                                  <select id="repeat_list" style={{float: 'right', width: '100%', marginRight: 5, border: '1px solid #ccc', fontWeight: 'normal', marginTop: 7, marginBottom: 0, padding: '5px 10px', height: 150}}
                                  //value={this.state.selectedRepeat}
                                  onChange={(e) => this.setState({ selectedRepeat: e.target.value, rpttext:e.target.value })} multiple>
                                  {/* <option value="">Select</option> 
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>  
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                  </select>
                                </div>
                              </div>*/}
                            <div className="col-sm-2" style={{paddingRight: 0}}>
                              <label className="col-sm-12 col-form-label text-left" style={{marginTop:'10px'}}>Food: <span style={{color:'red'}}>*</span></label>
                                <div className="col-sm-12">
                                  <select id="repeat_list" style={{float: 'right', width: '100%', marginRight: 5, border: '1px solid #ccc', fontWeight: 'normal', marginTop: 7, marginBottom: 0, padding: '5px 10px', height: 150}}
                                  onChange={(e) => this.setState({ selectedFood: e.target.value })} multiple>
                                    <option value="1">Before meals</option>
                                    <option value="2">with meals</option>
                                    <option value="3">after meals</option>
                                    <option value="4">on an empty stomach</option>
                                    <option value="5">without regard to meals</option>
                                  </select>
                                </div>
                            </div>
                            {/*<div className="col-sm-4" style={{paddingRight: 0}}>
                              <div className="form-group row">
                                  <div className="col-sm-6" style={{paddingLeft: 0}}></div>
                                  <div className="col-sm-12">  </div>
                              </div>
                              </div>*/}
                             
                        </div>
                      </div>
                      <div className="col-sm-12" style={{padding:'10px'}}>
                        <div className="col-sm-3 pt-3">
                            <label>Restrictions : {this.state.all_drugdetails!=undefined ? this.state.restrictions: ''}</label>
                            {/*<table className="scndtbl">
                              <tr>
                                <th>Quantity</th>
                                <th>Restrictions</th>
                              </tr>
                              {this.state.drugdetails!=undefined && this.state.drugdetails.map((item,i) => (
                                        <tr>
                                          <td>{item.quantity}</td>
                                          <td>{item.restrictions}</td>
                                        </tr>
                              ))}
                              </table>*/}
                        </div> 
                        <div className="col-sm-3">
                          <label className="col-sm-3 col-form-label pt-2" >Quantity:</label>
                          <input className="col-sm-2 mt-0" type="text" onChange={(e)=>this.setState({qtytext:e.target.value})} value={this.state.qtytext} disabled={this.state.qtyreadonly} style={{width:'100px'}}/>      
                        </div> 
                        <div className="col-sm-3">
                          <label className="col-sm-3 col-form-label pt-2" >Repeats:</label>
                          <input className="col-sm-2 mt-0" type="text" onBlur={(e)=>this.checkbps(e)} value={this.state.rpttext} onChange={(e)=>this.setState({rpttext:e.target.value})} style={{width:'200px'}}/>      
                        </div>    
                        <div className="col-sm-2">
                          <label><input type="checkbox" onChange={(e)=>this.setState({reg24:e.target.checked})} value={this.state.reg24} checked={this.state.reg24=="true"?true:''}/> Regulation 24</label>
                        </div>        

                      </div>
                      <div className="col-sm-12">
                        <div className="col-sm-2">
                          <label>Prescribe as:</label>
                        {this.state.restrictions=='PBS'? 
                          <label className="label-input100" style={{marginRight: 0, marginBottom: 1, fontSize: 12, paddingBottom: 0, paddingTop: 0,color:'black'}}>
                              <input type="radio"  name="pbpriv"  value="1" checked={this.state.pbprivate == 1} onClick={()=>this.setState({pbprivate:1})}/>&nbsp;PBS</label>
                        :''}
                          <label className="label-input100" style={{marginRight: 0, marginBottom: 1, fontSize: 12, paddingBottom: 0, paddingTop: 0,color:'black'}}>
                            <input type="radio"  name="pbpriv" value="2" checked={this.state.pbprivate == 2} onClick={()=>this.setState({pbprivate:2})}/>&nbsp;Private </label>
                        </div>
                         <div className="col-sm-2">
                         <label><input type="checkbox" onChange={(e)=>this.setState({allowbrand:e.target.checked})} value={this.state.allowbrand} checked={this.state.allowbrand=="true"?true:''}/> Allow brand substitution</label>

                        </div>
                        <div className="col-sm-1 pt-0">
                            <label>PBS Listing :&nbsp;</label>
                        </div> 
                         <div className="col-sm-3">
                            <input type="text" className="mt-0" readOnly value={this.state.restrictions}/>
                        </div>    
                     </div>
                      <div className="col-sm-12">
                        <label className="col-sm-1 pt-2"> Generate Note:</label> &nbsp;
                      <div className="col-sm-3"><input type="text"   value={this.state.mednote} onChange={(e)=>this.setState({mednote:e.target.value})} className="mt-0" />
                      </div>
                      <div className="col-sm-3"> <select  id="generatenote" name="generatenote" onChange={(e) => this.setState({ generatenote: e.target.value,mednote:e.target.value})} className="mt-0" value={this.state.generatenote}>
                          <option value="Medication prescribed">Medication prescribed</option>
                          <option value="Medication started in hospital">Medication started in hospital</option>
                          <option value="Medication started by specialist">Medication started by specialist</option>
                          <option value="Medication started by patient">Medication started by patient</option>
                          <option value="Medication started else where">Medication started else where</option>
                          <option value="Sample pack given">Sample pack given</option>
                        </select></div>
                      </div>        
                                  <div className="col-sm-12" style={{paddingLeft: 0}}>
                                    <textarea name="reason_visit" style={{float: 'left', width: '100%', height:'100px', border: '1px solid #ccc', padding: 10, fontWeight: 'normal', marginTop: 0}} placeholder="Reason for prescription" value={this.state.reasonVisit}  
                                      onChange={(e) => this.setState({ reasonVisit: e.target.value })}/>
                                  </div>
                                 
                                  

                                <div className="col-sm-12" style={{paddingLeft: 0, textAlign:'right'}}>
                                  <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={()=>this.showCMI()}>CMI</button>
                                  <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={()=>this.showCMI()} >Product Information</button>
                                    <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={this.addData.bind(this)}>Save & Print</button>
                                    {this.state.isaction=='add' ? 
                                      <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs"  onClick={this.addData.bind(this)}>Save</button>
                                    : <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs"  onClick={this.addData.bind(this)}>Update</button>}
                                  </div>
                   
                   
                   
                    </form>
                  </div>

                        {/**Past prescription */}
                        <div className="tab-pane fade" id="nav-prescriptions" role="tabpanel" aria-labelledby="nav-prescriptions-tab" style={{marginBottom: 10, marginTop: 10}}>
                          <div className="col-sm-6" style={{marginBottom: 10}}>
                              <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs">Represcribe</button>
                              <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs">View</button>
                              <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs">Delete</button>
                              <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs">Reprint</button>
                          </div>
                          <div className="col-sm-6" style={{marginBottom: 10, textAlign: 'right'}}>
                            <select style={{float: 'right', width: '25%', fontWeight: 'normal', marginRight: 5, marginTop: 7, marginBottom: 0, padding: '5px 10px', fontSize:'14px'}}>
                              <option>Search by drug</option>
                              <option>Select One</option>
                            </select>
                          </div>
                          <div style={{float: 'left', width: '100%'}}>
                            <table style={{width: '100%'}}>
                              <tbody>
                                <tr>
                                  <td>
                                    <div style={{width: '100%', height: 595, overflowY: 'auto'}}>
                                      <table className="calendar table table-bordered tablestyle" style={{float: 'left', width: '100%'}}>
                                        <thead>
                                          <tr>
                                            <th>Script Date</th>
                                            <th>Script Item</th>
                                            <th>Dose</th>
                                            <th >Doctor</th>
                                            <th>Quantity</th>
                                            <th>Repeats</th>
                                            <th>Script ID</th>
                                            <th>Script Type</th>
                                            <th>Authority No.</th>
                                            <th>Approval</th>
                                          </tr>
                                        </thead>
                                        <tbody className="meds">
                                          {this.state.past!=undefined && this.state.past.map((item,i) => (
                                             <tr style={{  }}>
                                              <td><input type="checkbox" />  { item.script_date}</td>
                                              <td>{item.script_item}</td>
                                              <td>{ item.dose }</td>
                                              <td>{item.doctor_id}</td>
                                              <td>{ item.quantity }</td>
                                              <td>{ item.repeats }</td>
                                              <td>{item.script_id}</td>
                                              <td>{item.script_type} </td>
                                              <td>{ item.authority_no }</td>
                                              <td>{ item.approval_status	 }</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>					
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

  toggleSidenav(action) {
    this.setState({isaction:action})
    var css = (this.state.showHideSidenav === "hidden") ? "show" : "show";
    this.setState({"showHideSidenav":css});
    if(action=='edit')
    {
        this.props.geteditdata({medication_id:this.state.selectedInId}).then(req=>
         {
           this.setState({
            //selectedQuantity:req.data.content[0].qunatity

           })
           //console.log(req.data.content[0].quantity)
         }
        )
    }
    else
    {
      this.setState({
        //selectedQuantity:''

       })
    }
    setTimeout(window.scrollTo(0,document.body.scrollHeight), 3000);
  }
}


const mapDispatchToProps = dispatch => {
  return {
    getMeds: () => dispatch(api_getMeds()) ,
    apiGetOnGoing: data => dispatch(api_getOnGoing(data)),
    apiGetOneOff: data => dispatch(api_getOneOff(data)),
    apiGetAllMeds: data => dispatch(api_getAllMeds(data)),
    apiAddMedication: data => dispatch(api_addMedication(data)),
    apiDeleteMedication: data => dispatch(api_deleteMedication(data)),
    apiListPast: data => dispatch(api_listpast(data)),
    getdrugs:data=>dispatch(api_get_drugs(data)),
    getDrugData:data=>dispatch(api_dosage_data(data)),
    geteditdata:data=>dispatch(api_get_edit_data(data)),
    getDrugDetail:data=>dispatch(api_get_drug_detail(data)),
    listallergies:data=>dispatch(api_get_patient_allergies(data))

  };
};


const mapStateToProps = state => {

  let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
  let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
    
return {
  redux_patientId,redux_docId
};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Medications);

//api_getOnGoing,api_getOneOff,api_getAllMeds,api_addMedication,api_deleteMedication export default Medications;
