import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {api_add_document,api_list_reason,api_update_document} from '../../../DS/DS.ListPage';
class AddDocument extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        document_name:'',
        errors:[],
       
    }
  }
  componentWillMount=()=>
  {
    if(this.props.popFor=='Edit')
    {
        const getData={tbl:'document_type',pk:'type_id',ids:this.props.ids}
        this.props.listDocumentType(getData).then(
            res=>{
                this.setState({document_name:res.data.content.document_name})
            }
        )
    }
  }
  handleValidation(){
    let errors = {};
    let formIsValid = true;
  
    //Reason name
    if(!this.state.document_name){
       formIsValid = false;
       errors["document_name"] = "Cannot be empty";
    }
     this.setState({errors: errors});
    return formIsValid;
  }
  onClickHandler=(e)=>
  {
    e.preventDefault();
    if(this.handleValidation()){
        const reasonData={
                reason:this.state.document_name,
              }
                this.props.addDocumentType(reasonData).then(
                  req=>
                         
                  {
                    if(req.data.status=="Duplicate"){
                       
                         NotificationManager.error('Duplicate document type record cannot be created',)
                    }
                    else if(req.data.status=="Success")
                    {
                      
                         NotificationManager.success('New document type created',)
                    }
                    else{
                        
                        NotificationManager.error('Error encountered while creating record',)
                    }
                    this.props.reloadPage()  
              }
                      
              );
    }
} 
updateRecord=(e)=>
{
    e.preventDefault();
    if(this.handleValidation()){
        const reasonData={
                reason:this.state.document_name,
                ids:this.props.ids,
              }
                this.props.UpdateDocumentType(reasonData).then(
                  req=>{
                    if(req.data.status=="Duplicate"){
                       
                         NotificationManager.error('Duplicate document type record cannot be created',)
                    }
                    else if(req.data.status=="Success")
                    {
                      
                         NotificationManager.success('Document type record modified',)
                    }
                    else{
                        
                        NotificationManager.error('Error encountered while creating record',)
                    }
                    this.props.reloadPage()  
              }
              );
    }
}
 render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
            <div>
                <div className="modal-header">
                    <span className="modal-title-2" id="exampleModalLabel"> Document Categories  </span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={(e)=>this.props.reloadPage()}>
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <div className="col-sm-2"> 
                                    <label> Category : </label>
                                </div>
                                <div className="col-sm-10 cursor">
                                    <input type="text" name="text" className="form-control" value={this.state.document_name} onChange={(e)=>this.setState({document_name:e.target.value})}/>
                                    <span className="error" style={{color:'red'}}>{this.state.errors["document_name"]}</span>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-sm-8"> </div>
                        <div className="col-sm-4">
                            {this.props.popFor=='Add'?
                            <button type="button" className="btn btn-default" data-dismiss="modal"  onClick={(e)=>this.onClickHandler(e)}>Save</button>
                            :
                            <button type="button" className="btn btn-default" data-dismiss="modal"  onClick={(e)=>this.updateRecord(e)}>Update</button>
        }
                            <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close" onClick={(e)=>this.props.reloadPage()}>Cancel</button> 
                        </div>
                    </div>
                </div>
    </div>
        )}
       </Popup>
    )}
} 
const mapDispatchToProps = dispatch => {
    return {
        addDocumentType: data => dispatch(api_add_document(data)),
        listDocumentType : data => dispatch(api_list_reason(data)),
        UpdateDocumentType: data => dispatch(api_update_document(data))
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(AddDocument);
