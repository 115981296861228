import axios from 'axios';
export const api_third_table_setting = data => dispatch => {
	return axios
	  .post( `${process.env.REACT_APP_BASE_URL}api=appointmentSetting&action=table_setting`,data);
  };
  export const api_default_lookup_setting = data => dispatch => {
	return axios
	  .post( `${process.env.REACT_APP_BASE_URL}api=appointmentSetting&action=default_lookup_setting`,data);
  };
  export const api_save_setting= data => dispatch => {
	return axios.post( `${process.env.REACT_APP_BASE_URL}api=appointmentSetting&action=add_settings`,data);};

export const api_add_layout= data => dispatch => {
	return axios.post( `${process.env.REACT_APP_BASE_URL}api=appointmentSetting&action=add_layout`,data);};

export const api_add_layout_member= data => dispatch => {
return axios.post( `${process.env.REACT_APP_BASE_URL}api=appointmentSetting&action=add_layout_member`,data);};
export const api_update_layout= data => dispatch => {
return axios.post( `${process.env.REACT_APP_BASE_URL}api=appointmentSetting&action=update_layout`,data);};
export const api_remove_ongroup_delete= data => dispatch => {
return axios.post( `${process.env.REACT_APP_BASE_URL}api=appointmentSetting&action=remove_layout_user`,data);};
export const api_delete_user= data => dispatch => {
	return axios.post( `${process.env.REACT_APP_BASE_URL}api=appointmentSetting&action=delete_user`,data);};
export const api_delete_layout= data => dispatch => {
		return axios.post( `${process.env.REACT_APP_BASE_URL}api=appointmentSetting&action=delete_layout`,data);};
			

		
  
