import axios from 'axios';


export const api_get_patient = () => dispatch => {
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=inbox&action=api_get_patient`);
}

export const api_get_doctor = () => dispatch => {
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=inbox&action=api_get_doctor`);
}

export const api_upload_image = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=inbox&action=upload_image`,data);
}

export const api_assign_mail = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=inbox&action=assign_mail`,data);
}

export const api_get_inbox_patient = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=inbox&action=get_inbox_patient`,data);
}

