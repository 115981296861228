import { color } from "highcharts";
import React, { Component } from "react";
import { connect } from "react-redux";
import { wflow } from "./../../DS/general";
import "./tags.css";
class Patienttags extends Component {
  state = {
    tags: [],
    activePatient: "",
    clsname:'',
    color:''
  };
componentDidMount(){
  wflow("PATIENTTAGS - cmpntdidmount tags ", this.props.tags);
  //alert(this.props.activePatient);
 console.log("&&&&&&&&&&&&&&&-----------",this.props.activePatientGender.display)
 if(this.props.activePatientGender.display=='Female')
 {
  this.setState({clsname:' scroll_tab_active_female'})
 }
 else if(this.props.activePatientGender.display=='Male')
 {
  this.setState({clsname:' scroll_tab_active_male'})
 }
 else
 {
  this.setState({clsname:' scroll_tab_active_other'})
 }
 
}

  componentWillReceiveProps(nextProps) {
    wflow("PATIENTTAGS receiveprops", nextProps, this.state);
    this.setState({ tags: nextProps.patientTagsList });
console.log("&&&&&&&&&&&&&&&-----------",nextProps.selectedPatientDetails.gender)
//alert(nextProps.selectedPatientDetails.gender)

  }

componentWillMount=()=>
{
  this.setState({clsname:'.scroll_tab_active_female'})
  console.log("&&&&&&&&&&&&&&&****",this.props.tags)

}

  render() {
    let bb = this.props.patientTagsList.length;
    return (
      <div className="indented_text col-md-12" style={{marginTop:'-20px'}}>
        <div
          id="tabs3"
          className="scroll_tabs_theme_light scroll_tabs_container"
          style={{}}
        >
          <div
            className="scroll_tab_left_button"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: 26,
              cursor: "pointer",
              display: "block",
              
            }}
          />
          <div
            className="scroll_tab_inner"
            style={{
              marginLeft: "27px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "clip",
              fontSize: 0,
              position: "absolute",
              top: 0,
              left: 0,
              right: 0
              
            }}
          >
            <span
              className="scroll_tab_left_finisher"
              style={{ zoom: 1, userSelect: "none", display: "inline-block",background:'red !important' }}
            >
              &nbsp;
            </span>





            {this.props.patientTagsList!=undefined&&
              this.props.patientTagsList.slice(1).map(tag => (
                <span
                  
                  className={tag.patient_id==this.props.activePatient?"scroll_tab_last scroll_tab_first"+this.state.clsname:"scroll_tab_last scroll_tab_first "}
                  style={{
                    display: "inline-block",
                    zoom: 1,
                    userSelect: "none",
                  }}
                >
                
                  {wflow("PATIENTTAGS - inside loop", tag)}
                  <a style={{color: 'yellow'}} style={{padding: 10}} onClick={e => this.props.loadPatient(e,tag)} > {tag.Firstname} </a>
                  {bb>2? <button
                    onClick={e => this.props.removeTag(e, tag)}
                    className="tabclose"
                    
                  >
                    x 
                  </button>:""}
                </span>
              ))}
             

            <span
              className="scroll_tab_right_finisher"
              style={{ zoom: 1, userSelect: "none", display: "inline-block" }}
            >
              &nbsp;
            </span>
          </div>
          <div
            className="scroll_tab_right_button"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: 26,
              cursor: "pointer",
              display: "none"
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log('&&&&&&&&&&&&&&&&&&&&&',state.getDrAppointments.patientTags)
  let patientTagsList = state.getDrAppointments.patientTags
    ? state.getDrAppointments.patientTags
    : [];

  wflow("PATIENTTAGS - statetoprops tags cmpnt", patientTagsList);
  return {
    patientTagsList
  };
};
export default connect(mapStateToProps)(Patienttags);
