import React, { Component } from 'react';
import Popup from 'reactjs-popup';


class editimmunisations extends Component {
    state = { 
      vaccines:[],
      billing_provider:"",
      given_by:"",
      date:"",
      include_inactive_providers:"",
      site:"",
      sequence:"",
      route:"",
      batch_no:"",
      batch_expiry:"",
      save_batch_details:"",
      comment:"",
      send_reminder:"",
      reminder_date:"",
     }
     handleFieldValueChanges(key, value) {
      this.setState({
        [key]: value,
      });

      console.log(this.state);
    }
    render() {
        return (
            <Popup  trigger={ <button id=""  type="button" className="btn btn-default" style={{marginRight: '10px', padding: ''}}>Edit</button>} position="right center"   modal
            closeOnDocumentClick={false}
            >
             {close => (

                 <div>



<div className="modal-header" style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}>

<button type="button" className="close" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
×
</button>
<h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Immunisations</h4>
</div>





<div className="modal-body">
  <div className="col-sm-12" style={{paddingRight: '0px', marginTop: '10px', marginBottom: '10px'}}>
    <div className="form-group row">
      <label className="col-sm-12 col-form-label text-left" style={{paddingRight: '0px', paddingLeft: '0px', marginLeft: '0px', marginRight: '0px'}}>Available vaccines:</label>
      <div className="col-sm-12" style={{paddingLeft: '0px', marginLeft: '0px',marginTop:'10px'}}>
        <table className="calendar table table-bordered" style={{float: 'left', width: '100%'}}>
          <thead>
            <tr style={{backgroundColor: '#f4f4f4'}}>
              <th>vaccines</th>
              <th>Against</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&nbsp;Acthib</td>
              <td>&nbsp;HIB</td>
            </tr>
            <tr>
              <td>&nbsp;Acthib</td>
              <td>&nbsp;HIB</td>
            </tr>
            <tr>
              <td>&nbsp;Acthib</td>
              <td>&nbsp;HIB</td>
            </tr>
            <tr>
              <td>&nbsp;Acthib</td>
              <td>&nbsp;HIB</td>
            </tr>
            <tr>
              <td>&nbsp;Acthib</td>
              <td>&nbsp;HIB</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-sm-2 col-form-label text-left" style={{paddingRight: '0px', paddingLeft: '0px', marginLeft: '0px', marginRight: '0px'}}>Billing Provider:</label>
      <div className="col-sm-6 cursor">
        <select style={{width: '100%', float: 'left'}}
        onChange={e =>
          this.handleFieldValueChanges('billing_provider', e.target.value)}>
          <option>Select One</option>
          <option>Dr. Frederick Findacure (Main Surgary) </option>
        </select>
      </div>
      <div className="col-sm-4">
      <label>  <input type="checkbox" defaultValue style={{margin: '8px 0px 3px 0px', float: 'left'}} /><span style={{ paddingLeft: '5px', marginRight: '5px', float: 'left'}}>Include inactive provider</span></label>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-sm-2 col-form-label text-left" style={{paddingRight: '0px', paddingLeft: '0px', marginLeft: '0px', marginRight: '0px'}}>Given by:</label>
      <div className="col-sm-6">
        <select style={{width: '100%', float: 'left'}}
        onChange={e =>
          this.handleFieldValueChanges('given_by', e.target.value)}>
          <option>Select One</option>
          <option>Dr. Frederick Findacure </option>
        </select>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-sm-2 col-form-label text-left" style={{paddingRight: '0px', paddingLeft: '0px', marginLeft: '0px', marginRight: '0px'}}>Date:</label>
      <div className="col-sm-3 cursor">
        <input 
        onChange={e =>
          this.handleFieldValueChanges('date', e.target.value)}></input>
       type="text" name="search_condition" className="form-control form-control-sm rq-form-elemen" defaultValue="16-12-2019" />
      </div>
      <label className="col-sm-1 col-form-label text-right" style={{paddingRight: '0px', paddingLeft: '0px', marginLeft: '0px', marginRight: '0px'}}>Site:</label>
      <div className="col-sm-3">
        <select>
        onChange={e =>
          this.handleFieldValueChanges('site', e.target.value)}>
          <option>Select One</option>
        </select>
      </div>
      <label className="col-sm-1 col-form-label text-left" style={{paddingRight: '0px', paddingLeft: '0px', marginLeft: '0px', marginRight: '0px'}}>Sequence:</label>
      <div className="col-sm-2">
        <input
        onChange={e =>
          this.handleFieldValueChanges('Sequence', e.target.value)}></input>
         type="text" name="search_condition" className="form-control form-control-sm rq-form-elemen" defaultValue={1} />
      </div>
    </div>
    <div className="form-group row">
      <label className="col-sm-2 col-form-label text-left" style={{paddingRight: '0px', paddingLeft: '0px', marginLeft: '0px', marginRight: '0px'}}>Route:</label>
      <div className="col-sm-2">
        <input
        onChange={e =>
          this.handleFieldValueChanges('route', "imi")}></input>
         type="radio" name="smoking" value="Non-smoker" style={{margin: '8px 0px 3px 5px', float: 'left'}} /><span style={{marginTop: '5px', paddingLeft: '5px', marginRight: '5px', float: 'left'}}>IMI</span>
      </div>
      <div className="col-sm-2">
        <input
        onChange={e =>
          this.handleFieldValueChanges('route', "sc")}></input>
        type="radio" name="smoking" value="Non-smoker" style={{margin: '8px 0px 3px 5px', float: 'left'}} /><span style={{marginTop: '5px', paddingLeft: '5px', marginRight: '5px', float: 'left'}}>SC</span>
      </div>
      <div className="col-sm-2">
        <input 
        onChange={e =>
          this.handleFieldValueChanges('route', "orl")}></input>
        type="radio" name="smoking" value="Non-smoker" style={{margin: '8px 0px 3px 5px', float: 'left'}} /><span style={{marginTop: '5px', paddingLeft: '5px', marginRight: '5px', float: 'left'}}>Orl</span>
      </div>
      <div className="col-sm-3">
        <input
        onChange={e =>
          this.handleFieldValueChanges('route', "intradermal")}>
            </input> type="radio" name="smoking" value="Non-smoker" style={{margin: '8px 0px 3px 5px', float: 'left'}} /><span style={{marginTop: '5px', paddingLeft: '5px', marginRight: '5px', float: 'left'}}>Intradermal</span>
      </div>
    </div>
    <div className="form-group row" style={{marginTop: '10px'}}>
      <label className="col-sm-2 col-form-label text-left" style={{paddingRight: '0px', paddingLeft: '0px', marginLeft: '0px', marginRight: '0px'}}>Batch No:</label>
      <div className="col-sm-3 cursor">
        <select>
        onChange={e =>
          this.handleFieldValueChanges('batch_no', e.target.value)}>
          <option>Select One</option>
        </select>
      </div>
      <label className="col-sm-2 col-form-label text-right" style={{paddingRight: '0px', paddingLeft: '0px', marginLeft: '0px', marginRight: '0px'}}>Batch expiry:</label>
      <div className="col-sm-2">
        <input 
        onChange={e =>
          this.handleFieldValueChanges('batch_expiry', e.target.value)}></input>
        type="text" name="search_condition" className="form-control form-control-sm rq-form-elemen" defaultValue="16-12-2019" />
      </div>
      <div className="col-sm-3">
        <input
        onChange={e =>
          this.handleFieldValueChanges('save_batch_details', e.target.value)}>
            </input> type="radio" defaultValue style={{margin: '8px 0px 3px 5px', float: 'left'}} /><span style={{marginTop: '5px', paddingLeft: '5px', marginRight: '5px', float: 'left'}}>Save batch details</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-sm-2 col-form-label text-left" style={{paddingRight: '0px', paddingLeft: '0px', marginLeft: '0px', marginRight: '0px'}}>Comment:</label>
      <div className="col-sm-10 cursor" style={{paddingLeft: '0px', marginLeft: '0px', marginTop: '20px'}}>
        <textarea 
        onChange={e =>
          this.handleFieldValueChanges('comment', e.target.value)}></textarea>
        style={{width: '100%', height: '100px', border: '1px solid #cccccc'}} defaultValue={""} />
      </div>
    </div>
    <div className="form-group row">
      <div className="col-sm-3">
        <input
         onChange={e =>
          this.handleFieldValueChanges('send_reminder', e.target.value)}></input>
         type="radio" defaultValue style={{margin: '8px 0px 3px 5px', float: 'left'}} /><span style={{marginTop: '5px', paddingLeft: '5px', marginRight: '5px', float: 'left'}}>Send reminder</span>
      </div>
      <label className="col-sm-2 col-form-label text-right" style={{paddingRight: '0px', paddingLeft: '0px', marginLeft: '0px', marginRight: '0px'}}>Reminder Dte:</label>
      <div className="col-sm-3">
        <input 
        onChange={e =>
          this.handleFieldValueChanges('reminder_date', e.target.value)}></input>
        type="text" name="search_condition" className="form-control form-control-sm rq-form-elemen" defaultValue="16-12-2019" />
      </div>
    </div>						
  </div>
  </div>
  <div className="modal-footer" style={{marginTop:'590px'}}>
    <button type="button" className="btn btn-primary">Save</button>
    <button type="button" className="btn btn-default"  onClick={() => close()} data-dismiss="modal" aria-label="Close">Cancel</button>
  </div>






  </div>

)}
</Popup>
         );
    }
}

export default editimmunisations ;
