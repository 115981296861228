import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Showhelp from './../showHelp';
import {api_get_account_holder,api_delete_holder} from './../../DS/DS.Paitents';
import EditPatientDetails from './../appointment/editPatientDetails';
import AddPatientForm from './../appointment/AddPatientForm'
class addNewAccount extends Component {
    constructor(props)
    {
      super(props);
      this.state={
        open:this.props.open,
        openEdit:false,
        key:0,
      }
    }



    
    componentWillMount=()=>
    {
        console.log('&&&&&&&&&&&&&&&&&&&&',this.props)
       
    }
    render(){
        return(
            <Popup position="right center"   modal open={this.state.open} >
            {close => (
                <div>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                        <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px', fontSize:'16px',marginBottom:'0px'}}>Account payer <Showhelp gotohelp="account_payer_help"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.closeAll()} style={{color:'black'}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ fontSize: "14px",overflow: 'scroll',height:'850px' }}>
                        <div className="row">
                            <div className="col-sm-12 c">
	                            <div className="card" style={{paddingLeft: '0px',border:'1px solid #ccc'}}>
		                            <div className="card-block">
		                                    
                                        <section className="content">
                                            
                                            <div className="col-xs-12">
                                                <div className="box">
                                                    <div className="box-body">
                                                        <div className="col-xs-12">
                                                            <div className="col-md-3">
                                                                <label>Title</label>
                                                            </div>
                                                        </div>    
                                                        <hr/>
                                                        <div class="bottom-buttons" style={{marginTop:'10px'}}>
                                                            <div class="col-md-6 col-sm-7">
                                                            </div>
                                                            <div class="col-md-6 col-sm-5 col-xs-12 text-right">
                                                                <button type="button" class="btn btn-primary" onClick={(e)=>this.checkRecord('view')}>View details</button>
                                                                <button type="button" class="btn btn-primary" onClick={()=>this.setState({openAddPatient:true})}>Add new</button>
                                                                <button type="button" class="btn btn-primary" onClick={()=>this.deleteRecord()}>Delete</button>
                                                            </div>
                                                        </div>
                                                       </div>
                                            </div>
                                    </div>
                                 </section>
                            </div>
	                    </div>
                    </div>
                </div>
            </div>
        </div>
        )}
        </Popup>
    )}           
}
const mapDispatchToProps = (dispatch) => {
	return {
        getHolder: (data) => dispatch(api_get_account_holder(data)),
        deletedHolder :(data) =>dispatch(api_delete_holder(data))
      
     
  }
}
const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details:'';
  
  return {
    redux_patientId,redux_docId
  };
  };
export default connect (mapStateToProps, mapDispatchToProps) (addNewAccount);