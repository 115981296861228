import axios from 'axios';
export const api_getreminder_reason=(data) => dispatch =>
{
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=reminders&action=get_reminder_reason`, data);
}
export const api_getallreminders=(data) => dispatch =>
{
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=reminders&action=get_reminders`, data);
}
export const api_getdoc=() => dispatch =>
{
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=reminders&action=get_doc`);
}
export const api_postreminder=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=reminders&action=add_reminder`,data);
}
export const api_update_reason=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=reminders&action=update_reason`,data);
}
export const api_delete_reminder=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=reminders&action=delete_reminder`,data);
}
export const api_new_Reason=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=reminders&action=new_Reason`,data);
}
export const api_get_settings=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=reminders&action=get_settings`,data);
}
export const api_perform_action=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=reminders&action=perform_action`,data);
}
export const api_get_settingsdef=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=reminders&action=get_settingsdef`,data);
}

export const api_get_distinct_reason=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=reminders&action=get_distinct_reason`,data);
}
export const api_get_reminders_top=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=reminders&action=get_reminders_top`,data);
}

export const api_get_appointments=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=patients&action=get_appointments`,data);
}

