import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import { connect } from 'react-redux';
import {api_getreminder_reason,api_getallreminders,api_getdoc,api_postreminder
  ,api_update_reason,api_delete_reminder,api_new_Reason,api_get_settings,api_perform_action,api_get_settingsdef} from '../../../DS/DS.Reminders';
import moment from 'moment';
import {
    NotificationContainer,
    NotificationManager,
  } from 'react-notifications';
  import CommonGrid from "../../crudcomponentV2/combinedcrud";
  import './reminders.css'
  import { confirmAlert } from 'react-confirm-alert';
import Centers from 'components/superadmin/managecenters/centers';
import cicon from "../../../assets/img/cicon.png";
import Showhelp from './../../showHelp';

class Reminders extends Component
{
    constructor(props)
    {
        super(props)
        this.state={
            all_reminder_reasons:[],
            openModal:false,
            all_reminders:[],
            startDate: new Date(),
            dateShow: false,
            selectedReminderRadionId:0,
            attemptin:'',
            othersn:'',
            selectedRsnId:'',
            all_doctors:[],
            otherreason:'',
            selectedDoc:'',
            disable:true,
            setting_data:'',
            errors:[],
            crudin:"",
            selectedRsnIndex:-1,
            editMode:'',
            reason:'',
            selectedReminderRadionId1:0,
            ischecked:1,
            selectedId:'',
            selectedLength:-1,
            key:0,
            addnew:false,
            newradio:'',
            newdefint:'',
            isFav:false,
            newRsn:'',
            showdeleted:false,
            showperformed:false,
            showDef:1,
            weekSel:'',
            setting_default_interval:'',
            setting_actions:'',
           actionPeriod:new Date(new Date().getFullYear() +"-" +(new Date().getMonth() + 1) +"-" +new Date().getDate()),
           reattempt:'',
           reattemptSelected:0

        }    
    }
    componentWillMount()
    {
       // alert("ïn")
       this.setState({openModal:this.props.showModal})
        this.props.apireasonreminder().then(contData=>{
            this.setState({all_reminder_reasons:contData.data.content})
           });
           this.props.apiallreminders({patient_id:this.props.redux_patientId}).then(remData=>{
            this.setState({all_reminders:remData.data.content})
           });
           this.setState({
            startDate: new Date()
          });
          console.log(moment(new Date()).format('DD/MM/YYYY'))
          this.props.apigetdoc().then(alldocs=>{
            this.setState({all_doctors:alldocs.data.content})
          })
          const settname={setting_name:'reminder_text'}
          this.props.apigetsettings(settname).then(res=>{
           this.setState({setting_data:res.data.content[0].setting_value})
          })
          const settdef={setting_name:'default_interval'}
          this.props.apigetsettingsdef(settdef).then(res=>{
           this.setState({setting_default_interval:res.data.content[0]})
           this.handleReminderUpdateRadio1()
          })
          const settact={setting_name:'default_actions'}
          this.props.apigetsettings(settact).then(res=>{
           this.setState({setting_actions:res.data.content[0].setting_value})
          // alert(this.state.actionPeriod)
           this.state.actionPeriod.setDate( this.state.actionPeriod.getMonth() - parseInt(this.state.setting_actions))
          })
       
          this.reminder_crud();
    }
    handleDate = date => {
        console.log(">>",date);
        this.setState({
          startDate: date //moment(new Date(date)).format('DD/MM/YYYY')
        }); 

        if(moment(new Date(date)).format('DD/MM/YYYY') == moment(new Date()).format('DD/MM/YYYY')){

          this.setState({
            dateShow: true,
          });
        }else {
          this.setState({
            dateShow: false,
          });
        }
      };
      handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
      
        //attm
        if(!this.state.attemptin){
           formIsValid = false;
           errors["attm"] = "Cannot be empty";
        }
      
       
       this.setState({errors: errors});
       return formIsValid;
      }
      handleReminderRadio=(radioid,e)=>
      {
       // alert(this.state.attemptin)
           /// e.preventDefault();
              if(this.handleValidation())
              {
                this.setState({selectedReminderRadionId:radioid})
               this.state.selectedReminderRadionId=radioid
           // alert(this.state.selectedReminderRadionId)
            if(radioid==1)
            {
              
                  let now = new Date();
                  now.setDate(now.getDate()+parseInt(this.state.attemptin) * 7);
                  this.setState({
                      startDate: now //moment(now).format('DD/MM/YYYY')
                    })
               //       alert(  moment(now).format('DD/MM/YYYY'))
          
          }
            if(radioid==2)
            {
                  let now = new Date();
                now.setMonth(now.getMonth()+parseInt(this.state.attemptin));
               
                this.setState({
                    startDate: now //moment(now).format('DD/MM/YYYY')
                  });
              //alert(  moment(now).format('DD/MM/YYYY'))
                 
            }
            if(radioid==3)
            {
                  let now = new Date();
                now.setYear(now.getFullYear()+parseInt(this.state.attemptin));
                this.setState({
                    startDate: now //moment(now).format('DD/MM/YYYY')
                  });
             // alert( moment(now).format('DD/MM/YYYY'))
            }
            
              }
              
      }
      handleReminderInput=(val)=>
      {
           /// e.preventDefault();
           this.setState({attemptin:val})
              if(this.handleValidation() && val!='')
              {
                this.setState({attemptin:val})
           //    this.state.selectedReminderRadionId=radioid
           // alert(this.state.selectedReminderRadionId)
            if(this.state.selectedReminderRadionId==1)
            {
              
                  let now = new Date();
                  now.setDate(now.getDate()+parseInt(val) * 7);
                  this.setState({
                      startDate: now //moment(now).format('DD/MM/YYYY')
                    })
               //       alert(  moment(now).format('DD/MM/YYYY'))
          
          }
            if(this.state.selectedReminderRadionId==2)
            {
                  let now = new Date();
                now.setMonth(now.getMonth()+parseInt(val));
               
                this.setState({
                    startDate: now //moment(now).format('DD/MM/YYYY')
                  });
              //alert(  moment(now).format('DD/MM/YYYY'))
                 
            }
            if(this.state.selectedReminderRadionId==3)
            {
                  let now = new Date();
                now.setYear(now.getFullYear()+parseInt(val));
                this.setState({
                    startDate: now //moment(now).format('DD/MM/YYYY')
                  });
             // alert( moment(now).format('DD/MM/YYYY'))
            }
            
              }
              
      } 
      
      saveData = () =>
      {
        let submitData={
            rsn:this.state.selectedRsnId,
            cntnt:this.state.otherreason,
            rdt:this.state.startDate,
            fid:this.props.redux_docId,
            tid:this.state.selectedDoc,
            pid:this.props.redux_patientId
        }
        this.props.apipostreminder(submitData).then(
            req=>this.props.changeKey(),
            NotificationManager.success(
                'Reminder created',
                //this.changeKey()
                )

        )
      }
      selectedReason=(e,selecteditem,index) =>
      {
          this.setState({selectedRsnId:selecteditem.reason_id,selectedRsnIndex:index})
          let atin=selecteditem.default_interval.split(" ")[0]
         
          console.log(this.state.attemptin)
          this.state.attemptin=atin;
          this.handleReminderRadio(selecteditem.radioValue,e)
          if(selecteditem.radioValue==1)
            {
              
                let now = new Date();
                now.setDate(now.getDate()+parseInt(atin) * 7);
                this.setState({
                    startDate: now, //moment(now).format('DD/MM/YYYY'),
                    attemptin:atin,
                   selectedReminderRadionId:selecteditem.radioValue
                  });
            }
            if(selecteditem.radioValue==2)
            {
                let now = new Date();
                now.setMonth(now.getMonth()+parseInt(atin));
               
                this.setState({
                    startDate: now ,//moment(now).format('DD/MM/YYYY'),
                    attemptin:atin,
                    selectedReminderRadionId:selecteditem.radioValue
                  });
            }
            if(selecteditem.radioValue==3)
            {
                let now = new Date();
                now.setYear(now.getFullYear()+parseInt(atin));
                this.setState({
                    startDate: now ,//moment(now).format('DD/MM/YYYY'),
                    attemptin:atin,
                    selectedReminderRadionId:selecteditem.radioValue
                  });
            }
       }
        
       deleteReminder=(id,action,selectedlength)=>
       {
          if(action=='selection')
          {
            this.state.selectedId=id
            this.state.selectedLength=selectedlength
          }
          if(action=='delete')
          {
           if (this.state.selectedLength> 0) {
              // const selectedIds={tb_name:"clinical_note",primary_key:'note_id',clinical_id:this.state.checkedRec}
                confirmAlert({
                  title: '',
                  message: 'You are about to delete reminder(s). Do you want to proceed?',
                  buttons: [
                  {
                      label: 'Yes',
                      onClick: () => {
                      this.props.apiDeleteReminder({selectedIds:this.state.selectedId}).then(
                      res => this.changeNote() ,
                      NotificationManager.success(
                      'Record deleted',
                      //this.changeKey()
                      )
                    );
                  },
              },
              {
                label: 'No',
                onClick: () => { this.changeNote()},
              },
            ], 
          });
        } else {
          NotificationManager.error('Please select a record to delete');
        }
      }
    }
    handleReminderUpdateRadio=(id)=>
       {}
       handleReminderUpdateRadio1=()=>
       {
         console.log(this.state.setting_default_interval)
        this.state.reattempt=this.state.setting_default_interval.def_interval.split(" ")[0]
        this.state.reattemptSelected=this.state.setting_default_interval.radiovalue
       }
       setTrClass = (id) => {
        let classs = "";
        classs +=
          id == this.state.selectedRsnIndex
            ? " selectedBg "
            : "";
        return classs;
      };
      editRecord=(record,index)=>
      {
      //  selectedid.replace(/,\s*$/, "")
        this.setState({editMode:'E_'+record.reason_id})
        this.setState({reason:record.reason,default_interval:record.default_interval.split(" ")[0],radiotype:record.radioValue})

      }
      updateRecord=(id)=>
      {
        let defaultV='';
        if(this.state.radiotype==1)
        {
          defaultV='Week';
        }
        else if(this.state.radiotype==2)
        {
          defaultV='Month';
        }
        else if(this.state.radiotype==3)
        {
          defaultV='Year';
        }
       
        const updaterecord={
          reason_id:id,
          reason:this.state.reason,
          defaultint:this.state.default_interval+' '+defaultV,
          radioty:this.state.radiotype
        }
          this.props.apiupdatereason(updaterecord).then(
            req =>{this.setState({editMode:''})
            this.props.apireasonreminder().then(contData=>{
              this.setState({all_reminder_reasons:contData.data.content})
             });
          }
          )
      }
      updateReminder=(e)=>
      {
        if(e.target.checked==true)
        {
            this.setState({disable:false})
        }
        else
        {
          this.setState({disable:true})
        }
      }
      changeNote=()=>{
        this.setState({key:Math.random()})
        this.setState({addnew:false})
      }
      addNewReason=()=>{
        let myfav=0
        if(this.state.isFav==true)
        {
          myfav=1
        }
        else
        {
          myfav=0
        }
        let appstr='';
        if(this.state.newradio==1)
        {
          appstr=' Week'
        }
        else if(this.state.newradio==2)
        {
          appstr=' Month'
        }
        else
        {
          appstr=' Year'
        }

          const newData={
          radiotype:this.state.newradio,
          interval:this.state.newdefint + appstr,
          fav:myfav,
          rsn:this.state.newRsn,
          doc_id:this.props.redux_docId
        }
        this.props.addNewRecordApi(newData).then(
          req=>{
            NotificationManager.success(
              'New reason recorded Successfully',
              )
              this.setState({addnew:false})
              this.props.apireasonreminder().then(contData=>{
                this.setState({all_reminder_reasons:contData.data.content})
               });
          }
        )
      }
      notRequiredReminder=(stat,msg)=>{
        if (this.state.selectedLength> 0) {
           this.props.apiPerformed({selectedIds:this.state.selectedId,performStat:stat}).then(
                  res => this.changeNote() ,
                  NotificationManager.success(
                  'Action marked as '+msg,
                  //this.changeKey()
                  )
                );
      } else {
      NotificationManager.error('Please select a record to update');
    }
      }
      handleCheckbox = (e,actionfor) => {
        //alert(actionfor)
        if(actionfor=='showdeleted')
        {
          if (e.target.checked) {
            this.state.showdeleted=1;
            this.state.showDef=0
            this.reminder_crud();
          } else {
            this.state.showdeleted=0;
            if(this.state.showperformed!=1)
            {
                this.state.showDef=1;
            }
            this.reminder_crud();
            
          }
        }
        else if(actionfor=='showperformed')
        {
          if (e.target.checked) {
            this.state.showperformed= 1 
            this.state.showDef=0
            this.reminder_crud();
          } else {
            this.state.showperformed= 0
             if(this.state.showdeleted!=1)
            {
                this.state.showDef=1;
            }
            this.reminder_crud();
          }
        }
        else if(this.state.showdeleted==0 && this.state.showperformed==0)
        {
          this.setState({showDef:1})
          this.reminder_crud();
        }
        
        this.changeNote();
      };
      reminder_crud = () => {
        let returnjson = "";
        this.setState({ crudin: "" });
        if (this.state.showDef == 1) {
         // alert("IN default")
          let crudwithout= {
            jsonGridVersion: "v2.0",
            grid: true,
            viewgrid: {
              isVisible:true,
              isPagination: true,
              isFiltering: false,
              isSelection:true,
              deleteFunction:this.deleteReminder,
              isDateGroup:false,
              isYearSeparator:false,
              dateCol:'',
              cellClicked:'',
              baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
              query: {
                tb: "reminders",
                cols: [
                  "reminder_id",
                  "DATE_FORMAT(reminder_datetime,'%d-%m-%Y') as date_added",
                  "content",
                  "FirstName as from_doc",
                  "reason"
                ],
               where: [
                  {
                    type: "and",
                    key: "patient_id",
                    operator: "=",
                    value: this.props.redux_patientId
               },
               {
                type: "and",
                key: "reminders.isDeleted",
                operator: "=",
                value: 0
              },{
                type: "and",
                key: "reminders.isperformed",
                operator: "=",
                value: 0
              }
             ],
               joins:[
                {
                  joinType: "left",
                  joinTable: "doctor_tb",
                  joinCondition: "reminders.to_guid = doctor_tb.doctor_id"
                },{
                  joinType: "left",
                  joinTable: "reminder_reason_tb",
                  joinCondition: "reminders.reminder_reason = reminder_reason_tb.reason_id"
                }
               ],
               customCols: [  
                "CONCAT(Firstname,' ',Surname) AS docname"
                
              ],
                primary_key:"reminder_id",
              },
              viewtabletitle: "",
              columns: [
                { name: "date_added", title: "Reminder Date" },
                { name: "from_doc"  , title: "Doctor" },
                { name: "reason" , title: "Reason" },
                { name: "content" , title: "Other Reason" },
              ],
              rows: [],
              sortinit: [{ columnName: "date_added", direction: "desc" }],
              pagesize: 10
            }
    
          }
          returnjson = crudwithout;
          this.setState({ crudin: crudwithout });
        }
        else if (this.state.showdeleted == 1 && (this.state.showperformed==0 || this.state.showperformed==false)) {
        //  alert("In deleted")
          let crudDelete={
            jsonGridVersion: "v2.0",
            grid: true,
            viewgrid: {
              isVisible:true,
              isPagination: true,
              isFiltering: false,
              isSelection:true,
              deleteFunction:this.deleteReminder,
              isDateGroup:false,
              isYearSeparator:false,
              dateCol:'',
              cellClicked:'',
              baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
              query: {
                tb: "reminders",
                cols: [
                  "reminder_id",
                  "DATE_FORMAT(reminder_datetime,'%d-%m-%Y') as date_added",
                  "content",
                  "FirstName as from_doc",
                  "reason"
                ],
               where: [
                  {
                    type: "and",
                    key: "patient_id",
                    operator: "=",
                    value: this.props.redux_patientId
               },
               {
                type: "and",
                key: "reminders.isperformed",
                operator: "=",
                value: 0
              },
             ],
               joins:[
                {
                  joinType: "left",
                  joinTable: "doctor_tb",
                  joinCondition: "reminders.to_guid = doctor_tb.doctor_id"
                },{
                  joinType: "left",
                  joinTable: "reminder_reason_tb",
                  joinCondition: "reminders.reminder_reason = reminder_reason_tb.reason_id"
                }
               ],
               customCols: [  
                "CONCAT(Firstname,' ',Surname) AS docname"
                
              ],
                primary_key:"reminder_id",
              },
              viewtabletitle: "",
              columns: [
                { name: "date_added", title: "Reminder Date" },
                { name: "from_doc"  , title: "Doctor" },
                { name: "reason" , title: "Reason" },
                { name: "content" , title: "Other Reason" },
              ],
              rows: [],
              sortinit:[{ columnName: "date_added", direction: "desc" }],
              pagesize: 10
            }
    
          }          
          this.setState({ crudin: crudDelete });
      
          returnjson = crudDelete;
        }
       else if (this.state.showperformed == 1 && (this.state.showdeleted==0 || this.state.showdeleted==false)) {
         //  alert(this.state.actionPeriod)
           let crudwithout= {
             jsonGridVersion: "v2.0",
             grid: true,
             viewgrid: {
               isVisible:true,
               isPagination: true,
               isFiltering: false,
               isSelection:true,
               deleteFunction:this.deleteReminder,
               isDateGroup:false,
               isYearSeparator:false,
               dateCol:'',
               cellClicked:'',
               baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
               query: {
                 tb: "reminders",
                 cols: [
                   "reminder_id",
                   "DATE_FORMAT(reminder_datetime,'%d-%m-%Y') as date_added",
                   "content",
                   "FirstName as from_doc",
                   "reason"
                 ],
                where: [
                   {
                     type: "and",
                     key: "patient_id",
                     operator: "=",
                     value: this.props.redux_patientId
                },
                {
                 type: "and",
                 key: "reminders.isPerformed",
                 operator: "!=",
                 value: 0
               },
               {
                type: "and",
                key: "reminders.reminder_datetime",
                operator: "> ",
                value:this.state.actionPeriod
              },
               {
                type: "or",
                key: "reminders.isDeleted",
                operator: "=",
                value: 0
              },
              ],
                joins:[
                 {
                   joinType: "left",
                   joinTable: "doctor_tb",
                   joinCondition: "reminders.to_guid = doctor_tb.doctor_id"
                 },{
                   joinType: "left",
                   joinTable: "reminder_reason_tb",
                   joinCondition: "reminders.reminder_reason = reminder_reason_tb.reason_id"
                 }
                ],
                customCols: [  
                 "CONCAT(Firstname,' ',Surname) AS docname"
                 
               ],
                 primary_key:"reminder_id",
               },
               viewtabletitle: "",
               columns: [
                 { name: "date_added", title: "Reminder Date" },
                 { name: "from_doc"  , title: "Doctor" },
                 { name: "reason" , title: "Reason" },
                 { name: "content" , title: "Other Reason" },
               ],
               rows: [],
               sortinit: [{ columnName: "date_added", direction: "desc" }],
               pagesize: 10
             }
     
           }
           returnjson = crudwithout;
           this.setState({ crudin: crudwithout });
         }
         else if (this.state.showperformed == 1 && this.state.showdeleted==1) {
          //alert("IN last")
           let crudall= {
             jsonGridVersion: "v2.0",
             grid: true,
             viewgrid: {
               isVisible:true,
               isPagination: true,
               isFiltering: false,
               isSelection:true,
               deleteFunction:this.deleteReminder,
               isDateGroup:false,
               isYearSeparator:false,
               dateCol:'',
               cellClicked:'',
               baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
               query: {
                 tb: "reminders",
                 cols: [
                   "reminder_id",
                   "DATE_FORMAT(reminder_datetime,'%d-%m-%Y') as date_added",
                   "content",
                   "FirstName as from_doc",
                   "reason"
                 ],
                where: [
                   {
                     type: "and",
                     key: "patient_id",
                     operator: "=",
                     value: this.props.redux_patientId
                },
                {
                 type: "and",
                 key: "reminders.reminder_datetime",
                 operator: "> ",
                 value:this.state.actionPeriod
               },
               /* {
                 type: "and",
                 key: "reminders.isPerformed",
                 operator: "!=",
                 value: 0
               },
               {
                type: "or",
                key: "reminders.isDeleted",
                operator: "=",
                value: 1
              }*/,
              ],
                joins:[
                 {
                   joinType: "left",
                   joinTable: "doctor_tb",
                   joinCondition: "reminders.to_guid = doctor_tb.doctor_id"
                 },{
                   joinType: "left",
                   joinTable: "reminder_reason_tb",
                   joinCondition: "reminders.reminder_reason = reminder_reason_tb.reason_id"
                 }
                ],
                customCols: [  
                 "CONCAT(Firstname,' ',Surname) AS docname"
                 
               ],
                 primary_key:"reminder_id",
               },
               viewtabletitle: "",
               columns: [
                 { name: "date_added", title: "Reminder Date" },
                 { name: "from_doc"  , title: "Doctor" },
                 { name: "reason" , title: "Reason" },
                 { name: "content" , title: "Other Reason" },
               ],
               rows: [],
               sortinit: [{ columnName: "date_added", direction: "desc" }],
               pagesize: 10
             }
      
           }
           returnjson = crudall;
           this.setState({ crudin: crudall });
         }
        
        
        return returnjson;
      };
      handleReminderRadio1=(radioid,e)=>
      {
           /// e.preventDefault();
              if(this.handleValidation1())
              {
                this.setState({reattemptSelected:radioid})
               this.state.reattemptSelected=radioid
            }
              
      }  
      handleValidation1(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
      
        //attm
        if(!this.state.reattempt){
           formIsValid = false;
           errors["attm1"] = "Cannot be empty";
        }
      
       
       this.setState({errors: errors});
       return formIsValid;
      } 
      focusTextInput() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        // this.textInput.current.focus();
        document.getElementById("remindercalendar").focus();
        // this.textInput.focus();
      }
    render() {
        return (
            <Popup 
            /*trigger={}*/ 
            position="right center"   
            open={this.state.openModal}
            modal
            closeOnDocumentClick={false}>
             {close => (

                <div style={{ width:'1000px', marginLeft:'-25px', backgroundColor:'#FFF', float:'left'}}>
                      <div className="modal-header">
                    <button type="button" className="close" onClick={() =>{close();this.props.changeKey()}} data-dismiss="modal" style={{ fontSize:'25px', right: '0px'}}>×</button>
                    <span className="popup-title" >Reminder <Showhelp gotohelp="reminders_help"/></span>
                    </div>
                    <div className="modal-body">
                    <div className="col-sm-6 m-b-0 mr-0 ml-0 pl-0 pr-0">
                        <div className="form-group row m-b-10">
                        <label className="col-sm-2 col-form-label text-left">To See</label>
                        <div className="col-sm-9">
                            
                            <select name="select" className="form-control" style={{padding: '.0rem .5rem', height: '30px', fontSize: '14px'}} onChange={(e) => this.setState({selectedDoc: e.target.value })}>
                                <option value="0">All</option>
                                {this.state.all_doctors != undefined && this.state.all_doctors.length> 0 && this.state.all_doctors.map((alldocs) => <option key={alldocs.doctor_id} value={alldocs.doctor_id}>Dr.{alldocs.Firstname}{alldocs.Surname}</option>)}
                            </select>
                        </div>
                        </div>
                        <div className="form-group row">
                          
                       {this.state.addnew ?
                        
                          <div className="card ml-3" style={{height: 'auto', float: 'left', paddingBottom:'10px', border: '1px solid #ddd', fontSize:'12px',marginBottom:'0px'}}>
                            <div className="card-block">
                            <div className="col-sm-12">
                              <div className="col-sm-2"><label>Reason</label></div>
                              <div className="col-sm-4">
                                <input className="form-control" type="text" onChange={(e)=>this.setState({newRsn:e.target.value})}/>
                                </div>
                                <div className="col-sm-6 mt-2">
                                  <input type="checkbox" onChange={(e)=>this.setState({isFav:e.target.checked})}/> Is Favourite
                                  </div>
                              </div>
                              <div className="col-sm-12">
                              <div className="col-sm-2"><label>Interval</label></div>
                              <div className="col-sm-4">
                                      <input type="text" className="form-control" onChange={(e)=>{this.setState({newdefint:e.target.value})}}/>
                                  </div>
                                  <div className="col-sm-6">  
                                    <select onChange={(e)=>{this.setState({newradio:e.target.value})}}>
                                           <option value="">Select</option>
                                            <option value="2">Month(s)</option>
                                            <option value="3">Year(s)</option>
                                            <option value="1">Week(s)</option>
                                          </select>      
                               </div>
                                
                              </div>

                              <div className="col-sm-12" style={{marginTop:'10px', textAlign:'center' }}>
                              <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" onClick={() => {this.addNewReason()}} >Save</button>  
                             <button type="button" className="btn btn-primary btn-xs " data-dismiss="modal" onClick={() => {this.changeNote()}} >Cancel</button>  
                            </div>

                            </div>
                          </div>  
                        :''
                       } 
                       <div className="col-sm-7 pl-0">  
                          <label className="col-sm-12 col-form-label text-left" style={{lineHeight: '.5', marginBottom: '10px', fontSize: '162x', marginTop:'10px'}}>Reminder Reason:</label>
                        </div>
                        <div  className="col-sm-5" style={{textAlign:'right'}}>
                        {!this.state.addnew ?
                          <button type="button" className="btn  btn-primary m-1" style={{padding:'2px 12px', marginBottom: '25px', fontSize:'12px',width:'',height:''}} data-dismiss="modal" onClick={() => {this.setState({addnew:true})}} >Add New Reason</button>  
                       :''  
                        }
                        </div>
                        <div className="col-sm-12 pr-0 mb-3">
                          <div className="card" style={{overflowY: 'scroll', height: '300px', border: '1px solid #ddd', fontSize:'16px'}}>
                            <div className="card-block">
                              <table className="calendar table table-bordered tablestyle" style={{float: 'left', overflow: 'scroll', width: '100%'}}>
                                <thead>
                                    <tr style={{backgroundColor: '#f4f4f4'}}>
                                    <th>Reason</th>
                                    <th>Default interval</th>
                                    <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.all_reminder_reasons !=undefined && this.state.all_reminder_reasons.map((item,i)=> (

                                        <tr onClick={(e) => this.selectedReason(e,item,i)}  className={this.setTrClass(i)}> 
                                        {this.state.editMode=='E_'+item.reason_id ? 
                                         <td style={{color: 'black'}}>
                                           <input type="text" value={this.state.reason} onChange={(e)=>{this.setState({reason:e.target.value})}}/></td>
                                          : <td>{item.reason}</td>}
                                        {this.state.editMode=='E_'+item.reason_id ? 
                                          <td  style={{color: 'black'}}>
                                            <input type="text" style={{width:'30px', border:'0px', padding:' 2px 5px', marginRight:'5px' }} value={this.state.default_interval} onChange={(e)=>{this.setState({default_interval:e.target.value})}}/>
                                          <select style={{padding:'1px 5px 1px'}} value={this.state.radiotype} onChange={(e)=>{this.setState({radiotype:e.target.value})}}>
                                            <option value="2">Month(s)</option>
                                            <option value="3">Year(s)</option>
                                            <option value="1">Week(s)</option>
                                          </select>
                                          </td>
                                       
                                        :  
                                          <td>{item.default_interval}
                                            { (item.doc_id!==null) ?
                                            <i className="fa fa-star" title="Reset" style={{marginRight: '5px', marginTop: '3px', float: 'right',color:'#ffb64d'}} aria-hidden="true" />
                                            : <i className="fa fa-star" title="Reset" style={{marginRight: '5px', marginTop: '3px', float: 'right'}} aria-hidden="true" />
                                          }
                                          </td>
                                        }
                                        {item.customid!=="0" && this.state.editMode!='E_'+item.reason_id ?
                                        <td><i class="fas fa-pen" onClick={()=>this.editRecord(item,i)}></i></td>:
                                        <td>
                                          {item.customid=="0" ?
                                         <i class="fas fa-ban" syle={{color:'red'}}></i>:<button type="button" className="btn btn-primary btn-xs" onClick={(e)=>this.updateRecord(item.reason_id)}>save</button> 
                                          }
                                           {item.customid==="0"?
                                         '':<button type="button" className="btn btn-primary btn-xs" onClick={(e)=>{this.setState({editMode:''})}}>cancel</button> 
                                          }

                                        </td>}
                                      </tr>
                                        

                                    ))}
                                  
                                </tbody>
                              </table>
                            </div>
                            </div>
                                  </div>
                        </div>
                        <div className="form-group row m-b-10"  className={this.state.setting_data=="1"? 'displayit':'displayoff'}>
                        <label className="col-sm-12 col-form-label text-left" style={{lineHeight: '.5', marginTop: '5px', marginBottom: '20px', fontSize: '12px'}}>Other Reason</label>
                        <div className="col-sm-12">
                            <input type="text" className="form-control form-control-sm" style={{padding: '5px 10px'}} onChange={(e) => this.setState({otherreason: e.target.value })}/>
                        </div>
                        </div>
                    </div>
                    <div className="col-sm-6 m-b-0 mr-0 ml-0 pl-0 pr-0" style={{marginTop:'15px'}}>
                        <div className="col-sm-12">
                        <label className="col-sm-2 pt-2 col-form-label text-left pl-0 pr-0" style={{lineHeight: '.5', marginTop: '8px !important', marginBottom: '5px', fontSize: '12px'}}>
                       Remind in  </label>
                        <div className="col-sm-5">
                            <input type="text" className="form-control form-control-sm" style={{padding: '5px 10px', marginTop:'0px'}}  value={this.state.attemptin} onChange={(e) =>{this.handleReminderInput(e.target.value) }}/>
                            <span className="error" style={{color:'red'}}>{this.state.errors["attm"]}</span>   
            
                        </div>
                        <div className="col-sm-5 mt-2 pl-0">
                            <label onClick={(e)=>{this.handleReminderRadio(1,e)}} style={{fontSize: '12px'}}>
                                <input type="radio" clasadios="form-control form-control-sm"  checked={this.state.selectedReminderRadionId==1 ?'checked':''} style={{marginLeft: '10px'}} name="reminderradio"   />&nbsp;Weeks<br />
                            </label>
                            <label onClick={(e)=>{this.handleReminderRadio(2,e)}} style={{fontSize: '12px'}}>
                                <input type="radio" clasadios="form-control form-control-sm" checked={this.state.selectedReminderRadionId==2 ?'checked':''} style={{marginLeft: '10px', fontSize: '12px'}} name="reminderradio"   />&nbsp;Months<br />
                            </label>
                            <label onClick={(e)=>{this.handleReminderRadio(3,e)}} style={{fontSize: '12px'}}>
                                <input type="radio" clasadios="form-control form-control-sm" checked={this.state.selectedReminderRadionId==3 ?'checked':''} style={{marginLeft: '10px', fontSize: '12px', marginBottom:'10px'}} name="reminderradio" />&nbsp;Years<br />
                            </label>
                        </div>
                        </div>
                    <div className="col-sm-12" >
                    <div className="col-sm-5"></div>
                    <div className="col-sm-5" style={{marginLeft:'-115px'}}> 
                    {/*<i class="fa fa-calendar" style={{fontSize: '18px',  margin: '5px 0 0 5px'}}> </i> */}
                                
                                    <DatePicker style={{ widt:'130px'}}
                                        className="datePicker"
                                     //   value= {this.state.StartDate}
                                        selected={this.state.startDate}
                                        onChange={this.handleDate}
                                        dateFormat="dd/MM/yyyy"
                                        id="remindercalendar"
                                    />       
                                    <label
                                      className="label-input100"
                                      style={{
                                        marginLeft: "0px",
                                        marginBottom: "0px",
                                        fontSize: "12px",
                                        paddingBottom: "0px",
                                        paddingTop: "0px",
                                        marginTop: "-2px",
                                        right: "-10px",
                                        position: "absolute"
                                      }}
                                    >
                                    <a onClick={this.focusTextInput} href="#">
                                      {<img src={cicon} />}
                                    </a>
                                </label>     
                                
                     </div> </div>
                        <div className="col-sm-12" style={{paddingRight: '0px', marginTop:'20px', fontSize: '12px'}}>
                          <label className="col-sm-12 col-form-label text-left" ><input type="checkbox" value={this.state.ischecked} style={{padding: '5px 10px', fontSize: '12px'}} onClick={(e)=> this.updateReminder(e)}/> Automatically update this reminder when sent to patient</label>
                        </div>
                        <div className="col-sm-12 mt-3">
                            <label className="col-sm-3 col-form-label text-left pr-0" style={{lineHeight: '1', fontWeight:'normal', marginTop: '5px', marginBottom: '5px', fontSize: '12px'}}>Update interval</label>
                             <div className="col-sm-4">
                                <input type="text" className="form-control form-control-sm mt-0" defaultValue={1} style={{padding: '5px 10px'}} onChange={(e) => this.setState({reattempt: e.target.value })}  disabled={(this.state.disable)? "disabled" : ""} value={this.state.reattempt}/>
                                <span className="error" style={{color:'red'}}>{this.state.errors["attm1"]}</span>   
                      
                            </div>
                            <div className="col-sm-5">
                                <label style={{fontSize: '12px'}} onClick={()=>{this.handleReminderRadio1(1)}}  disable={this.state.disable}>
                                    <input type="radio" clasadios="form-control form-control-sm"  value={this.state.reattemptSelected} style={{marginLeft: '10px'}} checked={this.state.reattemptSelected==1 ?'checked':''} name="reminderradio1"  disabled={(this.state.disable)? "disabled" : ""}/>&nbsp;Weeks<br />
                                </label>
                                <label style={{fontSize: '12px'}} onClick={()=>{this.handleReminderRadio1(2)}}  disable={this.state.disable}>
                                    <input type="radio" clasadios="form-control form-control-sm" value={ this.state.reattemptSelected} style={{marginLeft: '10px'}} name="reminderradio1"  checked={this.state.reattemptSelected==2 ?'checked':''} disabled={(this.state.disable)? "disabled" : ""}/>&nbsp;Months<br />
                                </label>
                                <label style={{fontSize: '12px'}} onClick={()=>{this.handleReminderRadio1(3)}}  disable={this.state.disable}>
                                    <input type="radio" clasadios="form-control form-control-sm" value={ this.state.reattemptSelected} style={{marginLeft: '10px', marginBottom:'10px'}} name="reminderradio1"  checked={this.state.reattemptSelected==3 ?'checked':''} disabled={(this.state.disable)? "disabled" : ""}/>&nbsp;Years<br />
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="col-sm-6"> 
                            <label for="confidential" style={{ display: "contents" }} onChange={e => this.handleCheckbox(e,'showdeleted')}>      
                              <input id="confidential" type="checkbox"  Checked={this.state.showdeleted === "0" ? true : false}/>{" "}
                                Show Delete
                            </label>
                          </div>
                          <div className="col-sm-6"> 
                            <label for="actionperf" style={{ display: "contents" }} onChange={e => this.handleCheckbox(e,'showperformed')}>      
                              <input id="actionperf" type="checkbox"  Checked={this.state.showperformed === "1" ? true : false}/>{" "}
                               Action Performed
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12">

                        <div className="commongridclass">
                                <CommonGrid
                                handleButton={this.handleButton}
                                crud={this.state.crudin}
                                key={this.state.key}
                              />
                          </div>
                        </div>
                    </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" onClick={() => {this.saveData();}} >Save</button>
                <button type="button" className="btn btn-primary btn-xs" onClick={() => this.deleteReminder('','delete','')} >Delete</button>
                <button type="button" className="btn btn-primary btn-xs" onClick={() => this.notRequiredReminder(1,'no longer required')} >No Longer Required</button>
                <button type="button" className="btn btn-primary btn-xs" onClick={() => this.notRequiredReminder(2,'performed')} >Performed</button>
                <button type="button" className="btn btn-primary btn-xs" onClick={() => {close();this.props.changeKey()}} data-dismiss="modal" >Cancel</button>
                           
                   </div>


        </div>
                 )}
                    </Popup> );

                  }
                    }

  const mapDispatchToProps = dispatch => {
       return {
                apireasonreminder: () => dispatch(api_getreminder_reason()),
                apiallreminders: data => dispatch(api_getallreminders(data)),
                apigetdoc: () =>dispatch(api_getdoc()),
                apipostreminder: (data) =>dispatch(api_postreminder(data)),
                apiupdatereason:data=>dispatch(api_update_reason(data)),
                apiDeleteReminder:data=>dispatch(api_delete_reminder(data)),
                addNewRecordApi:data=>dispatch(api_new_Reason(data)),
                apigetsettings:(data)=>dispatch(api_get_settings(data)),
                apiPerformed:(data)=>dispatch(api_perform_action(data)),
                apigetsettingsdef:(data)=>dispatch(api_get_settingsdef(data)),
                
               };
     };
     const mapStateToProps = (state,ownProps) => {
      console.log(">>> doc data",ownProps)
      if(ownProps.patient=='')
      {
        console.log(">>> doc data---------------------------")
        let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
        let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
        return {
          redux_patientId,redux_docId
        };
      }
      else
      {
        let redux_patientId=ownProps.patient
        return {
          redux_patientId
        };
      }

     
    };
     export default connect(
        mapStateToProps,
      mapDispatchToProps
    )(Reminders);

 //export default reminder;
