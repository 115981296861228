import axios from 'axios';
export const api_add_template=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=templates&action=add_template`, data);
}
export const api_get_template=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=templates&action=get_template`, data);
}

export const api_update_template=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=templates&action=update_template`, data);
}
