import React, { Component } from "react";
import BgridServer from "./grid/bgridServer";


class CommonGrid extends Component {
  constructor(props) {
    super(props);
    this.handleButton = this.handleButton.bind(this);
    this.showeditform = this.showeditform.bind(this);
    this.backtogrid = this.backtogrid.bind(this);

  }
  state = {
    grid: true,
    edit: false,
    select: false,

    formData : {}
  };

  backtogrid = () => {
    this.setState({
      edit: false,
      select: false,
      grid : true,
    });
  }

  showeditform = (formData) => {
    this.setState({
      edit: true,
      grid : false,
      formData : formData
    });
  }

  handleButton = (selectedId, todo) => {
    //this.props.handleButton(selectedId, todo);
    alert(1);
  };

  onItemClick = () => {
    this.setState({
      grid: !this.state.grid
    });
  };

  onEditClick = () => {
  
    this.setState({
      edit: false,
      grid: true
    });
     
  };

  
  
  render() {
    const { grid, edit } = this.state;
    //const {grid} = this.state;
    return (
      <div>
       
          <BgridServer
            handleButton={this.handleButton}
            viewgrid={this.props.crud.viewgrid}
            
          />
      </div>

     

      
    );
  }
  
}

export default CommonGrid;
 