import axios from 'axios';
//Add careplan goal
export const api_add_care_goal = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=carePlan&action=add_care_goal`, data);
}
//Update careplan goal
export const api_update_care_goal = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=carePlan&action=update_care_goal`, data);
}
//Add careplan task
export const api_add_care_task = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=carePlan&action=add_care_task`, data);
}
//Update careplan task
export const api_update_care_task = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=carePlan&action=update_care_task`, data);
}
//Add careplan task
export const api_add_condition = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=carePlan&action=add_condition`, data);
}
//Update careplan task
export const api_update_condition = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=carePlan&action=update_condition`, data);
}
export const api_restore_default = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=carePlan&action=restore_def`, data);
}


