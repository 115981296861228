import React, { Component } from "react";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import moment from "moment";
import "./myList.css";
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import DatePicker from "react-datepicker";
import SelectPatient from "./selectPatient";
import "react-datepicker/dist/react-datepicker.css";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import "./../../superadmin/managelists/listPage.css";
import {
  api_list_mytasks,
  api_delete_task,
  api_add_mytask,
  api_loadEditRecord
} from "../../../DS/DS.VisitnotesMyList";
import cicon from "./../../../assets/img/cicon.png";
import Showhelp from './../../showHelp';

class myList extends Component {
  constructor(props) {
    super(props);
    //  this.state.showGrid =props.showGrid;
    //console.log( props.doc_id);
    this.getAllId = this.getAllId.bind(this);
    this.handleMyTasksCrud = this.handleMyTasksCrud.bind(this);
    this.state = {
      SelectPatient: '',
      taskid: ""
,      key: 1,
      selectedRec: "",
      selectedRecLength: "",
      mytasks: [],
      date: new Date(),
      patient_id: this.props.patientId,
      //doctor_id: this.props.doctor_id,
      doctor_id: this.props.logged_info.content.details[0].doctor_id,
      selectedPatientId: this.props.selectedPatientDetails.patient_id,
      showGrid: props.showGrid,
      patient_name:
        this.props.selectedPatientDetails.Firstname != ""
          ? this.props.selectedPatientDetails.Firstname
          : "",
      patient_DOB:
        this.props.selectedPatientDetails.DOB != ""
          ? moment(this.props.selectedPatientDetails.DOB).format("DD/MM/YYYY")
          : "",
      Action: "",

      mylist_crud: {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.handleMyTasksCrud,
          isDateGroup: false,
          isYearSeparator: false,
          dateCol: "date",
          //cellClicked: this.handleInvestigationRowClick,
          cellClicked: "",
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          highlightprev:true,
          query: {
            tb: "my_tasks",
            cols: [
              "my_task_id",
              "action",
              "my_tasks.patient_id",
              "DATE_FORMAT(date,'%d-%m-%Y') as date",
              "doctor_id",
              //"my_tasks.createdAt as createdAt",
              "DATE_FORMAT(my_tasks.createdAt,'%d-%m-%Y') as createdAt",
              "DATE_FORMAT(patients.DOB,'%d-%m-%Y') as DOB",
             // "patients.DOB as DOB",
              "patients.Firstname as patient"
            ],
            where: [
              {
                type: "and",
                key: "my_tasks.patient_id",
                operator: "=",
                value: this.props.selectedPatientDetails.patient_id
              },
              {
                type: "and",
                key: "my_tasks.isDeleted",
                operator: "=",
                value: 0
              }
            ],
            joins: [
              {
                joinType: "inner",
                joinTable: "patients",
                joinCondition: "my_tasks.patient_id = patients.patient_id"
              }
            ],
            customCols: [],
            primary_key: "my_task_id"
          },
          viewtabletitle: "",
          columns: [
            { name: "patient", title: "Patient Name" },
            { name: "DOB", title: "DOB" },
            { name: "date", title: "Date" },
            { name: "action", title: "Action to be Taken" },
            { name: "createdAt", title: "Created At" }
          ],
          rows: [],
          sortinit: [{ columnName: "date", direction: "asc" }],
          pagesize: 10,
          getAllIds: true,
          getallId: this.getAllId
        }
      },
      mylist_crud_general: {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.handleMyTasksCrud,
          isDateGroup: false,
          isYearSeparator: false,
          dateCol: "date",
          //cellClicked: this.handleInvestigationRowClick,
          cellClicked: "",
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          highlightprev:true,
          query: {
            tb: "my_tasks",
            cols: [
              "my_task_id",
              "action",
              "my_tasks.patient_id",
              "DATE_FORMAT(date,'%d-%m-%Y') as date",
              "doctor_id",
              //"my_tasks.createdAt as createdAt",
              "DATE_FORMAT(my_tasks.createdAt,'%d-%m-%Y') as createdAt",
              "DATE_FORMAT(patients.DOB,'%d-%m-%Y') as DOB",
              //"patients.DOB as DOB",
              "patients.Firstname as patient"
            ],
            where: [
              
              {
                type: "and",
                key: "my_tasks.isDeleted",
                operator: "=",
                value: 0
              },
              {
                type: "and",
                key: "my_tasks.doctor_id",
                operator: "=",
                value: this.props.logged_info.content.details[0].doctor_id
              },
              {
                type: "and",
                key: "my_tasks.isDeleted",
                operator: "=",
                value: 0
              }
            ],
            joins: [
              {
                joinType: "left",
                joinTable: "patients",
                joinCondition: "my_tasks.patient_id = patients.patient_id"
              }
            ],
            customCols: [],
            primary_key: "my_task_id"
          },
          viewtabletitle: "",
          columns: [
            { name: "patient", title: "Patient Name" },
            { name: "DOB", title: "DOB" },
            { name: "date", title: "Date" },
            { name: "action", title: "Action to be Taken" },
            { name: "createdAt", title: "Created At" }
          ],
          rows: [],
          sortinit: [{ columnName: "date", direction: "asc" }],
          pagesize: 10,
          getAllIds: true,
          getallId: this.getAllId
        }
      }
    };
  }
  cancelEdit = (e) => {
    this.setState({
      taskid: "",
      Action: "",
      date: new Date()  
    })
  }
  handleDate = date => {

    if (new Date() < new Date(date)) {
    } else {
      alert("Task date must be today or future date.");
      date = new Date();
    }

    this.setState({ date: date });



  };

  
  focusTextInput_g() {
    document.getElementById("date-jumper_g").focus();
  }


  edit = () => {
    console.log("edit");
  };
  delete = () => {
    console.log("delete");

  };

  focusTextInput_h() {
    document.getElementById("date-jumper_h").focus();
  }

  componentWillMount() {
    this.props
      .list_mytasks({
        doctor_id: this.state.doctor_id,
        patient_id: this.props.selectedPatientDetails.patient_id,
        type: this.props.listType
      })
      .then(res => {
        this.setState({ mytasks: res.data.content, Action: "" });
      });
  }

  getAllId = ids => {
    console.log(ids);
  };
  addTask() {
    const values = {
      id: this.state.taskid,
      action: this.state.Action,
      patient_id: this.state.selectedPatientId,
      date: this.state.date,
      doctor_id: this.props.logged_info.content.details[0].doctor_id,
      createdAt: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
      isDeleted: 0
    };
    console.log(this.state.mytasks);
    if (values["action"] != "") {
      this.props.add_mytasks({ values }).then(()=> {
        this.changeKey();
      });
      this.props.list_mytasks({
        doctor_id: this.props.doc_id, patient_id: this.props.selectedPatientDetails.patient_id,
        type: this.props.listType
      }).then(res => {
        this.setState({ mytasks: res.data.content });
        NotificationManager.success("Tasks Updated Successfully!");
        
      });
    } else {  
      alert("Action cannot be empty!");
    }
    
  }

  getPatient = (patient, i) => {
    //alert(i);

    this.setState({
      selectedPatientId: patient.patient_id,
      patient_DOB: patient.DOB,
      patient_name: patient.Firstname,
    });

  } 
  resetForm() {
    this.setState({ Action: "", taskid: "" });
    if(this.state.showGrid!=1){
      this.setState({ patient_DOB: "", patient_name: "" });
    }
  }
  deleteTask = (e, id) => {
    //e.preventDefault();
 
    
    confirmAlert({
      title: '',
      message: 'You are about to delete task(s). Do you want to proceed?',
      buttons: [
      {
          label: 'Yes',
          onClick: () => {
            this.props.deleteTaskItem({ id: id }).then(
          res => this.changeKey() ,
          NotificationManager.success(
          'Task(s) Deleted.',
          //this.changeKey()
          )
        );
      },
  },
  {
    label: 'No',
    onClick: () => { this.changeKey()},
  },
],
});
   
  /*  this.props.list_mytasks({
      doctor_id: this.props.doc_id, patient_id: this.props.selectedPatientDetails.patient_id,
      type: this.props.listType
    }).then(res => {
      this.setState({ mytasks: res.data.content });
    });*/
  };
  updateTask = (e, item) => {
    e.preventDefault();
   // alert(item.Firstname);
    this.setState({
      //patient_name:item.Firstname != "0"? item.Firstname != ""? item.Firstname: "": "",
      patient_name:item.Firstname != ""? item.Firstname : "",
      patient_DOB: item.DOB != "" ? moment(item.DOB).format("DD/MM/YYYY") : "-",
      Action: item.action
    });
    this.props.list_mytasks({
      doctor_id: this.props.doc_id, patient_id: this.props.selectedPatientDetails.patient_id,
      type: this.props.listType
    }).then(res => {
      this.setState({ mytasks: res.data.content });
    });
  };

  loadEdit = (id) => {
      this.props.loadEditRecord({id:id}).then((res)=> {
        this.setState({
          Action: res.data.content[0].action, 
          date: new Date(res.data.content[0].date), 
          taskid: res.data.content[0].my_task_id,
          selectedPatientId: res.data.content[0].patient_id,
          patient_name: res.data.content[0].Firstname,
          patient_DOB: res.data.content[0].DOB,


        })
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPatientDetails !== this.props.selectedPatientDetails) {
      this.setState({ logged_info: nextProps.logged_info });
    }
  }
  handleMyTasksCrud = (selectedId, action, checklength) => {
    console.log(checklength);
    if (action == "edit") {
      if (this.state.selectedRecLength != 1) {
        alert("Select any one record to perform Edit");
      } else {
       // alert("performmign gediting ");
        this.loadEdit(this.state.selectedRec);
      }
    }
    if (action == "delete") {
      //console.log
      if (this.state.selectedRecLength > 0) {
        console.log("length more than 0 for delete");
        this.deleteTask(null, this.state.selectedRec);
        // confirmAlert({
        //   title: "Delete record",
        //   message: "Do you want to delete the record?",
        //   buttons: [
        //     {
        //       label: "Yes",
        //       onClick: () => {
        //         alert(selectedId);
        //         console.log(this.state);
        //         this.deleteTask(1, this.state.selectedRec);
        //         this.changeKey();
        //       }
        //     },
        //     {
        //       label: "No",
        //       onClick: () => {
        //         //this.changeKey();
        //       }
        //     }
        //   ]
        // });
      } else {
        //NotificationManager.error("Please select a record to delete");
        alert("Please select any record to perform this action");
      }
    }
    if (action == "selection") {
      // this.setState({selectedRec: selectedId});
      // this.setState({selectedRecLength: checklength});
      this.state.selectedRec = selectedId;
      this.state.selectedRecLength = checklength;
    }
  };

  changeKey = () => {
    this.setState({ key: Math.random() });
  };

  render() {
    var gridstyle = {
      padding: "10px"
    };
    if (this.state.showGrid == 1) {
      /* For Link use showGrid=1 while calling */
      return (
        <Popup
        
          trigger={
            <button style={{ paddingBottom: "10px" }}>
              <u style={{ textDecoration: "none", fontSize: "14px" }}>
                Add To My List
              </u>{" "}
            </button>
          }
          position="right center"
          modal
          closeOnDocumentClick={false}
        >
          {close => (
            <div style={{ width:'1000px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>
              <div className="modal-header">
                <div class="popup-title">
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => {this.resetForm(); close();}}
                    // style={{ marginTop: "10px" }}
                    style={{ position: 'absolute', right: '10px', fontSize: '25px' }}
                    aria-label="Close"
                  >
                    ×
                  </button>
                  <span>
                    My List <Showhelp gotohelp="mylist_values"/>
                  </span>
                </div>
              </div>
              <div className="modal-body mylistbody">
                <div style={{ float: "left", width: "100%", height: "auto" }}>
                   <div className="row">
                   <div className="col-md-5">
                    <button
                    style={{margin:'5px'}}
                      type="button"
                      className="btn btn-primary btn-xs"
                      onClick={() => {
                        this.handleMyTasksCrud('', 'edit', '');
                      }}
                    >
                      {" "}
                      Edit{" "}
                    </button>
                    <button
                    style={{margin:'5px'}}
                      type="button"
                      className="btn btn-primary btn-xs"
                      data-dismiss="modal"
                      onClick={() => {
                       this.handleMyTasksCrud('', 'delete', '');
                      }}
                    >
                      {" "}
                      Delete{" "}
                    </button>
                  </div> 
                  <div className="col-md-7"></div>
                  </div> 
                  <div className="mylist">
                   <CommonGrid
                    style={gridstyle}
                    key={this.state.key}
                    handleButton={this.handleButton}
                    crud={this.state.mylist_crud}
                  /> 
                  </div>  
                </div>
                <div
                  id="addtodolistopen"
                  className="col-sm-12"
                  style={{
                    marginTop: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginBottom: 10,
                    textAlign: "left"
                  }}
                >
                  <span>
                    <h5
                      style={{
                        backgroundColor: "#ddd",
                        width: "100%",
                        float: "left",
                        fontSize: "16px",
                        padding: "5px 10px"
                      }}
                    >






                   {this.state.taskid==""?'Add My List':'Edit My List'}   



                    </h5>
                    {this.state.taskid!=""?
                    <button type="button" className="close"  data-dismiss="modal" onClick={(e) => this.cancelEdit(e)} style={{ float: 'right', marginTop: "10px" }} aria-label="Close" >
                    ×
                  </button>:''}
                  </span>
                  <div className=" row">
                    <div className="col-sm-12" style={{ paddingLeft: 0 }}>
                      <div className=" row">
                        <div className="col-sm-2">
                          <label
                            className="col-form-label text-left"
                            style={{ marginTop: "12px" }}
                          >
                            Patient:
                          </label>
                        </div>
                        <div className="col-sm-3">
                          <input
                            style={{
                              border: "none",
                              borderBottom: "1px solid #cccccc",
                              marginTop: 0,
                              marginBottom: 0
                            }}
                            type="text"
                            value={this.state.patient_name}
                            name="search_condition"
                            className="form-control form-control-sm rq-form-elemen"
                            onChange={e =>
                              this.setState({ Patient_name: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className=" row">
                        <div className="col-sm-2">
                          <label
                            className="col-form-label text-left"
                            style={{ marginTop: "12px" }}
                          >
                            DOB:
                          </label>
                        </div>
                        <div className="col-sm-3">
                          <input
                            style={{
                              border: "none",
                              borderBottom: "1px solid #cccccc",
                              marginTop: 0,
                              marginBottom: 0
                            }}
                            type="text"
                            value={this.state.patient_DOB}
                            name="search_condition"
                            className="form-control form-control-sm rq-form-elemen"
                            onChange={e =>
                              this.setState({ DOB: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-2">
                          <label
                            className="col-form-label text-left"
                            style={{ marginTop: "12px" }}
                          >
                            Date:
                          </label>
                        </div>
                        <div className="col-sm-3">
                          <DatePicker
                            //ref={this.textInput}
                            ref={input => {
                              this.textInput = input;
                            }}
                            id="date-jumper_g"
                            inputRef={ref => {
                              this.textInput = ref;
                            }}
                            todayButton="Go to Today"
                            selected={this.state.date}
                            onChange={this.handleDate}
                            showMonthDropdown
                            useShortMonthInDropdown
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            dateFormat="dd/MM/yyyy"
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                          />
                           <label
                      className="label-input100"
                      style={{
                        marginRight: "0px",
                        marginBottom: "0px",
                        fontSize: "12px",
                        paddingBottom: "0px",
                        paddingTop: "0px"
                      }}
                    >
                      <a onClick={this.focusTextInput_g} href="#">
                        {<img src={cicon} />}
                      </a>
                    </label>
                          
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "5px" }}>
                        <div className="col-sm-2">
                          <label
                            className="col-form-label text-left"
                            style={{ marginTop: "12px" }}
                          >
                            Action to be taken
                          </label>
                        </div>
                        <div className="col-sm-6">
                          <textarea
                            style={{
                              width: "100%",
                              height: "60px",
                              border: "1px solid #cccccc",
                              marginTop: 0,
                              marginBottom: 0
                            }}
                            id="accute"
                            placeholder
                            value={this.state.Action}
                            onChange={e =>
                              this.setState({ Action: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer" style={{ float:'left', width:'100%'}}>
                <button
                  type="button"
                  className="btn btn-primary btn-xs"
                  onClick={() => {
                    this.addTask();
                    this.resetForm();
                  }}
                >
                  {" "}
                  Save{" "}
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-xs"
                  data-dismiss="modal"
                  onClick={() => {
                    this.addTask();
                    this.resetForm();
                    close();
                  }}
                >
                  {" "}
                  Save & Close{" "}
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={() => {this.resetForm(); close();}}
                >
                  {" "}
                  Cancel{" "}
                </button>
              </div>
            </div>
          )}
        </Popup>
      );
    } else if (this.state.showGrid == 0) {
      /* For Icon use showGrid=0 while calling */
      return (
        <Popup trigger={ <i className="fa fa-tasks fa-3x" style={{ fontSize: '2.5em', marginTop:'3px' }} />}
          position="right center" modal closeOnDocumentClick={false}>
          {close => (
            <div style={{ width:'1000px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>
              <SelectPatient open={this.state.SelectPatient} closeSelectPatient={ e => this.setState({SelectPatient: false})} getPatient={this.getPatient}/>
              <div className="modal-header">
              <div className="popup-title">
              <button type="button" className="close"  data-dismiss="modal" onClick={() => {this.resetForm(); close();}}  aria-label="Close" >
                    ×
                  </button>
                <span>My List</span>
              </div>
              </div>
              <div className="modal-body past-history-wrapper" style={{border:'0px'}}>
              <div className="row" style={{ paddingLeft:'30px', paddingRight:'30px'}}>
              <div className="col-md-12 pl-0">
                   <div className="col-md-5 pl-0">
                    <button
                    style={{margin:'5px'}}
                      type="button"
                      className="btn btn-primary btn-xs"
                      onClick={() => {
                        this.handleMyTasksCrud('', 'edit', '');
                      }}
                    >
                      {" "}
                      Edit{" "}
                    </button>
                    <button
                    style={{margin:'5px'}}
                      type="button"
                      className="btn btn-primary btn-xs"
                      data-dismiss="modal"
                      onClick={() => {
                       this.handleMyTasksCrud('', 'delete', '');
                      }}
                    >
                      {" "}
                      Delete{" "}
                    </button>
                  </div> 
                  <div className="col-md-7"></div>
                  </div> 
                {/* <div style={{ float: "left", width: "100%", height: "300px" }}> */}
                <div className="mylist" style={{float:'left', width:'100%'}}>
                   <CommonGrid
                    style={gridstyle}
                    key={this.state.key}
                    handleButton={this.handleButton}
                    crud={this.state.mylist_crud_general}
                  /> 
                  </div>  
                {/* </div> */}
                <div id="addtodolistopen"  className="col-sm-12 pl-0"  style={{marginTop: 0,paddingLeft: 0,paddingRight: 0, marginBottom: 10, textAlign: "left" }}>
                  <span>
                    <h5  style={{backgroundColor: "#f4f4f4",width: "98%",float: "left",fontSize: 16, margin: "10px 0px 0px 0px", padding:'10px'}}>
                      Add My List
                    </h5>
                  </span>
                  <div className=" row">
                    <div className="col-sm-12" style={{ paddingLeft: '15px', paddingRight: '15px', }}>
                      <div className=" row" style={{marginTop:'10px'}}>
                        <div className="col-sm-2">
                          <label className="col-form-label text-left" style={{ marginTop: "12px" }} onClick={e=> this.setState({SelectPatient:!this.state.SelectPatient})}>
                            Patient/DOB:
                          </label>
                        </div>
                        <div className="col-sm-3 pr-1">
                          <input onClick={e=> this.setState({SelectPatient:!this.state.SelectPatient})} readOnly={true} style={{ border: "none", padding: '5px 10px', borderBottom: "1px solid #cccccc",marginTop: 0,marginBottom: 0}} type="text" name="search_condition"   className="form-control form-control-sm rq-form-elemen" value={(this.state.patient_name!=undefined&&this.state.patient_name!="")?this.state.patient_name+'/'+moment(this.state.patient_DOB).format('DD-MM-YYYY'):''} 
                          onChange={(e)=>this.setState({patient_name:e.target.value})}/>
                          </div>
                          <div className="col-sm-3 pl-0">
                          <button className="btn btn-primary btn-xs" onClick={e=> this.setState({SelectPatient:!this.state.SelectPatient})}> Select Patient </button>
                        </div>
                       {/*} <div className="col-sm-3">
                          <input
                            style={{
                              border: "none",
                              marginTop: 0,
                              marginBottom: 0
                            }}
                            type="text"
                            name="search_condition"
                            className="form-control form-control-sm rq-form-elemen"
                          />
                          </div>*/}
                      </div>
                      <div className="row mt-3">
                        <div className="col-sm-2">
                          <label  className="col-form-label text-left" style={{ marginTop: "12px" }}>
                            Date:
                          </label>
                        </div>
                        <div className="col-sm-3">
                          <DatePicker
                            //ref={this.textInput}
                            ref={input => {
                              this.textInput = input;
                            }}
                            id="date-jumper_h"
                            inputRef={ref => {
                              this.textInput = ref;
                            }}
                            todayButton="Go to Today"
                            selected={this.state.date}
                            onChange={this.handleDate}
                            showMonthDropdown
                            useShortMonthInDropdown
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            dateFormat="dd/MM/yyyy"
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                          />

<label
                      className="label-input100"
                      style={{
                        marginRight: "0px",
                        marginBottom: "0px",
                        fontSize: "12px",
                        paddingBottom: "0px",
                        paddingTop: "0px",
                        position: "absolute",
                        top: "4px",
                        right: "-10px"
                      }}
                    >
                      <a onClick={this.focusTextInput_h} href="#">
                        {<img src={cicon} />}
                      </a>
                    </label>
                          {/*<input
                            style={{
                              border: "none",
                              borderBottom: "1px solid #cccccc",
                              marginTop: 0,
                              marginBottom: 0
                            }}
                            type="text"
                            value={moment().format("DD/MM/YYYY")}
                            name="search_condition"
                            className="form-control form-control-sm rq-form-elemen"
                          />*/}
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "5px" }}>
                        <div className="col-sm-2">
                          <label className="col-form-label text-left" style={{ marginTop: "12px" }}>
                            Action to be taken
                          </label>
                        </div>
                        <div className="col-sm-6">
                          <textarea  style={{ width: "100%", height: "60px",border: "1px solid #cccccc",marginTop: 0,marginBottom: 0 }}  id="accute" placeholder
                            value={this.state.Action}  onChange={e =>this.setState({ Action: e.target.value }) }/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer" style={{ float:'left', width:'100%'}}>
                <button   type="button" className="btn btn-primary btn-xs" onClick={() => {this.addTask(); this.resetForm();}}>
                  Save
                </button>
                <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" onClick={() => {this.addTask();  this.resetForm();close();}}>
                  Save & Close
                </button>
                <button  type="button" className="btn btn-primary btn-xs"  data-dismiss="modal" onClick={() => {this.resetForm(); close();}}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </Popup>
      );
    }
  }
}

const mapStateToProps = state => {
  // console.log(state);
  let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : [];
  let selectedPatientDetails;
  console.log(">>>>>>", state.getDrAppointments.getPatientDetails);
  let logged_info =
  state.getDrAppointments.logged_info != undefined
    ? state.getDrAppointments.logged_info
    : "";   
  if (patientDetails.length > 0) {
    selectedPatientDetails = patientDetails[0];
  } else {
    selectedPatientDetails = "";
  }
  return {
    selectedPatientDetails, logged_info
  };
};

const mapDispatchToProps = dispatch => {
  return {
    list_mytasks: data => dispatch(api_list_mytasks(data)),
    deleteTaskItem: data => dispatch(api_delete_task(data)),
    add_mytasks: data => dispatch(api_add_mytask(data)),
    loadEditRecord: data => dispatch(api_loadEditRecord(data)),
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(myList);
