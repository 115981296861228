import axios from 'axios';
export const api_get_patients=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=patients&action=get_patients`, data);
}
export const api_delete_patient=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=patients&action=delete_patient`, data);
}

export const api_get_account_holder=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=patients&action=get_account_holder`, data);
}

export const api_delete_holder=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=patients&action=delete_account_holder`, data);
}
export const api_total_notes=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=patients&action=total_notes`, data);
}

export const api_getAccount=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=patients&action=view_account_holder`, data);
}
export const api_update_account_holder=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=patients&action=update_account_holder`, data);
}

export const api_save_account_holder=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=patients&action=save_account_holder`, data);
}

