import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import AddNotes from './AddNotes';

class Feeammount extends Component {
    state = {  }
    render() { 
        return ( 
            <Popup trigger={ <a title="Account" style={{width: '100%',textAlign:'left'}}  className="collapsed btn btn-default" aria-expanded="false">Surgery consultation. Level A,</a>} position="right center" modal
            closeOnDocumentClick={false}>
             {close => (
            <div>
                <div className="modal-content" style={{width: '100%'}}>
  <div className="modal-header" style={{width: '100%', backgroundColor: '#f4f4f4', padding: '5px 15px'}}>
    <h5 style={{backgroundColor: '#f4f4f4', float: 'left', width: '100%', padding: '5px 10px', fontSize: '16px', marginBottom: '0px'}}>Fee Amount</h5>
    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => close()} >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div className="modal-body">
    <div className="col-sm-12">
      <div className="col-sm-3"><label className="col-form-label text-left" style={{paddingRight: '0px'}}>Notes from provider:</label></div>
      <div className="col-sm-9">
        <textarea style={{width: '100%', height: '60px', border: '1px solid #cccccc'}} id="accute" placeholder value="Surgery consultation. Level A, Left Ear" defaultValue={""} />
      </div>
    </div>
    <div className="col-sm-12">
      <div className="col-sm-3"><label className="col-form-label text-left" style={{paddingRight: '0px'}}>MBS Item No:</label></div>
      <div className="col-sm-9">
        <label className="col-form-label text-left" style={{paddingRight: '0px'}}>3</label>
      </div>
    </div>
    <div className="col-sm-12">
      <div className="col-sm-3"><label className="col-form-label text-left" style={{paddingRight: '0px'}}>Fee schedules:</label></div>
      <div className="col-sm-9">
        <table className="calendar table table-bordered tablestyle" style={{width: '100%', marginBottom: '0px'}}>
          <thead>
            <tr style={{backgroundColor: '#f4f4f4'}}>
              <th style={{textAlign: 'left'}}> Fee</th>
              <th style={{textAlign: 'left'}}> Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>MBS Schedule</td>
              <td>17.20</td>
            </tr>
            <tr>
              <td>MBS Rebate</td>
              <td>17.20</td>
            </tr>
            <tr>
              <td>Discount</td>
              <td>15.00</td>
            </tr>
            <tr>
              <td>Practice fee</td>
              <td>35.00</td>
            </tr>
            <tr>
              <td>WorkCover</td>
              <td>17.20</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div className="col-sm-12">
      <div className="col-sm-10 text-right"><label className="col-form-label text-right" style={{paddingRight: '0px'}}>No.of patient seen:</label></div>
      <div className="col-sm-2">
        <input type="tedt" name="search_condition" className="form-control form-control-sm rq-form-elemen" defaultValue={1} />
      </div>
    </div>
    <div className="col-sm-12">
      <div className="col-sm-3"><label className="col-form-label text-right" style={{paddingRight: '0px'}}>Fee to change:</label></div>
      <div className="col-sm-2">
        <input type="tedt" name="search_condition" className="form-control form-control-sm rq-form-elemen" defaultValue="17.20" />
      </div>
    </div>
    <div className="col-sm-12">
      <div className="col-sm-3"><label className="col-form-label text-right" style={{paddingRight: '0px'}}>Service details:</label></div>
      <div className="col-sm-8">
        <input type="tedt" name="search_condition" className="form-control form-control-sm rq-form-elemen" defaultValue="Left Ear" />
      </div>
      <div className="col-sm-1">
       <AddNotes/>
      </div>
    </div>
    <div className="col-sm-12">
      <div className="col-sm-3"><label className="col-form-label text-right" style={{paddingRight: '0px'}}>Restriction:</label></div>
      <div className="col-sm-5">
        <select className="form-control">
          <option>Separate Sites</option>
          <option>Not Related</option>
          <option>No.of comparison</option>
        </select>
      </div>
      <div className="col-sm-4">
        <div style={{width: '250px'}}><input type="checkbox" name="fasting" className="form-control form-control-sm" style={{float: 'left', width: '6px',height: '6px', marginRight: '3px',padding:'6px'}} />Self</div>
      </div>
    </div>
  </div>
  <div className="modal-footer" style={{marginTop: '400px'}}>
    <button type="button" className="btn btn-primary" data-dismiss="modal">Ok</button>
    <button type="button" className="btn btn-default" data-dismiss="modal"
     onClick={()=>close()}>Cancel</button>
  </div>
</div>

            </div>
            
     )}
     </Popup>
         );
    }
}
 
export default Feeammount;