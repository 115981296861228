import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import {api_get_audit_c} from '../../../DS/DS.FamilyHistory';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import Showhelp from './../../showHelp';

class AuditAssessment extends Component {
    constructor(props){
        super(props);
        this.state = {
            open:this.props.showmodal,
            audit_data:[]
        }
    }
    componentWillMount=()=>
    {
        const audit={patient_id:this.props.redux_patientId}
        this.props.apiGetAuditC(audit).then(
            req=>this.setState({audit_data:req.data.content})
        )
    }
    render() {
        return ( 
        <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
         {close => (
             <div style={{ width:'1000px', marginLeft:'220px', backgroundColor:'#FFF', float:'left'}}>
                <div class="modal-header" style={{width:"100%", backgroundColor:"#f4f4f4", padding:"0px 15px"}}>
                        <h6 style={{float:'left', fontSize: '16px'}}> Audit-C Assessments <Showhelp gotohelp="audit_c_help"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()} style={{ marginTop:"5px", fontSize: '25px'}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    
                </div>
                <div class="modal-body" style={{border:'0px'}}>
                    <div class="row row-distance">
                    <div class="col-sm-12">
                        <div class="col-sm-6">
                            <label class="col-form-label text-left" style={{fontSize:'14px'}}>  Questions :</label>
                            <div style={{width:'100%', height:'450px', border:'1px solid #cccccc', padding:'15px' /*, overflow:'scroll'*/}}>
					            
                                <p> 1.How often do you have a drink containing alcohol? </p>
                                <p> 2.How many units of alcohol do you drink on a typical day when you are drinking? </p>
                                <p> 3.How often have you had 6 or more units if female, or 8 or more if male, on a single occasion in the last year ? </p>
					            <div class="row row-distance3"></div> 
				              </div> 	
			            </div>		
			            <div class="col-sm-6">
                            <label class="text-left" style={{fontSize:'14px'}}> Answers : </label>
			   	            <table className="answer-table" style={{width:'100%'}}>
                                <thead>
                                   

                                </thead>
                                <tbody>
                                <td style={{width:'30%'}}>
                                      <tr>Date</tr>
                                      <tr>Q.1</tr>
                                      <tr>Q.2</tr>
                                      <tr>Q.3</tr>
                                      <tr>Score</tr>
                                   </td>
                                {this.state.audit_data!='' && this.state.audit_data!=undefined && this.state.audit_data.map((foundData,i)=> 
                                    <td style={{width:'40%'}}>   
                                        <tr  onClick={e =>this.showSelectedContent(e, foundData, i)}>{foundData.created_at}  </tr>
                                        <tr  onClick={e =>this.showSelectedContent(e, foundData, i)}>{foundData.how_often_char}</tr>
                                        <tr  onClick={e =>this.showSelectedContent(e, foundData, i)}>{foundData.unit_alcohol_char}</tr>
                                        <tr  onClick={e =>this.showSelectedContent(e, foundData, i)}>{foundData.single_occasion_char}</tr>
                                      <tr  onClick={e =>this.showSelectedContent(e, foundData, i)}>{foundData.score} </tr>
                                    

                                    </td>
                                    )}
                                </tbody>
                            </table>	
                            <div class="row row-distance3"></div> 
                            <div class="row row-distance3"></div> 
                            <div class="row row-distance3"></div> 
                            <div class="row row-distance3"></div> 
                          
				        </div>	  	
                       
			        </div></div>
                </div>
		        <div class="modal-footer">
                    <button type="button" class="btn btn-primary btn-xs">Print</button>
                    <button type="button" class="btn btn-primary btn-xs" data-dismiss="modal" onClick={()=>this.props.changeKey()}>Cancel</button>
                </div>
            </div>
         )}
     </Popup>
    )
  }
} 
const mapDispatchToProps = dispatch => {
    return {
        apiGetAuditC: (data) => dispatch(api_get_audit_c(data)),
            };
  };
  const mapStateToProps = state => {
     let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
 
   return {
     redux_patientId,redux_docId
   };
 };
export default connect(mapStateToProps,mapDispatchToProps)(AuditAssessment);
