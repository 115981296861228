import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import { connect } from 'react-redux';
import {
    NotificationContainer,
    NotificationManager,
  } from 'react-notifications';
  import AdminHeader from "../../adminheader";
  import Leftsidemenu from "../../leftmenu";
  import Footer from "../../footer";
  import CommonGrid from "../../crudcomponentV2/combinedcrud";
  import { confirmAlert } from 'react-confirm-alert';
  import Addtemplates from './addTemplate';
  import {api_delete_selection} from '../../../DS/DS.ListPage';

class TemplateList extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            reminderReasonCrud: {
                jsonGridVersion: "v2.0",
                grid: true,
                viewgrid: {
                  isVisible:true,
                  isPagination: true,
                  isFiltering: true,
                  isSelection:true,
                  deleteFunction:this.removeTemplate,
                  isDateGroup:false,
                  isYearSeparator:false,
                  dateCol:'',
                  groupDateSelection:false,
                  cellClicked:'',
                  baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                  query: {
                    tb: "default_templates_tb",
                    cols: [
                      "template_id",
                      "template_type_text",
                      "template_name"
       
                    ],
                   where: [
                      {
                        type: "and",
                        key: "isDeleted",
                        operator: "=",
                        value: 0
                      }
                   ],
                   joins:[
                       
                   ],
                    primary_key:"template_id",
                  },
                  viewtabletitle: "",
                  columns: [
                    {name: "template_name", title: "Name"},
                    {name: "template_type_text", title: "Type"}
                  ],
                  rows: [],
                  sortinit: [{ columnName: "template_id", direction: "asc" }],
                  pagesize: 10,
                  getAllIds:false,
                  getallId:''
                }
              },
              reloadKey:0,
              showadd:false,
              selectedid:'',
              selectedLength:'',
              showEdit:false
        }
    }

    removeTemplate=(ids,action,totalLength)=>
    {
       if(action=='selection')
       {
         this.state.selectedid=ids;
         this.state.selectedLength=totalLength;
       }
       if(action=='delete')
       {
           if (this.state.selectedLength> 0) {
                 const ReminderPrint={tb_name:'default_templates_tb',ids:this.state.selectedid,primary_key:'template_id'}
                 confirmAlert({
                   title: '',
                   message: 'You are about to delete template. Do you want to proceed? ',
                   buttons: [
                   {
                       label: 'Yes',
                       onClick: () => {
                       this.props.apiDelete(ReminderPrint).then(
                       res => this.closeAll() ,
                       NotificationManager.success(
                       'Reminder page record deleted',
                       )
                     );
                   },
               },
               {
                 label: 'No',
                 onClick: () => { this.closeAll()},
               },
             ],
           });
         } 
         else 
         {
           NotificationManager.error('Please select record(s) to delete');
         }
       }
    }
    closeAll=()=>{
        this.setState({showadd:false,reloadKey:Math.random(),showEdit:false})
    }
    editTemplate=()=>
    {
        if(this.state.selectedLength ==1 )
        {
           this.setState({showEdit:true})
        }
        else
        {
            alert("Please select single record to modify")
        }
    }
render(){

    return(
        <div>
        <AdminHeader />
        <Leftsidemenu />
          <section className="content-header" style={{marginLeft: '240px'}}>
              <h1>
                  Template
              </h1>
              <ol className="breadcrumb">
                  <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                  <li className="active">Template</li>
              </ol>
          </section>
          {/* MAIN PAGE*/ }
          <section className="content" style={{marginLeft: '240px'}}>
            <div className="row">
                <div className="col-xs-12">
                    <div className="box">
		                <div className="col-xs-12" style={{margin:'0px auto', float:'none'}}>
                            <div className="box-body">
				                <div className="col-sm-12 col-md-10 col-xl-10">		
							        <div id="pageContent">
                                        <div className="form-group row m-b-10">
                                            <label className="col-sm-12 col-form-label text-left" style={{lineHeight:'7px'}}> <b>Supadoc Comms templates </b></label>
		                                    <label className="col-sm-12 col-form-label text-left" style={{lineHeight:'20 px'}}> Supadoc Comms templates used for sending SMS and best health app messages</label>
	                                        <div className="col-sm-12 col-md-6 col-xl-6 m-t-10">
		                                        <div className="card" style={{height:'150px', overFlowY:'scroll', border:'1px solid #ccc'}}>
	                                                <div className="card-block">
	                                                     <CommonGrid
                                                                    crud={this.state.reminderReasonCrud}
                                                                    key={this.state.reloadKey}
                                                                />          
	                                                </div>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-2 m-t-60">
                                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary" data-toggle="modal" data-target="#template-add-btn" style={{width:'100px', marginBottom:'5px', marginTop:'60px'}}
                                                onClick={()=>this.setState({showadd:true})}>Add</button>
                                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#template-edit-btn" style={{width:'100px', marginBottom:'5px'}}
                                                onClick={(e)=>this.editTemplate()}>Edit</button>
                                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', marginBottom:'5px'}} onClick={(e)=>this.removeTemplate('','delete','')}>Delete</button>
	                                        </div>
	                                    </div>
                                    </div>	
	        		            </div>
                            </div>
			               
                            </div>
                        </div>
                    </div>
                    {this.state.showadd ? 
                    <Addtemplates open={this.state.showadd} closeall={this.closeAll} ids=""/> :''}
                     {this.state.showEdit ?
                    <Addtemplates open={this.state.showEdit} popFor='Edit' closeall={this.closeAll} ids={this.state.selectedid}/> :''}
                                                                
                </div>
            </section>
        </div>   
    )

}
}

const mapDispatchToProps = dispatch => {
    return {
        apiDelete:data =>dispatch(api_delete_selection(data)),
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };   
export default  connect(mapStateToProps,mapDispatchToProps)(TemplateList);