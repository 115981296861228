import React, { Component } from "react";
import Popup from "reactjs-popup";
import Showhelp from "./../showHelp";
import moment from "moment";
import { connect } from "react-redux";
import {
  api_list_careplans,
  api_add_create_account_item,
  api_useLastaccount
} from "./../../DS/DS.VisitnotesFinalizeVisit";
import {
  api_getSummaryCareplan,
  api_summary_billing
} from "./../../DS/DS.VisitnotesDiagnosis";
import ServiceNotes from "./../crudcomponent/serviceNotes";
class UselastaccountPopup extends Component {
  state = {
    servicenotes: "",
    patients_careplan: [],
    patients_careplan_date: [],
    patients_careplan_items: [],
    patients_careplan_ids: [],
    selected_invoice: "",
    target_invoice: this.props.target_invoice,

    selectedInput: "",
    service_date: new Date(),
    careplanMaster: [],
    service_note: "",
    selected_list: [
      { description: "23, Surgery consultation, Level, B" },
      { deccription: "" },
      { deccription: "" },
      { deccription: "" },
      { deccription: "" }
    ],
    fee: [
      { name: "Practical Fee", amount: "65.00" },
      { name: "", amount: "" },
      { name: "", amount: "" },
      { name: "", amount: "" },
      { name: "", amount: "" }
    ],
    written_servicenotes: []
  };

  componentDidMount() {
    console.log("USE LAST ACCOUNT");
    this.props
      .api_summary_billing({ patient_id: this.props.patient_id })
      .then(res => {
        this.setState({ patients_billing: res.data.content });
        //this.setState({patients_careplan_items: res.data.content.items});
      })
      .then(() => console.log(this.state, "summary state"));

    this.props
      .getSummaryCareplan({ patient_id: this.props.patient_id })
      .then(res => {
        this.setState({ patients_careplan_date: res.data.content.date });
        this.setState({ patients_careplan_items: res.data.content.items });
        this.setState({ patients_careplan_ids: res.data.content.id });
      })
      .then(() => console.log(this.state, "summary state"));

    this.props
      .listCareplans()
      .then(res => this.setState({ careplanMaster: res.data.content }));
  }
  addItem = () => {
    // alert("add");
    this.props.addItem(this.state);
  };

  handleRadio = id => {
    this.setState({ selected_invoice: id });
  };
  addcpitem = (e, id) => {
    this.setState({ cpid: id });
  };

  useselected = () => {
    if (this.state.selected_invoice != "") {
      this.props
        .useLastaccount({
          source_id: this.state.selected_invoice,
          target_id: this.props.target_invoice
        })
        .then(() => {

            this.props.reload();
        });
    } else {
      alert("Select any one invoice to use");
    }
    console.log("selecting  invoice ");
  };
  openServiceNotes = (e, i) => {
    // alert(i);
    this.setState({ servicenotes: true, selectedInput: i });
  };

  closeServiceNotes = () => {
    this.setState({ servicenotes: false, selectedInput: "" });
  };

  getServiceNote = (servicenote, position) => {
    let written_servicenotes = this.state.written_servicenotes;
    let service_note_item = {
      position: position,
      servicenote: servicenote
    };
    let added = 0;
    for (let i = 0; i < written_servicenotes.length; i++) {
      if (written_servicenotes[i].position == position) {
        written_servicenotes[i].servicenote = servicenote;
        added = 1;
        break;
      }
    }
    if (added != 1) {
      written_servicenotes.push(service_note_item);
    }
    this.setState({ written_servicenotes: written_servicenotes });
  };

  getServiceNoteValue = position => {
    let written_servicenotes = this.state.written_servicenotes;
    let selectedNote = "";
    for (let i = 0; i < written_servicenotes.length; i++) {
      if (written_servicenotes[i].position == position) {
        selectedNote = written_servicenotes[i].servicenote;
        break;
      }
    }
    return selectedNote;
  };
  componentWillMount() {
 
  }
  render() {
    return (
      <Popup
        // trigger={
        open={this.props.open}
        close={() => this.props.close()}
        // }
        position="right center"
        modal
        closeOnDocumentClick={false}
      >
        <div style={{ width:'1000px', margin:'0px auto'}}>
        <div style={{ width:'1000px', marginLeft:'20px', backgroundColor:'#FFF', float:'left'}}>
          <div className="modal-header">
            <div class="popup-title" style={{color:'#ffffff'}}>
              <span> Use Last Account </span>
              <Showhelp gotohelp="diagnosis_popup" />
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
          <div className="modal-body visit-notes-wrapper newpostpopup" style={{border:'0px'}}>
            <div className="col-md-12">
              <p />

        <table class="calendar table table-bordered tablestyle"> 
        <thead>
        <tr style={{backgroundColor: 'rgb(244, 244, 244)'}}>
          <th>Select</th>
          <th>Date</th>
          <th>Invoice No</th>
          <th>Services</th>
          <th>Provider</th>
          <th>Payer</th>
          
          <th>Claim No</th>
          <th>Acc</th>
          </tr>
          </thead>
          <tbody style={{color:'#666666'}}>

              
                {this.state.patients_careplan_date != undefined &&
                this.state.patients_careplan_ids != undefined &&
                  this.state.patients_careplan_date.length > 0 &&
                  this.state.patients_careplan_date.map((item, i) => (


                    <tr>
                    <td>
                    <input
                          type="radio"
                          checked={
                            this.state.selected_invoice ==
                            this.state.patients_careplan_ids[i]
                          }
                          name="selected_invoice"
                          onClick={e =>
                            this.handleRadio(
                              this.state.patients_careplan_ids[i]
                            )
                          }
                        />
                      </td>
                      <td>
                       {moment(this.state.patients_careplan_date[i]).format(
                          "DD/MM/YYYY"
                        )}{" "} </td>
                    <td>   #{this.state.patients_careplan_ids[i]}</td>
                    <td> {this.state.patients_careplan_items[i] != undefined &&
                          this.state.patients_careplan_items[i].length > 0 &&
                          this.state.patients_careplan_items[i].join(",")} </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                  </tr>
                   
                  ))}
                  </tbody>
                  </table>
               
              <p />
            </div>
          </div>
          
          <div className="modal-footer">
            <div className="col-sm-12">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                aria-label="Close"
                onClick={e => {
                  this.useselected();
                  this.props.close();
                }} //this.props.closeServiceNotes()}
              >
              Re-Use
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={e => this.props.close()}
              >
                Cancel
              </button>
            
          </div>
          <ServiceNotes
            open={this.state.servicenotes && this.state.selectedInput != ""}
            type="Invoice"
            selectedInput={this.state.selectedInput}
            closeServiceNotes={e => this.closeServiceNotes()}
            getServiceNote={this.getServiceNote}
          />
        </div>
        </div>
        </div>
      </Popup>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    listCareplans: data => dispatch(api_list_careplans(data)),
    addCreateAccountItem: data => dispatch(api_add_create_account_item(data)),
    useLastaccount: data => dispatch(api_useLastaccount(data)),
    getSummaryCareplan: data => dispatch(api_getSummaryCareplan(data)),
    api_summary_billing: data => dispatch(api_summary_billing(data))
  };
};

const mapStateToProps = state => {
  let selected_appointment =
    state.getDrAppointments.selected_appointment != undefined
      ? state.getDrAppointments.selected_appointment
      : [];
  // let logged_info  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info:[];
  console.log(
    "selected_appointment",
    state.getDrAppointments.getPatientDetailst
  );
  let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : [];
  let selectedPatientDetails;
  if (patientDetails.length > 0) {
    selectedPatientDetails = patientDetails[0];
  } else {
    selectedPatientDetails = "";
  }
  console.log(">>>>>>>>>>>>>>>SELECTED APPOINTMENT", selected_appointment);
  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";

  return {
    selectedPatientDetails,
    logged_info,
    selected_appointment,
    logged_info
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UselastaccountPopup);
//export default finalisevisit;
