import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../../showHelp';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  api_list_service_notes,
  api_add_service_note,
  api_delete_service_note,
  api_delete_refered_by,
  api_add_refered_bydata,
  api_list_referedby,



} from './../../../DS/DS.VisitnotesFinalizeVisit';

import { api_list_options } from "./../../../DS/DS.Appointments";

import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';

class referedby extends Component {
  state = {
    serviceNoteText: '',
    service_notes_master: [],
    selectedServiceNotes: '',
    list_options: {},
    referral_date: new Date(),
    first_visit: new Date(),
    referral_expiry: new Date(),
    referral_type: "", 
    referral_to: "",
    referral_by: "",
    details: "", 
    referral_gp_specialist: "",
    patientId: this.props.redux_patientId,
    referedby_list: [],
    edit_show: false,
    patients_referedby_id: "",
    gpspecial:"GP"
    

  };
  componentDidMount() {
    // this.props.list_service_notes({ serviceNoteText: '', type: this.props.type }).then(res => {
    //   this.setState({ service_notes_master: res.data.content });
    // });

    this.setState({referral_to:this.props.logged_info.content.details[0].doctor_id})
    this.props.list_options().then(res => {
        
        this.setState({ list_options: res.data.content });
        console.log(this.state.list_options);
      });

      this.props.listReferedBy({patientId: this.state.patientId}).then(res => {
        this.setState({ referedby_list: res.data.content });
      });

  }

  componentWillReceiveProps(nextProps) {
    this.setState({ logged_info: nextProps.logged_info });
    this.setState({ drId: nextProps.logged_info.content.details[0].doctor_id });
    if (nextProps.redux_patientId !== this.props.redux_patientId) {
      this.setState({ patientId: nextProps.redux_patientId });
    }
  }

  handleDate_referral_expiry = date => {
    this.setState({ referral_expiry: date });
  };

  edit_refere_by = (e, id, item) => {
    this.setState({edit_show: true});
    this.setState({
      patients_referedby_id: item.patients_referedby_id,
      referral_date: new Date(item.referral_date),
      first_visit: new Date(item.first_date),
      referral_expiry: new Date(item.referral_expiry),
      referral_type: item.referral_type, 
      referral_to: item.referral_to,
      referral_by: item.referral_by,
      referral_gp_specialist: item.referral_gp_specialist,
      details: item.details,

    });




  }
  handleDate_referral_date = date => {
    this.setState({ referral_date: date });
  };

  handleDate_first_visit = date => {
    this.setState({ first_visit: date });
  };
   
  cancel_edit = () => {
  this.setState({
    edit_show: false, 
    patients_referedby_id: "",
    referral_to: "", 
    referral_by: "", 
    referral_gp_specialist: "", 
    details: "", 
  })  
  }
addReferedBy = () => {



  if(this.state.referral_by!="" && this.state.referral_to!="" && this.state.referral_gp_specialist!=""){

  
    this.props.addReferedByData(this.state).then(() => {
        this.props.listReferedBy({patientId: this.state.patientId}).then(res => {
            this.setState({ referedby_list: res.data.content });
          });
    });
  } else {
    alert("Please fill required fields");
  }
}
   
 
  listreferedbyfunc = a => {
    this.props.listReferedBy({patientId: this.state.patientId}).then(res => {
      this.setState({ referedby_list: res.data.content });
    });
  };
  handleDelete = (e, id) => {
    confirmAlert({
      title: '',
      message:
        'You are about to delete a service note. Do you want to proceed?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props
              .delete_service_notes({ service_note_id: id })
              .then(() => {
                this.props
                  .list_service_notes({ serviceNoteText: '', type: this.props.type })
                  .then(res => {
                    this.setState({ service_notes_master: res.data.content });
                    if (
                      this.state.service_notes_master &&
                      this.state.service_notes_master.length > 0
                    ) {
                      const isSelectedValue = this.state.service_notes_master.indexOf(
                        this.props.selectedServiceNotesValue
                      );
                      if (isSelectedValue === -1) {
                        this.props.getServiceNote('', this.props.selectedInput);
                      }
                    }
                  });
              });
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  handlerSelectService = () => {
    this.props.getServiceNote(
      this.state.selectedServiceNotes,
      this.props.selectedInput
    );
    this.props.closeReferedBy();
  };



  delete_refered_by = (e, id) => {
        this.props.delete_ReferedBy({patients_referedby_id: id}).then(()=> {
            this.props.listReferedBy({patientId: this.state.patientId}).then(res => {
                this.setState({ referedby_list: res.data.content });
              });
        });
  }
  handleRadio=(val)=>
  {
    this.setState({gpspecial:val})
  }
  render() {
    return (
      <Popup
        onOpen={() => {
          this.setState({
            selectedServiceNotes: this.props.selectedServiceNotesValue,
          });
        }}
        open={this.props.open}
        close={this.props.closeReferedBy}
        modal
        closeOnDocumentClick={false}
      >
        <div>
          <div className="modal-header">
            <div class="popup-title">
              <span> {this.props.type} Refered By </span>
              <Showhelp gotohelp="refered_help" />
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  onClick={e => this.props.closeReferedBy()}
                >
                  ×
                </span>
              </button>
            </div>
          </div>
          <div
            className="modal-body visit-notes-wrapper newpostpopup"
            style={{ overflow: 'scroll' }}
          >
            <div className="modal-body">
             
              <div className="row">
         
              
                <div className="col-md-6">
                

                  <div class="form-group row">
                  <label class="col-sm-4 col-form-label text-left">
                  Referred To*
                  </label>
                  <div class="col-sm-3">  
                    <select
                    onChange = {e => {this.setState({referral_to: e.target.value})}}
                    >
                      <option> Select </option>
                    {this.state.list_options.referral_to != null && this.state.list_options.referral_to.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.referral_to === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                    </select>
                    
                  </div>
                </div>

                  <div class="form-group row">
                  <label class="col-sm-4 col-form-label text-left">
                  Referred By*
                  </label>
                  <div class="col-sm-3">
                    {/*<select 
                    onChange = {e => {this.setState({referral_by: e.target.value})}}
                    >
                    <option> Select </option>
                    {this.state.list_options.referral_by != null && this.state.list_options.referral_by.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.referral_by === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                    </select>
                    */}
                    <input type="text" value={this.state.referral_by} onChange={(e)=>this.setState({referral_by:e.target.value})} style={{marginTop:"3px"}}/>
                  </div>
                </div>
                
                  <div class="form-group row">
                  <div class="col-sm-3">
                  {/*}  <select onChange={e => this.setState({referral_gp_specialist: e.target.value})} >
                      <option> Select </option>
                    {this.state.list_options.referral_gp_specialist != null && this.state.list_options.referral_gp_specialist.map((item, index) => (
                          <option
                            value={item.referral_gp_specialist}
                            selected={this.state.referral_gp_specialist === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                    </select>*/}
                      <label style={{fontSize: '12px'}} onClick={()=>{this.handleRadio("GP")}}>
                          <input type="radio" clasadios="form-control form-control-sm"  value={this.state.gpspecial} 
                           checked={this.state.gpspecial=="GP" ?'checked':''} name="reminderradio1" />&nbsp;GP<br />
                      </label>
                     </div> 
                      <div class="col-sm-5">
                      <label style={{fontSize: '12px'}} onClick={()=>{this.handleRadio("Specialist")}}>
                          <input type="radio" clasadios="form-control form-control-sm" value={ this.state.gpspecial}  
                          name="reminderradio1"  checked={this.state.gpspecial=="Specialist" ?'checked':''} />&nbsp;Specialist<br />
                      </label>
                  </div>
                </div>
                
                <div class="form-group row" style={{marginTop:"10px"}}>
                  <label class="col-sm-4 col-form-label text-left">
                    Referral Date: 
                  </label>
                  <div class="col-sm-3" style={{marginTop:"17px"}}>

                  <DatePicker
                      selected={this.state.referral_date}
                      onChange={this.handleDate_referral_date}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />
                    
                  </div>
                </div>
                  </div>
                <div className="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label text-left">
                    First Visit: 
                  </label>
                  <div class="col-sm-3" style={{marginTop:"15px"}}>

                  <DatePicker
                      selected={this.state.first_visit}
                      onChange={this.handleDate_first_visit}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />
                    
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label text-left">
                  Referred Type
                  </label>
                  <div class="col-sm-3">
                    <select onChange={e => this.setState({referral_type: e.target.value})}>
                    {this.state.list_options.referral_type != null && this.state.list_options.referral_type.map((item, index) => (
                          <option
                            value={item.referral_type}
                            selected={this.state.referral_type === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                    </select>
                    
                  </div>
                </div>

                
              

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label text-left">
                    Referral Expiry: 
                  </label>
                  <div class="col-sm-3 shrink" style={{marginTop:"15px"}}>

                  <DatePicker
                      selected={this.state.referral_expiry}
                      onChange={this.handleDate_referral_expiry}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      showMonthYearPicker
                    />
                    
                  </div>
                </div>
                <div class="form-group row" style={{marginTop:"10px"}}>
                  <label class="col-sm-4 col-form-label text-left">
                    Detail: 
                  </label>
                  <div class="col-sm-3 shrink">
                      <textarea value={this.state.details}  onChange= {e => {this.setState({details: e.target.value})}}></textarea>
                  
                  </div>
                </div>
            
            


             
                </div>   
              </div>     
                
                <div class="row" style={{margin:"10px"}}>
                <div class="col-md-12">
                {this.state.edit_show == false && <button className="btn btn-primary" onClick={e => this.addReferedBy()}> Add </button>}
                {this.state.edit_show == true && <span><button className="btn btn-info" onClick={e => this.addReferedBy()}> Edit </button><button className="btn btn-info" onClick={e => this.cancel_edit()}> Cancel </button> </span>}
                  </div>
                  </div>
                
                
                
                
                <div className="col-md-12">
                  <div
                    style={{
                     
                      height: '300px',
                      color:"black"
                    }}
                  >
                    <table class="table">

                        <tr>
                            <th>Referral Date</th>
                            <th>Referred to</th>
                            <th>Referred By</th>
                            <th>First Visit</th>
                            <th>Expires</th>
                            <th>Detail</th>
                            <th>Action</th>

                        </tr>
                      {this.state.referedby_list != undefined &&
                        this.state.referedby_list.map(v => (
                          <tr>
                            <td>&nbsp;{v.referral_date} </td>
                            <td>&nbsp;{v.referral_to_doc} </td>
                            <td>&nbsp;{v.referral_by_doc} </td>
                            <td>&nbsp;{v.first_date} </td>
                            <td>&nbsp;{v.referral_expiry} </td>
                            <td>&nbsp;{v.details} </td>
                            <td>
                                
                            <a href="#" onClick= {e => {this.edit_refere_by(e, v.patients_referedby_id, v)}}>
                            <i className="fa fa-pencil" aria-hidden="true" />
                                
                              </a>  {" "}
                                <a href="#" onClick= {e => {this.delete_refered_by(e, v.patients_referedby_id)}}>
                                <i className="fa fa-trash" aria-hidden="true" /> 
                                </a>


                                  </td>
                          </tr>
                        ))}
                    </table>
                  </div>
                </div>



              
            </div>
          </div>
          <div className="modal-footer">
            <div className="row">
              <div className="col-sm-6"></div>
              <div className="col-sm-6">
                <button
                  type="button"
                  className="btn btn-info"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={e => this.props.closeReferedBy()} //this.props.closeServiceNotes()}
                >
                  Close{' '}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    //ItemValidation: data => dispatch(api_careplan_item_validation(data)),
    list_service_notes: data => dispatch(api_list_service_notes(data)),
   
    add_service_note: data => dispatch(api_add_service_note(data)),
    list_options: data => dispatch(api_list_options(data)),
    addReferedByData: data => dispatch(api_add_refered_bydata(data)),
    listReferedBy: data => dispatch(api_list_referedby(data)),
    delete_ReferedBy: data => dispatch(api_delete_refered_by(data)),
  };
};

const mapStateToProps = state => {
    let redux_patientId =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].patient_id
      : "";
  let selected_appointment =
    state.getDrAppointments.selected_appointment != undefined
      ? state.getDrAppointments.selected_appointment
      : [];
  // let logged_info  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info:[];
let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : [];
  let selectedPatientDetails;
  if (patientDetails.length > 0) {
    selectedPatientDetails = patientDetails[0];
  } else {
    selectedPatientDetails = '';
  }

  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : '';

  return {
    selectedPatientDetails,
    logged_info,
    selected_appointment,
    logged_info,
    redux_patientId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(referedby);
