import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {api_add_contact,api_list_reason,api_update_contact} from '../../../DS/DS.ListPage';
import CodeMapping from './codeMapping';
class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        contact_name:'',
        errors:[],
        con_type:1,
        indiDate:true,
        compDate:false,
        openCodeMapping:false,
        key:0
    }
  }
  componentWillMount=()=>
  {
    if(this.props.popFor=='Edit')
    {
        const getData={tbl:'contact_category',pk:'category_id',ids:this.props.ids}
        this.props.listCategoryReason(getData).then(
            res=>{
                this.setState({contact_name:res.data.content.contact_name})
                this.handleRadioButton(res.data.content.category_type)
            }
        )
    }
  }
  handleValidation(){
    let errors = {};
    let formIsValid = true;
  
    //Reason name
    if(!this.state.contact_name){
       formIsValid = false;
       errors["contact_name"] = "Cannot be empty";
    }
     this.setState({errors: errors});
    return formIsValid;
  }
  onClickHandler=(e)=>
  {
    e.preventDefault();
    if(this.handleValidation()){
        const reasonData={
                reason:this.state.contact_name,
                cat_type:this.state.con_type
              }
                this.props.addCategoryReason(reasonData).then(
                  req=>
                  {
                    
                    if(req.data.status=="Duplicate"){
                             
                      NotificationManager.error('Duplicate contact category record cannot be created',)
                 }
                 else if(req.data.status=="Success")
                 {
                    
                      NotificationManager.success('New contact category created',)
                 }
                 else{
                     
                     NotificationManager.console.error('Error encountered while creating record',)
                 }
                 this.props.reloadPage()
              }        
            
                      
              );
    }
} 
updateRecord=(e)=>
{
    e.preventDefault();
    if(this.handleValidation()){
        const reasonData={
                reason:this.state.contact_name,
                ids:this.props.ids,
                cat_type:this.state.con_type
              }
                this.props.UpdateCategoryReason(reasonData).then(
                  req=>{
                    if(req.data.status=="Duplicate"){
                       
                         NotificationManager.error('Duplicate contact category record cannot be created',)
                    }
                    else if(req.data.status=="Success")
                    {
                      
                         NotificationManager.success('Contact category modified',)
                    }
                    else{
                        
                        NotificationManager.error('Error encountered while creating record',)
                    }
                    this.props.reloadPage()  
              }
              );
    }
}
handleRadioButton=(rid)=>
{
    //alert(rid)
    if(rid==1)
    {
        this.setState({indiDate:true,compDate:false,con_type:1})
     
    }
    if(rid==2)
    {
        this.setState({indiDate:false,compDate:true,con_type:2})
    }
}
reloadPage=()=>
{
    this.setState({openCodeMapping:false})
    this.setState({key:Math.random()})
}
 render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
            <div>
                <div className="modal-header">
                    <span className="modal-title-2" id="exampleModalLabel">Contact Category</span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={(e)=>this.props.reloadPage()}>
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <div className="col-sm-4"> 
                                    <label> Enter the category name : </label>
                                </div>
                                <div className="col-sm-8 cursor">
                                    <input type="text" name="text" className="form-control" value={this.state.contact_name} onChange={(e)=>this.setState({contact_name:e.target.value})}/>
                                    <span className="error" style={{color:'red'}}>{this.state.errors["contact_name"]}</span>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-distance">
                        <div className="col-sm-4"> </div>
                        <div className="col-sm-4">
                            <label>
							    <input type="radio" value="1" style={{margin: '8px 0px 3px 5px', float:'left'}} name="movetype" onChange={(e) => this.handleRadioButton(1)}  checked={this.state.indiDate}/>
                                <span style={{marginTop:'5px', paddingLeft:'5px', marginRight:'5px', float:'left'}}>Individual</span>
                            </label>
                        </div>
                        <div className="col-sm-4"> </div>
                    </div>
                    <div className="row row-distance">
                        <div className="col-sm-4"> </div>
                        <div className="col-sm-4">
                            <label>
                                <input type="radio" value="2" style={{margin: '8px 0px 3px 5px', float:'left'}} name="movetype" onChange={(e) =>  this.handleRadioButton(2)}  checked={this.state.compDate}/>
                                <span style={{marginTop:'5px', marginBottom:'10px', paddingLeft:'5px', marginRight:'5px', float:'left'}}>Company / Institution</span>
                            </label>
                        </div>
                        <div className="col-sm-4"> </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-sm-6"> </div>
                        <div className="col-sm-6">
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#contact-category-code-mapping" onClick={(e)=>this.setState({openCodeMapping:true})}> Code Mapping </button>
                           {this.props.popFor=='Add' ?
                            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.onClickHandler(e)}>Save</button>
                            :<button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.updateRecord(e)}>Update</button>
                            }
                            <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close"  onClick={(e)=>this.props.reloadPage()}>Cancel</button> 
                        </div>
                    </div>
                </div>
                {this.state.openCodeMapping ?
                <CodeMapping showModal={this.state.openCodeMapping} reloadPage={this.reloadPage}/> : ''}
            </div>
        )}
        </Popup>
    )}
}

const mapDispatchToProps = dispatch => {
    return {
        addCategoryReason: data => dispatch(api_add_contact(data)),
        listCategoryReason : data => dispatch(api_list_reason(data)),
        UpdateCategoryReason: data => dispatch(api_update_contact(data))
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(AddCategory);
