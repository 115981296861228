import React  from 'react'
import { Chart } from 'react-charts'

 export default function MyChart(props) {
 
const data = React.useMemo(
    () => [
      {
        label: 'Series 1',
    //    data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]]
        data: []
      }
    ], 
    []
  )
 
  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'bottom' },
      { type: 'linear', position: 'left' }
    ],
    []
  )
 
  if(props.selectedChart.length > 0){
       data[0].data = props.selectedChart; 
    //   data[0].data = [['ddddd', 1], ['dd', 2], ['ddddddd', 4], ['ddd', 2], ['d', 7]];
   }
   else {
       //data[0].data = [['ccc', 1], ['cc', 2], ['cccc', 4], ['cc', 2], ['cd', 7]];
       data[0].data = [];
   }

    
    console.log(data);
 
 
   return(
    <div
      style={{
        width: '400px',
        height: '300px'
      }}
    >
        
      <Chart data={data} axes={axes} />  
    </div>
   )
}
 