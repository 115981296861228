import axios from 'axios';


export const api_deleteClinical = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=clinical&action=delete_clinical`, data);
}
export const api_addClinical = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=clinical&action=add_clinical_record`, data);
}
export const api_getClinical=(data) => dispatch =>
{
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=clinical&action=get_clinical`, data);
}
export const api_updateClinical = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=clinical&action=update_clinical_record`, data);
}

export const api_addClinicalNote = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=clinical&action=add_clinical_note`, data);
}
export const api_getContactType=(data) => dispatch =>
{
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=clinical&action=contact_type`, data);
}
export const api_getContactMethod=(data) => dispatch =>
{
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=clinical&action=contact_method`, data);
}
export const api_getContactWith=(data) => dispatch =>
{
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=clinical&action=contact_with`, data);
}
export const api_getContactStatus=(data) => dispatch =>
{
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=clinical&action=contact_status`, data);
}
export const api_getNotes = data => dispatch => {
	return axios
	  .get(`${process.env.REACT_APP_BASE_URL}api=clinical&action=get_notes`,data);
	 
  };
  export const api_getPatientDetails = data => dispatch => {
	return axios
	  .post(`${process.env.REACT_APP_BASE_URL}api=clinical&action=selected_patient`,data);
	 
  };
  export const api_upload_image = data => dispatch => {
	  console.log(">>>",data)
	return axios
	  .post( `${process.env.REACT_APP_BASE_URL}api=clinical&action=upload_image`,data);
	 
  };
  export const api_updateRecord = data => dispatch => {
	return axios
	  .post( `${process.env.REACT_APP_BASE_URL}api=clinical&action=update_record`,data);
	 
  };
  export const api_total_records = data => dispatch => {
	return axios
	  .post( `${process.env.REACT_APP_BASE_URL}api=clinical&action=total_records`,data);
	 
  };
  