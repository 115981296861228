import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import {api_add_antenantal} from '../../../DS/DS.ObstetricHistory'
import PregnancyDetails from './pregnancyDetails'
import { api_getDoctors } from '../../../DS/DS.Appointments';
import Showhelp from './../../showHelp';

class AntenatalOther extends Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            open:this.props.showmodal,
            key:0,
            docs:[],
            date:new Date(),
            patitne_id:this.props.redux_patientId,
            preg_id:this.props.id,
            test:'',
            result:''

        }
    }
    componentWillMount=()=>
    {
        
       
    }
    saveAllData=()=>
    {
        this.props.changeKey(this.state)
    }
    handleDate=(d)=>
    {
        this.setState({date:d})
    }
    render() {
        return ( 
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div style={{ width:'400px', backgroundColor:'#FFF', margin:"0px auto" }}> 
                <div style={{ width:'400px', marginLeft:'90px', backgroundColor:'#FFF', float:'left'}}>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '0px 15px',color:'black'}}>
                        <h6 style={{backgroundColor:'', float:'left', width:'100%', fontSize:'16px'}}> Antenatal visit <Showhelp gotohelp="antenatal_other_help"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()} style={{ marginTop: "-28px", right: "20px"}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body " style={{ fontSize: "14px" }}>
                        <div className="col-sm-12 mt-3" style={{marginBottom: '10'}}>
                            <div className="row">
                                <div className="col-sm-2 pr-0"><label className="col-form-label text-left">Date:</label></div>
                                <div className="col-sm-6"> 
                                <DatePicker
                                         className="datePicker"
                                         onChange={this.handleDate}
                                         dateFormat="dd/MM/yyyy"
                                         id="clinicalcalendar" 
                                         selected={
                                                this.state.dateShow
                                                    ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                    : Date.parse(this.state.date)
                                                }
                                        />  </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 mt-3 pr-0"><label className="col-form-label text-left">Test:</label></div>
                                <div className="col-sm-10"><input type="text" name="search_condition" className="form-control" value={this.state.test}
                                onChange={(e)=>this.setState({test:e.target.value})} /></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 mt-2 pr-0"><label className="col-form-label text-left">Result:</label></div>
                                <div className="col-sm-10"><input type="text" name="search_condition" className="form-control mt-0" 
                                 onChange={(e)=>this.setState({result:e.target.value})} value={this.state.result}/></div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button"  onClick={() => this.saveAllData()} className="btn btn-primary btn-xs" data-dismiss="modal">
                            Save
                        </button>
                        <button type="button"  onClick={() => this.props.changeKey()} className="btn btn-primary btn-xs" data-dismiss="modal">
                            Cancel
                        </button>
                    </div>   
                </div>
                </div>
        )}
    </Popup>
   )}
}   

/*const mapDispatchToProps = dispatch => {
    return {
            getDoctors: data => dispatch(api_getDoctors(data)),
            saveAnte:data=>dispatch(api_add_antenantal(data))
           
            };
  };
  const mapStateToProps = state => {
   console.log(">>> doc data",state.getDrAppointments.logged_info)
     let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
 
   return {
     redux_patientId,redux_docId
   };
 };*/
  export default connect(null,null)(AntenatalOther);