import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    api_add_correspondence,
    api_list_selected,
    api_update_correspondence
} from '../../../DS/DS.Correspondence';
import { api_getDoctors } from '../../../DS/DS.Appointments';
//import "react-datepicker/dist/react-datepicker.css"
import Showhelp from './../../showHelp';

class EditCorrespondence extends Component {
    constructor(props){
        super(props);
            this.openModal = this.openModal.bind(this);
            this.closeModal = this.closeModal.bind(this);
       }
    state = {
        open: false,
        patient_id: this.props.patient_id,
        startDate: '',
        dateShow: true,
        isChecked: false,
        selectedCategory : '',
        from_guid: '',
        subject: '',
        details: '',
        ref: '',
        docs: [],
        slectedCorres: []
    }
    componentDidMount(){

        this.props.list_selected({slectedCorrespondence:this.props.slectedCorrespondence[this.props.slectedCorrespondence.length -1]}).then(res => {
          this.setState({slectedCorres: res.data.content})}
          );
        this.props.getDoctors().then(res => {
            this.setState({
                docs: res.data.content ,
            })}
        );
        //console.log(this.props.slectedCorrespondence);
      } 
      openModal() {
        this.setState({ open: true });
        console.log(this.state.slectedCorres);
        this.props.list_selected({slectedCorrespondence:this.props.slectedCorrespondence[this.props.slectedCorrespondence.length -1]}).then(res => {
            this.setState({slectedCorres: res.data.content})}
            );
        //console.log(this.props.slectedCorrespondence[this.props.slectedCorrespondence.length -1]    );
      }
      closeModal() {
        this.setState({ open: false });
      }
     handleDate = date => {
        console.log(date);
        this.setState({
          startDate: moment(new Date(date)).format('YYYY/MM/DD')
        });

        if(moment(new Date(date)).format('MM/DD/YYYY') == moment(new Date()).format('MM/DD/YYYY')){

          this.setState({
            dateShow: true,
          });
        }else {
          this.setState({
            dateShow: false,
          });
        }
      };
      handleSave() {
          /*const values = {
            'patient_id': this.state.patient_id,
            'correspondence_date' : this.state.startDate,
            'category': this.state.selectedCategory,
            'from_guid': this.state.from_guid,
            'subject': this.state.subject,
            'isConfidencial' :this.state.isChecked,
            'isDeleted': 0,
            'user_reference' :this.state.ref,
            'details': this.state.details
          }*/
          this.props.update_correspondence({
            'patients_correspondence_id':this.props.slectedCorrespondence[this.props.slectedCorrespondence.length -1],
            'patient_id': this.state.patient_id,
            'correspondence_date' : this.state.startDate,
            'category': this.state.selectedCategory,
            'from_guid': this.state.from_guid,
            'subject': this.state.subject,
            'isConfidencial' :this.state.isChecked,
            'isDeleted': 0,
            'user_reference' :this.state.ref,
            'details': this.state.details
          });
          this.setState({startDate: '', isChecked: false});
          //console.log(values);
      }

      toggleChange = () => {
        this.setState({
          isChecked: !this.state.isChecked,
        });
      }
    render() {
        return (
        <div style={{display:'inline-block'}}>
            <button onClick={this.openModal} disabled={this.props.disable} id="session-timeout-dialog-logout" type="button" className="btn btn-default" style={{margin: '8px 5px 0px 0px', padding: '5px 10px'}}>Edit </button>
        <Popup
        open={this.state.open}
        position="right center"
        modal
        closeOnDocumentClick={false}
        contentStyle={{width:'40%'}}
        onClose={this.closeModal}>
         {close => (
            <div>
                <div className="modal-header" style={{width: '100%',height: '20', backgroundColor: '#f4f4f4',padding:'0 15px 0 15px'}} align="center">
                    <span><h6 style={{backgroundColor: '#f4f4f4', float: 'left', fontSize: 16}}>Edit <Showhelp gotohelp="correspondence_edit_help"/></h6>
                    <button type="button" className="close" data-dismiss="modal" onClick={() => close()} style={{marginTop:'10px'}} aria-label="Close">×</button></span>
                </div>

                <div className="modal-body">
                    <div>
                    <div style={{ float: "left", width: "100%", height: "auto",fontSize: 14}}>
                        <div className="col-sm-3" style={{ paddingLeft: '20px',marginTop: '25px'}}>
                            <label>Date :</label>
                        </div>
                        <div className="col-sm-9" style={{marginTop: '25px'}}>
                        { this.state.slectedCorres !=undefined && this.state.slectedCorres.map((item,i) => (
                        <DatePicker
                            className="datePicker"
                            value= {() => this.setState({ startDate: item.correspondence_date })}
                            selected={
                            this.state.dateShow 
                                ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                : Date.parse(this.state.startDate)
                            }

                            // selected={Date.parse(this.state.startDate)}
                            onChange={this.handleDate}
                        />
                        ))}
                        </div>
                    </div>
                    <div style={{ float: "left", width: "100%", height: "auto",fontSize: 14}}>
                        <div className="col-sm-3" style={{ paddingLeft: '20px',marginTop: '20px'}}>
                            <label>Category :</label>
                        </div>
                        <div className="col-sm-9" style={{marginTop: '10px'}}>
                        { this.state.slectedCorres !=undefined && this.state.slectedCorres.map((item,i) => (
                            <select style={{width:"60%",height:"30px"}} onChange={(e) => this.setState({ selectedCategory: e.target.value })}>
                                <option value={() => this.setState({ selectedCategory: item.category })}>{item.category}</option>
                                <option value="Clinical Photograph"> Clinical Photograph </option>
                                <option value="Discrage Summary"> Discrage Summary </option>
                                <option value="ECG"> ECG </option>
                                <option value="Email"> Email </option>
                                <option value="Letter"> Letter </option>
                                <option value="Patient Consent"> Patient Consent </option>
                                <option value="Referral Letter"> Referral Letter </option>
                                <option value="Specialist Letter"> Specialist Letter </option>
                                <option value="Report"> Report </option>
                            </select>
                        ))}
                        </div>
                    </div>
                    <div style={{ float: "left", width: "100%", height: "auto",fontSize: 14}}>
                        <div className="col-sm-3" style={{ paddingLeft: '20px',marginTop: '20px'}}>
                            <label>From :</label>
                        </div>
                        <div className="col-sm-9" style={{marginTop: '10px'}}>
                            <select style={{width:"50%",height:"25px"}} onChange={(e) => this.setState({ from_guid: e.target.value })}>
                                { this.state.slectedCorres !=undefined && this.state.slectedCorres.map((item,i) => (
                                <option value={() => this.setState({ from_guid: item.category })}></option>
                                ))}
                                { this.state.docs !=undefined && this.state.docs.map((item,i) => (
                                <option value={item.doctor_id}> {item.Firstname} </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div style={{ float: "left", width: "100%", height: "auto",fontSize: 14}}>
                        <div className="col-sm-3" style={{ paddingLeft: '20px',marginTop: '20px'}}>
                            <label>Subject :</label>
                        </div>
                        <div className="col-sm-9" style={{marginTop: '10px'}}>
                        { this.state.slectedCorres !=undefined && this.state.slectedCorres.map((item,i) => (
                            <input type="text" style={{width:"50%"}} defaultValue={item.subject} onChange={(e) => this.setState({ subject: e.target.value })}/>
                        ))}
                        </div>
                    </div>
                    <div style={{ float: "left", width: "100%", height: "auto",fontSize: 14}}>
                        <div className="col-sm-3" style={{ paddingLeft: '20px',marginTop: '20px'}}>
                            <label>Details :</label>
                        </div>
                        <div className="col-sm-9" style={{marginTop: '10px'}}>
                        { this.state.slectedCorres !=undefined && this.state.slectedCorres.map((item,i) => (
                            <textarea rows="4" cols="61" style={{width:"60%"}} defaultValue={item.details} onChange={(e) => this.setState({ details: e.target.value })}></textarea>
                        ))}
                        </div>
                    </div>
                    <div style={{ float: "left", width: "100%", height: "auto",fontSize: 14}}>
                        <div className="col-sm-3" style={{ paddingLeft: '20px',marginTop: '20px'}}>
                        </div>
                        <div className="col-sm-9" style={{marginTop: '10px'}}>
                            <input type="checkbox" checked={this.state.isChecked} onChange={this.toggleChange} />&nbsp;<span>Confidential</span>
                        </div>
                    </div>
                    <div style={{ float: "left", width: "100%", height: "auto",fontSize: 14}}>
                        <div className="col-sm-3" style={{ paddingLeft: '20px',marginTop: '20px'}}>
                            <label>User Reference :</label>
                        </div>
                        <div className="col-sm-9" style={{marginTop: '10px'}}>
                        { this.state.slectedCorres !=undefined && this.state.slectedCorres.map((item,i) => (
                            <input type="text" style={{width:"50%"}} defaultValue={item.user_reference} onChange={(e) => this.setState({ ref: e.target.value })}/>
                        ))}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div className="col-sm-8" style={{float:'left'}}></div>
                        <div className="col-sm-4" align="right" style={{float:'right'}}>
                            <button className="btn btn-primary btn-xs" style={{marginRight:'5px',width:'70px',height:'35px'}} onClick={() => { this.handleSave(); close(); this.props.onChange()}}>Save</button>
                            <button className="btn btn-primary btn-xs" style={{margin:'2px',width:'70px',height:'35px'}} onClick={() => { close(); this.props.onChange() }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          )}
          </Popup>
          </div>)
    }
}
const mapDispatchToProps = (dispatch) => {
	return {
    add_correspondence: data => dispatch(api_add_correspondence(data)),
    list_selected: data => dispatch(api_list_selected(data)),
    update_correspondence: data => dispatch(api_update_correspondence(data)),
    getDoctors: data => dispatch(api_getDoctors(data)),
  }
}
export default connect (null, mapDispatchToProps) (EditCorrespondence);
