import React from "react";
import Popup from "reactjs-popup";
//import { MenuItem } from 'react-contextmenu';
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import {
    api_list_vaccines,
    api_updatePatientPreventiveHealth
  } from "./../../../DS/DS.Immunisations";
class PreventiveHealth extends React.Component {
  state = {
    details: [],
    vaccines_master: [],
    patient_preventive: [],
    patient_id: this.props.patient_id,
  };

  componentDidMount(){
    this.props.list_vaccines().then(res => {
        this.setState({ vaccines_master: res.data.content });
      });
  }
  handleChange = (e, id) => {
    let preventive_ids= this.state.patient_preventive;
    preventive_ids.push(id);
    this.setState({patient_preventive: preventive_ids});
  }

  handleSave = (e) => {
      this.props.updatePatientPreventiveHealth({ids: this.state.patient_preventive, patient_id: this.state.patient_id});

      NotificationManager.success(
        "Preventive Health Information Saved Successfully"
      );

      this.props.close();
  }
  render() {
    console.log(this.props);
    return (
      <Popup
        style={{ zIndex: "99999" }}
        open={this.props.open}
        modal
        closeOnDocumentClick={false}
      >
        <div>

        <div className="modal-header">
                        <h5 className="col-sm-12 sheadtitle" style={{ width:'90%', padding:'0px 10px'}}>  Preventive Health </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.close()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

            <div className="modal-body addinvestigation" style={{ float:'left', marginTop:'20px' }}>
              <div className="col-sm-12 mt-3">
                <div className="col-sm-12">
                  <div className="row form-group">
                    <table
                      className=" table table-bordered"
                      style={{ float: "left", width: "100%" }}
                    >
                      <thead>
                        <tr style={{ backgroundColor: "#f4f4f4" }}>
                          <th> Immunisation </th>
                        
                        </tr>
                      </thead>
                      <tbody>
                          {this.state.vaccines_master!=undefined && this.state.vaccines_master.length> 0 && this.state.vaccines_master.map(v=> (
                          <tr>  <td>
                            <lable> 
                            <input type="checkbox" onChange={e=> this.handleChange(e, v.vaccine_id)} />
                            
                           {v.name} </lable>  
                              
                          </td> </tr>
                          ))}
                      </tbody>
                      
                    </table>
                  </div>
                </div>
              </div>
            <div class="modal-footer">
            <button
                type="button"
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
                onClick={e => {
                  this.handleSave(e);
                }}
              >
                Add
              </button>
              <button
                type="button"
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
                onClick={e => {
                  this.props.close(e);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    list_vaccines: data => dispatch(api_list_vaccines(data)),
    updatePatientPreventiveHealth: data => dispatch(api_updatePatientPreventiveHealth(data)),
    
  };
};
export default connect(null, mapDispatchToProps)(PreventiveHealth);
//export default MoveItem;
