import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
import Footer from "../../footer";
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import { confirmAlert } from 'react-confirm-alert';
import {api_delete_selection} from '../../../DS/DS.ListPage';
import AddReminderReason from './addReminderReason';
import AddReminderType from './addReminderType';
import AddReminderList from './addReminderList';
import {api_default_interval,api_update_settings} from '../../../DS/DS.ReminderPage';
import {api_get_settings} from '../../../DS/DS.Reminders';
 
class RemindersPage extends Component {
  constructor(props) {
    super(props);
    this.state={
      reloadKey:0,
      //reminder reason states
      addReminderReason:false,
      editReminderReason:false,
      reminderReasonSelected:'',
      reminderReasonSelectedLength:-1,
      //reminder type
      addReminderType:false,
      editReminderType:false,
      reminderTypeSelected:'',
      reminderTypeSelectedLength:-1,
      //settings states
      allow_text:false,
      allow_comments:false,
      default_inteval:[],
      selectedInterval:1,
      send_as:1,
      mark_as:false,
      def_actions:'',
      //reminder print states
      addReminderPrint:false,
      editReminderPrint:false,
      reminderPrintSelected:'',
      reminderPrintSelectedLength:-1,
      reminderReasonCrud: {
         jsonGridVersion: "v2.0",
         grid: true,
         viewgrid: {
           isVisible:true,
           isPagination: true,
           isFiltering: true,
           isSelection:true,
           deleteFunction:this.removeReminderReason,
           isDateGroup:false,
           isYearSeparator:false,
           dateCol:'',
           groupDateSelection:false,
           cellClicked:'',
           baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
           query: {
             tb: "reminder_reason_tb",
             cols: [
               "reason_id",
               "reason",
               "reason_type",
               "type_name"

             ],
            where: [
               {
                 type: "and",
                 key: "reminder_reason_tb.isDeleted",
                 operator: "=",
                 value: 0
               },
               {
                  type: "and",
                  key: "doc_id",
                  operator: "=",
                  value: 0               
               }
            ],
            joins:[
                {
                  joinType: "inner",
                  joinTable: "reminder_type",
                  joinCondition: "reminder_reason_tb.reason_type = reminder_type.type_id"
                }
            ],
             primary_key:"reason_id",
           },
           viewtabletitle: "",
           columns: [
             {name: "reason", title: "Reminder Reason"},
             {name: "type_name", title: "Type"}
           ],
           rows: [],
           sortinit: [{ columnName: "reason_id", direction: "desc" }],
           pagesize: 10,
           getAllIds:false,
           getallId:''
         }
       },
       reminderTypeCrud: {
         jsonGridVersion: "v2.0",
         grid: true,
         viewgrid: {
           isVisible:true,
           isPagination: true,
           isFiltering: true,
           isSelection:true,
           deleteFunction:this.removeReminderType,
           isDateGroup:false,
           isYearSeparator:false,
           dateCol:'',
           groupDateSelection:false,
           cellClicked:'',
           baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
           query: {
             tb: "reminder_type",
             cols: [
               "type_id",
               "type_name",
               "type_source"

             ],
            where: [
               {
                 type: "and",
                 key: "reminder_type.isDeleted",
                 operator: "=",
                 value: 0
               }
            ],
             primary_key:"type_id",
           },
           viewtabletitle: "",
           columns: [
             {name: "type_name", title: "Reminder Type"},
             {name: "type_source", title: "Source"}
           ],
           rows: [],
           sortinit: [{ columnName: "type_id", direction: "desc" }],
           pagesize: 10,
           getAllIds:false,
           getallId:''
         }
       },
       reminderPrintCrud: {
         jsonGridVersion: "v2.0",
         grid: true,
         viewgrid: {
           isVisible:true,
           isPagination: true,
           isFiltering: true,
           isSelection:true,
           deleteFunction:this.removeReminderPrint,
           isDateGroup:false,
           isYearSeparator:false,
           dateCol:'',
           groupDateSelection:false,
           cellClicked:'',
           baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
           query: {
             tb: "print_setting_tb",
             cols: [
               "setting_id",
               "print_text",
               "print_size",
               

             ],
            where: [
               {
                 type: "and",
                 key: "print_setting_tb.isDeleted",
                 operator: "=",
                 value: 0
               },
               {
                  type: "and",
                  key: "print_setting_tb.isSelected",
                  operator: "=",
                  value: 1
                }
            ],
             primary_key:"setting_id",
           },
           viewtabletitle: "",
           columns: [
             {name: "print_text", title: "Name"},
             {name: "print_size", title: "Szie"}
           ],
           rows: [],
           sortinit: [{ columnName: "setting_id", direction: "desc" }],
           pagesize: 10,
           getAllIds:false,
           getallId:''
         }
       }
     
       
    }
  }
  componentWillMount=()=>
  {
     this.props.apiDefaultInterval().then(res=>
      {
         this.setState({default_inteval:res.data.content})
      })
            const settname={setting_name:'reminder_text'}
          this.props.apigetsettings(settname).then(res=>{
           this.setState({setting_data:res.data.content[0].setting_value})
          })
          const settdef={setting_name:'default_interval'}
          this.props.apigetsettings(settdef).then(res=>{
           this.setState({selectedInterval:res.data.content[0].setting_value})
          })
          const settact={setting_name:'default_actions'}
          this.props.apigetsettings(settact).then(res=>{
           this.setState({def_actions:res.data.content[0].setting_value})
          })
          const settdef1={setting_name:'reminder_text'}
          this.props.apigetsettings(settdef1).then(res=>{
           this.setState({allow_text:res.data.content[0].setting_value})
          })
          const settact1={setting_name:'mark_as'}
          this.props.apigetsettings(settact1).then(res=>{
           this.setState({mark_as:res.data.content[0].setting_value})
          })
      
  }
  reloadPage=()=>{
   this.setState({
       addReminderReason:false, 
       editReminderReason:false,
       addReminderType:false,
       editReminderType:false,
       addReminderPrint:false,
       editReminderPrint:false
      })
   this.setState({
       reminderReasonSelected:'',
       reminderReasonSelectedLength:-1,
       reminderTypeSelected:'',
       reminderTypeSelectedLength:-1,
       reminderPrintSelected:'',
       reminderPrintSelectedLength:-1
      })
   this.setState({reloadKey:Math.random()})
}
//Delete reminder reason
removeReminderReason=(ids,action,totalLength)=>
{
   if(action=='selection')
   {
     this.state.reminderReasonSelected=ids;
     this.state.reminderReasonSelectedLength=totalLength;
   }
   if(action=='delete')
   {
       if (this.state.reminderReasonSelectedLength> 0) {
             const ReminderData={tb_name:'reminder_reason_tb',ids:this.state.reminderReasonSelected,primary_key:'reason_id'}
             confirmAlert({
               title: '',
               message: 'You are about to delete the reminder reason(s)?Do you want to proceed?',
               buttons: [
               {
                   label: 'Yes',
                   onClick: () => {
                   this.props.apiDelete(ReminderData).then(
                   res => this.reloadPage() ,
                   NotificationManager.success(
                   'Reminder reason(s) deleted',
                   )
                 );
               },
           },
           {
             label: 'No',
             onClick: () => { this.reloadPage()},
           },
         ],
       });
     } 
     else 
     {
       NotificationManager.error('Please select record(s) to delete');
     }
   }
}
 //edit reminder reason 
 editReminderReason=()=>
 {
     if(this.state.reminderReasonSelectedLength ==1 )
     {
        this.setState({editReminderReason:true})
     }
     else
     {
         alert("Please select single record to modify")
     }
 }
 //Delete reminder reason
removeReminderType=(ids,action,totalLength)=>
{
   if(action=='selection')
   {
     this.state.reminderTypeSelected=ids;
     this.state.reminderTypeSelectedLength=totalLength;
   }
   if(action=='delete')
   {
       if (this.state.reminderTypeSelectedLength> 0) {
             const ReminderData={tb_name:'reminder_type',ids:this.state.reminderTypeSelected,primary_key:'type_id'}
             confirmAlert({
               title: '',
               message: 'You are about to delete reminder type(s). Do you want to proceed?',
               buttons: [
               {
                   label: 'Yes',
                   onClick: () => {
                   this.props.apiDelete(ReminderData).then(
                   res => this.reloadPage() ,
                   NotificationManager.success(
                   'Reminder Type(s) deleted successfully',
                   )
                 );
               },
           },
           {
             label: 'No',
             onClick: () => { this.reloadPage()},
           },
         ],
       });
     } 
     else 
     {
       NotificationManager.error('Please select record(s) to delete');
     }
   }
}
//edit reminder reason 
editReminderType=()=>
{
    if(this.state.reminderTypeSelectedLength ==1 )
    {
       this.setState({editReminderType:true})
    }
    else
    {
        alert("Please select a record to modify")
    }
}
//Delete reminder reason
removeReminderPrint=(ids,action,totalLength)=>
{
   if(action=='selection')
   {
     this.state.reminderPrintSelected=ids;
     this.state.reminderPrintSelectedLength=totalLength;
   }
   if(action=='delete')
   {
       if (this.state.reminderPrintSelectedLength> 0) {
             const ReminderPrint={tb_name:'print_setting_tb',ids:this.state.reminderPrintSelected,primary_key:'setting_id'}
             confirmAlert({
               title: '',
               message: 'You are about to delete reminder page(s). Do you want to proceed? ',
               buttons: [
               {
                   label: 'Yes',
                   onClick: () => {
                   this.props.apiDelete(ReminderPrint).then(
                   res => this.reloadPage() ,
                   NotificationManager.success(
                   'Reminder page record deleted',
                   )
                 );
               },
           },
           {
             label: 'No',
             onClick: () => { this.reloadPage()},
           },
         ],
       });
     } 
     else 
     {
       NotificationManager.error('Please select record(s) to delete');
     }
   }
}
 //edit reminder reason 
 editReminderPrint=()=>
 {
     if(this.state.reminderPrintSelectedLength ==1 )
     {
        this.setState({editReminderPrint:true})
     }
     else
     {
         alert("Please select a record to modify")
     }
 }
 onFinalSaveHandler=()=>
 {
    if(this.state.allow_text==false)
    {
       this.state.allow_text=0
    }
    else
    {
       this.state.allow_text=1
    }
    if(this.state.allow_comments==false)
    {
       this.state.allow_comments=0
    }
    else
    {
       this.state.allow_comments=1
    }
    if(this.state.mark_as==false)
    {
       this.state.mark_as=0
    }
    else
    {
       this.state.mark_as=1
    }
    const finalData={
       allow_text:this.state.allow_text,
       allow_comments:this.state.allow_comments,
       send_as:this.state.send_as,
       mark_as:this.state.mark_as,
       def_interval:this.state.selectedInterval,
       def_action:this.state.def_actions

    }
    this.props.apUpdateSettings(finalData).then(
       res=>this.reloadPage() ,
      NotificationManager.success(
      'Settings updated successfully',
      )
    )
 }
 addCheck=()=>
{
  alert("Please deselect the record to proceed")
}
  render(){
      return (
          <div>
              <AdminHeader /><Leftsidemenu />
              <NotificationContainer />
                <section className="content-header" style={{marginLeft: '240px'}}>
                    <h1>
                        Reminders
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Reminders</li>
                    </ol>
                </section>
                {/* MAIN PAGE*/ }
                <section className="content" style={{marginLeft: '240px'}}>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="col-xs-12" style={{margin:'0px auto', float:'none'}}>
                                    <div className="box-body">
                                        <div className="col-sm-12 col-md-12 col-xl-12">
                                            <div id="pageContent">
                                                <div className="form-group row m-b-10">
                                                   <div className="col-sm-6">
                                                <div className="col-lg-12 m-t-60">
                                                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#reminders-add" style={{width:'100px', marginRight:'7px'}}  onClick={(e)=>{this.state.reminderReasonSelectedLength >0 ?this.addCheck():this.setState({addReminderReason:true})}}>Add</button>
                                                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#reminders-edit" style={{width:'100px', marginRight:'7px'}} onClick={(e)=>this.editReminderReason()}>Edit</button>
                                                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', marginRight:'7px'}} onClick={(e)=>this.removeReminderReason('','delete','')}>Remove</button>
                                                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#reminders-clean-up" style={{width:'100px', marginRight:'7px'}}>Clean up</button>
                                                        </div>
                                                        <div className="col-sm-12 col-md-12 col-xl-12 m-t-10 table-border">
                                                            <div className="card" style={{height:'120px', overFlow:'scroll'}}>
                                                                <div className="card-block">
                                                                    {/*<table style={{width:'100%'}}>
                                                                        <thead>
                                                                            <tr> 
                                                                                <th style={{padding:'5px'}}> Reminder reason </th>
                                                                                <th> Type </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>&nbsp;12mth Immunisation </td>
                                                                                <td>&nbsp;Normal </td>
                                                                            </tr>
                                                                        </tbody>
                                                                     </table>*/}
                                                            
                                                                     <CommonGrid
                                                                    crud={this.state.reminderReasonCrud}
                                                                    key={this.state.reloadKey}
                                                                />            
                                                            
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                        {this.state.addReminderReason ?
                                                            <AddReminderReason showModal={this.state.addReminderReason} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                             {this.state.editReminderReason ?
                                                            <AddReminderReason showModal={this.state.editReminderReason} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.reminderReasonSelected}/> :''}
                                                      </div>



                                                      <div className="col-sm-6">
                                                      <div className="col-lg-12 m-t-60">
                                                               <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#reminder-type-add" style={{width:'100px', marginRight:'7px'}} onClick={(e)=>{this.state.reminderTypeSelectedLength >0 ?this.addCheck():this.setState({addReminderType:true})}}> Add </button>
                                                               <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#reminder-type-edit" style={{width:'100px', marginRight:'7px'}} onClick={(e)=>this.editReminderType(e)}>Edit</button>
                                                               <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', marginRight:'7px'}}  onClick={(e)=>this.removeReminderType('','delete','')}>Delete</button>
                                                            </div>
                                                            <div className="col-sm-12 col-md-12 col-xl-12 m-t-10 table-border">
                                                               <div className="card" style={{height:'150px',overFlow:'scroll'}}>
                                                                  <div className="card-block">
                                                                   {/*}  <table style={{width:'100%'}}>
                                                                        <thead>
                                                                           <tr>
                                                                              <th style={{padding: '5px 0px'}}>&nbsp;Reminder Type</th>
                                                                              <th style={{padding: '5px 0px'}}>&nbsp;Source</th>
                                                                           </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                           <tr>
                                                                              <td>&nbsp;Normal</td>
                                                                              <td>&nbsp;Supplied</td>
                                                                           </tr>
                                                                        </tbody>
                                                                   </table>*/}
                                                                   
                                                                    <CommonGrid
                                                                    crud={this.state.reminderTypeCrud}
                                                                    key={this.state.reloadKey}
                                                                />     
                                                                  </div>
                                                               </div>
                                                            </div>
                                                           
                                                            {this.state.addReminderType ?
                                                            <AddReminderType showModal={this.state.addReminderType} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                             {this.state.editReminderType ?
                                                            <AddReminderType showModal={this.state.editReminderType} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.reminderTypeSelected}/> :''}
                                                            </div>
                                                         </div>
                                                         <div className="form-group row m-t-10">  
                                                         <div className="col-sm-6">                                     
                                                            <div className="col-sm-12 col-md-12 col-xl-12">
                                                               <div className="border-checkbox-section">
                                                                  <div className="border-checkbox-group border-checkbox-group-default">
                                                                  <label className="border-checkbox-label" for="checkbox1" onChange={(e)=>this.setState({allow_text:e.target.checked})} > 
                                                                   <input className="border-checkbox" type="checkbox" id="checkbox1" />
                                                                     &nbsp; Allow free text reminders</label>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                            <div className="col-sm-6"> </div>
                                                         </div>
                                                         </div>
                                                        {/*} <div className="form-group row m-t-10">
                                                            <div className="col-sm-12 col-md-3 col-xl-3">
                                                               <label className="col-form-label text-left">&nbsp;</label>			
                                                            </div>
                                                            <div className="col-sm-12 col-md-8 col-xl-8">
                                                               <div className="border-checkbox-section">
                                                                  <div className="border-checkbox-group border-checkbox-group-default">
                                                                  <label className="border-checkbox-label" for="checkbox2" onChange={(e)=>this.setState({allow_comments:e.target.checked})}><input className="border-checkbox" type="checkbox" id="checkbox2"/>
                                                                      &nbsp; Allow free text comments</label>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                             </div>*/}
                                                         <div className="form-group row m-b-10">
                                                         <div className="col-sm-6"> 
                                                               <div className="col-sm-12 col-md-12 col-xl-12 m-t-10">&nbsp;
                                                                  <label className="" style={{lineHeight:'.5', fontSize:'14px', marginBottom:'20px'}}>Fields to be printed on Reminder list:</label>
                                                               </div>
                                                               </div>
                                                               <div className="col-sm-6"> </div>
                                                            </div>
                                                            <div className="form-group row m-b-10"> 
                                                            <div className="col-sm-6">   
                                                            <div className="col-lg-12 m-t-60">
                                                                     <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#reminder-list-field-add" style={{width:'100px',marginRight:'7px'}} onClick={(e)=>{this.state.reminderPrintSelectedLength >0 ?this.addCheck():this.setState({addReminderPrint:true})}}> Add </button>
                                                                     <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#reminder-list-field-edit" style={{width:'100px', marginRight:'7px'}} onClick={(e)=>this.editReminderPrint(e)}>Edit</button>
                                                                     <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', marginRight:'7px'}} onClick={(e)=>this.removeReminderPrint('','delete','')}>Delete</button>
                                                                  </div>                           
                                                                  <div className="col-sm-12 col-md-12 col-xl-12 m-t-10 table-border">
                                                                     <div className="card" style={{height:'150px',overFlow:'scroll'}}>
                                                                        <div className="card-block">
                                                                           {/*<table style={{width:'100%'}}>
                                                                              <thead>
                                                                                 <tr>
                                                                                    <th style={{padding: '5px 0px'}}>&nbsp;Name</th>
                                                                                    <th style={{padding: '5px 0px'}}>&nbsp;Size(mm)</th>
                                                                                 </tr>
                                                                              </thead>
                                                                              <tbody>
                                                                                 <tr>
                                                                                    <td>&nbsp;Name</td>
                                                                                    <td>&nbsp;50</td>
                                                                                 </tr>
                                                                              </tbody>
                                                                           </table>*/}
                                                                            <CommonGrid
                                                                              crud={this.state.reminderPrintCrud}
                                                                              key={this.state.reloadKey}
                                                                           />    
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  
                                                                  {this.state.addReminderPrint ?
                                                                      <AddReminderList showModal={this.state.addReminderPrint} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                                  {this.state.editReminderPrint ?
                                                                     <AddReminderList showModal={this.state.editReminderPrint} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.reminderPrintSelected}/> :''}
                                                                  </div>
                                                                  <div className="col-sm-6">  

                                                               <div className="form-group row m-t-10" style={{marginTop:'20px'}}>
                                                                  <div className="col-sm-12 col-md-4 col-xl-4">
                                                                     <label className="col-form-label text-left">&nbsp;Send as</label>			
                                                                  </div>
                                                                  <div className="col-sm-12 col-md-3 col-xl-3">
                                                                     <select name="select" className="form-control" style={{padding:'.0rem .5rem', height:'30px', fontSize:'14px'}} onChange={(e)=>this.setState({send_as:e.target.value})} value={this.state.send_as}>
                                                                        <option value="opt1">Mail merge</option>
                                                                     </select>
                                                                  </div>
                                                                  <div className="col-sm-12 col-md-5 col-xl-5">
                                                                     <label className="col-form-label text-left">&nbsp;</label>			
                                                                  </div>
                                                               </div>
                                                               <div className="form-group row m-t-10">
                                                                  
                                                                  <div className="col-sm-12 col-md-10 col-xl-10">
                                                                     <div className="border-checkbox-section" style={{marginTop:'10px', marginBottom:'10px'}}>
                                                                        <div className="border-checkbox-group border-checkbox-group-default">
                                                                        <label className="border-checkbox-label" for="checkbox4" onChange={(e)=>this.setState({mark_as:e.target.checked})}>
                                                                           <input className="border-checkbox" type="checkbox" id="checkbox4"/>
                                                                            &nbsp;Check 'Mark as sent' box as default</label>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div className="col-sm-12 col-md-2 col-xl-2">
                                                                     <label className="col-form-label text-left">&nbsp;</label>			
                                                                  </div>
                                                               </div>
                                                               <div className="form-group row m-t-10">
                                                                 
                                                                  <div className="col-sm-12 col-md-4 col-xl-4">
                                                                     <label className="col-form-label text-left">&nbsp;Default reminder list interval</label>			
                                                                  </div>
                                                                  <div className="col-sm-12 col-md-4 col-xl-4">
                                                                     <select name="select" className="form-control" style={{padding: '.0rem .5rem', height: '30px', fontSize: '14px'}} onChange={(e) => this.setState({selectedInterval: e.target.value })} value={this.state.selectedInterval}>
                                                                        {this.state.default_inteval != undefined && this.state.default_inteval.length> 0 && this.state.default_inteval.map((allinterval) => <option key={allinterval.interval_id} value={allinterval.interval_id}>{allinterval.def_interval}</option>)}
                                                                     </select>
                                                                  </div>
                                                                  <div className="col-sm-12 col-md-2 col-xl-2">
                                                                     <label className="col-form-label text-left">&nbsp;</label>			
                                                                  </div>
                                                               </div>
                                                               <div className="form-group row m-t-10">
                                                                 
                                                                  <div className="col-sm-12 col-md-4 col-xl-4">
                                                                     <label className="col-form-label text-left">&nbsp;Default Actions/Reminders less than</label>			
                                                                  </div>
                                                                  <div className="col-sm-12 col-md-3 col-xl-3">
                                                                     <input type="text" className="form-control" style={{padding:'.2rem .75rem'}}  onChange={(e)=>this.setState({def_actions:e.target.value})} value={this.state.def_actions}/>
                                                                  </div>
                                                                  <div className="col-sm-12 col-md-2 col-xl-2">
                                                                     <label className="col-form-label text-left">&nbsp;months old</label>			
                                                                  </div>
                                                                {/*  <div className="col-sm-12 col-md-3 col-xl-3">
                                                                     <label className="col-form-label text-left">&nbsp;</label>			
                                                                  </div>*/}
                                                               </div>
                                                            </div>
                                                         </div>
                                                         </div>
                                                               </div>
                                                      </div>
                                                      <div className="modal-footer modal-footer-bottom-margin">
                                                         <div className="row">
                                                         <div className="col-sm-10"> </div>
                                                         <div className="col-sm-2">
                                                            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.onFinalSaveHandler()}>Save</button>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </section>
                                 <Footer />
                              </div>
        )
    }
}
const mapDispatchToProps = dispatch => {
   return {
       apiDelete:data =>dispatch(api_delete_selection(data)),
       apiDefaultInterval:()=>dispatch(api_default_interval()),
       apUpdateSettings:(data)=>dispatch(api_update_settings(data)),
       apigetsettings:(data)=>dispatch(api_get_settings(data))
    };
 };
 const mapStateToProps = state => {
 
   let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
   
   return {
       redux_userId
   };
 };
 
 
 export default connect(mapStateToProps,mapDispatchToProps)(RemindersPage);
