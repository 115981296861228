import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {api_list_reason} from '../../../DS/DS.ListPage';
import {api_add_reminder_list,api_update_reminder_list,api_get_print} from '../../../DS/DS.ReminderPage';

class AddReminderList extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        errors:[],
        reason:'',
        all_reminder_print:[],
        type_text:'',
        selectedRsnIndex:-1,
        type_size:''
    }
  }
  componentWillMount=()=>
  {
   //   alert(this.props.popFor)
    if(this.props.popFor=='Edit')
    {
        const getData={tbl:'print_setting_tb',pk:'setting_id',ids:this.props.ids}
        this.props.listreminderList(getData).then(
            res=>{
                this.setState({reason:res.data.content.print_text})
                this.setState({type_size:res.data.content.print_size})
            }
        )
        const getPrintData={print_module:'Reminder',is_selected:'0',isAdd:0}
         this.props.apiprint(getPrintData).then(contData=>{
               this.setState({all_reminder_print:contData.data.content})
               this.state.type_text=this.props.ids
               this.setTrClassOnLoad(this.props.ids);
               
         });
    }
    if(this.props.popFor=='Add')
    {
    const getPrintData={print_module:'Reminder',is_selected:'0',isAdd:1}
    this.props.apiprint(getPrintData).then(contData=>{
      this.setState({all_reminder_print:contData.data.content})
     });
   } 
  }
  handleValidation(){
    let errors = {};
    let formIsValid = true;
  
    //Description name
    if(this.state.type_size==''){
       formIsValid = false;
       errors["type_size"] = "Cannot be empty";
    }
     this.setState({errors: errors});
    return formIsValid;
  }
  onClickHandler=(e)=>
  {
    e.preventDefault();
    if(this.handleValidation()){
        if(this.state.isclinical==false)
        {
            this.state.isclinical=0
        }
        else
        {
            this.state.isclinical=1
        }
        const typeData={
                reason:this.state.reason,
                }
                this.props.addReminderList(typeData).then(
                  req=>{
                         NotificationManager.success('New reminder list record created successfully',)
                         this.props.reloadPage()
                      }
              );
    }
} 
updateRecord=(e)=>
{
    e.preventDefault();
    if(this.handleValidation()){
        const typeData={
         type_text:this.state.type_text,
         type_size:this.state.type_size
              }
                this.props.UpdatereminderList(typeData).then(
                  req=>{
                         NotificationManager.success('Selected reminder list record modified',)
                         this.props.reloadPage()
                      }
              );
    }
}
selectedReason=(e,selecteditem,index) =>
{
    this.setState({type_text:selecteditem.setting_id,selectedRsnIndex:index})
}
setTrClass = (id) => {
   let classs = "";
   classs +=
     id == this.state.selectedRsnIndex
       ? " selectedBg "
       : "";
   return classs;
 };
 setTrClassOnLoad = (id) => {
  // alert("IN")
   let classs = "";
   classs +=
     id == this.state.type_text
       ? " selectedBg "
       : "";
   return classs;
 };
 render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
            <div>
               <div className="modal-header">
               <span className="modal-title-2" id="exampleModalLabel"> Reminder List  </span>
               <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e)=>this.props.reloadPage()}>
                     <span aria-hidden="true">×</span>
               </button>
            </div>
            <div className="modal-body">
               <div className="row">
                  <div className="col-sm-12">
                     <div className="form-group">
                        <div className="col-sm-3"> 
                           <label> Select field : </label>
                        </div>
                        <div className="col-sm-9 cursor">
                           <div className="card appointment-type-add-inner-table">
                              <div className="card-block">
                              <table className="calendar table table-bordered tablestyle" style={{float: 'left', overflow: 'scroll', width: '100%'}}>
                                <thead>
                                    <tr style={{backgroundColor: '#f4f4f4'}}>
                                    <th>Field</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.all_reminder_print !=undefined && this.state.all_reminder_print.map((item,i)=> (

                                        <tr onClick={(e) => this.selectedReason(e,item,i)}  className={this.setTrClass(i)}> 
                                          <td>{item.print_text}</td>
                                        </tr>
                                    ))}
                                  
                                </tbody>
                              </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-sm-12">
                     <div className="form-group">
                        <div className="col-sm-3"> 
                           <label> Width (mm) : </label>
                        </div>
                        <div className="col-sm-3 cursor">
                           <input type="text" name="text" className="form-control" value={this.state.type_size} onChange={(e)=>this.setState({type_size:e.target.value})}/>
                           <span className="error" style={{color:'red'}}>{this.state.errors["type_size"]}</span>
                        
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="modal-footer">
               <div className="row">
                  <div className="col-sm-8"> </div>
                  <div className="col-sm-4">
                  {this.props.popFor=='Add' 
                        ? <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.updateRecord(e)}>Save</button>
                        : <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.updateRecord(e)}>Update</button>
                        }                      <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close"  onClick={(e)=>this.props.reloadPage()}>Cancel</button> 
                   </div>
               </div>
            </div>
         </div>
        )}
      </Popup>
    )}
   }    
   const mapDispatchToProps = dispatch => {
      return {
          addReminderList: data => dispatch(api_add_reminder_list(data)),
          listreminderList : data => dispatch(api_list_reason(data)),
          UpdatereminderList: data => dispatch(api_update_reminder_list(data)),
          apiprint : data => dispatch (api_get_print(data))
       };
    };
    const mapStateToProps = state => {
    
      let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
      
      return {
          redux_userId
      };
    };
    export default connect(mapStateToProps,mapDispatchToProps)(AddReminderList);
   