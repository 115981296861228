import React, { Component } from "react";
import Popup from "reactjs-popup";
import Showhelp from "./../showHelp";
import moment from "moment";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  api_list_careplans,
  api_add_create_account_item,
  api_create_account_line_item
} from "./../../DS/DS.VisitnotesFinalizeVisit";
import ServiceNotes from "./../crudcomponent/serviceNotes";
class EditItem extends Component {
  state = {
    cpid: "",
    servicenotes: "",
    patients_careplan_items_id: "",
    selectedInput: "",
    service_date: new Date(),
    careplanMaster: [],
    service_note: "",
    selected_list: [
      { description: "23, Surgery consultation, Level, B" },
      { deccription: "" },
      { deccription: "" },
      { deccription: "" },
      { deccription: "" }
    ],
    fee: [
      { name: "Practical Fee", amount: "65.00" },
      { name: "", amount: "" },
      { name: "", amount: "" },
      { name: "", amount: "" },
      { name: "", amount: "" }
    ],
    written_servicenotes: []
  };

  componentDidUpdate() {
    console.log("2eidt item create account", this.props.selectedRec);
    if (
      this.props.selectedRec.length == 1 &&
      this.props.selectedRec != this.state.selectedRec
    ) {
      this.setState({ selectedRec: this.props.selectedRec });

      this.props
        .getCreateAccountLineItem({
          item_id: this.props.selectedRec[0].patients_careplan_items_id
        })
        .then(res => {
          console.log("selected immunisation record", res.data.content);

          let a = [];
          let b = {
            position: 1,
            servicenote: res.data.content[0].service_note
          };

          a.push(b);

          this.setState({
            service_note: res.data.content[0].service_note,
            written_servicenotes: a,
            cpid: res.data.content[0].careplan_id,
            patients_careplan_items_id:
              res.data.content[0].patients_careplan_items_id,
            amt: res.data.content[0].amt,
            tax: res.data.content[0].tax
          });
        });
    }
  }

  handleDate2 = date => {
    this.setState({ service_date: date });
  };
  componentDidMount() {
    this.props
      .listCareplans()
      .then(res => this.setState({ careplanMaster: res.data.content }));
  }
  addItem = () => {
    // alert("add");
    console.log("editing", this.state);
    this.props.addItem(this.state);
  };
  addcpitem = (e, id, item) => {
    this.setState({ cpid: id, amt: item.price, tax: item.gst });
  };

  openServiceNotes = (e, i) => {
    // alert(i);
    this.setState({ servicenotes: true, selectedInput: i });
  };

  closeServiceNotes = () => {
    this.setState({ servicenotes: false, selectedInput: "" });
  };

  getServiceNote = (servicenote, position) => {
    let written_servicenotes = this.state.written_servicenotes;
    let service_note_item = {
      position: position,
      servicenote: servicenote
    };
    let added = 0;
    for (let i = 0; i < written_servicenotes.length; i++) {
      if (written_servicenotes[i].position == position) {
        written_servicenotes[i].servicenote = servicenote;
        added = 1;
        break;
      }
    }
    if (added != 1) {
      written_servicenotes.push(service_note_item);
    }
    this.setState({ written_servicenotes: written_servicenotes });
  };

  getServiceNoteValue = position => {
    let written_servicenotes = this.state.written_servicenotes;
    let selectedNote = "";
    for (let i = 0; i < written_servicenotes.length; i++) {
      if (written_servicenotes[i].position == position) {
        selectedNote = written_servicenotes[i].servicenote;
        break;
      }
    }
    return selectedNote;
  };

  render() {
    return (
      <Popup
        // trigger={
        open={this.props.open}
        close={() => this.props.closeAddItem}
        // }
        position="right center"
        modal
        closeOnDocumentClick={false}
      >
        <div>
          <div className="modal-header">
            <div class="popup-title">
              <span> Edit Item </span>
              <Showhelp gotohelp="diagnosis_popup" />
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
          <div className="modal-body creatac">
            <div className="col-sm-12">
              <div class="form-group row">
                <label
                  className="col-sm-2 col-form-label text-left"
                  style={{ paddingRight: "0px" }}
                >
                  Service Date
                </label>
                <div className="col-sm-3 cursor" style={{ paddingLeft: "0px", marginTop:'15px' }}>
                  <DatePicker
                    //ref={this.textInput}

                    ref={input => {
                      this.textInput = input;
                    }}
                    id="date-jumper"
                    inputRef={ref => {
                      this.textInput = ref;
                    }}
                    todayButton="Go to Today"
                    selected={this.state.service_date}
                    onChange={this.handleDate2}
                    showMonthDropdown
                    useShortMonthInDropdown
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    dateFormat="dd/MM/yyyy"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                  />
                  {/* <input
                    type="text"
                    name="search_condition"
                    className="form-control form-control-sm rq-form-elemen"
                    value={moment(this.state.service_date).format('DD/MM/YYYY')}
                  /> */}
                </div>
              </div>
              <div class="form-group row mb-3">
                <label>Select from list or free type:</label>
                <table
                  className="calendar table table-bordered tablestyle"
                  style={{ width: "100%", marginBottom: "0px" }}
                >
                  <thead>
                    <tr style={{ backgroundColor: "#f4f4f4" }}>
                      <th> Description</th>
                      <th> ItemNo.</th>
                      <th> GST</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.careplanMaster.length > 0 &&
                      this.state.careplanMaster.map(val => {
                        return (
                          <tr>
                            <td>
                              {" "}
                              
                                {" "}
                                <input
                                  type="radio"
                                  name="careplanitem"
                                  onClick={e =>
                                    this.addcpitem(e, val.care_plan_id, val)
                                  }
                                  checked={this.state.cpid == val.care_plan_id}
                                />
                                {val.name}{" "}
                              `{" "}
                            </td>
                            <td>{val.item_no}</td>
                            <td>{val.gst}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div class="col-sm-12 form-group row">
                <div class="col-sm-6 ">
                  <table
                    className="calendar table table-bordered tablestyle"
                    style={{ width: "100%", marginBottom: "0px" }}
                  >
                    <tbody>
                      {this.state.selected_list.map(val => {
                        return (
                          <tr>
                            <td>{val.description}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div class="col-sm-6">
                  <table
                    className="col-sm-6 calendar table table-bordered tablestyle"
                    style={{ width: "100%", marginBottom: "0px" }}
                  >
                    <tbody>
                      {this.state.fee.map(val => {
                        return (
                          <tr>
                            <td>{val.name}</td>
                            <td>{val.fee}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group row">
                  <label
                    className="col-form-label text-left"
                    style={{ paddingRight: "0px" }}
                  >
                    Description
                  </label>
                  <div className="cursor" style={{ paddingLeft: "0px" }}>
                    <input
                      type="text"
                      name="search_condition"
                      className="form-control form-control-sm rq-form-elemen"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    className="col-form-label text-left"
                    style={{ paddingRight: "0px" }}
                  >
                    Service Note
                  </label>
                  <div className="cursor" style={{ paddingLeft: "0px" }}>
                    <input
                      type="text"
                      name="service_note"
                      className="form-control form-control-sm rq-form-elemen"
                      value={this.getServiceNoteValue(1)}
                      onChange={e =>
                        this.setState({ service_note: e.target.value })
                      }
                    />
                  </div>
                  <button
                    onClick={e => this.openServiceNotes(e, 1)}
                    type="button"
                    className="btn btn-primary btn-xs mt-3"
                    data-toggle="modal"
                    data-target="#invoicenotes1"
                  >
                    Add
                  </button>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <label
                      className="col-sm-2 col-form-label text-left"
                      style={{ paddingRight: "0px" }}
                    >
                      Fee Amount
                    </label>
                    <div
                      className="col-sm-4 cursor"
                      style={{ paddingLeft: "0px" }}
                    >
                      <input
                        onChange={e => this.setState({ amt: e.target.value })}
                        value={this.state.amt}
                        type="text"
                        name="search_condition"
                        className="form-control form-control-sm rq-form-elemen"
                      />
                    </div>

                    <label
                      className="col-sm-1 col-form-label text-left"
                      style={{ paddingRight: "0px" }}
                    >
                      Tax
                    </label>
                    <div
                      className="col-sm-3 cursor"
                      style={{ paddingLeft: "0px" }}
                    >
                      <input
                        onChange={e => this.setState({ tax: e.target.value })}
                        value={this.state.tax}
                        type="text"
                        name="search_condition"
                        className="form-control form-control-sm rq-form-elemen"
                      />
                    </div>



                    <div class="form-group row mt-3">
                  <div class="col-sm-12">
                    <div className="col-sm-3 cursor pl-0">
                      <input
                        type="checkbox"
                        className="form-control form-control-sm"
                      />
                      Item Amount : {this.state.amt}
                    </div>
                    <div className="col-sm-3 cursor">
                      <input
                        type="checkbox"
                        className="form-control form-control-sm"
                      />
                      Fee Include GST : {parseFloat(this.state.amt)+(this.state.amt * parseFloat(this.state.tax)/100)}
                    </div>
                    </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="col-sm-12">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                aria-label="Close"
                onClick={e => {
                  this.addItem();
                  this.props.close();
                }} //this.props.closeServiceNotes()}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={e => this.props.close()}
              >
                Cancel
              </button>
            </div>
          </div>
          <ServiceNotes
            open={this.state.servicenotes && this.state.selectedInput != ""}
            type="Invoice"
            selectedInput={this.state.selectedInput}
            closeServiceNotes={e => this.closeServiceNotes()}
            getServiceNote={this.getServiceNote}
          />
        </div>
      </Popup>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    listCareplans: data => dispatch(api_list_careplans(data)),
    addCreateAccountItem: data => dispatch(api_add_create_account_item(data)),
    getCreateAccountLineItem: data =>
      dispatch(api_create_account_line_item(data))
  };
};

const mapStateToProps = state => {
  let selected_appointment =
    state.getDrAppointments.selected_appointment != undefined
      ? state.getDrAppointments.selected_appointment
      : [];
  // let logged_info  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info:[];
  console.log(
    "selected_appointment",
    state.getDrAppointments.getPatientDetailst
  );
  let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : [];
  let selectedPatientDetails;
  if (patientDetails.length > 0) {
    selectedPatientDetails = patientDetails[0];
  } else {
    selectedPatientDetails = "";
  }
  console.log(">>>>>>>>>>>>>>>SELECTED APPOINTMENT", selected_appointment);
  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";

  return {
    selectedPatientDetails,
    logged_info,
    selected_appointment,
    logged_info
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditItem);
//export default finalisevisit;
