
import axios from 'axios';
//Add careplan goal
export const api_add_group = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=messageSetup&action=add_group`, data);
}
//Update careplan goal
export const api_add_group_member = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=messageSetup&action=add_group_member`, data);
}
//Update careplan goal
export const api_remove_group_member = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=messageSetup&action=remove_group_member`, data);
}
export const api_remove_ongroup_delete = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=messageSetup&action=remove_ongroup_member`, data);
}
export const api_update_setting = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=messageSetup&action=update_setting`, data);
}


