import React, { Component } from "react";
import BgridServer from "./grid/bgridServer";
import Addform from "./form/add_form";
import Editform from "./form/edit_form";

class Grid extends Component {
  constructor(props) {
    super(props);
    this.handleButton = this.handleButton.bind(this);
    this.showeditform = this.showeditform.bind(this);
    this.backtogrid = this.backtogrid.bind(this);

  }
  state = {
    grid: true,
    edit: false,
    select: false,

    formData : {}
  };

  backtogrid = () => {
    this.setState({
      edit: false,
      select: false,
      grid : true,
    });
  }

  showeditform = (formData) => {
    this.setState({
      edit: true,
      grid : false,
      formData : formData
    });
  }

  handleButton = (selectedId, todo) => {
    //this.props.handleButton(selectedId, todo);
    alert(1);
  };

  onItemClick = () => {
    this.setState({
      grid: !this.state.grid
    });
  };

  onEditClick = () => {
  
    this.setState({
      edit: false,
      grid: true
    });
     
  };

  
  
  render() {
    const { grid, edit } = this.state;
    //const {grid} = this.state;
    return (
      <div>

<div className = {"row"}>
<div style={{right:'-3%'}} className={"col-xs-2 pull-right m-b-sm"} >
			
		
        {!edit && this.props.crud.add_form.isVisible && (
          <button
            className={"btn btn-primary  m-b-sm"}
            onClick={this.onItemClick}
          >
            
            {grid ? <span><i className={"fa fa-plus"}></i> Add </span> : "Show List"}
          </button>
        )}
        {edit &&(
          <button className={"btn btn-success"} onClick={this.onEditClick}>
            
            {edit ? "View Grid" : ""}
          </button>
        )}
       
</div></div>
        {edit && !grid && this.props.crud.add_form.isVisible ? (
          <div>
            <Editform backtogrid={this.backtogrid} formData={this.state.formData} form={this.props.crud.edit_form} />
          </div>
          ) : null}
        {!edit && !grid && this.props.crud.add_form.isVisible ? (
          <Addform backtogrid={this.backtogrid} form={this.props.crud.add_form} />
        ) : null}
        {!edit && grid && this.props.crud.viewgrid.isVisible ? (
          <BgridServer
            showeditform = {this.showeditform}
            handleButton={this.handleButton}
            viewgrid={this.props.crud.viewgrid}
            edit_form={this.props.crud.edit_form}
            delete={this.props.crud.delete}
          />
        ) : null}



      </div>

     

      
    );
  }
  
}

export default Grid;
