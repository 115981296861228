import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Showhelp from './../showHelp';
import {api_get_contact,api_delete_contact} from './../../DS/DS.Contact';
import NewContact from './../patientHistory/Correspondence/newContact';
import EditContact from './../patientHistory/Correspondence/editContact';
import { data } from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import { api_contact_category} from './../../DS/DS.Correspondence';

class enlistContact extends Component {
    constructor(props)
    {
      super(props);
      this.state={
        open:this.props.open,
        holder_list:[],
        selectedindex:-1,
        openEdit:false,
        key:0,
        selectedPatient:0,
        openContact:false,
        openAddPatient:false,
        searchedtext:'',
        openAdd:false,
        incl:'All',
        category:'All',
        serachfor:'',
        postcode:'',
        suburb:'',
        showmodal:false,
        showeditmodal:false,
        selectedInLength:-1,
        contact_cat:[]
      }
    }



    
    componentWillMount=()=>
    {
        console.log('&&&&&&&&&&&&&&&&&&&&',this.props)
        this.props.getContactCategory().then(res => {
            this.setState({
                contact_cat: res.data.content ,
            })}
        
        )

    }
    searchText=(valfor,val)=>
    {
        /*if(valfor=='searchfor')
        {
            this.setState({searchedtext:val})
        }
        if(valfor=='postcode')
        {
            this.setState({postcode:val})
        }
        if(valfor=='suburb')
        {
            this.setState({suburb:val})
        }*/
        this.setState({key:Math.random()})
     //   this.setState({searchedtext:val})
        const searchData={
            searchText:this.state.searchedtext,
            postcode:this.state.postcode,
            suburb:this.state.suburb,
            incl:this.state.incl,
            category:this.state.category
        }
     this.datafound(searchData)  
    }
    datafound=(val)=>
    {
        this.props.getContact(val).then(res=>{
            if(res.data.content!='')
            {
                this.setState({holder_list:res.data.content,key:Math.random()})
            }
        })
    }
    updateInGrid=()=>
    {
        this.setState({key:Math.random(),holder_list:[]}) 
       this.searchText('','');
    }
    setTrClass = (id) => {
        let classs = "";
        classs +=
          id == this.state.selectedindex
            ? " selectedBg "
            : "";
        return classs;
      };
      showSelectedContent(e, item, i) {
          console.log(item)
        this.setState({selectedindex:i,selectedPatient:item.contact_id,selectedInLength:1})
      }
      closePatientDetails=()=>
      {
          this.setState({showmodal:false,key:Math.random(),showeditmodal:false,openAddPatient:false,openAdd:false})
          this.searchText(this.state.searchText)
      }
      checkRecord=(checkfor)=>
      {
        if(this.state.selectedindex==-1)
        {
            alert("Please select a record to proceed")
        }
        else
        {
            if(checkfor=='view')
            {
                this.setState({openEdit:true})
            }
            if(checkfor=='contact')
            {
                this.setState({openContact:true})
            }
            if(checkfor=='add')
            {
                this.setState({openAddPatient:true})
            }
        }
      }
      deleteRecord=()=>
      {

        if (this.state.selectedInLength> 0) {
            confirmAlert({
              title: '',
              message: 'You are about to delete contact record. Do you want to proceed ? ',
              buttons: [
              {
                  label: 'Yes',
                  onClick: () => {
                    this.props.deletedHolder({id:this.state.selectedPatient}).then(res=>
                        this.updateInGrid(),
                        NotificationManager.success(
                        'Contact deleted successfully',
                  //this.changeKey()
                  )
                );
              },
          },
          {
            label: 'No',
            onClick: () => { this.updateInGrid()},
          },
        ],
      });
    } else {
      NotificationManager.error('Please select record(s) to be deleted');
    }
    
         
      }
    render(){
        return(
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                        <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px', fontSize:'16px',marginBottom:'0px'}}>Contacts <Showhelp gotohelp="contacts_help"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.closeAll()} style={{color:'black'}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ fontSize: "14px",overflow: 'scroll',height:'850px' }}>
                        <div className="row">
                            <div className="col-sm-12 c">
	                            <div className="card" style={{paddingLeft: '0px',border:'1px solid #ccc'}}>
		                            <div className="card-block">
		                                    
                                        <section class="content">
                                            <div class="row">
                                             <div class="col-md-12 search-div" style={{paddingBottom:'5px'}}>
                                                <div class="col-md-3 col-sm-3 col-xs-12">
                                                    <label >Search for</label>
                                                    <input type="text" id="search" onChange={(e)=>{this.setState({searchedtext:e.target.value});this.searchText('searchfor',e.target.value)}}/>
                                                </div>
                                                <div class="col-md-2 col-sm-2 col-xs-12">
                                                    <label >Post code</label>
                                                    <input type="text" id="search" onChange={(e)=>{this.setState({postcode:e.target.value});this.searchText('postcode',e.target.value)}}/>
                                                </div>
                                                <div class="col-md-2 col-sm-2 col-xs-12">
                                                    <label >Suburb</label>
                                                    <input type="text" id="search" onChange={(e)=>{this.setState({suburb:e.target.value});this.searchText('suburb',e.target.value)}}/>
                                                </div>  
                                                <div class="col-md-2 col-sm-2 col-xs-12">
                                                    <label >Include</label>
                                                   <select onChange={(e)=>{this.setState({incl:e.target.value});this.searchText('','')}} >
                                                       <option value="All">All</option>
                                                       <option value="Indi">Individual</option>
                                                       <option value="Cop">Companies/Institutions</option>
                                                   </select>
                                                </div>      
                                                <div class="col-md-2 col-sm-2 col-xs-12">
                                                    <label >Category</label>
                                                   <select onChange={(e)=>{this.setState({incl:e.target.value});this.searchText('','')}}>
                                                       <option value="All">All</option>
                                                       {this.state.contact_cat != undefined && this.state.contact_cat.length> 0 && this.state.contact_cat.map((cntcat) => <option key={cntcat.category_id} value={cntcat.contact_name}>{cntcat.contact_name}</option>)}

                                                   </select>
                                                </div>                                               
                                            </div>
                                            <div class="col-xs-12">
                                                <div class="box">
                                                    <div class="box-body">
                                                        <div class="table-div" style={{overFlowY:'auto',height:'45vh'}}>
                                                        <table id="example1" class="table table-bordered table-striped tableFixHead">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Contact name</th>
                                                                        <th>Category</th>
                                                                        <th>Comment</th>
                                                                        <th>Provider no.</th>
                                                                        <th>Address</th>
                                                                        <th>City</th>
                                                                        <th>Phone</th>
                                                                      </tr>
                                                                </thead>
                                                                <tbody id="table-body">
                                                                    {this.state.holder_list!=undefined && this.state.holder_list.map((item,i)=>(
                                                                        <tr  onClick={e =>
                                                                            this.showSelectedContent(e, item, i) }
                                                                            className={this.setTrClass(i)} >
                                                                        <td>{item.first_name} {' '} {item.surname}</td>
                                                                        <td>{item.contact_name}</td>
                                                                        <td>{item.comment}</td>
                                                                        <td>{item.provider_no}</td>
                                                                        <td>{item.addressLine1}{' '}{item.postcode}</td>
                                                                        <td>{item.city}</td>
                                                                        <td>{item.Phone}</td>
                                                                       </tr>    
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <hr/>
                                                        <div class="bottom-buttons" style={{marginTop:'10px'}}>
                                                            <div class="col-md-6 col-sm-7">
                                                            </div>
                                                            <div class="col-md-6 col-sm-5 col-xs-12 text-right">
                                                                <button type="button" class="btn btn-primary" onClick={(e)=>this.setState({showmodal:true})}>Add new</button>
                                                                <button type="button" class="btn btn-primary" onClick={()=>this.setState({showeditmodal:true})}>Add at address</button>
                                                                <button type="button" class="btn btn-primary" onClick={()=>this.setState({showeditmodal:true})}>Edit</button>
                                                                <button type="button" class="btn btn-primary" onClick={()=>this.deleteRecord()}>Delete</button>
                                                            </div>
                                                        </div>
                                                        { (this.state.showmodal)    ?
                                                                 <NewContact  showmodal={this.state.showmodal} changeKey={this.closePatientDetails} usecontact='yes'/>
                                                        :''}
                                                        { (this.state.showeditmodal)    ?
                                                         <EditContact  showmodal={this.state.showeditmodal} changeKey={this.closePatientDetails} cid={this.state.selectedPatient}/>
                                                        :''}
                                                       
                                                       
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                 </section>
                            </div>
	                    </div>
                    </div>
                </div>
            </div>
        </div>
        )}
        </Popup>
    )}           
}
const mapDispatchToProps = (dispatch) => {
	return {
        getContact: (data) => dispatch(api_get_contact(data)),
        deletedHolder :(data) =>dispatch(api_delete_contact(data)),
        getContactCategory:()=>dispatch(api_contact_category()),

     
  }
}
const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details:'';
  
  return {
    redux_patientId,redux_docId
  };
  };
export default connect (mapStateToProps, mapDispatchToProps) (enlistContact);