import axios from 'axios';
 

export const api_loadhelplink = data => dispatch => {
  //first

  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=general&action=load_help_link`,
    data
  );

};
