import axios from 'axios';

export const PATIENTS_INVESTIGATION = 'PATIENTS_INVESTIGATION';

export const getPatientsObservations = patientsInvestigations_data => ({
  type: PATIENTS_INVESTIGATION,
  patientsInvestigations_data,
});

export const api_patient_investigations = data => dispatch => {
  //first

  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=investigation&action=patients_investigations`,
    data
  );
  /*
      .then(res => res.data.content)
      .then(data => {
        console.log(data);  
        dispatch(getPatientsObservations(data))});
  */
};
export const api_modify_investigations = data => dispatch => {
  //first

  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=investigation&action=api_modify_investigations`,
    data
  );
  /*
      .then(res => res.data.content)
      .then(data => {
        console.log(data);  
        dispatch(getPatientsObservations(data))});
  */
};
export const api_list_options = data => dispatch => {
  //first

  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=investigation&action=list_options`,
    data
  );
 
};
export const api_loadSelectedInvestigation = data => dispatch => {
  //first

  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=investigation&action=load_selected_investigation`,
    data
  );
 
};

export const api_addInvestigation = data => dispatch => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=investigation&action=add_patients_investigations`,
      data
    )
    .then(res => {
      dispatch(api_patient_investigations());
    });
};
export const api_get_type = data => dispatch => {return axios.post(`${process.env.REACT_APP_BASE_URL}api=investigation&action=get_type`,data);};

export const api_change_status = data =>dispatch=>{
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=investigation&action=change_status`,
    data
  );
}


