import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
import Footer from "../../footer";
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {api_add_template,api_get_template,api_update_template} from '../../../DS/DS.Template';
class Addtemplates extends Component {
  constructor(props) {
    super(props);
    this.state={
      open:this.props.open,
      showVariable:[],
      selectedType:"",
      selectedindex:"",
      tempname:'',
      showText:'',
      key:0,
      selectedText:'',
      disableText:false,
      hasdef:0,
      hasedit:0,
      hasreply:0
    }
  }
  componentWillMount=()=>
  {
      if(this.props.ids!='')
      {
          this.props.getData({id:this.props.ids}).then(res=>
            {
                this.setState({
                    selectedType:res.data.content[0].template_type,
                    tempname:res.data.content[0].template_name,
                    showText:res.data.content[0].template_format,
                    hasdef:res.data.content[0].set_default,
                    hasedit:res.data.content[0].edit_text,
                    hasreply:res.data.content[0].expect_reply
                })
                this.setVariable(res.data.content[0].template_type,1)
              
            })
           
      }
  }
  setVariable=(val,clr)=>
  {
      if(clr=='')
      {
        this.setState({showText:''})
      }
    this.setState({selectedType:val})
    if(val==1)
    {
        this.setState({
            showVariable:["<PtSurname>","<PtFirstName>","<PtPreferredName>","<PtFullName>","<ApptDate>","<ApptTime24>","<ApptDate>","<DrName>","<ApptType>","<ApptLocation>","<DayOfWeek>"]
        ,
        selectedText:'Appointment'
        })
    }
    if(val==2)
    {
        this.setState({
            showVariable:["<PtSurname>","<PtFirstName>","<PtPreferredName>","<PtFullName>","<DrName>","<ReminderReason>","<UserPhone>","<Practice>","<UserLocation>"    ]
            ,
            selectedText:'Clinical Reminder',
            disableText:true,
            showText:'Do not reply'
        })
    }
    if(val==3)
    {
        this.setState({
            showVariable:["<PtSurname>","<PtFirstName>","<PtPreferredName>","<PtFullName>","<UserPhone>","<Practice>","<UserName>","<UserLocation>"    ]
            ,
            selectedText:'Patient Record',
            disableText:true,
            showText:'Do not reply'
        })
    }
    if(val==4)
    {
        this.setState({
            showVariable:["<PtSurname>","<PtFirstName>","<PtPreferredName>","<PtFullName>","<DrName>","<TestName>","<DateOfResult>","<Classification>","<Action>","<Comment>","<UserPhone>","<UserLocation>"]
            ,
            selectedText:'Result',
            disableText:true,
            showText:'Do not reply'
        })
    }
    if(val==5)
    {
        this.setState({
            showVariable:["<PtSurname>","<PtFirstName>","<PtPreferredName>","<PtFullName>","<UserPhone>","<Practice>","<UserName>","<UserLocation>","<Output>"]
            ,
            selectedText:'Search Utility',
            disableText:true,
            showText:'Do not reply'
        })
    }
    if(val=="")
    {
        this.setState({
            showVariable:[]
        })
    }
  }
  setTrClass = (id) => {
    let classs = "";
    classs +=
      id == this.state.selectedindex
        ? " selectedBg "
        : "";
    return classs;
  };
  showSelectedContent(e, i) {
    this.setState({selectedindex: i });
   
  }
  onSelection=(id)=>
  {
      const val=this.state.showText + this.state.showVariable[id];
      this.setState({showText:val})
      this.setState({key:Math.random()})
     
  }
  saveTemplate=()=>
  {
      const values={
        tempname:this.state.tempname,
        temptype:this.state.selectedType,
        tempformat:this.state.showText,
        temptext:this.state.selectedText,
        def:this.state.hasdef,
        hasedit:this.state.hasedit,
        expectrep:this.state.hasreply
      }
    this.props.saveTemplate(values).then(res=>
        {
            NotificationManager.success('Template Created')
           this.props.closeall()
        })
  }
  updateTemplate=()=>
  {
      const values={
        tempname:this.state.tempname,
        temptype:this.state.selectedType,
        tempformat:this.state.showText,
        temptext:this.state.selectedText,
        id:this.props.ids,
        def:this.state.hasdef,
        hasedit:this.state.hasedit,
        expectrep:this.state.hasreply
      }
    this.props.updateTemplate(values).then(res=>
        {
            NotificationManager.success('Template Created')
            this.props.closeall()
        })
  }
  setcheckbox=(val,valfor)=>
  {
      if(valfor=='def' && val==true)
      {
        this.setState({hasdef:1})
      }
      else if(valfor=='def' && val==false)
      {
        this.setState({hasdef:0})
      }

      if(valfor=='edit' && val==true)
      {
        this.setState({hasedit:1})
      }
      else if(valfor=='edit' && val==false)
      {
        this.setState({hasedit:0})
      }

      if(valfor=='reply' && val==true)
      {
        this.setState({hasreply:1})
      }
      else if(valfor=='reply' && val==false)
      {
        this.setState({hasreply:0})
      }
  }
  render() {
    return (
        <Popup
        open={this.state.open}
        closeOnDocumentClick={false}
         position="right center"   modal
       >
         {close => (
             <div>
                <div className="modal-header">
                    <span className="modal-title-2" id="exampleModalLabel"> Add template  </span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.closeall()}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                 <div className="row">
                  <div className="col-xs-12">
                      <div className="box">
                          <div className="col-xs-12" style={{margin:'0px auto', float:'none'}}>
                              <div className="box-body">
                                  <div className="col-sm-12 col-md-12 col-xl-12">
                                      <div id="pageContent">
                                            <div className="form-group row m-b-10">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <div className="col-sm-2"> 
                                                            <label> Name : </label>
                                                        </div>
                                                        <div className="col-sm-10 cursor">
                                                            <form>
                                                                <input type="text" name="text" className="form-control" value={this.state.tempname} onChange={(e)=>this.setState({tempname:e.target.value})}/>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <div className="col-sm-2"> 
                                                            <label> Type : </label>
                                                        </div>
                                                        <div className="col-sm-10 cursor">
                                                            <select className="dropdown-box" onChange={(e)=>this.setVariable(e.target.value,'')} value={this.state.selectedType}>
                                                                <option value=""> </option>
                                                                <option value="1"> Appointment</option>
                                                                <option value="2"> Clinical Reminder </option>
                                                                <option value="3"> Patient Record</option>
                                                                <option value="4"> Result </option>
                                                                <option value="5"> Search Utility </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row m-t-10">  
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <textarea id="accute" className="contact-mapping-textarea textarea3" placeholder=""  value= {this.state.showText} style={{width:'385px',height: '300px'}}> 
                                                       
                                                        </textarea>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <div className="col-sm-2"> 
                                                            <button type="button" className="btn btn-default left-arrow-btn" data-dismiss="modal" onClick={()=>this.onSelection(this.state.selectedindex)}> 
                                                                <i className="fa fa-chevron-left"> </i> <i className="fa fa-chevron-left"> </i> </button>
                                                        </div>
                                                        <div className="col-sm-10 cursor">
                                                            <div className="invoicenotes2-body"> 
                                                            <div className="box" style={{border:'1px solid'}}>
                                                            {this.state.showVariable != undefined && this.state.showVariable.length> 0 && this.state.showVariable.map((vari,i) =>
                                                                <label  onClick={e =>this.showSelectedContent(e, i) } className={this.setTrClass(i)} >{vari}</label>
                                                            )}   
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row m-t-10">  
                                                <div className="col-sm-4">
                                                    <div className="border-checkbox-section">
                                                        <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox3">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox3"
                                                            onChange={(e)=>this.setcheckbox(e.target.checked,'def')}
                                                            checked={this.state.hasdef==1 ? 'checked' :''}/>
                                                             &nbsp; Set as default template for this template type</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="border-checkbox-section">
                                                        <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label" for="checkbox4">
                                                            <input className="border-checkbox" type="checkbox" id="checkbox4"
                                                             onChange={(e)=>this.setcheckbox(e.target.checked,'edit')}
                                                             checked={this.state.hasedit==1 ? 'checked' :''}/>
                                                            &nbsp; Template text can be edited before sending</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="border-checkbox-section">
                                                        <div className="border-checkbox-group border-checkbox-group-default">
                                                            <label className="border-checkbox-label" for="checkbox5">
                                                                <input className="border-checkbox" type="checkbox" id="checkbox5" disabled={this.state.disableText}
                                                                 onChange={(e)=>this.setcheckbox(e.target.checked,'reply')}
                                                                 checked={this.state.hasreply==1 ? 'checked' :''}/>
                                                                 &nbsp; Appointment template experts a reply </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="row">
                    <div className="col-sm-10"> </div>
                          {this.props.ids=='' ?
                            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>this.saveTemplate()}>Save</button>
                          :
                          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>this.updateTemplate()}>Save</button>
                                                            }  
                            <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.closeall()}>Cancel</button> 
                    </div>
                </div>
            </div>
                
            )}
      </Popup>
    )}
   }  
   const mapDispatchToProps = dispatch => {
    return {
        saveTemplate: data => dispatch(api_add_template(data)),
        getData:data=>dispatch(api_get_template(data)),
        updateTemplate: data => dispatch(api_update_template(data)),
       };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };  
export default connect(mapStateToProps,mapDispatchToProps)(Addtemplates);