
import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {api_get_contact_cleanup} from '../../../DS/DS.ListPage';
import AddContactType from './contactType';
class CategoryCleanUp extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        reason_name:'',
        errors:[],
        openContactType:false,
        key:0,
        all_contacts:[],
        all_categories:[]
       
    }
  }
  componentWillMount=()=>
  {
      const getData={hasuser:'1'}
        this.props.listContactCleanUp(getData).then(
            res=>{
                this.setState({all_contacts:res.data.content})
            }
        )
        const getData1={hasuser:'0'}
        this.props.listContactCleanUp(getData1).then(
            res=>{
                this.setState({all_categories:res.data.content})
            }
        )
  }
  handleValidation(){
    let errors = {};
    let formIsValid = true;
  
    //Reason name
    if(!this.state.reason_name){
       formIsValid = false;
       errors["reason_name"] = "Cannot be empty";
    }
     this.setState({errors: errors});
    return formIsValid;
  }
  onClickHandler=(e)=>
  {
    
  } 
reloadPage=()=>
{
    this.setState({openContactType:false});
    this.setState({key:Math.random()})
}
 render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
            <div>

                <div className="modal-header">
                    <span className="modal-title-2" id="exampleModalLabel"> Clean up categories  </span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={()=>this.props.reloadPage()}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body" style={{height:'400px', overflowY:'scroll'}}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card clean-up-categories-inner-table" style={{padding:'15px'}}>
                                <div className="card-block" style={{border:'1px solid #ddd'}}>
                                    <table className="calendar table table-bordered tablestyle">
                                        <thead>
                                            <tr>
                                                <th>&nbsp; <span style={{fontSize:'16px'}}> Contacts  </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.all_contacts!=undefined && this.state.all_contacts !=='' && this.state.all_contacts.map((contactitem,i) => (
                                                <tr>
                                                     <td>&nbsp;{contactitem.contact_name}</td>
                                                 </tr>
                                            ))}
                                           
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card clean-up-categories-inner-table" style={{padding:'15px'}}>
                                <div className="card-block" style={{border:'1px solid #ddd'}}>
                                    <table className="calendar table table-bordered tablestyle">
                                        <thead>
                                            <tr>
                                                <th>&nbsp;  <span style={{fontSize:'16px'}}>Categories </span>
                                               
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.all_categories!=undefined && this.state.all_categories !=='' && this.state.all_categories.map((contactCategory,i) => (
                                                <tr>
                                                     <td>&nbsp;{contactCategory.contact_name}</td>
                                                 </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-sm-8"> </div>
                            <div className="col-sm-4">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Change</button>
                                <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close"  onClick={()=>this.props.reloadPage()}>Close</button> 
                            </div>
                        </div>
                    </div>
        </div>
        )}
        </Popup>
    )}}

const mapDispatchToProps = dispatch => {
    return {
        listContactCleanUp: data => dispatch(api_get_contact_cleanup(data)),
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(CategoryCleanUp);
