import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager} from 'react-notifications';
import {api_list_reason} from '../../../DS/DS.ListPage';
import {api_add_layout,api_update_layout} from '../../../DS/DS.AppointmentSettings';
import CommonGrid from "../../crudcomponentV2/combinedcrud";

class AddLayout extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        layoutName:'',
        errors:[]
            }
  }
  componentWillMount=()=>
  {
    if(this.props.popFor=='Edit')
    {
        const getData={tbl:'appointment_layout_tb',pk:'layout_id',ids:this.props.ids}
        this.props.listLayoutMember(getData).then(
            res=>{
                this.setState({layoutName:res.data.content.layout_name})
            }
        )
        
    }
  }
  onClickHandler=()=>
  {
      if(this.handleValidation()){
        const layout={layoutname:this.state.layoutName}
        this.props.apiaddlayout(layout).then(
        req=>{
                    
            if(req.data.status=="Duplicate"){
                     
              NotificationManager.error('Duplicate layout cannot be created',)
         }
         else if(req.data.status=="Success")
         {
            
              NotificationManager.success('New layout created',)
         }
         else{
             
             NotificationManager.error('Error encountered while creating layput',)
         }
        this.props.reloadKey()
        }     
        )
      }

  }
  handleValidation(){
    let errors = {};
    let formIsValid = true;
  
    //Description name
    if(this.state.layoutName==''){
       formIsValid = false;
       errors["layoutName"] = "Cannot be empty";
    }
    this.setState({errors: errors});
    return formIsValid;
  }
  updateRecord=()=>
  {
    if(this.handleValidation()){
        const layout={layoutname:this.state.layoutName,ids:this.props.ids}
        this.props.apiupdatelayout(layout).then(
        req=>{
                    
            if(req.data.status=="Duplicate"){
                     
              NotificationManager.error('Duplicate layout cannot be created',)
         }
         else if(req.data.status=="Success")
         {
            
              NotificationManager.success('Layout updated successfully',)
         }
         else{
             
             NotificationManager.error('Error encountered while updating layput',)
         }
        this.props.reloadKey()
        }     
        )
      }

  }
  render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
          
         
        {close => (
            <div>
                <div className="modal-header">
                    <span className="modal-title-2" id="exampleModalLabel"> Appointment Book layout  </span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.reloadKey()}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row row-distance">
                        <div className="col-sm-12"> 
                        <label style={{margin:'15px 0 0 10px'}}> Enter a name for this layout : </label>
                        </div>								
                    </div>
                    <div className="row row-distance">
                        <div className="col-sm-12">
                            <div className="form-group">                                                   
                                <input type="text" name="text" className="form-control" value={this.state.layoutName}
                                onChange={(e)=>this.setState({layoutName:e.target.value})}/>
                                 <span className="error" style={{color:'red'}}>{this.state.errors["layoutName"]}</span>
                                   
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-sm-5"> </div>
                        <div className="col-sm-7">
                        {this.props.popFor=='Add' 
                         ?          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>this.onClickHandler()}>Save</button>
                   : <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>this.updateRecord()}>Save</button>}
                            <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.reloadKey()}>Cancel</button> 
                        </div>
                    </div>
                </div>
            </div>
        )}
       </Popup>
    )}}

    const mapDispatchToProps = dispatch => {
        return {
            apiaddlayout:data =>dispatch(api_add_layout(data)),
            listLayoutMember:data=>dispatch(api_list_reason(data)),
            apiupdatelayout:data=>dispatch(api_update_layout(data))
         };
      };
      const mapStateToProps = state => {
      
        let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
        
        return {
            redux_userId
        };
      };
      
      
      export default connect(mapStateToProps,mapDispatchToProps)(AddLayout);
  