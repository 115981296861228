import React, { Component } from "react";
import Popup from "reactjs-popup";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import Pathology from "./pathology";
import Copies from "./copies";
import Previousrequest from "./previousrequest";
import Previousresult from "./previousresult";
import Setupfav from "./setupfav";
import {
  api_list_tests,
  api_list_group,
  api_save_pathology,
  api_save_pathology2,
  api_deleteTest,
  api_deleteGroup,
  api_deleteLab,
  api_previous_requests,
  api_addnewtest,
  api_toggleFav,
  api_list_options2,
  api_addnewgroup
} from "./../../../../DS/DS.VisitsNotes";
import { connect } from "react-redux";
import Showhelp from './../../../showHelp';

class pathologyPop extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",

      request_date: new Date(),
      pathalogy_tests: [],
      previous_tests: [],
      test_groups: [],

      selected_tests_content: "",
      fasting_or_nonfasting: "",
      search_text: "",
      group_names: "",
      test_names: "",
      group_status: "",
      add_entry_to_database: "",
      due_on: new Date(),
      private: "",
      concession: "",
      direct_bill: "",
      selected_tests: [],
      type: this.props.type,
      newTest: "",
      newGroup: "",
      provider: "",
      list_options: {},

      copies: "",
      send_copies_to: "",
      copies_search: "",
      copies_category: "",
      copies_textarea: "",

      previous_requests: [],
      previous_requests_add_entry_to_database: "",
      previous_requests_pathalogy_due_on: "",

      add_new_test: "",
      patient_id: this.props.redux_patientId,
      patient: this.props.redux_patient,

      newLabName: "",
      newLabAddress: "",
      newLabEmail: "",
      newLabContactNo: "",
      newLabFax: "",

      selectedContentinPreview: [],
      open:this.props.showNote
    };
  }

  selectedContentinPreview = (item) => {
    this.setState({ selectedContentinPreview: item });
  };

  Clear = () => {
    this.setState({copies: ""});
  }
  optionitems = (test_name) => {
    const options = this.state.selected_tests;
    let index = options.indexOf(test_name);
    let checked = false;
    if (index > 0) {
      checked = true;
    }
    return checked;
  };
  handleDate = (date) => {
    this.setState({ request_date: date });
  };
  copiestext = (emailtext) => {
    this.setState({ copies: emailtext });
  };
  handleFieldChange = (key, value) => {
    this.setState({ [key]: value });
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.redux_patientId !== this.props.redux_patientId) {
      this.setState({ patient_id: nextProps.redux_patientId });
      this.setState({ patient: nextProps.redux_patient });
    }
  }
  handleCheckboxSelectedGroup = () => {
    alert("Group selected");
  }
  copytopatient = () => {
    console.log("selectedpatient", this.state.patient);
    let copies = this.state.copies;
    this.setState({copies: copies+"\n"+ this.state.patient.Firstname + "\n"+ this.state.patient.Address1+ "\n"+this.state.patient.City+ "\n"});
  }
  updateSelectedTests = (e) => {
    let newText = e.target.value;
    this.setState({ selected_tests_content: newText });
  };
  handleCheckboxSelectedTests = (e, item) => {
    //console.log(item);
    const options = this.state.selected_tests;
    const options_content = "";
    let index;

    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      options.push(item.test_name);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = options.indexOf(item.test_name);
      options.splice(index, 1);
    }

    // sort the array
    options.sort();
    let selected_tests_content = "";
    if (options.length > 0) {
      selected_tests_content = options.join(", ");
    }

    console.log(item, options, selected_tests_content);
    // update the state with the new array of options
    this.setState({
      selected_tests: options,
      selected_tests_content: selected_tests_content,
    });
  };
  handleDate2 = (date) => {
    this.setState({ due_on: date });
  };

  toggleFav = (id, fav_type) => {
    console.log(fav_type);
    this.props.toggleFav({ id: id, fav_type: fav_type }).then(() => {
      // alert("Selected Test Deleted");
    });
    this.listtest();
  };
  deleteTest = (id) => {
    this.props.deleteTest({ id: id }).then(() => {
      alert("Selected Test Deleted");
    });
    this.listtest();
  };
  deleteGroup = (id) => {
    this.props.deleteGroup({ id: id }).then(() => {
      alert("Selected Test Deleted");
    });
    this.listgroup();
  };
  deleteLab = (id) => {
    this.props.deleteLab({ id: id }).then(() => {
      alert("Selected Lab Deleted");
    });
    this.list_options();
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  handleAddText = (e) => {
    console.log(e.target.value);
    this.setState({ newTest: e.target.value });
  };

  handleAddGroup = (e) => {
    console.log(e.target.value);
    this.setState({ newGroup: e.target.value });
  };

  addnewgroup = () => {
    console.log("adding new text");
    this.props
      .addnewgroup({ group_name: this.state.newGroup, type: this.props.type })
      .then(() => {
        this.setState({ newGroup: "" });
        this.listgroup();
      });
  };

  addnewtest = () => {
    console.log("adding new text");
    this.props
      .addnewtest({ test_name: this.state.newTest, type: this.props.type })
      .then(() => {
        this.setState({ newTest: "" });
        this.listtest();
      });
  };


  listgroup = () => {
    this.props.list_groups({doctor_id: this.state.doctor_id}).then((res) => {
      
       

      this.setState({ test_groups: res.data.content });
    });
  };

  listtest = () => {
    this.props.list_tests({}).then((res) => {
      let pathology_tests_only = [];
      if(res.data.content!=undefined)
      {
      for (let i = 0; i < res.data.content.length; i++) {
        if (res.data.content[i].type == this.props.type) {
          pathology_tests_only.push(res.data.content[i]);
        }
      }
    }
      this.setState({ pathalogy_tests: pathology_tests_only });
    });
  };

  list_options = () => {
    this.props.list_options().then((res) => {
      //   console.log("addPatientForm addPatientForm",res);
      this.setState({ list_options: res.data.content });
      // console.log(this.state.list_options);
    });
  };

  componentDidMount() {
    this.list_options();

    console.clear();
    this.listtest();

    this.props
      .previous_requests({
        patient_id: this.state.patient_id,
        type: this.state.type,
      })
      .then((res) => {
        this.setState({ previous_requests: res.data.content });
      });
  }
  optionitems = () => {};
  savePathology = (savetype) => {
    //  alert(savetype);
    if (
      this.state.fasting_or_nonfasting != "" &&
      this.state.selected_tests_content != "" &&
      this.state.provider != "" &&
      savetype == 1
    ) {
      this.props.savePathology(this.state).then(() => {
        this.listtest();
        alert(" Details saved");
        this.setState({
          selected_tests_content: "",
          provider: "",
          selected_tests: [],
          fasting_or_nonfasting: "",
          copies: "",
        });

        this.props
          .previous_requests({
            patient_id: this.state.patient_id,
            type: this.state.type,
          })
          .then((res) => {
            this.setState({ previous_requests: res.data.content });
          });
      });
    } else {
      if (savetype == 2 && this.state.selectedContentinPreview.length != 0) {
        this.props.savePathology2(this.state).then(() => {
          this.listtest();
          alert(" Details saved");
          this.setState({
            selected_tests_content: "",
            provider: "",
            copies: "",
            selected_tests: [],
            fasting_or_nonfasting: "",
            selectedContentinPreview: [],
          });

          this.props
            .previous_requests({
              patient_id: this.state.patient_id,
              type: this.state.type,
            })
            .then((res) => {
              this.setState({ previous_requests: res.data.content });
            });
        });
      } else {
        alert("Please fill all required fields");
      }
    }
    //this.printCert();
  }
  render(){
    return(
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
          <div  style={{ width:'1000px', backgroundColor:'#FFF', float:'left'}}>
              <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div
                  className="modal-header" style={{border:'0px'}}>
                  <button
                    type="button"
                    className="close"
                    style={{
                      position: "absolute",
                      right: "10px",
                      fontSize: "25px",
                    }}
                    onClick={() => this.props.changeKey()}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>

                  {this.props.type == "pathology" && (
                    <h5 className="col-sm-12 sheadtitle">
                      Pathology <Showhelp gotohelp="pathology_values"/>
                    </h5>
                  )}
                  {this.props.type == "radiology" && (
                    <h5 className="col-sm-12 sheadtitle">
                      Radiology <Showhelp gotohelp="radiology_values"/>
                    </h5>
                  )}
                </div>
              </TabPane>
              </TabContent>
              <div className="modal-body" style={{border:'0px'}}>
                        



            <TabContent activeTab={this.state.activeTab}>
             {/*} <TabPane tabId="1">
                <div
                  className="modal-header">
                  <button
                    type="button"
                    className="close"
                    style={{
                      position: "absolute",
                      right: "10px",
                      fontSize: "25px",
                    }}
                    onClick={() => close()}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>

                  {this.props.type == "pathology" && (
                    <h4 class="popup-title visitpop" style={{float:"left"}}>
                      Pathology
                    </h4>
                  )}
                  {this.props.type == "radiology" && (
                    <h4 class="popup-title visitpop" style={{float:"left"}}>
                      Radiology
                    </h4>
                  )}
                </div>
                  </TabPane>*/}
              <TabPane tabId="2">
                <div
                  className="modal-header" style={{border:'0px'}}>
                  <button
                    type="button"
                    className="close"
                    style={{
                      position: "absolute",
                      right: "10px",
                      fontSize: "25px",
                    }}
                    onClick={() =>this.props.changeKey()}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h5 className="col-sm-12 sheadtitle">
                    Copies
                  </h5>
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div
                  className="modal-header" style={{border:'0px'}}>
                  <button
                    type="button"
                    className="close"
                    style={{
                      position: "absolute",
                      right: "10px",
                      fontSize: "25px",
                    }}
                    onClick={() => this.props.changeKey()}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h5 className="col-sm-12 sheadtitle">
                    Previous Request
                  </h5>
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div
                  className="modal-header" style={{border:'0px'}}>
                  <button
                    type="button"
                    className="close"
                    style={{
                      position: "absolute",
                      right: "10px",
                      fontSize: "25px",
                    }}
                    onClick={() => this.props.changeKey()}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h5 className="col-sm-12 sheadtitle">
                    Previous Result
                  </h5>
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div
                  className="modal-header" style={{border:'0px'}}>
                  <button
                    type="button"
                    className="close"
                    style={{
                      position: "absolute",
                      right: "10px",
                      fontSize: "25px",
                    }}
                    onClick={() => this.props.changeKey()}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h5 class="col-sm-12 sheadtitle">
                    Setup Favourites
                  </h5>
                </div>
              </TabPane>
            </TabContent>
            <Row>
            <div className="col-sm-12">
              <div className="col-sm-1 pl-0" style={{backgroundColor: '#44514E', height: '550px', width: '73px'}}>
                <Nav tabs vertical pills style={{ border: "none" }}>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <a
                        href="#"
                        title="Pathology"
                        data-dismiss="modal"
                        data-toggle="modal"
                        data-target="#Pathologypop"
                        className="collapsed"
                        aria-expanded="false"
                      >
                        <i className="fas fa-microscope" style={{ fontSize: "28px", height: "30px", width: "53px", textAlign: "center", lineHeight: "48px" }} aria-hidden="true" />
                      </a>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      <a
                        href="#"
                        title="Copies"
                        data-dismiss="modal"
                        data-toggle="modal"
                        data-target="#copiespath"
                        className="collapsed"
                        aria-expanded="false"
                      >
                        <i
                          className="fas fa-user-tie"
                          style={{ fontSize: "28px", height: "30px", width: "53px", textAlign: "center", lineHeight: "48px" }} 
                          aria-hidden="true"
                        />
                      </a>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "3",
                      })}
                      onClick={() => {
                        this.toggle("3");
                      }}
                    >
                      <a
                        href="#"
                        title="Previous request"
                        data-dismiss="modal"
                        data-toggle="modal"
                        data-target="#previousrequests"
                        className="collapsed"
                        aria-expanded="false"
                      >
                        <i
                          className="far fa-calendar-alt"
                          style={{ fontSize: "28px", height: "30px", width: "53px", textAlign: "center", lineHeight: "48px" }} 
                          aria-hidden="true"
                        />
                      </a>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "4",
                      })}
                      onClick={() => {
                        this.toggle("4");
                      }}
                    >
                      <a
                        href="#"
                        title="Previous results"
                        data-dismiss="modal"
                        data-toggle="modal"
                        data-target="#previousresults"
                        className="collapsed"
                        aria-expanded="false"
                      >
                        <i
                          className="fas fa-folder-open"
                          style={{ fontSize: "28px", height: "30px", width: "53px", textAlign: "center", lineHeight: "48px" }} 
                          aria-hidden="true"
                        />
                      </a>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "5",
                      })}
                      onClick={() => {
                        this.toggle("5");
                      }}
                    >
                      <a
                        href="#"
                        title="setup favourites"
                        data-dismiss="modal"
                        data-toggle="modal"
                        data-target="#setupfavourites"
                        className="collapsed"
                        aria-expanded="false"
                      >
                        <i
                          className="fas fa-clipboard-list"
                          style={{ fontSize: "28px", height: "30px", width: "53px", textAlign: "center", lineHeight: "48px" }} 
                          aria-hidden="true"
                        />
                      </a>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <div className="col-sm-11">
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Pathology
                      savePathology={this.savePathology}
                      key={this.state}
                      statecontent={this.state}
                      handleDate={this.handleDate}
                      handleDate2={this.handleDate2}
                      type={this.props.type}
                      test_groups = {this.state.test_groups}
                      updateSelectedTests={this.updateSelectedTests}
                      handleCheckboxSelectedTests={
                        this.handleCheckboxSelectedTests
                      }
                      handleCheckboxSelectedGroup={
                        this.handleCheckboxSelectedGroup
                      }
                      handleFieldChange={this.handleFieldChange}
                      optionitems={this.optionitems}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <Copies
                    Clear={this.Clear}
                      statecontent={this.state}
                      type={this.props.type}
                      copies={this.state.copies}
                      copiestext={this.copiestext}
                      copytopatient ={this.copytopatient}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <Previousrequest
                      patient={this.props.redux_patient}
                      statecontent={this.state}
                      type={this.props.type}
                      redux_patient={this.props.redux_patient}
                      selectedContentinPreview={this.selectedContentinPreview}
                      handleDate2={this.handleDate2}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <Previousresult
                      statecontent={this.state}
                      type={this.props.type}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    <Setupfav
                      statecontent={this.state}
                      type={this.props.type}
                      deleteLab={this.deleteLab}
                      deleteTest={this.deleteTest}
                      deleteGroup={this.deleteGroup}
                      handleAddText={this.handleAddText}
                      handleAddGroup={this.handleAddGroup}
                      handleAdd={this.handleAddText}
                      toggleFav={this.toggleFav}
                      addnewtest={this.addnewtest}
                      addnewgroup={this.addnewgroup}
                      list_options={this.list_options}
                    />
                  </TabPane>
                </TabContent>
              </div>
              </div>
            </Row>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                {/* <div className="modal-footer" style={{ marginTop: "30px" }}>
                 
                  <button type="button" className="btn btn-default" style={{marginRight:'2px',width:'70px',height:'35px'}}  data-dismiss="modal" onClick={() => close()} aria-label="Close">Cancel</button>
                </div> */}
              </TabPane>
              <TabPane tabId="2">
                <div className="modal-footer" style={{ marginTop: "30px" }}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      marginRight: "5px",
                      width: "70px",
                      height: "35px",
                    }}
                    onClick={(e) => {
                      this.savePathology(1);
                    }}
                    data-dismiss="modal"
                  >
                    Print
                  </button>
                </div>  
              </TabPane>
              <TabPane tabId="3">
                <div className="modal-footer" style={{ marginTop: "40px" }}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      marginRight: "5px",
                      width: "70px",
                      height: "35px",
                    }}
                    onClick={(e) => {
                      this.savePathology(2);
                    }}
                    data-dismiss="modal"
                  >
                    Print
                  </button>
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div className="modal-footer" style={{ marginTop: "45px" }}>
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      marginRight: "5px",
                      width: "70px",
                      height: "35px",
                    }}
                    onClick={(e) => {
                      this.savePathology();
                    }}
                    data-dismiss="modal"
                  >
                    Print
                  </button> */}
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div className="modal-footer" style={{ marginTop: "5px" }}>
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      marginRight: "5px",
                      width: "70px",
                      height: "35px",
                    }}
                    data-dismiss="modal"
                  >
                    Print
                  </button> */}
                  <button
                    type="button"
                    className="btn btn-primary btn-xs"  data-dismiss="modal"
                    onClick={() => this.props.changeKey()}
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                </div>
              </TabPane>
            </TabContent>

              </div>
          </div>

        )}
        </Popup>

    )}
  
}

const mapStateToProps = (state) => {
  let redux_patientId =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].patient_id
      : "";
  let patient_age =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].age
      : "";
  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";
  let redux_patient =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0]
      : "";
  return {
    redux_patientId,
    redux_patient,
    patient_age,
    logged_info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    list_tests: (data) => dispatch(api_list_tests(data)),
    list_groups: (data) => dispatch(api_list_group(data)),

    savePathology: (data) => dispatch(api_save_pathology(data)),
    savePathology2: (data) => dispatch(api_save_pathology2(data)),
    previous_requests: (data) => dispatch(api_previous_requests(data)),
    deleteTest: (data) => dispatch(api_deleteTest(data)),

    deleteGroup: (data) => dispatch(api_deleteGroup(data)),
    deleteLab: (data) => dispatch(api_deleteLab(data)),
    addnewtest: (data) => dispatch(api_addnewtest(data)),
    
    addnewgroup: (data) => dispatch(api_addnewgroup(data)),
    toggleFav: (data) => dispatch(api_toggleFav(data)),
    list_options: (data) => dispatch(api_list_options2(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(pathologyPop);
