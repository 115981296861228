import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager} from 'react-notifications';
import DatePicker from 'react-datepicker';
import cicon from "../../../../assets/img/cicon.png";
import moment from 'moment';
import InsertViewFile from './insertViewFile';
import SendMail from './sendMail'
import Showhelp from './../../../showHelp';

class MedicalCertificate extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        certificate:1,
        startDate:new Date(),
        dateShow: false,
        endDate:new Date(),
        enddateShow: false,
        pg:'',
        insertmodal:false,
        key:0,
        sendModal:false,
        totaldays:1,  
        diffstart:new Date(),
        diffend:moment(new Date()),
        startChanged:0,
        calendardate: new Date(),
        showSingle:this.props.isSingle,
        singleDate:new Date().getDate()+'/'+(new Date().getMonth()+1)+'/'+new Date().getFullYear()
    }
  }
  componentWillMount=()=>
  {
    //this.setState({singleDate:new Date().getDate()+'/'+(new Date().getMonth()+1)+'/'+new Date().getFullYear()})
   //   alert(new Date().getDate()+'/'+(new Date().getMonth()+1)+'/'+new Date().getFullYear())
      if(this.props.isSingle=="Yes")
      {
        this.setState({certificate:"1"})
      }
  }
  handleDate = date => {
    console.log(">>>>>>>>>>>",date);
    this.setState({
      startDate:date
    });

    if(moment(new Date(date)).format('DD/MM/YYYY') == moment(new Date()).format('DD/MM/YYYY')){

      this.setState({
        dateShow: true,
      });
    }else {
      this.setState({
        dateShow: false,
      });
    }
    this.setState({diffstart: date,startChanged:1})

    console.log(date)
    if(date==null)
    {
      this.setState({startDate:new Date()})
      this.setState({totaldays:1})
    }
    else
    {
      console.log("in else")
    var start =moment(date);
    var end =moment(this.state.endDate);

    if(moment(new Date(this.state.endDate)).format('DD/MM/YYYY') == moment(new Date(date)).format('DD/MM/YYYY')){
      const differ=end.diff(start, "days")
      const total=differ+1;
      this.setState({totaldays:total})
      console.log("if",total)
    }
    else{
      const differ=end.diff(start, "days")
      const total=differ+1
      this.setState({totaldays:total})
      console.log("if",total)
    }
  }
    
  };
  focusTextInput() {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    // this.textInput.current.focus();
    document.getElementById("fromdate").focus();
    // this.textInput.focus();
  }
  handleEndDate = date1 => {
    this.setState({
      endDate:date1
    });
   
    if(moment(new Date(date1)).format('MM/DD/YYYY') == moment(new Date()).format('MM/DD/YYYY')){

      this.setState({
        enddateShow: true,
      });
    }else {
      this.setState({
        enddateShow: false,
      });
    }

    if(date1==null)
    {
      this.setState({endDate:new Date()})
      this.setState({totaldays:1})
    }
    else{
    this.setState({diffend: date1})
    var start =moment(this.state.startDate);
    var end =moment(new Date(date1));

    if(moment(new Date(date1)).format('DD/MM/YYYY') == moment(new Date(this.state.startDate)).format('DD/MM/YYYY')){
      const differ=end.diff(start, "days")
      const total=differ+1;
      this.setState({totaldays:total})
      console.log("if",total)
    }
    else{
      const differ=end.diff(start, "days")
      if(this.state.startChanged==0)
      {
        const total=differ+2
        this.setState({totaldays:total})
      console.log("if",total)
      }
      else
      {
        const total=differ+2
        this.setState({totaldays:total})
      console.log("if",total)
      }
     
      
    }
  }
    
    
  };

  daysChangeHandler=(val)=>
  {
   
    if(val!=0)
    {
      this.state.endDate.setDate(this.state.startDate.getDate()+parseInt(val)-1);
      this.setState({totaldays:val})
    }
    else
    {
     // this.state.endDate.setDate(this.state.startDate)
      //console.log(">>",this.state.startDate)
      this.setState({totaldays:1,endDate:new Date()})
    }
    
  }
  focusToTextInput() {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    // this.textInput.current.focus();
    document.getElementById("todate").focus();
    // this.textInput.focus();
  }
  viewPage=(pagetype)=>
  {
        this.setState({pg:pagetype,insertmodal:true})
  }
  reloadPage=()=>
  {
      this.setState({insertmodal:false,key:Math.random(),sendModal:false})
  }
  handleDate2 = date => {
    this.setState({ calendardate: date });
    
  };
  render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
    {close => (
      <div style={{ width:'100%', margin:"0px auto"}}>	 
        <div style={{ width:'100%', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>	    
            <div className="modal-header" style={{ backgroundCcolor:'#f4f4f4', padding: '5px 15px' }}>
		        
                <button type="button" className="close" style={{position: 'absolute', right: '10px', fontSize: '25px'}} data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}>
                    <span aria-hidden="true">×</span>
                </button>
                <h5 class="col-sm-12 sheadtitle">Medical Certificate <Showhelp gotohelp="medical_certificate_help"/></h5>
            </div>
            <div className="modal-body">
                <div className="col-sm-12">

                            <div className="row mt-3">
                              <div className="col-sm-2 pr-0">
                                <label>From date</label>
                              </div>
                              <div  className="col-sm-5">
                                  {(this.state.showSingle=="Yes") ? <label className="col-form-label ">{this.state.singleDate}</label> :''}
                                { (this.state.showSingle=="No") ? 
                                    <DatePicker
                                  //ref={this.textInput}
                                  ref={input => {
                                    this.textInput = input;
                                  }}
                                  id="fromdate"
                                  inputRef={ref => {
                                    this.textInput = ref;                             
                                  }}
                                  todayButton="Go to Today"
                                  selected={this.state.startDate}
                                  onChange={this.handleDate}
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  showYearDropdown
                                  dateFormatCalendar="MMMM"
                                  dateFormat="dd/MM/yyyy"
                                  yearDropdownItemNumber={15}
                                  scrollableYearDropdown
                                />
                                    : ''}
                                    { (this.state.showSingle=="No") ?   
                                  <label>
                                  <a onClick={this.focusTextInput} href="#" className="dateimg">
                                    {<img src={cicon} />}
                                  </a>
                                </label> : ''}
                                
                              </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-sm-2 pr-0">
                                    <label>To Date:</label>
                                </div>
                                <div className="col-sm-5">
                                {(this.state.showSingle=="Yes") ? <label className="col-form-label ">{this.state.singleDate}</label> :''}
                                { (this.state.showSingle=="No") ? 
                                <DatePicker
                                  //ref={this.textInput}
                                  ref={input => {
                                    this.textInput = input;
                                  }}
                                  id="todate"
                                  inputRef={ref => {
                                    this.textInput = ref;
                                  }}
                                  todayButton="Go to Today"
                                  selected={this.state.endDate}
                                  onChange={this.handleEndDate}
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  showYearDropdown
                                  dateFormatCalendar="MMMM"
                                  dateFormat="dd/MM/yyyy"
                                  yearDropdownItemNumber={15}
                                  scrollableYearDropdown
                                />
:''}
 { (this.state.showSingle=="No") ? 
                                  <label>
                                  <a onClick={this.focusToTextInput} href="#" className="dateimg">
                                    {<img src={cicon} />}
                                  </a>
                                </label>:''}
                              </div>

                                <div className="col-sm-2">
                                { (this.state.showSingle=="No") ? 
                                    <input type="text" value={this.state.totaldays} name="search_condition" className="form-control mt-0" onChange={(e)=>this.daysChangeHandler(e.target.value)}/>
                                    :''}
                                    </div>
                                <div className="col-sm-3 pl-0 mt-2">
                                { (this.state.showSingle=="No") ? 
                                <label className="col-form-label text-left">No.of Day(s)</label> :
                                <label className="col-form-label text-left">No.of Day : 1</label>}
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 pr-0">
                                    <label>Category</label>
                                </div>
                                <div className="col-sm-5">
                                { (this.state.showSingle=="No") ? 
                                    <select  className="form-control mt-0" onChange={(e)=>this.setState({certificate:e.target.value})} value={this.state.certificate}>
                                        <option value="1">Work</option>
                                        <option value="2">School</option>
                                        <option value="3">University</option>
                                    </select> :
                                    <label> Work</label>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
  

            <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" data-toggle="modal" data-target="#mcertificate1" onClick={()=>this.viewPage('view')}>Insert and View</button>
                <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" onClick={()=>this.props.changeKey()}>Cancel</button>
            </div>
            {this.state.insertmodal ?
            <InsertViewFile showModal={this.state.insertmodal} pagetype={this.state.pg} startdate={this.state.startDate} enddate={this.state.endDate} changeKey={this.reloadPage}
            certficate={this.state.certificate}/> :''}
           
        </div>
        </div>
  )}
  </Popup>
    )}
}

const mapDispatchToProps = dispatch => {
    return {
         };
  };
  const mapStateToProps = state => {
  
    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    
    return {
        redux_patientId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(MedicalCertificate);


