import React, { Component } from 'react';
import AddClinicalRecord from './addClinicalRecord';
import EditClinicalRecord from './editClinicalRecord';

import RecordNote from './recordnote';
import CommonGrid from "../../crudcomponentV2/combinedcrud";

import ViewClinicalRecord from './viewClinicalRecord';
import ViewCorrespondenceRecord from './../Correspondence/viewCorrespondenceRecord';
import axios from "axios";
import { connect } from 'react-redux';
import
{
  api_deleteClinical,
} from '../../../DS/DS.Clinical';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import './clinical.css';
import jsPDF from 'jspdf';
class ClinicalImages extends Component
{
  constructor (props) {
    super(props);
    this.deleteClick = this.deleteClick.bind(this);
    this.editClick = this.editClick.bind(this);
    this.onRowClicked = this.onRowClicked.bind(this);
    this.viewImage=this.viewImage.bind(this);
    this.getAllId=this.getAllId.bind(this);

    this.state = {
    selectedRec:'',
    viewEdit:false,
    selectedRecLength:0,
    key:"3",
    showNote:false,
    clickedId:'',
    showImg:false,
    getAllIds:true, 
    getallId:this.getAllId,
    showadd:false,
    crud:"",
    showconfidential:false
   
  };
  
  }

  componentWillMount=()=>
 {
  this.clinical_crud(0);
 }
 handleDeleteCheckbox = e => {
  if (e.target.checked) {
    this.setState({ showconfidential: 1 });
    this.clinical_crud(1);
  } else {
    this.setState({showconfidential: 0 });
    this.clinical_crud(0);
  }
  this.changeKey();
};
clinical_crud = showconfidential => {
  let returnjson = "";
  this.setState({ crud: "" });
 // alert(showdeleted)
  if (showconfidential == 0) {
    let crudWithout= {
      jsonGridVersion: "v2.0",
      grid: true,
      viewgrid: {
        isVisible:true,
        isPagination: true,
        isFiltering: true,
        isSelection:true,
        deleteFunction:this.deleteClick,
        isDateGroup:false,
        isYearSeparator:false,
        dateCol:'image_date',
        groupDateSelection:false,
        cellClicked:'',
        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "clinical_images_tb",
          cols: [
            "clinical_id",
            "description",
            "details",
            "DATE_FORMAT(image_date,'%d-%m-%Y') as image_date",
            
          ],
         where: [
            {
              type: "and",
              key: "patient_id",
              operator: "=",
              value: this.props.redux_patientId
         },
         {
          type: "and",
          key: "isDeleted",
          operator: "=",
          value: 0
        },{
          type: "and",
          key: "isConfidential",
          operator: "=",
          value: 0
        }],
          primary_key:"clinical_id",
        },
        viewtabletitle: "",
        columns: [
          {name: "image_date", title: "Date" },
          {name: "description", title: "Description" },
          {name: "details", title: "Details" }
        
        ],
        rows: [],
        sortinit: [{ columnName: "image_date", direction: "desc" }],
        pagesize: 10,
        getAllIds:false,
        getallId:'',
        highlightprev:false
      }
    }
    returnjson = crudWithout;
    this.setState({ crud: crudWithout });
  }

  if (showconfidential == 1) {
    let crudwith= {
      jsonGridVersion: "v2.0",
      grid: true,
      viewgrid: {
        isVisible:true,
        isPagination: true,
        isFiltering: true,
        isSelection:true,
        deleteFunction:this.deleteClick,
        isDateGroup:false,
        isYearSeparator:false,
        dateCol:'image_date',
        groupDateSelection:false,
        cellClicked:'',
        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "clinical_images_tb",
          cols: [
            "clinical_id",
            "description",
            "details",
            "DATE_FORMAT(image_date,'%d-%m-%Y') as image_date",
            
          ],
         where: [
            {
              type: "and",
              key: "patient_id",
              operator: "=",
              value: this.props.redux_patientId
         },
         {
          type: "and",
          key: "isDeleted",
          operator: "=",
          value: 0
        }
        ,{
          type: "and",
          key: "isConfidential",
          operator: "=",
          value: 1
        }],
          primary_key:"clinical_id",
        },
        viewtabletitle: "",
        columns: [
          {name: "image_date", title: "Date" },
          {name: "description", title: "Description" },
          {name: "details", title: "Details" },
          
        
        ],
        rows: [],
        sortinit: [{ columnName: "image_date", direction: "desc" }],
        pagesize: 10,
        getAllIds:false,
        getallId:'',
        highlightprev:false
      }
    }
    this.setState({ crud: crudwith });

    returnjson = crudwith;
  }

  console.log("pasthistory return json", returnjson, this.state.acute_crud);
  return returnjson;
};
  print=()=>{
    var doc=new jsPDF();
    var docs=document.getElementById('clinical-list-part');
    console.log(docs);
    doc.fromHTML(docs,10,10
                     )
        doc.save("generated.pdf")
  }
  getAllId=(allids)=>
  {
   // console.log(allids);
  }
    deleteClick= (selectedId,action,checklength) => 
    {
      
      if(action=='delete')
      {
          if (this.state.selectedRecLength> 0) {
                const clinicalData={tb_name:"clinical_images_tb",primary_key:'clinical_id',clinical_id:this.state.selectedRec}
                confirmAlert({
                  title: '',
                  message: 'You are about to delete the clinical record. Do you want to proceed?',
                  buttons: [
                  {
                      label: 'Yes',
                      onClick: () => {
                      this.props.apiDeleteClinical(clinicalData).then(
                      res => this.changeKey() ,
                      NotificationManager.success(
                      'Clinical record(s) deleted successfully',
                      //this.changeKey()
                      )
                    );
                  },
              },
              {
                label: 'No',
                onClick: () => { this.changeKey()},
              },
            ],
          });
        } 
        else 
        {
          NotificationManager.error('Please select a record(s) to delete');
        }
      }
      if(action=='selection')
      {
//        console.log("****",selectionindex)
        this.state.selectedRec=selectedId;
        this.state.selectedRecLength=checklength;
      }
    } 
    editClick=(selectedId,action)=>{
      this.setState({viewEdit:false})
      if(action=='edit')
      {
      
        if(this.state.selectedRecLength == 1)
        {
          this.setState({viewEdit:true})
          //return <EditClinicalRecord showform={this.state.viewEdit} selectedid={this.state.selectedId}/>
        }
        else
        {
          alert("Please select single record to edit")
        }
        console.log(this.state.selectedRecLength)
      }
    }
    onRowClicked=(clickedId,action)=>
    {
     // alert(this.state.showNote)
     //this.changeKey();
     if(action=="rowclicked")
      {
        this.setState({showNote:true,clickedId:clickedId})
      }
      if(this.state.selectedRecLength==1 && action=="btnclicked")
      {
        this.setState({showNote:true,clickedId:this.state.selectedRec})
      //  alert(this.state.showNote)
      }
      else
      {
           alert("Please select single record to record a note")
      }
    }
    viewImage=()=>
    {
     //  alert("IN",this.state.selectedRec)
       if(this.state.selectedRecLength==1)
       {
          this.setState({showImg:true,clickedId:this.state.selectedRec})
       }
       else
       {
         alert("Please select a record to view clinical image")
       }
    }
    changeKey=()=>
    {
      this.setState({key:Math.random()})
      this.setState({viewEdit:false})
       this.setState({showNote:false})
       this.setState({showadd:false})
       this.setState({showImg:false})
       this.state.selectedRec='';
       this.state.selectedRecLength=0;
     
    }
    render(){
      var gridstyle = {
      padding: "10px"
    };
        return(
          <div id="notesdiv11" className="col-md-12" style={{paddingLeft: 0, paddingRight: 0}} >
            <div className="panel panel-default mb-0" >
    <div className="panel-heading panspace" >
      <div className="col-lg-12 col-xl-12">
        <h4
                className="panel-title">
                <a href="#" className="collapsed" aria-expanded="false">
                  Clinical Images
                </a>
              </h4>
        <div style={{ width: "auto", height: 30, position: "absolute", right: 15, top: 0}}>
          <span className="makescreen"><a href="#" title="Make This Default Screen"  className="collapsed" aria-expanded="false"><i className="fa fa-star panelicon" aria-hidden="true" /></a></span>
          <a href="#" title="Email" className="collapsed" aria-expanded="false"><i  className="fas fa-envelope panelicon" aria-hidden="true" /></a>
          <a href="#" title="Print" className="collapsed" aria-expanded="false" onClick={this.print}><i  className="fas fa-print panelicon" aria-hidden="true" /></a>
          {/*<a  href="#" title="Export XLs"><i class="fas fa-file-excel-o" aria-hidden="true" style="font-size:12px; margin-right:5px;"></i></a>*/}
          <a  title="Export PDF" onclick="recentVisitmini();" className="collapsed" aria-expanded="false"><i className="fa fa-file-pdf-o panelicon" aria-hidden="true" /></a>
        </div> 
      </div>
    </div>
  </div>
  <div  className="col-sm-12" style={{marginBottom: 10}}>
    {this.state.showadd ?
  <AddClinicalRecord showform={this.state.showadd} changeKey={this.changeKey}/>:''}
   {/*{(this.state.viewEdit) ?
          <AddClinicalRecord showform={this.state.viewEdit} selectedid={this.state.selectedId}/> : ''}*/}
    
    <button  id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={(e)=> this.setState({showadd:true})}>Add</button>
     <button  id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={(e) => this.editClick('','edit')}>Details</button>
        {(this.state.viewEdit) ?
          <EditClinicalRecord sid={this.state.selectedRec} showform={this.state.viewEdit} changeKey={this.changeKey}/> : ''}
    <button  id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={(e) => this.deleteClick('','delete','')}>Delete</button>
    <button  id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs">Print</button>
    <button  id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={(e) => this.viewImage()}>View</button>
   
    <button  id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs">Scan</button>
   
    <button  id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={(e) => this.onRowClicked('','btnclicked')}>Record Note</button>
    <label for="confidential" style={{ display: "contents", marginTop:'10px' }} onChange={e => this.handleDeleteCheckbox(e)}>      
      <input id="confidential" type="checkbox"  Checked={this.state.showconfidential === "1" ? true : false}/>{" "}
          Show Confidential
    </label>
    <div className="clinical-list-part mt-2" id="clinical-list-part" ref="clinical-list-part">
    <CommonGrid
    
       handleButton={this.handleButton}
       crud={this.state.crud}
       key={this.state.key}
     />
    </div> 
  </div>
  {(this.state.showImg) ?
          <ViewClinicalRecord sid={this.state.clickedId} showImg={this.state.showImg} changeKey={this.changeKey} showfor='clinical'/> : ''}

   
  {(this.state.showNote) ?
         <ViewCorrespondenceRecord sid={this.state.clickedId} showNote={this.state.showNote} changeKey={this.changeKey} correspondencetype="clinical Reason" showbutton='yes'/> : ''}

      

</div>

        )
    }
}

const mapDispatchToProps = dispatch => {
  return {
    apiDeleteClinical: data => dispatch(api_deleteClinical(data)),
   };
};
const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';

  return {
    redux_patientId
  };
};


export default connect(
    mapStateToProps,
  mapDispatchToProps
)(ClinicalImages);

//export default ClinicalImages
