import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    api_add_correspondence,
    api_get_recieved,
    api_get_sent,
    api_get_correspondence,
    api_update_correspondence
} from '../../../DS/DS.Correspondence';
//import Files from 'react-files';
import { api_getDoctors } from '../../../DS/DS.Appointments';
import {
    NotificationContainer,
    NotificationManager,
  } from 'react-notifications';
//import "react-datepicker/dist/react-datepicker.css"
import SearchDoctors from './searchDoctors';
import { EditorState, ContentState, convertFromHTML, convertToRaw  } from "draft-js";
import cicon from "../../../assets/img/cicon.png";
import Showhelp from './../../showHelp';

class AddCorrespondenceOut extends Component {
    constructor(props){
        super(props);
        this.state = {
            slectedCorrespondence: '',
            patient_id: this.props.redux_patientId,
            startDate:new Date(),
            dateShow: false,
            isChecked: false,
            selectedCategory : 'Clinical Photograph',
            from_guid: '',
            subject: '',
            details: '',
            ref: '',
            docs: [],
            selectedFile:null,
            errors:[],
            searchDoc:false,
            selected_doc_id:'',
            open:this.props.showmodal,
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML('<p>'+this.props.reason+ '</p>')
                )
              ),
            
        }
    }
    componentWillMount=()=>
    {
        console.log(this.props)
    }
    componentDidMount(){
       console.log(this.props.reason)
        this.props.getDoctors().then(res => {
            this.setState({
              docs: res.data.content ,
            })}
            );
        if(this.props.sid!='')
        {
            this.props.getCorrespondenceData({id:this.props.sid}).then(res=>
            {
                this.setState({
                    startDate: moment(new Date(res.data.content[0].correspondence_date)).format('YYYY/MM/DD'),
                    selectedCategory:res.data.content[0].category,
                    selected_doc_id:res.data.content[0].from_guid,
                    from_guid:res.data.content[0].display+''+res.data.content[0].first_name+'.'+res.data.content[0].surname,
                    subject:res.data.content[0].subject,
                    details:res.data.content[0].details,
                    ref:res.data.content[0].user_reference
                })
            })
        }
    }
     handleDate = date => {
        console.log('test');
        this.setState({
          startDate: moment(new Date(date)).format('YYYY/MM/DD')
        });

        if(moment(new Date(date)).format('MM/DD/YYYY') == moment(new Date()).format('MM/DD/YYYY')){

          this.setState({
            dateShow: true,
          });
        }else {
          this.setState({
            dateShow: false,
          });
        }
      };
      handleSave() {
          const values = {
            'patient_id': this.state.patient_id,
            'correspondence_date' : this.state.startDate,
            'createdAt':this.state.startDate,
            'category': this.state.selectedCategory,
            'from_guid': this.props.redux_docId,
            'subject': this.state.subject,
            'isConfidencial' :this.state.isChecked,
            'isDeleted': 0,
            'user_reference' :this.state.ref,
            'details': this.state.details,
            'date_checked': this.state.startDate,
            'isInOut': 1,
            'image_name':this.props.reasons,
            'to_guid':this.state.selected_doc_id,
            'has_image':2
          }
          this.props.add_correspondence({values}).then(
              req=>this.props.changeKey(),
              NotificationManager.success(
              'Record Added',
              //this.changeKey()
              )
          )
          this.setState({
              startDate: '',
              isChecked: false,
              selectedCategory: '',
              from_guid: '',
              subject: '',
              ref: '',
              details: ''
            });
          console.log(values);
          this.props.get_recieved({patient_id: this.state.patient_id}).then(res => {
            this.setState({
              corr_recieve: res.data.content ,
            })}
            );
      }

      toggleChange = () => {
        this.setState({
          isChecked: !this.state.isChecked,
        });
      }
      onChangeHandler=event=>{
        this.setState({
        selectedFile: event.target.files[0],
        loaded: 0,
      })
  }
  serachDoctor=()=>
  {
      this.setState({searchDoc:true})
  }
  addSelectedDoc=(id)=>
  {
      this.setState({searchDoc:false})
      this.setState({selected_doc_id:id.contact_id,from_guid:id.display+''+id.first_name+'.'+id.surname})
  }
  updateData=()=>
  {
    const values = {
        'patient_id': this.state.patient_id,
        'correspondence_date' : this.state.startDate,
        'createdAt':this.state.startDate,
        'category': this.state.selectedCategory,
        'from_guid': this.props.redux_docId,
        'subject': this.state.subject,
        'isConfidencial' :this.state.isChecked,
        'user_reference' :this.state.ref,
        'details': this.state.details,
        'date_checked': this.state.startDate,
        'patients_correspondence_id':this.props.sid,
        'to_guid':this.state.selected_doc_id
      }
    this.props.updatecorrespondence(values).then(
        req=>
            this.props.changeKey(),
            NotificationManager.success(
                'Record Added',
                ) 
        
    )
  }
  focusTextInput() {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    // this.textInput.current.focus();
    document.getElementById("correspondencecalendarout").focus();
    // this.textInput.focus();
  }
    render() {
        return ( <Popup 
            position="right center"   modal
            closeOnDocumentClick={false}
            open={this.state.open}  
         >
         {close => (
             <div>
                <div className="modal-header" style={{width: '100%',height: '20', backgroundColor: '',padding:'0 15px 0 15px'}} align="center">
                    <span><h6 style={{float: 'left', fontSize: 16}}>Correspondence <Showhelp gotohelp="add_correspondence_out_help"/></h6>
                    <button type="button" className="close" data-dismiss="modal" onClick={() => close()} style={{marginTop:'10px'}} aria-label="Close">×</button></span>
                </div>
                <div className="modal-body" style={{padding:'0 30px'}}>
                    
                        
                    <div style={{ float: "left", width: "100%", height: "auto",fontSize: 14}}>
                        <div className="col-sm-3" style={{ paddingLeft: '20px',marginTop: '25px'}}>
                            <label>Date :</label>
                        </div>
                        <div className="col-sm-9" style={{marginTop: '25px'}}>
                        <DatePicker
                            className="datePicker"
                            selected={
                            this.state.dateShow
                                ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                : Date.parse(this.state.startDate)
                            }

                            // selected={Date.parse(this.state.startDate)}
                            onChange={this.handleDate}
                            id="correspondencecalendarout"
                        />
                        <label
                            className="label-input100"
                            style={{
                                marginLeft: "6px",
                                marginBottom: "0px",
                                fontSize: "12px",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                                marginTop: "-2px"
                            }}
                        >
                      <a onClick={this.focusTextInput} href="#">
                        {<img src={cicon} />}
                      </a>
                    </label>
                        </div>
                    </div>
                    <div style={{ float: "left", width: "100%", height: "auto",fontSize: 14}}>
                        <div className="col-sm-3" style={{ paddingLeft: '20px',marginTop: '10px'}}>
                            <label>Category :</label>
                        </div>
                        <div className="col-sm-9" style={{marginTop: '10px'}}>
                            <select style={{width:"50%",height:"30px"}} onChange={(e) => this.setState({ selectedCategory: e.target.value })} value={this.state.selectedCategory}>
                                <option value="Clinical Photograph"> Clinical Photograph </option>
                                <option value="Discrage Summary"> Discrage Summary </option>
                                <option value="ECG"> ECG </option>
                                <option value="Email"> Email </option>
                                <option value="Letter"> Letter </option>
                                <option value="Patient Consent"> Patient Consent </option>
                                <option value="Referral Letter"> Referral Letter </option>
                                <option value="Specialist Letter"> Specialist Letter </option>
                                <option value="Report"> Report </option>
                            </select>
                        </div>
                    </div>
                    <div style={{ float: "left", width: "100%", height: "auto",fontSize: 14}}>
                        <div className="col-sm-3" style={{ paddingLeft: '20px',marginTop: '10px'}}>
                            <label>From :</label>
                        </div>
                        <div className="col-sm-6" style={{marginTop: '10px'}}>
                            <input type="text" value={this.state.from_guid} /> 
                            {/*<select style={{width:"50%",height:"25px"}} onChange={(e) => this.setState({ from_guid: e.target.value })}>
                                <option value={this.state.from_guid}></option>
                                { this.state.docs !=undefined && this.state.docs.map((item,i) => (
                                <option value={item.doctor_id}> {item.Firstname} </option>
                                ))}
                                </select>*/}
                        </div>
                        <div className="col-md-3">
                            <button className="btn btn-default" onClick={()=> this.serachDoctor()} >Search</button>
                        </div>
                    </div>
                    {(this.state.searchDoc) ?
                    <SearchDoctors searchDoc={this.state.searchDoc} changeKey={this.addSelectedDoc}/> : ''}
                    <div style={{ float: "left", width: "100%", height: "auto",fontSize: 14}}>
                        <div className="col-sm-3" style={{ paddingLeft: '20px',marginTop: '10px'}}>
                            <label>Subject :</label>
                        </div>
                        <div className="col-sm-9" style={{marginTop: '10px'}}>
                            <input type="text" style={{width:"40%"}} onChange={(e) => this.setState({ subject: e.target.value })} value={this.state.subject}/>
                        </div>
                    </div>
                    <div style={{ float: "left", width: "100%", height: "auto",fontSize: 14}}>
                        <div className="col-sm-3" style={{ paddingLeft: '20px',marginTop: '10px'}}>
                            <label>Details :</label>
                        </div>
                        <div className="col-sm-9" style={{marginTop: '10px'}}>
                            <textarea rows="4" cols="61" style={{width:"50%"}} onChange={(e) => this.setState({ details: e.target.value })} value={this.state.details}></textarea>
                        </div>
                    </div>
                    <div style={{ float: "left", width: "100%", height: "auto",fontSize: 14}}>
                        <div className="col-sm-3" style={{ paddingLeft: '20px',marginTop: '10px'}}>
                        </div>
                        <div className="col-sm-9" style={{marginTop: '10px'}}>
                            <input type="checkbox" checked={this.state.isChecked} onChange={this.toggleChange} />&nbsp;<span>Confidential</span>
                        </div>
                    </div>
                    <div style={{ float: "left", width: "100%", height: "auto",fontSize: 14}}>
                        <div className="col-sm-3" style={{ paddingLeft: '20px',marginTop: '10px'}}>
                            <label>User Reference :</label>
                        </div>
                        <div className="col-sm-9" style={{marginTop: '10px'}}>
                            <input type="text" style={{width:"40%"}} onChange={(e) => this.setState({ ref: e.target.value })} value={this.state.ref}/>
                        </div>
                    </div>
                    <div class="modal-footer" style={{border:'none'}}>
                        <div className="col-sm-8" style={{float:'left'}}></div>
                        <div className="col-sm-4" align="right" style={{float:'right'}}>
                            {(this.props.sid=='')?
                                <button className="btn btn-primary btn-xs" style={{marginRight:'5px',width:'70px',height:'35px'}} onClick={() => { this.handleSave(); close();}}>Save</button>
                            :   <button className="btn btn-primary btn-xs" style={{marginRight:'5px',width:'70px',height:'35px'}} onClick={() => { this.updateData();}}>Update</button>
                        }
                            <button className="btn btn-primary btn-xs" style={{margin:'2px',width:'70px',height:'35px'}} onClick={() => { close(); this.props.changeKey()}}>Cancel</button>
                        </div>
                    </div>
                </div>
             </div>
          )}
          </Popup>)
    }
}
const mapDispatchToProps = (dispatch) => {
	return {
    add_correspondence: data => dispatch(api_add_correspondence(data)),
    get_recieved: data => dispatch(api_get_recieved(data)),
    get_sent: data => dispatch(api_get_sent(data)),
    getDoctors: data => dispatch(api_getDoctors(data)),
    getCorrespondenceData:data => dispatch(api_get_correspondence(data)),
    updatecorrespondence:data=>dispatch(api_update_correspondence(data))
  }
}
const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
  
  return {
    redux_patientId,redux_docId
  };
  };
export default connect (mapStateToProps, mapDispatchToProps) (AddCorrespondenceOut);
