import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager} from 'react-notifications';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import {api_add_layout_member} from  '../../../DS/DS.AppointmentSettings';
class AddLayoutUser extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        layoutName:'',
        errors:[],
        layoutmemberCrud:{
            jsonGridVersion: "v2.0",
            grid: true,
            viewgrid: {
              isVisible:true,
              isPagination: true,
              isFiltering: true,
              isSelection:true,
              deleteFunction:this.removeUser,
              isDateGroup:false,
              isYearSeparator:false,
              dateCol:'',
              groupDateSelection:false,
              cellClicked:'',
              baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
              query: {
                tb: "user_tb",
                cols: [
                  "name",
                  "role_name",
                  "user_tb.user_id"
                  
                
                ],
               where: [
               {
                 type:"and user_tb.user_id not in (select user_id from appointment_layout_users_tb where appointment_layout_users_tb.layout_id="+this.props.groupid+")",
                
                 
      
               }
              ],
              joins:[
               
              {
                joinType: "left",
                joinTable: "user_role_tb",
                joinCondition: " user_role_tb.user_id=user_tb.user_id"
              },
              {
                joinType: "left",
                joinTable: "role_tb",
                joinCondition: "role_tb.role_id=user_role_tb.role_id"
              }
    
              
              ]
               ,
                primary_key:"user_id",
              },
              viewtabletitle: "",
              columns: [
                {name: "name", title: "Name"},
                {name: "role_name", title: "Role"}
                
               
              ],
              rows: [],
              sortinit: [{ columnName: "user_id", direction: "desc" }],
              pagesize: 10,
              getAllIds:false,
              getallId:''
            }
          },
          userSelected:'',
          userSelectedLength:-1
    }
  }
  componentWillMount=()=>
  {
    
  }
  removeUser=(ids,action,totalLength)=>
  {
    if(action=='selection')
    {
      this.state.userSelected=ids;
      this.state.userSelectedLength=totalLength;
    }
  }
  onClickHandler=()=>
  {
    if(this.state.userSelectedLength > 0){
        const reasonData={
                group_id:this.props.groupid,
                user_id:this.state.userSelected
            }
                this.props.addLayoutMember(reasonData).then(
                req=>
                { if(req.data.status=="Duplicate"){
                                
                    NotificationManager.error('Duplicate group cannot be created',)
                 }
                 else if(req.data.status=="Success")
                 {
                     
                     NotificationManager.success('User added to layout',)
                 }
                 else{
                     
                     NotificationManager.error('Error encountered while adding user to layout',)
                 }
                this.props.reloadKey()
            }        
            
                    
            );
    }
    else
    {
        NotificationManager.error('Select user to add to the layout',)
    }
  }
  render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
            <div>
                <div className="modal-header">
                    <span className="modal-title-2" id="exampleModalLabel"> Appointment Book layout  </span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                    onClick={()=>this.props.reloadKey()}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="appointment-book-layout-table-2">
                        <CommonGrid
                            crud={this.state.layoutmemberCrud}
                        /> 
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-sm-8"> </div>
                        <div className="col-sm-4">
                            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>this.onClickHandler()}>Save</button>
                            <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close"  onClick={()=>this.props.reloadKey()}>Cancel</button> 
                        </div>
                    </div>
                </div>
            </div>    
        )}
        </Popup>
    )}
}
const mapDispatchToProps = dispatch => {
    return {
        addLayoutMember: data =>dispatch(api_add_layout_member(data)),
        
        
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(AddLayoutUser);
