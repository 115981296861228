import React, { Component } from 'react';
import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
import {NotificationContainer,NotificationManager} from 'react-notifications';
import {api_account_text,api_get_account} from '../../../DS/DS.AccountText';
import { connect } from 'react-redux';
import Footer from "../../footer";
import leftsidemenu from 'components/leftmenu';

class AccountText extends Component {
constructor(props)
{
    super(props)
    this.state={
        a_location:1,
        account_footer:'',
        overdue1:'',
        overdue1_text:'',
        overdue2:'',
        overdue2_text:'',
        overdue3:'',
        overdue3_text:'',
        discount_text:'',
        base_overdue:''

    }
    
}
componentWillMount=()=>
{
    this.props.getAccount().then(
        req=>
        {
            if(req.data.status=="success")
            {
                this.setState({
                a_location:req.data.content[0].a_location,
                account_footer:req.data.content[0].account_footer,
                overdue1:req.data.content[0].overdue1,
                overdue1_text:req.data.content[0].overdue1_text,
                overdue2:req.data.content[0].overdue2,
                overdue2_text:req.data.content[0].overdue2_text,
                overdue3:req.data.content[0].overdue3,
                overdue3_text:req.data.content[0].overdue3_text,
                discount_text:req.data.content[0].discount_text,
                base_overdue:req.data.content[0].base_overdue
            })
        }
        }
    )
}

savehandler=()=>
{
    const adata={
        a_location:this.state.a_location,
        account_footer:this.state.account_footer,
        overdue1:this.state.overdue1,
        overdue1_text:this.state.overdue1_text,
        overdue2:this.state.overdue2,
        overdue2_text:this.state.overdue2_text,
        overdue3:this.state.overdue3,
        overdue3_text:this.state.overdue3_text,
        discount_text:this.state.discount_text,
        base_overdue:this.state.base_overdue
    }
    this.props.apiAccount({adata}).then(
        req=>{
         NotificationManager.success('Account text stored successfully');
        }
    )
}
render()
{
return(
    <div>
         <AdminHeader /><Leftsidemenu />
          <NotificationContainer />
          <section className="content-header" style={{marginLeft: '240px'}}>
                <h1>
                    Account Text
                </h1>
                <ol className="breadcrumb">
                    <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                    <li className="active">Account text</li>
                </ol>
            </section>
            <section className="content" style={{marginLeft: '240px'}}>
            <div className="row">
                <div className="col-xs-12">
                    <div className="box">
                        <div className="col-xs-12" style={{margin:'0px auto', float:'none'}}>
                            <div className="box-body">
                                <div className="row row-distance">
                                    <div className="col-sm-1"> 
                                        <label style={{fontSize:'12px',fontWeight:'100'}}> Location : </label>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">                                                   
                                            <select className="dropdown-box" style={{margin:'-30px 0 0 -10px', width:'100%'}} value={this.state.a_location} onChange={(e)=>this.setState({a_location:e.target.value})}>
                                                <option value="1"> Default </option>
                                                <option value="2"> Main surgery </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row row-distance">
                            <label className="col-sm-12 col-form-label text-left" style={{fontSize:'14px', fontWeight:'normal', margin:'0px 0 10px 25px'}}> Standard account footer : </label>
                            <div className="col-sm-12 col-md-12 col-xl-10">	    	
                                <div id="pageContent">
                                    <div className="form-group row m-b-10" style={{marginTop:'5px'}}>
                                        <div className="col-sm-12 col-md-12 col-xl-12 m-t-10">
                                            <div className="card" style={{padding: '10px', height:'150px', overFlowY:'scroll', border:'1px solid #ccc'}}>
                                                <div className="card-block">
                                                    <textarea value={this.state.account_footer} onChange={(e)=>this.setState({account_footer:e.target.value})} 
                                                    style={{width:'100%',height:'125px', border:'none'}}></textarea>    
                                                </div>
                                            </div>
                                        </div>
                                    </div>                 
                                </div>
                            </div>	
                        </div>
                        <div className="row">
                            <label className="col-sm-12 col-form-label text-left" style={{fontSize:'16px', marginBottom:'0px'}}> 
                                <div className="border-checkbox-section">
                                    <div className="border-checkbox-group border-checkbox-group-default">
                                        <span class="" style={{fontWeight:'normal', fontSize:'14px', marginLeft:'30px', float:'left'}}> &nbsp; &gt; </span>
                                            <div className="form-group col-sm-1">                                                   
                                                <input type="text" name="text" className="form-control" style={{margin:'-10px 0 0 0'}} value={this.state.overdue1} onChange={(e)=>this.setState({overdue1:e.target.value})}/>
                                            </div>
                                        <label class="col-sm-2" style={{fontWeight:'normal', fontSize:'12px', margin:'0 0 0 -15px'}}> days overdue : </label> 
                                    </div>
                                </div>			
                            </label>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-xl-10">	    	
                                <div id="pageContent"><div className="form-group row m-b-10" style={{marginTop:'5px'}}>
                                    <div className="col-sm-12 col-md-12 col-xl-12 m-t-10">
                                        <div className="card" style={{padding:'10px', height:'150px', overFlowY:'scroll', border:'1px solid #ccc'}}>
                                            <div className="card-block">
                                                <textarea value={this.state.overdue1_text} onChange={(e)=>this.setState({overdue1_text:e.target.value})}
                                                style={{width:'100%',height:'125px', border:'none'}}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>                 
                            </div>
                        </div>	
                    </div>
                    <div className="row row-distance">
                        <label className="col-sm-12 col-form-label text-left" style={{fontSize:'16px', marginBottom:'0px'}}> 
                        <div className="border-checkbox-section">
                                <div className="border-checkbox-group border-checkbox-group-default">
                                <span class="" style={{fontWeight:'normal', fontSize:'14px', marginLeft:'30px', float:'left'}}> &nbsp; &gt; </span>
                                            <div className="form-group col-sm-1">                                                   
                                                <input type="text" name="text" className="form-control" style={{margin:'-10px 0 0 0'}} value={this.state.overdue2} onChange={(e)=>this.setState({overdue2:e.target.value})}/>
                                            </div>
                                            <label class="col-sm-2" style={{fontWeight:'normal', fontSize:'12px', margin:'0 0 0 -15px'}}> days overdue : </label> 
                                    </div>
                                </div>			
                            </label>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-xl-10">	    	
                                <div id="pageContent"><div className="form-group row m-b-10" style={{marginTop:'5px'}}>
                                    <div className="col-sm-12 col-md-12 col-xl-12 m-t-10">
                                        <div className="card" style={{padding: '10px', height:'150px', overFlowY:'scroll', border:'1px solid #ccc'}}>
                                            <div className="card-block">
                                                <textarea value={this.state.overdue2_text} onChange={(e)=>this.setState({overdue2_text:e.target.value})}
                                                 style={{width:'100%',height:'125px', border:'none'}}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>                 
                            </div>
                        </div>	
                    </div>  
                    <div className="row row-distance">
                        <label className="col-sm-12 col-form-label text-left" style={{fontSize:'16px', marginBottom:'0px'}}> 
                        <div className="border-checkbox-section">
                                <div className="border-checkbox-group border-checkbox-group-default">
                                <span class="" style={{fontWeight:'normal', fontSize:'14px', marginLeft:'30px', float:'left'}}> &nbsp; &gt; </span>
                                        <div className="form-group col-sm-1">                                                   
                                                <input type="text" name="text" className="form-control" style={{margin:'-10px 0 0 0'}} value={this.state.overdue3} onChange={(e)=>this.setState({overdue3:e.target.value})}/>
                                        </div>
                                   
                                        <label class="col-sm-2" style={{fontWeight:'normal', fontSize:'12px', margin:'0 0 0 -15px'}}> days overdue : </label> 
                                </div>
                            </div>			
                        </label>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-10">	    	
                            <div id="pageContent">
                                <div className="form-group row m-b-10" style={{marginTop:'5px'}}>
                                    <div className="col-sm-12 col-md-12 col-xl-12 m-t-10">
                                        <div className="card" style={{padding:'10px', height:'150px', overFlowY:'scroll', border:'1px solid #ccc'}}>
                                            <div className="card-block">
                                                <textarea value={this.state.overdue3_text} onChange={(e)=>this.setState({overdue3_text:e.target.value})}
                                                style={{width:'100%',height:'125px', border:'none'}}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>                 
                            </div>
                        </div>	
                    </div>
                    <div className="row row-distance">
                        <label className="col-sm-12 col-form-label text-left" style={{fontSize:'16px', marginBottom:'0px'}}> 
                        <div className="border-checkbox-section">
                            <div className="border-checkbox-group border-checkbox-group-default">
                                    <label class="col-sm-12 col-form-label text-left" style={{margin:'10px 0 10px 10px', fontSize:'14px', fontWeight:'normal'}}> Discount text : </label> 
                            </div>
                        </div>			
                        </label>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-10">	    	
                            <div id="pageContent">
                                <div className="form-group row m-b-10" style={{marginTop:'5px'}}>
                                    
                                        <div className="form-group">                                                   
                                            <input type="text" name="text" className="form-control" value={this.state.discount_text} onChange={(e)=>this.setState({discount_text:e.target.value})}/>
                                        </div>
                                    
                                </div>                 
                            </div>
                        </div>	
                    </div>
                    <div className="row row-distance">
                        <div className="col-sm-12 col-md-12 col-xl-10">	    	
                            <div id="pageContent">
                                <div className="form-group row m-b-10" style={{margin:'5px 0 20px 0'}}>
                                    <div className="col-sm-2">
                                        <label class="col-form-label" style={{fontWeight:'normal'}}> Base overdue for practice on : </label> 
                                    </div>
                                    <div className="col-sm-2">
                                        <label onClick={(e)=>this.setState({base_overdue:e.target.value})} style={{fontWeight:'100'}}>
                                            <input type="radio" style={{marginLeft:'10px'}} checked="" name="invoice" value="1"
                                            checked={this.state.base_overdue==1 ?'checked':''}/> &nbsp; Invoice date
                                        </label>
                                    </div>
                                    <div className="col-sm-2">
                                        <label  onClick={(e)=>this.setState({base_overdue:e.target.value})} style={{fontWeight:'100'}}>
                                            <input type="radio" style={{marginLeft:'10px'}} checked="" name="invoice" value="2"
                                             checked={this.state.base_overdue==2?'checked':''}/> &nbsp; Service date
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                    </div>
                                </div>                 
                            </div>
                        </div>	
                    </div>        
                </div>
                <div className="modal-footer modal-footer-bottom-margin">
                    <div className="row">
                        <div className="col-sm-10"> 
                        </div>
                        <div className="col-sm-2">
                                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>this.savehandler()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
        </section>

        <Footer/>
 </div>
    )
}}
const mapDispatchToProps = dispatch => {
    return {
        apiAccount: (data) => dispatch(api_account_text(data)),
        getAccount:()=>dispatch(api_get_account())
            };
  };
  /*const mapStateToProps = state => {
 //  console.log(">>> doc data",state.getDrAppointments.logged_info)
   //  let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     //let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
 
   return {
    // redux_patientId,redux_docId
   };
 };*/

export default connect(
null,
mapDispatchToProps
)
(AccountText);