import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';

export default class TabList extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
   // alert(tab);
    console.log(this.props);
  }

  render() {
    const { onClickTabItem, props: {children},state: {activeTab} } = this;

    return (
      <div>
        <div class="panel-heading panspace">
            <nav>
            <div className="nav nav-pills" style={{padding:'0px', color:'red'}}>
                {children.map((child) => {
                const { label } = child.props;

                return (
                    <Tab
                    activeTab={activeTab}
                    key={label}
                    label={label}
                    onClick={onClickTabItem}
                    />
                );
                })}
            </div>
            </nav>
            </div>
            <div className="tab-content">
            {children.map((child) => {
                if (child.props.label !== activeTab) return undefined;
                return child.props.children;
            })}
            </div>
      </div>
    );
  }
}
