import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertToRaw  } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AddCorrespondenceOut from "./addCorrespondenceOut"
import {api_get_image} from '../../../DS/DS.Correspondence';
import { connect } from 'react-redux';
import {api_get_type} from "./../../../DS/DS.Investigation";
import Showhelp from './../../showHelp';

class CorrespondenceEditor extends Component {
    constructor(props){
        super(props);
        this.state = {
            open:this.props.showmodal,
            reason:'',
            showAdd:false,
            editorState:''
            
        }
    }
    componentWillMount=()=>
    {
      if(this.props.sid!='')
      {
        if(this.props.showfor=='')
        {
          this.props.getCorrespondenceRecord({ params: {recordid: this.props.sid}}).then(clinicalrec => {
            let data = EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML('<p>' + clinicalrec.data.content[0].image_name + '</p>')
              ));
              this.setState({editorState: data})
           });
        }
        else if(this.props.showfor=='investigation')
        {
          const typeid={id:this.props.sid}
          this.props.gettype(typeid).then(clinicalrec=>
            {
              let data = EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML('<p>' + clinicalrec.data.content[0].image_name + '</p>')
                ));
                this.setState({editorState: data})
            })
        }
        
        
      } 
    }
    onClickHandler=()=>
    {
       
        this.setState({showAdd:true})
      //  this.setState({open:false})
    }
    onEditorStateChange = editorState => {
        console.log("editor notes", editorState);
      this.setState({
        editorState
      });
      const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
      const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
      this.setState({reason:value})
    };
    render() {
        return ( <Popup 
            position="right center"   modal
            closeOnDocumentClick={false}
            open={this.state.open}  
         >
         {close => (
             <div>
                <div className="modal-header" style={{width: '100%',height: '20', backgroundColor: '',padding:'0 15px 0 15px'}} align="center">
                    <span><h6 style={{backgroundColor:'#', float: 'left', fontSize: 16}}>
                      {this.props.showfor=='investigation' ? 'Investigation Report' : 'Correspondence'} <Showhelp gotohelp="correspondence_editor_help"/></h6>
                    <button type="button" className="close" data-dismiss="modal" onClick={() => this.props.changeKey()} style={{marginTop:'10px'}} aria-label="Close">×</button></span>
                </div>
                <div className="modal-body" style={{height:'600px'/*,overflowY: 'auto'*/}}>
                    <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              name={this.state.reason}
                              editorState={this.state.editorState}
                              onEditorStateChange={this.onEditorStateChange}
                            /> 
                </div>
                <div className="modal-footer">
                  {this.props.sid==''? 
                <button
                    style={{padding:'8px 15px'}}
                  type="button"
                  className="btn btn-primary btn-xs"
                  onClick={(e)=>{this.onClickHandler() ;}}
              >
                Next
              </button>:''}
              
              <button
                type="button"
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
                onClick={() =>this.props.changeKey()}
              >
                Cancel
              </button>
            </div>
            {this.state.showAdd?
          <AddCorrespondenceOut showmodal={this.state.showAdd}  changeKey={this.props.changeKey} reasons={this.state.reason} sid=''/>
          :''}
             </div>
            )}
            
         </Popup>
        )
    }
}
const mapDispatchToProps = dispatch => {
  return {
      getCorrespondenceRecord:data => dispatch(api_get_image(data)),
      gettype:data =>dispatch(api_get_type(data))
      
   };
};
export default connect(
  null,
mapDispatchToProps
) (CorrespondenceEditor);