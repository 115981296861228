import React, { Component } from 'react';

class InvestigationReports extends Component{
    render(){
        return(
            <div id="notesdiv5" className="col-md-12" style={{paddingLeft: 0, paddingRight: 0}}>
  <div className="panel panel-default">
    <div className="panel-heading" style={{float: 'left', width: '100%', padding: '4px 0px 3px 10px'}}>
      <div className="col-lg-12 col-xl-12" style={{paddingLeft: 0, paddingRight: 0}}>
        <h4 className="panel-title" style={{float: 'left', width: '100%', display: 'inline', marginRight: 10}}>
          <a href="#" style={{float: 'left', fontSize: 16}} className="collapsed" aria-expanded="false">Investigation reports</a>
        </h4>
        <div style={{width: 'auto', height: 30, position: 'absolute', right: 15, top: 5}}>
          <span className="makescreen"><a href="#" title="Make This Default Screen" className="collapsed" aria-expanded="false"><i className="fa fa-star" aria-hidden="true" style={{fontSize: 12, marginRight: 5}} /></a></span>
          <a href="#" title="Send SMS" data-toggle="modal" data-target="#sendsms" className="collapsed" aria-expanded="false"><i className="fas fa-sms" aria-hidden="true" style={{fontSize: 12, marginRight: 5}} /></a>
          <a href="#" title="Email" data-toggle="modal" data-target="#sendmail" className="collapsed" aria-expanded="false"><i className="fas fa-envelope" aria-hidden="true" style={{fontSize: 12, marginRight: 5}} /></a>
          <a href="#" title="Print" className="collapsed" aria-expanded="false"><i className="fas fa-print" aria-hidden="true" style={{fontSize: 12, marginRight: 5}} /></a>
          {/*<a href="#" title="Export XLs"><i class="fas fa-file-excel-o" aria-hidden="true" style="font-size:12px; margin-right:5px;"></i></a>*/}
          <a title="Export PDF" onclick="recentVisitmini();" className="collapsed" aria-expanded="false"><i className="fa fa-file-pdf-o" aria-hidden="true" style={{marginTop: '-15px', fontSize: 12}} /></a>
        </div>
      </div>
    </div>
  </div>
  <div className="col-sm-6" style={{marginBottom: 10}}>
    <button id="session-timeout-dialog-logout" type="button" className="btn btn-default" style={{margin: '7px auto 0px', padding: '2px 10px'}}>Add</button>
    <button id="session-timeout-dialog-logout" type="button" className="btn btn-default" style={{margin: '7px auto 0px', padding: '2px 10px'}}>Edit</button>
    <button id="session-timeout-dialog-logout" type="button" className="btn btn-default" style={{margin: '7px auto 0px', padding: '2px 10px'}}>Delete</button>
    <button id="session-timeout-dialog-logout" type="button" className="btn btn-default" style={{margin: '7px auto 0px', padding: '2px 10px'}}>Mark result as given</button>
    <button id="session-timeout-dialog-logout" type="button" className="btn btn-default" style={{margin: '7px auto 0px', padding: '2px 10px'}}>Visit Notes</button>
    <button id="session-timeout-dialog-logout" onclick="Graph();" type="button" className="btn btn-default" style={{margin: '7px auto 0px', padding: '2px 10px', float: 'right'}}>Give Values</button>
  </div>
  <div className="col-sm-6" style={{marginBottom: 10, textAlign: 'right'}}>
    <button id="session-timeout-dialog-logout" onclick="atomised();" type="button" className="btn btn-default" style={{float: 'right', margin: '7px auto 0px', padding: '2px 10px'}}>Graph</button>
    <select style={{float: 'right', width: '25%', marginRight: 5, marginTop: 7, marginBottom: 0, padding: '2px 10px'}}>
      <option>Filter</option>
      <option>Select One</option>
    </select>
  </div>
  <div className="col-sm-6">
    <div style={{float: 'left', width: '100%', overflow: 'auto'}}>
      <table style={{width: '100%'}}>
        <tbody><tr>
            <td>
              <div style={{float: 'left', width: '100%', height: 595}}>
                <table className="calendar table table-bordered tablestyle" style={{float: 'left', width: 1100}}>
                  <thead>
                    <tr style={{backgroundColor: '#f4f4f4', curser: 'ponter'}} onclick="Investigationview();">
                      <th style={{textAlign: 'left'}}><input type="checkbox" style={{float: 'left', marginRight: 5, width: 50}} /> Date</th>
                      <th style={{width: 250}}>Test name</th>
                      <th>Checked by</th>
                      <th style={{width: 94}}>Date Checked</th>
                      <th>Action</th>
                      <th>Status</th>
                      <th>Comment</th>
                      <th>Provider</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr bgcolor="#ddddba">
                      <td><input type="checkbox" name="invest" id="check_allin" /> 28/05/2019</td>
                      <td>General Micro (GMC-0)</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>11/05/2019</td>
                      <td>No action</td>
                      <td>Given 31/05/2018</td>
                      <td>Normal</td>
                      <td>Western Diagnostic Pathology</td>
                    </tr>
                    <tr bgcolor="#ddddba">
                      <td><input type="checkbox" name="invest" /> 28/05/2019</td>
                      <td>General Micro (GMC-0)</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>20/05/2019</td>
                      <td>No action</td>
                      <td>Given 31/05/2018</td>
                      <td>Normal</td>
                      <td>Western Diagnostic Pathology</td>
                    </tr>
                    <tr bgcolor="#ddddba">
                      <td><input type="checkbox" name="invest" /> 28/05/2019</td>
                      <td>SE- Liver Function</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>20/05/2019</td>
                      <td>No action</td>
                      <td>//</td>
                      <td>Normal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#e1bf9e">
                      <td><input type="checkbox" name="drug" /> 07/05/2019</td>
                      <td>SE- Renal Function</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>30/04/2019</td>
                      <td>No action</td>
                      <td>//</td>
                      <td>Normal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#d4ab83">
                      <td><input type="checkbox" name="drug" /> 05/05/2019</td>
                      <td>SE- Iron Studies</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>30/05/2018</td>
                      <td>Non-urgent appointm</td>
                      <td>Contacted 01/05/2018</td>
                      <td>Abnormal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#d799e1">
                      <td><input type="checkbox" name="drug" /> 22/04/2019</td>
                      <td>SE- Calcium Studies</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>11/04/2019</td>
                      <td>No action</td>
                      <td>//</td>
                      <td>Normal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#e199bf">
                      <td><input type="checkbox" name="drug" /> 14/04/2019</td>
                      <td>SE- Magnesium</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>30/03/2019</td>
                      <td>No action</td>
                      <td>//</td>
                      <td>Normal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#e199bf">
                      <td><input type="checkbox" name="drug" /> 14/04/2019</td>
                      <td>SE- VITAMIN D 25 OH</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>31/05/2018</td>
                      <td>Non-urgent appointm</td>
                      <td>Contacted 30/03/2019</td>
                      <td>Abnormal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#e199bf">
                      <td><input type="checkbox" name="drug" /> 14/04/2019</td>
                      <td>SE-Thyroid Function</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>30/03/2019</td>
                      <td>No action</td>
                      <td>//</td>
                      <td>Normal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#bc99e1">
                      <td><input type="checkbox" name="drug" /> 27/01/2019</td>
                      <td>ED-Haematology</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>20/01/2019</td>
                      <td>No action</td>
                      <td>//</td>
                      <td>Normal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#bc99e1" style={{borderBottom: '3px solid #d60d0d'}}>
                      <td><input type="checkbox" name="drug" /> 27/01/2019</td>
                      <td>EBV Serology</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>30/05/2018</td>
                      <td>No action</td>
                      <td>Given 11/01/2019</td>
                      <td>Normal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#dddddd">
                      <td><input type="checkbox" name="drug" /> 29/11/2018</td>
                      <td>Chlamydia/Gono Naat Scrn</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>21/11/2018</td>
                      <td>No action</td>
                      <td>Given 31/05/2018</td>
                      <td>Normal</td>
                      <td>Western Diagnostic Pathology</td>
                    </tr>
                    <tr bgcolor="#dddddd">
                      <td><input type="checkbox" name="drug" /> 29/11/2018</td>
                      <td>RPR TPHA SYPH IGM SERIAL</td>
                      <td>Dr. Rachel Gilroy</td>
                      <td>30/05/2018</td>
                      <td>No action</td>
                      <td>Given 31/05/2018</td>
                      <td>Normal</td>
                      <td>Western Diagnostic Pathology</td>
                    </tr>
                    <tr bgcolor="#cccccc">
                      <td><input type="checkbox" name="drug" /> 21/07/2018</td>
                      <td>H.I.V SEROLOGY (HIV-0)</td>
                      <td>Dr. Rachel Gilroy</td>
                      <td>30/05/2018</td>
                      <td>No action</td>
                      <td>Given 31/05/2018</td>
                      <td>Normal</td>
                      <td>Western Diagnostic Pathology</td>
                    </tr>
                    <tr bgcolor="#f4f4f">
                      <td><input type="checkbox" name="drug" /> 16/07/2018</td>
                      <td>HEPATITIS SCREEN UNIVERSL</td>
                      <td>Dr. Rachel Gilroy</td>
                      <td>30/05/2018</td>
                      <td>No action</td>
                      <td>Given 31/05/2018</td>
                      <td>Normal</td>
                      <td>Western Diagnostic Pathology</td>
                    </tr>
                    <tr bgcolor="#eeeeee">
                      <td><input type="checkbox" name="drug" /> 16/07/2018</td>
                      <td>OPG OF TMJ DISEASE OR DYSPUNCTION</td>
                      <td>Dr. Jayaendran </td>
                      <td>09/10/2018</td>
                      <td>Non-urgent appointm</td>
                      <td>Given 31/05/2018</td>
                      <td>Acceptable</td>
                      <td>SKG</td>
                    </tr>
                    <tr bgcolor="#d3d3d3" style={{borderBottom: '3px solid #ccd9fc'}}>
                      <td><input type="checkbox" name="drug" /> 28/05/2018</td>
                      <td>MRI - SPINE</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>13/12/2018</td>
                      <td>Non-urgent appointm</td>
                      <td>Contacted 14/12/2018</td>
                      <td>Abnormal</td>
                      <td>SKG</td>
                    </tr>
                    <tr bgcolor="#dddddd">
                      <td><input type="checkbox" name="drug" /> 28/05/2018</td>
                      <td>General Micro (GMC-0)</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>31/05/2018</td>
                      <td>No action</td>
                      <td>Given 31/05/2018</td>
                      <td>Normal</td>
                      <td>Western Diagnostic Pathology</td>
                    </tr>
                    <tr bgcolor="#dddddd">
                      <td><input type="checkbox" name="drug" /> 28/05/2018</td>
                      <td>General Micro (GMC-0)</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>31/05/2018</td>
                      <td>No action</td>
                      <td>Given 31/05/2018</td>
                      <td>Normal</td>
                      <td>Western Diagnostic Pathology</td>
                    </tr>
                    <tr bgcolor="#dddddd">
                      <td><input type="checkbox" name="drug" /> 28/05/2018</td>
                      <td>SE- Liver Function</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>30/05/2018</td>
                      <td>No action</td>
                      <td>//</td>
                      <td>Normal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#cccccc">
                      <td><input type="checkbox" name="drug" /> 05/05/2018</td>
                      <td>SE- Renal Function</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>30/05/2018</td>
                      <td>No action</td>
                      <td>//</td>
                      <td>Normal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#d4d4d4">
                      <td><input type="checkbox" name="drug" /> 07/05/2018</td>
                      <td>SE- Iron Studies</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>30/05/2018</td>
                      <td>Non-urgent appointm</td>
                      <td>Contacted 31/05/2018</td>
                      <td>Abnormal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#d3d3d3">
                      <td><input type="checkbox" name="drug" /> 22/04/2018</td>
                      <td>SE- Calcium Studies</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>31/05/2018</td>
                      <td>No action</td>
                      <td>//</td>
                      <td>Normal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#f4f4f4">
                      <td><input type="checkbox" name="drug" /> 14/04/2018</td>
                      <td>SE- Magnesium</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>30/05/2018</td>
                      <td>No action</td>
                      <td>//</td>
                      <td>Normal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#f4f4f4">
                      <td><input type="checkbox" name="drug" /> 14/04/2018</td>
                      <td>SE- VITAMIN D 25 OH</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>31/05/2018</td>
                      <td>Non-urgent appointm</td>
                      <td>Contacted 31/05/2018</td>
                      <td>Abnormal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#f4f4f4">
                      <td><input type="checkbox" name="drug" /> 14/04/2018</td>
                      <td>SE-Thyroid Function</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>30/05/2018</td>
                      <td>No action</td>
                      <td>//</td>
                      <td>Normal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                    <tr bgcolor="#eeeeee">
                      <td><input type="checkbox" name="drug" /> 27/01/2019</td>
                      <td>ED-Haematology</td>
                      <td>Dr. Kushan Rajapaksha</td>
                      <td>30/05/2018</td>
                      <td>No action</td>
                      <td>//</td>
                      <td>Normal</td>
                      <td>Clinipath Pathology</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody></table>
    </div>
  </div>
  <div className="col-sm-6" style={{paddingLeft: 10, overflow: 'auto', height: 615, border: '1px solid #ddd'}} id="Investigationdetails">
    <h5 style={{backgroundColor: '#f4f4f4', padding: '5px 10px', marginLeft: '-10px', width: '105%', marginBottom: 20}}>28/05/2018 - General Micro (GMC-0) </h5>
    <a title="Next" onclick="detailsvisit1();" style={{paddingRight: 5, float: 'right'}} className="collapsed" aria-expanded="false"><i className="fa fa-arrow-circle-up" aria-hidden="true" style={{float: 'right', marginTop: 10, fontSize: 16}} /></a><br />
    <a title="Previous" onclick="detailsvisit2();" style={{paddingRight: 5, marginRight: '-18px', float: 'right'}} className="collapsed" aria-expanded="false"><i className="fa fa-arrow-circle-down" aria-hidden="true" style={{float: 'right', marginTop: 10, fontSize: 16}} /></a>
    <div style={{marginTop: '-30px'}}>
      ZEEMAN,	KAITLYN D<br /><br />
      14 BEILSON CT,	CANNING VALE.	6155<br />
      Phone:	62542553<br />
      Birthdate:	08/07/1996<br />    
      Sex:	F <br /><br />
      Medicare Number:	6248726071<br /><br />
      Your Reference:<br />
      Lab Reference:	18-81269167-GMC-0<br />
      Laboratory:	WESTERN DIAGNOSTIC PATHOLOGY<br />
      Addressee:	DR RACHEL GILROY<br />
      Referred by:	DR RACHEL GILROY<br /><br />
      Name of Test:	GENERAL MICRO (GMC-0)<br />
      Requested:	28/05/2018<br />
      Collected:	28/05/2018<br />
      Reported:	31/05/2018    12:53<br /><br />
      MICROBIOLOGY<br /><br />
      SPECIMEN/SITE:   Swab from genitalia<br /><br />
      MICROSCOPY:<br /> No white cells.Numerous gram variable pleomorphic
      bacteria.Numerous gram positive bacilli.No yeast cells
      seen.<br /><br />
      Bacteria morphologically resembling lactobacilli NOT seen
      on microscopy.<br /><br />
      The microscopic features are consistent with bacterial
      vaginosis. Oral or intravaginal metronidazole or
      clindamycin, or oral tinidazole, may be used as treatment
      in symptomatic patients. Treatment of sexual partners is
      not required.<br /><br />
      CULTURE:       No pathogens isolated.<br /><br />
      Submitted sample was received incompletely labelled.  Minimum labelling
      requirements by NATA are two identifiers attributed to the patient. If
      you cannot confirm the sample is attributed to the patient a repeat
      request and sample is recommended for this test.<br /><br />
      Interim Report<br /><br />
      Requested Tests : GMC*
    </div>
  </div>
  <div className="col-sm-6" style={{paddingLeft: 0, overflow: 'auto', height: 565, display: 'none'}} id="Graphdetails">
    <h5 style={{backgroundColor: '#f4f4f4', padding: '5px 10px', marginBottom: 10}}>Give Values </h5>
    <div className="form-group row">
      <label className="col-sm-3 col-form-label text-left">Report Date:</label>
      <div className="col-sm-3">
        <input type="text" className="form-control form-control-sm" />
      </div>
      <label className="col-sm-3 col-form-label text-left">Family Name:</label>
      <div className="col-sm-3">
        <input type="text" className="form-control form-control-sm" />
      </div>
    </div>
    <div className="form-group row">
      <label className="col-sm-3 col-form-label text-left">HbA1c:</label>
      <div className="col-sm-3">
        <input type="text" className="form-control form-control-sm" />
      </div>
      <label className="col-sm-3 col-form-label text-left">Family Name:</label>
      <div className="col-sm-3">
        <input type="text" className="form-control form-control-sm" />
      </div>
    </div>
    <div className="form-group row">
      <label className="col-sm-3 col-form-label text-left">Total Cholesterol:</label>
      <div className="col-sm-3">
        <input type="text" className="form-control form-control-sm" />
      </div>
      <label className="col-sm-3 col-form-label text-left">Triglycerides:</label>
      <div className="col-sm-3">
        <input type="text" className="form-control form-control-sm" />
      </div>
    </div>
    <div className="form-group row">
      <label className="col-sm-3 col-form-label text-left">HDL Cholesterol:</label>
      <div className="col-sm-3">
        <input type="text" className="form-control form-control-sm" />
      </div>
      <label className="col-sm-3 col-form-label text-left">LDL Cholesterol:</label>
      <div className="col-sm-3">
        <input type="text" className="form-control form-control-sm" />
      </div>
    </div>
    <div className="form-group row">
      <label className="col-sm-3 col-form-label text-left">Creatinine ratio:</label>
      <div className="col-sm-3">
        <input type="text" className="form-control form-control-sm" />
      </div>
      <label className="col-sm-3 col-form-label text-left">Micro-albuminuria:</label>
      <div className="col-sm-3">
        <input type="text" className="form-control form-control-sm" />
      </div>
    </div>
    <div className="form-group row">
      <label className="col-sm-3 col-form-label text-left">Haemoglobin (g/L):</label>
      <div className="col-sm-3">
        <input type="text" className="form-control form-control-sm" />
      </div>
      <label className="col-sm-6 col-form-label text-left">&nbsp;</label>
    </div>
    <div className="form-group row">
      <div className="col-sm-3">
        <button id="session-timeout-dialog-logout" type="button" className="btn btn-default" style={{margin: '7px auto 0px', padding: '2px 10px'}}>Lookup Lx</button>
      </div>
      <div className="col-sm-9" style={{}}>
        <button id="session-timeout-dialog-logout" type="button" className="btn btn-default" style={{float: 'right', margin: '7px auto 0px', padding: '2px 10px'}}>Cancel</button>
        <button id="session-timeout-dialog-logout" type="button" className="btn btn-default" style={{float: 'right', margin: '7px auto 0px', padding: '2px 10px'}}>Save</button>
      </div>
    </div>
  </div>
  <div className="col-sm-6" style={{paddingLeft: 0, display: 'none'}} id="atomiseddetails">
    <h5 style={{float: 'left', width: '100%', backgroundColor: '#f4f4f4', padding: '5px 10px', marginBottom: 10}}>Graph</h5>
    <div className="col-lg-12 classic-tabs" style={{float: 'left', width: '100%', paddingLeft: 0, paddingRight: 0, marginTop: 0}}>
      <div className="col-lg-2 classic-tabs" style={{float: 'left', width: '100%', paddingLeft: 0, paddingRight: 0, marginTop: 0}}>
        <table className="calendar table table-bordered" style={{backgroundColor: '#f4f4f4'}}>
          <tbody>
            <tr>
              <td><div id="chartopen"><a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">5-Hydroxy Vitamin</a></div><div id="chartclose" style={{display: 'none'}}><a onclick="observationsOpennew();" title="Click to see graph" className="collapsed" aria-expanded="false"><b>25OH Vitamin</b></a></div></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Albumin</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Alk Phos</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Alkaline Phosphatase</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">ALT</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">AST</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Basophils</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Bicarbonate</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Bilirubin</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Calcium</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Chloride</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Cholesterol</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Corrected Calcium</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Creatinie</a></td>
            </tr>
            <tr>
              <td><div id="chartopen"><a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">25-Hydroxy Vitamin</a></div><div id="chartclose" style={{display: 'none'}}><a onclick="observationsOpennew();" title="Click to see graph" className="collapsed" aria-expanded="false"><b>25OH Vitamin</b></a></div></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Albumin</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Alk Phos</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Alkaline Phosphatase</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">ALT</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">AST</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Basophils</a></td>
            </tr>
            <tr>
              <td>&nbsp;<a onclick="observationsClose();" title="Click to see graph" className="collapsed" aria-expanded="false">Bicarbonate</a></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="observations_details" className="col-lg-10 classic-tabs" style={{float: 'left', width: '100%', paddingLeft: 0, paddingRight: 0, marginTop: 0, display: 'block'}}>
        <table className="calendar table table-bordered" style={{backgroundColor: '#ffffff'}}>
          <thead>
            <tr style={{backgroundColor: '#cccccc'}}>
              <th style={{padding: 5}}>Today</th>
              <th style={{padding: 5}}>23/4/2019</th>
              <th style={{padding: 5}}>19/3/2019</th>
              <th style={{padding: 5}}>4/3/2019</th>
              <th style={{padding: 5}}>11/2/2019</th>
              <th style={{padding: 5}}>17/1/2019</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;37</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;55</td>
              <td>&nbsp;</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;48</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;35</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;39</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;45</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;42</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;51</td>
              <td>&nbsp;</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;47</td>
              <td>&nbsp;</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;43</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;56</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;53</td>
              <td>&nbsp;</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;49</td>
              <td>&nbsp;</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;42</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;33</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;55</td>
              <td>&nbsp;</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;44</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;37</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;35</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;44</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;41</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;53</td>
              <td>&nbsp;</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td>&nbsp;</td>
              <td>&nbsp;49</td>
              <td>&nbsp;</td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
              <td style={{backgroundColor: '#ffffff'}}><input type="text" style={{border: 0, marginBottom: 0, width: 40}} id="result" name="result" /></td>
              <td><a href="#" className="collapsed" aria-expanded="false">&nbsp;</a></td>
              <td>&nbsp;46</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="observations_chart" className="col-lg-10 classic-tabs" style={{float: 'left', width: '100%', paddingLeft: 0, paddingRight: 0, marginTop: 0, display: 'none'}}>
        <img src="images/LineChart1.png" style={{width: '100%', height: 330}} />
        <a onclick="observationsOpennew();" className="collapsed" aria-expanded="false"><i className="fa fa-close" aria-hidden="true" style={{float: 'right', marginTop: 10, position: 'absolute', right: 15, top: 0}} /></a>
      </div>
    </div>
  </div>		</div>

        )
    }
}
export default InvestigationReports