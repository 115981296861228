import axios from 'axios';
import { wflow } from './general';
export const LOGGED_INFO = 'LOGGED_INFO';

export const saveLoggedInfo = logged_data => ({
  type: LOGGED_INFO,
  logged_data,
});

export const api_login = data => dispatch => {
  console.log(data);
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}api=acm&action=login`, data, {
      withCredentials: true,
    })
    .then(res => {
      return res.data;
    })
    .catch(() => {
      return false;
    });
};

export const GET_LOGGED_INFO = 'GET_LOGGED_INFO';

export const setLoggedInfo = loggedData => ({
  type: GET_LOGGED_INFO,
  loggedData,
});



export const api_getUserInfo = () => dispatch => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}api=acm&action=me`, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(() => false);
};


export const api_setLoggedInfoRedux = (data) => dispatch => {
  console.log("setting logindata", data);
   dispatch(setLoggedInfo(data));
   
};

export const api_logout =() => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}api=acm&action=logout`, {
      withCredentials: true,
    })
    .then(res => res.data)
    .catch(() => false);
};

export const search_all = data => dispatch => {return axios.post(`${process.env.REACT_APP_BASE_URL}api=acm&action=search_all`,data);};
