import React, { Component } from "react";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import {api_get_distinct_reason} from '../../../DS/DS.Reminders';
import Showhelp from '../../showHelp';
import leftsidemenu from "components/leftmenu";

 class SelectPatients extends Component {
  constructor(props) {
    super(props);
  this.state = {
     open:this.props.showmod,
     key:0,
     reasons:[],
     isSelected:[]
     }
 
}

componentWillMount=()=>{
 this.props.getReasons().then(res=>
    {
        if(res.data.content!='')
        {
        this.setState({reasons:res.data.content})
        }
    })
}
checkd=(e,eve)=>
{
  console.log("&&&&&&&&&&&&&&&&&&&&",this.state.isSelected)
 // alert(e.target.checked)
 if(eve=='')
 {
    if(e.target.checked==true)
    {
        this.state.isSelected.push(e.target.value)
    }
    if(e.target.checked==false)
    {
      this.removePeople(e)
    }
  }
  else
  {
    this.state.isSelected.push(e)
  }
}
removePeople(e) {
  console.log("&&&&&&&&&&&&&&&&&&&&",this.state.isSelected)
  var array = [...this.state.isSelected]; // make a separate copy of the array
  var index = array.indexOf(e.target.value)
  console.log("&&&&&&&&&&&&&&&&&&&&",index)
  if (index !== -1) {
    array.splice(index, 1);
    this.setState({isSelected: array});
    console.log("&&&&&&&&&&&&&&&&&&&&",array)
  }
}
onSubmit=()=>
{
  this.props.close1(this.state.isSelected)
}
 selectAll() {
  // alert("IN")
  var items = document.getElementsByName('acs');
  for (var i = 0; i < items.length; i++) {
      items[i].checked = true;
      console.log("&&&&&&&&&&&&&&&&&&&&",items[i])
      this.checkd(items[i].value,'chk')
  }
}
deselectAll() {
  // alert("IN")
  var items = document.getElementsByName('acs');
  for (var i = 0; i < items.length; i++) {
      items[i].checked = false;
      console.log("&&&&&&&&&&&&&&&&&&&&",items[i])
     
  }
  this.setState({isSelected: []});
}

 
    render(){
        return(
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (

              <div style={{ width:'550px', margin: '0px auto'}}>
              <div style={{ width:'550px', marginLeft:'0px', backgroundColor:'#FFF',float:'left'}}>
              <div className="modal-header">
              <button type="button" className="close" onClick={() =>{close();this.props.changeKey()}} data-dismiss="modal" style={{ fontSize:'25px', right: '0px'}}>×</button>
              <span className="popup-title" >Select patients for reminders <Showhelp gotohelp="reminders_help"/></span>
              </div>

                    <div className="modal-body reminder">
                       <div style={{marginLeft:'20px', marginTop:'10px'}}>
                        {this.state.reasons!=undefined && this.state.reasons.map((item,i)=> (
                          
                          <label> <input type="checkbox" id={i} name="acs" value={item.content} onChange={(e)=>this.checkd(e,'')} ></input>{item.content}</label>
                        ))}
                        </div>
                        <div>
                          <div className="col-sm-12" style={{float:'left', marginTop:'10px'}}>
                          <button className="btn btn-primary btn-xs" onClick={()=>this.selectAll()}>Select All</button>
                          <button className="btn btn-primary btn-xs" onClick={()=>this.deselectAll()}>DeSelect All</button>
                        </div></div>
                    </div>
                    <div className="modal-footer">
                    <button type="button"  className="btn btn-primary btn-xs" data-dismiss="modal" onClick={()=>this.onSubmit()}>Submit</button>
                        <button type="button"  className="btn btn-primary btn-xs" data-dismiss="modal" onClick={()=>this.props.close1('')}>Close</button>
                    </div>   
                </div>
                </div>         
                    )}     
            </Popup>
        )}
    } 
    const mapDispatchToProps = dispatch => {
        return {
          getReasons: () => dispatch(api_get_distinct_reason()),
        };
      };
      
      const mapStateToProps = state => {
        //console.log(state);
        let appointments = state.getDrAppointments.getAppointments
          ? state.getDrAppointments.getAppointments
          : [];
        let get_cut_copy_details = state.getDrAppointments.cut_copy_details
          ? state.getDrAppointments.cut_copy_details
          : {};
        let logged_info =
          state.getDrAppointments.logged_info != undefined
            ? state.getDrAppointments.logged_info
            : "";
      
        return {
          appointments,
          get_cut_copy_details,
          logged_info
        };
      };
      
      export default connect(mapStateToProps, mapDispatchToProps)(SelectPatients)



