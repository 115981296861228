import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager} from 'react-notifications';
import {api_delete_selection} from '../../../DS/DS.ListPage';
import {api_remove_ongroup_delete,api_delete_user,api_delete_layout} from '../../../DS/DS.AppointmentSettings';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import AddLayout from './addAppointmentLayout';
import AddLayoutUser from './addLayoutUser';
import { confirmAlert } from 'react-confirm-alert';
import {api_get_settings} from '../../../DS/DS.Reminders';
class AppointmentLayout extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        addLayoutUser:'',
        layoutCrud: {
            jsonGridVersion: "v2.0",
            grid: true,
            viewgrid: {
              isVisible:true,
              isPagination: true,
              isFiltering: true,
              isSelection:true,
              deleteFunction:this.removeLayout,
              isDateGroup:false,
              isYearSeparator:false,
              dateCol:'',
              groupDateSelection:false,
              cellClicked:'',
              baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
              query: {
                tb: "appointment_layout_tb",
                cols: [
                  "layout_id",
                  "layout_name",
                 
                ],
               where: [
                  {
                    type: "and",
                    key: "isDeleted",
                    operator: "=",
                    value: 0
                  }
               ],
                primary_key:"layout_id",
              },
              viewtabletitle: "",
              columns: [
                {name: "layout_name", title: "Layout"},
              ],
              rows: [],
              sortinit: [{ columnName: "layout_id", direction: "desc" }],
              pagesize: 10,
              getAllIds:false,
              getallId:''
            }
          },
          layoutUserCrud: {
            jsonGridVersion: "v2.0",
            grid: true,
            viewgrid: {
                isVisible:true,
                isPagination: true,
                isFiltering: true,
                isSelection:true,
                deleteFunction:this.removeLayoutUser,
                isDateGroup:false,
                isYearSeparator:false,
                dateCol:'',
                groupDateSelection:false,
                cellClicked:'',
                baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                query: {
                tb: "appointment_layout_users_tb",
                cols: [
                    "layout_user_id",
                    "layout_name",
                    "name",
                    "role_name"
                    
                
                ],
                where: [
                /* {
                type:"and",
                key:" message_group_user_tb.group_id",
                operator:"=",
                value:this.props.groupid

                }*/
                ],
                joins:[
                {
                joinType: "left",
                joinTable: "appointment_layout_tb",
                joinCondition: "appointment_layout_tb.layout_id=appointment_layout_users_tb.layout_id"
                },
                {
                joinType: "left",
                joinTable: "user_tb",
                joinCondition: "user_tb.user_id= appointment_layout_users_tb.user_id"
                },{
                joinType: "left",
                joinTable: "user_role_tb",
                joinCondition: " user_role_tb.user_id=user_tb.user_id"
                },
                {
                joinType: "left",
                joinTable: "role_tb",
                joinCondition: "role_tb.role_id=user_role_tb.role_id"
                }

                
                ]
                ,
                primary_key:"layout_user_id",
                },
                viewtabletitle: "",
                columns: [
                {name: "name", title: "Name"},
                {name: "layout_name", title: "Layout"},
                {name: "role_name", title: "Role"}
                
                ],
                rows: [],
                sortinit: [{ columnName: "layout_user_id", direction: "desc" }],
                pagesize: 10,
                getAllIds:false,
                getallId:''
            }
          },
          key:0,
          layoutSelectedLength:-1,
          layoutSelected:'',
          layoutUserSelected:'',
          layoutUserSelectedLength:-1
    }
  }
  componentWillMount=()=>
  {
            
  }
  removeLayout=(ids,action,totalLength)=>
  {
    if(action=='selection')
    {
      this.state.layoutSelected=ids;
      this.state.layoutSelectedLength=totalLength;
    }
    if(action=='delete')
    {
        if (this.state.layoutSelectedLength> 0) {
            const metaData={tb_name:'appointment_layout_tb',ids:this.state.layoutSelected,primary_key:'layout_id'}
               
              confirmAlert({
                title: '',
                message: 'You are about to remove selected layout.Do you want to proceed?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                        this.props.apiDelete(metaData).then(
                        res =>{
                        //  alert(res.data);
                            if(res.data.status=="Found")
                            {
                              NotificationManager.error(
                                'Layout(s) with user(s) cannot be deleted.',
                              )
                            }
                            this.reloadPage();
                           }
                          
                          );
                      },
                },
                {
                  label: 'No',
                  onClick: () => { this.reloadPage()},
                },
          ],
        });
      } 
      else 
      {
        NotificationManager.error('Please select a record to delete');
      }
    }
  }
  
  addCheck=()=>
{
  alert("Please deselect the record to proceed")
}
reloadPage=()=>
{
  //alert("in")
    this.setState({addLayout:false,addLayoutUser:false,editLayout:false})
    this.setState({key:Math.random()})
}
checkgroupselected=()=>
{
      alert("Please select layout to add user")
}
editClicked=()=>
{
    if(this.state.layoutSelectedLength ==1)
    {
            this.setState({editLayout:true})
    }
    else
    {
        NotificationManager.error('Please select single record to modify',)
    }
}
removeLayoutUser=(ids,action,totalLength)=>
{
  if(action=='selection')
  {
    this.state.layoutUserSelected=ids;
    this.state.layoutUserSelectedLength=totalLength;
  }
  if(action=='delete')
  {
      if (this.state.layoutUserSelectedLength> 0) {
          const metaData={ids:this.state.layoutUserSelected}
             
            confirmAlert({
              title: '',
              message: 'You are about to remove user from layout.Do you want to proceed?',
              buttons: [
                  {
                      label: 'Yes',
                      onClick: () => {
                      this.props.apiDeleteUser(metaData).then(
                      res =>this.reloadPage() ,
                          NotificationManager.success(
                          'Layout user removed successfully',
                        )
                      
                    );
                  },
              },
              {
                label: 'No',
                onClick: () => { this.reloadPage()},
              },
        ],
      });
    } 
    else 
    {
      NotificationManager.error('Please select a record to delete');
    }
  }
}

 render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center" 
         modal
      
      >
        {close => (
            <div className="">
            <div className="modal-header">
                <span className="modal-title-2" id="exampleModalLabel"> Appointment Book layout  </span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                    onClick={()=>this.props.reloadKey()}>
                        <span aria-hidden="true">×</span>
                    </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6">
                    <div className="col-sm-12">
                        
                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#appointment-book-layout-add-btn" style={{width:'100px', margin:'15px 5px 0px 0'}}
                                onClick={(e)=>{this.state.layoutSelected >0 ?this.addCheck():this.setState({addLayout:true})}}> Add </button>
                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#appointment-book-layout-edit-btn" style={{width:'100px', margin:'15px 5px 0px 0'}}
                                onClick={()=>this.editClicked()}>Edit</button>
                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', margin:'15px 5px 0px 0'}} onClick={()=>this.removeLayout('','delete','')}>Delete</button>
                          
                    </div>
           
                    <div className="col-sm-12">
                        <div className="appointment-book-layout-table" style={{/*marginLeft:'12px'*/}}>
                            <CommonGrid
                              crud={this.state.layoutCrud}
                              key={this.state.key}
                              />    
                        </div>
                    </div> 
                  </div>
               

             
                <div className="col-sm-6">
                        <div className="col-sm-12 m-t-60">
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" 
                            data-target="#appointment-book-layout-add-btn2" style={{width:'100px', margin:'15px 5px 0px 0'}}
                            onClick={(e)=>{this.state.layoutSelected == 0 ?this.checkgroupselected():this.setState({addLayoutUser:true})}}> Add </button>
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', margin:'15px 5px 0px 0px'}} onClick={()=>this.removeLayoutUser('','delete','')}>Remove</button>
                         {/*}   <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', marginBottom:'5px'}}>Up</button>
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', marginBottom:'5px'}}>Down</button>
                        */}
                      </div>
                    <div className="col-sm-12">
                        <div className="appointment-book-layout-table-2" style={{/*marginLeft:'12px'*/}}>
                           {/*} <table className="calendar table table-bordered tablestyle">
                            <thead>
                                <tr>
                                    <th> Name </th>
                                    <th> Location </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> Dr. lvor Cure </td>
                                    <td> Main surgery (Usual Location) </td>
                                </tr>
                            </tbody>
                            </table>*/}
                             <CommonGrid
                              crud={this.state.layoutUserCrud}
                              key={this.state.key}
                              />  
                    </div>
                </div>
                </div>	
                </div>
          
                <div className="row row-distance"> 
                <div className="col-sm-1"> </div>
                <div className="col-sm-10">
                    <p> Use the SHIFT key and the allow keys to move providers up and down the list.  </p>
                </div>
            </div>
        </div>
        <div className="modal-footer">
            <div className="row">
                <div className="col-sm-10"> </div>
                <div className="col-sm-2">
                    <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.reloadKey()}>Cancel</button> 
                </div>
            </div>
        </div>
        {this.state.addLayout ?
            <AddLayout showModal={this.state.addLayout} popFor='Add' reloadKey={this.reloadPage}/> :''}
        {this.state.editLayout ?
            <AddLayout showModal={this.state.editLayout} popFor='Edit' reloadKey={this.reloadPage} ids={this.state.layoutSelected}/> :''}
        {this.state.addLayoutUser ?
            <AddLayoutUser showModal={this.state.addLayoutUser} popFor='Add' reloadKey={this.reloadPage}  groupid={this.state.layoutSelected}/> :''}
           
    </div>
    )}
    </Popup>
   )}
}

const mapDispatchToProps = dispatch => {
    return {
        apiDelete:data =>dispatch(api_delete_layout(data)),
        apigrpremovemember:data=>dispatch(api_remove_ongroup_delete(data)),
        apiDeleteUser:data=>dispatch(api_delete_user(data)),
        apigetsettings:(data)=>dispatch(api_get_settings(data))
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  
  
  export default connect(mapStateToProps,mapDispatchToProps)(AppointmentLayout);
