import React, { Component } from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import {
  api_investigations_master,
  api_patient_investigations,
  api_add_patient_investigations
} from "../../../DS/DS.Observations";
import Showhelp from './../../showHelp';
import cicon from "./../../../assets/img/cicon.png";
import {api_get_record_values} from '../../../DS/DS.Observations';

class Investigationaddvalues extends Component {
  constructor(props)
  {
    super(props);
    this.state = {
      investigationAddData: [],
      patientId: this.props.redux_patientId,
      startDate: new Date(),
      hba1:''
    };
  }
componentDidMount(){
  document.getElementById("ivntableadd").scrollIntoView({
    behavior: 'smooth'
  });
  this.props.observation_master();
     this.props.patient_observations({
        type: "showall",
        patient_id: this.state.patientId
      });

     this.props.getRecordValues({patient_id:this.props.redux_patientId,stdate:this.state.startDate}) .then(res=>{
       
     })
}
  handleDate = date => {
    console.log("test");
    this.setState({
      startDate: date
    });
  };
  addingData(e) {
    e.preventDefault();
    if(this.state.investigationAddData.length>0 && this.state.startDate!="" ){
    this.props.add_patient_investigations(this.state.investigationAddData);
    this.setState({ investigationAddData: [] });
    NotificationManager.success("Values added successfully!");
    this.props.close();

  } else {
    alert("Give Date & any value to proceed.");
  }
    // this.props.patient_investigations({type:'showall', patient_id: this.state.patientId});
  }
  updateInvestigationAddData(e, day, investigationId) {
    let items = this.state.investigationAddData;
    let inputValue = e.target.value;
    let obj = {
      investigationId: investigationId,
      value: inputValue,
      patientId: this.state.patientId,
      date: this.state.startDate
    };
    let found = 0;
    for (let i = 0; i < items.length; i++) {
      console.log(items[i]);
      console.log(items[i].investigationId);
      if (items[i].investigationId == investigationId) {
        items[i].value = inputValue;
        found = 1;
        break;
      } else {
        found = 0;
      }
    }
    if (found === 0) {
      items.push(obj);
    }

    this.setState({
      investigationAddData: items
    });
    console.log("on changing values", this.state);
  }
  focusTextInput_imm3(e) {
    e.preventDefault();
    document.getElementById("date-jumper_imm3").focus();
  }
  render() {
    return (
      <div class="invesvalue">
         <Popup open={this.props.open=="add_give_values"} modal closeOnDocumentClick={false}>
        <div style={{ width:'550px', marginLeft:'350px', backgroundColor:'#FFF', float:'left'}}>
        <div class="modal-header">
          <div className="popup-title">
            <span> Result Values <Showhelp gotohelp="result_values_help"/> </span> 
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => this.props.close()}>
                ×
              </span>
            </button>
            </div>
            </div>

            <div className="modal-body addinvestigation " style={{border:'0px'}}>
            <div
          className="col-sm-12"
       
          style={{ paddingLeft: 0 }}
          id="Graphdetails"
        >
          {/* <h5
            style={{
              backgroundColor: "#f4f4f4",
              padding: "5px 10px",
              marginBottom: 10
            }}
          >
             
          </h5> */}
          <div id="ivntableadd" className="row">
          <div className="col-sm-12">
            <div className="col-sm-3 mt-3">
            <label>Report Date:</label></div>
            <div className="col-sm-3 pl-0">
              {/* <input type="text" className="form-control form-control-sm" /> */}
              <DatePicker
                className="datePicker form-control form-control-sm"
                selected={
                   this.state.startDate
                }
                // selected={Date.parse(this.state.startDate)}
                onChange={this.handleDate}

                id="date-jumper_imm3"
                //ref={this.textInput}
                dateFormat="dd/MM/yyyy"
                 
                
                showMonthDropdown
                useShortMonthInDropdown
                showYearDropdown
                dateFormatCalendar="MMMM"
                yearDropdownItemNumber={15}
                scrollableYearDropdown
                className="form-control"
              />
              
                <label
                      className="label-input100"
                      style={{ marginRight: "0px", marginBottom: "0px", fontSize: "12px", paddingBottom: "0px",  zIndex:"1", paddingTop: "15px", position: "absolute", marginLeft: "-30px"
                      }}
                    >
                      <a onClick={e => this.focusTextInput_imm3(e)} href="#">
                        {<img src={cicon} />}
                      </a>
                    </label>

                    </div>
            </div>
          </div>
          </div>


          <div className="row">
            {this.props.observation_master_data != undefined &&
            this.props.observation_master_data.length > 0
              ? this.props.observation_master_data
                 // .filter(observation => observation.isActive == "1")
                  .map(observation => (
                    <div className="col-sm-6">
                      <div className="col-sm-6 mt-3 pr-0">
                      <label>
                        {" "}
                        {observation.name}:
                      </label></div>
                      <div className="col-sm-6 pl-0">
                        <input
                          type="number"
                          step="any"
                          onChange={e =>
                            this.updateInvestigationAddData(
                              e,
                              "today",
                              observation.investigations_id
                            )
                          }
                          className="form-control"
                        />
                        
                      </div>
                    </div>
                  ))
              : ""}
                  </div>
        

            <div class="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-xs"
                onClick={(e) => {
                  this.addingData(e);
                }}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
                onClick={() => this.props.close()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popup>
        
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    observation_master: () => dispatch(api_investigations_master()),
    patient_observations: data => dispatch(api_patient_investigations(data)),
    add_patient_investigations: data =>
      dispatch(api_add_patient_investigations(data)),
      getRecordValues:data=>dispatch(api_get_record_values(data))
  };
};
const mapStateToProps = state => {
  console.log(state);
  let observation_master_data = state.getObservations.investigations_master
    ? state.getObservations.investigations_master
    : [];
  //let patient_observations_data = (state.getObservations.patient_investigations !== undefined) ? (state.getObservations.patient_investigations.patient_investigations) : [];
  //let patient_observations_dates = (state.getObservations.patient_investigations !== undefined) ? (state.getObservations.patient_investigations.dates) : [];
  let redux_patientId =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].patient_id
      : "";
  return {
    observation_master_data,
    redux_patientId
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Investigationaddvalues);
