import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {api_add_appoinment_type,api_list_reason,api_update_appoinment_type} from '../../../DS/DS.ListPage';
import {api_add_reminder_type,api_update_reminder_type} from '../../../DS/DS.ReminderPage';

class AddReminderType extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        errors:[],
        reason:'',
        
    }
  }
  componentWillMount=()=>
  {
   //   alert(this.props.popFor)
    if(this.props.popFor=='Edit')
    {
        const getData={tbl:'reminder_type',pk:'type_id',ids:this.props.ids}
        this.props.listreminderReason(getData).then(
            res=>{
                this.setState({reason:res.data.content.type_name})
            }
        )
    }
  }
  handleValidation(){
    let errors = {};
    let formIsValid = true;
  
    //Description name
    if(this.state.reason==''){
       formIsValid = false;
       errors["reason"] = "Cannot be empty";
    }
     this.setState({errors: errors});
    return formIsValid;
  }
  onClickHandler=(e)=>
  {
    e.preventDefault();
    if(this.handleValidation()){
        if(this.state.isclinical==false)
        {
            this.state.isclinical=0
        }
        else
        {
            this.state.isclinical=1
        }
        const typeData={
                reason:this.state.reason,
                }
                this.props.addReminderType(typeData).then(
                  req=>{
                     if(req.data.status=="Duplicate"){
                        
                          NotificationManager.error('Duplicate reminder type cannot be created',)
                     }
                     else if(req.data.status=="Success")
                     {
                       
                          NotificationManager.success('New reminder type created',)
                     }
                     else{
                         
                         NotificationManager.error('Error encountered while creating reminder type',)
                     }
                     this.props.reloadPage()  
               }
              );
    }
} 
updateRecord=(e)=>
{
    e.preventDefault();
    if(this.handleValidation()){
        const typeData={
            reason:this.state.reason,
               ids:this.props.ids
              }
                this.props.UpdatereminderType(typeData).then(
                  req=>{
                     if(req.data.status=="Duplicate"){
                        
                          NotificationManager.error('Duplicate reminder type cannot be created',)
                     }
                     else if(req.data.status=="Success")
                     {
                       
                          NotificationManager.success('Selected reminder type modified',)
                     }
                     else{
                         
                         NotificationManager.error('Error encountered while modifying record',)
                     }
                     this.props.reloadPage()  
               }
              );
    }
}
 render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
            <div>
                  <div className="modal-header">
                     <span className="modal-title-2" id="exampleModalLabel"> Reminder Type  </span>
                     <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e)=>this.props.reloadPage()}>
                        <span aria-hidden="true">×</span>
                     </button>
                  </div>
                  <div className="modal-body">
                  <div className="row">
                     <div className="col-sm-12">
                        <div className="form-group">
                           <div className="col-sm-2"> 
                                 <label> Type : </label>
                           </div>
                           <div className="col-sm-10 cursor">
                              <input type="text" name="text" className="form-control" value={this.state.reason} onChange={(e)=>this.setState({reason:e.target.value})}/>
                              <span className="error" style={{color:'red'}}>{this.state.errors["reason"]}</span>
                               
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="modal-footer">
                  <div className="row">
                     <div className="col-sm-8"> </div>
                     <div className="col-sm-4">
                     {this.props.popFor=='Add' 
                        ? <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.onClickHandler(e)}>Save</button>
                        : <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.updateRecord(e)}>Update</button>
                        }                      <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close"  onClick={(e)=>this.props.reloadPage()}>Cancel</button> 
                     </div>
                  </div>
               </div>
            </div>
        )}
       </Popup>
    )}
} 
const mapDispatchToProps = dispatch => {
   return {
       addReminderType: data => dispatch(api_add_reminder_type(data)),
       listreminderReason : data => dispatch(api_list_reason(data)),
       UpdatereminderType: data => dispatch(api_update_reminder_type(data))
    };
 };
 const mapStateToProps = state => {
 
   let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
   
   return {
       redux_userId
   };
 };
 export default connect(mapStateToProps,mapDispatchToProps)(AddReminderType);
