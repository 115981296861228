import React, { Component } from 'react';
import './style.css'
import {api_adf_service,api_get_occupation,api_save_occupation,api_get_occ,api_delete_details} from '../../../DS/DS.FamilyHistory';
import { connect } from 'react-redux';
import NewOccupation from './addOccupation';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import {
    NotificationContainer,
    NotificationManager,
  } from 'react-notifications';
  import { confirmAlert } from 'react-confirm-alert';
  
class Occupation extends Component {
    constructor(props)
    {
        super(props);
        this.state={
            all_adf:[],
            addmodal:false,
            selected_length:0,
            showfor:'',
            selected_id:-1,
            key:0,
            foundData:[],
            occupation:'',
            emp_name:'',
            adf_service:0,
            retired:'',
            crud:{
                jsonGridVersion: "v2.0",
                grid: true,
                viewgrid: {
                  isVisible:true,
                  isPagination: true,
                  isFiltering: true,
                  isSelection:true,
                  deleteFunction:this.deleteOccupation,
                  isDateGroup:false,
                  isYearSeparator:false,
                  dateCol:'',
                  groupDateSelection:false,
                  cellClicked:'',
                  baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                  query: {
                    tb: "patients_occupation_details_tb",
                    cols: [
                      "details_id",
                      "occupation_name",
                      "employer_name",
                      "year_commenced",
                      "year_ceased",
                     
                      
                    ],
                   where: [
                   {
                    type: "and",
                    key: "isDeleted",
                    operator: "=",
                    value: 0
                  }],
                  joins:[
                    {
                      joinType: "inner",
                      joinTable: "occupation_master",
                      joinCondition: "occupation_master.occupation_id = patients_occupation_details_tb.occupation_id"
                    }],
                    primary_key:"details_id",
                  },
                  viewtabletitle: "",
                  columns: [
                    {name: "occupation_name", title: "Occupation" },
                    {name: "employer_name", title: "Employer" },
                    {name: "year_commenced", title: "Started" },
                    {name: "year_ceased" , title: "Stoped"}
                  
                  ],
                  rows: [],
                  sortinit: [{ columnName: "details_id", direction: "desc" }],
                  pagesize: 10,
                  getAllIds:false,
                  getallId:'',
                  highlightprev:false
                }
            },
            
            }
    }
   componentWillMount=()=>
   {
       this.props.apiadfservice().then(
           req=>
           {
               this.setState({all_adf:req.data.content})
           }
       )
       const patient={id:this.props.redux_patientId}
       this.props.apigetdetails(patient).then(req=>
        {
            if(req.data.content!='')
            {
            this.setState({foundData:req.data.content}) 
            this.setState({emp_name:req.data.content[0].occupation_name})
            }
        })
        this.props.apigetOcc(patient).then(req=>
            {
                if(req.data.content!='')
                {
                this.setState({adf_service:req.data.content[0].adf_service,retired:req.data.content[0].is_retired}) 
                } 
            })
   }
   reloadPage=()=>
   {
    const patient={id:this.props.redux_patientId}
    this.props.apigetdetails(patient).then(req=>
     {
         if(req.data.content!='')
         {
         this.setState({foundData:req.data.content}) 
         this.setState({emp_name:req.data.content[0].occupation_name})
         }
     })
     this.props.apigetOcc(patient).then(req=>
         {
            if(req.data.content!=''){
             this.setState({adf_service:req.data.content[0].adf_service,retired:req.data.content[0].is_retired}) }
         })
       this.setState({addmodal:false,key:Math.random()})
   }
   checkSelected=(actionfor)=>
   {
       if(actionfor=='Add')
       {
           if(this.state.selected_length>0)
           {
            alert("Please deselect the record to proceed")
           }
           else
           {
                this.setState({addmodal:true,showfor:actionfor})
           }
       }
       if(actionfor=='Edit')
       {
           if(this.state.selected_length==1)
           {
           
            this.setState({addmodal:true,showfor:actionfor})
           }
           else
           {
            alert("Please select single record to proceed")
           }
       }
    }
    onSaveClick=()=>
    {
        if(this.state.retired==true)
        {
            this.state.retired=1
        }
        else
        {
            this.state.retired=0
        }
        const pat={
            patient_id:this.props.redux_patientId,
            doc_id:this.props.redux_docId,
            is_retired:this.state.retired,
            adf_service:this.state.adf_service
        }
        this.props.saveOccupation({pat}).then(
            req=>{
                if(req.data.status=="Success")
                {
                    NotificationManager.success("Occupation details save scuccessfully")
                }
                if(req.data.status=="Error")
                {
                    NotificationManager.success("Error encountered while updation details")
                }
            }
        )
    }
    deleteOccupation=(id,action,totalLength)=>
    {
        if(action=='selection')
        {
            this.state.selected_id=id;
            this.state.selected_length=totalLength;
        }
        if(action=='delete')
        {
            if (this.state.selected_length> 0) {
                confirmAlert({
                  title: '',
                  message: 'You are about to delete occupation delete record. Do you want to proceed ? ',
                  buttons: [
                  {
                      label: 'Yes',
                      onClick: () => {
                        this.props.deleteDetails({id:this.state.selected_id}).then(
                          req=>this.reloadPage(),
                      NotificationManager.success(
                      'Occupation detail(s) deleted successfully',
                      //this.changeKey()
                      )
                    );
                  },
              },
              {
                label: 'No',
                onClick: () => { this.updateInGrid()},
              },
            ],
          });
        } else {
          NotificationManager.error('Please select occupation record(s) to be removed from the list');
        }
        }
    }
    render() {
        return (
            <div className="col-sm-12 pl-0 pr-0">
               {/*} <div className="col-sm-12 pl-0 pr-0">
                    <h5 class="sheadtitle">Occupation </h5>
        </div>*/}

<h5 class="col-sm-12 sheadtitle">Occupation</h5>


<div className="col-sm-12 pl-0 pr-0">
  <div className="col-sm-12" style={{width: '100%', height: 550, overflowY: 'auto'}}>
    <div className="row">
      <div className="col-sm-1 mt-3 pr-0">
        <label> Current Occupation: </label>
      </div>
      <div className="col-sm-2">
      <input type="text"  class="form-control" disabled="true" value={this.state.emp_name}></input>
      </div>
      <div className="col-sm-2 pl-0 mt-3">
        <label className="checkbox-inline">
        <input type="checkbox" checked={this.state.retired==1 ? 'checked' :''} onChange={(e)=>this.setState({retired:e.target.checked})} value={this.state.retired}/> Retired 
        </label>
      </div>
    </div>
    <div className="row mb-1">
      <div className="col-sm-12"> 
        <button id="session-timeout-dialog-keepalive" data-toggle="modal" data-target="#occupation-add" type="button" onClick={()=>this.checkSelected('Add')} className="btn btn-primary btn-xs">Add </button>
        <button id="session-timeout-dialog-keepalive" data-toggle="modal" data-target="#occupation-edit" type="button" onClick={()=>this.checkSelected('Edit')} className="btn btn-primary btn-xs">Edit </button>
        <button id="session-timeout-dialog-keepalive" data-toggle="modal" data-target="#" type="button" onClick={()=>this.deleteOccupation('','delete','')} className="btn btn-primary btn-xs">Delete</button>
      </div>
      <div className="col-sm-10"></div>
    </div>
    <div className="row">
      <div className="col-sm-12 mb-1">
        <h5 className="col-sm-12 sheadtitle">Clinical Images</h5>
        <div className="currentrx"> 
          <div style={{width: '100%', height: 310, overflowY: 'auto'}}>
             {/*} <table style={{margin:0}}>
                            <tr>
                                <th width="40%">Occupation</th>
                                <th width="30%">Employer</th>
                                <th width="15%">Started</th>
                                <th width="20%">Stoped</th>
                                <th width="15%">Primary</th>
                            </tr>
                            <tr>

                            </tr>
                            </table>*/}
                            <CommonGrid
                                crud={this.state.crud}
                                key={this.state.key}
                                /> 
          </div>
        </div>
      </div>
      <div>
                        <div align='left' className="col-sm-12 col-padding" style={{textAlign:'left',padding:'10px'}}>
                            <div class="card" disabled="true" styke={{border: '1px solid'}}>
                            {this.state.foundData != undefined && this.state.foundData.length> 0 && this.state.foundData.map((fd) => 
                                <p>
                                    <lable>{fd.occupation_name}</lable><br/>
                                    <lable>{fd.emp_name}</lable><br/>
                                    <lable >{fd.year_commenced !='' && fd.year_commenced !='0000'? 'Started '+fd.year_commenced :''} </lable><br/>
                                    <lable>{fd.year_ceased !='' && fd.year_ceased !='0000' ? 'Stopped '+fd.year_ceased :''}</lable><br/>
                                    <lable>{fd.asbestos ==1 ? 'Asbestos Exposure : Yes ' :''}</lable><br/>
                                    <lable>{fd.dust ==1 ? 'Dust Exposure : Yes':''}</lable><br/>
                                    <lable>{fd.radiation ==1 ? 'Radiation Exposure : Yes' :''}</lable><br/>
                                    <lable>{fd.animals ==1 ? 'Animal Exposure : Yes' :''}</lable><br/>
                                    <lable>{fd.is_primary ==1 ? 'Primary : Yes' :''}</lable><br/>

                                </p>
                            )}

                             </div>
                        </div>
                    </div>

    </div>
    <div className="row mb-3">
      <div className="col-sm-1 cus-widf mt-3"><label> ADF Service: </label></div>
      <div className="col-sm-3">
      <select  class="form-control" value={this.state.adf_service} onChange={(e)=>this.setState({adf_service:e.target.value})}>
                                <option value="0"> </option>
                                {this.state.all_adf != undefined && this.state.all_adf.length> 0 && this.state.all_adf.map((adfcat) => 
                                    <option key={adfcat.service_id} value={adfcat.service_id}>{adfcat.service_name}</option>)}
                            </select>
      </div>
      <div className="col-sm-8 mt-3 pr-0" style={{textAlign: 'right'}}>
      </div>
    </div>


    <div className="row mb-3">
    <div className="col-sm-12">
                            <label style={{visibility:'hidden'}}>for partioning</label>
                        </div>
    </div>


    <div className="row mb-3">
      <div className="col-sm-1 cus-widf mt-3"></div>
      <div className="col-sm-3">
      </div>
      <div className="col-sm-8 mt-3 pr-0" style={{textAlign: 'right'}}>
        <button className="btn btn-primary btn-xs" onClick={()=>this.onSaveClick()}>Save</button>
        <button className="btn btn-primary btn-xs">Cancel</button>
      </div>
    </div>
    
    
    </div>
</div>


               {(this.state.addmodal) ?
                <NewOccupation showmodal={this.state.addmodal} showfor={this.state.showfor} sid={this.state.selected_id} changeKey={this.reloadPage}/> : ''}
          
            </div>
         );
    }
}
const mapDispatchToProps = dispatch => {
    return {
             apiadfservice: (data) => dispatch(api_adf_service(data)),
             apigetdetails :(data) =>dispatch(api_get_occupation(data)),
             saveOccupation:(data) => dispatch(api_save_occupation(data)),
             apigetOcc:(data)=>dispatch(api_get_occ(data)),
             deleteDetails:(data)=>dispatch(api_delete_details(data))  
            };
  };
  const mapStateToProps = state => {
   console.log(">>> doc data",state.getDrAppointments.logged_info)
     let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
 
   return {
     redux_patientId,redux_docId
   };
 };
 export default connect(
    mapStateToProps,
  mapDispatchToProps
  ) 
 (Occupation);