import React, { Component } from "react";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import moment from "moment";

import CommonGrid from "../../crudcomponentV2/combinedcrud";
import DatePicker from "react-datepicker";

import cicon from "./../../../assets/img/cicon.png";
import InvestigationReports from "./investigationReports copy";
import {api_change_status} from '../../../DS/DS.Investigation';
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import NoteRecord from './noteRecord';
import CreateAppointment from './createappointment';
import Showhelp from './../../showHelp';

class InvestigationReport extends Component {
  constructor(props) {
    super(props);
    //  this.state.showGrid =props.showGrid;
    //console.log( props.doc_id);
    this.handleMyTasksCrud = this.handleMyTasksCrud.bind(this);
    this.state = {
      selectedRecLength:'',
      key:0,
      crudinv:
      {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.handleMyTasksCrud,
          isDateGroup: false,
          isYearSeparator: false,
        //  dateCol: "date",
          //cellClicked: this.handleInvestigationRowClick,
          cellClicked: "",
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          highlightprev:false,
          query: {
            tb: "patients_reports",
            cols: [
              "patients_report_id",
              "date",
              "SUBSTRING(test_name,1,50) as test_name",
              "SUBSTRING(content,1,10) as content",
              "status",
              "given_time",
              "checked_by_guid"
            ],
            where: [
              {
                type: "and",
                key: "patients_reports.isDeleted",
                operator: "!=",
                value: 1
              },
              {
                type: "and",
                key: "checked_by_guid",
                operator: "=",
                value: this.props.logged_info.content.details[0].user_id
              }
            ],
            joins: [
              {
                joinType: "inner",
                joinTable: "doctor_tb",
                joinCondition:
                  "patients_reports.checked_by_guid = doctor_tb.doctor_id"
              },
              {
                joinType: "inner",
                joinTable: "patients",
                joinCondition:
                  "patients_reports.patient_id = patients.patient_id"
              }
            ],
            customCols: ["CONCAT(doctor_tb.Firstname,' ',doctor_tb.Middlename) AS docname","CONCAT(patients.Firstname,' ',patients.Surname) AS patname"],
            primary_key: "patients_report_id"
          },
          viewtabletitle: "",
          columns: [
            { name: "patname", title: "Patient" },
            { name: "test_name", title: "Test Name" },
            { name: "content", title: "Content" },
            { name: "status", title: "Status" },
            { name: "given_time", title: "Given Time" },
            { name: "date_checked", title: "Date Checked" },
            { name: "docname", title: "Given By" }
          ],
          rows: [],
          sortinit: [{ columnName: "date", direction: "asc" }],
          pagesize: 10,
          getAllIds: true,
          getallId: this.getAllId
        }
      },
      recordnote:false,
      createappointment:false
    }
    }
  componentWillMount() {
   
  }
handleMyTasksCrud = (selectedId, action, checklength) => {
  if (action == "selection") {
    // this.setState({selectedRec: selectedId});
    // this.setState({selectedRecLength: checklength});
    this.state.selectedRec = selectedId;
    this.state.selectedRecLength = checklength;
  }
  };
  getAllId = ids => {
    console.log(ids);
  };
  markascontacted=(status)=>
  {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",this.state.selectedRec)
    const val={id:this.state.selectedRec,value:status}
    this.props.markascontacted(val).then(res=>{
      NotificationManager.success("Status updated");
     this.changeKey()
    })
  }
  changeKey = () => {
    this.setState({ key: Math.random(),recordnote:false,createappointment:false });
  };

  makeappt=()=>
  {
    if(this.state.selectedRecLength==1)
    {
      this.setState({createappointment:true})
    }
    else
    {
      alert('Please select single record to proceed.')
    }
  }
  render() {
      return (
        <Popup trigger={ <i className="fa fa-file fa-3x" style={{ fontSize: '2.5em', marginTop:'3px' }} />}
          position="right center" modal closeOnDocumentClick={false}>
          {close => (
            <div style={{ width:'1000px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>
              <div className="modal-header">
                <div className="popup-title">
                  <button type="button" className="close"  data-dismiss="modal" onClick={() => {close();}}  aria-label="Close" >
                    ×
                  </button>
                  <span>Investigation Report <Showhelp gotohelp="investigation_report_help"/></span>
                </div> 
              </div>
              <div className="modal-body" style={{color:'black'}}>
			              <div class="col-sm-12 mt-3">
                      <div class="row">
                          <div class="col-sm-2" >
                            <label>All results since</label>
                          </div>
                          <div class="col-sm-2">
		                        <select name="select" class="form-control mt-0">
		                          <option value="opt1">9/04/2019</option>
		                        </select>
                          </div>
                          <div class="col-sm-8">
                            <button id="session-timeout-dialog-keepalive" type="button"  class="btn btn-primary" onClick={()=>this.markascontacted('Contacted')}>Mark as Contacted</button>
                            <button id="session-timeout-dialog-logout" type="button" class="btn btn-primary" onClick={()=>this.markascontacted('Given')}>Mark result as given</button>
                            <button id="session-timeout-dialog-keepalive" type="button" class="btn btn-primary" onClick={()=>this.setState({recordnote:true})}>Record note</button>
                            <button id="session-timeout-dialog-logout" type="button" class="btn btn-primary" onClick={()=>this.makeappt()}>Make appointment</button>	
                          </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
				            <div id="referralstlist" class="panel-collapse collapse" style={{display:'block', float:'left', width:'100%'}}>
			                <div class="panel panel-default" style={{float: 'left', width: '100%'}}>
                        <div style={{float:'left', width:'100%'}}>

                          <CommonGrid
                            handleButton={this.handleButton}
                            crud={this.state.crudinv}
                            key={this.state.key}
                          /> 
                        </div>

                  </div>
                  </div>
                  {(this.state.recordnote) ?
                            <NoteRecord sid={this.state.selectedRec} isVisibleNote={this.state.recordnote} changeKey={this.changeKey}  reasontype='Investigation' attempted='' showbutton='false' patid={this.state.selectedRec}/> : ''}
                  {(this.state.createappointment) ? 
                  <CreateAppointment sid={this.state.selectedRec} showForm={this.state.createappointment} changeKey={this.changeKey}/> : ''}    
                </div>
              </div>{/*modal body end*/}
            </div>
          )}
        </Popup>
      );
    }
}
const mapDispatchToProps = dispatch => {
  return {
    markascontacted: data => dispatch(api_change_status(data)),
   
  };
};

const mapStateToProps = state => {
  // console.log(state);
  let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : [];
  let selectedPatientDetails;
  console.log(">>>>>>", state.getDrAppointments.logged_info.content.details[0].user_id);
  let logged_info =
  state.getDrAppointments.logged_info != undefined
    ? state.getDrAppointments.logged_info
    : "";   
  if (patientDetails.length > 0) {
    selectedPatientDetails = patientDetails[0];
  } else {
    selectedPatientDetails = "";
  }
  return {
    selectedPatientDetails, logged_info,patientDetails
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
) (InvestigationReport);
