import React, { Component } from "react";
import Form from "react-jsonschema-form";
import {NotificationContainer, NotificationManager} from 'react-notifications';
//import axios from "axios";
import { addForm } from "../../../DS/services";
const AgeSelector = props => {
  const { value, onChange } = props
  const handleChange = val => evt => {
    evt.preventDefault()
    onChange(val)
  }

  return (
    <div>
      <button
        style={{ backgroundColor: value === 20 ? 'red' : 'white' }}
        onClick={handleChange(20)}
      >
        20
      </button>
      <button
        style={{ backgroundColor: value === 30 ? 'red' : 'white' }}
        onClick={handleChange(30)}
      >
        30
      </button>
      <button
        style={{ backgroundColor: value === 40 ? 'red' : 'white' }}
        onClick={handleChange(40)}
      >
        40
      </button>
    </div>
  )
}
const widgets = {
  ageSelector: AgeSelector
}
class addform extends Component {
  state = {
    schema: {}
  };
  
  onSubmit = ({ formData }, e) => {
    let postBodyData = {
      formData: formData,
      prop: this.props.form
    };
    addForm(postBodyData)
    .then(res => {
     if(res.status === 200){
      
      this.setState({
        formData:{}
      });
      if(this.props.form.isBacktoGridOnSubmit == true){
        this.props.backtogrid();
      }
      NotificationManager.success(this.props.form.successMessage);
     }
       else {
        NotificationManager.error(this.props.form.errorMessage);
       }
    })
  };
  
  render() {
    console.log(this.props);
    const uiSchema = this.props.form.ui_schema;
    const log = type => console.log.bind(console, type);
    let formData = {gender: "1", name: "1", medical_center_id: "2", key_contact: "2", mobile: "2"};
    return (

      <div>
         <NotificationContainer/>
        <Form
          schema={this.props.form.schema}
          uiSchema={uiSchema}
          onChange={log("changed")}
          onSubmit={this.onSubmit}
          onError={log("errors")}
        />
      </div>
    );
  }
}

export default addform;
