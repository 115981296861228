import axios from 'axios';
export const api_add_screening =(data) =>dispatch =>{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=add_history`, data);
}
export const api_get_whistory=(data) =>dispatch =>{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=get_whistory`, data);
}
export const api_update_history=(data) =>dispatch =>{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=update_history`, data);
}
export const api_delete_history=(data) =>dispatch =>{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=delete_history`, data);
}


