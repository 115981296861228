import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {api_add_contact_note,api_list_reason,api_update_contact_note} from '../../../DS/DS.ListPage';
import AddContactType from './contactType';
class DocumentCleanUp extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        reason_name:'',
        errors:[],
        openContactType:false,
        key:0
       
    }
  }
  componentWillMount=()=>
  {
    if(this.props.popFor=='Edit')
    {
        const getData={tbl:'contact_note_reason',pk:'reason_id',ids:this.props.ids}
        this.props.listContactNote(getData).then(
            res=>{
                this.setState({reason_name:res.data.content.reason_name})
            }
        )
    }
  }
  handleValidation(){
    let errors = {};
    let formIsValid = true;
  
    //Reason name
    if(!this.state.reason_name){
       formIsValid = false;
       errors["reason_name"] = "Cannot be empty";
    }
     this.setState({errors: errors});
    return formIsValid;
  }
  onClickHandler=(e)=>
  {
    e.preventDefault();
    if(this.handleValidation()){
        const reasonData={
                reason:this.state.reason_name,
              }
                this.props.addContactNote(reasonData).then(
                  req=>{
                         NotificationManager.success('Record Added',)
                         this.props.reloadPage()
                      }
              );
    }
} 
updateRecord=(e)=>
{
    e.preventDefault();
    if(this.handleValidation()){
        const reasonData={
                reason:this.state.reason_name,
                ids:this.props.ids,
              }
                this.props.UpdateContactNote(reasonData).then(
                  req=>{
                         NotificationManager.success('Record Updated',)
                         this.props.reloadPage()
                      }
              );
    }
}
reloadPage=()=>
{
    this.setState({openContactType:false});
    this.setState({key:Math.random()})
}
 render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
            <div>
                    <div className="modal-header">
                        <span className="modal-title-2" id="exampleModalLabel"> Clean up documents  </span>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.reloadPage()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{height:'200px', overflow:'scroll'}}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="card clean-up-categories-inner-table" style={{padding:'15px'}}>
                                    <div className="card-block">
                                        <table className="calendar table table-bordered tablestyle">
                                            <thead>
                                                <tr>
                                                    <th> &nbsp; <span style={{fontSize:'16px'}}> Documents </span> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>&nbsp; Clinical Phoyograph </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="card clean-up-categories-inner-table" style={{padding:'15px'}}>
                                    <div className="card-block">
                                        <table className="calendar table table-bordered tablestyle">
                                            <thead>
                                                <tr>
                                                    <th> &nbsp; <span style={{fontSize:'16px'}}>Categories </span> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>&nbsp; Clinical Phoyograph </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row">
                            <div className="col-sm-8" style={{textAlign:'left'}}>
                                <input className="border-checkbox" type="checkbox" id="checkbox0"/>
                                <label className="border-checkbox-label" for="checkbox0" style={{display:'inline'}}> &nbsp; Save current Category as Subject</label>
                            </div>
                            <div className="col-sm-4">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Change</button>
                                <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.reloadPage()}>Close</button> 
                            </div>
                        </div>
                    </div>
            </div>
        )}
        </Popup>
 )}}

 const mapDispatchToProps = dispatch => {
    return {
        addContactNote: data => dispatch(api_add_contact_note(data)),
        listContactNote : data => dispatch(api_list_reason(data)),
        UpdateContactNote: data => dispatch(api_update_contact_note(data))
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(DocumentCleanUp);
