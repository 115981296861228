import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import {
  api_getDoctors,
  api_getTimeslots,
  api_getAppointments,
  api_modifyAppointments,
  api_getPatientDetails,
  api_set_cut_copy_details,
  api_setSelectedThisAppointment,
  api_save_waiting
} from "../../DS/DS.Appointments";
import Popup from "reactjs-popup";
import { wflow, wip } from "../../DS/general";
import Addappointment from "./add-appointment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./calendar.css";
import PatientDetails from "../PatientDetails";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Viewcelldetails from "./viewcelldetails";
import cicon from "./../../assets/img/cicon.png";
import CreateAccount from './../../components/appointment/createAccount';
 

import moment from "moment";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import MoveItem from "./MoveItem";
import ViewAppointmentText from "./ViewAppointmentText";
import CancelAppointment from './cancelAppointment';
class Calendarall extends Component {
  //State
  state = {
    sethightlight: 0,
    tabshow: "",
    addAppointmentTab: false,
    showPatientDetails: false,
    editAppointmentDetails: false,
    doctors: [],
    timeslots: [],
    start_datetime: this.props.startDate,
    calendardate: new Date(this.props.startDate),
    stateSearchParam: {
      start_datetime: this.props.startDate,
      end_datetime: "2019-08-18 04:16:00",
      open: false
    },
    selectedTableProp: {
      date: "",
      time: "",
      dr: ""
    },
    period: 1,
    cdate1: true,
    cdate2: false,
    cdate3: false,
    cutAppointmentId: "",
    cutAppointmentType: "",
    selected: {
      thisappointment: [],
      startDate: "",
      starttime: "",
      doctor_id: ""
    },
    selected_thisappointment: [],
    selected_startDate: "",
    selected_starttime: "",
    selected_doctor_id: "",
    is_move_item_open: false,
    is_view_text_open: false,
    is_view_cell_open: false,
    showCreateaccount: false,
    currentcellappointments: [],
    showconfirmalert:false,
    showcancelpop:false

  };

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
  }

  
closeCreateAccount = () => {
  this.setState({showCreateaccount:false,showcancelpop:false});
}

openCreateAccount = ()  => {
  setTimeout(
    function() {
      //Start the timer
      this.setState({showCreateaccount:true});
    }.bind(this),
    2000
  );
   
} 


  handlecontext = () => {
    console.log(this.state);
    setTimeout(
      function() {
        //Start the timer
        console.log(this.state);
      }.bind(this),
      3000
    );
  };
  focusTextInput() {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    // this.textInput.current.focus();
    document.getElementById("date-jumper").focus();
    // this.textInput.focus();
  }
  setRightClickOptions = (thisappointment, startDate, starttime, doctor_id) => {
    this.setState({ selected_thisappointment: thisappointment });
    this.setState({ currentcellappointments: thisappointment });
    this.setState({ selected_startDate: startDate });
    this.setState({ selected_starttime: starttime });
    this.setState({ selected_doctor_id: doctor_id });

    wflow(
      "setting rightclick",
      thisappointment,
      startDate,
      starttime,
      doctor_id
    );
  };

  handleScrollToElement2(event) {
    setTimeout(
      function() {
        //Start the timer
        wflow("scrolling to addmoredetail");
        const tesNode2 = ReactDOM.findDOMNode(this.refs.refaddmore);
        if (1) {
          tesNode2.scrollIntoView({
            behavior: "smooth"
          });
        }
      }.bind(this),
      2000
    );
  }

  setRightClickOptions2 = (
    thisappointments,
    startDate,
    starttime,
    doctor_id
  ) => {
    this.setState({ selected_thisappointment: thisappointments });
    this.setState({ currentcellappointments: thisappointments });
    this.setState({ selected_startDate: startDate });
    this.setState({ selected_starttime: starttime });
    this.setState({ selected_doctor_id: doctor_id });

    wflow(
      "setting rightclick",
      thisappointments,
      startDate,
      starttime,
      doctor_id
    );
  };
  toggle = this.toggle.bind(this);

  handleDate = date => {
    this.setState({ calendardate: date });
    var searchParam = {
      start_datetime: moment(date).format("YYYY-MM-DD"),
      end_datetime: moment(date).format("YYYY-MM-DD") + " 23:55:00"
    };

    this.props.loadDateCalendar(moment(date).format("YYYY-MM-DD"));
  };
  toggleMoveItem(e) {
    setTimeout(
      function() {
        //Start the timer
        this.setState({
          is_move_item_open: !this.state.is_move_item_open
        });
      }.bind(this),
      2000
    );
  }
  toggleViewText(e) {
    setTimeout(
      function() {
        //Start the timer

        wflow("currentcelldata", this.state.currentcellappointments);

        this.setState({
          is_view_text_open: !this.state.is_view_text_open
        });
      }.bind(this),
      1000
    );
  }
  closeTabshow = e => {
    //alert(1);
    this.setState({ tabshow: "" });
    this.setState({
      is_view_cell_open: !this.state.is_view_cell_open
    });
  };
  toggleViewCell = d => {
    this.setState({ tabshow: d });
    this.setState({
      is_view_cell_open: !this.state.is_view_cell_open
    });
    // alert(d);
  };

  //Day/Month
  handleChangeRadio1 = e => {
    this.setState({
      period: e.target.value,
      cdate1: true,
      cdate2: false,
      cdate3: false
    });
    console.log(e.target.value);
  };
  handleChangeRadio2 = e => {
    this.setState({
      period: e.target.value,
      cdate1: false,
      cdate2: true,
      cdate3: false
    });
    console.log(e.target.value);
  };
  handleChangeRadio3 = e => {
    this.setState({
      period: e.target.value,
      cdate1: false,
      cdate2: false,
      cdate3: true
    });
    console.log(e.target.value);
  };
  format = function date2str(x, y) {
    var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds()
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
      return ((v.length > 1 ? "0" : "") + eval("z." + v.slice(-1))).slice(-2);
    });

    return y.replace(/(y+)/g, function(v) {
      return x
        .getFullYear()
        .toString()
        .slice(-v.length);
    });
  };

  setPrevDate(date, period) {
    let myDate = new Date(date);
    let plusSeven = "";

    if (period == 7) plusSeven = new Date(myDate.setDate(myDate.getDate() - 7));

    if (period == 1) plusSeven = new Date(myDate.setDate(myDate.getDate() - 1));

    if (period == 30)
      plusSeven = new Date(myDate.setMonth(myDate.getMonth() - 1));

    this.setState({ calendardate: plusSeven });
    let formatted = this.format(new Date(plusSeven), "yyyy-MM-dd");
    console.log("prev date" + period);
    //alert(date + '' +  period + '' +  formatted);
    return formatted;
  }

  setNextDate(date, period) {
    let myDate = new Date(date);
    let plusSeven = "";
    if (period == 7) plusSeven = new Date(myDate.setDate(myDate.getDate() + 7));

    if (period == 1) plusSeven = new Date(myDate.setDate(myDate.getDate() + 1));

    if (period == 30)
      plusSeven = new Date(myDate.setMonth(myDate.getMonth() + 1));

    this.setState({ calendardate: plusSeven });
    let formatted = this.format(new Date(plusSeven), "yyyy-MM-dd");
    //alert(date + '' +  period + '' +  formatted);
    return formatted;
  }

  ddmonthyyyy(date) {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC"
    ];
    let current_datetime = new Date(date);
    let formatted_date =
      current_datetime.getDate() +
      "-" +
      months[current_datetime.getMonth()] +
      "-" +
      current_datetime.getFullYear();
    console.log(formatted_date);
    return formatted_date;
  }

  ddmmyyyy(date) {
    let current_datetime = new Date(date);
    let formatted_date =
      current_datetime.getDate() +
      "-" +
      (current_datetime.getMonth() + 1) +
      "-" +
      current_datetime.getFullYear();
    //  console.log(formatted_date);
    return formatted_date;
  }
  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }
  scrolltotime() {
    setTimeout(
      function() {
        wflow("scrolling to time");
        const starthere = ReactDOM.findDOMNode(this.refs.starthere);
        console.log("starthere 2", starthere);
        // if (1) {
        //  starthere.scrollIntoView();

        if (starthere != null) {
          starthere.scrollIntoView({
            behavior: "smooth"
          });
          // 80134a90fc3367759cd8e8e7f0607273857c57ce
        } else {
          console.log(" NO starthere");
        }
      }.bind(this),
      1000
    );
  }
  componentDidMount() {
    // alert(this.props.startDate);
    var searchParam = {
      start_datetime: this.state.stateSearchParam.start_datetime,
      end_datetime: this.state.stateSearchParam.end_datetime
    };
    this.props.getDoctors().then(doctors => {
      this.setState({ doctors: doctors.data.content });
    });
    this.props.getTimeslots().then(timeslots => {
      this.setState({ timeslots: timeslots.data.content });
      this.scrolltotime();
    });
    this.props.allAppointments(searchParam);
  }

  addMoreAppointment = () => {
    confirmAlert({
      message: "You are creating multiple appointments in single slot. Do you want to proceed?",
      title: "",

      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setTimeout(
              function() {
                //Start the timer
                this.getColumnProp(
                  null,
                  [],
                  this.state.selected_startDate,
                  this.state.selected_starttime,
                  this.state.selected_doctor_id
                );
              }.bind(this),
              1000
            );
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    });
  };

  triggerPatientDetails = () => {
    setTimeout(
      function() {
        //Start the timer
        wflow(
          "trigger patient details appointment format",
          this.state.selected_thisappointment
        );
        this.getColumnProp(
          null,
          this.state.selected_thisappointment,
          this.state.selected_startDate,
          this.state.selected_starttime,
          this.state.selected_doctor_id
        );
      }.bind(this),
      1000
    );
  };
  triggerEditAppointments = () => {
    setTimeout(
      function() {
        if (
          new Date(moment().format("YYYY/MM/DD")) <=
          new Date(this.state.selected_startDate)
        ) {
          //Start the timer
          this.editAppointmentDetails(
            this.state.selected_thisappointment,
            this.state.selected_startDate,
            this.state.selected_starttime,
            this.state.selected_doctor_id
          );
        } else {
          alert("Cannot perform this action.");
        }
      }.bind(this),
      1000
    );
  };

  editAppointmentDetails(thisappointment, date, time, doctor_id) {
    // alert(2);
    if (thisappointment.length != 0) {
      // alert("if");
      this.setState({ editAppointmentDetails: true });
      this.setState({ showPatientDetails: false });
      this.setState({ addAppointmentTab: true });

      this.props.getPatientDetails({ patient_id: thisappointment });
    } else {
      // alert("else");
      this.setState({ editAppointmentDetails: false });
      this.setState({ showPatientDetails: false });
      this.setState({ addAppointmentTab: true });
    }

    wflow("EDIT MODE", thisappointment, date, time, doctor_id);
  }
  getColumnProp(e, thisappointment, date, time, doctor_id) {
    this.setState({ currentcellappointments: thisappointment });
    wflow("getcolumn prop", thisappointment);
    if (e != null) {
      if (this.editAppointmentDetails.detail === 1) {
        // it was a single click
        alert("single");
      } else if (e.detail === 2) {
        // it was a double click
        // alert('double');
        // this.toggleViewText(null);
      }
    }
    wflow("get column props ", thisappointment);
    if (thisappointment.length != 0) {
      this.state.showPatientDetails = true;
      this.state.addAppointmentTab = false;
      this.state.editAppointmentDetails = false;
      this.setState({ selected_thisappointment: thisappointment });

      this.props.setSelectedThisAppointment(thisappointment);
      this.setState({ tabshow: "" });
      this.props.getPatientDetails({ patient_id: thisappointment });
    } else {
      this.state.showPatientDetails = false;
      this.state.addAppointmentTab = true;
      this.state.editAppointmentDetails = false;
    }

    wflow(
      "CTYPE:DATE selected cell item properties",
      thisappointment,
      date,
      time,
      doctor_id
    );
    //console.log(thisappointment, date, time, doctor_id);

    var selectedTableProp = { ...this.state.selectedTableProp };
    selectedTableProp.date = date;
    selectedTableProp.time = time;
    selectedTableProp.dr = doctor_id;
    this.setState({ selectedTableProp });
  }

  startdate(timeslot_starttime, date) {
    let slot_starttime = new Date(date + " " + timeslot_starttime);
    return slot_starttime;
  }

  enddate(timeslot_starttime, date) {
    let slot_starttime = new Date(date + " " + timeslot_starttime);
    let slot_endtime = new Date(slot_starttime.getTime() + 5 * 60000);
    return slot_endtime;
  }

  appointmentdatetime(startdatetime) {
    return new Date(startdatetime);
  }
  toggleActive = j => {
    if (j === this.state.isActive) {
      this.setState({
        isActive: null
      });
    } else {
      this.setState({
        isActive: j
      });
    }
  };

  handleScrollToElement(event) {
    setTimeout(
      function() {
        //Start the timer
        wflow("scrolling to addtab");
        const tesNode = ReactDOM.findDOMNode(this.refs.test);
        if (tesNode != null) {
          tesNode.scrollIntoView({
            behavior: "smooth"
          });
        }
      }.bind(this),
      2000
    );
  }

  rightClick(app, action) {
    //alert(action)
    if(action=='label-Ontheday')
    {
      console.log(">>>",this.state.selected_thisappointment)
      if(this.state.selected_thisappointment.comments!='')
            {
              confirmAlert({
                title: '',
                message: this.state.selected_thisappointment.comments,
                buttons: [
                {
                    label: 'Ok',
                    onClick: () => {
                 
                },
               },
             ],
            });
            }
    }
    // alert(moment().format("YYYY/MM/DD")+" "+app.date);
    if (new Date(moment().format("YYYY/MM/DD")) < new Date(app.date)) {
      //  alert("valid")

      setTimeout(
        function() {
          //Start the timer
          console.log(this.state);
          app = this.state.selected_thisappointment;

          let modifyAppointmentParam = {
            app: app,
            action: action,
            ctype: this.props.ctype,
            dateSearchParam: {
              start_datetime: this.props.startDate
            },
            doctorSearchParam: {
              doctor_guid: this.state.drId,
              start_datetime:
                new Date().getFullYear() +
                "-" +
                (new Date().getMonth() + 1) +
                "-" +
                new Date().getDate() +
                " 06:00:00",
              end_datetime: new Date(
                new Date().setDate(new Date().getDate() + 6)
              )
            }
          };
         /* if (modifyAppointmentParam.action == "cancel") {
            this.props.modifyAppointments(modifyAppointmentParam)
          }*/
          if (modifyAppointmentParam.action == "delete") {
            confirmAlert({
              message: "You are about to delete an appointment. Do you want to proceed?",
              title: "",

              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    this.props.modifyAppointments(modifyAppointmentParam);
                    NotificationManager.success(
                      "Appointment Deleted Successfully!"
                    );
                  }
                },
                {
                  label: "No",
                  onClick: () => {}
                }
              ]
            });
          } else {
            this.props.modifyAppointments(modifyAppointmentParam);
            NotificationManager.success(
              "Appointment Status Updated Successfully!"
            );
          }

          wflow("right click action", modifyAppointmentParam);
        }.bind(this),
        3000
      );
    } else {
      alert("Cannot perform for past dates");
    }
  }

  rightClickCut(app, date, time, drId, type) {
    setTimeout(
      function() {
        //Start the timer

        if (
          type == "cut" &&
          new Date() > new Date(this.state.selected_thisappointment.start_time)
        ) {
          alert("Couldn't Cut appointment in Past Date/time.");
        } else {
          app = this.state.selected_thisappointment.appoinment_id;
          this.setState({ cutAppointmentId: app });
          this.setState({ cutAppointmentType: type });

          this.props.set_cut_copy_details({
            cutAppointmentId: app,
            cutAppointmentType: type
          });

          wflow("rightclick cut", app, type);
         if(type == "cut"){
          NotificationManager.success(
            "Appointment Cut Successfully! Please Paste it on destination cell"
          );
         } 
         if(type=="copy"){
          NotificationManager.success(
            "Appointment Copied Successfully! Please Paste it on destination cell"
          );
         }
          
        }
      }.bind(this),
      1000
    );
    //set state cutAppointmentId
  }
  rightClickPaste(appId, date, time, drId, type) {
    let canBook = true;
    if (appId != "") {
      setTimeout(
        function() {
          if (new Date() > new Date(date + " " + time)) {
            alert("Couldn't Paste appointment in Past Date/time.");
            canBook = false;
          } else {
            canBook = true;
            if (this.props.appointments.length > 0) {
              for (let i = 0; i < this.props.appointments.length; i++) {
                if (
                  this.props.appointments[i].doctor_id ==
                    this.state.selected_doctor_id &&
                  this.props.appointments[i].patient_id ==
                    this.state.selected_thisappointment.patient_id &&
                  this.state.selected_startDate +
                    " " +
                    this.state.selected_starttime +
                    ":00" ==
                    this.props.appointments[i].start_time
                ) {
                  canBook = false;
                  alert(
                    "Couldn't book appointment, Already same person found on timeslot."
                  );
                }
              }
            }
          }

          //alert("insideif")
          if (canBook) {
            //Start the timer
            let modifyAppointmentParam = {
              cut_copy: type,
              action: "cut_copy_paste",
              appId: appId,
              date: date,
              time: this.state.selected_starttime,
              drId: this.state.selected_doctor_id,
              ctype: this.props.ctype,
              dateSearchParam: {
                start_datetime: this.props.startDate
              },
              doctorSearchParam: {
                doctor_guid: this.state.drId,
                start_datetime:
                  new Date().getFullYear() +
                  "-" +
                  (new Date().getMonth() + 1) +
                  "-" +
                  new Date().getDate() +
                  " 06:00:00",
                end_datetime: new Date(
                  new Date().setDate(new Date().getDate() + 6)
                )
              }
            };

            this.props.modifyAppointments(modifyAppointmentParam);

            wflow(
              "rightclick paste",
              this.state.cutAppointmentId,
              modifyAppointmentParam
            );

            this.setState({cutAppointmentId: ""});
            NotificationManager.success("Appointment Pasted Successfully! ");
          }
        }.bind(this),
        4000
      );
    } else {
      alert("Please cut/copy to perform Paste");
    }
  }
  saveWaiting=(statfor,appId)=>
  {
    if(statfor=='waiting')
    {
          console.log('<><><><><><><>',new Intl.DateTimeFormat('en-US', {day: '2-digit',month: '2-digit',year: 'numeric'}).format(new Date()));

          console.log('<><><><><><><>', new Date().getDate());
          const val={
            appid:appId.appoinment_id,
            statfor:statfor
          }
          this.props.saveWaitingStatus(val).then(res=>{
          
          })
    }
    if(statfor=='Cancel')
    {
      this.setState({showcancelpop:true})
    }
  }

  renderTableColumnData(
    startDate,
    starttime,
    doctor_id,
    j,
    minutes_per_appointment
  ) {
    let tableDataContent = "";
    let multiClrtableDataContent = [];
    let classContent = "";
    let existTableData = 0;
    let thisappointment = [];
    let thiscellappointments = [];
    let hasContent = true;
    let status = "blacktext";

    if (this.props.appointments.length > 0) {
      for (let i = 0; i < this.props.appointments.length; i++) {
        if (
          this.props.appointments[i].doctor_id == doctor_id &&
          this.startdate(starttime, this.props.appointments[i].date) <=
            this.appointmentdatetime(this.props.appointments[i].start_time) &&
          this.enddate(starttime, this.props.appointments[i].date) >
            this.appointmentdatetime(this.props.appointments[i].start_time)
        ) {
          let commented_value='';
          if(this.props.appointments[i].dressing!='')
          {
            commented_value= '-'+this.props.appointments[i].dressing
          }
          wflow("inside cell appointments", this.props.appointments[i]);
          existTableData = 1;
          tableDataContent +=
            tableDataContent == ""
              ? this.props.appointments[i].Firstname + commented_value.substring(0,10)
              : ", " + this.props.appointments[i].Firstname;

          status = this.props.appointments[i].status
            ? this.props.appointments[i].status
            : "blacktext";
          multiClrtableDataContent.push(
            <span style={{ padding: "5px" }} className={status}>
              {this.props.appointments[i].Firstname.slice(0, 3)}{" "}
            </span>
          );

          wflow(
            "inside cell appointments name",
            tableDataContent,
            multiClrtableDataContent
          );
          thisappointment = this.props.appointments[i];
          thiscellappointments.push(this.props.appointments[i]);
          if (this.props.appointments[i].status == "label-unavailable") {
            classContent = "label-unavailable";
          }
          if (this.props.appointments[i].status == "label-Ontheday") {
            
            classContent = "label-Ontheday";
          }
          if (this.props.appointments[i].status == "label-warning") {
            classContent = "label-warning";
          }
          if (this.props.appointments[i].status == "label-primary") {
            classContent = "label-primary";
          }
          if (this.props.appointments[i].status == "label-success") {
            classContent = "label-success";
          }
          if (this.props.appointments[i].status == "label-completed") {
            classContent = "label-completed";
          }
          if (this.props.appointments[i].status == "label-attend") {
            classContent = "label-attend";
          }
          if (this.props.appointments[i].status == "label-danger") {
            classContent = "label-danger";
          }
          if (this.props.appointments[i].status == "label-elsewhere") {
            classContent = "label-elsewhere";
          }
          hasContent = true;
          //break;
        } else {
          existTableData = 0;
          // tableDataContent = putspace();
        }
      }
    } else {
      tableDataContent = putspace();
      multiClrtableDataContent = putspace();
      hasContent = false;
    }

    function putspace() {
      return (
        <span>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
        
        </span>
      );
      // return <span>--------</span>;
    }

    function prepare(multiClrtableDataContent, status, firstname) {
      if (multiClrtableDataContent == "") {
        status = status ? status : "blacktext";
        return <span className={status}>{firstname}</span>;
      }
    }

    let tabledata = (
      <td
        rowSpan={minutes_per_appointment > 5 ? minutes_per_appointment / 5 : 1}
        className={thiscellappointments.length < 2 ? classContent : ""}
        style={
          this.state.isActive === j
            ? {
                padding: "1px 5px",
                border: "2px solid #57b846",
                fontSize: "11px"
              }
            : { background: "", padding: "1px 5px", fontSize: "11px" }
        }
        key={j}
        key={Math.random()}
        // onDoubleClick={e => this.toggleViewText(e)}
        onClick={e => {
          thiscellappointments.length < 2 &&
            this.getColumnProp(
              e,
              thisappointment,
              startDate,
              starttime,
              doctor_id
            );

          thiscellappointments.length < 2 && this.toggleActive(j);

          thiscellappointments.length < 2 && this.handleScrollToElement();

          //  thiscellappointments.length<2 &&
          //  this.toggleViewCell();
        }}
      >
        <div className="ColumnContainer">
          {thiscellappointments.length == 0 && (
            <ContextMenuTrigger
              collect={() =>
                this.setRightClickOptions([], startDate, starttime, doctor_id)
              }
              id="3"
            >
              {/*Parser(insideTable)*/} {putspace()}
            </ContextMenuTrigger>
          )}

          {thiscellappointments.length > 1 && (
            <ContextMenuTrigger
              collect={() =>
                this.setRightClickOptions(
                  thiscellappointments,
                  startDate,
                  starttime,
                  doctor_id
                )
              }
              id="2"
            >
              <span
                onClick={e =>
                  this.toggleViewCell(
                    "is_view_cell_open" +
                      startDate +
                      "_" +
                      starttime +
                      "_" +
                      doctor_id
                  )
                }
              >
                {" "}
                {multiClrtableDataContent}
                {/*{tableDataContent.slice(0, 10) + "..."}{" "}*/}
              </span>
              <Popup
                closeOnDocumentClick={false}
                open={
                  this.state.tabshow ==
                  "is_view_cell_open" +
                    startDate +
                    "_" +
                    starttime +
                    "_" +
                    doctor_id
                }
                //trigger={<span> {tableDataContent.slice(0, 10) + "..."} </span>}
                modal
              >
                {close => (
                  <div>
                    <div class="popup-title">
                      <span style={{ color: "#000", fontSize: 17 }}>
                        {" "}
                        View Slot Details{" "}
                      </span>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span
                          aria-hidden="true"
                          onClick={e => {
                            close();
                            this.closeTabshow(e);
                          }}
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    {this.state.saving ? (
                      <div>Saving</div>
                    ) : (
                      <div>
                        <div className="modal-body addinvestigation">
                          <div className="col-sm-12">
                            <table
                              class="calendar"
                              style={{ fontSize: 17, marginTop: 30 }}
                            >
                              {thiscellappointments.map(
                                thissingleappointment => (
                                  <tr>
                                    <td
                                      className={
                                        thissingleappointment.status
                                          ? thissingleappointment.status
                                          : "blacktext"
                                      }
                                      style={{ width: 300, height: 30 }}
                                      onClick={e => {
                                        this.getColumnProp(
                                          e,
                                          thissingleappointment,
                                          startDate,
                                          starttime,
                                          doctor_id
                                        );

                                        this.toggleActive(j);

                                        this.handleScrollToElement();

                                        //  thiscellappointments.length<2 &&
                                        //  this.toggleViewCell();
                                      }}
                                    >
                                      {" "}
                                      <ContextMenuTrigger
                                        collect={() =>
                                          this.setRightClickOptions(
                                            thissingleappointment,
                                            startDate,
                                            starttime,
                                            doctor_id
                                          )
                                        }
                                        id="1"
                                      >
                                        {thissingleappointment.Firstname}{" "}
                                      </ContextMenuTrigger>
                                      <ContextMenu
                                        onShow={() => {
                                          alert("on show");
                                          this.handlecontext();
                                        }}
                                        id={thissingleappointment.appoinment_id}
                                      >
                                        <MenuItem
                                          data={{ action: "add_appintment" }}
                                          onClick={() => {
                                            this.addMoreAppointment();
                                            this.handleScrollToElement();
                                          }}
                                        >
                                          Add One More Appointment
                                        </MenuItem>
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={() => {
                                            this.triggerEditAppointments();
                                            this.handleScrollToElement();
                                          }}
                                        >
                                          Edit Appointment
                                        </MenuItem>
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={() => {
                                            this.triggerPatientDetails();
                                            this.handleScrollToElement();
                                          }}
                                        >
                                          Patient Details
                                        </MenuItem>
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          //onClick={() => ()}
                                          onClick={e => this.openCreateAccount(e)}
                                        >
                                          Create Account
                                        </MenuItem>
                                        <MenuItem divider />
                                        <MenuItem
                                          data={{ action: "unavailable" }}
                                          onClick={() => {
                                            this.rightClick(
                                              this.state
                                                .selected_thisappointment,
                                              "label-unavailable"
                                            );
                                          }}
                                        >
                                          Unavailable
                                        </MenuItem>
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={() => {
                                            this.rightClick(
                                              this.state
                                                .selected_thisappointment,
                                              "label-Ontheday"
                                            );
                                          }}
                                        >
                                          On the Day
                                        </MenuItem>
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={() => {
                                            this.rightClick(
                                              this.state
                                                .selected_thisappointment,
                                              "label-warning"
                                            );
                                          }}
                                        >
                                          Waiting
                                        </MenuItem>
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={() => {
                                            this.rightClick(
                                              this.state.selected_thisappointment,
                                              "label-primary"
                                            );
                                          }}
                                        >
                                          With Doctor
                                        </MenuItem>
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={() => {
                                            this.rightClick(
                                              this.state
                                                .selected_thisappointment,
                                              "label-success"
                                            ); 
                                          }}
                                        >
                                          At Billing
                                        </MenuItem>
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={() => {
                                            this.rightClick(
                                              this.state
                                                .selected_thisappointment,
                                              "label-completed"
                                            );
                                          }}
                                        >
                                          Completed
                                        </MenuItem>
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={() => {
                                            this.rightClick(
                                              this.state
                                                .selected_thisappointment,
                                              "label-attend"
                                            );
                                          }}
                                        >
                                          Did Not Attend
                                        </MenuItem>
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={() => {
                                            this.rightClick(
                                              this.state
                                                .selected_thisappointment,
                                              "label-danger"
                                            );
                                          }}
                                        >
                                          Urgent
                                        </MenuItem>
                                        
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={() => {
                                            this.rightClick(
                                              this.state
                                                .selected_thisappointment,
                                              "label-elsewhere"
                                            );
                                          }}
                                        >
                                          Elsewhere
                                        </MenuItem>
                                        <MenuItem divider />
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={() => {
                                            this.rightClickCut(
                                              this.state
                                                .selected_thisappointment
                                                .appoinment_id,
                                              this.state.selected.startDate,
                                              this.state.selected.starttime,
                                              this.state.selected.doctor_id,
                                              "cut"
                                            );
                                          }}
                                        >
                                          Cut
                                        </MenuItem>
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={() => {
                                            this.rightClickCut(
                                              this.state
                                                .selected_thisappointment
                                                .appoinment_id,
                                              this.state.selected.startDate,
                                              this.state.selected.starttime,
                                              this.state.selected.doctor_id,
                                              "copy"
                                            );
                                          }}
                                        >
                                          Copy
                                        </MenuItem>
                                        <MenuItem
                                        disabled={this.state.cutAppointmentId==""?true:false}
                                          data={{ action: "bar" }}
                                          onClick={() =>
                                            this.rightClickPaste(
                                              this.props.get_cut_copy_details
                                                .cutAppointmentId,
                                              this.props.startDate,
                                              this.state.selected_starttime,
                                              this.state.selected_doctor_id,
                                              this.props.get_cut_copy_details
                                                .cutAppointmentType
                                            )
                                          }
                                        >
                                          Paste
                                        </MenuItem>

                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={e => {
                                            this.closeTabshow(e);
                                            this.toggleMoveItem(e);
                                          }}
                                        >
                                          Move
                                        </MenuItem>
                                        <MenuItem divider />
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={e => this.toggleViewText(e)}
                                        >
                                          View Text
                                        </MenuItem>
                                        <MenuItem
                                          data={{ action: "bar" }}
                                          onClick={() => {
                                            this.rightClick(
                                              this.state
                                                .selected_thisappointment,
                                              "delete"
                                            );
                                          }}
                                        >
                                          Delete / Remove
                                        </MenuItem>
                                      </ContextMenu>
                                    </td>
                                  </tr>
                                )
                              )}
                            </table>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            className="btn btn-default"
                            data-dismiss="modal"
                            onClick={e => {
                              close();
                              this.closeTabshow(e);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Popup>
            </ContextMenuTrigger>
          )}

          {thiscellappointments.length > 1 && (
            <div className="hiddenMore">
              {/* <span> 
                Doctor: Vishnu <br />
                Time: 12-January-2020 03:30:00
              
              </span> */}
              <table class="calendar" style={{zIndex:'0'}}>
                {thiscellappointments.map(thissingleappointment => (
                  <tr>
                    <td
                      className={
                        thissingleappointment.status
                          ? thissingleappointment.status
                          : "blacktext"
                      }
                    >
                      {" "}
                      {thissingleappointment.Firstname}{" "}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          )}

          {thiscellappointments.length == 1 && (
            <ContextMenuTrigger
              collect={() =>
                this.setRightClickOptions(
                  thisappointment,
                  startDate,
                  starttime,
                  doctor_id
                )
              }
              id="1"
            >
              {/*Parser(insideTable)*/}{" "}
              {tableDataContent.length > 0 ? tableDataContent : "ssssss"}
            </ContextMenuTrigger>
          )}
        </div>
      </td>
    );
    return tabledata;
  }

   addMinutes = (time, minsToAdd) =>  {
    function D(J){ return (J<10? '0':'') + J;};
    var piece = time.split(':');
    var mins = piece[0]*60 + +piece[1] + +minsToAdd;
  
    return D(mins%(24*60)/60 | 0) + ':' + D(mins%60);  
  }  
  
  

  isSkipable = (currentTimeSlot, doctor) => {
    let minutes_per_appointment = doctor.minutes_per_appointment;
    let doctor_id = doctor.doctor_id;
    let doctor_spec_from = currentTimeSlot; 
    let doctor_spec_to = this.addMinutes(currentTimeSlot, minutes_per_appointment);  // '18:35'
    let timeslots = this.state.timeslots;
    //basic cell value is 5minutes
    //howmany splits ? if it is more than 5 , split is 0 if it is 5 or below
    let split = minutes_per_appointment > 5 ? minutes_per_appointment / 5 : 0;
    let position = 0;
    let isProceed = true;


    for (let i = 0; i < timeslots.length; i++) {
      position = i;
      if (timeslots[i].full == currentTimeSlot) {
        // console.log("POSITION ", currentTimeSlot, minutes_per_appointment, position, (split!=0? position%split: 0));



        if (this.props.appointments.length > 0) {
          for (let i = 0; i < this.props.appointments.length; i++) {
            if (
              this.props.appointments[i].doctor_id == doctor_id 
              //&& this.startdate(starttime, this.props.appointments[i].date) <=
               // this.appointmentdatetime(this.props.appointments[i].start_time) &&
              //this.enddate(starttime, this.props.appointments[i].date) >
               // this.appointmentdatetime(this.props.appointments[i].start_time)
            ) {
              if (
                (new Date(this.props.appointments[i].start_time) <=
                new Date(
                  this.props.startDate +
                    " " +
                    currentTimeSlot)
                )
                && 
                (new Date(this.props.startDate + " " +this.addMinutes(timeslots[i].full, this.props.appointments[i].appointment_length)) >
                new Date(
                  this.props.startDate +
                    " " +
                    currentTimeSlot)
                )
                ){
                 
                  split = this.props.appointments[i].appointment_length > 5 ? this.props.appointments[i].appointment_length / 5 : 0;
                  console.log("INSIDEAPPOINTMENT DOCTOR (CURRENT TIME LIES BETWEEN APPSTART END)", "split", split, "app length", this.props.appointments[i].appointment_length , "starttime", this.props.appointments[i].start_time, "startdate", this.props.startDate, "doctor id", doctor_id, "min per app", minutes_per_appointment, "doc spec from", doctor_spec_from, "doc spec to", doctor_spec_to, "current", currentTimeSlot);
                 // break;
                }


              // if (
              //   (new Date(this.props.appointments[i].start_time) >=
              //   new Date(
              //     this.props.startDate +
              //       " " +
              //       doctor_spec_from)
              //   )
              //   &&
              //   (new Date(this.props.appointments[i].start_time) <=
              //   new Date(
              //     this.props.startDate +
              //       " " +
              //       doctor_spec_to)
              //   )
              //   )
              //   {
              //     console.log("INSIDEAPPOINTMENT DOCTOR (INSIDE TIME)", this.props.appointments[i].appointment_length , this.props.appointments[i].start_time, this.props.startDate, doctor_id, minutes_per_appointment, doctor_spec_from, doctor_spec_to);
              //     split = this.props.appointments[i].appointment_length > 5 ? this.props.appointments[i].appointment_length / 5 : 0;
              //   }
              
            }}}

        let d = split != 0 ? position % split : 0;
        isProceed = d == 0 ? true : false;
        break;
      }
      


      
    }

    return isProceed;
  };
  render() {
    let j = 0;
    let sethightlight = 0;
    const { doctors, timeslots } = this.state;
    const { appointments } = this.props;
    const show = this.state.menu ? "show" : "";

    let skip = [];

    for (let i = 0; i < this.state.doctors.length; i++) {
      let id_item = {
        doctor_id: doctors[i].doctor_id,
        merge_cell_count: doctors[i].minutes_per_appointment,
        temp_count: 0
      };
      skip.push(id_item);
    }

    return (
      <div>
        <MoveItem
          ctype={this.props.ctype}
          closeTabshow={this.closeTabshow}
          selected_starttime={this.state.selected_starttime}
          selected_startDate={this.state.selected_startDate}
          selected_thisappointment={this.state.selected_thisappointment}
          selected_doctor_id={this.state.selected_doctor_id}
          open={this.state.is_move_item_open}
          close={e => this.toggleMoveItem(e)}
        />
        <CreateAccount open={this.state.showCreateaccount} from="calendar" selected_appointment2={this.state.selected_thisappointment}  closeCreateAccount={this.closeCreateAccount}/>
        <ViewAppointmentText
          setRightClickOptions={this.setRightClickOptions}
          handlecontext={this.handlecontext}
          addMoreAppointment={this.addMoreAppointment}
          handleScrollToElement={this.handleScrollToElement}
          triggerEditAppointments={this.triggerEditAppointments}
          currentcellappointments={this.state.currentcellappointments}
          open={this.state.is_view_text_open}
          close={e => this.toggleViewText(e)}
        />
        <div className="col-md-12 p-0" style={{ top: "-10px",zIndex:'0' }}>
          <NotificationContainer />
          {/* Box Comment */}
          <div className="box box-widget m-0">
            <div
              className="box-header with-border"
              style={{
                marginTop: "10px",
                padding: "10px 0",
                background: "#fff"
              }}
            >
              <div className="user-block">
                <div className="col-lg-5 col-xl-5 align-left">
                  <span
                    className="username"
                    data-widget="collapse"
                    style={{ fontWeight: "unset" }}
                  >
                    <a href="#">
                      {" "}
                      <span 
                        style={{
                          fontWeight: "bold",
                          color: "#009688",
                          fontSize: "18px",
                          letterSpacing: "0.3px"
                        }}
                      >
                        {" "}
                        Doctor Appointments -{" "}
                        </span><span style={{color: "#333"}}>
                        {"Dr." +
                          this.props.logged_info.content.details[0]
                            .Firstname}{" "}
                      </span>
                    </a>
                  </span>
                </div>
                <div className="col-lg-3 col-xl-3 ">
                  <span className="username" data-widget="collapse" style={{marginTop: '5px'}}>
                    {" "}
                    {this.ddmmyyyy(this.props.startDate)}
                  </span>
                </div>
                <div
                  className="col-lg-4 col-xl-4 align-right"
                  style={{ display: "flex" }}
                >
                  <label style={{ marginRight: "23px", fontSize: "12px", marginTop: '5px' }}>
                    <input
                      type="radio"
                      name="cdate1"
                      value="1"
                      onChange={this.handleChangeRadio1}
                      checked={this.state.cdate1}
                    />{" "}
                    &nbsp;Day{" "}
                  </label>
                  <label style={{ marginRight: "23px", fontSize: "12px", marginTop: '5px' }}>
                    <input
                      type="radio"
                      name="cdate2"
                      value="7"
                      onChange={this.handleChangeRadio2}
                      checked={this.state.cdate2}
                    />{" "}
                    &nbsp;Week{" "}
                  </label>
                  <label style={{ marginRight: "23px", fontSize: "12px", marginTop: '5px' }}>
                    <input
                      type="radio"
                      name="cdate3"
                      value="30"
                      onChange={this.handleChangeRadio3}
                      checked={this.state.cdate3}
                    />{" "}
                    &nbsp;Month{" "}
                  </label>

                  <div>
                    <DatePicker
                      //ref={this.textInput}
                      ref={input => {
                        this.textInput = input;
                      }}
                      id="date-jumper" style={{marginTop:'0px'}}
                      inputRef={ref => {
                        this.textInput = ref;
                      }}
                      todayButton="Go to Today"
                      selected={this.state.calendardate}
                      onChange={this.handleDate}
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      dateFormat="dd/MM/yyyy"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />

                    <label
                      className="label-input100"
                      style={{
                        marginRight: "0px",
                        marginBottom: "0px",
                        fontSize: "12px",
                        paddingBottom: "0px",
                        paddingTop: "0px",
                        position: "absolute",
                        marginLeft: "-30px"
                      }}
                    >
                      <a onClick={this.focusTextInput} href="#">
                        {<img src={cicon} />}
                      </a>
                    </label>
                  </div>

                  <a
                    href="#"
                    onClick={() =>
                      this.props.loadDateCalendar(
                        this.setPrevDate(
                          this.props.startDate,
                          this.state.period
                        )
                      )
                    }
                  >
                    <img className="img_np" src="dist/img/left.jpg" />
                  </a>
                  <a
                    href="#"
                    onClick={() =>
                      this.props.loadDateCalendar(
                        this.setNextDate(
                          this.props.startDate,
                          this.state.period
                        )
                      )
                    }
                  >
                    <img className="img_np" src="dist/img/right.jpg" />
                  </a>
                </div>
              </div>
              {/* /.user-block */}
              <div className="box-tools" style={{ top: "8px" }}>
                <button
                  type="button"
                  className="btn btn-box-tool"
                  data-widget="collapse"
                  style={{ backgroundColor: "unset" }}
                >
                  <i className="fa fa-minus fa-2x" />
                </button>
              </div>
              {/* /.box-tools */}
            </div>
            {/* /.box-header */}
            <div
              className="box-body"
              style={{ background: "#f5f5f5", padding: "0px 2px 0px 0px" }}
            >
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>
                      <div className="appcolumn">
                        <table
                          key={Math.random()}
                          className="calendar table table-bordered"
                          style={{ background: "#ffffff" }}
                        >
                          <thead
                            style={{
                              backgroundColor: "antiquewhite",
                              position: "sticky",
                              top: "40"
                            }}
                          >
                            <tr
                              key={parseInt(Math.random() * 10000000000000, 15)}
                            >
                              <th style={{ width: "1%" }}> Time </th>
                              {this.state.doctors.map(doctor => (
                                <th
                                  style={{ width: "3%", cursor: "pointer" }}
                                  key={parseInt(
                                    Math.random() * 10000000000000,
                                    11
                                  )}
                                  onClick={() =>
                                    this.props.loadDrCalendar(doctor)
                                  }
                                >
                                  Dr {doctor.Firstname}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.timeslots.map(timeslot => (
                              <tr
                                key={parseInt(
                                  Math.random() * 10000000000000,
                                  11
                                )}
                                class={
                                  moment(
                                    this.state.start_datetime +
                                      " " +
                                      timeslot.ampm
                                  ) > moment() &&
                                  moment(this.props.startDate).format(
                                    "YYYY-MM-DD"
                                  ) == moment().format("YYYY-MM-DD") &&
                                  sethightlight == 0
                                    ? "highlight_starthere"
                                    : ""
                                }
                                ref={
                                  moment(
                                    this.state.start_datetime +
                                      " " +
                                      timeslot.ampm
                                  ) > moment() &&
                                  moment(this.props.startDate).format(
                                    "YYYY-MM-DD"
                                  ) == moment().format("YYYY-MM-DD") &&
                                  sethightlight == 0
                                    ? "starthere"
                                    : "222"
                                }
                                setting={
                                  moment(
                                    this.state.start_datetime +
                                      " " +
                                      timeslot.ampm
                                  ) > moment()
                                    ? (sethightlight = 1)
                                    : ""
                                }
                                something={
                                  (console.log(
                                    "starthere",
                                    moment(
                                      this.state.start_datetime +
                                        " " +
                                        timeslot.ampm
                                    ) > moment()
                                  ),
                                  moment(
                                    this.state.start_datetime +
                                      " " +
                                      timeslot.ampm
                                  ))
                                }
                              >
                                <td
                                  style={{
                                    padding: "1px 5px",
                                    fontSize: "11px",
                                    minWidth: "60px"
                                  }}
                                >
                                  {timeslot.ampm}
                                </td>
                                {/* {console.log("skip", skip)} */}
                                {this.state.doctors.map(
                                  doctor =>
                                    this.isSkipable(
                                      timeslot.full,
                                      doctor
                                    ) &&
                                    this.renderTableColumnData(
                                      this.props.startDate,
                                      timeslot.full,
                                      doctor.doctor_id,
                                      j++,
                                      doctor.minutes_per_appointment
                                    )

                                  //doctor.minutes_per_appointment
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="calfooter">
                <ul
                  className="recentdate"
                  style={{
                    float: "left",
                    width: "100%",
                    color: "",
                    marginTop: 10,
                    marginLeft: 0,
                    position: "absolute",
                    bottom: "7px",
                    backgroundColor:"#ffffff",
                    padding: "3px 0px 3px 60px"
                  }}
                >
                  <li
                    className="label label-unavailable"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a>{/*<img src="/dist/img/circle.png" />*/} Unavailable</a>
                  </li>
                  <li
                    className="label label-Ontheday"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a>{/*<img src="/dist/img/heart.png" />*/} On The Day</a>
                  </li>
                  <li
                    className="label label-warning"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a>{/*<img src="/dist/img/hexagon.png" />*/} Waiting</a>
                  </li>
                  <li
                    className="label label-primary"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a>
                      {/*<img src="/dist/img/rectangle.png" />*/} With Doctor
                    </a>
                  </li>
                  <li
                    className="label label-success"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a> {/*<img src="/dist/img/square.png" />*/} At Billing</a>
                  </li>
                  <li
                    className="label label-completed"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a> {/*<img src="/dist/img/star.png" />*/} Completed</a>
                  </li>
                  <li
                    className="label label-attend"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a>
                      {/*<img src="/dist/img/triangle.png" />*/} Did Not Attend
                    </a>
                  </li>
                  <li
                    className="label label-danger"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a>{/*<img src="/dist/img/rectangle.png" />*/} Urgent</a>
                  </li>
                  <li
                    className="label label-elsewhere"
                    style={{ padding: "12px", margin: "1px" }}
                  >
                    <a>{/*<img src="/dist/img/heart.png" />*/} Elsewhere</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* /.box-body */}
          </div>
          {/* /.box */}

          <span ref="test"> </span>
        </div>
        <ContextMenu onShow={() => this.handlecontext()} id="3">
          <MenuItem
            data={{ action: "add_appintment" }}
            onClick={() => {
              this.addMoreAppointment();
              this.handleScrollToElement();
            }}
          >
            Add One More Appointment
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            disabled={this.state.cutAppointmentId==""?true:false}
            onClick={() =>
              this.rightClickPaste(
                this.props.get_cut_copy_details.cutAppointmentId,
                this.props.startDate,
                this.state.selected_starttime,
                this.state.selected_doctor_id,
                this.props.get_cut_copy_details.cutAppointmentType
              )
            }
          >
            Paste
          </MenuItem>
        </ContextMenu>

        <ContextMenu onShow={() => this.handlecontext()} id="2">
          <MenuItem
            data={{ action: "add_appintment" }}
            onClick={() => {
              this.addMoreAppointment();
              this.handleScrollToElement();
            }}
          >
            Add One More Appointment
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={e => this.toggleViewText(e)}
          >
            View Text
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={e =>
              //this.toggleViewText(e)
              this.toggleViewCell(
                "is_view_cell_open" +
                  this.state.selected_startDate +
                  "_" +
                  this.state.selected_starttime +
                  "_" +
                  this.state.selected_doctor_id
              )
            }
          >
            View Slot Details
          </MenuItem>
        </ContextMenu>
        <ContextMenu onShow={() => this.handlecontext()} id="1">
          <MenuItem
            data={{ action: "add_appintment" }}
            onClick={() => {
              this.addMoreAppointment();
              this.handleScrollToElement();
            }}
          >
            Add One More Appointment
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.triggerEditAppointments();
              this.handleScrollToElement();
            }}
          >
            Edit Appointment
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.triggerPatientDetails();
              this.handleScrollToElement();
            }}
          >
            Patient Details
          </MenuItem>
          <MenuItem data={{ action: "bar" }} 
          //onClick={() => ()}
           onClick={e => this.openCreateAccount(e)}
          >
            Create Account
          </MenuItem>
          <MenuItem divider />
          <MenuItem
            data={{ action: "unavailable" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-unavailable"
              );
            }}
          >
            Unavailable
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-Ontheday"
              );
            }}
          >
            On the Day
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-warning"
              );this.saveWaiting('waiting',this.state.selected_thisappointment)
            }}
          >
            Waiting
          </MenuItem>
          <MenuItem
              data={{ action: "bar" }}
              onClick={() => {
                this.saveWaiting('Cancel',this.state.selected_thisappointment);
               
             }}>Cancel</MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-primary"
              );
            }}
          >
            With Doctor
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-success"
              );
            }}
          >
            At Billing
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-completed"
              );
            }}
          >
            Completed
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-attend"
              );
            }}
          >
            Did Not Attend
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-danger"
              );
            }}
          >
            Urgent
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-elsewhere"
              );
            }}
          >
            Elsewhere
          </MenuItem>
          <MenuItem divider />
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClickCut(
                this.state.selected_thisappointment.appoinment_id,
                this.state.selected.startDate,
                this.state.selected.starttime,
                this.state.selected.doctor_id,
                "cut"
              );
            }}
          >
            Cut
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClickCut(
                this.state.selected_thisappointment.appoinment_id,
                this.state.selected.startDate,
                this.state.selected.starttime,
                this.state.selected.doctor_id,
                "copy"
              );
            }}
          >
            Copy
          </MenuItem>
          <MenuItem
          disabled={this.state.cutAppointmentId==""?true:false}
            data={{ action: "bar" }}
            onClick={() =>
              this.rightClickPaste(
                this.props.get_cut_copy_details.cutAppointmentId,
                this.props.startDate,
                this.state.selected_starttime,
                this.state.selected_doctor_id,
                this.props.get_cut_copy_details.cutAppointmentType
              )
            }
          >
            Paste
          </MenuItem>

          <MenuItem
            data={{ action: "bar" }}
            onClick={e => this.toggleMoveItem(e)}
          >
            Move
          </MenuItem>
          <MenuItem divider />
          <MenuItem
            data={{ action: "bar" }}
            onClick={e => {
              this.closeTabshow();
              this.toggleViewText(e);
            }}
          >
            View Text
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(this.state.selected_thisappointment, "delete");
            }}
          >
            Delete / Remove
          </MenuItem>
        </ContextMenu>

        <Addappointment
          selected_thisappointment={this.state.selected_thisappointment}
          path={this.props.path}
          key={
            this.state.addAppointmentTab +
            "-" +
            this.state.showPatientDetails +
            "-" +
            this.state.editAppointmentDetails
          }
          addAppointmentTab={this.state.addAppointmentTab}
          showPatientDetails={this.state.showPatientDetails}
          editAppointmentDetails={this.state.editAppointmentDetails}
          cdate={this.props.startDate}
          ctype={this.props.ctype}
          selectedTableProp={this.state.selectedTableProp}
        />

        {this.state.showcancelpop ?
        <CancelAppointment open={this.state.showcancelpop} onClose={this.closeCreateAccount} appId={ this.state.selected_thisappointment} ctype={this.props.ctype} sDate={this.props.startDate}/> :''}
      </div>
    );
  }
}
//state - not nec, only despatch - connect (null, method)
//state - only - connect (state)
const mapDispatchToProps = dispatch => {
  return {
    getDoctors: () => dispatch(api_getDoctors()),
    getTimeslots: () => dispatch(api_getTimeslots()),
    modifyAppointments: modifyAppointmentParam =>
      dispatch(api_modifyAppointments(modifyAppointmentParam)),
    allAppointments: searchParam => dispatch(api_getAppointments(searchParam)),
    getPatientDetails: patient_id =>
      dispatch(api_getPatientDetails(patient_id)),
    set_cut_copy_details: data => dispatch(api_set_cut_copy_details(data)),
    setSelectedThisAppointment: data =>
      dispatch(api_setSelectedThisAppointment(data)),
      saveWaitingStatus:data=>dispatch(api_save_waiting(data))
  };
};

const mapStateToProps = state => {
  //console.log(state);
  let appointments = state.getDrAppointments.getAppointments
    ? state.getDrAppointments.getAppointments
    : [];
  let get_cut_copy_details = state.getDrAppointments.cut_copy_details
    ? state.getDrAppointments.cut_copy_details
    : {};
  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";

  return {
    appointments,
    get_cut_copy_details,
    logged_info
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendarall);
