import axios from 'axios';
export const api_tobacco_history = data => dispatch => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=tobacco_history`,data)
};
export const api_cage_question=data=>dispatch=>
{
    return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=cage_question`,data)
}
export const api_alocohol_history=data=>dispatch=>
{
    return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=alocohol_history`,data)
}
export const api_audit_c=data=>dispatch=>
{
    return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=audit_c`,data)
}
export const api_get_audit_c=data=>dispatch=>
{
    return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=get_audit_c`,data)
}
export const api_get_carer=data=>dispatch=>
{
    return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=get_carer`,data)
}
export const api_add_carer=data=>dispatch=>
{
    return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=add_carer`,data)
}

export const api_add_social=data=>dispatch=>
{
    return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=add_social`,data)
}

export const api_get_condition = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=get_condition`, data);
}
export const api_add_family = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=add_family`, data);
}
export const api_get_family = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=get_family`, data);
}
export const api_search_condition = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=search_condition`, data);
}

export const api_add_family_relationship = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=add_family_relationship`, data);
}

export const api_get_relationship = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=get_relationship`, data);
}
export const api_delete_relationship = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=delete_relationship`, data);
}
export const api_get_alcohol = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=get_alcohol`, data);
}
export const api_get_history = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=get_smoking`, data);
}
export const api_get_relationship_master = () => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=get_relationship_master`);
}
export const api_cage_details_master = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=cage_details`, data);
}
export const api_adf_service = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=adf_service`, data);
}
export const api_search_occupation = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=search_occupation`, data);
}
export const api_get_details = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=get_emp_details`, data);
}
export const api_save_occupation_details = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=add_occupation_details`, data);
}
export const api_get_occupation = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=get_occupation`, data);
}
export const api_save_occupation = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=save_occupation`, data);
}
export const api_get_occ = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=get_occ`, data);
}
export const api_delete_details = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=delete_details`, data);
}
export const api_get_record = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=get_record`, data);
}
export const api_update_occupation_details = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=update_occupation_details`, data);
}
export const api_get_alcohol_details = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=familyhistory&action=get_alcohol_details`, data);
}

