import React, { Component } from 'react';
class Footer extends Component {
    state = {  }
    render() { 
        return (<footer className="main-footer" style={{bottom: '0',position: 'fixed',width: '100%'}}>
        <strong>
          Copyright © 2019 <a href="#">Supadoc</a>.
        </strong>{" "}
        All rights reserved.
      </footer>  );
    }
}
 
export default Footer;