import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {api_add_appoinment_type,api_list_reason,api_update_appoinment_type} from '../../../DS/DS.ListPage';
import {api_add_reminder_reason,api_update_reminder_reason,api_get_reminder_type} from '../../../DS/DS.ReminderPage';

class AddReminderReason extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        errors:[],
        def_interval:'',
        reason:'',
        res_type:1,
        isclinical:false,
        interval_no:'',
        selectedReminderRadionId:0,
        selectedReminderRadionText:'Days',
        selected_res_type:[]
        
    }
  }
  componentWillMount=()=>
  {

    this.props.getReminderType().then(
        res=>{
           this.setState({selected_res_type:res.data.content})
        }
     )
   //   alert(this.props.popFor)
    if(this.props.popFor=='Edit')
    {
        const getData={tbl:'reminder_reason_tb',pk:'reason_id',ids:this.props.ids}
        this.props.listreminderReason(getData).then(
            res=>{
                let atin=res.data.content.default_interval.split(" ")[0]
                this.setState({reason:res.data.content.reason,
                    interval_no:atin,
                    selectedReminderRadionId:res.data.content.radioValue,
                    isclinical:res.data.content.isclinical,
                    res_type:res.data.content.reason_type})
            }
        )
    }
    
  }
  handleValidation(){
    let errors = {};
    let formIsValid = true;
  
    //Description name
    if(this.state.description==''){
       formIsValid = false;
       errors["reason"] = "Cannot be empty";
    }
    //
    if(this.state.interval_no==''){
        formIsValid = false; 
        errors["interval_no"] = "Cannot be empty";
     }
     this.setState({errors: errors});
    return formIsValid;
  }
  onClickHandler=(e)=>
  {
    e.preventDefault();
    if(this.handleValidation()){
        if(this.state.isclinical==false)
        {
            this.state.isclinical=0
        }
        else
        {
            this.state.isclinical=1
        }
        const reasonData={
                reason:this.state.reason,
                def_intv:this.state.interval_no+' '+this.state.selectedReminderRadionText,
                res_type:this.state.res_type,
                isclinical:this.state.isclinical,
                radioValue:this.state.selectedReminderRadionId


              }
                this.props.addReminderReason(reasonData).then(
                  req=>{
                    if(req.data.status=="Duplicate"){
                       
                         NotificationManager.error('Duplicate reminder reason cannot be created',)
                    }
                    else if(req.data.status=="Success")
                    {
                      
                         NotificationManager.success('New reminder reason created',)
                    }
                    else{
                        
                        NotificationManager.error('Error encountered while creating new record',)
                    }
                    this.props.reloadPage()  
              }
              );
    }
} 
updateRecord=(e)=>
{
    e.preventDefault();
    if(this.handleValidation()){
        if(this.state.isclinical==false)
        {
            this.state.isclinical=0
        }
        else
        {
            this.state.isclinical=1
        }
        const reasonData={
            reason:this.state.reason,
            def_intv:this.state.interval_no+' '+this.state.selectedReminderRadionText,
            res_type:this.state.res_type,
            isclinical:this.state.isclinical,
            radioValue:this.state.selectedReminderRadionId,
                ids:this.props.ids
              }
                this.props.UpdatereminderReason(reasonData).then(
                  req=>{
                    if(req.data.status=="Duplicate"){
                       
                         NotificationManager.error('Duplicate reminder reason cannot be created',)
                    }
                    else if(req.data.status=="Success")
                    {
                      
                         NotificationManager.success('Selected reminder reason modified',)
                    }
                    else{
                        
                        NotificationManager.error('Error encountered while modifying record',)
                    }
                    this.props.reloadPage()  
              }
              );
    }
}
handleReminderRadio=(e)=>
{
    if(e.target.value=="0")
    {
        this.setState({selectedReminderRadionId:e.target.value,selectedReminderRadionText:'Days'})
    }
    if(e.target.value=="1")
    {
        this.setState({selectedReminderRadionId:e.target.value,selectedReminderRadionText:'Weeks'})
    }
    if(e.target.value=="2")
    {
        this.setState({selectedReminderRadionId:e.target.value,selectedReminderRadionText:'Months'})
    }
    if(e.target.value=="3")
    {
        this.setState({selectedReminderRadionId:e.target.value,selectedReminderRadionText:'Years'})
    }
   
}
 render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
            <div>
                <div className="modal-header">
                    <span className="modal-title-2" id="exampleModalLabel"> Reminder reason  </span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e)=>this.props.reloadPage()}>
                        <span aria-hidden="true">×</span>
                    </button> 
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <div className="col-sm-3"> 
                                    <label> Reason : </label>
                                </div>
                                <div className="col-sm-9 cursor">
                                    <form>
                                        <input type="text" name="text" className="form-control" value={this.state.reason} onChange={(e)=>this.setState({reason:e.target.value})}/>
                                        <span className="error" style={{color:'red'}}>{this.state.errors["reason"]}</span>
                               
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-distance">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <div className="col-sm-3"> 
                                    <label> Type : </label>
                                </div>
                                <div className="col-sm-9 cursor">
                                    <select className="dropdown-box" value={this.state.res_type} onChange={(e)=>this.setState({res_type:e.target.value})}>
                                    <option value="">Select</option>
                                    {this.state.selected_res_type != undefined && this.state.selected_res_type.length> 0 && this.state.selected_res_type.map((resStat) => <option key={resStat.type_id} value={resStat.type_id} >{resStat.type_name}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-distance">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <div className="col-sm-3">  </div>
                                <div className="col-sm-9 cursor">
                                    <div className="border-checkbox-group border-checkbox-group-default">
                                        <label className="border-checkbox-label" for="checkbox0" value={this.state.isclinical} onChange={(e)=>this.setState({isclinical:e.target.checked})}>  
                                            <input className="border-checkbox" type="checkbox" id="checkbox0"/>
                                        &nbsp; Clinically Significant</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-distance">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <div className="col-sm-3"> 
                                    <label> Default interval : </label>
                                </div>
                                <div className="col-sm-2 cursor">
                                    <form>
                                        <input type="text" name="text" className="form-control" value={this.state.interval_no} onChange={(e)=>this.setState({interval_no:e.target.value})}/>
                                        <span className="error" style={{color:'red'}}>{this.state.errors["interval_no"]}</span>
                               
                                    </form>
                                </div>
                                <div className="col-sm-5 cursor">
                                    <div className="form-group">
                                       <label onClick={(e)=>{this.handleReminderRadio(e)}}>
                                           <input type="radio" clasadios="form-control form-control-sm"  checked={this.state.selectedReminderRadionId==0?'checked':''} style={{marginLeft: '10px'}} name="reminderradio" value="0"  /> Days
                                        </label> 
                                    </div>
                                    <div className="form-group">
                                        <label onClick={(e)=>{this.handleReminderRadio(e)}}>
                                            <input type="radio" clasadios="form-control form-control-sm"  checked={this.state.selectedReminderRadionId==1?'checked':''} style={{marginLeft: '10px'}} name="reminderradio" value="1" /> Weeks
                                        </label> 
                                    </div>
                                    <div className="form-group">
                                        <label onClick={(e)=>{this.handleReminderRadio(e)}}>
                                            <input type="radio" clasadios="form-control form-control-sm"  checked={this.state.selectedReminderRadionId==2?'checked':''} style={{marginLeft: '10px'}} name="reminderradio" value="2" /> Months
                                        </label> 
                                    </div>
                                    <div className="form-group">
                                        <label onClick={(e)=>{this.handleReminderRadio(e)}}>
                                            <input type="radio" clasadios="form-control form-control-sm"  checked={this.state.selectedReminderRadionId==3?'checked':''} style={{marginLeft: '10px'}} name="reminderradio"  value="5"/> Years
                                        </label> 
                                    </div>
                                </div>
                                <div className="col-sm-2 cursor"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-sm-8"> </div>
                        <div className="col-sm-4">
                        {this.props.popFor=='Add' 
                        ? <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.onClickHandler(e)}>Save</button>
                        : <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.updateRecord(e)}>Update</button>
                        }
                        <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close" onClick={(e)=>this.props.reloadPage()}>Cancel</button> 
                        </div>
                    </div>
                </div>
            </div>
        )}
    </Popup>
  )}
}
const mapDispatchToProps = dispatch => {
    return {
        addReminderReason: data => dispatch(api_add_reminder_reason(data)),
        listreminderReason : data => dispatch(api_list_reason(data)),
        UpdatereminderReason: data => dispatch(api_update_reminder_reason(data)),
        getReminderType:()=>dispatch(api_get_reminder_type())
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(AddReminderReason);
  

