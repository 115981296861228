import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager} from 'react-notifications';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import { confirmAlert } from 'react-confirm-alert';
import AddConfirmKeyword from './addConfirmKeyword';
import {delete_Keyword,delete_Keyword1,update_setting} from '../../../DS/DS.AppointmentReminder';
import AddDeclineKeyword from './addDeclineKeyword';
import {api_get_settings} from '../../../DS/DS.Reminders';

class AttendenceReplies extends Component {
  constructor(props) {
    super(props);
    this.deleteKeyword=this.deleteKeyword.bind(this);
   
    this.state={
            open:this.props.showModal,
            disable1:true,
            keyboardbeign:'',
            curdConfirm:{
                jsonGridVersion: "v2.0",
                grid: true,
                viewgrid: {
                isVisible:true,
                isPagination: true,
                isFiltering: true,
                isSelection:true,
                deleteFunction:this.deleteKeyword,
                isDateGroup:false,
                isYearSeparator:false,
                dateCol:'',
                cellClicked:'',
                baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                query: {
                    tb: "appointment_keywords_tb",
                    cols: [
                    "keyword_id",
                    "keyword_name",
                    ],
                where: [
                    {
                        type: "and",
                        key: "isDeleted",
                        operator: "=",
                        value: 0
                },
                {
                    type: "and",
                    key: "keyword_type",
                    operator: "=",
                    value: 1
                }],
                    primary_key:"keyword_id",
                },
                viewtabletitle: "",
                columns: [
                    { name: "keyword_name", title: "Keyword" },
                ],
                rows: [],
                sortinit: [{ columnName: "keyword_id", direction: "asc" }],
                pagesize: 10
                }
            },
            crudDecline:{
                jsonGridVersion: "v2.0",
                grid: true,
                viewgrid: {
                isVisible:true,
                isPagination: true,
                isFiltering: true,
                isSelection:true,
                deleteFunction:this.deleteKeyword1,
                isDateGroup:false,
                isYearSeparator:false,
                dateCol:'',
                cellClicked:'',
                baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                query: {
                    tb: "appointment_keywords_tb",
                    cols: [
                    "keyword_id",
                    "keyword_name",
                    ],
                where: [
                    {
                        type: "and",
                        key: "isDeleted",
                        operator: "=",
                        value: 0
                },
                {
                    type: "and",
                    key: "keyword_type",
                    operator: "=",
                    value: 2
                }],
                    primary_key:"keyword_id",
                },
                viewtabletitle: "",
                columns: [
                    { name: "keyword_name", title: "Keyword" },
                ],
                rows: [],
                sortinit: [{ columnName: "keyword_id", direction: "asc" }],
                pagesize: 10
                }
            },
            key:0,
            selectedId:'',
            selectedLength:0,
            confimrmodal:false,
            declinemodal:false,
            selectedIdDel:'',
            selectedLengthDel:0,
            keywordcontain:0,
            keywordbeign:0
            

    }
  }
  componentWillMount=()=>
  {
    const settname={setting_name:'begin_keyword'}
    this.props.apigetsettings(settname).then(res=>{
     this.setState({keywordbeign:res.data.content[0].setting_value})
     if(res.data.content[0].setting_value==1)
     {
         this.setState({disable1:false})
     }
    })

    const settname1={setting_name:'contain_keyword'}
    this.props.apigetsettings(settname1).then(res=>{
     this.setState({keywordcontain:res.data.content[0].setting_value})
    })
  }
  enable1=(val)=>
  {
      if(val==true)
      {
        this.setState({disable1:false,keywordbeign:1})
      }
      else
      {
          this.setState({disable1:true,keywordbeign:0,keywordcontain:0})
      }
  }
  deleteKeyword=(id,actionfor,length)=>
  {
    // alert(id)
    if(actionfor=='selection')
    {
        this.state.selectedId=id
        this.state.selectedLength=length
       // alert( this.state.selectedId)
    }
    else if(actionfor=='delete')
    {
        if (this.state.selectedLength > 0) {
            confirmAlert({
              title: '',
              message: 'You are about to delete keyword. Do you want to proceed ? ',
              buttons: [
              {
                  label: 'Yes',
                  onClick: () => {
                    if(this.state.selectedLength==1)
                    {
                        if(this.state.selectedId!=1)
                        {
                            this.props.deleteword({id:this.state.selectedId}).then(
                                req=>this.reloadpage(),
                                NotificationManager.success(
                                'Keyword deleted successfully',
                                //this.changeKey()
                              )
                          );
                        }
                        else
                        {
                            alert("YES keyword cannot be deleted")
                        }
                    }
                    else{
                        this.props.deleteword({id:this.state.selectedId}).then(
                        req=>this.reloadpage(),
                        NotificationManager.success(
                        'Keyword deleted successfully',
                        //this.changeKey()
                        )
                    );
                }
              },
          },
          {
            label: 'No',
            onClick: () => { this.reloadpage()},
          },
        ],
      });
    } else {
      NotificationManager.error('Please select keyword(s) to be removed from the list');
    }
    }
  }
  deleteKeyword1=(id,actionfor,length)=>
  {
    if(actionfor=='selection')
    {
        this.state.selectedIdDel=id
        this.state.selectedLengthDel=length
       // alert( this.state.selectedId)
    }
    else if(actionfor=='delete')
    {
        if (this.state.selectedLengthDel > 0) {
            confirmAlert({
              title: '',
              message: 'You are about to delete keyword. Do you want to proceed ? ',
              buttons: [
              {
                  label: 'Yes',
                  onClick: () => {
                    if(this.state.selectedLengthDel==1)
                    {
                        if(this.state.selectedIdDel!=2)
                        {
                            this.props.deleteword1({id:this.state.selectedIdDel}).then(
                                req=>this.reloadpage(),
                                NotificationManager.success(
                                'Keyword deleted successfully',
                                //this.changeKey()
                              )
                          );
                        }
                        else
                        {
                            alert("NO keyword cannot be deleted")
                        }
                    }
                    else{
                        this.props.deleteword1({id:this.state.selectedIdDel}).then(
                        req=>this.reloadpage(),
                        NotificationManager.success(
                        'Keyword deleted successfully',
                        //this.changeKey()
                        )
                    );
                }
              },
          },
          {
            label: 'No',
            onClick: () => { this.reloadpage()},
          },
        ],
      });
    } else {
      NotificationManager.error('Please select keyword(s) to be removed from the list');
    }
    }
  }
  reloadpage=()=>
  {
      this.setState({confimrmodal:false,declinemodal:false, key:Math.random()})
  }
  saveSetting=()=>
  {
      const values={
        begin_keyword:this.state.keywordbeign,
        contain_keyword:this.state.keywordcontain
      }
    this.props.updatesetting({values}).then(
        req=>{
            NotificationManager.success("Settings modified successfully");
            this.props.changeKey()
        }
    )
  }
  checkEdit=(actionfor)=>
  {
    if(actionfor=='confirm')
    {
        if(this.state.selectedLength==1)
        {
            this.setState({confimrmodal:true})
        }
        else
        {
            alert("Please select single record to modify")
        }
    }
    if(actionfor=='decline')
    {
        if(this.state.selectedLengthDel==1)
        {
            this.setState({declinemodal:true})
        }
        else
        {
            alert("Please select single record to modify")
        }
    }
  }
  checkAdd=(actionfor)=>
  {
      if(this.state.selectedLength > 0 || this.state.selectedLengthDel > 0)
      {
        alert("Please deselect the record to proceed")
      }
      else{
          if(actionfor=='confirm')
          {
            this.setState({confimrmodal:true})
          }
          else if(actionfor=='decline')
          {
            this.setState({declinemodal:true})
          }
      }
  }
  render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
    {close => (
        <div>
            <div className="modal-header">
                <span className="modal-title-2" id="exampleModalLabel"> Automatic Confirmation Options  </span>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}>
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body" style={{height:'450px', overflowY:'scroll',overflowX:'hidden',width:'100%'}}>
                <div className="row row-distance" style={{padding:'10px 10px 0 10px'}}>
                    <div className="col-sm-12"> 
                        <p> 
                            The below options allow extra configuration settings to be applies to the automatic confirmation of appointment.
                        </p>

                        <p> 
                            Note that these settings will match whole words only (those surrounded by spaces or punctuation) and are case insensitive.
                        </p>

                        <p> 
                            While 'Yes' will trigger confirmation of appointment. 'Yesterday' will not.
                        </p>

                    </div>                                        
                </div>
                <div className="row row-distance" style={{padding:'5px 10px 0 10px'}}>
                    <div className="col-sm-12"> 			
                        <div className="border-checkbox-group border-checkbox-group-default">
                            <label className="border-checkbox-label" for="checkbox22">
                                <input className="border-checkbox" type="checkbox" id="checkbox22" value={this.state.keywordbeign}
                                onChange={(e)=>this.enable1(e.target.checked)} checked={this.state.keywordbeign==1 ? 'checked' :''}/>
                                &nbsp; Confirm or decline appointment when message begins with the keyword 
                            </label>
                        </div>
                    </div>                                        
                </div>
                <div className="row" style={{padding:'5px 10px 0 10px'}}>
                    <div className="col-sm-12"> 
                        <div className="border-checkbox-group border-checkbox-group-default">
                            <label className="border-checkbox-label" for="checkbox23"> 
                                <input className="border-checkbox" type="checkbox" id="checkbox23" disabled={this.state.disable1}  value={this.state.keywordcontain}
                            onChange={(e)=>this.setState({keywordcontain:e.target.checked})} checked={this.state.keywordcontain==1 ? 'checked' :''}/>
                                    &nbsp; Confirm or decline appointment when message begins contains the keyword 
                            </label>
                        </div>
                    </div>                                        
                </div>
                <div className="row row-distance" style={{padding:'5px 10px 0 10px'}}>
                    <div className="col-sm-12"> 
                        <p> 
                            NOTE: Messages containing both positive and negative <br/>
                            Keywords will not cause any appointment actions
                        </p>		
                    </div>                                        
                </div>
                <div className="row row-distance" style={{padding:'5px 10px 0 10px'}}>
                        <label className="col-sm-12 col-form-label text-left" style={{fontSize:'16px', fontWeight:'bold'}}> Additional Keywords : </label>		                                        
                </div>
                <div className="row row-distance" style={{padding:'5px 10px 0 10px'}}>
                
                    <div className="col-sm-9 col-md-9 col-xl-9 m-t-10">
                        <div className="row">
                            <label className="col-sm-12 col-form-label text-left" style={{fontSize:'16px', fontWeight:'bold'}}> Confirm </label>		                                        
                            <div className="col-lg-12 m-t-60">
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#keyword-add-btn1" style={{width:'100px', margin:'5px 5px 0 0'}} onClick={()=>this.checkAdd('confirm')}>Add</button>
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="" style={{width:'100px', margin:'5px 5px 0 0'}}  onClick={()=>this.checkEdit('confirm')}>Edit</button>
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', margin:'5px 0 0 0'}} onClick={()=>this.deleteKeyword('','delete','')}>Delete</button>
                    </div>
                        </div>
                        
                        <div className="card" style={{padding: '0px', height:'150px', overFlowY:'scroll'}}>
                            <div className="card-block">
                                <CommonGrid
                                    crud={this.state.curdConfirm}
                                    key={this.state.key}
                                 />
                            </div>
                        </div>
                    </div>
                   {/*} <div className="col-lg-2 m-t-60">
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#keyword-add-btn1" style={{width:'100px', marginBottom:'5px', marginTop:'30px'}} onClick={()=>this.setState({confimrmodal:true})}>Add</button>
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="" style={{width:'100px', marginBottom:'5px'}} onClick={()=>this.checkEdit('confirm')}>Edit</button>
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', marginBottom:'5px'}} onClick={()=>this.deleteKeyword('','delete','')}>Delete</button>
    </div>*/}
                </div>
                <div className="row row-distance" style={{padding:'5px 10px 0 10px'}}>
                    <div className="col-sm-9 col-md-9 col-xl-9 m-t-10">
                        <div className="row">
                            <label className="col-sm-12 col-form-label text-left" style={{fontSize:'16px', fontWeight:'bold'}}> Decline </label>		                                        
                            <div className="col-lg-12 m-t-60">
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#keyword-add-btn2" style={{width:'100px', margin:'5px 5px 0 0'}} onClick={()=>this.checkAdd('decline')}>Add</button>
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="" style={{width:'100px', margin:'5px 5px 0 0'}}onClick={()=>this.checkEdit('decline')}>Edit</button>
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', margin:'5px 0 0 0'}} onClick={()=>this.deleteKeyword1('','delete','')}>Delete</button>
                    </div>
                        </div>
                        <div className="card" style={{paddingLeft: '0px', height:'150px', overFlowY:'scroll'}}>
                            <div className="card-block">
                                <CommonGrid
                                    crud={this.state.crudDecline}
                                    key={this.state.key}
                                 />
                            </div>
                        </div>
                    </div>
                 {/*}   <div className="col-lg-2 m-t-60">
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#keyword-add-btn2" style={{width:'100px', marginBottom:'5px', marginTop:'30px'}} onClick={()=>this.setState({declinemodal:true})}>Add</button>
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="" style={{width:'100px', marginBottom:'5px'}}  onClick={()=>this.checkEdit('decline')}>Edit</button>
                            <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', marginBottom:'5px'}} onClick={()=>this.deleteKeyword1('','delete','')}>Delete</button>
</div>*/}
                </div>
            </div>
            <div className="modal-footer">
                <div className="row">
                    <div className="col-sm-9"> </div>
                        <div className="col-sm-3">
                            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>this.saveSetting()}> Ok </button>
                        <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}>Cancel</button> 
                        </div>
                    </div>
                </div>
                {this.state.confimrmodal ?
                <AddConfirmKeyword showModal={this.state.confimrmodal} sid={this.state.selectedId} changeKey={this.reloadpage}/> :''}
                     {this.state.declinemodal ?
                <AddDeclineKeyword showModal={this.state.declinemodal} sid={this.state.selectedIdDel} changeKey={this.reloadpage}/> :''}
           
            </div>
    )}
    </Popup>
    )}
}

const mapDispatchToProps = dispatch => {
    return {
        deleteword: data =>dispatch(delete_Keyword(data)),
        deleteword1: data =>dispatch(delete_Keyword1(data)),
        updatesetting:data=>dispatch(update_setting(data)),
        apigetsettings:(data)=>dispatch(api_get_settings(data)),

        
        
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(AttendenceReplies);


