import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
//import './addPatientHistory.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import {wflow} from './../../../DS/general';
import axios from 'axios';
import
{
  api_addClinicalNote,
  api_getPatientDetails,
  api_getContactType,
  api_getContactMethod,
  api_getContactWith,
  api_getContactStatus,
  api_getNotes,
  api_updateRecord
} from '../../../DS/DS.Clinical';
import Showhelp from './../../showHelp';

import ViewClinicalRecord from './viewClinicalRecord';
class EditRecordNote extends Component {
  constructor(props) {
    super(props);
    console.log(">>>"+JSON.stringify(props))
    this.state = {
      userId:1 ,//props.patientId,
      confidential: 0,
      startDate: '',
      dateShow: false,
      selectedFile: null,
      open:false,
      calendardate:'',
      description:'',
      details:'',
      patient_id:this.props.redux_patientId,
      isChecked: false,
      patientsData:[],
      sid:this.props.sid,
      contactType:[],
      selected_contact_type:'',
      contactMethod:[],
      selected_contact_method:'',
      contactWith:[],
      selected_contact_with:'',
      contactStatus:[],
      selected_contact_status:'',
      errors:[],
      disable:true,
      viewimage:false,
      clinicalid:'',
      netkin:'',
      cont_attempt:''
    };
  }
 componentWillMount()
 {
  // alert()
    this.setState({open:this.props.isVisibleNote})
    this.props.apiGetPatientDetails({patient_id:this.state.patient_id}).then(patdata=>{
      
       this.setState({patientsData:patdata.data.content})
    //   console.log(patdata.data.content)
       
      });
       this.props.getContactType().then(contData=>{
       this.setState({contactType:contData.data.content})
      });
      this.props.getContactMethod().then(contMethod=>{
       this.setState({contactMethod:contMethod.data.content})
      });
      this.props.getContactWith().then(contWith=>{
       this.setState({contactWith:contWith.data.content})
      });
      this.props.getContactStatus().then(contStat=>{
       this.setState({contactStatus:contStat.data.content})
      });
      this.setState({
        calendardate: moment(new Date() ,'YYYY/MM/DD')
      });
      this.props.getnoteData({ params:{note_id:this.props.sid}}).then(noteStat=>{
        this.setState({noteData:noteStat.data.content})
        this.setState(
          {
            selected_contact_type:noteStat.data.content[0].contact_type,
            selected_contact_method:noteStat.data.content[0].contact_method,
            selected_contact_with:noteStat.data.content[0].contact_with,
            selected_contact_status:noteStat.data.content[0].message_status,
            contact_rsn:noteStat.data.content[0].contact_reason,
            calendardate: moment(new Date(noteStat.data.content[0].contact_date) ,'YYYY/MM/DD'),
            details:noteStat.data.content[0].user_comments,
            clinicalid:noteStat.data.content[0].clinical_id,
            cont_attempt:noteStat.data.content[0].contact_attempt
          })
          if(noteStat.data.content[0].contact_type==1)
          {
            //alert("IN")
            this.setState({netkin:this.state.patientsData[0].Nexttokin})
          }
       });
}

  handleChange(event) {
    console.log(event.target.value)
    //this.setState({description: event.target.value});
  }
  onChangeHandler=event=>{
      this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })
}
handleValidation(){
  let fields = this.state.fields;
  let errors = {};
  let formIsValid = true;

  //contact method
  if(!this.state.selected_contact_method){
     formIsValid = false;
     errors["selected_contact_method"] = "Cannot be empty";
  }
  if(!this.state.selected_contact_status){
    formIsValid = false;
    errors["selected_contact_status"] = "Cannot be empty";
 }


  

 this.setState({errors: errors});
 return formIsValid;
}
onClickHandler=(e)=>
{
  e.preventDefault();
    if(this.handleValidation()){
    const clinicalData={
        contact_date:this.state.calendardate,
        comments:this.state.details,
        clinical_id:this.state.sid,
        cont_type:this.state.selected_contact_type,
        cont_method:this.state.selected_contact_method,
        cont_with:this.state.selected_contact_with,
        cont_stat:this.state.selected_contact_status,
        cont_attempt:this.state.cont_attempt,
        contact_reason:this.props.reasontype,
        note_id:this.state.sid,
        doc_id:this.props.redux_docId  
        }
        console.log(this.state)
        this.props.apiUpdateClinicalNote(clinicalData).then(
            res=>this.props.changeKey(),
             NotificationManager.success(
                    'Note updated for selected clinical record',
                   ) 
        );
    }
}
handleDate = date => {
  console.log(date);
  this.setState({
    calendardate: moment(new Date(date),'YYYY/MM/DD')
  });

  if(moment(new Date(date)).format('MM/DD/YYYY') == moment(new Date()).format('MM/DD/YYYY')){

    this.setState({
      dateShow: true,
    });
  }else {
    this.setState({
      dateShow: false,
    });
  }
};
  handleFieldChange = (key, value) => {
   this.setState({ key: value});
  };
   toggleChange = () => {
        this.setState({
          isChecked: !this.state.isChecked,
        });
      }
      handleContactType=(e)=>
    {
      this.setState({selected_contact_type:e})
      /*  if(e==1)
        {
            this.setState({disable:false})
          
        }
        else
        {
            this.setState({disable:true})
        }*/
    }
    refreshPage=()=>{
      this.setState({key:Math.random()})
      this.setState({viewimage:false})
    }
  render() {
    return (
      <Popup
      modal
       open={this.state.open}
       closeOnDocumentClick={false}>
        {close => (
          <div>
             <div className="modal-content" style={{ backgroundColor: '#fff'}}>
                    <div className="modal-header" style={{width:'auto', background:'#ddd', padding: '10px'}}>
                      
                            <h6 style={{background:'#ddd', float:'left',width:'50%', paddingBottom:'5px', fontSize:'16px',marginBottom:'0px',display: 'inline-flex'}}>Result Note - &nbsp;
                            { this.state.patientsData !=undefined && this.state.patientsData.map((item,i) => (
                               <i> {item.Firstname}</i>
                            ))}
                            
                            </h6>
                            <Showhelp gotohelp="record_note_edit"/>
                                <button type="button" className="close"  onClick={() => {close();this.props.changeKey();}} data-dismiss="modal" aria-label="Close" style={{marginTop:'5px'}}>
                                    <span aria-hidden="true">×</span>
                                </button>
                    </div>
                    <div className="modal-body" style={{overflowY:'scroll', height:'600px'}}>
                                 <div className="col-sm-12" style={{paddingLeft:'0px', overFlow:'auto'}} id="Graphdetails">
                                    <h5 style={{background:'#ddd', float:'left', width:'100%', padding:'10px', fontSize:'16px', margin:'20px 0',display: 'inline-flex'}}>Patient contact details</h5>
                                        <div className="form-group row">				
                                            <label className="col-sm-2 col-form-label text-left">Patient name:</label>
                                                <div className="col-sm-2">
                                                 { this.state.patientsData !=undefined && this.state.patientsData.map((item,i) => (
                           
                                                    <input type="text" className="form-control form-control-sm" style={{padding:'10px'}} value={item.Firstname} disabled="true"/>
                                                 ))} 
                                                </div>
                                                <label className="col-sm-2 col-form-label text-right">Age:</label>
                                                <div className="col-sm-2">
                                                { this.state.patientsData !=undefined && this.state.patientsData.map((item,i) => (
                           
                                                    <input type="text" className="form-control form-control-sm"  style={{padding:'10px'}} value={item.age} disabled="true"/>
                                                ))}   
                                                </div>
                                                    <label className="col-sm-2 col-form-label text-right" style={{paddingRight:'0px', paddingLeft:'0px', marginLeft:'0px', marginRight:'0px'}}>Prefered contact us:</label>
                                                <div className="col-sm-2">
                                                 { this.state.patientsData !=undefined && this.state.patientsData.map((item,i) => (
                           
                                                    <input type="text" className="form-control form-control-sm"  style={{padding:'10px'}} value={item.phone} disabled="true"/>
                                                 ))} </div>
                                            </div>
                                            <div className="form-group row">				
						                        <label className="col-sm-2 col-form-label text-left">Home phone:</label>
						                        <div className="col-sm-2">
							                        <input type="text" className="form-control form-control-sm"  style={{padding:'10px'}} disabled="true"/>
						                        </div>
							                        <label className="col-sm-2 col-form-label text-right"  style={{padding:'10px'}}>Work phone:</label>
						                        <div className="col-sm-2">
							                        <input type="text" className="form-control form-control-sm"  style={{padding:'10px'}} disabled="true"/>
						                        </div>
                                                    <label className="col-sm-2 col-form-label text-right" style={{paddingRight:'0px', paddingLeft:'0px', marginLeft:'0px', marginRight:'0px'}}>Mobile phone:</label>
						                        <div className="col-sm-2">
							                            <input type="text" className="form-control form-control-sm"  style={{padding:'10px'}} disabled="true"/>
						                        </div>
                                            </div>
                                            <h5 style={{background:'#ddd', float:'left', width:'100%', padding:'10px', fontSize:'16px', margin:'20px 0',display: 'inline-flex'}}>Other contact's details</h5>
				                            <div className="form-group row">				
						                        <label className="col-sm-2 col-form-label text-left">Contact type:</label>
					                            <div className="col-sm-3">
							                        <select style={{width:'-webkit-fill-available', float:'left', 'marginLeft':'7px', 'marginBottom':'0px', padding:'2px 5px', fontSize:'14px', border:'1px solid #666'}} onChange={(e) => this.handleContactType(e.target.value)} value={this.state.selected_contact_type} disabled="true">
                                                  <option value="0"></option>
                                            {this.state.contactType != undefined && this.state.contactType.length> 0 && this.state.contactType.map((contactdata) => <option key={contactdata.type_id} value={contactdata.type_id}>{contactdata.type_text}</option>)}
                                      </select>
						                        </div>
						                        <div className="col-sm-7">
							                        <input type="text" className="form-control form-control-sm"  style={{padding:'10px'}} disabled={(this.state.disable)? "disabled" : ""} value={this.state.netkin}/>
						                        </div>
                                            </div>
                                            <div className="form-group row">				
						                            <label className="col-sm-2 col-form-label text-left">Home phone:</label>
                                                <div className="col-sm-2">
                                                    <input type="text" className="form-control form-control-sm"  style={{padding:'10px'}} disabled={(this.state.disable)? "disabled" : ""}/>
                                                </div>
					                            <label className="col-sm-2 col-form-label text-right">Work phone:</label>
                                                <div className="col-sm-2">
                                                    <input type="text" className="form-control form-control-sm"  style={{padding:'10px'}} disabled={(this.state.disable)? "disabled" : ""}/>
                                                </div>
						                        <label className="col-sm-2 col-form-label text-right" style={{paddingRight:'0px', paddingLeft:'0px', marginLeft:'0px', marginRight:'0px'}}>Mobile phone:</label>
                                                <div className="col-sm-2"> 
                                                    <input type="text" className="form-control form-control-sm"  style={{padding:'10px'}}  disabled={(this.state.disable)? "disabled" : ""}/>
                                                </div>
				                            </div>
                                                <h5 style={{background:'#ddd', float:'left', width:'100%', padding:'10px', fontSize:'16px', margin:'20px 0',display: 'inline-flex'}}>Communication details</h5>
                                            <div className="form-group row">				
						                        <label className="col-sm-2 col-form-label text-left">Contact reason:</label>
					                            <div className="col-sm-2">
						                            <input type="text" className="form-control form-control-sm"  style={{padding:'10px'}} value={this.props.reasontype} disabled="true"/>
					                            </div>
		    		                            <label className="col-sm-2 col-form-label text-right">Contact method:</label>
					                                <div className="col-sm-2">
						                                      <select style={{width:'-webkit-fill-available', float:'left', 'marginLeft':'7px', 'marginBottom':'0px', padding:'2px 5px', fontSize:'14px', border:'1px solid #666'}} onChange={(e) => this.setState({selected_contact_method: e.target.value })} value={this.state.selected_contact_method} disabled="true">
                                                  <option value="">Select</option>
                                            {this.state.contactMethod != undefined && this.state.contactMethod.length> 0 && this.state.contactMethod.map((contactmethod) => <option key={contactmethod.method_id} value={contactmethod.method_id}>{contactmethod.method_name}</option>)}
                                      </select>
                                      <span className="error" style={{color:'red'}}>{this.state.errors["selected_contact_method"]}</span>
                                          </div>
                                                    <label className="col-sm-2 col-form-label text-right" style={{paddingRight:'0px', paddingLeft:'0px', marginLeft:'0px', marginRight:'0px'}}>Contact with:</label>
					                                  <div className="col-sm-2">
						                                     <select style={{width:'-webkit-fill-available', float:'left', 'marginLeft':'7px', 'marginBottom':'0px', padding:'2px 5px', fontSize:'14px', border:'1px solid #666'}} onChange={(e) => this.setState({selected_contact_with: e.target.value })} value={this.state.selected_contact_with} disabled="true">
                                            {this.state.contactWith != undefined && this.state.contactWith.length> 0 && this.state.contactWith.map((contactwith) => <option key={contactwith.with_id} value={contactwith.with_id}>{contactwith.with_text}</option>)}
                                      </select>
					                                    </div>
                                            </div>
                                            <div className="form-group row">				
				                                <label className="col-sm-2 col-form-label text-left">Contact date:</label>
                	                            <div className="col-sm-2">
                                              <DatePicker
                                                className="datePicker"
                                                value= {this.state.calendardate}
                                                selected={
                                                this.state.dateShow 
                                                ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                : Date.parse(this.state.calendardate)
                                                }
                                                onChange={this.handleDate}
                                              />       
					                            </div>
			                                </div>
                                            <div className="form-group row">				
				                                <label className="col-sm-2 col-form-label text-left">Contact attempt to:</label>
	    			                            <div className="col-sm-2">
						                            <input type="text" className="form-control form-control-sm"  style={{padding:'10px'}} value={this.state.cont_attempt}/>
					                            </div>
			                                </div>
                                      <div className="form-group row">				
				                                <label className="col-sm-12 col-form-label text-left">User Comments:</label>
                                                <div className="col-sm-12 cursor" style={{marginLeft:'0px', marginTop:'0px', }}>
                                                    <textarea style={{width:'100%', height:'200px', border:'1px solid #cccccc'}}
                                                    onChange={e =>
                                                                     this.setState({ details: e.target.value })
                                                                }
                                                                value={this.state.details}
                                                    
                                                    ></textarea>
				                                        </div>
			                                    </div>	
                                          <div class="form-group row">				
							                              <label class="col-sm-10 col-form-label text-right" 
                                            style={{paddingRight:'0px', paddingLeft:'0px', marginLeft:'0px', marginRight:'0px'}}>
                                            Message status:</label>
							                                <div class="col-sm-2">
							                                      <select style={{width:'-webkit-fill-available', float:'left', 'marginLeft':'7px', 'marginBottom':'0px', padding:'2px 5px', fontSize:'12px', border:'1px solid #666'}} onChange={(e) => this.setState({selected_contact_status: e.target.value })} value={this.state.selected_contact_status} disabled="true">
                                                    <option value="">Select</option>{this.state.contactStatus != undefined && this.state.contactStatus.length> 0 && this.state.contactStatus.map((contactstat) => <option key={contactstat.status_id} value={contactstat.status_id}>{contactstat.status_text}</option>)}
                                      </select>
                                      <span className="error" style={{color:'red'}}>{this.state.errors["selected_contact_status"]}</span>
							                                </div>
						                              </div>			
                                        </div>
                    </div>
                    <div className="modal-footer">
                    {this.props.showbutton=='yes' ?
                       <button type="button" className="btn btn-default" style={{float:'left', marginRight:''}}onClick={()=>this.setState({viewimage:true})}>View Image</button>
                       :''} <button type="button" className="btn btn-default"  onClick={(e)=>{this.onClickHandler(e) ; }}>Save</button>
                        <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => {close();this.props.changeKey();}}>Cancel</button>
                    </div>    
            </div>
            {(this.state.viewimage) ?
          <ViewClinicalRecord sid={this.state.clinicalid} showImg={this.state.viewimage} changeKey={this.refreshPage} showfor='clinical'/> : ''}

          </div>

        )}
      </Popup>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    apiAddClinicalNote: data => dispatch(api_addClinicalNote(data)),
    apiGetPatientDetails : data => dispatch(api_getPatientDetails(data)),
    getContactType: () => dispatch(api_getContactType()),
    getContactMethod: () => dispatch(api_getContactMethod()),
    getContactWith: () => dispatch(api_getContactWith()),
    getContactStatus:() => dispatch(api_getContactStatus()),
    getnoteData:data=> dispatch(api_getNotes(data)),
    apiUpdateClinicalNote:data=>dispatch(api_updateRecord(data))
   };
};

const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
   
  return {
    redux_patientId,redux_docId
  };
};
export default connect(
    mapStateToProps,
  mapDispatchToProps
)(EditRecordNote);

//export default AddClinicalRecord;






















