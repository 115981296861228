import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../../showHelp';

class reviewin extends Component {
  constructor (props)
  {
    super(props);
    this.state={
      disable1:true,
      disable2:true,
      disable3:true,
      disable4:true,
      disable5:true,
      Interval:""
    }
  }
  onRadioClicked=(radiofor,radiotext)=>
  {
    //alert(radiofor)

    this.setState({Interval:radiotext})
    if(radiofor=='interval')
    {
      this.setState({disable1:false,disable2:true,disable3:true,disable4:true})
    }
    if(radiofor=='not_better')
    {
      this.setState({disable1:true,disable2:false,disable3:true,disable4:true})
    }
    if(radiofor=='on_date')
    {
      this.setState({disable1:true,disable2:true,disable3:false,disable4:true})
    }
    if(radiofor=='other_radio')
    {
      this.setState({disable1:true,disable2:true,disable3:true,disable4:false})
    }
    if(radiofor=='other')
    {
      this.setState({disable1:true,disable2:true,disable3:true,disable4:true})
    }
  }
  saveText=()=>
  {
    
  }

    render() { 
        return ( 
            <Popup trigger={<button id="session-timeout-dialog-logout" data-toggle="modal" data-target="#reviewin" type="button" className="btn btn-primary btn-xs">Review in</button>} position="right center"   modal
            closeOnDocumentClick={false}>
             {close => (
               <div style={{ width:'400px', margin:'0px auto'}} >
                 <div style={{ width:'400px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>
                    <div className="modal-header" style={{ backgroundColor: '#f4f4f4', padding: '5px 15px'}}>
                    <h5 className="col-sm-12 sheadtitle">Review in <Showhelp gotohelp="review_in_values"/></h5>
                      <button type="button" className="close" style={{marginTop: '-28px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      
                    </div>


                    <div className="modal-body">
                      <div className="col-sm-12 mt-3 pl-0">
                        <div className="col-sm-4 mt-3">
                        <label class="radio-inline">
                              <input type="radio"  style={{float: 'left', width: 'auto', marginRight: '5px'}} name="search_condition" className=" " 
                              value="Interval"
                              checked={this.state.Interval === "Interval"}
                            //  onChange={e => this.props.handleOptionChange(e, "reviewin", "Interval")}
                              onChange={e => this.onRadioClicked('interval','Interval')}
                              />Interval</label>
                         </div>
                         <div className="col-sm-3 mt-0 pl-0">
                              <input type="text"  style={{marginRight: '5px'}}   value={this.props.vals.days}
                                onChange={e => this.props.handleOptionChange(e, "reviewin", "days")} disabled={this.state.disable1}/>
                         </div>
                         <div className="col-sm-2 pl-0">
                              <select  style={{width: 'auto'}}   onChange={e => this.props.handleOptionChange(e, "reviewin", "daystext")}
                              disabled={this.state.disable1}>
                                <option value="days">days</option>
                                <option value="weeks">weeks</option>
                                <option value="months">months</option>
                                <option value="years">years</option>
                              </select>
                         </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="row ">
                          <div className="col-sm-12">
                          <label class="radio-inline">
                                <input type="radio"  style={{float: 'left', width: 'auto', marginRight: '5px'}} name="search_condition" className=" " 
                                value="If problem presists"
                                checked={this.state.Interval === "If problem presists"}
                               // onChange={e => this.props.handleOptionChange(e, "reviewin", "problem_status")}
                               onChange={e => this.onRadioClicked('other','If problem presists')}
                                />If problem presists</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                          <label class="radio-inline">
                                <input 
                                value="If problem worsens"
                                checked={this.state.Interval === "If problem worsens"}
                                //onChange={e => this.props.handleOptionChange(e, "reviewin", "problem_worsen")}
                                onChange={e => this.onRadioClicked('other','If problem worsens')}
                                type="radio" defaultValue style={{float: 'left', width: 'auto', marginRight: '5px'}} name="search_condition" className="" />
                                If problem worsens</label>
                          </div>
                        </div>	
                        <div className="row">
                          <div className="col-sm-12">
                          <label class="radio-inline">
                              <input 
                              value="If problem recurs"
                              checked={this.state.Interval === "If problem recurs"}
                              //onChange={e => this.props.handleOptionChange(e, "reviewin", "problem_recurs")}
                              onChange={e => this.onRadioClicked('other','If problem recurs')}
                              type="radio" defaultValue style={{float: 'left', width: 'auto', marginRight: '5px'}} name="search_condition" className="" />
                            If problem recurs</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3 mt-3" style={{paddingRight: '0px'}}>
                          <label class="radio-inline">
                              <input 
                              value="If not better"
                              checked={this.state.Interval === "If not better"}
                            //  onChange={e => this.props.handleOptionChange(e, "reviewin", "not_better")}
                                onChange={e => this.onRadioClicked('not_better','If not better')}
                              type="radio" style={{float: 'left', width: 'auto', marginRight: '5px'}} name="search_condition" className="" />
                              If not better</label>
                          </div>
                          <div className="col-sm-1 mt-3">
                            <label className="col-form-label text-left">in</label>
                          </div>
                          <div className="col-sm-3 mt-0">
                            <input 
                            value={this.props.vals.in_days}
                            onChange={(e) =>
                              this.props.handleOptionChange(
                                e,
                                "reviewin",
                                "in_days"
                              )
                            }
                            type="text"  name="search_condition" className="" disabled={this.state.disable2} />
                          </div>
                          <div className="col-sm-2 mt-0 pl-0">
                          <select  style={{width: 'auto'}}   onChange={e => this.props.handleOptionChange(e, "reviewin", "indaystext")}
                          disabled={this.state.disable2}>
                                <option value="days">days</option>
                                <option value="weeks">weeks</option>
                                <option value="months">months</option>
                                <option value="years">years</option>
                              </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                          <label class="radio-inline">
                            <input type="radio"  style={{float: 'left', width: 'auto', marginRight: '5px'}} name="search_condition" className="" 
                             value="p.r.n"
                             checked={this.state.Interval === "p.r.n"}
                            // onChange={e => this.props.handleOptionChange(e, "reviewin", "prn")}
                            onChange={e => this.onRadioClicked('other','p.r.n')}
                            />
                           p.r.n</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3 mt-3" style={{marginRight: '0px'}}>
                          <label class="radio-inline">
                                <input type="radio" defaultValue style={{float: 'left', width: 'auto', marginRight: '5px'}} name="search_condition" className="" 
                                 value="On date"
                                 checked={this.state.Interval === "On date"}
                                // onChange={e => this.props.handleOptionChange(e, "reviewin", "on_date")}
                                onChange={e => this.onRadioClicked('on_date','On date')}/>On date:</label>
                          </div>
                          <div className="col-sm-4 mt-0">
                            <input type="text" defaultValue="25-11-2019" name="search_condition" className="" disabled={this.state.disable3}/>
                          </div>
                          <div className="col-sm-2 mt-2">
                            <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" disabled={this.state.disable3}>Book</button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                          <label class="checkbox-inline"> 
                           <input type="checkbox" defaultValue style={{float: 'left', width: 'auto', marginRight: '5px', marginLeft: '100px'}} name="search_condition" className="" disabled={this.state.disable3} />
                           Send reminder</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4 mt-3">
                          <label class="radio-inline"><input type="radio" defaultValue style={{float: 'left', width: 'auto', marginRight: '5px'}} name="search_condition" className="" 
                           value="Other"
                           checked={this.state.Interval === "other_radio"}
                           //onChange={e => this.props.handleOptionChange(e, "reviewin", "Other")}
                           onChange={e => this.onRadioClicked('other','other_radio')}/>Other:</label>
                          </div>
                          <div className="col-sm-6">
                            <input type="text"  name="search_condition" className="" 
                             value={this.props.vals.other_text}
                           
                             onChange={e => this.props.handleOptionChange(e, "reviewin", "other_text")}
                             disabled={this.state.disable4}/>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="modal-footer">
                      <button 
                       onClick={e => 
                        {this.props.handleSaveOptions(e, "reviewin");
                        close();}
                        }
                      type="button" className="btn btn-primary btn-xs" data-dismiss="modal">Save</button>
                      <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>
            </div>
             </div>
         )}
 </Popup> 
       );
    }
}
 
export default reviewin;