import axios from 'axios';


//observation master
export const OBSERVATIONS_MASTER = 'OBSERVATIONS_MASTER';

export const getObservationsMaster = obvMast_data => ({
  type: OBSERVATIONS_MASTER,
  obvMast_data, 
});

export const api_observation_master = data => dispatch => {
    //first
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=observation&action=list_observations`,
        data
      )
     .then(res => res.data.content)
      .then(data => dispatch(getObservationsMaster(data)));
  };

  

//observation patient data
export const PATIENTS_OBSERVATIONS = 'PATIENTS_OBSERVATIONS';

export const getPatientsObservations = patientsObservations_data => ({
  type: PATIENTS_OBSERVATIONS,
  patientsObservations_data,
});

export const api_patient_observations = data => dispatch => {
    //first
    
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=observation&action=patients_observations`,
        data
      )
      .then(res => res.data.content)
      .then(data => {
        console.log(data);  
        dispatch(getPatientsObservations(data))});
  };

  //adding patient observations master
  export const api_add_patient_observations = data => dispatch => {
    //first
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=observation&action=add_observations`,
        data
      );
      
  };















//investions master
  export const INVESTIGATIONS_MASTER = 'INVESTIGATIONS_MASTER';

  export const getInvestigationsMaster = invMast_data => ({
    type: INVESTIGATIONS_MASTER,
    invMast_data, 
  });
  
  export const api_investigations_master = data => dispatch => {
      //first
      return axios
        .post(
          `${process.env.REACT_APP_BASE_URL}api=observation&action=list_investigations`,
          data
        )
       .then(res => res.data.content)
        .then(data => dispatch(getInvestigationsMaster(data)));
};
  
  
  
//patients investigations
export const PATIENTS_INVESTIGATIONS = 'PATIENTS_INVESTIGATIONS';

export const getPatientsInvestigations = patientsInvestigations_data => ({
  type: PATIENTS_INVESTIGATIONS,
  patientsInvestigations_data,
});

export const api_patient_investigations = data => dispatch => {
    //first
    
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=observation&action=patients_investigations`,
        data
      )
      .then(res => res.data.content)
      .then(data => {
        console.log(data);  
        dispatch(getPatientsInvestigations(data))});
  };


  
  export const api_add_patient_investigations = data => dispatch => {
    //first
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=observation&action=add_investigations`,
        data
      );
      
  };
  

  export const api_get_record_values = data => dispatch => {
    //first
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=observation&action=get_record_values`,
        data
      );
      
  };
  