import React, { Component } from 'react';

import Popup from 'reactjs-popup';
import './addinvestigation.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { wflow } from '../../../DS/general';
import Showhelp from './../../showHelp';

class Editinvestigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        provider: '',
        test_name: '',
        notation: '',
        checked_by: '',
        date_of_test: '',
        condidential: false,
        action_to_be_taken: '',
        date_checked: '',
        has_been_actioned: '',
        comments: '',
      },
      saving: false,
    };
  }
  options = {
    provider_options: [
      {
        value: '',
        label: 'Select Provider',
      },
      {
        value: '1',
        label: 'Provider 1',
      },
      {
        value: '2',
        label: 'Provider 2',
      },
    ],
    notation_options: [
      {
        value: '',
        label: 'Select Provider',
      },
      {
        value: '1',
        label: 'Provider 1',
      },
      {
        value: '2',
        label: 'Provider 2',
      },
    ],
    checked_by_options: [
      {
        value: '',
        label: 'Select Provider',
      },
      {
        value: '1',
        label: 'Provider 1',
      },
      {
        value: '2',
        label: 'Provider 2',
      },
    ],
    action_to_be_taken_options: [
      {
        value: '',
        label: 'Select Provider',
      },
      {
        value: '1',
        label: 'Provider 1',
      },
      {
        value: '2',
        label: 'Provider 2',
      },
    ],
  };
  handleFieldChange = (key, value) => {
    this.setState({
      data: {
        [key]: value,
      },
    });
  };

  handleSave = async close => {
    await this.props.addInvestigations(this.state);
    close();
  };

  render() {
    return (
      <Popup
        trigger={
          <button
            id="session-timeout-dialog-logout"
            data-toggle="modal"
            data-target="#pastmedical"
            type="button"
            className="btn btn-default  past-history-action-button"
          >
            Edit
          </button>
        }
        modal
        closeOnDocumentClick={false}
      >
        {close => (
          <div>
            <div class="popup-title">
              <span> Investigations Report <Showhelp gotohelp="edit_investigation_help"/></span>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => close()}>
                  ×
                </span>
              </button>
            </div>
            {this.state.saving ? (
              <div>Saving</div>
            ) : (
              <div>
                <div className="modal-body addinvestigation">
                  <div className="col-sm-12">
                    <div className="col-sm-6">
                      <div className="row form-group">
                        <label className="col-sm-4 col-form-label text-left">
                          Provider
                        </label>
                        <div className="col-sm-8 cursor">
                          <select
                            className="form-control"
                            onChange={e =>
                              this.handleFieldChange('provider', e.target.value)
                            }
                          >
                            {this.options.provider_options.map(
                              (item, index) => (
                                <option
                                  value={item.value}
                                  selected={
                                    this.state.data.provider === item.value
                                  }
                                  key={index}
                                >
                                  {item.label}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="row form-group">
                        <label className="col-sm-4 col-form-label text-left">
                          Test Name
                        </label>
                        <div className="col-sm-8 col-form-label text-left">
                          <input
                            className="form-control"
                            onChange={e =>
                              this.handleFieldChange(
                                'test_name',
                                e.target.value
                              )
                            }
                            value={this.state.data.test_name}
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <label className="col-sm-4 col-form-label text-left">
                          Notation
                        </label>
                        <div className="col-sm-8 cursor">
                          <select
                            className="form-control"
                            onChange={e =>
                              this.handleFieldChange('notation', e.target.value)
                            }
                          >
                            {this.options.notation_options.map(
                              (item, index) => (
                                <option
                                  value={item.value}
                                  selected={
                                    this.state.data.notation === item.value
                                  }
                                  key={index}
                                >
                                  {item.label}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="row form-group">
                        <label className="col-sm-4 col-form-label text-left">
                          Checked By
                        </label>
                        <div className="col-sm-8 cursor">
                          <select
                            className="form-control"
                            onChange={e =>
                              this.handleFieldChange(
                                'checked_by',
                                e.target.value
                              )
                            }
                          >
                            {this.options.checked_by_options.map(
                              (item, index) => (
                                <option
                                  value={item.value}
                                  selected={
                                    this.state.data.checked_by === item.value
                                  }
                                  key={index}
                                >
                                  {item.label}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="row form-group">
                        <label className="col-sm-4 col-form-label">
                          Date of Test
                        </label>
                        <div className="col-sm-8 cursor">
                          <DatePicker
                            className="datePicker"
                            selected={this.state.data.date_of_test}
                            onChange={value =>
                              this.handleFieldChange('date_of_test', value)
                            }
                          />
                        </div>
                      </div>
                      <div className="row form-group form-check">
                        <label
                          className="col-sm-4 col-form-label form-check-label"
                          for="confidential"
                        >
                          Confidential
                        </label>
                        <div className="col-sm-8 cursor">
                          <input  style={{marginTop:'0px'}}
                            type="checkbox"
                            className="form-check-input"
                            id="confidential"
                            checked={this.state.data.condidential}
                            onChange={e =>
                              this.handleFieldChange(
                                'condidential',
                                e.target.checked
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <label className="col-sm-4 col-form-label text-left">
                          Action to be Taken
                        </label>
                        <div className="col-sm-8 cursor">
                          <select
                            className="form-control"
                            onChange={e =>
                              this.handleFieldChange(
                                'action_to_be_taken',
                                e.target.value
                              )
                            }
                          >
                            {this.options.action_to_be_taken_options.map(
                              (item, index) => (
                                <option
                                  value={item.value}
                                  selected={
                                    this.state.data.action_to_be_taken ===
                                    item.value
                                  }
                                  key={index}
                                >
                                  {item.label}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="row form-group">
                        <label className="col-sm-4 col-form-label text-left">
                          Date checked
                        </label>
                        <div className="col-sm-8 cursor">
                          <DatePicker
                            className="datePicker"
                            selected={this.state.data.date_checked}
                            // selected={Date.parse(this.state.startDate)}
                            onChange={value =>
                              this.handleFieldChange('date_checked', value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group row">
                      <div className="col-sm-12 cursor">
                        <input  style={{marginTop:'0px'}}
                          type="checkbox"
                          checked={this.state.data.has_been_actioned}
                          onChange={e =>
                            this.handleFieldChange(
                              'has_been_actioned',
                              e.target.checked
                            )
                          }
                        />
                        <label className="col-sm-3 col-form-label text-left">
                          Has been actioned
                        </label>
                      </div>
                    </div>

                    <div className="form-group row">
                      <textarea
                        value={this.state.data.comments}
                        onChange={e =>
                          this.handleFieldChange('comments', e.target.checked)
                        }
                        style={{ width: '100%', height: '150px' }}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      this.handleSave(close);
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-xs"
                    data-dismiss="modal"
                  >
                    Another
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-xs"
                    data-dismiss="modal"
                    onClick={() => close()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </Popup>
    );
  }
}

export default Editinvestigation;
