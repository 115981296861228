
import React, { Component } from 'react';
import {
  Link
} from "react-router-dom"
import { api_logout } from '../DS/DS.Users';
class leftsidemenu extends Component {
    state = {  }
    async performLogout() {
      await api_logout();
      window.location.reload();
    }
    render() {
        return (
             <aside className="main-sidebar" style={{ backgroundColor: "#161616", paddingTop: 50  }}>
        {/* sidebar: style can be found in sidebar.less */}
        <section className="sidebar" style={{height: 'auto'}}>
          {/* Sidebar user panel */}
          {/* /.search form */}
          {/* sidebar menu: : style can be found in sidebar.less */}
          <ul className="sidebar-menu tree" data-widget="tree">
            <li className="header">ADMIN NAVIGATION</li>
            <li className>
              <a href="index.php"><i className="fa fa-dashboard" /> <span>Dashboard</span></a>
            </li>
            <li className="treeview">
              <a href="#"><i className="fa fa-circle-o" /> Manage SupaDoc
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </a>
              <ul className="treeview-menu" >
                <li className="">
                <Link to="/superadmin/Centers"> <i className="fa fa-laptop" /> <span>Manage Centres</span></Link>
                </li>
                <li className>
                <Link to="/superadmin/PatientAccount"> <i className="fa fa-laptop" /> <span>Patient Account</span></Link>
                </li>
               
               
                <li className>
                <Link to="/superadmin/sessions"> <i className="fa fa-table" /> <span>Manage Centre Sessions</span></Link>
                </li>
                <li className>
                  <a href="manage-payments.php"><i className="fa fa-laptop" /> <span>Manage Payments</span></a>
                </li>
              </ul>
            </li>
            <li className="treeview">
              <a href="#"><i className="fa fa-circle-o" /> Manage My Centre
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </a>
              <ul className="treeview-menu">
                <li className>
                  <a href="manage-doctors.php"><i className="fa fa-edit" /> <span>Manage Doctors</span></a>
                </li>
                <li className>
                  <a href="manage-nurses.php"><i className="fa fa-share" /> <span>Manage Nurses</span></a>
                </li>
                <li className>
                  <a href="manage-practice-managers.php"><i className="fa fa-files-o" /> <span>Manage Practice Managers</span></a>
                </li>
                <li className>
                <Link to="/superadmin/sessions"> <i className="fa fa-table" /> <span>Manage Sessions</span></Link>
                </li>
                <li className>
                  <a href="manage-feedback.php"><i className="fa fa-pie-chart" /> <span>Manage Feedbacks</span></a>
                </li>
                <li className>
                <Link to="/superadmin/managepatients"> <i className="fa fa-table" /> <span>Manage Patients</span></Link>
                </li>
              </ul>
            </li>
            <li className="treeview menu-open active">
              <a href="#"><i className="fa fa-circle-o" /> Configuration
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </a>
              <ul className="treeview-menu " style={{display:'block'}}>
              <li className>
                  <a href="/superadmin/general"><i className="fa fa-edit" /> <span>General</span></a>
                </li>
                <li className>
                  <a href="/superadmin/lists"><i className="fa fa-edit" /> <span>List</span></a>
                </li>
                <li className>
                  <a href="/superadmin/reminders"><i className="fa fa-bell" /> <span>Reminders</span></a>
                </li>
                <li className>
                  <a href="/superadmin/careplan"><i className="fa fa-medkit" /> <span>Care Plans</span></a>
                </li>
                <li className>
                  <a href="/superadmin/messages"><i className="fa fa-medkit" /> <span>Messages</span></a>
                </li>
                <li className>
                  <a href="/superadmin/appointment"><i className="fa fa-medkit" /> <span>Appointment</span></a>
                </li>
                <li className>
                  <a href="/superadmin/accounttext"><i className="fa fa-medkit" /> <span>Account Text</span></a>
                </li>
                <li className>
                  <a href="/superadmin/invoicenotes"><i className="fa fa-medkit" /> <span>Invoice Notes</span></a>
                </li>
                <li className>
                  <a href="/superadmin/bankaccount"><i className="fa fa-medkit" /> <span>Bank Account</span></a>
                </li>
                <li className>
                  <a href="/superadmin/appointmentreminder"><i className="fa fa-medkit" /> <span>Appointment Reminder</span></a>
                </li>
                <li className>
                  <a href="/superadmin/templates"><i className="fa fa-medkit" /> <span>Templates</span></a>
                </li>
              </ul>
            </li>
            <li className="treeview  menu-open">
            <a href="#" onClick={() => this.performLogout()}><i className="fa fa-circle-o" /> Logout
               
              </a>
             </li> 
            
          </ul>
          
        </section>
        {/* /.sidebar */}
      </aside>
          );
    }
}

export default leftsidemenu;