import React, { Component } from "react";
import Header from "../../header";
import Footer from "../../footer";
import Grid from "../../crudcomponent/combinedcrud";
import { dropDown, dropdown1 } from "../../../DS/services";
import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
import { Link } from "react-router-dom";

class managepatients extends Component {
  /*constructor() {
    super();
    
    this.data = {
      data: ''
    };
    
    this.handleChange = this.handleChange.bind(this);
    
  }

  handleChange(event) {
    this.setState({
      data: event.target.value
    });
  }
  _refreshPage() {
    console.log("Clicked");
    window.location.reload();
  }*/
  state = {
    crud: {
      jsonGridVersion: "v2.0",
      pagetitle: "Patients Primary Title",
      subtitle: "Patients sub title",
      grid: true,
      breadcrumb: {
        link: "./managepatients",
        text: "Manage Patients"
      },
      add_form: {
        isVisible: true,
        isBacktoGridOnSubmit: true,
        destination: "patients",
        pk: "patient_id",
        successMessage: "Successfully Added",
        errorMessage: "Something went to Wrong, Try again later",
        schema: {
          title: "Add patients",
          type: "object",
          required: ["Firstname", "City","branch_id"],
          properties: {
            Title: { type: "string", title: "Title" },
            Firstname: { type: "string", title: "First Name" },
            Middlename: { type: "string", title: "Middle Name"},
            Surname:{ type: "string", title: "Surname"},
            
            Address1: { type: "string", title: "Address1" },
            Address2: { type: "string", title: "Address2"},
            Postcode: { type: "string", title: "Post Code"},
            PostalAddress: { type: "string", title: "Postal Address"},
            //PostalCity: { type: "string", title: "PostalCity"},
            PostalPostcode: { type: "string", title: " Postal Postcode"},
            DOB: { type: "string", title: "Date Of Birth" },
            Age: { type: "number", title: "Age"},
            Sex: dropDown("lookup_tb", "value", "display", "where field_name='Sex'"),
            /*Sex: {
              "title": "Sex",
              
              "type": "string",
              "enum": [
                "Select",
                "0",
                "1",
                "2"
              ],
              "enumNames": [
                "Select",
                "Male", 
                "Female",
                "Other"
              ],
              default: "Select"
              
          },*/
          City: dropDown("city_tb", "City_id", "City", ""),
          branch_id: dropDown("branches_tb", "branch_id", "name", ""),
          }
        },

        ui_schema: {
          mobile: {
            "ui:help": "Ex: 0255515678"
          },
          email: {
            "ui:widget": "email",
            "ui:help": "Enter primary contact email"
          }
        }
      },
      edit_form: {
        isVisible: true,
        isBacktoGridOnSubmit: true,
        destination: "patients",
        pk: "patient_id",
        successMessage: "Successfully Updated",
        errorMessage: "Something went to Wrong, Try again later",
        schema: {
          title: "Edit patient",
          type: "object",
         required: ["Firstname", "City","branch_id"],
          properties: {
            Title: { type: "string", title: "Title" },
            Firstname: { type: "string", title: "Firstname" },
            Middlename: { type: "string", title: "Middlename"},
            Surname:{ type: "string", title: "Surname"},
            
            Address1: { type: "string", title: "Address1" },
            Address2: { type: "string", title: "Address2"},
            Postcode: { type: "string", title: "Postcode"},
            PostalAddress: { type: "string", title: "PostalAddress"},
            //PostalCity: { type: "string", title: "PostalCity"},
            PostalPostcode: { type: "string", title: " PostalPostcode"},
            DOB: { type: "string", title: "Date Of Birth" },
            Age: { type: "number", title: "Age"},
            Sex: dropDown("lookup_tb", "value", "display", "where field_name='Sex'"),
            
          City: dropDown("city_tb", "City_id", "City", ""),
          branch_id: dropDown("branches_tb", "branch_id", "name", ""),
            ID: { type: "number" }
          }
        },

        ui_schema: {
         
          ID: {
            "ui:widget": "hidden"
          },
          
        }
      },
      delete: {
        destination: "patients",
        pk: "patient_id",
        isConfirmBeforeDelete: true,
        successMessage: "Successfully Deleted..!",
        errorMessage: "Something went to Wrong, Try again later",
        confirmationTitle: "Do you really want to delete?",
        confirmationMessage: "Are you sure to do this."
      },
      viewgrid: {
        isVisible: true,
        isPagination: true,
        isFiltering: true,
        isActions: true,

        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "patients",
          cols: [
            "patient_id",
            
            "Firstname",
            "Middlename",
            "Surname",
            "Address1",
            "Address2",
            "Postcode",
            "PostalAddress",
            "PostalCity",
            "PostalPostcode",
            "DOB",
            "display",
            "patients.City",
            "city_tb.City",
            "patients.branch_id",
            "name",
             
          ],
          customCols: [
          "CONCAT(Firstname,' ',Middlename,' ', Surname) AS patientname",
          "CONCAT(Address1,' ',Address2) AS Address"
            
          ],
          joins: [
            {
              joinType: "inner",
              joinTable: "branches_tb",
              joinCondition: "branches_tb.branch_id = patients.branch_id"
            },
           {
              joinType: "inner",
              joinTable: "city_tb",
              joinCondition: "city_tb.City_id = patients.City"
            },
            {
              joinType: "inner",
              joinTable: "lookup_tb",
              joinCondition: "lookup_tb.value = patients.Sex"
            }

          ],
          where: [
            {
              type: "and",
              key: "patients.isDeleted",
              operator: "=",
              value: "0"
            },
            {
              type: "and",
              key: "lookup_tb.field_name",
              operator: "=",
              value: "Sex"
            }
           
          ]
        },
        viewtabletitle: "",
        columns: [
          //{ name: "Title", title: "Title"}, 
          { name: "patientname", title: "Patient Name" },
         // { name: "Preferredname", title: "Preferred Name"}, 
          { name: "City", title: "City"},
          { name: "DOB", title: "DOB" },
          //{ name: "Address1", title: "Address" },
          { name: "display", title: "Sex"},
        
          { name: "name", title: "Branch Name"},
          { name: "action", title: "Action"}
        ],
        
        rows: [],
        sortinit: [{ columnName: "patient_id", direction: "desc" }],
        pagesize: 3,
        actions: [
          {
            buttonClass: "info",
            icon: "fa-pencil",
            text: "",
            function: "edit",
            passParam: "patient_id",
            isDefault: true
          },
          {
            buttonClass: "danger",
            icon: "fa-trash",
            text: "",
            function: "delete",
            passParam: "patient_id",
            isDefault: true
          },
          {
            buttonClass: "primary",
            icon: "fa-eye",
            text: "",
            function: "view",
            passParam: "Firstname",
            isDefault: true
          }
        ]
      }
    }
  };
  componentDidMount() {}
  
  render() {
    var gridstyle = {
      padding: "10px"
    };
    return (
      <div>
      <body
        className="skin-blue sidebar-mini"
        data-gr-c-s-loaded="true"
        style={{ height: "auto", minHeight: "100%" }}
      >
        <div
          className="wrapper"
          style={{ height: "auto", minHeight: "100%" }}
        >
          <AdminHeader />
          <Leftsidemenu />
          {/* Content Header (Page header) */}

          <div className="content-wrapper" style={{ minHeight: 314 }}>
            <section className="content-header">
              <h1>
                {this.state.crud.pagetitle}
                <small> {this.state.crud.subtitle} </small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <a href="#">
                    <i className="fa fa-dashboard" /> Home
                  </a>
                </li>

                <li className="active">
                  <Link to={this.state.crud.breadcrumb.link}>
                    {this.state.crud.breadcrumb.text}
                  </Link>
                </li>
              </ol>
            </section>
<br></br>
            <div className="row">
    
              <div className="col-lg-3"></div>
                <div className="col-lg-6">
                 
               
                    <input id="patient" value="0" name="patient" type="radio" checked={this.state.data === "patient"}
                    //onChange={this.handleChange}
                      onClick={this._refreshPage}
                    />Create Patient Account
              

               
                    <input id="non_patient" value="1" name="patient" type="radio" checked={this.state.data === "non-patient"}
                      onChange={this.handleChange}
                    />Create Non-Patient Account
                  
                    <input id="complex" value="2" name="patient" type="radio" checked={this.state.data === "complex"}
                      onChange={this.handleChange}
                    />Create Complex Account
               
            </div>
            <div className="col-lg-3"></div>
            </div>

            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12">
                
                  <Grid
                    style={gridstyle}
                    handleButton={this.handleButton}
                    crud={this.state.crud}
                  />
                  
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </body>
    </div>
    );
  }
}


export default managepatients;
