import React, { Component } from "react";
import AddLabPopup from "./addLabPopup";
class setupfav extends Component {
  state = {
    showLabAdd: false,
  };
  openLabAdd = () => {
    console.log("1");
    this.setState({ showLabAdd: true });
  };
  closeLabAdd = () => {
    this.setState({ showLabAdd: false });
    this.props.list_options();
  };

  render() {
    return (
      <div>
        <div className="modal-body"  style={{overflowY: "scautoroll", height: "auto", border:'0px' }}>
          <div className="col-sm-12 pr-0 mt-3 pl-0">
          <div className="col-sm-6 pr-0 pl-0">
          <div style={{ height: "200px", overflowY: "scroll" }}>
         
            <label
                  className="col-form-label text-left"
                  style={{ marginTop: "15px" }}
                >
  Grouping:
                </label>
                <table
                  className="  table table-bordered tablestyle"
                  style={{ float: "left", overflow: "scroll", width: "100%" }}
                >
                  <thead>
                    <tr style={{ backgroundColor: "#f4f4f4" }}>
                      <th style={{ textAlign: "left" }}>Groups</th>
                      <th style={{ textAlign: "left" }}>Options</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.props.statecontent.test_groups
                    //  .filter((item) => item.type == this.props.type)
                      .map((item) => (
<tr>
                          <td> {item.group_name} &nbsp; </td>
                          <td> 
                          <i
                              onClick={(e) =>
                                this.props.deleteGroup(item.test_group_id)
                              }
                              class="fa fa-trash"
                            >
                              {" "}
                            </i>{" "}
                             </td>
                          </tr>))}
                          </tbody>
                </table>
                          </div>
                <div className="col-sm-12" style={{ textAlign: "left" }}>
                <div className="col-sm-4 pl-0 pr-0 mt-3"><label>Add New Group:</label></div>
                <div className="col-sm-6 pr-0"><input className="form-control" type="text"
                  value={this.props.statecontent.newGroup}
                  onChange={(e) => this.props.handleAddGroup(e)}
                /></div>
                <div className="col-sm-2 mt-3"><button
                  onClick={(e) => this.props.addnewgroup()}
                  type="button"
                  className="btn btn-primary btn-xs" data-dismiss="modal"
                >
                  Add
                </button></div>
                  
                 </div>
            </div>
         
            <div className="col-sm-6 pr-0">
              <div style={{ height: "200px", overflowY: "scroll" }}>
                <label
                  className="col-form-label text-left"
                  style={{ marginTop: "15px" }}
                >
                  Custom Tests:
                </label>

                <table
                  className="  table table-bordered tablestyle"
                  style={{ float: "left", overflow: "scroll", width: "100%" }}
                >
                  <thead>
                    <tr style={{ backgroundColor: "#f4f4f4" }}>
                      <th style={{ textAlign: "left" }}>Tests</th>
                      <th style={{ textAlign: "left" }}>Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.statecontent.pathalogy_tests
                      .filter((item) => item.type == this.props.type)
                      .map((item) => (
                        <tr>
                          <td> {item.test_name} &nbsp; </td>
                          <td>
                            <i
                              onClick={(e) =>
                                this.props.deleteTest(item.test_id)
                              }
                              class="fa fa-trash"
                            >
                              {" "}
                            </i>{" "}
                            {item.isFav == 0 && (
                              <i
                                onClick={(e) =>
                                  this.props.toggleFav(item.test_id, "Fav")
                                }
                                class="fa fa-star"
                              >
                                {" "}
                              </i>
                            )}
                            {item.isFav == 1 && (
                              <i
                                onClick={(e) =>
                                  this.props.toggleFav(item.test_id, "UnFav")
                                }
                                class="fa fa-star"
                                style={{ color: "rgb(255, 182, 77)" }}
                              >
                                {" "}
                              </i>
                            )}
                            {/* <i  onClick= {e => this.props.toggleFav(item.test_id)} class="fa fa-star" title="Reset" aria-hidden="true" style="margin-right: 5px; margin-top: 3px; float: right;"></i>
            <i onClick= {e => this.props.toggleFav(item.test_id)} class="fa fa-star" title="Reset" aria-hidden="true" style="margin-right: 5px; margin-top: 3px; float: right; color: rgb(255, 182, 77);"></i> */}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="col-sm-12" style={{ textAlign: "left" }}>
              <div className="col-sm-3 mt-3"><label>Add New Test:</label></div>
              <div className="col-sm-7"><input className="form-control"
                  type="text"
                  value={this.props.statecontent.newTest}
                  onChange={(e) => this.props.handleAddText(e)}
                /></div>
                <div className="col-sm-2 mt-3"><button
                  onClick={(e) => this.props.addnewtest()}
                  type="button"
                  className="btn btn-primary btn-xs" data-dismiss="modal"
                >
                  Add
                </button></div>
              </div>
              <div className="form-group row">
                <div className="col-sm-6" style={{ marginLeft: "0px" }}>
                  {/* <label style={{textAlign:'left'}}><input type="checkbox" className="" style={{float: 'left', width: 'auto', marginRight: '3px',paddingRight:'10px'}} />&nbsp;Include default tests in list</label> */}
                </div>
              </div>
            </div>

            <div className="col-sm-12 pl-0 pr-0">
              <label
                className="col-form-label text-left"
                style={{ marginTop: "15px" }}
              >
                Lab / Provider:
              </label>
              <div style={{ height: "200px", overflowY: "scroll" }}>
                <table
                  className="  table table-bordered tablestyle"
                  style={{ float: "left", overflow: "scroll", width: "100%" }}
                >
                  <thead>
                    <tr style={{ backgroundColor: "#f4f4f4" }}>
                      <th style={{ textAlign: "left" }}>Lab</th>
                      <th style={{ textAlign: "left" }}>Address</th>
                      <th style={{ textAlign: "left" }}>Email</th>
                      <th style={{ textAlign: "left" }}>Phone No</th>
                      <th style={{ textAlign: "left" }}>Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.statecontent.list_options.lab_master!=undefined && this.props.statecontent.list_options.lab_master.map(
                      (item) => (
                        <tr>
                          <td> {item.name} &nbsp; </td>
                          <td> {item.address} &nbsp; </td>
                          <td> {item.email} &nbsp; </td>
                          <td> {item.contact_no} &nbsp; </td>

                          <td>
                            <i
                              onClick={(e) => this.props.deleteLab(item.lab_id)}
                              class="fa fa-trash"
                            >
                              {" "}
                            </i>{" "}
                            {/* <i  onClick= {e => this.props.toggleFav(item.test_id)} class="fa fa-star" title="Reset" aria-hidden="true" style="margin-right: 5px; margin-top: 3px; float: right;"></i>
            <i onClick= {e => this.props.toggleFav(item.test_id)} class="fa fa-star" title="Reset" aria-hidden="true" style="margin-right: 5px; margin-top: 3px; float: right; color: rgb(255, 182, 77);"></i> */}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-sm-6" style={{ textAlign: "left" }}>
                <AddLabPopup
                  open={this.state.showLabAdd}
                  close={this.closeLabAdd}
                ></AddLabPopup>
                {/* <button onClick={e => this.props.addnewtest()} type="button" className="btn btn-primary" style={{marginRight:'5px',width:'70px',height:'35px',marginBottom: '5px'}} data-dismiss="modal">Add</button> */}
                <button
                  onClick={(e) => this.openLabAdd()}
                  type="button"
                  className="btn btn-primary btn-xs" data-dismiss="modal"
                >
                  Add
                </button>
              </div>
              <div className="form-group row">
                <div className="col-sm-6" style={{ marginLeft: "0px" }}>
                  {/* <label style={{textAlign:'left'}}><input type="checkbox" className="" style={{float: 'left', width: 'auto', marginRight: '3px',paddingRight:'10px'}} />&nbsp;Include default tests in list</label> */}
                </div>
              </div>
            </div>
            {/* <div className="col-sm-6">
    <div className="col-sm-12"  style={{float: 'left',height:'80%', overflowX:'scroll' ,marginTop: '15px'}}>
      <label className="col-form-label text-left">Laboratory Details:</label>
      <table className="calendar table table-bordered tablestyle" style={{float: 'left'}}>
        <thead>
          <tr style={{backgroundColor: '#f4f4f4'}}>    
            <th>Laboratory</th>
            <th>Code</th>
            <th>Preferred</th>
            <th>Paper</th>
            <th>Lables</th>
            <th>Duplicate down</th>
            <th>Label down</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={7} style={{height: '100px'}}>&nbsp;</td>
          </tr>        
        </tbody>
      </table>
      </div>
      <div className="form-group row">
        <div className="col-sm-12" style={{marginLeft: '5px'}}>
        <button type="button" className="btn btn-primary" style={{marginRight:'5px',width:'70px',height:'35px',marginTop:"15px"}} data-dismiss="modal">Edit</button>
        </div></div>
    </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default setupfav;
