import React, { Component } from "react";

import Popup from "reactjs-popup";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
//import { wflow } from './../../../DS/general';
class Viewcelldetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false
    };
  }

  render() {
    return (
      <Popup trigger={<span>Multiple</span>} modal closeOnDocumentClick={false}>
        {close => (
          <div>
            <div class="popup-title">
              <span> Details </span>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => close()}>
                  ×
                </span>
              </button>
            </div>
            {this.state.saving ? (
              <div>Saving</div>
            ) : (
              <div>
                <div className="modal-body addinvestigation">
                  <div className="col-sm-12">
                    <table class="calendar">
                      {this.props.cellappointments.map(thissingleappointment => (
                
                      <tr>
                        <td>
                          {" "}
                          {/*<ContextMenuTrigger collect= {() => this.setRightClickOptions(thisappointment, startDate, starttime, doctor_id)}id="1"> George </ContextMenuTrigger>*/}{" "}
                           {thissingleappointment.Firstname} {" "}
                        </td> 
                      </tr>
                      ))}
                      
                    </table>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      this.handleSave(close);
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Another
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                    onClick={() => close()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </Popup>
    );
  }
}

export default Viewcelldetails;
