import axios from 'axios';
//Add careplan goal
export const add_keyword = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=appointmentReminder&action=add_Keyword`, data);
}
export const update_Keyword = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=appointmentReminder&action=update_Keyword`, data);
}
export const get_Keyword = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=appointmentReminder&action=get_Keyword`, data);
}
export const delete_Keyword = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=appointmentReminder&action=delete_Keyword`, data);
}
export const delete_Keyword1 = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=appointmentReminder&action=delete_Keyword1`, data);
}
export const update_setting = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=appointmentReminder&action=update_setting`, data);
}
