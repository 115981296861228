import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GoalPopup from "./GoalPopup";
import ProblemPopup from "./ProblemPopup";
import TaskPopup from "./TaskPopup";
import {api_list_options, api_addenchance,api_last_careplan} from "./../../../DS/DS.EPC";
import moment from "moment";
import SearchDoctors from './../Correspondence/searchDoctors';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import Carer from '../familySocialHistory/carer'
import Showhelp from './../../showHelp';

class addenhanced extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      reminderthree: false,
      remindersix: true,
      remindertwelve:true,
      remindernine:false,
      searchDoc: false,
      from_guid: "",
      selected_doc_id: "",
      selected_doc_mobile: "",
      selected_doc_address: "",
      selected_doc_category: "",

      careplandate: "",
      precareplandate: new Date(),
      careplanreviewdate: new Date(),
      precareplanOutcome: "",
      provider: this.props.logged_info!=undefined?this.props.logged_info.content.details[0].doctor_id:"",
      total_tasks: [],
      list_options: {},
      patient_id: this.props.patient_id,

      showGoalPopup: false,
      showTaskPopup: false,
      showProblemsPopup: false,
      reminder_date: new Date(),
      care_details: "",

      epcGoal: "",
      epcGoalIds: "",

      epcTask: "",
      recorded_date: new Date(),
      epcProblem: "",
      epcProblemIds: "",

      epcProvider: "",
      other_comments: "",
      open:this.props.showForm,
      prev_by:[]
     }
  }
  componentDidMount() {
    // this.printCert();
    // alert("patientid"+ this.props.redux_patientId);
     this.props.list_options().then(res => {
       //   console.log("addPatientForm addPatientForm",res);
       this.setState({ list_options: res.data.content });
       console.log(this.state.list_options);
     });

     
   }

   componentWillMount=()=>
   {
     this.props.lastcareplan({patient_id: this.props.redux_patientId}).then(res => {
       if(res.data.content!='')
       {
         console.log(res.data.content[0].prev_care_plan_date)
         this.setState({ careplandate: res.data.content[0].prev_care_plan_date,precareplandate:res.data.content[0].provider_name,prev_by:res.data.content[0] });
       }
       else
       {
         this.setState({ careplandate: '',precareplandate:'' });
         
       }
     });
   }
   componentWillReceiveProps(nextProps) {
     
       if(this.state.provider != nextProps.logged_info.content.details[0].doctor_id){
         this.setState({
           provider: nextProps.logged_info.content.details[0].doctor_id});
       }
       
      
   }


   handleDate = date => {
     this.setState({ careplandate: date });
     
   };

   handleDate4 = date => {
     this.setState({ reminder_date: date });
     
   };

   handleDate2 = date => {
     this.setState({ precareplandate: date });
     
   };

   handleDate3 = date => {
     this.setState({ careplanreviewdate: date });
     this.setState({remindersix: false,remindertwelve:false})
     
   };

   handleInput = (e, key, value) => {
     this.setState({ [key]: value });
   }
   handleReminderInput =(e,val) =>
   {
     if(e.target.checked==true)
     {
       this.setState({remindersix:true, remindertwelve: true})
     }
     else
     {
       this.setState({remindersix:false, remindertwelve: false})
     }
   }
//problems
closeProblemPopup = () => {
   this.setState({showProblemsPopup:false});
}

openProblemPopup = ()  => {
    this.setState({showProblemsPopup:true});
} 

//goal
closeGoalPopup = () => {
 this.setState({showGoalPopup:false});
}

openGoalPopup = ()  => {
  this.setState({showGoalPopup:true});
} 

//task
closeTaskPopup = () => {
 this.setState({showTaskPopup:false});
}

openTaskPopup = ()  => {
  this.setState({showTaskPopup:true});
} 

addGoalEPC = (goal, ids) => {
 this.setState({epcGoal: goal});
 this.setState({epcGoalIds: ids});
 this.closeGoalPopup();
}
addProblemEPC = (problem, ids) => {
 this.setState({epcProblem: problem});
 this.setState({epcProblemIds: ids});
 this.closeProblemPopup();
}

addSelectedDoc=(id)=>
{
  console.log("&&&&&&&&&&&&&&&&&&&&&&",id)
   this.setState({searchDoc:false})
   if(id!='')
   {
   this.setState({
     from_guid:id.firstname+'.'+id.surname,
   })
   }
}
serachDoctor=()=>
{
   this.setState({searchDoc:true})
}
removeTask = (i) => {
 let total_tasks = this.state.total_tasks;
 delete total_tasks[i];
 this.setState({total_tasks: total_tasks});
}
// this.props.addTasksEPC(total_selected_tasks, this.state.duedate, this.state.from_guid, this.state.selected_doc_id, this.state.selected_doc_address, this.state.selected_doc_category , this.state.selected_doc_mobile, this.state.no_of_visits);
addTasksEPC = (taskitem, date, provider, provider_id, provider_address, provider_mobile,  provider_category, no_of_visits) => {
 let total_tasks = this.state.total_tasks;
 let task = {
   task: taskitem, 
   due_date: date,
   provider: provider, 
   provider_id: provider_id, 
   provider_address: provider_address, 
   provider_mobile: provider_mobile, 
   provider_category: provider_category,

   no_of_visits: no_of_visits
 }
 total_tasks.push(task);
 this.setState({total_tasks: total_tasks});
 this.closeTaskPopup();
}

addenchance = () => {
 if (this.state.total_tasks.length>0 && this.state.epcProblem!="" && this.state.epcGoal!="") {
   this.props.addenchance(this.state).then((res)=> {
     // alert("savedid"+ res.data.last_inserted_id);
   //   this.props.OpenPopupLetter(res.data.last_inserted_id);
      
      NotificationManager.success('New primary care record created',)
      this.props.lastcareplan({patient_id: this.props.redux_patientId}).then(res => {
       if(res.data.content!='')
       {
         console.log(res.data.content[0].prev_care_plan_date)
         this.setState({ careplandate: res.data.content[0].prev_care_plan_date,precareplandate:res.data.content[0].provider_name });
       }
       else
       {
         this.setState({ careplandate: '',precareplandate:'' });
         
       }
       this.props.changeKey();
     });
    })
 }
 else
 {
   confirmAlert({
     title: 'Information Required',
     message: 'Problems, Goals and Tasks are required to proceed',
     buttons: [
     {
         label: 'Ok',
         onClick: () => {
          // alert(this.state.open)
           
           },
         },
       /*{
         label: 'No',
           //onClick: () => { this.updateGridIn()},
         onClick: () => {}
       },*/
     ],
   });
 }
}
 /*if(this.state.total_tasks.length>0 && this.state.epcProblem!="" && this.state.epcGoal!="") {
   this.props.addenchance(this.state).then((res)=> {
     // alert("savedid"+ res.data.last_inserted_id);
      this.props.OpenPopupLetter(res.data.last_inserted_id);
      this.props.changeKey();
      NotificationManager.success('New primary care record created',)
      this.props.lastcareplan({patient_id: this.props.redux_patientId}).then(res => {
       if(res.data.content!='')
       {
         console.log(res.data.content[0].prev_care_plan_date)
         this.setState({ careplandate: res.data.content[0].prev_care_plan_date,precareplandate:res.data.content[0].provider_name });
       }
       else
       {
         this.setState({ careplandate: '',precareplandate:'' });
         
       }
     });
    })
 } else {
   alert("Follwing fields are required:  Problem, Goal & Task");
 }*/

 render() {
  return (
 <Popup
      modal
       open={this.state.open}
       closeOnDocumentClick={false}>
        {close => (
          <div style={{ width:'1000px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>
                  <div className="modal-header">
                    <div class="popup-title" style={{textAlign:'left'}}>
                      <button type="button" className="close"  onClick={() => {this.props.changeKey();}} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                      <span>Gp Management Plan <Showhelp gotohelp="add_enhanced_help"/></span>
                    </div>
                  </div>
                  <div className="modal-body"   style={{border:'0px' }}>

                  <div className="col-sm-12">
  <div className="row mt-3">
    <div className="col-sm-2"><label>Previous care plan date:</label></div>
    <div className="col-sm-2 pl-0">
    <label className="col-form-label">{this.state.careplandate}</label>
    </div>
    <div className="col-sm-2"><label>Previous care plan by:</label></div>
    <div className="col-sm-3">
    <label className="col-form-label ">{this.state.prev_by.Firstname} {this.state.prev_by.Surname}</label>
    </div>
  </div>
  <div className="row">
    <div className="col-sm-12 mt-2">
      <div className="col-sm-1 pl-0 pr-0"><label>Previous care plan outcome:</label></div>
      <div className="col-sm-11 pr-0 pl-0" style={{width: '90%'}}>
      <textarea value={this.state.precareplanOutcome} style={{width: '100%', height: '80px', float: 'left', border: '1px solid #ddd', padding: '10px'}} onChange={e=> this.handleInput(e, "precareplanOutcome", e.target.value)}/>
        </div>
    </div>
  </div>
  <div className="row">
    <div className="col-sm-1 pr-0 mt-2"><label>Carer details</label></div>
    <div className="col-sm-4 mt-0"><input type="text" name="search_condition" className="form-control" value={this.state.from_guid} onChange={e => this.handleInput(e, "from_guid", e.target.value)} /></div>
    <div className="col-sm-3 mt-2 pl-0">{(this.state.searchDoc) ?
                                        <Carer showmodal={this.state.searchDoc} changeKey={this.addSelectedDoc} isEpc='yes'/> : ''}
                                          <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{width:'68px'}} onClick={()=> this.serachDoctor()} >Add</button> </div>
  </div>
  <div className="row">
    <div className="col-sm-5 pr-0">
      <div style={{float: 'left', width: '100%'}}>
      <table style={{width: '100%'}}>
                                  <tbody><tr>
                                      <td>
                                        <div style={{width: '100%', height: '100px', }}>
                                          <table className="table-bordered" style={{float: 'left', width: '100%'}}>
                                            <thead>
                                              <tr style={{backgroundColor: '#f4f4f4'}}>
                                                <th>Problem</th>
                                              </tr>
                                            </thead>
                                            <tbody style={{overflowY: 'auto'}}>
                                              <tr>
                                                <td>   {this.state.epcProblem}  </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody></table>
      </div>
    </div>
    <GoalPopup addGoalEPC={this.addGoalEPC} open={this.state.showGoalPopup}  close={this.closeGoalPopup}/>
                            <TaskPopup addTasksEPC={this.addTasksEPC}  open={this.state.showTaskPopup}  close={this.closeTaskPopup}/>
                            <ProblemPopup addProblemEPC={this.addProblemEPC} open={this.state.showProblemsPopup}  close={this.closeProblemPopup}/>
    <div className="col-sm-1"><button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={e => this.openProblemPopup()}>Problem</button></div>
    <div className="col-sm-5 pl-0">
      <div style={{float: 'left', width: '100%'}}>
      <table style={{width: '100%'}}>
                                  <tbody><tr>
                                      <td>
                                        <div style={{width: '100%', height: '100px'}}>
                                          <table className=" table-bordered" style={{float: 'left', width: '100%'}}>
                                            <thead>
                                              <tr style={{backgroundColor: '#f4f4f4'}}>
                                                <th>Goal</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>    {this.state.epcGoal} </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody></table>
      </div>
    </div>
    <div className="col-sm-1 cus-widf pl-0"><button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs"  onClick={e => this.openGoalPopup()}>Goal</button></div>
  </div>
  <div className="row">
    <div className="col-sm-11 mt-2 pr-0">
      <div style={{float: 'left', width: '100%'}}>
      <table style={{width: '100%'}}>
                                    <tbody><tr>
                                        <td>
                                          <div style={{width: '100%', height: '60px'}}>
                                            <table className=" table-bordered" style={{float: 'left', width: '100%'}}>
                                              <thead>
                                                <tr style={{backgroundColor: '#f4f4f4'}}>
                                                  <th>Task</th>
                                                  <th>Provider </th>
                                                  <th>Category</th>
                                                  <th>Due Date</th>
                                                  <th>No of Visits</th>
                                                  <th>Options</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.total_tasks.length>0 && this.state.total_tasks.map((item, i) => (
                                                  <tr>
                                                  
                                                <td> {item.task}</td>
                                                <td> {item.provider}</td>
                                                <td> {item.provider_category}</td>
                                                <td> {moment(item.due_date).format('DD/MM/YYYY')}</td>
                                                
                                                <td> {item.no_of_visits}</td>
                                                <td>  <span onClick={e => this.removeTask(i)} > Remove  </span> </td>
                                                </tr>

                                                ))}
                                                
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody></table>
      </div>
    </div>
    <div className="col-sm-1 mt-2  cus-widf"><button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={e => this.openTaskPopup()} >Task</button></div>
  </div>

                             {/* <div className="form-group row">
                            <div className="col-sm-12">
                              <div className="col-sm-11">
                                <div style={{float: 'left', width: '100%'}}>
                                  <table style={{width: '100%'}}>
                                    <tbody><tr>
                                        <td>
                                          <div style={{width: '100%', height: '60px'}}>
                                            <table className=" table-bordered" style={{float: 'left', width: '100%'}}>
                                              <thead>
                                                <tr style={{backgroundColor: '#f4f4f4'}}>
                                                  <th>Task</th>
                                                  <th>Provider </th>
                                                  <th>Category</th>
                                                  <th>Due Date</th>
                                                  <th>No of Visits</th>
                                                  <th>Options</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.total_tasks.length>0 && this.state.total_tasks.map((item, i) => (
                                                  <tr>
                                                  
                                                <td> {item.task}</td>
                                                <td> {item.provider}</td>
                                                <td> {item.provider_category}</td>
                                                <td> {moment(item.due_date).format('DD/MM/YYYY')}</td>
                                                
                                                <td> {item.no_of_visits}</td>
                                                <td>  <span onClick={e => this.removeTask(i)} > Remove  </span> </td>
                                                </tr>

                                                ))}
                                                
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody></table>
                                </div>
                              </div>
                              <div className="col-sm-1">
                                <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" style={{margin: '0px auto 0px', padding: '2px 10px'}} onClick={e => this.openTaskPopup()} >Task</button>
                              </div>
                            </div>
                          </div> */}



  <div className="row">
    <div className="col-sm-12 mt-2">
      <div style={{float: 'left', width: '100%'}}>
      <table style={{width: '100%'}}>
                                    <tbody><tr>
                                        <td>
                                          <div style={{width: '100%', height: '60px'}}>
                                            <table className=" table-bordered" style={{float: 'left', width: '100%'}}>
                                              <thead>
                                                <tr style={{backgroundColor: '#f4f4f4'}}>
                                                  <th>Provider</th>
                                                  <th>Category</th>
                                                  <th>Contact</th>
                                                  <th>Address</th>
                                                  
                                              
                                                  
                                                </tr>
                                              </thead>
                                              <tbody>
                                              {this.state.total_tasks.length>0 && this.state.total_tasks.map(item => (
                                                  <tr>
                                                  
                                                <td> {item.provider}</td>
                                              <td> {item.provider_category}</td>
                                              <td> {item.provider_mobile!=null?item.provider_mobile:'-'}</td>
                                              <td> {item.provider_address}</td>
                                                


                                                
                                                </tr>

                                                ))}

                                            
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody></table>
      </div>
    </div>
  </div>

  <div className="row">
    <div className="col-sm-1 pr-0 col-sm-01"><label className="col-form-label text-left">Performed By:</label></div>
    <div className="col-sm-3">
    <select
                                              className="form-control"
                                              value={this.state.provider}
                                              onChange={e =>
                                                this.handleInput(e, "provider", e.target.value)
                                              }
                                            >
                                              <option value=""> Select Provider </option>
                                              {this.state.list_options != undefined &&
                                                this.state.list_options.provider_options != null &&
                                                this.state.list_options.provider_options.map(
                                                  (item, index) => (
                                                    //      (item, index) => (
                                                    <option
                                                      value={item.value}
                                                      selected={
                                                        this.state.provider === item.value
                                                      }
                                                      key={index}
                                                    >
                                                      Dr.{item.label}
                                                    </option>
                                                  )
                                                )}
                                            </select>
    </div>
    <div className="col-sm-3"><label className="col-form-label text-left">Date for review of care plan:</label></div>
    <div className="col-sm-2" style={{marginLeft: '-70px', marginTop: '-5px'}}>
    <DatePicker
                                            //ref={this.textInput}
                                            ref={input => {
                                              this.textInput = input;
                                            }}
                                            id="date-jumper"
                                            inputRef={ref => {
                                              this.textInput = ref;
                                            }}
                                            todayButton="Go to Today"
                                            selected={this.state.careplanreviewdate}
                                            onChange={this.handleDate3}
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            showYearDropdown
                                            dateFormatCalendar="MMMM"
                                            dateFormat="dd/MM/yyyy"
                                            yearDropdownItemNumber={15}
                                            scrollableYearDropdown
                                          />
    </div>
  </div>
  <div className="row">
    <div className="col-sm-3 pr-0">
      <label className="checkbox-inline">
        <input type="checkbox" name="optcheckbox" /> 6 months /12 months reminder
      </label>
    </div>
    <div className="col-sm-6 pr-0" style={{marginLeft: '-45px'}}>
    <label className="checkbox-inline">  <input checked={this.state.reminderthree}  onChange={e => this.handleInput(e, "reminderthree", e.target.checked==true?true:false)}  type="checkbox" className="" style={{float: 'left', width: 'auto'}} /><span style={{ paddingLeft: '5px', marginRight: '5px', float: 'left'}}> 3 Months </span> </label>
    <label className="checkbox-inline">  <input checked={this.state.remindersix} onChange={e => this.handleInput(e, "remindersix", e.target.checked==true?true:false)}  type="checkbox" className="" style={{float: 'left', width: 'auto'}} /><span style={{ paddingLeft: '5px', marginRight: '5px', float: 'left'}}> 6 Months </span> </label>
    <label className="checkbox-inline">  <input checked={this.state.remindernine} onChange={e => this.handleInput(e, "remindernine", e.target.checked==true?true:false)} type="checkbox" className="" style={{float: 'left', width: 'auto'}} /><span style={{ paddingLeft: '5px', marginRight: '5px', float: 'left'}}> 9 Months</span> </label>
    <label className="checkbox-inline">  <input checked={this.state.remindertwelve} onChange={e => this.handleInput(e, "remindertwelve", e.target.checked==true?true:false)} type="checkbox" className="" style={{float: 'left', width: 'auto'}} /><span style={{ paddingLeft: '5px', marginRight: '5px', float: 'left'}}> 12 Months</span> </label>
    </div>
  </div>
</div>


                   </div>    
                   <div className="modal-footer" >
                        <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" onClick={e => {this.addenchance();}}>Save</button>
                        <button type="button" className="btn btn-primary btn-xs"  onClick={() => this.props.changeKey()} data-dismiss="modal" aria-label="Close">Close</button>
                      </div>      
              
          </div>        
          )}
          </Popup>
  )}

}

const mapStateToProps = state => {
  
  let logged_info =state.getDrAppointments.logged_info != undefined ? state.getDrAppointments.logged_info: "";
  let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
  let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';


  return {
 
    logged_info,redux_docId,redux_patientId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    list_options: data => dispatch(api_list_options(data)),
    addenchance: data => dispatch(api_addenchance(data)),
    lastcareplan : data => dispatch(api_last_careplan(data))
     
  };
};
export default connect(mapStateToProps, mapDispatchToProps)( addenhanced);