import React, { Component } from "react";
import Header from "../../header";
import Footer from "../../footer";
import Grid from "../../crudcomponent/combinedcrud";
import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import {} from '../../../DS/DS.ListPage';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import {NotificationContainer,NotificationManager} from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import {api_get_settings} from '../../../DS/DS.Reminders';
import {api_third_table_setting,api_default_lookup_setting,api_save_setting} from '../../../DS/DS.AppointmentSettings';
import AppointmentLayout from './appointmentLayout';
class AppointmentSetting extends Component
{
  constructor (props) {
    super(props);
    this.state={
        startTime:1,
        endTime:1,
        start_time_setting:[],
        end_time_setting:[],
        appointmentLength:0,
        appointment_length_setting:[],
        refreshInterval:0,
        refresh_interval_setting:[],
        display_sunday:false,
        making_notes:false,
        patient_arrives:false,
        insurance_fund:false,
        creating_account:false,
        legend_status:false,
        color_appointments:false,
        open_patient_records:false,
        booked_by:false,
        cancelled_appointment:false,
        inactive_patients:false,
        reason_required:false,
        display_appointment_type:false,
        avoid_appointment_type:false,
        attendance_status:false,
        show_uncompleted:false,
        add_to_waiting_room:false,
        add_to_doctor_waiting_room:false,
        showAppointment:false,
        reloadkey:0
    }
  }

  componentWillMount=()=>
  {
      const getStart={seting_name:"start_time",module:"Appointment",compare_to:"START_TIME"}
        this.props.apithirdtablesettings(getStart).then(
            req=>
            { 
                this.setState({startTime:req.data.content[0].setting_value})
            }
        )
        const getStartlookup={compare_to:"START_TIME"}
        this.props.apilookupsettings(getStartlookup).then(
            req=>
            { this.setState({start_time_setting:req.data.content})
            }
        )
        const getEnd={seting_name:"end_time",module:"Appointment",compare_to:"END_TIME"}
        this.props.apithirdtablesettings(getEnd).then(
            req=>
            { this.setState({endTime:req.data.content[0].setting_value})
            }
        )
        const getEndlookup={compare_to:"END_TIME"}
        this.props.apilookupsettings(getEndlookup).then(
            req=>
            { this.setState({end_time_setting:req.data.content})
            }
        )
        const getappoin={seting_name:"appointment_length",module:"Appointment",compare_to:"APPOINTMENT_LENGTH"}
        this.props.apithirdtablesettings(getappoin).then(
            req=>
            { this.setState({appointmentLength:req.data.content[0].setting_value})
            }
        )
        const getappoinlookup={compare_to:"APPOINTMENT_LENGTH"}
        this.props.apilookupsettings(getappoinlookup).then(
            req=>
            { this.setState({appointment_length_setting:req.data.content})
            }
        )
        const getrefresh={seting_name:"refresh_interval",module:"Appointment",compare_to:"REFRESH_INTERVAL"}
        this.props.apithirdtablesettings(getrefresh).then(
            req=>
            { this.setState({refreshInterval:req.data.content[0].setting_value})
            }
        )
        const getrefreshlookup={compare_to:"REFRESH_INTERVAL"}
        this.props.apilookupsettings(getrefreshlookup).then(
            req=>
            { this.setState({refresh_interval_setting:req.data.content})
            }
        )
        const settname={setting_name:'display_sunday'}
          this.props.apigetsettings(settname).then(res=>{
           this.setState({display_sunday:res.data.content[0].setting_value})
          })
        const settname1={setting_name:'making_notes'}
          this.props.apigetsettings(settname1).then(res=>{
           this.setState({making_notes:res.data.content[0].setting_value})
          })
        const settname2={setting_name:'patient_arrives'}
          this.props.apigetsettings(settname2).then(res=>{
           this.setState({patient_arrives:res.data.content[0].setting_value})
          })  
        const settname3={setting_name:'insurance_fund'}
          this.props.apigetsettings(settname3).then(res=>{
           this.setState({insurance_fund:res.data.content[0].setting_value})
          })  
        const settname4={setting_name:'creating_account'}
          this.props.apigetsettings(settname4).then(res=>{
           this.setState({creating_account:res.data.content[0].setting_value})
          })  
        const settname5={setting_name:'legend_status'}
          this.props.apigetsettings(settname5).then(res=>{
           this.setState({legend_status:res.data.content[0].setting_value})
          })  
        const settname6={setting_name:'color_appointments'}
          this.props.apigetsettings(settname6).then(res=>{
           this.setState({color_appointments:res.data.content[0].setting_value})
          })
        const settname7={setting_name:'open_patient_records'}
          this.props.apigetsettings(settname7).then(res=>{
           this.setState({open_patient_records:res.data.content[0].setting_value})
          })  
        const settname8={setting_name:'booked_by'}
          this.props.apigetsettings(settname8).then(res=>{
           this.setState({booked_by:res.data.content[0].setting_value})
          })
        const settname9={setting_name:'cancelled_appointment'}
          this.props.apigetsettings(settname9).then(res=>{
           this.setState({cancelled_appointment:res.data.content[0].setting_value})
          })
        const settname10={setting_name:'inactive_patients'}
            this.props.apigetsettings(settname10).then(res=>{
             this.setState({inactive_patients:res.data.content[0].setting_value})
            })
        const settname11={setting_name:'reason_required'}
            this.props.apigetsettings(settname11).then(res=>{
             this.setState({reason_required:res.data.content[0].setting_value})
            })    
        const settname12={setting_name:'display_appointment_type'}
            this.props.apigetsettings(settname12).then(res=>{
             this.setState({display_appointment_type:res.data.content[0].setting_value})
            })    
        const settname13={setting_name:'avoid_appointment_type'}
            this.props.apigetsettings(settname13).then(res=>{
             this.setState({avoid_appointment_type:res.data.content[0].setting_value})
            })
        const settname14={setting_name:'attendance_status'}
            this.props.apigetsettings(settname14).then(res=>{
             this.setState({attendance_status:res.data.content[0].setting_value})
            })
        const settname17={setting_name:'show_uncompleted'}
            this.props.apigetsettings(settname17).then(res=>{
             this.setState({show_uncompleted:res.data.content[0].setting_value})
            })
        const settname15={setting_name:'add_to_waiting_room'}
            this.props.apigetsettings(settname15).then(res=>{
             this.setState({add_to_waiting_room:res.data.content[0].setting_value})
            })
        const settname16={setting_name:'add_to_doctor_waiting_room'}
            this.props.apigetsettings(settname16).then(res=>{
             this.setState({add_to_doctor_waiting_room:res.data.content[0].setting_value})
            })
  }
  saveSetting=()=>
  {
      if(this.state.display_sunday==false)
      {
          this.state.display_sunday=0
      }
      else{
        this.state.display_sunday=1
      }
      if(this.state.making_notes==false)
      {
          this.state.making_notes=0
      }
      else{
        this.state.making_notes=1
      }
      if(this.state.patient_arrives==false)
      {
          this.state.patient_arrives=0
      }
      else{
        this.state.patient_arrives=1
      }
      if(this.state.insurance_fund==false)
      {
          this.state.insurance_fund=0
      }
      else{
        this.state.insurance_fund=1
      }
      if(this.state.creating_account==false)
      {
          this.state.creating_account=0
      }
      else{
        this.state.creating_account=1
      }
      if(this.state.legend_status==false)
      {
          this.state.legend_status=0
      }
      else{
        this.state.legend_status=1
      }
      if(this.state.color_appointments==false)
      {
          this.state.color_appointments=0
      }
      else{
        this.state.color_appointments=1
      }
      if(this.state.open_patient_records==false)
      {
          this.state.open_patient_records=0
      }
      else{
        this.state.open_patient_records=1
      }
      if(this.state.booked_by==false)
      {
          this.state.booked_by=0
      }
      else{
        this.state.booked_by=1
      }
      if(this.state.cancelled_appointment==false)
      {
          this.state.cancelled_appointment=0
      }
      else{
        this.state.cancelled_appointment=1
      }
      if(this.state.inactive_patients==false)
      {
          this.state.inactive_patients=0
      }
      else{
        this.state.inactive_patients=1
      }
      if(this.state.reason_required==false)
      {
          this.state.reason_required=0
      }
      else{
        this.state.reason_required=1
      }
      if(this.state.display_appointment_type==false)
      {
          this.state.display_appointment_type=0
      }
      else{
        this.state.display_appointment_type=1
      }
      if(this.state.avoid_appointment_type==false)
      {
          this.state.avoid_appointment_type=0
      }
      else{
        this.state.avoid_appointment_type=1
      }
      if(this.state.attendance_status==false)
      {
          this.state.attendance_status=0
      }
      else{
        this.state.attendance_status=1
      }
      if(this.state.show_uncompleted==false)
      {
          this.state.show_uncompleted=0
      }
      else{
        this.state.show_uncompleted=1
      }
      if(this.state.add_to_waiting_room==false)
      {
          this.state.add_to_waiting_room=0
      }
      else{
        this.state.add_to_waiting_room=1
      }
      if(this.state.add_to_doctor_waiting_room==false)
      {
          this.state.add_to_doctor_waiting_room=0
      }
      else{
        this.state.add_to_doctor_waiting_room=1
      }
        const setting_data={
            startTime:this.state.startTime,
            endTime:this.state.endTime,
            appointmentLength:this.state.appointmentLength,
            refreshInterval:this.state.refreshInterval,
            display_sunday:this.state.display_sunday,
            making_notes:this.state.making_notes,
            patient_arrives:this.state.patient_arrives,
            insurance_fund:this.state.insurance_fund,
            creating_account:this.state.creating_account,
            legend_status:this.state.legend_status,
            color_appointments: this.state.color_appointments,
            open_patient_records:this.state.open_patient_records,
            booked_by:this.state.booked_by,
            cancelled_appointment:this.state.cancelled_appointment,
            inactive_patients:this.state.inactive_patients,
            reason_required:this.state.reason_required,
            display_appointment_type:this.state.display_appointment_type,
            avoid_appointment_type:this.state.avoid_appointment_type,
            attendance_status:this.state.attendance_status,
            show_uncompleted:this.state.show_uncompleted,
            add_to_waiting_room:this.state.add_to_waiting_room,
            add_to_doctor_waiting_room:this.state.add_to_doctor_waiting_room
        }

    this.props.apisavesettings(setting_data).then(
        req=>{
            NotificationManager.success('Setting saved successfully',)
         //   this.props.reloadPage()
        }
    )
  }
  reloadPage=()=>
  {
       this.setState({showAppointment:false})
      this.setState({reloadkey:Math.random()})
  }
  render() {
    return ( 
       <div>
           <AdminHeader /><Leftsidemenu />
           <NotificationContainer />
             <section className="content-header" style={{marginLeft: '240px'}}>
                 <h1>
                     Appointment
                 </h1>
                 <ol className="breadcrumb">
                     <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                     <li className="active">List</li>
                 </ol>
             </section>
             {/* main section start */}
             <section className="content" style={{marginLeft: '240px'}}>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="box">
		                    <div className="col-xs-12" style={{margin:'0px auto', float:'none'}}>
                                <div className="box-body">
				                    <div className="col-sm-12 col-md-10 col-xl-10">		
							            <div id="pageContent">
                                        <div className="form-group row m-b-10">
	                                        <div className="col-sm-12 col-md-3 col-xl-3">
		                                        <label className="col-form-label text-left">&nbsp;Start time</label>			
	                                        </div>			
	                                        <div className="col-sm-12 col-md-3 col-xl-3">
                                                <select name="select" className="form-control" style={{padding:'.0rem .5rem',height:'30px', fontSize:'14px'}} 
                                                value={this.state.startTime} onChange={(e)=>this.setState({startTime:e.target.value})}>
                                                {this.state.start_time_setting != undefined && this.state.start_time_setting.length> 0 && this.state.start_time_setting.map((startsett) => 
                                                <option key={startsett.value} value={startsett.value} >{startsett.display}</option>)}
                                    
                                                </select>
	                                        </div>
                                            <div className="col-sm-12 col-md-2 col-xl-2 text-right">
                                                <label className="col-form-label font-normal">&nbsp;End time</label>			
                                            </div>			
                                            <div className="col-sm-12 col-md-3 col-xl-3">
                                                <select name="select" className="form-control" style={{padding:'.0rem .5rem', height:'30px', fontSize:'14px'}} value={this.state.endTime} onChange={(e)=>this.setState({endTime:e.target.value})}>
                                                {this.state.end_time_setting != undefined && this.state.end_time_setting.length> 0 && this.state.end_time_setting.map((endsett) => 
                                                <option key={endsett.value} value={endsett.value} >{endsett.display}</option>)}
                                    
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <div className="col-sm-12 col-md-3 col-xl-3">
                                                <label className="col-form-label text-left">&nbsp;Refresh interval</label>			
                                            </div>			
                                            <div className="col-sm-12 col-md-3 col-xl-3">
                                                <select name="select" className="form-control" style={{padding:'.0rem .5rem', height:'30px', fontSize:'14px'}} value={this.state.refreshInterval} onChange={(e)=>this.setState({refreshInterval:e.target.value})}>
                                                    {this.state.refresh_interval_setting != undefined && this.state.refresh_interval_setting.length> 0 && this.state.refresh_interval_setting.map((refsett) => 
                                                    <option key={refsett.value} value={refsett.value} >{refsett.display}</option>)}
                                                 </select>
                                            </div>
                                            <span>
                                                <label className="col-form-label text-left">&nbsp;Seconds (minimum 15 seconds)</label>			
                                            </span>
                                        </div>
                                        <div class="form-group row m-b-10">
                                            <div class="col-sm-12 col-md-3 col-xl-3">
                                                <label class="col-form-label text-left">&nbsp;Appointment length</label>			
                                            </div>			
                                            <div class="col-sm-12 col-md-3 col-xl-3">
                                                <select name="select" class="form-control" style={{padding:'.0rem .5rem', height:'30px', fontSize:'14px'}} value={this.state.appointmentLength} onChange={(e)=>this.setState({appointmentLength:e.target.value})}>
                                                {this.state.appointment_length_setting != undefined && this.state.appointment_length_setting.length> 0 && this.state.appointment_length_setting.map((appsett) => 
                                                <option key={appsett.value} value={appsett.value} >{appsett.display}</option>)}
                                    
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-0">
                                            <div className="col-sm-12">
                                                <div className="border-checkbox-section">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label font-normal" for="checkbox3" value={this.state.display_sunday} onChange={(e)=>this.setState({display_sunday:e.target.checked})}>
                                                            <input className="border-checkbox checkbox-left" type="checkbox" id="checkbox3"
                                                            checked={this.state.display_sunday=="1"?'checked':''}/>
                                                             &nbsp; Display Sunday in appointment window &nbsp; &nbsp; &nbsp;
                                                        </label>
                                                        
			                                        </div>
		                                        </div>			
	                                        </div>
                                        </div>
                                        <div className="form-group row m-b-0">
	    	                                <div className="col-sm-12">
		                                        <div className="border-checkbox-section">
			                                        <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label left-class font-normal" for="checkbox5" value={this.state.making_notes} onChange={(e)=>this.setState({making_notes:e.target.checked})}>
                                                            <input className="border-checkbox" type="checkbox" id="checkbox5" checked={this.state.making_notes=="1"?'checked':''}/>
                                                            &nbsp; Pop up notes when making appointment &nbsp; &nbsp; &nbsp;
                                                        </label>
                                                        <label className="border-checkbox-label font-normal" for="checkbox6" value={this.state.patient_arrives} onChange={(e)=>this.setState({patient_arrives:e.target.checked})}>
                                                            <input className="border-checkbox" type="checkbox" id="checkbox6" checked={this.state.patient_arrives=="1"?'checked':''}/>
                                                            &nbsp; Pop up notes when patient arrives
                                                        </label>
			                                        </div>
		                                        </div>			
	                                        </div>
                                        </div>
                                        <div className="form-group row m-b-0">
		                                    <div className="col-sm-12">
		                                        <div className="border-checkbox-section">
			                                        <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label font-normal" for="checkbox7" value={this.state.insurance_fund} onChange={(e)=>this.setState({insurance_fund:e.target.checked})}>
                                                            <input className="border-checkbox" type="checkbox" id="checkbox7" checked={this.state.insurance_fund=="1"?'checked':''}/>
                                                            &nbsp; Check the Health Insurance Fund expiry date &nbsp; &nbsp; &nbsp;
                                                        </label>
                                                    </div>
		                                        </div>			
	                                        </div>
                                        </div>
                                        <div className="form-group row m-b-0">
		                                    <div className="col-sm-12">
		                                        <div className="border-checkbox-section">
			                                        <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label left-class font-normal" for="checkbox9" value={this.state.creating_account} onChange={(e)=>this.setState({creating_account:e.target.checked})}>
                                                            <input className="border-checkbox" type="checkbox" id="checkbox9" checked={this.state.creating_account=="1"?'checked':''}/>
                                                            &nbsp; Pop up notes when creating account &nbsp; &nbsp; &nbsp; 
                                                        </label>
                                                        <label className="border-checkbox-label font-normal" for="checkbox10" value={this.state.legend_status} onChange={(e)=>this.setState({legend_status:e.target.checked})}>
                                                            <input className="border-checkbox" type="checkbox" id="checkbox10" checked={this.state.legend_status=="1"?'checked':''}/>
                                                            &nbsp; Show Invoiced and Paid status on legend 
                                                        </label>
			                                        </div>
		                                        </div>			
	                                        </div>
                                        </div>
                                        <div className="form-group row m-b-0">
                                            <div className="col-sm-12">
                                                <div className="border-checkbox-section">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label font-normal" for="checkbox11" value={this.state.color_appointments} onChange={(e)=>this.setState({color_appointments:e.target.checked})}>
                                                            <input className="border-checkbox" type="checkbox" id="checkbox11" checked={this.state.color_appointments=="1"?'checked':''}/>
                                                            &nbsp; Color booked appointments differently from unbooked
                                                        </label>
                                                    </div>
                                                </div>			
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-0">
		                                    <div className="col-sm-12">
		                                        <div className="border-checkbox-section">
			                                        <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label font-normal" for="checkbox12" value={this.state.open_patient_records} onChange={(e)=>this.setState({open_patient_records:e.target.checked})}>
                                                            <input className="border-checkbox" type="checkbox" id="checkbox12" checked={this.state.open_patient_records=="1"?'checked':''}/>
                                                            &nbsp; Allow users to open patient records from other user's appointments
                                                        </label>
			                                        </div>
		                                        </div>			
	                                        </div>
                                        </div>
                                        <div className="form-group row m-b-0">
		                                    <div className="col-sm-12">
		                                        <div className="border-checkbox-section">
			                                        <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label font-normal" for="checkbox13" value={this.state.booked_by} onChange={(e)=>this.setState({booked_by:e.target.checked})}>
                                                            <input className="border-checkbox" type="checkbox" id="checkbox13" checked={this.state.booked_by=="1"?'checked':''}/>
			                                                &nbsp; Allow users to change the 'Booked by' name when making appointments
                                                        </label>
			                                        </div>
		                                        </div>			
	                                        </div>
                                        </div>
                                        <div className="form-group row m-b-0">
		                                    <div className="col-sm-12">
		                                        <div className="border-checkbox-section">
			                                        <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label font-normal" for="checkbox14" value={this.state.cancelled_appointment} onChange={(e)=>this.setState({cancelled_appointment:e.target.checked})}> 
                                                            <input className="border-checkbox" type="checkbox" id="checkbox14" checked={this.state.cancelled_appointment=="1"?'checked':''}/>
			                                                &nbsp; Show Cancellation list when an appointment is cancelled
                                                        </label>
			                                        </div>
		                                        </div>			
	                                        </div>
                                        </div>
                                        <div className="form-group row m-b-0">
		                                    <div className="col-sm-12">
		                                        <div className="border-checkbox-section">
			                                        <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label font-normal" for="checkbox15" value={this.state.inactive_patients} onChange={(e)=>this.setState({inactive_patients:e.target.checked})}>
                                                            <input className="border-checkbox" type="checkbox" id="checkbox15"  checked={this.state.inactive_patients=="1"?'checked':''}/>
			                                                &nbsp; Include Inactive patitents when cancelling an appointment
                                                        </label>
			                                        </div>
		                                        </div>			
	                                        </div>
                                        </div>
                                        <div className="form-group row m-b-0">
		                                    <div className="col-sm-12">
		                                        <div className="border-checkbox-section">
			                                        <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label font-normal" for="checkbox16" value={this.state.reason_required} onChange={(e)=>this.setState({reason_required:e.target.checked})}>
                                                            <input className="border-checkbox" type="checkbox" id="checkbox16"  checked={this.state.reason_required=="1"?'checked':''}/>
			                                                 &nbsp; Require a reason when cancelling an appointment
                                                        </label>
			                                        </div>
		                                        </div>			
	                                        </div>
                                        </div>
                                        <div className="form-group row m-b-0">
                                            <div className="col-sm-12">
                                                <div className="border-checkbox-section">
                                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label font-normal" for="checkbox17" value={this.state.display_appointment_type} onChange={(e)=>this.setState({display_appointment_type:e.target.checked})}> 
                                                            <input className="border-checkbox" type="checkbox" id="checkbox17" checked={this.state.display_appointment_type=="1"?'checked':''}/>
                                                            &nbsp; Display appointment type icons in appointment book and  Waiting room
                                                        </label>
                                                    </div>
                                                </div>			
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-0">
		                                    <div className="col-sm-12">
		                                        <div className="border-checkbox-section">
			                                        <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label font-normal" for="checkbox18"  value={this.state.avoid_appointment_type} onChange={(e)=>this.setState({avoid_appointment_type:e.target.checked})}> 
                                                            <input className="border-checkbox" type="checkbox" id="checkbox18" checked={this.state.avoid_appointment_type=="1"?'checked':''}/>
                                                            &nbsp; Don't display appointment icon on standard appointment
                                                        </label>
			                                        </div>
		                                        </div>			
	                                        </div>
                                        </div>
                                        <div className="form-group row m-b-0">
		                                    <div className="col-sm-12">
		                                        <div className="border-checkbox-section">
			                                        <div className="border-checkbox-group border-checkbox-group-default">
                                                        <label className="border-checkbox-label font-normal" for="checkbox19"  value={this.state.attendance_status} onChange={(e)=>this.setState({attendance_status:e.target.checked})}>
                                                            <input className="border-checkbox" type="checkbox" id="checkbox19" checked={this.state.attendance_status=="1"?'checked':''}/>
			                                                &nbsp; Automatically show attendance status on appointment book
                                                        </label>
			                                        </div>
		                                        </div>			
	                                        </div>
                                        </div>
                                        <div className="form-group row m-b-0">
		                                    <div className="col-sm-12">
                                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default invoice-notes-btn" 
                                                data-toggle="modal" data-target="#appointment-book-layout-page" onClick={(e)=>this.setState({showAppointment:true})} style={{'margin': '10px'}}>Appointment Book Layout</button>
		                                    </div>
                                        </div>
                                        <label className="col-sm-12 col-form-label text-left" style={{lineHeight:'.5',margin:'10px 0 20px 10px', fontSize:'16px', fontWeight:'bold'}}>Waiting room</label>
                                        <div className="form-group row m-b-0">
		                                    <div className="col-sm-12">
		                                    <div className="border-checkbox-section">
                                                <div className="border-checkbox-group border-checkbox-group-default">
                                                    <label className="border-checkbox-label font-normal" for="checkbox20" value={this.state.show_uncompleted} onChange={(e)=>this.setState({show_uncompleted:e.target.checked})}>
                                                        <input className="border-checkbox" type="checkbox" id="checkbox20" checked={this.state.show_uncompleted=="1"?'checked':''}/>
                                                        &nbsp; Show uncompleted appointments in Waiting room when opened
                                                    </label>
                                                </div>
		                                    </div>			
	                                    </div>
                                    </div>
                                    <div className="form-group row m-b-0">
		                                <div className="col-sm-12">
		                                    <div className="border-checkbox-section">
			                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                    <label className="border-checkbox-label font-normal" for="checkbox22" value={this.state.add_to_waiting_room} onChange={(e)=>this.setState({add_to_waiting_room:e.target.checked})}>
                                                        <input className="border-checkbox" type="checkbox" id="checkbox22" checked={this.state.add_to_waiting_room=="1"?'checked':''}/>
			                                            &nbsp; Allow patients to be added directly to Waiting room
                                                    </label>
			                                    </div>
		                                    </div>			
	                                    </div>
                                    </div>
                                    <div className="form-group row m-b-0">
		                                <div className="col-sm-12">
		                                    <div className="border-checkbox-section">
			                                    <div className="border-checkbox-group border-checkbox-group-default">
                                                    <label className="border-checkbox- font-normal" for="checkbox21" value={this.state.add_to_doctor_waiting_room} onChange={(e)=>this.setState({add_to_doctor_waiting_room:e.target.checked})}> 
                                                        <input className="border-checkbox" type="checkbox" id="checkbox21" checked={this.state.add_to_doctor_waiting_room=="1"?'checked':''}/>
                                                        &nbsp; Allow paitents to be added to Waiting room for 'Any doctor'
                                                    </label>
			                                    </div>
		                                    </div>			
	                                    </div>
                                    </div>
                                </div>	
					        </div>
				        </div>
			            <div className="modal-footer modal-footer-bottom-margin">
                            <div className="row">
                           <div className="col-sm-10"> </div>
                                <div className="col-sm-2">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>this.saveSetting()}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.showAppointment ?
            <AppointmentLayout showModal={this.state.showAppointment} popFor='Add' reloadKey={this.reloadPage}/> :''}
                                                       
        </div>
      </section>
      <Footer />
    </div> 
    )
}

}

const mapDispatchToProps = dispatch => {
    return {
        apigetsettings:(data)=>dispatch(api_get_settings(data)),
        apithirdtablesettings:(data)=>dispatch(api_third_table_setting(data)),
        apilookupsettings:(data)=>dispatch(api_default_lookup_setting(data)),
        apisavesettings:data=>dispatch(api_save_setting(data))
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  
  
  export default connect(mapStateToProps,mapDispatchToProps)(AppointmentSetting);

