import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import AnotherValues from './anotherValues';
import {api_get_diabetic,api_add_reminder} from '../../../DS/DS.EPC';
import { connect } from 'react-redux';
import SelectDate from './selectDate';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class creatediabetes extends Component {
    constructor(props)
    {
      super(props);
      this.state={
        addValues:false,
        key:0,
        dibedata:[],
        setDate:false
        
      }
    }
    componentWillMount=()=>
    {
      this.props.getDiabetic({pat_id:this.props.redux_patientId}).then(res=>
        {
            if(res.data.content!='')
            {
              this.setState({dibedata:res.data.content})
            }
        })
    }
    closeAll=()=>
    {
      this.setState({addValues:false,key:Math.random(),setDate:false})
    }
    useSave=(id)=>
    {
      this.setState({nextDate:new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric'}).format(id)});
      this.closeAll();
    }
    handleDate=date=>
    {
      this.setState({remdate:date})
    }
    sendReminder=(e)=>
    {
      const val={
        title:'Diabetic Care',
        content:'Diabetic Care',
        doc_id:this.props.redux_docId,
        patid:this.props.redux_patientId,
        reminder_date:this.state.remdate,
      }
      this.props.addReminder(val).then(res=>
        {

        }) 
    }
    render() {
        return (
            <Popup  trigger={<button id="Diabetesopen" data-toggle="modal" data-target="" type="button" className="btn btn-primary btn-xs">Create</button>} position="right center"   modal
            closeOnDocumentClick={false}>
             {close => (
              <div style={{ width:'1000px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}> 
                  <div className="modal-header" style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}>
                    <button type="button" className="close" onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                    </button>
                    <h5 class="col-sm-12 sheadtitle" >Diabetes Care</h5>
                  </div>
                  <div className="modal-body">
                    <div className="col-sm-12 mt-3" style={{marginBottom: '10px', textAlign: 'left'}}>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="col-sm-2">
                            <label className="col-form-label text-left">Every 6 Months:</label>
                          </div>
                          <div className="col-sm-10">
                            <div style={{float: 'left', width: '100%', overflow: 'auto'}}>
                              <table style={{width: '100%'}}>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div style={{float: 'left', width: '100%', height: 'auto'}}>
                                        <table className=" table-bordered tablestyle" style={{width: '100%'}}>
                                          <thead>
                                            <tr style={{backgroundColor: '#f4f4f4'}}>
                                              <th style={{textAlign: 'left'}}> Date</th>
                                              <th>BP</th>
                                              <th>Weight</th>
                                              <th>Height</th>
                                              <th>BMI</th>
                                              <th>Waist</th>
                                              <th>BSL</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.dibedata.length > 0 && this.state.dibedata.map((item, i) => (
                                            <tr>
                                              <td style={{height: '50px'}}>{item.observation_date}</td>
                                              <td  style={{height: '50px'}}>{item.bp}</td>
                                              <td  style={{height: '50px'}}>{item.weight}</td>
                                              <td  style={{height: '50px'}}>{item.height}</td>
                                              <td  style={{height: '50px'}}></td>
                                              <td  style={{height: '50px'}}>{item.waist}</td>
                                              <td  style={{height: '50px'}}>{item.bsl}</td>
                                            </tr>  
                                          ))}      
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="col-sm-2">
                            <label className="col-form-label text-left">Foot examination:</label>
                          </div>
                          <div className="col-sm-10">
                          <div style={{float: 'left', width: '100%', overflow: 'auto'}}>
                            <table style={{width: '100%'}}>
                              <tbody><tr>
                                  <td>
                                    <div style={{float: 'left', width: '100%', height: 'auto'}}>
                                      <table className=" table-bordered tablestyle" style={{width: '100%'}}>
                                        <thead>
                                          <tr style={{backgroundColor: '#f4f4f4'}}>
                                            <th style={{textAlign: 'left'}}> Date</th>
                                            <th>Defomity(R)</th>
                                            <th>UIcers(R)</th>
                                            <th>Neuropathy(R)</th>
                                            <th>Pulses(R)</th>
                                            <th>Defomity(L)</th>
                                            <th>UIcers(L)</th>
                                            <th>Neuropathy(L)</th>
                                            <th>Pulses(L)</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.dibedata.length>0 && this.state.dibedata.map((item, i) => (
                                          <tr>
                                            <td  style={{height: '50px'}}>{item.foot_examination}</td>
                                            <td  style={{height: '50px'}}>{item.right_deform==1 ? 'Yes' : 'No'}</td>
                                            <td  style={{height: '50px'}}>{item.right_ulcer==1 ? 'Yes' : 'No'}</td>
                                            <td  style={{height: '50px'}}>{item.right_neuro==1 ? 'Yes' : 'No'}</td>
                                            <td  style={{height: '50px'}}>{item.right_pulse==1 ? 'Yes' : 'No'}</td>
                                            <td  style={{height: '50px'}}>{item.left_deform==1 ? 'Yes' : 'No'}</td>
                                            <td  style={{height: '50px'}}>{item.left_ulcer==1 ? 'Yes' : 'No'}</td>
                                            <td  style={{height: '50px'}}>{item.left_neuro==1 ? 'Yes' : 'No'}</td>
                                            <td  style={{height: '50px'}}>{item.left_pulse==1 ? 'Yes' : 'No'}</td>
                                          </tr>  
                                        ))}      
                                        </tbody>
                                      </table> 
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="col-sm-2">
                          <label className="col-form-label text-left">Every 12-24<br />month<br />Fundus examination</label>
                        </div>
                        <div className="col-sm-10">
                          <div style={{float: 'left', width: '100%', overflow: 'auto'}}>
                            <table style={{width: '100%'}}>
                              <tbody><tr>
                                  <td>
                                    <div style={{float: 'left', width: '100%', height: 'auto'}}>
                                      <table className=" table-bordered tablestyle" style={{width: '100%'}}>
                                        <thead>
                                          <tr style={{backgroundColor: '#f4f4f4'}}>
                                            <th style={{textAlign: 'left'}}> Date</th>
                                            <th>Right</th>
                                            <th>Left</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.dibedata.length>0 && this.state.dibedata.map((item, i) => (
                                          <tr>
                                            <td  style={{height: '50px'}}>{item.fundus_examination}</td>
                                            <td  style={{height: '50px'}}>{item.fundus_right}</td>
                                            <td  style={{height: '50px'}}>{item.fundus_left}</td>
                                          </tr>    
                                        ))}    
                                        </tbody>
                                      </table> </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="col-sm-2">
                          <label className="col-form-label text-left">Investigation every 12-24 months :</label>
                        </div>
                        <div className="col-sm-10">
                          <div style={{float: 'left', width: '100%', overflow: 'auto'}}>
                            <table style={{width: '100%'}}>
                              <tbody>
                                <tr>
                                  <td>
                                    <div style={{float: 'left', width: '100%', height: 'auto'}}>
                                      <table className=" table-bordered tablestyle" style={{width: '100%'}}>
                                        <thead>
                                          <tr style={{backgroundColor: '#f4f4f4'}}>
                                            <th style={{textAlign: 'left'}}> Date</th>
                                            <th>HbA1C</th>
                                            <th>Cholesterol</th>
                                            <th>HDL</th>
                                            <th>LDL</th>
                                            <th>Triglycerides</th>
                                            <th>Creatinine</th>
                                            <th>eGFR</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.dibedata.length>0 && this.state.dibedata.map((item, i) => (
                                          <tr>
                                            <td style={{height: '50px'}}>{item.observation_date}</td>
                                            <td style={{height: '50px'}}>{item.hb_val}</td>
                                            <td style={{height: '50px'}}>{item.total_chole_val}</td>
                                            <td style={{height: '50px'}}>{item.hdl_val}</td>
                                            <td style={{height: '50px'}}>{item.ldl_val}</td>
                                            <td style={{height: '50px'}}>{item.trigly_val}</td>
                                            <td style={{height: '50px'}}>{item.creat_val}</td>
                                            <td style={{height: '50px'}}>{item.egfr_val}</td>
                                          </tr> 
                                        ))}       
                                        </tbody>
                                      </table> 
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="col-sm-2">
                          <label className="col-form-label text-left">Last visit to :</label>
                        </div>
                        <div className="col-sm-10">
                          <div className="col-sm-12" style={{paddingLeft: '0px'}}>
                            <div className="col-sm-2" style={{paddingLeft: '0px'}}>
                              <label className="col-form-label text-left">Endocrinologist</label>
                            </div>
                            <div className="col-sm-2">
                              <input type="text" name="search_condition" className="form-control form-control-sm rq-form-elemen" 
                              value={this.state.dibedata.length > 0 ? this.state.dibedata[0].endocri_doc :''}/>
                            </div>
                            <div className="col-sm-2">
                              <input type="text" name="search_condition" className="form-control form-control-sm rq-form-elemen" 
                              value={this.state.dibedata.length > 0 ? this.state.dibedata[0].endocri_date:''}/>
                            </div>
                            <div className="col-sm-2">
                              <label className="col-form-label text-left">Dietitian</label>
                            </div>
                            <div className="col-sm-2">
                              <input type="text" name="search_condition" className="form-control form-control-sm rq-form-elemen" 
                              value={this.state.dibedata.length > 0 ? this.state.dibedata[0].dietitian_doc :''}/>
                            </div>
                            <div className="col-sm-2">
                              <input type="text"  name="search_condition" className="form-control form-control-sm rq-form-elemen" 
                              value={this.state.dibedata.length > 0 ? this.state.dibedata[0].dietitian_date :''}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="col-sm-2">&nbsp;</div>
                        <div className="col-sm-10">
                          <div className="col-sm-12" style={{paddingLeft: '0px'}}>
                            <div className="col-sm-2" style={{paddingLeft: '0px'}}>
                              <label className="col-form-label text-left">Ophthalmologist</label>
                            </div>
                            <div className="col-sm-2">
                              <input type="text" name="search_condition" className="form-control form-control-sm rq-form-elemen" 
                               value={this.state.dibedata.length > 0 ? this.state.dibedata[0].ophtha_doc :''}/>
                            </div>
                            <div className="col-sm-2">
                              <input type="text" name="search_condition" className="form-control form-control-sm rq-form-elemen" 
                               value={this.state.dibedata.length > 0 ? this.state.dibedata[0].ophtha_date:''}/>
                            </div>
                            <div className="col-sm-2">
                              <label className="col-form-label text-left">Podiatrist</label>
                            </div>
                            <div className="col-sm-2">
                              <input type="text"  name="search_condition" className="form-control form-control-sm rq-form-elemen"
                              value={this.state.dibedata.length > 0 ? this.state.dibedata[0].pedia_doc:''} />
                            </div>
                            <div className="col-sm-2">
                              <input type="text" name="search_condition" className="form-control form-control-sm rq-form-elemen" 
                              value={this.state.dibedata.length > 0 ? this.state.dibedata[0].pedia_date:''}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="col-sm-2">&nbsp;</div>
                        <div className="col-sm-10">
                          <div className="col-sm-12" style={{paddingLeft: '0px'}}>
                            <div className="col-sm-2" style={{paddingLeft: '0px', paddingRight: '0px'}}>
                              <label className="col-form-label text-left">Diabetes Educator</label>
                            </div>
                            <div className="col-sm-2">
                              <input type="text"  name="search_condition" className="form-control form-control-sm rq-form-elemen" 
                              value={this.state.dibedata.length > 0 ? this.state.dibedata[0].diabe_doc :''}/>
                            </div>
                            <div className="col-sm-2">
                              <input type="text" name="search_condition" className="form-control form-control-sm rq-form-elemen" 
                              value={this.state.dibedata.length > 0 ? this.state.dibedata[0].diabe_date:''}/>
                            </div>
                            <div className="col-sm-2">
                              <label className="col-form-label text-left">Podiatrist </label>
                            </div>
                            <div className="col-sm-2">
                              <input type="text"  name="search_condition" className="form-control form-control-sm rq-form-elemen" 
                               value={this.state.dibedata.length > 0 ? this.state.dibedata[0].pedi_date:''}/>
                            </div>
                            <div className="col-sm-2">
                              <input type="text"  name="search_condition" className="form-control form-control-sm rq-form-elemen" 
                               value={this.state.dibedata.length > 0 ? this.state.dibedata[0].pedi_date :''}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="col-sm-4 pr-0"><label className="col-form-label text-left">Date that the last cycle of care was completed</label></div>
                        <div className="col-sm-1 pl-0 pr-0">
                          <input type="text"  name="search_condition" className="form-control form-control-sm rq-form-elemen mt-0"
                          value={this.state.nextDate} />
                        </div>
                        <div className="col-sm-1 mt-1">
                          <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={()=>this.setState({setDate:true})}>Set</button>
                        </div>
                        <div className="col-sm-1 cus-wid pr-0">
                          <label className="col-form-label text-left">Next review date:</label>
                        </div>
                        <div className="col-sm-1 pr-0 pl-0">
                                  <DatePicker
                                    className="datePicker"
                                    onChange={this.handleDate}
                                    dateFormat="dd/MM/yyyy"
                                    id="clinicalcalendar" 
                                    selected={
                                          this.state.dateShow
                                          ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                           : Date.parse(this.state.remdate)
                                        }
                                    />  
                        </div>
                        <div className="col-sm-2" style={{paddingLeft: '0px'}}>
                        <label>  
                          <input type="checkbox" className="" style={{float: 'left', width: 'auto', marginLeft:'5px', marginRight:'5px'}}  
                          onChange={(e)=>this.sendReminder(e)}/>Send Reminder</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer"  style={{marginTop:'0px'}}>
                  <button type="button" className="btn btn-primary btn-xs">Print and Close</button>
                  <button type="button" className="btn btn-primary btn-xs">Complete this cycle</button>
                  <button type="button" className="btn btn-primary btn-xs" onClick={()=>this.setState({addValues:true})}>Add new values</button>
                  <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal" aria-label="Close">Close</button>
                </div>
                {this.state.addValues ?
                <AnotherValues showmodal={this.state.addValues} closeAll={this.closeAll}/> :''} 
                {this.state.setDate ?
                <SelectDate showmodal={this.state.setDate} closeAll={this.closeAll} saveData={this.useSave}/> :''}
              </div>
            )}
        </Popup>
      )}
  }
const mapDispatchToProps = (dispatch) => {
	return {
        getDiabetic: data => dispatch(api_get_diabetic(data)),
        addReminder: data => dispatch(api_add_reminder(data))
  }
}
const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
  
  return {
    redux_patientId,redux_docId
  };
  };
export default connect (mapStateToProps, mapDispatchToProps) (creatediabetes);

