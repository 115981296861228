import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import {search_all} from './../DS/DS.Users';
import { Row } from "react-bootstrap";
class SearchAll extends Component {
  constructor(props)
  {
      super(props)
      this.state={
        showsearch:this.props.showbox,
        enablepat:false,
        enabledoc:false,
        enableall:false,
        totpat:0,
        totdoc:0,
        totall:0,
        patientdata:[],
        doctordata:[],
        patstate:'tab-pane',
        docstate:'tab-pane'
      }     
  }
  componentWillMount=()=>
  {
    this.setState({searchedVal:this.props.searchfor})
    const searched={searchfor:this.props.searchfor}
    this.props.searchall(searched).then(
      req=>{
            if(req.data.status=='success')
            {
                if(req.data.content[0].itsFor=='patient')
                {
                    this.setState({patientdata:req.data.content,doctordata:[]})
                    this.setState({enablepat:true,enabledoc:false,enableall:false,totpat:req.data.content[0].total,
                        patstate:'tab-pane active',docstate:'tab-pane'});
                    console.log(this.state.patientdata)
                }
                else if(req.data.content[0].itsFor=='doctor')
                {
                   
                    this.setState({doctordata:req.data.content,patientdata:[]})
                    this.setState({enablepat:false,enabledoc:true,enableall:false,totdoc:req.data.content[0].total,
                        patstate:'tab-pane',docstate:'tab-pane active' });
                    console.log(this.state.doctordata)
                }
                else if(req.data.content[0].itsFor=='0')
                {
                    console.log(">>>>",req.data.contentdoc[0].totaldoc)
                   
                    this.setState({doctordata:req.data.contentdoc,patientdata:req.data.content})
                    this.setState({enablepat:true,enabledoc:true,enableall:false,totdoc:req.data.contentdoc[0].totaldoc,
                        patstate:'tab-pane active',docstate:'tab-pane',totpat:req.data.content[0].totalpat });
                }
            }
            else
            {
                NotificationManager.error('No match found for the search')
            }
        }
    )
  }
 togglestate=(val)=>
 {
    if(val=='doctor')
    {
            this.setState({patstate:'tab-pane',docstate:'tab-pane active'})

    }
    if(val=='patient')
    {
        this.setState({patstate:'tab-pane active',docstate:'tab-pane'})
    }
 }
  render() {
    return (
      <div id="addAppointments">
        {this.state.showsearch ?
          <div className="col-md-12 p-1  pl-1">
            {/* <NotificationContainer /> */}
                <div id="collapseSix112" className="panel-collapse" style={{paddingTop:'0px', width:'100%'}}>
                    <div id="exTab2">
                        <ul className="nav nav-tabs">
                            {this.state.enablepat ? 
                            <li className="active" aria-expanded="false" onClick={()=>this.togglestate('patient')}><a href="#2" data-toggle="tab" aria-expanded="true" className="active">Patient <small>{this.state.totpat}</small></a>
                            </li>:''}
                            {this.state.enabledoc ? 
                           
                            <li className="" aria-expanded="false" onClick={()=>this.togglestate('doctor')}><a href="#3" data-toggle="tab" aria-expanded="false" className="">Doctor <small>{this.state.totdoc}</small></a>
                            </li>:''}
                           {/*} <li className=""><a href="#4" data-toggle="tab" aria-expanded="false" className="">Account Holders <small>05</small></a>
                            </li>*/}
                        </ul>
                        <div className="tab-content">
                                {/**PATIENT  */}
                                <div className={this.state.patstate} id="2" aria-expanded="true">
                                <div className="row">
                                    <div className="col-lg-12">	
		                                <div style={{background:'#ffffff', border:'1px #dddddd solid', borderTop:'1px #dddddd solid'}}>
                                            <table style={{width:'100%'}}>
						                        <tbody>
						                            <tr style={{background:'#dddddd'}}>
                                                        <td style={{padding: '5px 0px'}}>&nbsp;PATIENT</td>
                                                        <td style={{padding: '5px 0px'}}>&nbsp;Address</td>
                                                        <td style={{padding: '5px 0px'}}>&nbsp;D.O.B.</td>
                                                        <td style={{padding: '5px 0px'}}>&nbsp;Medicare</td>
                                                        <td style={{padding: '5px 0px'}}>&nbsp;Age</td>
                                                        <td style={{padding: '5px 0px'}}>&nbsp;Phone</td>
						                            </tr>

                                                    {this.state.patientdata != undefined && this.state.patientdata.length> 0 && this.state.patientdata.map((item) =>
                                                     <tr style={{borderBottom:'1px #dddddd solid', color:'#000000'}}>
                                                        <td style={{padding: '5px 0px'}}>
                                                          <a href="#" style={{cursor:'pointer', color:'#000000'}}>{item.Firstname} {item.Surname}</a>
                                                        </td> 
                                                    <td style={{padding: '5px 0px'}}><div className=""><a href="#" style={{cursor:'pointer', color:'#000000'}}>{item.Address1} , {item.Address2}</a></div></td>
                                                    <td style={{padding: '5px 0px'}}><div className=""><a href="#" style={{cursor:'pointer', color:'#000000'}}>{item.DOB}</a></div></td>
                                                    <td style={{padding: '5px 0px'}}><div className=""><a href="#" style={{cursor:'pointer', color:'#000000'}}>{item.medicare_no}</a></div></td>
                                                    <td style={{padding: '5px 0px'}}><div className=""><a href="#" style={{cursor:'pointer', color:'#000000'}}>{item.age}</a></div></td>
                                                    <td style={{padding: '5px 0px'}}><div className=""><a href="#" style={{cursor:'pointer', color:'#000000'}}>{item.phone}</a></div></td>
						                         
                                                     
                                                     </tr> 
                                                    )}
 
						                         </tbody>
						                    </table>
		                                </div>
                                    </div>
                                </div>
                                <div style={{width: '100%', float: 'left', textAlign: 'right'}} id="bookon">
						            <div style={{width:'100%', minWidth:'50px', margin: '10px auto 20px'}}>
							            <div className="app-design text-right">
								            <div style={{float:'right'}}>
                                                <a href="#" className="btn btn-primary" style={{marginBottom:'5px', padding: '5px 10px', width:'180px'}} data-toggle="modal" data-dismiss="modal" aria-hidden="true" data-target="#"><div className="lbottom">Appointment Calendar</div></a>
                                                <a href="#" className="btn btn-primary" style={{marginBottom:'5px', padding: '5px 10px', width:'110px'}} data-toggle="modal" data-dismiss="modal" aria-hidden="true" data-target="#"><div className="editapp">View Details</div></a>
                                                <a href="#" className="btn btn-primary" style={{marginBottom:'5px', padding: '5px 1px', width:'110px'}}><div className="newapp">New Patient</div></a>
                                                <a href="#" className="btn btn-primary" style={{marginBottom:'5px', padding: '5px 10px', width:'110px'}} data-toggle="modal" data-dismiss="modal" aria-hidden="true" data-target="#">Add to Family</a>
                                                <a href="#" className="btn btn-primary" style={{marginBottom:'5px', marginRight:'10px', padding: '5px 10px', width:'110px'}}>Billing History</a></div>
							                </div>
						                </div>
					                </div>
                                </div>
                                {/**DCOTOR  */}
                                <div className={this.state.docstate} id="3" aria-expanded="true">
                                    <div className="row">
                                        <div className="col-lg-12">	
		                                    <div style={{background:'#ffffff', border:'1px #dddddd solid', borderTop:'1px #dddddd solid'}}>
                                                <table style={{width:'100%'}}>
						                            <tbody>
                                                    <tr style={{background:'#dddddd'}}>
                                                        <td style={{padding: '5px 0px'}}>&nbsp;DOCTOR</td>
                                                        <td style={{padding: '5px 0px'}}>&nbsp;Address</td>
                                                        <td style={{padding: '5px 0px'}}>&nbsp;D.O.B.</td>
                                                        <td style={{padding: '5px 0px'}}>&nbsp;Age</td>
                                                        <td style={{padding: '5px 0px'}}>&nbsp;Phone</td>
                                                    </tr>
                                                    {this.state.doctordata != undefined && this.state.doctordata.length> 0 && this.state.doctordata.map((item) =>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;{item.Firstname} {item.Surname}</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;{item.Address1} , { item.Address2}</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;{item.DOB}</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;{item.age}</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;{item.phone  }</td>
                                                        </tr>
                                                    )}
                                                    
                                                </tbody>
						                    </table>
		                                </div>
                                        <div style={{width: '100%', float: 'left', textAlign: 'right'}} id="bookon">
						                    <div style={{width:'100%', minWidth:'50px', margin: '10px auto 20px'}}>
							                    <div className="app-design text-right">
							    	                <div style={{float:'right'}}>
                                                        <a href="#" className="btn btn-primary" style={{marginBottom:'5px', padding: '5px 10px', width:'110px'}} data-toggle="modal" data-dismiss="modal" aria-hidden="true" data-target="#myModalEditPatient1">Add new</a>
								                        <a href="#" className="btn btn-primary" style={{marginBottom:'5px', padding: '5px 1px', width:'110px'}}>
                                                            <div className="newapp">Add at address</div>
                                                        </a>
								                        <a href="#" className="btn btn-primary" style={{marginBottom:'5px', padding: '5px 10px', width:'110px'}} data-toggle="modal" data-dismiss="modal" aria-hidden="true" data-target="#myModalfamilyPatient">Edit</a>
                                                    </div>
							                    </div>
						                    </div>
					                    </div>
                                    </div>
                                </div>
                            </div>
                                <div className="tab-pane" id="4" aria-expanded="true">
                                    <div className="row">
                                        <div className="col-lg-12">	
                                            <div style={{background:'#ffffff', border:'1px #dddddd solid', borderTop:'1px #dddddd solid'}}>
                                                <table style={{width:'100%'}}>
                                                    <tbody>
                                                        <tr style={{background:'#dddddd'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Name</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Category</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Address</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;D.O.B.</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Age</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Phone</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;76 Goodwood Way Canning Vale - 6155</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;12/11/1998</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;20 yrs</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;0404 463 595</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;76 Goodwood Way Canning Vale - 6155</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;12/11/1998</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;20 yrs</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;0404 463 595</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;76 Goodwood Way Canning Vale - 6155</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;12/11/1998</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;20 yrs</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;0404 463 595</td>
                                                        </tr><tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;76 Goodwood Way Canning Vale - 6155</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;12/11/1998</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;20 yrs</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;0404 463 595</td>
                                                        </tr><tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;76 Goodwood Way Canning Vale - 6155</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;12/11/1998</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;20 yrs</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;0404 463 595</td>
                                                        </tr>
                            
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="5" aria-expanded="true" style={{display:'none'}}>    
                                    <div className="row">
                                        <div className="col-lg-12">	
                                            <div style={{background:'#ffffff', border:'1px #dddddd solid', borderTop:'1px #dddddd solid'}}>
                                                <table style={{width:'100%'}}>
                                                    <tbody>
                                                        <tr style={{background:'#dddddd'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Contact Name</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Category</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;organisation</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Comment</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Individual</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Speaks Malay</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Companies/institutions</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;c/.Jaman McKenna</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Individual</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Speaks Malay</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Companies/institutions</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;c/.Jaman McKenna</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Individual</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Speaks Malay</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Companies/institutions</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;c/.Jaman McKenna</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Individual</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Speaks Malay</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Companies/institutions</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;c/.Jaman McKenna</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Individual</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Speaks Malay</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Companies/institutions</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;c/.Jaman McKenna</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Individual</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Speaks Malay</td>
                                                        </tr>
                                                        <tr  style={{borderBottom:'1px #dddddd solid'}}>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Murugan</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Oncology</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;Companies/institutions</td>
                                                            <td style={{padding: '5px 0px'}}>&nbsp;c/.Jaman McKenna</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="6" aria-expanded="true" style={{display:'none'}}>
                                <div className="row">
                                    <div className="col-lg-12">	
		                                <div className="ibox float-e-margins">
                                            <div className="ibox-content">
                                                <div className="row">
                                                    <div className="col-lg-12">	
                                                        <div id="searchmain" className="table-responsive" style={{background:'#ffffff', border:'1px #dddddd solid', borderTop:'1px #dddddd solid'}}>
                                                            <table className="table table-sm table-hover">
                                                                <thead className="thead-default">
                                                                    <tr className="add-category-row">
                                                                        <th>
                                                                            <div className="tags" style={{marginTop:'10px'}}>
                                                                                <a href="#">Blood</a> <a href="#">Olympic Medical Centre</a>
                                                                                <a href="#">Vishnu Gopalan</a> <a href="#">Murugan</a> <a href="#">Blood Test</a> <a href="#">Blood Report</a>
                                                                                <a href="#">hypertension</a> <a href="#">Blood pressure chart</a> <a href="#">Doctors</a> <a href="#">Heart Foundation</a>
                                                                                <a href="#">diastolic</a> <a href="#">Blood Pressure</a>
                                                                            </div>
                                                                        </th>
                                                                        <th style={{width: '70px'}}></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className="category-row">
                                                                        <th style={{textAlign: 'center'}}>1.Blood Pressure</th>
                                                                        <th style={{textAlign: 'right'}}></th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td >Blood Pressure Chart &amp; Numbers (Normal Range, Systolic, Diastolic)</td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-view-fund btn btn-default btn-xs" type="button" >
                                                                                <span className="glyphicon glyphicon-list" aria-hidden="true"></span>
                                                                            </button> 
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td >Understanding Blood Pressure Readings | Olympic Medical Centre</td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-view-fund btn btn-default btn-xs" type="button" >
                                                                                <span className="glyphicon glyphicon-list" aria-hidden="true"></span>
                                                                            </button> 
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td >What is normal blood pressure | The Heart Foundation</td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-view-fund btn btn-default btn-xs" type="button" >
                                                                                <span className="glyphicon glyphicon-list green-bg" aria-hidden="true"></span>
                                                                            </button> 
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>High blood pressure (hypertension) - Doctors and departments</td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-view-fund btn btn-default btn-xs" type="button" >
                                                                                <span className="glyphicon glyphicon-list" aria-hidden="true"></span>
                                                                            </button> 
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="category-row">                      
                                                                        <th style={{textAlign: 'center'}}>2.Hypertension</th>
                                                                        <th style={{textAlign: 'right'}}></th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td >Blood Pressure Chart &amp; Numbers (Normal Range, Systolic, Diastolic)</td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-view-fund btn btn-default btn-xs" type="button" >
                                                                                <span className="glyphicon glyphicon-list" aria-hidden="true"></span>
                                                                            </button> 
                                                                        </td>
                                                                    </tr> 
                                                                    <tr>
                                                                        <td >Understanding Blood Pressure Readings | Olympic Medical Centre</td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-view-fund btn btn-default btn-xs" type="button" >
                                                                                <span className="glyphicon glyphicon-list" aria-hidden="true"></span>
                                                                            </button>  
                                                                        </td>
                                                                    </tr>                  
                                                                    <tr>
                                                                        <td >Blood Pressure : Blood pressure chart</td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-view-fund btn btn-default btn-xs" type="button" >
                                                                                <span className="glyphicon glyphicon-list" aria-hidden="true"></span>
                                                                            </button> 
                                                                        </td>
                                                                    </tr>                  
                                                                    <tr>
                                                                        <td >What is normal blood pressure | The Heart Foundation</td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-view-fund btn btn-default btn-xs" type="button" >
                                                                                <span className="glyphicon glyphicon-list" aria-hidden="true"></span>
                                                                            </button> 
                                                                        </td>
                                                                    </tr>                    
                                                                    <tr>
                                                                        <td >High blood pressure (hypertension) - Doctors and departments</td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-view-fund btn btn-default btn-xs" type="button" >
                                                                                <span className="glyphicon glyphicon-list" aria-hidden="true"></span>
                                                                            </button> 
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="category-row">                      
                                                                        <th style={{textAlign: 'center'}}>3.Blood Test Reports</th>
                                                                        <th style={{textAlign: 'right'}}></th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td >Blood Pressure Chart &amp; Numbers (Normal Range, Systolic, Diastolic)</td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-view-fund btn btn-default btn-xs" type="button" >
                                                                                <span className="glyphicon glyphicon-list" aria-hidden="true"></span>
                                                                            </button> 
                                                                        </td>
                                                                    </tr> 
                                                                    <tr>
                                                                        <td >Understanding Blood Pressure Readings | Olympic Medical Centre</td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-view-fund btn btn-default btn-xs" type="button" >
                                                                                <span className="glyphicon glyphicon-list" aria-hidden="true"></span>
                                                                            </button>  
                                                                        </td>
                                                                    </tr>                  
                                                                    <tr>
                                                                        <td >Blood Pressure : Blood pressure chart</td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-view-fund btn btn-default btn-xs" type="button" >
                                                                                <span className="glyphicon glyphicon-list" aria-hidden="true"></span>
                                                                            </button> 
                                                                        </td>
                                                                    </tr>                  
                                                                    <tr>
                                                                        <td >What is normal blood pressure | The Heart Foundation</td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-view-fund btn btn-default btn-xs" type="button" >
                                                                                <span className="glyphicon glyphicon-list" aria-hidden="true"></span>
                                                                            </button> 
                                                                        </td>
                                                                    </tr>                    
                                                                    <tr>
                                                                        <td >High blood pressure (hypertension) - Doctors and departments</td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-view-fund btn btn-default btn-xs" type="button" >
                                                                                <span className="glyphicon glyphicon-list" aria-hidden="true"></span>
                                                                            </button> 
                                                                        </td>
                                                                    </tr>					
                                                                </tbody>
                                                            </table>
				                                            <div className="text-center">
                                                                    <div className="btn-group">
                                                                        <button className="btn btn-white" type="button"><i className="glyphicon glyphicon-chevron-left"></i></button>
                                                                        <button className="btn btn-white">1</button>
                                                                        <button className="btn btn-white  active">2</button>
                                                                        <button className="btn btn-white">3</button>
                                                                        <button className="btn btn-white">4</button>
                                                                        <button className="btn btn-white">5</button>
                                                                        <button className="btn btn-white">6</button>
                                                                        <button className="btn btn-white">7</button>
                                                                        <button className="btn btn-white" type="button"><i className="glyphicon glyphicon-chevron-right"></i> </button>
                                                                    </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            :''}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
    return {
      searchall: data => dispatch(search_all(data)),
     }
    }
export default connect(null, mapDispatchToProps)(SearchAll);
//export default SearchAll;
