import React, { Component } from "react";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import moment from "moment";
//import "./myList.css";
//import CommonGrid from "../../crudcomponentV2/combinedcrud";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

import { confirmAlert } from "react-confirm-alert";
import Showhelp from '../../showHelp';
import SelectPatients from './selectPatients'
import {api_get_reminders_top,api_delete_reminder,api_get_appointments} from '../../../DS/DS.Reminders'
import { isFixedItems } from "react-jsonschema-form/lib/utils";
 class ViewAppointment extends Component {
  constructor(props) {
    super(props);
  this.state = {
    open:this.props.open,
    key:0,
    appointments:[]
  
     }
 
}

componentWillMount=()=>{
this.props.getAppoinment({id:this.props.id}).then(res=>
    {
        if(res.data.content!='')
        {
            this.setState({appointments:res.data.content})
        }
    })
}
    render(){
        return(
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div style={{ width:'550px', marginLeft:'320px', backgroundColor:'#FFF', float:'left'}}>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                        <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px', fontSize:'16px',marginBottom:'0px'}}>Patients appointments <Showhelp gotohelp="view_appointments_help"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.close1()} style={{color:'black'}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ fontSize: "14px" }}>
                        <div className="col-md-12">
                            <div className="col-md-12">
                                <label>The following appointments have been booked for {this.props.patient} </label>
                            </div>
                           </div>

                        {/** reminder listing */}
                        
                         <div id="referralstlist" className="panel-collapse collapse" style={{float:'left', display:'block',  marginTop:'20px', width:'100%'}}>
			                <div className="panel panel-default" style={{float: 'left', width: '100%'}}>
                               {/*} <div className="panel-heading" style={{float: 'left', padding:'0px 15px', width: '100%'}}>
                                    <h4 className="panel-title" style={{float:'left', display:'inline', marginRight:'10px'}}>
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" style={{fontSize:'24px'}}>Reminders</a>
                                    </h4>              
                                    </div>*/}
                                <div style={{float:'left',width:'100%',overflow:'scroll'}}>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div style={{width:'100%', height:'200px'}}>
                                                        <table className="calendar table table-bordered" style={{float:'left', width:'2000px'}}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Time</th>
                                                                    <th>Provider</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.appointments!=undefined && this.state.appointments.map((item,i)=>
                                                                <tr >
                                                                    <td>{item.date}</td>
                                                                    <td>{item.start_time}</td>
                                                                    <td>{item.Firstname}{" "}{item.Surname}</td>
                                                                </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
			                </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                            <button type="button"  onClick={() => this.props.close1()} className="btn btn-primary btn-xs" data-dismiss="modal">Close</button>
                    </div>   
                </div>        
                    )}     
            </Popup>
        )}
    } 
    
    const mapDispatchToProps = dispatch => {
        return {
            getAppoinment: (data) => dispatch(api_get_appointments(data)),
        };
      };
      
      const mapStateToProps = state => {
        //console.log(state);
        let appointments = state.getDrAppointments.getAppointments
          ? state.getDrAppointments.getAppointments
          : [];
        let get_cut_copy_details = state.getDrAppointments.cut_copy_details
          ? state.getDrAppointments.cut_copy_details
          : {};
        let logged_info =
          state.getDrAppointments.logged_info != undefined
            ? state.getDrAppointments.logged_info
            : "";
      
        return {
          appointments,
          get_cut_copy_details,
          logged_info
        };
      };
      
      export default connect(mapStateToProps, mapDispatchToProps)(ViewAppointment)

