import React, { Component } from "react";
import { connect } from "react-redux";
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import {
  diagonsisSave,
  update_general_comments
} from "../../../DS/DS.VisitnotesDiagnosis";
import moment from "moment";
import {
  acute,
  ChangeDragData,
  api_searchConditions,
  api_addConditions,
  deleteData,
  addPaitentData,
  deletePatientId,
  editPaitentData,
  api_loadPatientCondition,
  api_getSelectedPatientDetails,
  api_getPatientDetails
} from "../../../DS/DS.Appointments";
import "./pasthistory.css";

import { wip } from "./../../../DS/general";
import Diagonsis from "./../visitNotes/diagonsis";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { confirmAlert } from "react-confirm-alert";

import AddPatientHistory from "./addPatientHistory";
import EditPatientHistory from "./editPatientHistory";
import Showhelp from './../../showHelp';

class PastHistory extends Component {
  constructor(props, context) {
    super(props, context);
    this.acuteFunction = this.acuteFunction.bind(this);
    this.chronicFunction = this.chronicFunction.bind(this);
    this.getAllId_acute = this.getAllId_acute.bind(this);
    this.getAllId_chronic = this.getAllId_chronic.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.mapOld = this.mapOld.bind(this);
    this.state = {
      openDiagonsis: false,
      general_comments: this.props.pastHistoryComments,
      showconfidential: 0,
      showadd: false,
      key: "2",
      selectedRec_acute: "",
      selectedRec_chronic: "",

      getAllId_chronic: "",
      getAllId_acute: "",
      selectedRecLength_acute: "",
      selectedRecLength_chronic: "",

      userId: 1,
      patientId: this.props.redux_patientId,
      conditions: [],
      AddConditionText: "",
      show: false,
      id: "",
      seleted: {
        patientId: {}
      },
      editData: "",
      acuteData: [],
      selectAll: 0,
      selectedAcute: [],
      selectedChornic: [],
      defaultValues: "something",
      searchText: "",
      today:
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate(),

      canEdit: false,
      acute_crud: "",
      chronic_crud: {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.chronicFunction,
          isDateGroup: true,
          isYearSeparator: false,
          dateCol: "date",
          cellClicked: "",
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "patients_conditions",
            cols: [
              "patients_conditions_id",
              "DATE_FORMAT(date,'%d-%m-%Y') as date",

              "acute_chronic",
              "left_side",
              "right_side",
              "active",
              "severity",
              "description",
              "patients.medicare_no as medicare_no",

              // "summary",
              // "confidential",
              // "my_health_record",
               "patients_conditions.createdAt as createdAt",

              "condition_master_tb.name as condition_name"
            ],
            primary_key: "patients_conditions_id",
            joins: [
              {
                joinType: "inner",
                joinTable: "condition_master_tb",
                joinCondition:
                  "condition_master_tb.condition_id = patients_conditions.condition_id"
              },
              {
                joinType: "left",
                joinTable: "patients",
                joinCondition:
                  "patients.patient_id = patients_conditions.patient_id"
              }
            ],
            where: [
                {
                type: "and",
                key: "patients_conditions.isDeleted",
                operator: "!=",
                value: 1
              },
              {
                type: "and",
                key: "patients_conditions.patient_id",
                operator: "=",
                value: this.props.redux_patientId
              },
              {
                type: "and",
                key: "acute_chronic",
                operator: "=",
                value: "chronic"
              },
              {
                type: "and",
                key: "confidential",
                operator: "=",
                value: "0"
              }
            ]
          },

          viewtabletitle: "",
          columns: [
            { name: "date", title: "Date given" },
            { name: "condition_name", title: "Condition" },
            { name: "severity", title: "Severity" },
            // { name: "description", title: "Description." },
            // { name: "summary", title: "Summary" },

            { name: "confidential", title: "Confidential" },
            { name: "medicare_no", title: "My Health Record" },
            { name: "createdAt", title: "Date recorded" }
          ],
          rows: [],
          sortinit: [{ columnName: "date", direction: "desc" }],
          pagesize: 10,
          getAllIds: true,
          getallId: this.getAllId_chronic
        }
      }
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setdefaultvalues = this.setdefaultvalues.bind(this);
  }
  closeDiagonsis = () => {
    this.setState({openDiagonsis:false})
  }
  handleGeneralComments = e => {
    this.setState({ general_comments: e.target.value });
  };
  saveGeneralComments = e => {
    //e.preventDefault();
    this.props.update_general_comments({
      general_comments: this.state.general_comments,
      patientId: this.state.patientId
    });
    NotificationManager.success("Comment Updated SuccessFully!");
    this.props.getPatientDetails1({patient_id: {patient_id: this.state.patientId}});
    // this.props.getPatientDetails({patient_id: {patient_id: this.state.patientId}}).then((res)=> {
    //   console.log("getpatient details pasthistory", res.data.content)
    // this.setState({general_comments: res.data.content[0].pastHistoryComments});
    // });
  };
  handleConfidentialCheckbox = e => {
    if (e.target.checked) {
      this.setState({ showconfidential: 1 });
      this.acute_crud(1);
      this.chronic_crud(1);
    } else {
      this.setState({ showconfidential: 0 });
      this.acute_crud(0);
      this.chronic_crud(0);
    }
    this.changeKey();
  };

  chronic_crud = showconfidential => {
    let returnjson = "";
    this.setState({ chronic_crud: "" });
    if (showconfidential == 1) {
      let chronic_crud_json_withconfidential = {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.chronicFunction,
          isDateGroup: true,
          isYearSeparator: false,
          dateCol: "date",
          cellClicked: "",
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "patients_conditions",
            cols: [
              "patients_conditions_id",
              "DATE_FORMAT(date,'%d-%m-%Y') as date",
              "patients_conditions.condition_id as condition_id",
              "acute_chronic",
              "left_side",
              "right_side",
              "active",
              "severity",
              "description",
              "patients.medicare_no as medicare_no",
              "summary",
              "confidential",
              "my_health_record",
              "patients_conditions.createdAt as createdAt",
              "condition_master_tb.name as condition_name"
            ],
            primary_key: "patients_conditions_id",
            joins: [
              {
                joinType: "inner",
                joinTable: "condition_master_tb",
                joinCondition:
                  "condition_master_tb.condition_id = patients_conditions.condition_id"
              }
              ,
              {
                joinType: "left",
                joinTable: "patients",
                joinCondition:
                  "patients.patient_id = patients_conditions.patient_id"
              }
            ],
            where: [
              {
                type: "and",
                key: "patients_conditions.isDeleted",
                operator: "!=",
                value: 1
              },
              {
                type: "and",
                key: "patients_conditions.patient_id",
                operator: "=",
                value: this.props.redux_patientId
              },
              {
                type: "and",
                key: "acute_chronic",
                operator: "=",
                value: "chronic"
              },
              // {
              //   type: "and",
              //   key: "confidential",
              //   operator: "=",
              //   value: "1"
              // }
            ]
          },

          viewtabletitle: "",
          columns: [
            { name: "date", title: "Date given" },
            { name: "condition_name", title: "Condition" },
            { name: "severity", title: "Severity" },
            // { name: "description", title: "Description." },
            // { name: "summary", title: "Summary" },

            { name: "confidential", title: "Confidential" },
            { name: "medicare_no", title: "My Health Record" },
            { name: "createdAt", title: "Date recorded" }
          ],
          rows: [],
          sortinit: [{ columnName: "date", direction: "asc" }],
          pagesize: 10,
          getAllIds: true,
          getallId: this.getAllId_acute
        }
      };
      returnjson = chronic_crud_json_withconfidential;
      this.setState({ chronic_crud: chronic_crud_json_withconfidential });
    }

    if (showconfidential == 0) {
      let chronic_crud_json_withoutconfidential = {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.chronicFunction,
          isDateGroup: true,
          isYearSeparator: false,
          dateCol: "date",
          cellClicked: "",
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "patients_conditions",
            cols: [
              "patients_conditions_id",
              // "date",
              "DATE_FORMAT(date,'%d-%m-%Y') as date",
              "patients_conditions.condition_id as condition_id",
              "acute_chronic",
              "left_side",
              "right_side",
              "active",
              "severity",
              "description",
              "patients.medicare_no as medicare_no",
              "summary",
              "confidential",
              "my_health_record",
               "patients_conditions.createdAt as createdAt",
              "condition_master_tb.name as condition_name"
            ],
            primary_key: "patients_conditions_id",
            joins: [
              {
                joinType: "inner",
                joinTable: "condition_master_tb",
                joinCondition:
                  "condition_master_tb.condition_id = patients_conditions.condition_id"
              },
              {
                joinType: "left",
                joinTable: "patients",
                joinCondition:
                  "patients.patient_id = patients_conditions.patient_id"
              }
            ],
            where: [
              {
                type: "and",
                key: "patients_conditions.isDeleted",
                operator: "!=",
                value: 1
              },
              {
                type: "and",
                key: "patients_conditions.patient_id",
                operator: "=",
                value: this.props.redux_patientId
              },
              {
                type: "and",
                key: "acute_chronic",
                operator: "=",
                value: "chronic"
              },
              {
                type: "and",
                key: "confidential",
                operator: "=",
                value: "0"
              }
            ]
          },

          viewtabletitle: "",
          columns: [
            { name: "date", title: "Date given" },
            { name: "condition_name", title: "Condition" },
            { name: "severity", title: "Severity" },
            // { name: "description", title: "Description." },
            // { name: "summary", title: "Summary" },

            { name: "confidential", title: "Confidential" },
            { name: "medicare_no", title: "My Health Record" },
            { name: "createdAt", title: "Date recorded" }
          ],
          rows: [],
          sortinit: [{ columnName: "date", direction: "desc" }],
          pagesize: 10,
          getAllIds: true,
          getallId: this.getAllId_acute
        }
      };

      this.setState({ chronic_crud: chronic_crud_json_withoutconfidential });

      returnjson = chronic_crud_json_withoutconfidential;
    }

    console.log("pasthistory return json", returnjson, this.state.chronic_crud);
    return returnjson;
  };

  acute_crud = showconfidential => {
    let returnjson = "";
    this.setState({ acute_crud: "" });
    if (showconfidential == 1) {
      let acute_crud_json_withconfidential = {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.acuteFunction,
          isDateGroup: true,
          isYearSeparator: false,
          dateCol: "date",
          cellClicked: "",
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "patients_conditions",
            cols: [
              "patients_conditions_id",
              "DATE_FORMAT(date,'%d-%m-%Y') as date",
              "patients_conditions.condition_id as condition_id",
              "acute_chronic",
              "left_side",
              "right_side",
              "active",
              "severity",
              "description",
              "patients.medicare_no as medicare_no",
              "summary",
              "confidential",
              "my_health_record",
               "patients_conditions.createdAt as createdAt",
              "condition_master_tb.name as condition_name"
            ],
            primary_key: "patients_conditions_id",
            joins: [
              {
                joinType: "inner",
                joinTable: "condition_master_tb",
                joinCondition:
                  "condition_master_tb.condition_id = patients_conditions.condition_id"
              }, 
              {
                joinType: "left",
                joinTable: "patients",
                joinCondition:
                  "patients.patient_id = patients_conditions.patient_id"
              }
            ],
            where: [
              {
                type: "and",
                key: "patients_conditions.isDeleted",
                operator: "!=",
                value: 1
              },
              {
                type: "and",
                key: "patients_conditions.patient_id",
                operator: "=",
                value: this.props.redux_patientId
              },
              {
                type: "and",
                key: "acute_chronic",
                operator: "=",
                value: "acute"
              },
              // {
              //   type: "and",
              //   key: "confidential",
              //   operator: "=",
              //   value: "1"
              // }
            ]
          },

          viewtabletitle: "",
          columns: [
            { name: "date", title: "Date given" },
            { name: "condition_name", title: "Condition" },
            { name: "severity", title: "Severity" },
            // { name: "description", title: "Description." },
            // { name: "summary", title: "Summary" },

            { name: "confidential", title: "Confidential" },
            { name: "medicare_no", title: "My Health Record" },
            { name: "createdAt", title: "Date recorded" }
          ],
          rows: [],
          sortinit: [{ columnName: "date", direction: "desc" }],
          pagesize: 10,
          getAllIds: true,
          getallId: this.getAllId_acute
        }
      };
      returnjson = acute_crud_json_withconfidential;
      this.setState({ acute_crud: acute_crud_json_withconfidential });
    }

    if (showconfidential == 0) {
      let acute_crud_json_withoutconfidential = {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.acuteFunction,
          isDateGroup: true,
          isYearSeparator: false,
          dateCol: "date",
          cellClicked: "",
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "patients_conditions",
            cols: [
              "patients_conditions_id",
              "DATE_FORMAT(date,'%d-%m-%Y') as date",
              "patients_conditions.condition_id as condition_id",
              "acute_chronic",
              "left_side",
              "right_side",
              "active",
              "severity",
              "description",
              "patients.medicare_no as medicare_no",
              "summary",
              "confidential",
              "my_health_record",
                "patients_conditions.createdAt as createdAt",
              "condition_master_tb.name as condition_name"
            ],
            primary_key: "patients_conditions_id",
            joins: [
              {
                joinType: "inner",
                joinTable: "condition_master_tb",
                joinCondition:
                  "condition_master_tb.condition_id = patients_conditions.condition_id"
              }, 
              {
                joinType: "left",
                joinTable: "patients",
                joinCondition:
                  "patients.patient_id = patients_conditions.patient_id"
              }
            ],
            where: [
              {
                type: "and",
                key: "patients_conditions.isDeleted",
                operator: "!=",
                value: 1
              },
              {
                type: "and",
                key: "patients_conditions.patient_id",
                operator: "=",
                value: this.props.redux_patientId
              },
              {
                type: "and",
                key: "acute_chronic",
                operator: "=",
                value: "acute"
              },
              {
                type: "and",
                key: "confidential",
                operator: "=",
                value: "0"
              }
            ]
          },

          viewtabletitle: "",
          columns: [
            { name: "date", title: "Date given" },
            { name: "condition_name", title: "Condition" },
            { name: "severity", title: "Severity" },
            // { name: "description", title: "Description." },
            // { name: "summary", title: "Summary" },

            { name: "confidential", title: "Confidential" },
            { name: "medicare_no", title: "My Health Record" },
            { name: "createdAt", title: "Date recorded" }
          ],
          rows: [],
          sortinit: [{ columnName: "date", direction: "desc" }],
          pagesize: 10,
          getAllIds: true,
          getallId: this.getAllId_acute
        }
      };

      this.setState({ acute_crud: acute_crud_json_withoutconfidential });

      returnjson = acute_crud_json_withoutconfidential;
    }

    console.log("pasthistory return json", returnjson, this.state.acute_crud);
    return returnjson;
  };

  componentWillMount() {
    this.props.acute({ patientId: this.state.patientId });
  }
  componentWillReceiveProps(nextProps) {
    //    console.log('---------recieve props changed',nextProps);

    if (nextProps.pastHistoryComments !== this.props.pastHistoryComments) {
      this.setState({general_comments: nextProps.pastHistoryComments});
    }
    if (nextProps.redux_patientId !== this.props.redux_patientId) {
      this.setState({ patientId: nextProps.redux_patientId });
      this.props.acute({ patientId: nextProps.redux_patientId });
      console.log("inside receive props", this.state.patientId);
    }
  }
  componentDidMount() {
    this.acute_crud(0);
    setTimeout(
      function() {
        //Start the timer
        //   this.acute_crud(this.state.showconfidential);
      }.bind(this),
      3000
    );

    /* generate combined list to present in check box*/
    let defaultValues = "";
    this.props.acuteData.map((item, i) => {
      defaultValues = defaultValues + "\n" + item.name;
    });
    this.setState({ defaultValues: defaultValues });

    var searchParam = {
      searchText: this.state.searchText
    };
    this.props.searchConditions(searchParam).then(list => {
      this.setState({ conditions: list.data.content });
    });
  }
  checkEdit = () => {
    console.log(
      "checked",
      this.state.selectedAcute,
      this.state.selectedChornic
    );
    let canEdit = true;
    if(this.state.selectedAcute[0][0] == "" && this.state.selectedChornic[0][0] == ""){
      canEdit = false;
    }


    if (
      this.state.selectedAcute.length == 1 &&
      this.state.selectedAcute[0].length > 1
    ) {
      console.log(
        "checked inside acute if",
        this.state.selectedAcute[0].length
      );
      console.log("edit clicked - 1 ");
      canEdit = false;
    }
    if (
      this.state.selectedChornic.length == 1 &&
      this.state.selectedChornic[0].length > 1
    ) {
      canEdit = false;
      console.log("edit clicked - 2 ");
    }
    // if (
    //   this.state.selectedAcute.length == 1 &&
    //   this.state.selectedChornic.length == 1
    // ) {
    //   canEdit = false;
    //   console.log("edit clicked - 3 ");
    // }
    if (
      this.state.selectedAcute.length == 0 &&
      this.state.selectedChornic.length == 0
    ) {
      canEdit = false;
      console.log("edit clicked - 4 ");
    }

    if (canEdit) {
      let editData = "";

      if (
        this.state.selectedAcute.length == 1 &&
        this.state.selectedAcute[0].length == 1 &&
        this.state.selectedAcute[0][0] != ""
      ) {
        //  alert(629);
        //  alert(this.state.selectedAcute[0][0]);

        this.props
          .loadSelectedPatientCondition({ id: this.state.selectedAcute[0][0] })
          .then(res => {
            // alert('res');
            this.setState({ editData: res.data.content[0] });
          });
      }
      if (
        this.state.selectedChornic.length == 1 &&
        this.state.selectedChornic[0].length == 1 &&
        this.state.selectedChornic[0][0] != ""
      ) {
        // alert(640);
        editData = this.props
          .loadSelectedPatientCondition({
            id: this.state.selectedChornic[0][0]
          })
          .then(res => {
            this.setState({ editData: res.data.content[0] });
          });
      }
      console.log(editData);
      ///this.setState({editData: editData});
      this.setState({ canEdit: true });
    } else {
      alert("To Edit, Select any single Item");
    }
  };
  toggleEdit = () => {
    this.setState({ canEdit: false });
  };
  getAllId_acute = ids => {
    console.log("all ids past histroy- acute", ids);
    //this.setState({getAllId_acute: ids});
    return ids;
  };
  getAllId_chronic = ids => {
    console.log("all ids past histroy- chronic", ids);
    //this.setState({getAllId_chronic: ids});
    return ids;
  };

  acuteFunction = (selectedId, action, checklength) => {
    console.log("imm", selectedId);
    if (action == "delete") {
      //console.log
      if (this.state.selectedRecLength_acute > 0) {
        const immData = { immunisation_id: this.state.selectedRec_acute };
        confirmAlert({
          title: "",
          message: "You are about to delete Past Medical History record(s). Do you want to proceed?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                //this.changeKey();
              }
            },
            {
              label: "No",
              onClick: () => {
                // this.changeKey();
              }
            }
          ]
        });
      } else {
        NotificationManager.error("Please select any record to delete");
      }
    }
    if (action == "selection") {
      // this.setState({selectedRec: selectedId});
      // this.setState({selectedRecLength: checklength});
      this.state.selectedRec_acute = selectedId;
      this.state.selectedRecLength_acute = checklength;
      //  this.mapOld('acute');
      let item = [];
      item.push(selectedId.split(","));
      this.state.selectedAcute = item;
      // this.setState({selectedAcute: item});
    }

    console.log("pasthistorystate", this.state);
  };
  chronicFunction = (selectedId, action, checklength) => {
    if (action == "delete") {
      //console.log
      if (this.state.selectedRecLength_chronic > 0) {
        const immData = { immunisation_id: this.state.selectedRec_chronic };
        confirmAlert({
          title: "",
          message: "You are about to delete Past Medical History record(s). Do you want to proceed?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                // this.changeKey();
              }
            },
            {
              label: "No",
              onClick: () => {
                // this.changeKey();
              }
            }
          ]
        });
      } else {
        NotificationManager.error("Please select any record to delete");
      }
    }
    if (action == "selection") {
      // this.setState({selectedRec: selectedId});
      // this.setState({selectedRecLength: checklength});
      this.state.selectedRec_chronic = selectedId;
      this.state.selectedRecLength_chronic = checklength;

      // this.mapOld('chronic')
      let item = [];
      item.push(selectedId.split(","));
      this.state.selectedChornic = item;
    }
  };

  mapOld = type => {
    if (type == "chronic") {
      let item = this.state.selectedRec_chronic;
      let items = item.split(",");
      this.setState({ selectedChronic: items });
    }
    if (type == "acute") {
      let item = this.state.selectedRec_acute;
      let items = item.split(",");
      this.setState({ selectedAcute: items });
    }
  };

  diagonsisSave = data => {
    this.props.diagonsisSave(data).then(res => this.changeKey());
    this.props.getPatientDetails({patient_id: {patient_id: this.state.patientId}}).then((res)=> {
      console.log("getpatient details pasthistory", res.data.content)
    this.setState({general_comments: res.data.content[0].pastHistoryComments});
    });
    this.closeDiagonsis();
    // .then(res => {
    //   NotificationManager.success('Successfully Added');
    // })
  };

  handleAddText = e => {
    this.setState({
      AddConditionText: e.target.value
    });
  };

  changeKey = () => {
    this.setState({ key: Math.random() });
    this.setState({
      acuteCheckedConditionsId: {},
      acuteCheckedConditions: {},
      chronicCheckedConditionsId: {},
      chronicCheckedConditions: {},
      drFavCheckedConditions: {},
      drFavCheckedConditionsId: {},
      drFavCheckedConditions2: {},
      drFavCheckedConditionsId2: {}
    });

    this.setState({
      selectedRec_chronic: "",
      selectedRecLength_chronic: "",
      selectedRec_acute: "",
      selectedRecLength_acute: ""
    });

    // this.props
    //   .list_patient_immunisations({ patient_id: this.state.patientId })
    //   .then(res => {
    //     this.setState({ patient_immunisations: res.data.content });
    //   });
  };

  editMaster = data => {
    wip();
  };

  addCondition = data => {
    var AddParam = {
      AddConditionText: data
    };
    this.props.apiAddConditions(AddParam);
    NotificationManager.success("Coniditon Added Successfully..");
    this.searchCondition();
  };

  handleChangeText = e => {
    this.setState({
      searchText: e.target.value
    });
    this.searchCondition();
  };

  searchCondition() {
    var searchParam = {
      searchText: this.state.searchText
    };
    this.props.searchConditions(searchParam).then(list => {
      this.setState({ conditions: list.data.content });
    });
  }
  isAllChecked = (e, type, length) => {
    if (type === "acute") {
      return this.state.selectedAcute.length === length;
    } else if (type === "chronic") {
      return this.state.selectedChornic.length === length;
    }
  };

  isCheckboxChecked = (type, id) => {
    if (type === "acute") {
      return this.state.selectedAcute.includes(id);
    } else if (type === "chronic") {
      return this.state.selectedChornic.includes(id);
    }
  };
  handleCheckbox = (e, data, type, id) => {
    if (type === "acute") {
      if (this.state.selectedAcute.includes(id)) {
        this.setState({
          selectedAcute: [
            ...this.state.selectedAcute.filter(patientId => patientId !== id)
          ],
          editData: {}
        });
      } else {
      //  alert(859);
        this.setState({
          selectedAcute: [...this.state.selectedAcute, id],
          editData: data
        });
      }
    } else if (type === "chronic") {
      if (this.state.selectedChornic.includes(id)) {
        this.setState({
          selectedChornic: [
            ...this.state.selectedChornic.filter(patientId => patientId !== id)
          ],
          editData: {}
        });
      } else {
      //  alert(874);
        this.setState({
          selectedChornic: [...this.state.selectedChornic, id],
          editData: data
        });
      }
    }
  };

  handleCheckAllbox = (e, type, ids) => {
    if (type === "acute") {
      this.setState({
        selectedAcute: e.target.checked ? ids : []
      });
    } else if (type === "chronic") {
      this.setState({
        selectedChornic: e.target.checked ? ids : []
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.acuteData) {
      this.setState({ acuteData: nextProps.acuteData });
    }
  }

  setdefaultvalues(d) {
    //this.setState({defaultValues:d});
  }

  dragAcutetoChronic = value => {
    let patientIds, type;
    if (value.data == "acute") {
      if (this.state.selectedAcute.length > 0) {
        patientIds = this.state.selectedAcute;
        type = "acute_to_chronic";
      } else {
        NotificationManager.error("Please select anyone item from acute");
      }
    } else if (value.data == "chronic") {
      if (this.state.selectedChornic.length > 0) {
        patientIds = this.state.selectedChornic;
        type = "chronic_to_acute";
      } else {
        NotificationManager.error("Please select anyone item from Chronic");
      }
    }
    let data = {
      patientId: patientIds,
      change_type: type
    };
    if (
      this.state.selectedAcute.length > 0 ||
      this.state.selectedChornic.length > 0
    ) {
      this.props.ChangeDragData(data).then(res => {
        NotificationManager.success("Successfully Moved");
        this.setState({
          selectedAcute: [],
          selectedChornic: [],
          editData: {}
        });
        this.changeKey();
        this.props.acute({ patientId: this.state.patientId });
      });
    }
  };

  deleteHandle = e => {
    e.preventDefault();
    if (
      this.state.selectedAcute.length > 0 ||
      this.state.selectedChornic.length > 0
    ) {
      let deleted = {
        acuteData: this.state.selectedAcute,
        chronicData: this.state.selectedChornic
      };
      confirmAlert({
        title: "",
        message: "You are about to delete Past Medical History record(s). Do you want to proceed?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.props.deleteData(deleted).then(
                res => this.changeKey(),
                //this.props.acute({patientId: this.state.patientId}),
                NotificationManager.success(
                  "Successfuly deleted",
                  this.setState({
                    selectedAcute: [],
                    selectedChornic: [],
                    editData: {}
                  })
                )
              );
            }
          },
          {
            label: "No",
            onClick: () => {}
          }
        ]
      });
    } else {
      NotificationManager.error("Please select anyone checkbox for delete");
    }
  };

  addPaitentData = data => {
    return this.props.addPaitentData(data);
  };
  editPaitentData = data => {
   
    return this.props.editPaitentData(data).then((res) => {
        
      this.setState({
          selectedAcute: [],
          selectedChornic: [],
          editData: {}
        });
      NotificationManager.success("SuccessFully Updated");
    
      this.props.getPatientDetails({patient_id: {patient_id: this.state.patientId}}).then((res)=> {
        console.log("getpatient details pasthistory", res.data.content)
      this.setState({general_comments: res.data.content[0].pastHistoryComments});
      
      });
    
    }
    );
  };
  deletePatientId = data => {
    confirmAlert({
      title: "",
      message: "You are about to delete Past Medical History record(s). Do you want to proceed?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.deletePatientId(data).then(() => {
              this.searchCondition();
              NotificationManager.success("Successfuly deleted");
            });
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    var gridstyle = {
      padding: "10px"
    };
    const { acuteData } = this.props;
    return (
      <div
        id="notesdiv14"
        className="col-md-12"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <NotificationContainer />
        <div className="panel panel-default">
          <div
            className="panel-heading panspace">
            <div
              className="col-lg-12 col-xl-12">
              <h4
                className="panel-title">
                <a href="#" className="collapsed" aria-expanded="false">
                  Past History
                </a> <Showhelp gotohelp="past_history_help"/>
              </h4>
              <div style={{ width: "auto", height: 30, position: "absolute", right: 15, top: 0}}>
                <span className="makescreen">
                  <a
                    href="#"
                    title="Make This Default Screen"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    <i
                      className="fa fa-star panelicon"
                      aria-hidden="true" />
                  </a>
                </span>
                <a
                  href="#"
                  title="Email"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-envelope panelicon"
                    aria-hidden="true" />
                </a>
                <a
                  href="#"
                  title="Print"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-print panelicon"
                    aria-hidden="true" />
                </a>
                <a
                  title="Export PDF"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fa fa-file-pdf-o panelicon"
                    aria-hidden="true" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="patient-history-content">
          <div className="row">
            <div className="col-md-12">
              <div style={{ marginBottom: "10px" }}>
              <div style={{float: 'left'}}>
                <Diagonsis
                key={Math.random()}
                openDiagonsis={this.state.openDiagonsis}
                closeDiagonsis={e => this.closeDiagonsis()}
                  popupName="Add"
                  diagonsisSave={this.diagonsisSave}
                  changeKey={this.changeKey}
                />
                 <button
                 onClick={e => this.setState({openDiagonsis: true})}
            type="button"
            className="btn btn-primary btn-xs"
            style={{ marginRight: "5px" }}
          >
             Add
          </button>
                {/* <AddPatientHistory
              key={this.state.patientId}
              patientId = {this.state.patientId}
              conditions={this.state.conditions}
              handleChangeText={this.handleChangeText}
              handleAddText={this.handleAddText}
              addCondition={this.addCondition}
              editMaster ={this.editMaster }
              addPaitentData={this.addPaitentData}
              deletePatientId={this.deletePatientId}
           /> */}
           
                <button
                  onClick={e => this.checkEdit(e)}
                  id="session-timeout-dialog-logout"
                  data-toggle="modal"
                  data-target="#pastmedical"
                  type="button"
                  className="btn btn-primary btn-xs"
                  style={{ marginRight: "5px" }}
                >
                  Edit
                </button>
                <EditPatientHistory
                  general_comments={this.state.general_comments}
                  open={this.state.canEdit}
                  key={this.state.patientId}
                  close={this.toggleEdit}
                  patientId={this.state.patientId}
                  editData={this.state.editData}
                  editPaitentData={this.editPaitentData}
                  changeKey={this.changeKey}
                />
                <button
                  id="session-timeout-dialog-logout"
                  type="button"
                  onClick={this.deleteHandle}
                  className="btn btn-primary btn-xs"
                  style={{ marginRight: "5px" }}
                >
                  Delete
                </button>
                </div>
                </div>
                <p style={{ marginTop: "-4px", marginLeft: '20px', marginBottom:'0px' }}>
                  <input
                    id="confidential"
                    type="checkbox"
                    onChange={e => this.handleConfidentialCheckbox(e)}
                    Checked={this.state.showconfidential === "1" ? true : false}
                  />{" "}
                  <label for="confidential" style={{ display: "contents" }}>
                    {" "}
                    Show Confidential
                  </label>
                </p>
            
            </div>
          </div>
          <div className="row past-history-list">
            <div className="past-history-list-part col-md-6" style={{}}>
              <h5 className="sheadtitle">
                Acute:
              </h5>
              {console.log("pasthistory crud acute", this.state.acute_crud)}
              {console.log("pasthistory crud chronic", this.state.chronic_crud)}
              {this.state.acute_crud != "" && (
                <CommonGrid
                  style={gridstyle}
                  key={this.state.key}
                  handleButton={this.handleButton}
                  crud={this.state.acute_crud}
                />
              )}
           
            <div className="past-history-list-part-selector">
              <div>
                <a
                style={{cursor: 'pointer',color:"black"}}
                  onClick={() => this.dragAcutetoChronic({ data: "acute" })}
                  alt="Click and drag items between Acute and Chronic"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    title="Click and drag items between Acute and Chronic"
                    className="fas fa-arrow-right"
                    aria-hidden="true"
                    style={{ fontSize: 14, clear: "both", marginRight: 5 }}
                  />
                </a>
                <br />
                <a
                style={{cursor: 'pointer',color:"black"}}
                  onClick={() => this.dragAcutetoChronic({ data: "chronic" })}
                  alt="Click and drag items between Chronic and Acute"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    title="Click and drag items between Chronic and Acute"
                    className="fas fa-arrow-left"
                    aria-hidden="true"
                    style={{ fontSize: 14, marginRight: 5, marginTop: 10 }}
                  />
                </a>
              </div>
            </div>
            </div>
            <div className="past-history-list-part col-md-6" style={{}}>
              <h5 className="sheadtitle">
                Chronic:{" "}
              </h5>
              <CommonGrid
                style={gridstyle}
                key={this.state.key}
                handleButton={this.handleButton}
                crud={this.state.chronic_crud}
              />
            </div>
          </div>
          <div className="row past-history-list">
            <div className="col-md-12">
            <br /> General Comments <br />
            <textarea
              style={{ width: "100%" }}
              rows="3"
              value={this.state.general_comments}
              onChange={e => this.handleGeneralComments(e)}
            >
              {" "}
            </textarea>
            <br />
          </div>
          </div>
          <div
            style={{ padding: "10px", float: 'right' }}
            className="row right past-history-list"
          >
            <div className="col-md-12">
            <button
              onClick={e => this.saveGeneralComments()}
              class="btn right  btn-primary"
            >
              {" "}
              Save General Comments{" "}
            </button>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    acute: data => dispatch(acute(data)),
    ChangeDragData: data => dispatch(ChangeDragData(data)),
    deleteData: data => dispatch(deleteData(data)),
    searchConditions: searchParam =>
      dispatch(api_searchConditions(searchParam)),
    apiAddConditions: data => dispatch(api_addConditions(data)),
    addPaitentData: data => dispatch(addPaitentData(data)),
    editPaitentData: data => dispatch(editPaitentData(data)),
    deletePatientId: data => dispatch(deletePatientId(data)),
    diagonsisSave: data => dispatch(diagonsisSave(data)),
    update_general_comments: data => dispatch(update_general_comments(data)),
    loadSelectedPatientCondition: data =>
      dispatch(api_loadPatientCondition(data)),
      getPatientDetails: patient_id => dispatch(api_getSelectedPatientDetails(patient_id)),
      getPatientDetails1: patient_id => dispatch(api_getPatientDetails(patient_id))
  };
};

const mapStateToProps = state => {
  let acuteData = state.getDrAppointments.getAcute
    ? state.getDrAppointments.getAcute
    : [];

  let redux_patientId =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].patient_id
      : "";
  let pastHistoryComments =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].pastHistoryComments
      : "";

  return {
    acuteData,
    redux_patientId,
    pastHistoryComments
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PastHistory);
