import React, { Component } from 'react';
import Header from '../../header';
import Grid from '../../crudcomponent/combinedcrud';
import Genform from '../../crudcomponent/form/formcomponent';
import {dropDown} from '../../../DS/services';
class viewcenters extends Component {
    constructor(){
        super();
        this.handleButton = this.handleButton.bind(this);
    }
    state ={

        crud:{
        pagetitle: "",
        grid :true,
        add_form: {
            isVisible : false,
            schema : {
                title: "Center",
                type: "object",
                properties:{
                    name: {type: "string", title: "Name", },
                    medical_center_id: {type: "string", title: "Medical Center ID"},
                    key_contact: {type: "string", title: "Key Contact"},
                    mobile: {type: "string", title: "Mobile"},
                    email: {type: "string", title: "Email"},
                    //city:  dropDown( "patients", "user_id", "Firstname", 1),
                }
            },


            ui_schema : {}
        },
        edit_form: {
            isVisible : false,
            schema : {
                title: "Center",
                type: "object",
                required: ["name","medical_center_id"],
                properties:{
                    name: {type: "string", title: "UserName", },
                    medical_center_id: {type: "string", title: "Password"},

                }
            },
            ui_schema : {},
            formData: {}
        },
        viewgrid : {
            isVisible : false,
            isPagination : false,
            isFiltering : false,
            isActions : false,

            baseurl : 'http://dev46.fiveminutes.in/supadoc/api/project/?api=general&action=q',
            query:{
                tb : "center_tb",
                cols : [
                    "name",
                    "medical_center_id",
                    "key_contact",
                    "status"
                ]
            },
            viewtabletitle : "",
            columns : [
                { name: 'name', title: 'Name' },
                { name: 'medical_center_id', title: 'Medical Center ID' },
                { name: 'key_contact', title: 'Key Contact' },
                { name: 'status', title: 'Status' },
                { name: 'action', title: 'Action' },
              ],
            rows : [],
            sortinit : [{ columnName: 'name', direction: 'asc' }],
            pagesize : 3,
            actions : [
                {buttonClass : 'info', icon: "fa-eye", text: "", function: "edit", passParam : "name", isDefault : true },
                {buttonClass : 'danger', icon: "fa-trash", text: "",  function: "delete", passParam : "name", isDefault : true  },
                {buttonClass : 'primary', icon: "fa-eye", text: "",  function: "view", passParam : "name", isDefault : false  }
            ]
         }
    }
 }

 handleButton = (selectedId, todo) => {
    console.clear();
    console.log('edit');
    if(todo == "view"){

    }


};



    render() {
        const {grid} = this.state;
        return ( <div>
            <Header/><h1> Manage Center  </h1>



            {  <Grid handleButton={this.handleButton} crud={this.state.crud}/>}


            </div> );
    }
}

export default viewcenters;
