import React, { Component } from "react";
import Header from "../../header";
import Footer from "../../footer";
import Grid from "../../crudcomponent/combinedcrud";
import { dropDown, dropdown1, dropDowna } from "../../../DS/services";
import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
  
class patients extends Component {
  state = {
    crud: {
      jsonGridVersion: "v1.0",
      pagetitle: "",
      grid: true,
      add_form: {
        isVisible: true,
        isBacktoGridOnSubmit: true,
        destination: "patients",
        pk: "patient_id",
        successMessage: "Successfully Added",
        errorMessage: "Something went to Wrong, Try again later",
        schema: {
          title: "Add Patient",
          type: "object",
          required: ["Firstname", "city"],
          properties: {
            Firstname: { type: "string", title: "Firstname" },
            Middlename: { type: "string", title: "Middlename"},
            Surname:{ type: "string", title: "Surname"},
            
            Address1: { type: "string", title: "Address1" },
            Address2: { type: "string", title: "Address2"},
            Postcode: { type: "string", title: "Postcode"},
            PostalAddress: { type: "string", title: "PostalAddress"},
            //PostalCity: { type: "string", title: "PostalCity"},
            PostalPostcode: { type: "string", title: " PostalPostcode"},
            DOB: { type: "string", title: "Date Of Birth" },
            Age: { type: "number", title: "Age"},
            Sex: {
              "title": "Sex",
              "type": "string",
              "enum": [
                "Male",
                "Female",
                "Other"
              ],
              "enumNames": [
                "Male",
                "Female",
                "Other"
              ]
            },
            //Sex: { type: "string", title: "Sex"},
             /*mobile: { type: "string", title: "Mobile" },
            email: { type: "string", title: "Email" },*/
            city: dropDown("patients", "patient_id", "Firstname", "")
          } 
        },

        ui_schema: {
          mobile: {
            "ui:help": "Ex: (02) 5551 5678"
          },
          email: {
            "ui:widget": "email",
            "ui:help": "Enter primary contact email"
          }
        }
      },
      edit_form: {
        isVisible: true,
        isBacktoGridOnSubmit: true,
        destination: "patients",
        pk: "patient_id",
        successMessage: "Successfully Updated",
        errorMessage: "Something went to Wrong, Try again later",
        schema: {
          title: "Edit Patient",
          type: "object",
          required: ["Firstname", "city"],
          properties: {
            Firstname: { type: "string", title: "Firstname" },
            Middlename: { type: "string", title: "Middlename"},
            Surname:{ type: "string", title: "Surname"},
            
            Address1: { type: "string", title: "Address1" },
            Address2: { type: "string", title: "Address2"},
            Postcode: { type: "string", title: "Postcode"},
            PostalAddress: { type: "string", title: "PostalAddress"},
            //PostalCity: { type: "string", title: "PostalCity"},
            PostalPostcode: { type: "string", title: " PostalPostcode"},
            DOB: { type: "string", title: "Date Of Birth" },
            Age: { type: "number", title: "Age"},
            Sex: {
              "title": "Sex",
              "type": "string",
              "enum": [
                "Male",
                "Female",
                "Other"
              ],
              "enumNames": [
                "Male",
                "Female",
                "Other"
              ]
            },
            city: dropDown("patients", "patient_id", "Firstname", ""),
            ID: { type: "number" }
          }
        },

        ui_schema: {
          mobile: {
            "ui:help": "Ex: (02) 5551 5678"
          },
          email: {
            "ui:widget": "email",
            "ui:help": "Enter primary contact email"
          },
          ID: {
            "ui:widget": "hidden"
          }
        }
      },
      delete: {
        destination: "patients",
        pk: "patient_id",
        isConfirmBeforeDelete: true,
        successMessage: "Successfully Deleted..!",
        errorMessage: "Something went to Wrong, Try again later",
        confirmationTitle: "Do you really want to delete?",
        confirmationMessage: "Are you sure to do this."
      },
      viewgrid: {
        isVisible: true,
        isPagination: true,
        isFiltering: true,
        isActions: true,

        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "patients",
          cols: [
            "patient_id",
            "Firstname",
            "Middlename",
            "Surname",
            "Address1",
            "Address2",
            "Postcode",
            "PostalAddress",
            "PostalCity",
            "PostalPostcode",
            "DOB",
            "Sex",
            "City"
            
           /* "medicareno",
            "mobile",
            "email",*/
          
            //"status"
          ]
        },
        viewtabletitle: "test table",
        columns: [
          { name: "Firstname", title: "FirstName" },
          { name: "Middlename", title: "Middlename"},
          { name: "Surname", titlt: "Surname"}, 
          { name: "DOB", title: "DOB" },
          { name: "Address1", title: "Address" },
          { name: "Sex", title: "Sex"},
          /*{ name: "medicareno", title: "MedicareNo"},
          { name: "mobile", title: "Phone"},
          { name: "email", title: "Email"},*/
          { name: "City", title: "City"},
          { name: "action", title: "Action"}
          
        ],
        rows: [],
        sortinit: [{ columnName: "Firstname", direction: "asc" }],
        pagesize: 3,
        actions: [
          
          {
            buttonClass: "info",
            icon: "fa-pencil",
            text: "",
            function: "edit",
            passParam: "patient_id",
            isDefault: true
          },
          {
            buttonClass: "danger",
            icon: "fa-trash",
            text: "",
            function: "delete",
            passParam: "patient_id",
            isDefault: true
          },
          {
            buttonClass: "primary",
            icon: "fa-eye",
            text: "",
            function: "view",
            passParam: "Firstname",
            isDefault: false
          }
        ]
      }
    }
  };
  componentDidMount() {}
  render() {
    return (
      <div>
        <body
          className="skin-blue sidebar-mini"
          data-gr-c-s-loaded="true"
          style={{ height: "auto", minHeight: "100%" }}
        >
          <div
            className="wrapper"
            style={{ height: "auto", minHeight: "100%" }}
          >
            <AdminHeader />
            <Leftsidemenu />
            {/* Content Header (Page header) */}

            <div className="content-wrapper" style={{ minHeight: 314 }}>
              <section className="content-header">
                <h1>
                  Manage Patients
                  <small>Sub title</small>
                </h1>
                <ol className="breadcrumb">
                  <li>
                    <a href="#">
                      <i className="fa fa-dashboard" /> Home
                    </a>
                  </li>
                  <li className="active">Manage Patients</li>
                </ol>
              </section>
              {/* Main content */}
              <section className="content">
                <div className="row">
                  <div className="col-lg-12">
                    <Grid
                      handleButton={this.handleButton}
                      crud={this.state.crud}
                    />
                  </div>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </body>
      </div>
    );
  }
}

export default patients;
