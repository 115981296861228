import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {api_list_reason} from '../../../DS/DS.ListPage';
import {api_add_group_member} from '../../../DS/DS.MessageGroup';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
class AddMessageGroup extends Component {
  constructor(props) {
    super(props);
    this.state={
                open:this.props.showModal,
                errors:[],
                groupname:'',
                memberSelected:'',
                memberSelectedlength:-1,
                query1:'(select user_id from message_group_user_tb where message_group_user_tb.group_id='+this.props.groupid+')',
                groupmemberCrud:''
            }
    }
    componentWillMount=()=>
    {
      //  alert(this.props.groupid)
       // alert(this.state.query1)
        this.reloadCrud()
    }
    removeGroupMember=(ids,action,totalLength )=>
    {
        if(action=='selection')
        {
             this.state.memberSelected=ids;
             this.state.memberSelectedlength=totalLength;
        }
    }
    componentDidMount=()=>
    {
        
    }
addMembers=()=>
{   
    if(this.state.memberSelectedlength > 0){
        const reasonData={
                group_id:this.props.groupid,
                user_id:this.state.memberSelected
            }
                this.props.addGroupMember(reasonData).then(
                req=>
                { if(req.data.status=="Duplicate"){
                                
                    NotificationManager.error('Duplicate group cannot be created',)
                 }
                 else if(req.data.status=="Success")
                 {
                     
                     NotificationManager.success('New group created',)
                 }
                 else{
                     
                     NotificationManager.error('Error encountered while creating group',)
                 }
                this.props.reloadPage()
            }        
            
                    
            );
    }
    else
    {
        NotificationManager.error('Select user to add to the group',)
    }
}
reloadCrud=()=>
{
   // alert("IN");
    let returnjson = "";
  this.setState({ groupmemberCrud: "" });
   const groupmemberCrud1= {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible:true,
          isPagination: true,
          isFiltering: true,
          isSelection:true,
          deleteFunction:this.removeGroupMember,
          isDateGroup:false,
          isYearSeparator:false,
          dateCol:'',
          groupDateSelection:false,
          cellClicked:'',
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "user_tb",
            cols: [
              "name",
              "role_name",
              "user_tb.user_id"
              
            
            ],
           where: [
           {
             type:"and user_tb.user_id not in (select user_id from message_group_user_tb where message_group_user_tb.group_id="+this.props.groupid+")",
            
             
  
           }
          ],
          joins:[
           
          {
            joinType: "left",
            joinTable: "user_role_tb",
            joinCondition: " user_role_tb.user_id=user_tb.user_id"
          },
          {
            joinType: "left",
            joinTable: "role_tb",
            joinCondition: "role_tb.role_id=user_role_tb.role_id"
          }

          
          ]
           ,
            primary_key:"user_id",
          },
          viewtabletitle: "",
          columns: [
            {name: "name", title: "Name"},
            {name: "role_name", title: "Role"}
            
           
          ],
          rows: [],
          sortinit: [{ columnName: "user_id", direction: "desc" }],
          pagesize: 10,
          getAllIds:false,
          getallId:''
        }
      }
      returnjson = groupmemberCrud1;
  this.setState({ groupmemberCrud: groupmemberCrud1 });
  return returnjson;
}
  render() {
    return (
      <Popup open={this.state.open} closeOnDocumentClick={false} position="right center"   modal>
        {close => (
            <div>
                 <NotificationContainer />
                <div className="modal-header">
                    <span className="modal-title2" id="exampleModalLabel"> Message users  </span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                   {/*} <div className="row row-distance">
                        <div className="col-sm-3"> 
                            <label> Location : </label>
                        </div>
                         <div className="col-sm-9">
                            <div className="form-group">                                                   
                                <select className="dropdown-box">
                                    <option> abc </option>
                                    <option> xyz </option>
                                    <option> abc </option>
                                </select>
                            </div>
                        </div>
                    </div>*/}
                    <div className="row row-distance">
                        <div className="col-sm-12"> 
                           {/*} <table className="calendar table table-bordered tablestyle">
                                <thead>
                                    <tr>
                                        <th> Name </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="border-checkbox-group border-checkbox-group-default">
                                                <input className="border-checkbox" type="checkbox" id="checkbox0"/>
                                                <label className="border-checkbox-label" for="checkbox0"> &nbsp; Ms. Nadine Nurse</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="border-checkbox-group border-checkbox-group-default">
                                                <input className="border-checkbox" type="checkbox" id="checkbox4"/>
                                                <label className="border-checkbox-label" for="checkbox4"> &nbsp; Ms. Jenny Reception</label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                </table>*/}
                                                <CommonGrid
                                                     crud={this.state.groupmemberCrud}
                                                     key={this.state.reloadKey}
                                                /> 
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-sm-5"> </div>
                        <div className="col-sm-7">
                            {/*<button type="button" className="btn btn-default" data-dismiss="modal"> Select all </button>
                            <button type="button" className="btn btn-default" data-dismiss="modal"> Deselect all </button>*/}
                            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>this.addMembers()}> Add </button>
                            <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.reloadPage()}>Cancel</button> 
                        </div>
                    </div>
                </div>
            </div>
        )}
       </Popup>    
    )}
}
const mapDispatchToProps = dispatch => {
    return {
        addGroupMember: data =>dispatch(api_add_group_member(data)),
        
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(AddMessageGroup);
