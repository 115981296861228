import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import Showhelp from './../../showHelp';

class StandardDrinks extends Component {
    constructor(props){
        super(props);
        this.state = {
            open:this.props.showmodal,
           }
    }
    render() {
        return ( 
                <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
                {close => (
                    <div style={{ width:'700px', marginLeft:'170px', backgroundColor:'#FFF', float:'left'}}>
                        <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '0px 15px'}}>
                            <h6 style={{float:'left', fontSize: '16px'}}> Standard drinks <Showhelp gotohelp="standard_drinks_help"/></h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" 
                                onClick={()=>this.props.changeKey()} style={{ marginTop:"5px", fontSize: '25px'}}>
                                    <span aria-hidden="true">×</span>
                                </button>
                        </div>
                        <div className="modal-body" style={{border:'0px'}}>
                            <div className="container">
                            <div className="row row-distance" style={{marginTop:'20px'}}>
                                <div className="col-sm-4"> </div>		
                                <div className="col-sm-6">
			   		                <label className="col-form-label text-left"> 1 Standard drink = 1g alcohol </label>
			                    </div>	  	
			                </div>
                            <div className="row row-distance"></div>
                            <div className="row row-distance">
                                <div className="col-sm-8">
				                    <label className="col-form-label text-left" style={{marginTop:'15px'}}> <b> The amount of alcohol is contained in : </b>  </label>
				                </div>
			                    <div className="col-sm-4"> </div> 
			                </div>
			                <div className="row row-distance"></div>
                            <div className="row row-distance">
                                <div className="col-sm-1"> </div>		
                                <div className="col-sm-11">
			   		                <label className="col-form-label text-left"> 1.285ml of 'heavy' beer (middy,pot, 10oz) </label>
			                    </div>	  	
			                </div>
                            <div className="row row-distance">
                                <div className="col-sm-1"> </div>		
                                <div className="col-sm-11">
			   		                <label className="col-form-label text-left"> 2.375ml stubby 'mid strength' beer </label>
			                    </div>	  	
			                </div>
                            <div className="row row-distance">
                                <div className="col-sm-1"> </div>		
                                <div className="col-sm-11">
			   		                <label className="col-form-label text-left"> 3.120ml glass of table wine </label>
			                    </div>	  	
			                </div>
                            <div className="row row-distance">
                                <div className="col-sm-1"> </div>		
                                <div className="col-sm-11">
			   		                <label className="col-form-label text-left"> 4.60ml glass of port, sherry </label>
			                    </div>	  	
			                </div>
                            <div className="row row-distance">
                                <div className="col-sm-1"> </div>		
                                <div className="col-sm-11">
			   		                <label className="col-form-label text-left"> 5.30ml nip of spirits </label>
			                    </div>	  	
			                </div>			
                        </div>
                        </div>
		                <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>this.props.changeKey()}>Close</button>
                        </div>
                    </div>
                )}
            </Popup>
        )
    }
}        
export default StandardDrinks;