import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager} from 'react-notifications';
import Showhelp from './../../../showHelp';

class SendMail extends Component {
  constructor(props) {
    super(props);
    this.state={
      email:'',
      open:this.props.showModal
    }
  }
  render() {
    return (
      <Popup open={this.state.open} closeOnDocumentClick={false} position="right center"   modal>
        {close => (
            <div>
                <div className="modal-header" style={{backgroundColor:'#f4f4f4', padding: '5px 15px'}}>
		            <h5 style={{backgroundColor:'#f4f4f4', float:'left', width:'100%', padding:'5px 10px', fontSize:'16px', marginBottom:'0px'}}>Send Mail <Showhelp gotohelp="send_mail_help"/></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body" style={{paddingTop:'30px', paddingBottom:'30px'}}>
			        <div className="col-sm-2" style={{textAlign:'right', paddingRight: '0px', marginTop:'10px'}}>
				        <label className="col-form-label text-right">Email:</label>
			        </div>
			        <div className="col-sm-8">
				        <input type="text" className="form-control form-control-sm" value={this.state.email} style={{padding: '5px 10px', width:'100%'}}
                onChange={(e)=>this.setState({email:e.target.value})}/>
			        </div>
			        <div className="col-sm-1">
				        <i className="fas fa-edit" aria-hidden="true" style={{fontSize:'16px', marginLeft:'-15px', marginTop:'15px'}}></i>
			        </div>
	            </div>
                <div className="modal-footer">
		            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>this.props.changeKey()}>Save &amp; Send</button>
		            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>this.props.changeKey()}>Close</button>
                </div>
            </div>
        )}
        </Popup>
    )}
}
 const mapDispatchToProps = dispatch => {
    return {
         };
  };
  const mapStateToProps = state => {
  
    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    
    return {
        redux_patientId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(SendMail);


