import React, { Component } from "react";
//import {api_getPatientDetails} from './../../DS/DS.Appointments';
import {
  api_getSummaryData,
  api_getSummaryCareplan,
  api_summary_billing,
  api_getSummaryInvestigations,
  api_getSummaryObservations,
} from "../../../DS/DS.VisitnotesDiagnosis";
import {
  api_investigations_master,
  api_patient_investigations,
  api_add_patient_investigations,
} from "../../../DS/DS.Observations";
import Reminders from "../reminders/reminders";
import { connect } from "react-redux";
import PreventiveHealth from "./preventiveHealth";
import moment from "moment";
import BillingHistory from "./billingHistory";
import Referedby from "./refferedBy";
import Showhelp from './../../showHelp';

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patientId: this.props.redux_patientId,
      summary: [],
      isBMI: true,
      reminderModal: false,
      patients_careplan: [],
      patients_careplan_date: [],
      patients_careplan_items: [],
      patients_investigations: [],
      patients_billing: [],
      patients_observations: [],
      left_investigations: [23, 24, 25],
      key: 0,
      PreventiveHealthOpen: false,
      referedby: false,
      billing_total: 0
    };
  }
  openReferedBy = (e) => {
    // alert(i);
    this.setState({ referedby: true });
  };

  closeReferedBy = (e) => {
    // alert(i);
    this.setState({ referedby: false });
  };

  togglePreventiveHealthOpen = () => {
    this.setState({ PreventiveHealthOpen: !this.state.PreventiveHealthOpen });
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.redux_patientId !== this.props.redux_patientId) {
      this.setState({ patientId: nextProps.redux_patientId });
    }
  }

  componentDidMount() {
    this.props.getSummaryData().then((res) => {
      this.setState({ summary: res });
    });
    this.props.investigation_master();
    this.props
      .getSummaryCareplan({ patient_id: this.state.patientId })
      .then((res) => {
        this.setState({ patients_careplan_date: res.data.content.date });
        this.setState({ patients_careplan_items: res.data.content.items });
        this.setState({ patients_reminders: res.data.content.reminders });
      })
      .then(() => console.log(this.state, "summary state"));
    this.props
      .api_summary_billing({ patient_id: this.state.patientId })
      .then((res) => {
        this.setState({ patients_billing: res.data.content });

        let billing = res.data.content;
        let price = 0;
        for(let z=0; z<billing.length; z++){
          price += parseInt(billing[z].price);
        }
        
        this.setState({billing_total : price});




        //this.setState({patients_careplan_items: res.data.content.items});
      })
      .then(() => console.log(this.state, "summary state"));

    this.props
      .getSummaryInvestigations({ patient_id: this.state.patientId })
      .then((res) => {
        this.setState({ patients_investigations: res.data.content });
      });
    this.props
      .getSummaryObservations({ patient_id: this.state.patientId })
      .then((res) => {
        this.setState({ patients_observations: res.data.content });
      });
    console.log("summary state", this.state);
  }

  bmi16 = (e, type) => {
    e.preventDefault();
    if (type == "bmi") {
      this.setState({ isBMI: true });
    } else {
      this.setState({ isBMI: false });
    }
  };
  callReminder = () => {
    this.setState({ reminderModal: true });
  };
  updatePage = () => {
    this.setState({ key: Math.random() });
  };
  toggleBillingHistory = () => {
    this.setState({ showBillingHistory: false });
  };
  openBillingHistory = () => {
    this.setState({ showBillingHistory: true });
  };
  render() {
    const { selectedPatientDetails } = this.props;
    return (
      <div
        id="summaryshort"
        className="col-md-5"
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          height: "665px",
          overflowX: "hidden",
        }}
      >
        <div
          id="notesdiv"
          className="col-md-12"
          style={{ display: "block", paddingLeft: "0px", paddingRight: "0px" }}
        >
          <Referedby
            open={this.state.referedby}
            //open={true}
            closeReferedBy={(e) => this.closeReferedBy()}
            //getServiceNote={this.getServiceNote}
          />
          <div
            style={{
              width: "100%",
              height: "400px",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  float: "left",
                  width: "100%",
                  padding: "7px 10px",
                  marginBottom: "0px",
                }}
              >
                <div
                  className="col-lg-12 col-xl-12"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <h4
                    className="panel-title"
                    style={{
                      float: "left",
                      width: "100%",
                      display: "inline",
                      marginRight: "10px",
                    }}
                  >
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseOne"
                      style={{
                        float: "left",
                        fontSize: "16px",
                        fontWeight: "",
                        color: "#ffffff",
                      }}
                      aria-expanded="true"
                    >
                      Summary
                    </a>
                  </h4>
                  <div
                    style={{
                      width: "auto",
                      height: "30px",
                      position: "absolute",
                      right: "-12px",
                    }}
                  >
                    <span className="makescreen">
                      <a href="#" title="Make This Default Screen">
                        <i
                          className="fa fa-star mainala panelicon"
                          aria-hidden="true" />
                      </a>
                    </span>
                    <a href="#" title="Email">
                      <i
                        className="fas fa-envelope panelicon"
                        aria-hidden="true" />
                    </a>
                    <a href="#" title="Print">
                      <i
                        className="fas fa-print panelicon"
                        aria-hidden="true" />
                    </a>
                    <a title="Export PDF">
                      <i
                        className="fa fa-file-pdf-o panelicon"
                        aria-hidden="true" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="row" style={{ padding: "0px 0 0 10px" }}>
                  <div className="row">
                    <div className="col-md-6 pl-0 pr-0">
                      <div
                        className="col-xl-12"
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <div className="summarybg">
                          <p>
                            <b>Care plan date</b>{" "}
                            <span style={{ float: "right" }}></span>
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-xl-12"
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <div
                          className="summarybg"
                          style={{
                            backgroundColor: "#ffffff",
                            height: "190px",
                            overflowY: "scroll",
                          }}
                        >
                          <p />

                          <table style={{ width: "100%" }}>
                            <tbody>
                              {this.state.patients_careplan_date != undefined &&
                                this.state.patients_careplan_date.length > 0 &&
                                this.state.patients_careplan_date.map(
                                  (item, i) => (
                                    <tr>
                                      <td>
                                        {" "}
                                        {moment(
                                          this.state.patients_careplan_date[i]
                                        ).format("DD/MM/YYYY")}{" "}
                                      </td>
                                      <td>
                                        {this.state.patients_careplan_items[
                                          i
                                        ] != undefined &&
                                          this.state.patients_careplan_items[i]
                                            .length > 0 &&
                                          this.state.patients_careplan_items[
                                            i
                                          ].join(",")}{" "}
                                      </td>{" "}
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>

                          <p />
                        </div>
                      </div>
                    </div>
                    {/*billing history*/}
                    <div
                      className=" col-lg-6 col-xl-12"
                      style={{ paddingRight: "0px" }}
                    >
                      <div className="summarybg">
                        <p>
                          <b>Billing History:</b>
                        </p>
                      </div>
                      <div
                        style={{
                          width: "auto",
                          height: "185px",
                          position: "absolute",
                          right: "-15px",
                          top: "5px",
                        }}
                      >
                        <label
                          className="label-input100"
                          style={{
                            paddingBottom: 0,
                            fontWeight: "bold",
                            fontSize: 12,
                            paddingTop: 3,
                            color: '#333333'
                          }}
                        >
                          O/S Amt:{" "}
                          <span
                            style={{
                              fontSize: 12,
                              fontWeight: "normal",
                              color: "#ff0000",
                            }}
                          >
                          $ {this.state.billing_total}
                            &nbsp;
                          </span>
                        </label>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-xl-12"
                      style={{ paddingRight: "0px" }}
                    >
                      <div
                        style={{
                          float: "left",
                          width: "100%",
                          height: "185px",
                          overflow: "scroll",
                        }}
                      >
                        <table style={{ width: "100%" }}>  
                          <tbody>
                            <tr>
                              <td>
                                <div>
                                  <table className="calendar table table-bordered">
                                    <thead>
                                      {/* <tr>
                                        <th style={{ width: "100px" }}>Date</th>
                                        <th>MBS Item</th>
                                      </tr> */}
                                    </thead>

                                    <tbody>
                                      {this.state.patients_billing !=
                                        undefined &&
                                        this.state.patients_billing.length >
                                          0 &&
                                        this.state.patients_billing.map(
                                          (item, i) => (
                                            <tr>
                                              <td>
                                                {" "}
                                                {moment(item.recordedAt).format(
                                                  "DD/MM/YYYY"
                                                )}
                                              </td>
                                              <td>
                                                <a
                                                  href="#"
                                                  onClick={(e) =>
                                                    this.openBillingHistory()
                                                  }
                                                  style={{
                                                    color: "#0000ff",
                                                    textDecoration: "underline",
                                                  }}
                                                  data-toggle="modal"
                                                  data-target="#billinghistory"
                                                >
                                                  {" "}
                                                  {item.item_no}{" "}
                                                </a>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <BillingHistory
                          patient_id={this.state.patientId}
                          open={this.state.showBillingHistory}
                          close={this.toggleBillingHistory}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <span><div className="col-xl-12" style={{paddingLeft: '0px', paddingRight: '0px'}}>
                    <div className="summarybg col-xl-6"><p className="tob" style={{width: 'auto', float: 'left'}}><span className="col-xl-7 stoba" onclick="stoba();" style={{float: 'left', fontWeight: 'normal', paddingLeft: '0px'}}><b style={{fontWeight: 'bold'}}>Tobacco &nbsp; </b></span> <i className="fas fa-smoking" title="No" style={{marginRight: '20px', color: 'red'}} />
                      </p><div id="stSmoking" onclick="stSmoking()" style={{display: 'block'}}>Smoker</div>
                      <div id="stexSmoking" onclick="stexSmoking()" style={{display: 'none'}}>Ex-Smoker</div>
                      <div id="stnonSmoking" onclick="stnonSmoking()" style={{display: 'none'}}>Non-Smoker</div>
                      <p /></div>
                    <div className="summarybg col-xl-6"><p><span className="col-xl-3 salc" onclick="salco();" style={{float: 'left', fontWeight: '', paddingLeft: '0px'}}><b style={{fontWeight: ''}}>Alcohol &nbsp; </b></span> <i className="fas fa-glass-martini" style={{color: 'red'}} title="Yes" /></p></div>
                  </div>
                  <div className="col-xl-12" style={{paddingLeft: '0px', paddingRight: '0px', display: 'block'}} id="statussmoking">
                    <div className="summarybg" style={{backgroundColor: '#ffffff'}}>
                      <div className="row" style={{marginLeft: '0px'}}>
                        <div className="col-xl-4">Year Commenced - 2005</div>
                        <div className="col-xl-4">No. per day - 5</div>
                      </div>
                    </div>
                  </div>
                  </span> */}
                  <div
                    className="col-xl-12"
                    style={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      display: "none",
                    }}
                    id="statusexsmoker"
                  >
                    <div
                      className="summarybg mt-2"
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      <div className="row" style={{ padding: "10px 0 0 10px" }}>
                        <div className="col-xl-4">Year Commenced - 2005</div>
                        <div className="col-xl-4">No. per day - 5</div>
                        <div className="col-xl-4">Year Quit:- 2015</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-12"
                    style={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      display: "none",
                    }}
                    id="statusalco"
                  >
                    <div
                      className="summarybg"
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      <div className="row">
                        <div className="col-xl-4">Days per week 1</div>
                        <div className="col-xl-4">Drinks per day 3</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ padding: "10px 0 0 0px" }}>
                  <div
                    className=" col-lg-6 col-xl-6"
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <div
                      className="col-xl-12"
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      {this.props.investigation_master_data.length > 0 &&
                        this.props.investigation_master_data
                          .filter((item) => item.summary_left == "1")
                          .sort((a, b) => a.left_sort > b.left_sort)
                          .map((item) => (
                            <div
                              className="col-xl-12 "
                              style={{
                                paddingRight: "0px",
                              }}
                            >
                              <div className="summarybg mt-2">
                                <p>
                                  <span
                                    className="col-xl-6">
                                    <b> {item.name}</b>
                                  </span>

                                  {this.state.patients_investigations.length >
                                    0 &&
                                    this.state.patients_investigations
                                      .filter(
                                        (i) =>
                                          i.isActive == "1" &&
                                          i.investigations_id ==
                                            item.investigations_id
                                      )
                                      .map((i) => (
                                        <span>
                                          <span
                                            className="col-xl-2"
                                            style={
                                              ({ float: "left" },
                                              { marginLeft: "10px" })
                                            }
                                          >
                                            {i.value}
                                          </span>
                                          <span style={{ float: "right" }}>
                                            {moment(i.date).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </span>
                                        </span>
                                      ))}
                                </p>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-xl-6"
                    style={{ paddingRight: "0px" }}
                  >
                    <div
                      className="col-xl-12"
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      <div className="summarybg mt-2">
                        <p>
                          <span
                            className="col-xl-6"
                            style={{ float: "left", paddingLeft: "0px" }}
                          >
                            <b>Blood Pressure</b>{" "}
                          </span>
                          <span
                            className="col-xl-2"
                            style={({ float: "left" }, { marginLeft: "10px" })}
                          >
                            {this.state.patients_observations.length > 0 &&
                              this.state.patients_observations
                                .filter((item) => item.observation_id == "1")
                                .map((i) => <span>{i.value}</span>)}
                          </span>
                          <span style={{ float: "right", textAlign: "right" }}>
                            {this.state.patients_observations.length > 0 &&
                              this.state.patients_observations
                                .filter((item) => item.observation_id == "1")
                                .map((i) => (
                                  <span>
                                    {moment(i.date).format("DD/MM/YYYY")}
                                  </span>
                                ))}
                          </span>
                        </p>
                      </div>
                    </div>

                    {this.props.investigation_master_data.length > 0 &&
                      this.props.investigation_master_data
                        .filter((item) => item.summary_right == "1")
                        .sort((a, b) => a.right_sort > b.right_sort)
                        .map((item) => (
                          <div
                            className="col-xl-12 "
                            style={{ paddingLeft: "0px", paddingRight: "0px" }}
                          >
                            <div className="summarybg mt-2">
                              <p>
                                <span
                                  className="col-xl-6">
                                  <b> {item.name}</b>
                                </span>

                                {this.state.patients_investigations.length >
                                  0 &&
                                  this.state.patients_investigations
                                    .filter(
                                      (i) =>
                                        i.isActive == "1" &&
                                        i.investigations_id ==
                                          item.investigations_id
                                    )
                                    .map((i) => (
                                      <span>
                                        <span
                                          className="col-xl-2"
                                          style={
                                            ({ float: "left" },
                                            { marginLeft: "10px" })
                                          }
                                        >
                                          {i.value}
                                        </span>
                                        <span style={{ float: "right" }}>
                                          {moment(i.date).format("DD/MM/YYYY")}
                                        </span>
                                      </span>
                                    ))}
                              </p>
                            </div>
                          </div>
                        ))}
                  </div>
                  <div
                    className="col-sm-12"
                    style={{ marginBottom: "10px", marginTop: "0px" }}
                  >
                    <PreventiveHealth
                      close={(e) => this.togglePreventiveHealthOpen(e)}
                      patient_id={this.state.patientId}
                      open={this.state.PreventiveHealthOpen}
                    />
                    <button
                      onClick={(e) => this.togglePreventiveHealthOpen(e)}
                      id="session-timeout-dialog-logout"
                      type="button"
                      className="btn btn-primary btn-xs">
                      Preventive Health
                    </button>
                    {this.state.reminderModal ? (
                      <Reminders
                        showModal={this.state.reminderModal}
                        changeKey={this.updatePage}
                      />
                    ) : (
                      ""
                    )}
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#myModaltest"
                      className=" btn btn-primary btn-xs" onClick={() => {
                        this.callReminder();
                      }}
                    >
                      Reminders
                    </button>
                  </div>
                  <div
                    className="col-lg-6 col-xl-12"
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <div className="summarybg">
                      <p>
                        <b>Reminders</b>
                      </p>
                    </div>

                    <div
                      className="col-xl-12"
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      <div className="summarybg" style={{height: '150px', overflowY: 'scroll'}}>
                        <p
                          style={{
                            float: "left",
                            width: "100%",
                            overflowY: "scroll",
                            height: "100px",
                          }}
                        >
                          {this.state.patients_reminders != undefined &&
                            this.state.patients_reminders.length > 0 &&
                            this.state.patients_reminders.map((item, i) => (
                              <span>
                                {moment(item.reminder_datetime).format(
                                  "DD/MM/YYYY"
                                )} -{" "}
                               {item.reason} ({item.content}) {" "}
                                <br />
                                <hr/>
                              </span>
                            ))}

                          {/* <textarea
                            style={{
                              float: "left",
                              width: "100%",
                              padding: "5px",
                              height: "50px",
                            }}
                            defaultValue={
                              "Pathology test (02-01-2019)\nCare Plan Review (07-06-2019)\n\t\t\t\t\t\t\t"
                            }
                          /> */}
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-xl-12"
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    ></div>
                  </div>
                  <div className="row" style={{ marginLeft: "0px" }}>
                    <div className="col-md-6">
                      <div
                        className="col-xl-12"
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <div className="summarybg">
                          <p>
                            {!(this.props.patient_age < 19) && (
                              <span
                                className="col-xl-3 sbmi"
                                style={{
                                  float: "left",
                                  fontWeight: "b",
                                  paddingLeft: "0px",
                                }}
                              >
                                <b
                                  style={{ fontWeight: "", cursor: "pointer" }}
                                >
                                  Body Mass Index
                                </b>
                              </span>
                            )}
                            {this.props.patient_age < 19 && (
                              <span
                                className="col-xl-3 sbmi"
                                style={{
                                  float: "left",
                                  fontWeight: "b",
                                  paddingLeft: "0px",
                                }}
                              >
                                <b
                                  style={{ fontWeight: "", cursor: "pointer" }}
                                >
                                  {" "}
                                  Below 18 yrs
                                </b>
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-xl-12"
                        id="bmi"
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        {!(this.props.patient_age < 19) && (
                          <div
                            className="summarybg"
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            <p>
                              {" "}
                              Weight :{" "}
                              {this.state.patients_observations.length > 0 &&
                                this.state.patients_observations
                                  .filter((item) => item.observation_id == "8")
                                  .map((i) => <span>{i.value}</span>)}
                              <br />
                              Height:{" "}
                              {this.state.patients_observations.length > 0 &&
                                this.state.patients_observations
                                  .filter((item) => item.observation_id == "7")
                                  .map((i) => <span>{i.value}</span>)}
                              <br />
                              Pulse:{" "}
                              {this.state.patients_observations.length > 0 &&
                                this.state.patients_observations
                                  .filter((item) => item.observation_id == "2")
                                  .map((i) => <span>{i.value}</span>)}
                              <br />
                              Waist:{" "}
                              {this.state.patients_observations.length > 0 &&
                                this.state.patients_observations
                                  .filter((item) => item.observation_id == "3")
                                  .map((i) => <span>{i.value}</span>)}
                              <br />
                              BMI:{" "}
                              {this.state.patients_observations.length > 0 &&
                                this.state.patients_observations
                                  .filter((item) => item.observation_id == "4")
                                  .map((i) => <span>{i.value}</span>)}
                              <br />
                            </p>
                          </div>
                        )}
                      </div>
                      {this.props.patient_age < 19 && (
                        <div
                          className="col-xl-12"
                          id="b16"
                          style={{ paddingLeft: "0px", paddingRight: "0px" }}
                        >
                          <div
                            className="summarybg"
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            <p>
                              Head:{" "}
                              {this.state.patients_observations.length > 0 &&
                                this.state.patients_observations
                                  .filter((item) => item.observation_id == "6")
                                  .map((i) => <span>{i.value}</span>)}
                              <br />
                              Waist/Hip:{" "}
                              {this.state.patients_observations.length > 0 &&
                                this.state.patients_observations
                                  .filter((item) => item.observation_id == "14")
                                  .map((i) => <span>{i.value}</span>)}
                              <br />
                              Height:{" "}
                              {this.state.patients_observations.length > 0 &&
                                this.state.patients_observations
                                  .filter((item) => item.observation_id == "7")
                                  .map((i) => <span>{i.value}</span>)}
                              <br />
                              Weight:{" "}
                              {this.state.patients_observations.length > 0 &&
                                this.state.patients_observations
                                  .filter((item) => item.observation_id == "8")
                                  .map((i) => <span>{i.value}</span>)}
                              <br />
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="summarybg" style={{ marginTop: "10px" }}>
                        <p>
                          <span
                            className="col-xl-4"
                            style={{ float: "left", paddingLeft: "0px" }}
                          >
                            <b>Referred By</b>
                          </span>
                          <span
                            className="col-xl-4"
                            style={({ float: "left" }, { marginLeft: "20px" })}
                          ></span>
                          <span style={{ float: "" }}>
                            {/* <a href="#" title="Add">
                              <i
                                className="fas fa-plus-square"
                                aria-hidden="true"
                                style={{ fontSize: "14px", marginRight: "5px" }}
                              />
                            </a> */}
                            <a
                              href="#"
                              title="Modify"
                              onClick={(e) => this.openReferedBy()}
                            >
                              <i
                                className="fas fa-edit"
                                aria-hidden="true"
                                style={{ fontSize: "14px", marginRight: "5px" }}
                              />
                            </a>
                            {/* <a href="#" title="Delete">
                              <i
                                className="fas fa-trash-alt"
                                aria-hidden="true"
                                style={{ fontSize: "14px", marginRight: "5px" }}
                              />
                            </a> */}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getSummaryData: (data) => dispatch(api_getSummaryData(data)),
    getSummaryCareplan: (data) => dispatch(api_getSummaryCareplan(data)),
    getSummaryInvestigations: (data) =>
      dispatch(api_getSummaryInvestigations(data)),
    getSummaryObservations: (data) =>
      dispatch(api_getSummaryObservations(data)),
    api_summary_billing: (data) => dispatch(api_summary_billing(data)),
    investigation_master: () => dispatch(api_investigations_master()),
  };
};

const mapStateToProps = (state) => {
  let redux_patientId =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].patient_id
      : "";
  let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : [];
  let selectedPatientDetails;
  let investigation_master_data = state.getObservations.investigations_master
    ? state.getObservations.investigations_master
    : [];

  let patient_age =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].age
      : "";
  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";

  if (patientDetails.length > 0) {
    selectedPatientDetails = patientDetails[0];
  } else {
    selectedPatientDetails = "";
  }
  return {
    redux_patientId,
    selectedPatientDetails,
    patient_age,
    investigation_master_data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
