import React, { Component } from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import
{
  api_getPatientDetails,
 
} from '../../../DS/DS.Clinical';
import {api_getDoctors,api_getTimeslots} from '../../../DS/DS.Appointments'
import Showhelp from './../../showHelp';

class CreateAppointment extends Component {
  constructor(props)
  {
    super(props);
    this.state = {
        open:this.props.showForm,
        patientsData:[],
        calendardate:new Date(),
        doctors: [],
        timeslots: [],
        newslots:[]
        
     };
  }
  componentWillMount=()=>
  {
    this.props.apiGetPatientDetails({patient_id:this.props.sid}).then(patdata=>{
        console.log(patdata)
       this.setState({patientsData:patdata.data.content})
      });
      this.props.getDoctors().then(doctors => {
        this.setState({ doctors: doctors.data.content,doclength:doctors.data.content[0].minutes_per_appointment });
        this.calculate(doctors.data.content[0].minutes_per_appointment)
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",doctors.data.content[0].minutes_per_appointment)
        
      });
      
  }
  handleDate = date => {
    console.log(date);
    this.setState({
      calendardate: moment(new Date(date),'YYYY/MM/DD')
    });
}
  
calculate=(mpa)=>
{
  this.props.getTimeslots().then(timeslots => {
         
    for(let i = 0; i < timeslots.data.content.length; i++)
    {
      let newtime=parseInt(timeslots.data.content[i].full) + parseInt(mpa)
      this.state.newslots.push({'newtime':newtime})
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>',mpa)
    }
    this.setState({ timeslots: timeslots.data.content });
  });
}
  render() {
    return(
        <Popup
        modal
         open={this.state.open}
         closeOnDocumentClick={false}>
          {close => (
               <div style={{ width:'800px', margin: '0px auto'}}>
               <div style={{ width:'800px', marginLeft:'25px', backgroundColor:'#FFF',float:'left'}}>
                    <div className="modal-header">
                      <div class="popup-title" style={{textAlign:'left'}}>
                        <button type="button" className="close"  onClick={() => {this.props.changeKey();}} data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                        <span>Make Appointment <Showhelp gotohelp="make_appointment_help"/></span>
                      </div>
                    </div>
                    <div className="modal-body"   style={{overflowY: "scroll", height: "650px" }}>
                        <div className="col-md-12">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                { this.state.patientsData !=undefined && this.state.patientsData.map((item,i) => (
                                    <label style={{marginLeft:'100px'}}> {item.Firstname}{" "}{item.Surname}</label>
                                    ))}
                            </div>
                            <div className="col-md-4"></div>    
                        </div> 
                        <div className="col-md-12">
                            <div className="col-md-1"><label>Date</label></div>
                            <div className="col-md-4">
                                    <DatePicker
                                                className="datePicker"
                                                value= {this.state.calendardate}
                                                selected={
                                                this.state.dateShow 
                                                ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                : Date.parse(this.state.calendardate)
                                                }
                                                onChange={this.handleDate}
                                              />    
                            </div>
                            <div className="col-md-3">
                                 <select className="form-control mt-0" onChange={(e)=>this.setState({location:e.target.value})} value={this.state.location}>
                                        <option value="All">All</option> 
                                        <option value="Main Surgery">Main Surgery</option>        
                                 </select>               
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="col-md-12">
                              <table className="table dx-g-bs4-table dx-g-bs4-table-sticky dx-g-bs4-table-head">
                              { this.state.doctors !=undefined && this.state.doctors.map((item,i) => (
                                  <tr>
                                      <td style={{ paddingLeft:'10px' }}>
                                          Dr.{item.Firstname}{'.'}{item.Surname}
                                      </td>
                                  </tr>
                                  ))}
                              </table>
                            </div>
                            <div className="col-md-12" >
                            <table className="table dx-g-bs4-table dx-g-bs4-table-sticky dx-g-bs4-table-head makeapp" style={{border:'0px'}}>
                                  <tr>
                                      <td style={{border:'0px'}}>
                                        { this.state.timeslots !=undefined && this.state.timeslots.map((item,i) => (
                                       <label className="btn btn-o btn-sm btn-rnd btn-blue">
                                       {item.full}
                                       </label>
                                       ))}
                                      </td>
                                  </tr>
                                  
                              </table>
                            </div>                    
                        </div>

                    </div> 
                </div>
                </div>
          )}
          </Popup>   
        )
    }
  }
  const mapDispatchToProps = dispatch => {
    return {
      apiGetPatientDetails : data => dispatch(api_getPatientDetails(data)),
      getDoctors: () => dispatch(api_getDoctors()),
      getTimeslots: () => dispatch(api_getTimeslots()),
     };
  };
  
  const mapStateToProps = state => {
      let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
    return {
      redux_docId
    };
  };
  export default connect(
      mapStateToProps,
    mapDispatchToProps
  )(CreateAppointment);