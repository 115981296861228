import React, { Component } from "react";
import Form from "react-jsonschema-form";
//import axios from "axios";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { editForm } from "../../../DS/services";
class editform extends Component {
  state = {
    schema: {},
    formData:{}
  };
  onSubmit = ({ formData }, e) => {
    let postBodyData = {
      formData: formData,
      prop: this.props.form
    };
    editForm(postBodyData)
    .then(res => {
      if(res.status === 200){
      
       
      if(this.props.form.isBacktoGridOnSubmit == true){
        this.props.backtogrid();
      }
      NotificationManager.success(this.props.form.successMessage);
      }
        else {
         NotificationManager.error("Something went to Wrong, Try again later");
        }
     })
  };

  render() {
    const uiSchema = this.props.form.ui_schema;
    const log = type => console.log.bind(console, type);
    let formData = this.props.formData;
    return (

      <div>
          <NotificationContainer/>
        <Form
          schema={this.props.form.schema}
          uiSchema={uiSchema}
          onChange={log("changed")}
          onSubmit={this.onSubmit}
          onError={log("errors")}
          formData = {formData}
        />
      </div>
    );
  }
}

export default editform;
