import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { api_getUserInfo, api_setLoggedInfoRedux } from '../DS/DS.Users';

export class AuthRequired extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: 'not_checked',
    };
  }

  componentWillMount() {
    this.props.api_getUserInfo().then(loginUser => {
      console.log("logindata",loginUser);
      this.props.setLoggedInfoRedux(loginUser);
 
      this.setState({
        loggedIn:
          loginUser && loginUser.status === 'success'
            ? 'valid_user'
            : 'invalid_user',
      });
    });
  }

  render() {
    if (this.state.loggedIn === 'invalid_user') {
      window.location = '/';
      
    } else if (this.state.loggedIn === 'valid_user') {
      
      return this.props.orRender;
    } else {
      return (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    api_getUserInfo: () => dispatch(api_getUserInfo()),
    setLoggedInfoRedux: data => dispatch(api_setLoggedInfoRedux(data)),
  };
};
export default connect(null, mapDispatchToProps)(AuthRequired);
