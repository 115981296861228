import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import moment from 'moment';
import axios from 'axios'
import { connect } from 'react-redux';
import Addaccount from "./Addaccount";
import AddNotes from "./AddNotes";
import Feeammount from "./Feeammount";
import DatePicker from 'react-datepicker';
import { patients_visit_reason ,api_addVisitNote} from './../../../DS/DS.VisitsNotes';

import {
    api_get_account,
    api_getDoctors, 
  } from './../../../DS/DS.Appointments';
import patients from '../managepatients/patients';
import addNotes from './AddNotes';
import Editpatient from './Editpatient';
const users = [
  {id: 1, name: 'Surgery Consultation,Level A',GST:'NO'},
  {id: 2, name: 'Surgery Consultation,Level B',GST:'NO'},
  {id: 3, name: 'Surgery Consultation,Level C',GST:'NO'},
  {id: 4, name: 'Surgery Consultation,Level D',GST:'NO'},
];
class Select extends React.Component {
  constructor(props){
    super();
    this.state = {  
    };
  }
 
  render(){
    return (
      <div>
        <ul id="lang" key={this.props.tech}  id={this.props.tech}>
          <li onClick={this.handleChange.bind(this)}  id="Angular">Angular</li>
          <li onClick={this.handleChange.bind(this)} id="Bootstrap">Bootstrap</li>
          <li onClick={this.handleChange.bind(this)} id="React">React</li>
        </ul>
      
        <h2>{this.props.tech1}</h2>
        <button onClick={this.handleClick.bind(this)} id="React">React</button>
      </div>
    )
  }
}
class AccountDetails extends Component {
    constructor(props){
        super(props);
    this.state = {
        account_det: [],
        visit_reason:'',
        doctor:'',
        City:'',
       
        doctors:[],
        doctor_id:'1',
        Firstname:'',
        startDate: new Date(),
    selectedValue: null,
    patient_id: '1',
    City:'',
    medicare_no:'',
    time: '10:00',
     random : 10 }}
  onChange = () => {
    this.setState({
Firstname:this.state.Firstname,
});
this.removeItem = this.removeItem.bind(this);
}
     componentDidMount(){
        this.props.getDoctors({doctor_id:this.state.doctor_id}).then(doctors=>{
            this.setState({doctors: doctors.data.content});
          });

        this.props.get_account({patient_id:this.state.patient_id}).then(res => {
          this.setState({account_det: res.data.content},
            () =>  console.log(this.state.account_det))
        }
          );
         
          this.props.getPatientsVisitReason({ params: {patient_id: this.state.patient_id}}).then(rsnlist => {
            this.setState({patient_visit_reason: rsnlist.data.content });
            console.log( rsnlist.data.content);
           });
         
        }
        submiteVisitNote() {
            const visiData={
              vd_visittype:this.state.selected_visit_type,
              vd_reason:this.state.visit_reason,
              vd_visitnote:this.state.visit_note,
              vd_patient:this.state.patientId,
              vd_doctor:1,
              vd_appointment:1,
              vd_careitem:'237',
              vd_reviewdate:this.state.date,
              vd_reviewtime:this.state.hours,
              vd_confidential:1,
              vd_deleted:1
      
            }
            this.props.apiAddVisitnote(visiData);
        }
      refresh(){
        this.props.get_account().then(res => {
          this.setState({account_det: res.data.content,})}
          );
      }
      getChckeboxValue(event) {
        console.log('called');
        if(event.target.checked== true){
          this.setState({selectedValue :event.target.value});
          console.log(this.state.selectedValue);
        }
      }
      static defaultProps = {
        selected: false
      }
     
      removeItem(index, e) {
       /* let id = arguments[0];

    this.setState({
      users: this.state.users.filter(item => {
        if (item.id !== id) {
          return item;
        }
      })
    });*/
      }
    render() { 
      const { selected, id, name, handleSelect } = this.props;
      //console.log(`render TableRow :: ${id} :: ${name}`);
      const users = [
        {id: 1, name: 'Surgery Consultation,Level A'},
        {id: 2, name: 'Surgery Consultation,Level B'},
        {id: 3, name: 'Surgery Consultation,Level C'},
        {id: 4, name: 'Surgery Consultation,Level D'},
      ];
        return ( 
            <Popup trigger={ <a title="AccountDetails"  className="collapsed btn btn-default" style={{ marginRight: "10px" }} aria-expanded="false">Select</a>} position="right center" modal
            closeOnDocumentClick={false} contentStyle={{height:'80%',width:'70%', overflowY:'scroll'}}
            >
             {close => (
                 <div>
                        <div className="popup-content" style={{width: '100%'}}>
                     <div className="modal-header" style={{width: '100%', backgroundColor: '#f4f4f4', padding: '5px 15px'}}>
                      
                      <button type="button" className="c
                      lose" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <h4 style={{backgroundColor: '#f4f4f4', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>AccountDetails</h4>
                    </div>
                    { this.state.account_det !=undefined && this.state.account_det.map((item,i) => (                    

                    <div className="modal-body">
  
                <div className="col-sm-12 ">
              
                            <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Invoice date</label>
                        </div> 
                        <div className="col-sm-2 " style={{float: 'left',marginTop: '15px'}}>
                            <input type="text" style={{width: '90px'}} value={moment().format('DD/MM/YYYY')} />
                        </div>

                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px',width:'150px'}}>Invoice No:</label>    
                        </div> 
                        <div className="col-sm-1 " style={{float: 'left',marginTop: '15px'}}>
                            <input type="text" style={{width: '70px'}} value={this.state.random} />
                        </div>

                        <div className="col-sm-1 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px',marginRight:'250px'}}>Location:</label>    
                        </div> 
                        <div className="col-sm-2 " style={{float: 'left',marginTop: '15px'}}>
                            <select style={{width:"100%",height:"25px"}} onChange={(e) => this.setState({ City: e.target.value })}>
                                <option value={() => this.setState({ City: item.City })}>{item.City} </option>
                                <option> </option>
                            </select> 
                        </div>
                        
                        <div className="col-sm-2 " style={{float: 'left',marginTop: '15px'}}>
                        <button type="text" className="btn btn-default" style={{}}>Use last Details</button>
                        </div>

                        </div>
            <div className="col-sm-12 ">
                            <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Provider:</label>
                        </div> 
                        <div className="col-sm-2 " style={{float: 'left',marginTop: '15px'}}>
                        <select style={{width:"100%",height:"25px"}}onChange={(e) => this.setState({ Firstname: e.target.value })}>
                            { this.state.account_det !=undefined && this.state.doctors.map((doctor) => (

                                <option> {doctor.Firstname}</option>
                                ))}
                            </select>  
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Service date</label>
                        </div>

                        <div className="col-sm-2 " style={{float: 'left',marginTop: '15px'}}>
                        <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                          />                    
                              </div>
            
                        </div>
            <div className="col-sm-12 ">
                            <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Bill to:</label>
                        </div> 
                        <div className="col-sm-2 " style={{float: 'left',marginTop: '15px'}}>
                           <select style={{width:"100%",height:"25px"}}>
                                <option></option>
                              
                            </select> 
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input type="text" style={{width: '70px'}} placeholder="Search" />
                        </div>

                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Bill to:</label>
                        </div> 

                        <div className="col-sm-4 " style={{textAlign:'left'}}>
    <label style={{marginTop: '15px'}}>{this.state.Firstname}</label>
                       
                            </div> 
                </div>

                    <div className="col-sm-12 ">
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Billing Schedule:</label>
                        </div> 
                        <div className="col-sm-2 " style={{float: 'left',marginTop: '15px'}}>
                            <select style={{width:"100%",height:"25px"}}>
                                <option>Rebate only</option>
                            </select> 
                        </div>
                        <div className="col-sm-2 " >
                    </div>
                 </div>
                 <div className="col-sm-12 ">
                 <div className="col-sm-2 " style={{textAlign:'left'}}>
                     
                            <label style={{marginTop: '15px'}}>Patient:</label>
                        </div> 

                        <div className="col-sm-3 " style={{textAlign:'left'}}>          
                            <h5 style={{marginTop: '15px'}} >{item.Firstname}</h5>
                        </div> 
                        <div className="col-sm-1 "></div>
                        <div className="col-sm-3 " style={{textAlign:'left'}}>
                          <Editpatient/>

                        </div> 
                        <div className="col-sm-3 "></div>
                       
                        </div> 
            <div className="col-sm-12 ">
                     <div className="col-sm-6 " style={{textAlign:'left'}}></div>
                     <div className="col-sm-2 " style={{textAlign:'left',paddingBottom:'40px'}}>
                     <div className="col-sm-1 "></div>
                        <button className="btn btn-default" style={{width: '200px', marginTop:'10px'}} >Verify Medicare/DVA Elidibility</button>
                        </div>      
                 </div>

            <div className="col-sm-12 ">
            <div className="col-sm-1 ">

          <Addaccount selectedValue= {this.state.selectedValue}/>
        
                 </div> 
              
            <div className="col-sm-1 ">
            <button type="button" className="btn btn-default" style={{width: '90px',marginLeft:'30px'}}
                  >Delete Item</button>
            </div>
            <div className="col-sm-1 ">
                 <button type="button" className="btn btn-default"  style={{width: '90px',marginLeft:'60px'}} >Vary Item</button>
            </div>
            <div className="col-sm-2 " style={{textAlign:'center',marginLeft:'80px',float: 'left',width: '150px'}}>
                            <label>No. of Patients:</label>
                        </div> 
                        <div className="col-sm-1" style={{float: 'left'}}>
                        <input type="text" style={{width: '40px'}} value="1" />
                        </div>
                        <div className="col-sm-2" style={{float: 'left',width: '140px'}}>
                           <input type="checkbox"  /> Apply multiple operation rules
                        </div>
                        <div className="col-sm-2" style={{float: 'left',width: '180px'}}>
                           <input type="checkbox"  /> diagnosis imaging rules &nbsp;
                        </div>
               </div>        
               <div className="col-sm-12 " style={{marginTop: '10px'}}></div>
                 <div className="col-sm-12 " style={{paddingRight: '0px', paddingLeft: '0px', height: '180px', border: '1px solid #ccc'}}>
        <table style={{width: '870px',marginLeft:'5px',marginTop:'5px'}}>   
            <thead> 
           <tr style={{width: '100%'}}>
               <th>Date</th>
               <th>MBS item</th>
               <th>Description</th>
               <th>Amount</th>
               <th>GST</th>
               <th>Total</th>
           </tr>
           </thead>        
          <tbody style={{border: 'none'}}>
          <tr>

               <input type="checkbox"  value= {item.patients_account_id} 
                
                onChange={e => this.getChckeboxValue(e)}/>{item.account_date}
               <td>3/12/2019</td>
                 <td><Feeammount/></td>
                 <td>Descr</td>
               <td>37.60</td>
               <td>0.00</td>
               </tr>
               {
            users.map((item) => (
               <tr onClick={() => this.removeItem(this, item.id)} >  
               <input type="checkbox"  value= {item.patients_account_id}  onChange={e => this.getChckeboxValue(e)}/>{item.account_date}
               <td>3/12/2019</td>
                 <td>{name}</td>
                 <td style={{alignItems:'center'}}>Descr</td>
               <td>37.60</td>
               <td>0.00</td>
              
               </tr>
              ))  }
               </tbody>  
          </table> 
              </div>          
                 <div className="col-sm-12 ">
                          <div className="col-sm-2 " style={{textAlign:'left' ,marginTop: '5px'}}>
                            <label style={{marginTop: '5px'}}>Visit Duration</label>
                          </div>
                          <div className="col-sm-1 " style={{textAlign:'left',marginTop: '5px'}}>
                            <input type="text" style={{width: '150px'}} placeholder="" />
                          </div> 
                        
                        <div className="col-sm-3" style={{float: 'left',marginLeft:'190px',width: '200px'}}>
                           <input type="checkbox"  /> Not normal aftercase &nbsp;
                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginLeft:'40px',width: '200px'}}>
                           <input type="checkbox"  />In hospital &nbsp;
                        </div> 
                 </div>
               
                 <div className="col-sm-12 " style={{marginTop: '15px'}}>
                          <div className="col-sm-2 " style={{textAlign:'left' ,marginTop: '5px'}}>
   
                           <label style={{marginTop: '5px'}}>Notes from provider</label>
                         </div>
                         { this.state.patient_visit_reason !=undefined && this.state.patient_visit_reason.map((Vrsn) => (
                          <div className="col-sm-3 " style={{textAlign:'left',marginTop: '5px'}}>
                            <textarea style={{overflowY:'scroll',height:'60px'}} value={Vrsn.visit_type}/>                           
                        </div>
                          ))}                     
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                           <label style={{marginTop: '5px'}}>Notes:</label> 
                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginRight:'70px'}}>
                        
                        <h4>{this.props.tech}</h4>
                        </div>
                        <div className="col-sm-1 " style={{textAlign:'left'}}>
                           <AddNotes/>
                         </div>
                         <div>
                         
                        </div>
                        <div className="col-sm-12 " style={{marginBottom:'5px',paddingBottom:'10px'}}>
                        <div className="col-sm-4 " style={{textAlign:'left'}}></div>
                        <div className="col-sm-2 " style={{textAlign:'left',width:'190px'}}>
                        <input type="checkbox"  /> Print Medicare claim form &nbsp;
                        </div> 
                        <div className="col-sm-2 " style={{textAlign:'left',width:'180px'}}>
                        <input type="checkbox"  /> Do another account &nbsp;
                        </div> 
                        <div className="col-sm-2 " style={{textAlign:'left',width:'180px'}}>
                        <input type="checkbox"  /> Open Billing History &nbsp;
                        </div> 
                        </div>
                   </div>               
             </div>
 ))}
                 <div className="modal-footer"  style={{marginTop: '710px'}}>
                 <button type="button" className="btn btn-default" style={{marginRight:'2px',width:'70px',height:'35px'}} >Pay now</button>
                      <button type="button" className="btn btn-default" style={{marginRight:'2px',width:'80px',height:'35px'}} >Print</button>
                      <button type="button" className="btn btn-default" style={{marginRight:'3px',width:'70px',height:'35px'}} >Store</button>
                      <button type="button" className="btn btn-default" style={{marginRight:'3px',width:'70px',height:'35px'}} >Hold</button>
                      <button type="button" className="btn btn-default" style={{marginRight:'3px',width:'70px',height:'35px'}} onClick={()=>close()} >Cancel</button>
 </div>
     </div>

      </div>
     
)}
</Popup>
         );
    }
}
 
const mapDispatchToProps = (dispatch) => {
	return {
    get_account: data => dispatch(api_get_account(data)),
    getDoctors: () => dispatch(api_getDoctors()), 
    getPatientsVisitReason: patientid => dispatch(patients_visit_reason(patientid)),
    apiAddVisitnote: data => dispatch(api_addVisitNote(data)),

  }
}
export default connect (null, mapDispatchToProps) (AccountDetails);

