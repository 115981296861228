
import { OBSERVATIONS_MASTER, PATIENTS_OBSERVATIONS, INVESTIGATIONS_MASTER, PATIENTS_INVESTIGATIONS } from '../DS/DS.Observations';
import { LOGGED_INFO } from '../DS/DS.Users';
import {wflow} from './../DS/general';
const initialState = {
  observations_master: [],
  patient_observations: [],
  investigations_master: [],
  patient_investigations: [],
  logged_info: []
} 
//setting up data to state
export default function auth(state = initialState, action = {}) {
 // console.log("reducer", action);
  wflow("redux state", initialState);
  switch (action.type) {
    
      case OBSERVATIONS_MASTER: {
        return {...state, observations_master: action.obvMast_data}
      }
      break;
      case PATIENTS_OBSERVATIONS: {
        return {...state, patient_observations: action.patientsObservations_data}
      }
      break;
      
      case INVESTIGATIONS_MASTER: {
        return {...state, investigations_master: action.invMast_data}
      }
      break;
      case PATIENTS_INVESTIGATIONS: {
        return {...state, patient_investigations: action.patientsInvestigations_data}
      }
      break;

      case LOGGED_INFO: {
        return {...state, logged_info: action.logged_data}
      }
      break;
      

    default:
      return state;
    }
}