import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/img/supadoc-logo-l1.png';
import productInfo from 'assets/img/word-m.png';
import reminderInfo from 'assets/img/alarm-clock.png';
import investigationInfo from 'assets/img/list1.png';
import followincomingInfo from 'assets/img/follow-up-inbox.gif';
import incomingInfo from 'assets/img/report.gif';
import upinboxInfo from 'assets/img/mail-icon2.gif';
import patienttopInfo from 'assets/img/list.png';
import MyList from './patientHistory/visitNotes/myList';
import { api_logout } from '../DS/DS.Users';
import Applist from './appointment/appList';
import { connect } from 'react-redux';
import SearchAll from './searchAll';
import Popup from "reactjs-popup";
import ReactDOM from 'react-dom';
import ReminderTop from '../components/patientHistory/reminders/remindersTop';
import InvestigationReport from './patientHistory/investigation/investigationReport';
import MailAssignment from './patientHistory/inbox/mailAssignment';
import Inbox from './patientHistory/inbox/inbox'
import EnlistPatient from './headerView/enlistPatient';
import EnlistAccount from './headerView/enlistAccount';
import EnlistContact from './headerView/enlistContact';
import { wflow, wip } from "./../DS/general";
import {get_appointment_details,api_setSelectedThisAppointment,api_getPatientDetails} from './../DS/DS.Appointments';
import IncomingReports from './patientHistory/incomingReports/incomingReports';
class Header extends Component {
  constructor(props)
  {
    super(props);
    this.state = {
      applist : false,
      searchedVal:'',
      searchOn:false,
      key:0,
      patients:[],
      patientsData:[],
      searchPatientId:"",
      query: '',
      queryData:'',
      pName:0,
      showSearchModal: false,
      searchHeading:"",
      aUrl:"",
      htmlData:[],
      appliston:false,
      showreminder:false,
      showMailAssignment:false,
      showinbox:false,
      openPatients:false,
      openAccounts:false,
      openContacts:false,
      addAppointmentTab: false,
      showPatientDetails: false,
      showincoming:false
    };
  }
  triggerPatientDetails = (appdetails) => {
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>',appdetails)
    setTimeout(
      function() {
        //Start the timer
        wflow(
          "trigger patient details appointment format",
          appdetails
        );
        this.getColumnProp(
          null,
          appdetails,
          null,
          null,
          null
        );
      }.bind(this),
      1000
    );
  };
  getColumnProp(e, thisappointment, date, time, doctor_id) {
    wflow("getcolumn prop", thisappointment);
    
    wflow("get column props ", thisappointment);
    if (thisappointment.length != 0) {
      this.state.showPatientDetails = true;
      this.state.addAppointmentTab = false;
      this.state.editAppointmentDetails = false;
      this.setState({ selected_thisappointment: thisappointment });

      this.props.setSelectedThisAppointment(thisappointment);
      this.setState({ tabshow: "" });
      this.props.getPatientDetails({ patient_id: thisappointment });
      this.handleScrollToElement()
    } else {
      this.state.showPatientDetails = false;
      this.state.addAppointmentTab = true;
      this.state.editAppointmentDetails = false;
    }

    wflow(
      "CTYPE:DATE selected cell item properties",
      thisappointment,
      date,
      time,
      doctor_id
    );
    //console.log(thisappointment, date, time, doctor_id);

    var selectedTableProp = { ...this.state.selectedTableProp };
    selectedTableProp.date = date;
    selectedTableProp.time = time;
    selectedTableProp.dr = doctor_id;
    this.setState({ selectedTableProp });
  }

  handleScrollToElement(event) {
    setTimeout(
      function() {
        //Start the timer
        wflow("scrolling to addtab");
        const tesNode = ReactDOM.findDOMNode(this.refs.test);
        console.log(">>>>>>>>>>>>>>>>>>>>>",tesNode)
        if (tesNode != null) {
          tesNode.scrollIntoView({
            behavior: "smooth"
          });
        }
      }.bind(this),
      2000
    );
  }
  handleInputChange = () => {      
    if(this.state.pName == 0){  
    this.setState({
      query: this.search.value
    })
    document.getElementById('searchlist').style.display = 'block';
  }else{
   // alert(this.search.value);
    this.setState({
      queryData:this.search.value
    })
    
    document.getElementById('searchData').style.display = 'block';  
  }
  }

  open() {
    this.setState({showSearchModal: true});
  }

  close() {
    this.setState({showSearchModal: false});
  }
  componentDidMount(){
    fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=get_patients`)
        .then(res => res.json())
        .then(json => {
          this.setState({            
            patients:json,            
          })
        });
  }
 
  handleMenuItemClick = (event) => {
    //alert(event.target.textContent);
    //document.getElementById('searchall').value = event.target.textContent;
    document.getElementById('searchlist').style.display = 'none';    
    document.getElementById('pName').textContent = event.target.textContent;
    document.getElementById('pName').style.display = 'block';
    document.getElementById('pName').style.cursor = 'pointer';
    document.getElementById('searchall').value = "";
    document.getElementById("searchall").focus();    
    this.state.pName = 1;
    //alert(event.target.getAttribute('id'));
    const data = new FormData();
    data.set('patient_id', event.target.getAttribute('id'));
    const options = {
      method: 'POST',
      body: data
    }
    fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=get_patients_data`,options)
        .then(res => res.json())
        .then(json => {
          this.setState({            
            patientsData:json,            
          })
        }); 
       console.log(">>>>>>>>>>>>>>>>>>>>>>>",event.target.getAttribute('id'))
    
    this.props.getAppointmentDetails({patientid:event.target.getAttribute('id')}).then(res=>
      { 
        this.triggerPatientDetails(res.data.content)
      })    
    //document.getElementById('searchData').style.display = 'block';    

  };
  
  handlepatientInfoClick = (event) => {
//alert("OMSAI"+event.target.textContent+"/"+ event.target.getAttribute('dataTab'));
    window.location.href =  "#"+event.target.getAttribute('dataTab');
    this.setState({
      searchHeading:event.target.getAttribute('dataTab')
    })

    // if(event.target.getAttribute('dataTab') == "WomenHistory"){      
    //   var aUrl = 'http://dev46.fiveminutes.in/supadoc/api/project/?api=womenHistory&action=get_whistory';          
    // }
    // else if(event.target.getAttribute('dataTab') == "Medications"){
    //   var aUrl = 'http://dev46.fiveminutes.in/supadoc/api/project/?api=womenHistory&action=get_medication';
    // }
    // else if(event.target.getAttribute('dataTab') == "Clinical Images"){
    //   var aUrl = 'http://dev46.fiveminutes.in/supadoc/api/project/?api=clinical&action=selected_patient';
    // }
    // else if(event.target.getAttribute('dataTab') == "Immunization"){
    //   var aUrl = 'http://dev46.fiveminutes.in/supadoc/api/project/?api=immunisations&action=get_immunisation';
    // }
    const apiUrl = `${process.env.REACT_APP_BASE_URL}api=womenHistory&action=get_search_detail`;
    const formData = new FormData();
    formData.append('id', event.target.getAttribute('dataId'));
    formData.append('tab', event.target.getAttribute('dataTab'));
    const options = {
      method: 'POST',
      body: formData
    }
    fetch(apiUrl, options)
        .then(res => res.json())
        .then(
          (result) => {     
            //alert(result[0].performed_date); 
            if(this.state.searchHeading == "Women History"){
            const htmlDatas = (
            // "<p>"+result[0].other_info+"</p>"+
            "<div style='padding:20px;'>"+
            "<table style='width:100%;'>"+
            "<tr><td>Date performed:</td><td>"+result[0].performed_date+"</td><td>Performed by:</td><td>"+result[0].performed_by+"</td></tr>"+
            "<tr><td>Test</td><td>"+result[0].test+"</td><td>&nbsp;</td><td>&nbsp;</td></tr>"+
            "<tr><td>Unsatisfactory specimen</td><td>"+result[0].unsatisfactory_spec+"</td><td>&nbsp;</td><td>&nbsp;</td></tr>"+
            "<tr><td>Endoservical Cells</td><td>"+result[0].endoservical+"</td><td>HPV Changes</td><td>"+result[0].hpv_changes+"</td></tr>"+
            "<tr><td>HPV 16</td><td>"+result[0].hpv16+"</td><td>HPV 18</td><td>"+result[0].hpv18+"</td></tr>"+
            "<tr><td>HPV 16/18</td><td>"+result[0].hpv+"</td><td>&nbsp;</td><td>&nbsp;</td></tr>"+
            "<tr><td>IBC Result</td><td>"+result[0].lbc_result+"</td><td>Result</td><td>"+result[0].result+"</td></tr>"+
            "<tr><td>Other information</td><td>"+result[0].other_info+"</td><td>&nbsp;</td><td>&nbsp;</td></tr>"+
            "<table>"+
            "</div>"
            );
            document.getElementById("searchDetail").innerHTML= htmlDatas;
          }else if(this.state.searchHeading == "Medications"){
            const htmlDatas = (
              "<div style='padding:20px;'>"+
              "<table style='width:100%;'>"+
              "<tr><td>Doctor:</td><td>"+result[0].doctor_name+"</td><td>&nbsp;</td><td>&nbsp;</td></tr>"+
              "<tr><td>Drug Name:</td><td>"+result[0].drug_name+"</td><td>Product Name:</td><td>"+result[0].product_name+"</td></tr>"+
              "<tr><td>Dose:</td><td>"+result[0].dose+"</td><td>Reason:</td><td>"+result[0].reason+"</td></tr>"+
              "<tr><td>Frequency:</td><td>"+result[0].frequency+"</td><td>Repeat:</td><td>"+result[0].repeat_m+"</td></tr>"+
              "<tr><td>Script Date:</td><td>"+result[0].s_date+"</td><td>Treatment Type:</td><td>"+result[0].treatment_type+"</td></tr>"+              
              "<tr><td>Reason of prescription:</td><td>"+result[0].reason_visit+"</td><td>&nbsp;</td><td>&nbsp;</td></tr>"+ 
              "<table>"+
              "</div>"
            );
            document.getElementById("searchDetail").innerHTML= htmlDatas;
          }else if(this.state.searchHeading == "Clinical Images"){
            const htmlDatas = (
              "<div style='padding:20px;'>"+
              "<table style='width:100%;'>"+
              "<tr><td>Image Name:</td><td>"+result[0].image_name+"</td><td>Image Date:</td><td>"+result[0].image_date+"</td></tr>"+
              "<tr><td>Description:</td><td>"+result[0].description+"</td><td>Details:</td><td>"+result[0].details+"</td></tr>"+
              "<tr><td>Doctor:</td><td>"+result[0].doctor_name+"</td><td>Confidential:</td><td>"+result[0].isConfidential+"</td></tr>"+
              "<tr><td>Created:</td><td>"+result[0].created_at +"</td><td>Modified:</td><td>"+result[0].modified_at+"</td></tr>"+              
              "<table>"+
              "</div>"
            );
            document.getElementById("searchDetail").innerHTML= htmlDatas;
          }else if(this.state.searchHeading == "Immunization"){
            const htmlDatas = (
              "<div style='padding:20px;'>"+
              "<table style='width:100%;'>"+
              "<tr><td>Vaccine:</td><td>"+result[0].vaccine_name +"</td><td>	diseases:</td><td>"+result[0].diseases_name+"</td></tr>"+
              "<tr><td>Sequence:</td><td>"+result[0].sequence+"</td><td>	Batch No:</td><td>"+result[0].batch_no+"</td></tr>"+
              "<tr><td>Comment:</td><td>"+result[0].comment+"</td><td>&nbsp;</td><td>&nbsp;</td></tr>"+
              "<tr><td>Created:</td><td>"+result[0].createdAt+"</td><td>	Modified:</td><td>"+result[0].modifiedAt+"</td></tr>"+
              "<table>"+
              "</div>"
            );
            document.getElementById("searchDetail").innerHTML= htmlDatas;
          }
           
            // console.log(">>>>>>>>>>>"+result[0]);
          },
          (error) => {
            alert(error);
            this.setState({ error });
          }              
        )         
        
      this.open();
  };



  handlePnameClick = (event) => {
    document.getElementById('pName').textContent = "";
    document.getElementById('pName').style.display = 'none';
    document.getElementById('searchData').style.display = 'none'; 
    document.getElementById('searchall').value = "";
    document.getElementById("searchall").focus();
    this.state.pName = 0;
  }

  toggleAppList = () => {
    this.setState({appliston: false,showreminder:false,showMailAssignment:false,showinbox:false,openPatients:false,openAccounts:false,openContacts:false,
    showincoming:false});
  }


  async performLogout() {
    await api_logout();
    window.location.reload();
  }

  globalSearch=(val)=>
  {
   
    this.setState({searchedVal:val.target.value,searchOn:true,key:Math.random()})
    val.preventDefault();
  }
  handleChangeText = e => {
    this.setState({searchedVal:e.target.value});
  };

  
  



  render() {
    var {patients} = this.state;
    var {patientsData} = this.state;
    return (
      <div>
        <header className="main-header">
          {/* Logo */}
          <div className="col-md-2 ">
            <a href="#" className="logo">
              <img src={logo} alt="Supadoc" style={{ padding: 'unset' }} />
            </a>
          </div>
          {/* Header Navbar: style can be found in header.less */}
          <div className="navbar navbar-static-top">
            {/* search form */}

            <div className="col-md-6 ">
              <form
                action="#"
                method="get"
                className="sidebar-form"
                style={{ border: 'unset', margin:'0px 0px' }}
              >
                <div
                  className="wrap-input100 input100"
                  style={{ width: '100%',position:'static' }}
                >
                 <input
                    className="input100 form-control"
                    type="text"
                    name="searchall"
                    id="searchall"
                    placeholder="Search Everything"
                    style={{
                      border: '0px',
                      color: '#666',
                      padding: '25px 0px 25px 0px',
                      background:
                        'url(dist/img/searchicon.png) 98% 10px no-repeat #ffffff',
                      fontSize: '18px',
                      borderRadius: '25px',
                      height: 'auto',
                      paddingLeft:'15px',
                      letterSpacing: '1px',
                    }}
                    ref={input => this.search = input}
                    onChange={this.handleInputChange}                    
                    autocomplete="off"
                  />
                  <p id="pName"
                  style={{
                    position:'absolute',
                    top:'28px',
                    right:'100px',
                    background:'#1ebea5',
                    borderRadius:'6px',
                    width:'auto',
                    overflow:'hidden',
                    padding:'6px 10px',
                    margin:'0',
                    display:'none',
                    whiteSpace:'nowrap',
                    color: '#ffffff',
                    fontSize:'15px',
                  }}
                  onClick={event => this.handlePnameClick(event)}
                  >&nbsp;</p>
                  <div id="searchlist"
                  style={{
                    background:'#fff',
                    maxHeight:'300px',
                    width:'100%',
                    position:'absolute',
                    left:'0',
                    top:'65px',
                    overflow:'auto',
                    border:'solid 1px #ccc',
                    padding:'20px',
                    display:'none',
                    zIndex:2,                    
                  }}
                  >
                  {/* {this.state.query} 
                  onClick={e =>{ this.handleScrollToElement();this.triggerPatientDetails()}}*/}
                  {patients.filter(patient => patient.Firstname.toLowerCase().includes(this.state.query)).map(patient=>(
                    <p 
                    style={{
                      borderBottom:'solid 1px #dfdfdf',
                      padding:'5px',
                      margin:'0',
                    
                    }}
                    >

                      <a href="javascriptt:void(0)"   id={patient.patient_id} onClick={event => this.handleMenuItemClick(event)} >
                      <i> Patient:</i> {patient.Firstname} {patient.Middlename} {patient.Surname} <i> DOB - </i>{patient.DOB} <i>Gender -</i>{patient.display} <i>Medicare no. - </i>{patient.medicare_no}</a></p>                                                                       
                                  ))}
                    
                    </div>
                    <div id="searchData"
                  style={{
                    background:'#fff',
                    maxHeight:'300px',
                    width:'100%',
                    position:'absolute',
                    left:'0',
                    top:'65px',
                    overflow:'auto',
                    border:'solid 1px #ccc',
                    padding:'20px',
                    display:'none',
                    zIndex:1,                    
                  }}
                  >
                  {/* {this.state.query} */}
                  {patientsData.filter(pdata => pdata.info.toLowerCase().includes(this.state.queryData)).map(pdata=>(
                    <p 
                    style={{
                      borderBottom:'solid 1px #dfdfdf',
                      padding:'5px',
                      margin:'0',
                      fontSize:'.9rem'
                    }}
                    >
                    <p style={{marginTop: '5px'}}><span style={{background:'#72afd2',color:'#fff',padding:'3px'}}>{pdata.tableName} </span></p>
                    <a href="javascriptt:void(0)"  dataTab={pdata.tableName} dataId={pdata.row_id} onClick={event => this.handlepatientInfoClick(event)} >
                    {pdata.info}</a>
                    <div>
                      </div>
                    </p>  
                    
                   
                    
                                  ))}
                    
                    </div>
                  <input
                    className="input100"
                    type="text"
                    name="searchpmain"
                    id="searchpmain"
                    placeholder="Search Everything"
                    style={{
                      border: '0px',
                      color: '#f4f4f4',
                      padding: '10px 0px 10px 0px',
                      background:
                        'url(dist/img/searchicon.png) right 10px no-repeat',
                      fontSize: '18px',
                      letterSpacing: '1px',
                      display:'none',
                    }}
                    value={this.state.searchedVal}
                    onKeyPress={event => {
                      if (event.key === "Enter") {
                        this.globalSearch(event);
                      }
                    }}
                    onChange={this.handleChangeText}
                  />
                  <span className="focus-input100"></span>
                </div>
              </form>
            </div>
            {/* /.search form */}
            <div className="col-md-5 " style={{ float: 'right' }}>
              <div className="navbar-custom-menu">
                <ul className="nav navbar-nav">
                  <li>
                    <a href="#">
                      <i className="fa fa-home fa-3x" style={{fontSize:'2.5em'}} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-database fa-3x" style={{fontSize:'2.5em'}} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                    <img src={productInfo} alt="Product Information" style={{width:'32px', height:'32px', marginLeft:'-5px', marginTop:'0px'}}/>
                    </a>
                  </li>
                  <li>
                    <a href="#" title="Reminder">
                    <i class="fas fa-bell fa-3x" onClick={()=>this.setState({showreminder:true})}></i>
                    </a>
                   {/*} <Link to="/patientHistory/reminders/reminders">
                    <img src={reminderInfo} title='Reminders' alt="Product Information" style={{width:'35px', height:'35px', marginLeft:'-5px', marginTop:'0px'}}/>
                  </Link>*/}
                  </li>
                 
                  <li>
                  {/*<Link to="#/investigationReports">
                    <img src={investigationInfo} title='Investigation Report' alt="Product Information" style={{width:'35px', height:'35px', marginLeft:'-5px', marginTop:'0px'}}/>
                  </Link>*/}
                  <a href="#" title="Investigation Report">
                  <InvestigationReport showGrid="0" doc_id="1" style={{fontSize:'2.5em'}}  />
                  </a>
                  </li>
                  <li>
                    <a href="#">
                    <img src={followincomingInfo} alt="Product Information" style={{width:'35px', height:'35px', marginLeft:'-5px', marginTop:'0px'}}/>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                    <img src={incomingInfo} alt="Incoming reports" style={{width:'35px', height:'35px', marginLeft:'-5px', marginTop:'0px'}}
                     onClick={()=>this.setState({showincoming:true})}/>
                    </a>
                  </li>
                  <li>
                    <a href="#" title="Inbox">
                    <img src={upinboxInfo} alt="Inbox" style={{width:'35px', height:'35px', marginLeft:'-5px', marginTop:'0px'}}
                    onClick={()=>this.setState({showinbox:true})}/>  
                    </a>
                  </li>
                  <li>
                    <a href="#" title="My List">
                    <MyList showGrid="0" doc_id="1" style={{fontSize:'2.5em'}}  />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-file-image-o fa-3x" style={{fontSize:'2.5em', marginTop:'3px'}}  />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-inbox fa-3x" onClick={()=>this.setState({appliston:true})}/>
                    </a>
                  </li>
                  <li>
                    <a href="#" title="Inbox Assignment">
                      <i class="far fa-paper-plane fa-3x" onClick={()=>this.setState({showMailAssignment:true})}></i>
                    </a>
                  </li>
                  </ul>
              </div>
            </div>
          </div>
          <span ref="test"> </span>
        </header>
        <nav className="navbar navbar-static-top navbar-dark bg-dark m-0">
          <ul className="nav navbar-nav">
            <li className="active">
              <a href="#">File</a>
            </li>
            <li>
              <a href="#">Clinical</a>
            </li>
            <li>
              <a href="#">Management</a>
            </li>
            <li>
              <a href="#">Utilities</a>
            </li>
            <li className="dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                View <span className="caret" />
              </a>
              <ul className="dropdown-menu" role="menu">
                <li>
                  <a href="#">MIMS Product Information</a>
                </li>
                <li>
                  <a href="#" onClick={(e)=>this.setState({openPatients:true})}>Patients</a>
                </li>
                <li>
                  <a href="#" onClick={(e)=>this.setState({openAccounts:true})}>Account Holders</a>
                </li>
                <li>
                  <a href="#" onClick={(e)=>this.setState({openContacts:true})}>Contact</a>
                </li>
                <li>
                  <a href="#">Travel Medicine</a>
                </li>
                <li>
                  <a href="#">My Health Record Audit</a>
                </li>
                <li>
                  <a href="#">eReferrals</a>
                </li>
                <li>
                  <a href="#">Health Link Forms</a>
                </li>
                <li>
                  <a href="#">Inbox</a>
                </li>
                <li>
                  <a href="#">Incoming Reports</a>
                </li>
                <li>
                  <a href="#">Investigation Reports</a>
                </li>
                <li>
                  <a href="#">Appointment Book</a>
                </li>
                <li>
                  <a href="#">Waitting Room</a>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                MyHealthRecord <span className="caret" />
              </a>
              <ul className="dropdown-menu" role="menu">
                <li>
                  <a href="#">MIMS Product Information</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">Setup</a>
            </li>
            <li>
              <a href="#">Help</a>
            </li>
            <li>
              <a href="#" onClick={e => this.toggleAppList(e)}> List </a>
            </li> 
            <li>
              <a href="#" onClick={() => this.performLogout()}>
                Logout
              </a>
            </li>
          </ul>
        </nav>
        {this.state.appliston ?
          <Applist open={this.state.appliston} close={e => this.toggleAppList(e)}/>
        :''}
        
       {this.state.searchOn ? 
        <SearchAll
          showbox={this.state.searchOn}
          searchfor={this.state.searchedVal}
          path={this.props.path}
          fetchval={this.handleChangeText}
          key={this.state.key}
        />:''}
        {this.state.showreminder ?
        <ReminderTop open={this.state.showreminder} close={e => this.toggleAppList(e)} /> :''}

        {this.state.showMailAssignment ?
        <MailAssignment open={this.state.showMailAssignment} closeAll={e=>this.toggleAppList(e)}/> :''}
        {this.state.showinbox ? 
        <Inbox open={this.state.showinbox} closeAll={e=>this.toggleAppList(e)}/> :''}
        {this.state.openPatients ?
        <EnlistPatient open={this.state.openPatients} closeAll={(e)=>this.toggleAppList()}/> : ''}
        {this.state.openAccounts ?
        <EnlistAccount open={this.state.openAccounts} closeAll={(e)=>this.toggleAppList()}/> : ''}
         {this.state.openContacts ?
        <EnlistContact open={this.state.openContacts} closeAll={(e)=>this.toggleAppList()}/> : ''}
        {this.state.showincoming ? 
        <IncomingReports open={this.state.showincoming} closeAll={e=>this.toggleAppList(e)}/> :''}
        <Popup
      style={{ minHeight: "500px" }}
      
      open={this.state.showSearchModal}
      close={() => this.close()}  
      
      position="right center"
      modal
      closeOnDocumentClick={false}
      contentStyle={{}}
      >

      <div>
      <div className="modal-header">
      <button
        type="button"
        className="close"
        onClick={() => this.close()}
        data-dismiss="modal"
        aria-label="Close"
      >
        {" "}
        ×
      </button>
      <span>{this.state.searchHeading}</span>
    </div>
    <div className="modal-body">
    <div id="searchDetail"></div>
        {/* {this.state.htmlData.other_info} */}
    
    </div>
        </div>
      </Popup>

      </div>     
  );
  }
}

const mapDispatchToProps = (dispatch) => {
return {
 // searchall: data => dispatch(search_all(data)),
 getAppointmentDetails:data=>dispatch(get_appointment_details(data)),
 setSelectedThisAppointment: data =>
 dispatch(api_setSelectedThisAppointment(data)),
 getPatientDetails: patient_id =>
 dispatch(api_getPatientDetails(patient_id)),
 }
}
export default connect (null, mapDispatchToProps) (Header);

//export default Header;
