import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {api_add_invoice_notes,api_update_invoice_note} from '../../../DS/DS.InvoiceNotes';
import {api_list_reason} from '../../../DS/DS.ListPage';
class AddInvoiceNote extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        errors:[], 
        notes_text:''
      }
  }
  componentWillMount=()=>
  {
      if(this.props.sid!='')
      {
        const getData={tbl:'invoice_notes_tb',pk:'notes_id',ids:this.props.sid}
          this.props.listInvoice(getData).then(
              req=>{
                  this.setState({
                      notes_text:req.data.content.note_text
                  })
              }
          )
      }
  }
  handleValidation(){
    let errors = {};
    let formIsValid = true;
  
    //Description name
    if(this.state.notes_text==''){
       formIsValid = false;
       errors["notes_text"] = "Cannot be empty";
    }
       this.setState({errors: errors});
    return formIsValid;
    //<span className="error" style={{color:'red'}}>{this.state.errors["notes_text"]}</span>
                                   
  }
  saveHandler=(e)=>
  {
      e.preventDefault();
      if(this.handleValidation())
      {
         if(this.props.sid=='')
         {

         
            const values=
            {
                note_text:this.state.notes_text
            }
            this.props.addInvoiceNotes(values).then(
                req=>
                {
                    if(req.data.status=='Duplicate')
                    {
                        NotificationManager.error('Duplicate invoice note cannot be created')
                    }
                    if(req.data.status=='Success')
                    {
                        NotificationManager.success('New invoice note created')
                    }
                    if(req.data.status=='Error')
                    {
                        NotificationManager.error('Error encountered while creating record')
                    }
                    this.props.changekey();
                }
            
            )
         }
         else if(this.props.sid!='')
         {
            const values=
            {
                reason:this.state.notes_text,
                ids:this.props.sid
            }
            this.props.updateInvoiceNote(values).then(
                req=>
                {
                    if(req.data.status=='Duplicate')
                    {
                        NotificationManager.error('Duplicate invoice note cannot be created')
                    }
                    if(req.data.status=='Success')
                    {
                        NotificationManager.success("Invoice note modified")
                    }
                    if(req.data.status=='Error')
                    {
                        NotificationManager.error('Error encountered while creating record')
                    }
                    this.props.changekey();
                }
            
            )
         }
      }
  }
render()
{
    return(
            <Popup open={this.state.open} closeOnDocumentClick={false} position="right center"  modal>
                {close => (
                <div>
                    <div className="modal-header">
                        <span className="modal-title-2" id="exampleModalLabel"> Invoice note </span>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={()=> this.props.changekey()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row row-distance">
                            <div className="col-sm-12 col-md-12 col-xl-12 m-t-10">
                                <div className="form-group">
                                    <textarea id="accute" className="contact-mapping-textarea textarea3" style={{height:'200px', padding:'5px 10px', width:'100%'}} placeholder="" value={this.state.notes_text}
                                    onChange={(e)=>this.setState({notes_text:e.target.value})}
                                    > </textarea>
                                </div>
                                <span className="error" style={{color:'red'}}>{this.state.errors["notes_text"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row">
                            <div className="col-sm-8"> </div>
                            <div className="col-sm-4">
                                <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" onClick={(e)=>this.saveHandler(e)}> Save </button>
                                <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" aria-label="Close" onClick={()=> this.props.changekey()}>Cancel</button> 
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    )}
}
const mapDispatchToProps = dispatch => {
    return {
        addInvoiceNotes: data => dispatch(api_add_invoice_notes(data)),
        listInvoice : data => dispatch(api_list_reason(data)),
        updateInvoiceNote:data=>dispatch(api_update_invoice_note(data))
     };
  };
/*  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };*/
  export default connect(null,mapDispatchToProps)(AddInvoiceNote);
