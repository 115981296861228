import React, { Component } from "react";
import Header from "../../header";
import Footer from "../../footer";
import Grid from "../../crudcomponent/combinedcrud";
import { dropDown, dropdown1 } from "../../../DS/services";
import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
import { Link } from "react-router-dom";

class sessions extends Component {
  state = {
    crud: {
      pagetitle: "Manage Sessions",
      grid: true,
      breadcrumb: {
        link: "./sessions",
        text: "Manage Sessions"
      },
     
      add_form: {
        isVisible: true,
        isBacktoGridOnSubmit: true, 
        destination: "center_session_tb",
        pk: "center_session_id",
        schema: {
          title: "Add Sessions",
          type: "object",
          //required: ["name", "city"],
          properties: {
           
            days_of_week: { type: "string", title: "Days Of Week" },
            appointment_start_time: { type: "string", title: "Appointment Start Time" },
            appointment_end_time: { type: "string", title: "Appointment End Time" },
            color: { type: "string", title: "Color" },
            appoinment_length: { type: "string", title: "Appointment Length" },
            start_date: { type: "string", title: "Start Date" },
            end_date: { type: "string", title: "End Date" },
            this_session: { type: "number", title: "This Session" },
            week_cycle: { type: "number", title: "Week Cycle" },
            cycle_start_date: { type: "string", title: "Cycle Start Date" },
            first_date_away: { type: "string", title: "First Date Away" },
            last_date_away: { type: "string", title: "Last Date Away" },
            extra_session_date: { type: "string", title: "Extra Session Date" },
            branch_id: dropDown("branches_tb", "branch_id", "name", "")
          }
        },

       
        ui_schema: {
          "mobile": {
             "ui:help": "Ex: (02) 5551 5678"
          },
          "email":{
            "ui:widget": "email",
            "ui:help" : "Enter primary contact email"
          }
        }
      },
      edit_form: {
        isVisible: true,
        isBacktoGridOnSubmit: true,
        destination: "center_session_tb",
        pk: "center_session_id",
        schema: {
          title: "Add Center",
          type: "object",
         // required: ["name", "city"],
          properties: {
            ID: { type: "number" },
            days_of_week: { type: "string", title: "Days Of Week" },
            appointment_start_time: { type: "string", title: "Appointment Start Time" },
            appointment_end_time: { type: "string", title: "Appointment End Time" },
            color: { type: "string", title: "Color" },
            appoinment_length: { type: "string", title: "Appointment Length" },
            start_date: { type: "string", title: "Start Date" },
            end_date: { type: "string", title: "End Date" },
            this_session: { type: "number", title: "This Session" },
            week_cycle: { type: "number", title: "Week Cycle" },
            cycle_start_date: { type: "string", title: "Cycle Start Date" },
            first_date_away: { type: "string", title: "First Date Away" },
            last_date_away: { type: "string", title: "Last Date Away" },
            extra_session_date: { type: "string", title: "Extra Session Date" },
            branch_id: dropDown("branches_tb", "branch_id", "name", "")
          }
        },

        ui_schema: {
          "mobile": {
             "ui:help": "Ex: (02) 5551 5678"
          },
          "email":{
            "ui:widget": "email",
            "ui:help" : "Enter primary contact email"
          },
          "ID":{
            "ui:widget": "hidden",
         }
        }
      },
      delete:{
        destination: "center_session_tb",
        pk: "center_session_id",
        isConfirmBeforeDelete: true,
        successMessage: "Successfully Deleted..!",
        errorMessage: "Something went to Wrong, Try again later",
        confirmationTitle: "Do you really want to delete?",
        confirmationMessage: "Are you sure to do this."
       
      },
      viewgrid: {
        isVisible: true,
        isPagination: true,
        isFiltering: true,
        isActions: true,

        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "center_session_tb",
          cols: ["center_session_id",
            "days_of_week", 
            "appointment_start_time",
             "appointment_end_time", 
             "color",
             "appoinment_length",
             "start_date",
             "end_date",
             "this_session",
             "week_cycle",
             "cycle_start_date",
             "first_date_away",
             "last_date_away",
             "extra_session_date",
             "center_session_tb.branch_id",
             "name"
            
            ],

            joins: [
              {
                joinType: "inner",
                joinTable: "branches_tb",
                joinCondition: "branches_tb.branch_id = center_session_tb.branch_id"
              }
            ]
             /*{
                joinType: "inner",
                joinTable: "city_tb",
                joinCondition: "city_tb.City_id = patients.City"
              },
              {
                joinType: "inner",
                joinTable: "lookup_tb",
                joinCondition: "lookup_tb.value = patients.Sex"
              }
  
            ],
            where: [
              {
                type: "and",
                key: "patients.isDeleted",
                operator: "=",
                value: "0"
              },
              {
                type: "and",
                key: "lookup_tb.field_name",
                operator: "=",
                value: "Sex"
              }
             
            ]*/
        },
        //viewtabletitle: "List of Sessions",
       //viewdropdown: "{branch_id: dropDown("branches_tb", "branch_id", "name", "")}",
        columns: [
          { name: "days_of_week", title: "Day Of Week" },
          { name: "appointment_start_time	", title: "First Appointment" },
          { name: "appointment_end_time", title: "Last Appointment" },
          { name: "appoinment_length", title: "Appointment length" },
          { name: "start_date", title: "Start Date" },
          { name: "end_date", title: "End Date" },
          { name: "week_cycle", title: "Weekly" },
          { name: "name", title: "Branch" },
          { name: "action", title: "Action" },
          
        ],
        rows: [
          
        ],
        sortinit: [{ columnName: "center_session_id", direction: "desc" }],
        pagesize: 3,
        actions: [
          {
            buttonClass: "info",
            icon: "fa-pencil",
            text: "",
            function: "edit",
            passParam: "center_session_id",
            isDefault: true
          },
          {
            buttonClass: "danger",
            icon: "fa-trash",
            text: "",
            function: "delete",
            passParam: "center_session_id",
            isDefault: true
          },
          {
            buttonClass: "primary",
            icon: "fa-eye",
            text: "",
            function: "view",
            passParam: "center_session_id",
            isDefault: false
          }
        ]
      }
    }
  };
  componentDidMount() {}
  render() {
    var gridstyle = {
      padding: "10px"
    };
    return (
      <div>
        <body
          className="skin-blue sidebar-mini"
          data-gr-c-s-loaded="true"
          style={{ height: "auto", minHeight: "100%" }}
        >
          <div
            className="wrapper"
            style={{ height: "auto", minHeight: "100%" }}
          >
            <AdminHeader />
            <Leftsidemenu />
            {/* Content Header (Page header) */}

            <div className="content-wrapper" style={{ minHeight: 314 }}>
              <section className="content-header">
                <h1>
                  {this.state.crud.pagetitle}
                  <small> {this.state.crud.subtitle} </small>
                </h1>
                <ol className="breadcrumb">
                  <li>
                    <a href="#">
                      <i className="fa fa-dashboard" /> Home
                    </a>
                  </li>

                  <li className="active">
                    <Link to={this.state.crud.breadcrumb.link}>
                      {this.state.crud.breadcrumb.text}
                    </Link>
                  </li>
                </ol>
              </section>
              {/* Main content */}
              <section className="content">
                <div className="row">
                  <div className="col-lg-12">
                 
                    <Grid
                      style={gridstyle}
                      handleButton={this.handleButton}
                      crud={this.state.crud}
                      
                    />
                   
                  </div>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </body>
      </div>
    );
  }
}

export default sessions;
