import React, { Component } from "react";

import Popup from "reactjs-popup";
import "./addPatientHistory.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { wflow } from "./../../../DS/general";
import Showhelp from './../../showHelp';

class AddPatientHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: props.patientId,
      pickList: "",
      conditions: {},
      conditionsId: {},
      left: 0,
      right: 0,
      active: 0,
      confidential: 0,
      summary: 0,
      comment: "",
      differential_diagnosis: 0,
      save_past_medical_distory: 0,
      startDate: "",
      dateShow: false
    };
  }
  handleDate = date => {
    console.log("test");
    this.setState({
      startDate: date
    });

    if (
      moment(new Date(date)).format("MM/DD/YYYY") ==
      moment(new Date()).format("MM/DD/YYYY")
    ) {
      this.setState({
        dateShow: true
      });
    } else {
      this.setState({
        dateShow: false
      });
    }
  };

  handleCheckbox = (e, data, id) => {
    let itemChecked = this.state.conditions;
    let itemCheckedId = this.state.conditionsId;
    if (!e.target.checked) {
      delete itemChecked[data];
      delete itemCheckedId[id];
    } else {
      itemChecked[data] = e.target.checked;
      itemCheckedId[id] = e.target.checked;
    }
    this.setState({
      conditions: itemChecked,
      conditionsId: itemCheckedId
    });
  };
  resetForm() {
    this.setState({
      pickList: "",
      conditions: {},
      conditionsId: {},
      left: 0,
      right: 0,
      active: 0,
      confidential: 0,
      summary: 0,
      comment: "",
      differential_diagnosis: 0,
      save_past_medical_distory: 0,
      startDate: "",
      dateShow: false
    });
  }
  handleSave = e => {
    this.props.addPaitentData(this.state).then(res => {
      this.resetForm();
    });
  };

  render() {
    return (
      <Popup
        trigger={
          <button
            id="session-timeout-dialog-logout"
            data-toggle="modal"
            data-target="#pastmedical"
            type="button"
            className="btn btn-default past-history-action-button"
            style={{
              marginTop: "20px",
              padding: "5px 10px",
              marginRight: "6px"
            }}
          >
            Add
          </button>
        }
        modal
        closeOnDocumentClick={false}
      >
        {close => (
          <div>
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() => close()}
                data-dismiss="modal"
                style={{ position: "absolute", right: "20px" }}
              >
                ×
              </button>
              <h4
                className="modal-title"
                style={{
                  float: "left",
                  width: "100%",
                  textAlign: "left",
                  fontSize: "25px"
                }}
              >
                Past Medical History <Showhelp gotohelp="past_medical_history_help"/>
              </h4>
            </div>
            <div className="modal-body past-history-wrapper" style={{height:'450px', overflowY:'scroll'}}>
              <div
                className="col-sm-12"
                style={{ paddingRight: 0, marginTop: 10, marginBottom: 10 }}
              >
                <div className="form-group row" style={{ marginTop: "20px" }}>
                  <label className="col-sm-1 col-form-label text-left">
                    Search:
                  </label>
                  <div className="col-sm-4 cursor">
                    <input
                      type="text"
                      name="search_condition"
                      className="form-control form-control-sm"
                      onChange={this.props.handleChangeText}
                      ref={input => {
                        this.nameInput = input;
                      }}
                    />
                  </div>
                  <div className="col-sm-7"></div>
                </div>
                <div className="form-group row" style={{ marginTop: 10 }}>
                  <label className="col-sm-1 col-form-label text-left">
                    Date:
                  </label>
                  <DatePicker
                    className="datePicker"
                    selected={
                      this.state.dateShow
                        ? Date.parse(moment(new Date()).format("MM/DD/YYYY"))
                        : Date.parse(this.state.startDate)
                    }
                    // selected={Date.parse(this.state.startDate)}
                    onChange={this.handleDate}
                  />
                  <div
                    style={{
                      marginRight: "-30px",
                      marginTop: "-14px",
                      float: "left"
                    }}
                    className="col-sm-1 text-right"
                  >
                    <input
                      className="checkbox1"
                      type="checkbox"
                      onChange={e =>
                        this.setState({
                          dateShow: e.target.checked
                        })
                      }
                      defaultChecked={this.state.dateShow}
                      style={{ float: "left", width: "auto" }}
                    />
                  </div>
                  <div className="col-sm-2" style={{ paddingLeft: 10 }}>
                    <input
                      type="text"
                      defaultValue={moment(new Date()).format("DD/MM/YYYY")}
                      className="form-control form-control-sm"
                      style={{ float: "left" }}
                    />
                  </div>
                </div>
                <div className="col-sm-8 m-b-0">
                  <div className="form-group row m-b-10">
                    <label className="col-sm-4 col-form-label text-right">
                      <input
                        type="radio"
                        defaultChecked
                        style={{ padding: "5px 5px" }}
                      />{" "}
                      Pick from list (coded)
                    </label>
                    <div className="col-sm-8" style={{ marginTop: "15px" }}>
                      <div className="autocomplete" style={{ width: "100%" }}>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          onChange={data =>
                            this.setState({
                              AddConditionText: data.target.value
                            })
                          }
                          style={{
                            padding: "5px 10px",
                            width: "92%",
                            float: "left"
                          }}
                        />
                        <a
                          title="Condition"
                          onClick={() =>
                            this.props.addCondition(this.state.AddConditionText)
                          }
                          className="collapsed"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-plus"
                            aria-hidden="true"
                            style={{
                              fontSize: 14,
                              marginTop: -2,
                              float: "left"
                            }}
                          />
                        </a>
                      </div>
                      <div className="col-sm-4" style={{ paddingLeft: 0 }}>
                        <div
                          style={{
                            overflowY: "scroll",
                            height: 100,
                            width: 320,
                            marginTop: 0,
                            border: "1px solid #666",
                            padding: "10px"
                          }}
                        >
                          <ul className="navpat nav-list nav-menu-list-style" style={{margin:'-4px 0px 0px 0px'}}>
                            {this.props.conditions != undefined &&
                            this.props.conditions.length > 0 ? (
                              this.props.conditions.map(condition => (
                                <li>
                                  <div
                                    className="newdesign5"
                                    style={{ height: "27px" }}
                                  >
                                    <label
                                      className="tree-toggle nav-header"
                                      style={{ paddingLeft: 0 }}
                                    ></label>
                                    <input
                                      style={{ float: "left" }}
                                      type="checkbox"
                                      onChange={e =>
                                        this.handleCheckbox(
                                          e,
                                          condition.name,
                                          condition.condition_id
                                        )
                                      }
                                    />
                                    &nbsp;{condition.name}
                                    <div
                                      style={{
                                        width: "auto",
                                        height: 16,
                                        marginTop: -9,
                                        float: "right"
                                      }}
                                    >
                                      <span className="makescreen">
                                        <a
                                          href="#"
                                          title="Edit"
                                          style={{ padding: 0 }}
                                          className="collapsed"
                                          aria-expanded="false"
                                        >
                                          <i
                                            className="fas fa-pencil-alt"
                                            aria-hidden="true"
                                            style={{
                                              fontSize: 12,
                                              marginRight: 5
                                            }}
                                            onClick={() =>
                                              this.props.editMaster({
                                                condition_id:
                                                  condition.condition_id
                                              })
                                            }
                                          />
                                        </a>{" "}
                                      </span>
                                      <i
                                        className="fas fa-trash-alt"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: 12,
                                          marginRight: 5
                                        }}
                                        onClick={() =>
                                          this.props.deletePatientId({
                                            condition_id: condition.condition_id
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <li>
                                <label className="tree-toggle nav-header">
                                  {" "}
                                  No Conditions Found
                                </label>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<div class="form-group row m-b-10">
                          <label class="col-sm-4 col-form-label text-right"><input type="radio" style="padding: 5px 10px;"> Free text(coded)</label>
                          <div class="col-sm-8">
                              <input type="text" class="form-control form-control-sm" value="" style="padding: 5px 10px; width:100%;">
                          </div>
                      </div>*/}
                  <div className="form-group row m-b-10">
                    <label className="col-sm-4 col-form-label text-left">
                      {" "}
                      &nbsp;
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="checkbox"
                        onChange={e => {
                          if (e.target.checked) this.setState({ left: 1 });
                        }}
                        style={{ margin: "8px 0px 3px 0px", float: "left" }}
                        defaultChecked={this.state.left === 0 ? false : true}
                      />
                      <span
                        style={{ marginTop: 5, marginRight: 10, float: "left" }}
                      >
                        &nbsp; Left
                      </span>{" "}
                      <input
                        type="checkbox"
                        onChange={e => {
                          if (e.target.checked) this.setState({ right: 1 });
                        }}
                        defaultChecked={this.state.right === 0 ? false : true}
                        style={{ margin: "8px 0px 3px 0px", float: "left" }}
                      />
                      <span
                        style={{ marginTop: 5, marginRight: 10, float: "left" }}
                      >
                        &nbsp; Right
                      </span>
                    </div>
                  </div>
                  <div className="form-group row m-b-10">
                    <label className="col-sm-4 col-form-label text-left">
                      {" "}
                      &nbsp;
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="checkbox"
                        onChange={e => {
                          if (e.target.checked) this.setState({ active: 1 });
                        }}
                        defaultChecked={this.state.active === 0 ? false : true}
                        style={{ margin: "8px 0px 3px 0px", float: "left" }}
                      />
                      <span
                        style={{ marginTop: 5, marginRight: 10, float: "left" }}
                      >
                        &nbsp; Active
                      </span>
                      <input
                        type="checkbox"
                        onChange={e => {
                          if (e.target.checked)
                            this.setState({ confidential: 1 });
                        }}
                        defaultChecked={
                          this.state.confidential === 0 ? false : true
                        }
                        style={{ margin: "8px 0px 3px 0px", float: "left" }}
                      />
                      <span
                        style={{ marginTop: 5, marginRight: 10, float: "left" }}
                      >
                        &nbsp; Confidential
                      </span>
                      <input
                        type="checkbox"
                        onChange={e => {
                          if (e.target.checked) this.setState({ summary: 1 });
                        }}
                        defaultChecked={this.state.summary === 0 ? false : true}
                        style={{ margin: "8px 0px 3px 0px", float: "left" }}
                      />
                      <span
                        style={{ marginTop: 5, marginRight: 10, float: "left" }}
                      >
                        &nbsp; Summary
                      </span>
                    </div>
                  </div>
                  <div className="form-group row m-b-10">
                    <label className="col-sm-4 col-form-label text-right">
                      {" "}
                      &nbsp; Comment
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        style={{
                          float: "left",
                          width: "100%",
                          height: 100,
                          border: "1px solid #cccccc"
                        }}
                        onChange={e =>
                          this.setState({ comment: e.target.value })
                        }
                        value={this.state.comment}
                      />
                    </div>
                  </div>
                  <div className="form-group row m-b-10">
                    <div className="col-sm-4 col-form-label text-right">
                      {" "}
                      &nbsp;
                    </div>
                    <div className="col-sm-8">
                      <input
                        type="checkbox"
                        style={{ margin: "8px 0px 3px 0px", float: "left" }}
                        onChange={e => {
                          if (e.target.checked)
                            this.setState({ differential_diagnosis: 1 });
                        }}
                        defaultChecked={
                          this.state.differential_diagnosis === 0 ? false : true
                        }
                      />
                      <span
                        style={{ marginTop: 5, marginRight: 10, float: "left" }}
                      >
                        &nbsp; Differential diagnosis
                      </span>
                      <input
                        type="checkbox"
                        onChange={e => {
                          if (e.target.checked)
                            this.setState({ save_past_medical_distory: 1 });
                        }}
                        defaultChecked={
                          this.state.save_past_medical_distory === 0
                            ? false
                            : true
                        }
                        style={{ margin: "8px 0px 3px 0px", float: "left" }}
                      />
                      <span
                        style={{ marginTop: 5, marginRight: 10, float: "left" }}
                      >
                        &nbsp; Save in Past Medical History
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col-sm-4 col-md-4 col-xl-4 m-b-0"
                  style={{ paddingLeft: 0 }}
                >
                  <div
                    className="panel panel-default"
                    style={{ float: "left", width: "100%" }}
                  >
                    <div
                      className="panel-heading"
                      style={{ float: "left", width: "100%", padding: 5 }}
                    >
                      <div
                        className="col-lg-12 col-xl-12"
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                      >
                        <h6
                          className="panel-title"
                          style={{
                            float: "left",
                            width: "100%",
                            fontWeight: "bold",
                            display: "inline",
                            marginRight: 10
                          }}
                        >
                          <a
                            href="#"
                            style={{ float: "left", fontSize: 14 }}
                            className="collapsed"
                            aria-expanded="false"
                          >
                            Condition
                          </a>
                        </h6>
                        <div
                          style={{
                            width: "auto",
                            height: 30,
                            position: "absolute",
                            right: 0,
                            top: 2
                          }}
                        >
                          <a
                            href="#"
                            title="Save"
                            className="collapsed"
                            aria-expanded="false"
                          >
                            <i
                              className="fa fa-save"
                              aria-hidden="true"
                              style={{ fontSize: 12, marginRight: 5 }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <textarea
                    placeholder="For Doctor's Use"
                    style={{
                      height: 248,
                      width: "100%",
                      marginTop: "-20px",
                      border: "1px solid #cccccc",
                      marginBottom: 0,
                      padding: 10
                    }}
                    //
                    value={Object.keys(this.state.conditions)}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-xs"
                onClick={() => {
                  this.handleSave();
                  close();
                }}
                style={{ padding: "6px 10px", fontSize: "14px" }}
                disabled={
                  Object.keys(this.state.conditions).length === 0 &&
                  !this.state.dateShow
                    ? "disabled"
                    : ""
                }
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
                onClick={() => {
                  this.handleSave();
                }}
                disabled={
                  Object.keys(this.state.conditions).length === 0 &&
                  !this.state.dateShow
                    ? "disabled"
                    : ""
                }
              >
                Another
              </button>
              <button
                type="button"
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
                onClick={() => close()}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}

export default AddPatientHistory;
