
import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import AddNotes from "./AddNotes";
import AccountDetails from './AccountDetails';
import user from 'components/user';

const users = [
  {id: 1, name: 'Surgery Consultation,Level A',GST:'NO'},
  {id: 2, name: 'Surgery Consultation,Level B',GST:'NO'},
  {id: 3, name: 'Surgery Consultation,Level C',GST:'NO'},
  {id: 4, name: 'Surgery Consultation,Level D',GST:'NO'},
];

class TableRow extends React.PureComponent {
 static defaultProps = {
    selected: false
  }

  render() {
    const { selected, id, name,GST,handleSelect } = this.props;
    //console.log(`render TableRow :: ${id} :: ${name}`);
    
    return (
    <table style={{width:'100%'}}>
      <tbody>
      <tr>
        <td><input name={id} type="checkbox" checked={selected[user.id]}
         onChange={handleSelect} /></td>
        <td style={{width:'280px'}}>{id}</td>
        <td style={{width:'295px'}}>{name}</td>
        <td style={{width:'295px'}}>{GST}</td>
      </tr>
      </tbody>
      </table>
    );
  }
}
const list = [
  {
    name: "Person 1",
    phone: "123-4567",
    id: 11,
    hidden:true
  },
  {
    name: "Person 2",
    phone: "123-4567",
    id: 12,
    hidden:true
  },
]
class Addaccount extends Component {
  constructor(props){
    super(props);
this.state = {
      descr:'',
      selected: {},
      list: list,  
     };
     this.handleClick = this.handleClick.bind(this);
     this.removeItem = this.removeItem.bind(this);
    }
    handleSelect = (e) => {
      const selected = this.state.selected;
      selected[e.target.name] = e.target.checked;
    //                  this.setState({ selected });
      users.map((user) => {
        if(this.state.selected[user.id]){
        this.setState({
         selected:this.state.selected,
         id:user.id,
         user:this.state.user
         },console.log(user.name));
      }
    })    
    }
  handleClick(selected) {
     
    }
    removeItem(item) {
      // Put our list into an array
      const users = this.state.users.slice();
      // Check to see if item passed in matches item in array
      users.some((el, i) => {
        if (el === item) {
          // If item matches, remove it from array
          users.splice(i, 1);
          return true;
        }
      });
      // Set state to list
      this.setState({
        users: users
      });
    }
    render() { 
      const { selected, id, name, handleSelect } = this.props;   
const users = [
  {id: 1, name: 'Surgery Consultation,Level A',GST:'NO'},
  {id: 2, name: 'Surgery Consultation,Level B',GST:'NO'},
  {id: 3, name: 'Surgery Consultation,Level C',GST:'NO'},
  {id: 4, name: 'Surgery Consultation,Level D',GST:'NO'},
];
        return ( 
            <Popup trigger={ <a title="Account"  className="collapsed btn btn-default" aria-expanded="false">Add Item</a>} position="right center" modal
            closeOnDocumentClick={false} contentStyle={{height:'80%',width:'70%', overflowY:'scroll'}}>
             {close => (
            <div>
         <div className="popup-content" style={{width: '100%'}}>
  <div className="modal-header" style={{width: '100%', backgroundColor: '#f4f4f4', padding: '5px 15px'}}>
    <h5 style={{backgroundColor: '#f4f4f4', width: '100%', padding: '5px 10px', fontSize: '16px', marginBottom: '0px'}}>Add Item</h5>
    <button type="button" className="close" data-dismiss="modal" onClick={()=>close()} aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div className="modal-body">
  <div className="row ">
            <div className="col-lg-12" style={{marginTop:'15px'}}>
              <div className="col-lg-2">
                <label style={{ fontSize: '12px',fontWeight:'normal'}}>MBS item:</label>
                </div>
                <div className="col-lg-2">
                <input type="text" className="form-control" style={{ fontSize: '12px'}} />
                </div>
                <div className="col-lg-4" >
                <div className="col-lg-1">
                <input className="border-checkbox" type="checkbox" id="checkbox0" style={{marginRight: '10px'}} />
                </div>
                <div className="col-lg-4">
                <label style={{ fontSize: '15px',fontWeight:'normal'}}>Default list</label>
                 </div>
                 </div>
                 <div className="col-lg-2">
                  <input type="text" className="form-control"/>
                </div>
                <div className="col-lg-2">
                <button type="button" className="btn btn-default">Search MBS</button>
                </div>
               
            </div>
         </div>
         <div className="row">
         <div className="col-lg-12 m-t-30">
</div>
         </div>
      <div className="row">
        <div className="col-lg-12 m-t-30" style={{marginTop:'10px'}}>
          <div style={{paddingRight: '0px', paddingLeft: '0px', height: '220px', border: '1px solid #ccc'}}>
          <table style={{width:'100%'}}>
      <thead>
      <tr>
        <th style={{textAlign:'left',width:'100px'}}>ID</th>
        <th style={{textAlign:'left' ,width:'100px'}}>Name</th>
        <th style={{textAlign:'left',width:'100px'}}>GST</th>
      </tr>
      </thead>
      </table>   
    <tbody style={{width:'100%'}}>    
      {
        users.map((user) => {
          return <TableRow key={user.id} id={user.id} name={user.name} GST={user.GST} selected={this.state.selected[user.id]} handleSelect={this.handleSelect} />;      
        })
      }
    </tbody>
         
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 m-t-30">
          <div className="col-lg-7">
            <div className="col-lg-12 m-t-30">
            <label>Fee List</label>
              <div className="card" style={{paddingRight: '0px', paddingLeft: '0px', height: '180px', border: '1px solid #ccc'}}>           
                 <div className="card-block"> 
                 <div>
                   
    
      </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="col-lg-12 m-t-30">
            <label>Fee List</label>
              <div className="card" style={{paddingRight: '0px', paddingLeft: '0px', height: '180px', border: '1px solid #ccc'}}>
              
                <div className="card-block">
               
             </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 classic-tabs" style={{float: 'left', width: '100%', marginTop: '5px'}}>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12 m-b-0">
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label text-right" style={{ paddingRight: '0px', marginTop: '10px', fontSize: '12px'}}>Description of service:</label>
                    <div className="col-sm-8" style={{overflowY: 'scroll'}}>
                      <input type="text" className="form-control" style={{padding: '.2rem .75rem',height:'60px'}} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label text-right" style={{lineHeight: '.5', paddingRight: '0px', marginTop: '10px', fontSize: '12px'}}>Fee to change:</label>
                    <div className="col-sm-4">
                      <input type="text" className="form-control" style={{padding: '.2rem .75rem'}}  />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label text-right" style={{lineHeight: '.5', paddingRight: '0px', marginTop: '10px', fontSize: '12px'}}>Service Details:</label>
                    <div className="col-sm-6">
                      <input type="text" className="form-control" style={{padding: '.2rem .75rem'}}  />
                    </div>
                    <div className="col-sm-1" >
                    <AddNotes/>               
                     </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-xl-12 m-b-0">
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label text-right" style={{lineHeight: '.5', paddingRight: '0px', marginTop: '10px', fontSize: '12px'}}>Restriction code:</label>
                    <div className="col-sm-3">
                    <select style={{width:"100%",height:"25px"}}>
                                <option> </option>
                     </select>
                    </div>
                    <div className="col-sm-1">
                      <input className="border-checkbox" type="checkbox" id="checkbox0" style={{marginLeft: '20px'}} />
                      </div>
                      <div className="col-sm-2">
                      <label className="border-checkbox-label" htmlFor="checkbox0" style={{fontStize: 'normal'}}>Self deemed</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>

        <div>
       
            </div>
        <button type="button" className="btn btn-default" data-dismiss="modal" style={{marginRight:'5px',width:'95px',height:'35px'}} onClick={this.handleClick(selected)} >Add</button>  
        <button type="button" className="btn btn-default" data-dismiss="modal" style={{marginRight:'5px',width:'95px',height:'35px'}}>Add Another</button>
        <button type="button" className="btn btn-default" data-dismiss="modal" style={{marginRight:'2px',width:'90px',height:'35px'}} onClick={()=>close()}>Cancel</button>
   
      </div>
  </div>
</div>
   </div>
 
            )}
            </Popup>
         );
    }
}
 
export default Addaccount;