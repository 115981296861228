import React, { Component } from "react";
import Header from "../../header";
import Footer from "../../footer";
import Grid from "../../crudcomponent/combinedcrud";
import { dropDown, dropdown1 } from "../../../DS/services";
import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import {api_delete_selection} from '../../../DS/DS.ListPage';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import {NotificationContainer,NotificationManager} from 'react-notifications';
import AddMessageGroup from './addMessageGroup';
import { confirmAlert } from 'react-confirm-alert';
import AddUserToGroup from './addUserToGroup';
import {api_remove_group_member,api_remove_ongroup_delete,api_update_setting} from '../../../DS/DS.MessageGroup';
class MessagesPage extends Component {
    constructor(props)
    {
        super(props);
        this.state={
            //message group states
            addGroup:false,
            editGroup:false,
            groupSelected:'',
            groupSelectedLength:-1,
            reloadKey:0,
          //  groupUserCrud:'',
            addmemeber:false,
            memberSelected:'',
            memberselectedlength:-1,
            messageSetting:false,
            groupCrud: {
                jsonGridVersion: "v2.0",
                grid: true,
                viewgrid: {
                  isVisible:true,
                  isPagination: true,
                  isFiltering: true,
                  isSelection:true,
                  deleteFunction:this.removeGroup,
                  isDateGroup:false,
                  isYearSeparator:false,
                  dateCol:'',
                  groupDateSelection:false,
                  cellClicked:this.onRowClicked,
                  baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                  query: {
                    tb: "message_group_tb",
                    cols: [
                      "group_id",
                      "group_name",
                      
                    
                    ],
                   where: [
                      {
                        type: "and",
                        key: "isDeleted",
                        operator: "=",
                        value: 0
                   }]
                   ,
                    primary_key:"group_id",
                  },
                  viewtabletitle: "",
                  columns: [
                    {name: "group_name", title: "Message Groups" }
                    
                   
                  ],
                  rows: [],
                  sortinit: [{ columnName: "group_id", direction: "desc" }],
                  pagesize: 10,
                  getAllIds:false,
                  getallId:''
                }
              },
              groupUserCrud: {
                jsonGridVersion: "v2.0",
                grid: true,
                viewgrid: {
                  isVisible:true,
                  isPagination: true,
                  isFiltering: true,
                  isSelection:true,
                  deleteFunction:this.removeGroupMember,
                  isDateGroup:false,
                  isYearSeparator:false,
                  dateCol:'',
                  groupDateSelection:false,
                  cellClicked:'',
                  baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                  query: {
                    tb: "message_group_user_tb",
                    cols: [
                      "group_user_id",
                      "group_name",
                      "name",
                      "role_name"
                      
                    
                    ],
                   where: [
                  /* {
                     type:"and",
                     key:" message_group_user_tb.group_id",
                     operator:"=",
                     value:this.props.groupid
          
                   }*/
                  ],
                  joins:[
                   {
                     joinType: "left",
                     joinTable: "message_group_tb",
                     joinCondition: "message_group_tb.group_id=message_group_user_tb.group_id"
                   },
                   {
                    joinType: "left",
                    joinTable: "user_tb",
                    joinCondition: "user_tb.user_id= message_group_user_tb.user_id"
                  },{
                    joinType: "left",
                    joinTable: "user_role_tb",
                    joinCondition: " user_role_tb.user_id=user_tb.user_id"
                  },
                  {
                    joinType: "left",
                    joinTable: "role_tb",
                    joinCondition: "role_tb.role_id=user_role_tb.role_id"
                  }
          
                  
                  ]
                   ,
                    primary_key:"group_user_id",
                  },
                  viewtabletitle: "",
                  columns: [
                    {name: "name", title: "Name"},
                    {name: "group_name", title: "Group"}
                    
                   
                  ],
                  rows: [],
                  sortinit: [{ columnName: "group_user_id", direction: "desc" }],
                  pagesize: 10,
                  getAllIds:false,
                  getallId:''
                }
              }
        }
    }
    componentWillMount=()=>
    {
      //this.get_group_member(0);
    }
    addCheck=()=>
    {
      alert("Please deselect the record to proceed")
    }
    checkgroupselected=()=>
    {
      alert("Please select group to add user")
    }
    reloadPage=()=>{
        this.setState({
            addGroup:false, 
            editGroup:false,
            addmemeber:false
            })
        this.setState({
            groupSelected:'',
            groupSelectedLength:-1,
            memberSelected:'',
            memberselectedlength:-1
           })
           
        this.setState({reloadKey:Math.random()})
    }
    //deleted group
 removeGroup=(ids,action,totalLength )=>
 {
    if(action=='selection')
    {
      this.state.groupSelected=ids;
      this.state.groupSelectedLength=totalLength;
    }
    if(action=='delete')
    {
        if (this.state.groupSelectedLength> 0) {
              const groupData={tb_name:'message_group_tb',ids:this.state.groupSelected,primary_key:'group_id'}
              confirmAlert({
                title: '', 
                message: 'You are about to delete the group.Do you want to proceed?',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                    this.props.apiDelete(groupData).then(
                    res =>{
                      this.props.apigrpremovemember({groupid:this.state.groupSelected}).then(
                      this.reloadPage() ,
                     // this.get_group_member(0),
                        NotificationManager.success(
                        'Group deleted successfully',
                        )
                      )
                    }
                  );
                },
            },
            {
              label: 'No',
              onClick: () => { this.reloadPage()},
            },
          ],
        });
      } 
      else 
      {
        NotificationManager.error('Please select a record to delete');
      }
    }
 }
 removeGroupMember=(ids,action,totalLength )=>
 {
    if(action=='selection')
    {
      this.state.memberSelected=ids;
      this.state.memberselectedlength=totalLength;
    }
    if(action=='delete')
    {
        if (this.state.memberselectedlength> 0) {
              const groupData={userid:this.state.memberSelected}
              confirmAlert({
                title: '',
                message: 'You are about to remove selected member from group.Do you want to proceed?',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                    this.props.apiremovemember(groupData).then(
                    res => this.reloadPage() ,
                    NotificationManager.success(
                    'Member removed from the group',
                    )
                  );
                },
            },
            {
              label: 'No',
              onClick: () => { this.reloadPage()},
            },
          ],
        });
      } 
      else 
      {
        NotificationManager.error('Please select a record to delete');
      }
    }
 }
 onRowClicked=(clickedId,action)=>
  {
    //alert(clickedId)
     if(action=="rowclicked")
      {
      //  this.get_group_member(clickedId.group_id)
     //   console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",clickedId.group_id)
      }
    }
 get_group_member=(groupid)=>
 {
  // alert(groupid)
  let returnjson = "";
  this.setState({ groupUserCrud: "" });
  if(groupid==0)
  {
    const  groupmemberCrud= {
      jsonGridVersion: "v2.0",
      grid: true,
      viewgrid: {
        isVisible:true,
        isPagination: true,
        isFiltering: true,
        isSelection:true,
        deleteFunction:this.removeGroupMember,
        isDateGroup:false,
        isYearSeparator:false,
        dateCol:'',
        groupDateSelection:false,
        cellClicked:'',
        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "message_group_user_tb",
          cols: [
            "group_user_id",
            "group_name",
            "name",
            "role_name"
            
          
          ],
         where: [
        /* {
           type:"and",
           key:" message_group_user_tb.group_id",
           operator:"=",
           value:this.props.groupid

         }*/
        ],
        joins:[
         {
           joinType: "left",
           joinTable: "message_group_tb",
           joinCondition: "message_group_tb.group_id=message_group_user_tb.group_id"
         },
         {
          joinType: "left",
          joinTable: "user_tb",
          joinCondition: "user_tb.user_id= message_group_user_tb.user_id"
        },{
          joinType: "left",
          joinTable: "user_role_tb",
          joinCondition: " user_role_tb.user_id=user_tb.user_id"
        },
        {
          joinType: "left",
          joinTable: "role_tb",
          joinCondition: "role_tb.role_id=user_role_tb.role_id"
        }

        
        ]
         ,
          primary_key:"group_user_id",
        },
        viewtabletitle: "",
        columns: [
          {name: "name", title: "Name"},
          {name: "group_name", title: "Group"}
          
         
        ],
        rows: [],
        sortinit: [{ columnName: "group_user_id", direction: "desc" }],
        pagesize: 10,
        getAllIds:false,
        getallId:''
      }
    }
    returnjson = groupmemberCrud;
  this.setState({ groupUserCrud: groupmemberCrud });
  
  }
  if(groupid!=0)
  {
   
    const  groupwith= {
      jsonGridVersion: "v2.0",
      grid: true,
      viewgrid: {
        isVisible:true,
        isPagination: true,
        isFiltering: true,
        isSelection:true,
        deleteFunction:this.removeGroupMember,
        isDateGroup:false,
        isYearSeparator:false,
        dateCol:'',
        groupDateSelection:false,
        cellClicked:'',
        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "message_group_user_tb",
          cols: [
            "group_user_id",
            "group_name",
            "name",
            "role_name"
            
          
          ],
         where: [
         {
           type:"and",
           key:" message_group_user_tb.group_id",
           operator:"=",
           value:groupid

         }
        ],
        joins:[
         {
           joinType: "left",
           joinTable: "message_group_tb",
           joinCondition: "message_group_tb.group_id=message_group_user_tb.group_id"
         },
         {
          joinType: "left",
          joinTable: "user_tb",
          joinCondition: "user_tb.user_id= message_group_user_tb.user_id"
        },{
          joinType: "left",
          joinTable: "user_role_tb",
          joinCondition: " user_role_tb.user_id=user_tb.user_id"
        },
        {
          joinType: "left",
          joinTable: "role_tb",
          joinCondition: "role_tb.role_id=user_role_tb.role_id"
        }

        
        ]
         ,
          primary_key:"group_user_id",
        },
        viewtabletitle: "",
        columns: [
          {name: "name", title: "Name"},
          {name: "group_name", title: "Group"}
          
         
        ],
        rows: [],
        sortinit: [{ columnName: "group_user_id", direction: "desc" }],
        pagesize: 10,
        getAllIds:false,
        getallId:''
      }
    }
    console.log(groupwith)
    returnjson = groupwith;
    this.setState({ groupUserCrud: groupwith });
   
  
  }
    return returnjson;
 }
 saveSetting=()=>{
   if(this.state.messageSetting==false)
   {
      this.state.messageSetting=0
   }
   else
   {
    this.state.messageSetting=1
   }
    const settingData={
      setting:'patient_message',
      setting_val:this.state.messageSetting,
      module:'Appointment'
    }
      this.props.apiUpdateSetting(settingData).then(
        req=>{
        if(req.data.status=="Success")
        {
            
            NotificationManager.success('Setting updates',)
        }
        else{
            
            NotificationManager.error('Error encountered whileupdating the setting',)
        }
       }
      )
 }
    render() {
        return (
           <div>
               <AdminHeader /><Leftsidemenu />
               <NotificationContainer />
                 <section className="content-header" style={{marginLeft: '240px'}}>
                     <h1>
                         Messages
                     </h1>
                     <ol className="breadcrumb">
                         <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                         <li className="active">Messages</li>
                     </ol>
                 </section>
                 {/* main section start */}
                 <section className="content"  style={{marginLeft: '240px'}}>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="col-xs-12" style={{margin:'0px auto', float:'none'}}>
                                    <div className="box-body">
                                     
				                        <div className="col-sm-12 col-md-12 col-xl-12">		
							                <div id="pageContent">
                              
                              <div className="form-group row m-b-10">
                                  <div className="col-sm-6">
                                <div className="col-lg-12 m-t-60">
                                                    <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#new-grp-btn" style={{width:'120px', marginRight:'5px'}} 
                                                    onClick={(e)=>{this.state.groupSelected > 0 ?this.addCheck():this.setState({addGroup:true})}} >New group</button>
                                                    <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'120px'}} onClick={(e)=>this. removeGroup('','delete','')}>Delete group</button>
	                                            </div>
                                              
                                   
	                                            <div className="col-sm-12 col-md-12 col-xl-12 m-t-10">
	                                                <div className="card" style={{height:'150px', overFlowY:'scroll'}}>
	                                                    <div className="card-block">
                                                        <CommonGrid
                                                                    crud={this.state.groupCrud}
                                                                    key={this.state.reloadKey}
                                                                /> 
	                                                    </div>
	                                                </div>
	                                            </div>
	                                            
                                                {this.state.addGroup ?
                                                    <AddMessageGroup showModal={this.state.addGroup} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                {this.state.editGroup ?
                                                    <AddMessageGroup showModal={this.state.editGroup} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.groupSelected}/> :''}
                                           </div>
                                            
                                            


                                           <div className="col-sm-6"> 
                                                <div className="col-lg-12 m-t-60">
                                                        <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#msg-user-btn" style={{width:'120px', marginRight:'5px'}}
                                                         onClick={(e)=>{this.state.groupSelected ==0 ?this.checkgroupselected():this.setState({addmemeber:true})}} >Add User</button>
                                                        <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'120px'}}
                                                        onClick={()=>this.removeGroupMember('','delete','')}>Remove User</button>
                                                    </div>
                                                    
                                                    <div className="col-sm-12 col-md-12 col-xl-12 m-t-10">
                                                        <div className="card" style={{height:'150px', overFlowY:'scroll', border:'1px solid none'}}>
                                                            <div className="card-block">
                                                          
                                                            <CommonGrid
                                                                    crud={this.state.groupUserCrud}
                                                                    key={this.state.reloadKey}
                                                                /> 

                                                            </div>
                                                        </div>
                                                        {this.state.addmemeber ?
                                                    <AddUserToGroup showModal={this.state.addmemeber} popFor='Add' reloadPage={this.reloadPage} groupid={this.state.groupSelected} /> :''}
                                               
                                                    </div>
                                                  
                                                </div> 
                                                <div className="form-group row m-t-10">
                                                    <div className="col-sm-12 col-md-10 col-xl-10">
                                                        <div className="border-checkbox-section" style={{marginTop:'10px', marginBottom:'10px'}}>
                                                            <div className="border-checkbox-group border-checkbox-group-default">
                                                            <label className="border-checkbox-label label-margin-top" for="checkbox5" onClick={(e)=>this.setState({messageSetting:e.target.checked})}> 
                                                                <input className="border-checkbox checkbox-left" type="checkbox" id="checkbox5"/>
                                                                &nbsp; Don't allow deletion of messages that are attached to a patient</label>
                                                            </div>
                                                        </div>		
                                                    </div>
                                                </div>
                                            </div>	
                                        </div>
                                        </div>
                                </div>
                                <div className="modal-footer modal-footer-bottom-margin">
                                    <div className="row">
                                        <div className="col-sm-10"> </div>
                                            <div className="col-sm-2">
                                                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.saveSetting()}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
    </div>
    )}
}



const mapDispatchToProps = dispatch => {
    return {
        apiDelete:data =>dispatch(api_delete_selection(data)),
        apiremovemember:data => dispatch(api_remove_group_member(data)),
        apigrpremovemember:data=>dispatch(api_remove_ongroup_delete(data)),
        apiUpdateSetting:data=>dispatch(api_update_setting(data))
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  
  
  export default connect(mapStateToProps,mapDispatchToProps)(MessagesPage);