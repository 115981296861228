import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import {api_add_antenantal} from '../../../DS/DS.ObstetricHistory'
import PregnancyDetails from './pregnancyDetails'
import { api_getDoctors } from '../../../DS/DS.Appointments';
import Showhelp from './../../showHelp';

class Antenatal extends Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            open:this.props.showmodal,
            key:0,
            docs:[],
            antedate:new Date(),
            from_guid:'',
            systo:'',
            dias:'',
            weight:'',
            oedema:'',
            urine:'',
            glucose:'',
            other:'',
            calc:'',
            clin:'',
            fundal:'',
            foetal:'',
            rate:'',
            movement:'',
            presentation:'',
            pos:'',
            engag:'',
            comments:'',
            patitne_id:this.props.redux_patientId,
            preg_id:this.props.id

        }
    }
    componentWillMount=()=>
    {
        
        this.props.getDoctors().then(res => {
            this.setState({
              docs: res.data.content ,
            })});
    }
    saveAllData=()=>
    {
        this.props.saveAnte(this.state).then(res=>
            {
                this.props.changeKey()
            })
    }
    render() {
        return ( 
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div style={{ width:'800px', marginLeft:'200px', backgroundColor:'#FFF', float:'left'}}>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '0px 15px',color:'black'}}>
                        <h6  className="popup-title"> Antenatal visit <Showhelp gotohelp="antenatal_visit_help"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()} style={{ marginTop: "-28px", right: "20px"}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body " style={{ fontSize: "14px" }}>
                        <div className="col-sm-12" style={{marginBottom: 10, textAlign: 'left'}}>
                            <div className="form-group row">
                                <div className="col-sm-2"><label className="col-form-label text-left">Date</label></div>
                                <div className="col-sm-2" >
                                        <DatePicker
                                         className="datePicker"
                                         selected={this.state.antedate}
                                             onChange={this.handleDate}
                                            dateFormat="dd/MM/yyyy"
                                            id="clinicalcalendar" 
                                            
                                        />    
                                </div>
                                <div className="col-sm-2"><label className="col-form-label text-left">Doctor</label></div>

                                <div className="col-sm-3">
                                    <select style={{width:"50%",height:"25px"}} onChange={(e) => this.setState({ from_guid: e.target.value })}>
                                        <option value={this.state.from_guid}></option>
                                        { this.state.docs !=undefined && this.state.docs.map((item,i) => (
                                        <option value={item.doctor_id}> {item.Firstname} </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-2"><label className="col-form-label text-left">B.P Systolic:</label></div>
                                <div className="col-sm-2"><input type="text" name="search_condition" className="form-control" value={this.state.systo} onChange={(e)=>this.setState({systo:e.target.value})}/></div>
                               
                                <div className="col-sm-2"><label className="col-form-label text-left">Diastolic:</label></div>
                                <div className="col-sm-2"><input type="text" name="search_condition" className="form-control" value={this.state.dias} onChange={(e)=>this.setState({dias:e.target.value})}/></div>
                            </div>
                            
                            <div className="form-group row">
                                <div className="col-sm-2"><label className="col-form-label text-left">Weight</label></div>
                                <div className="col-sm-2"><input type="text" name="search_condition" className="form-control" value={this.state.weight} onChange={(e)=>this.setState({weight:e.target.value})}/></div>
                               
                                <div className="col-sm-2"><label className="col-form-label text-left">Oedema</label></div>
                                <div className="col-sm-2">
                                    <select value={this.state.oedema} onChange={(e)=>this.setState({oedema:e.target.value})}>
                                            <option value=""></option>
                                            <option value="Nil">Nil</option>
                                            <option value="+">+</option>
                                            <option value="++">++</option>
                                            <option value="+++">+++</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className="form-group row">
                                <div className="col-sm-2"><label className="col-form-label text-left">Urine : Protein :</label></div>
                                <div className="col-sm-2">
                                    <select onChange={(e)=>this.setState({urine:e.target.value})} value={this.state.urine}>
                                        <option value=""></option>
                                        <option value="Trace">Trace</option>
                                        <option value="+">+</option>
                                        <option value="++">++</option>
                                        <option value="+++">+++</option>
                                        <option value="Nil">Nil</option>
                                    </select>
                                </div>
                                <div className="col-sm-2"><label className="col-form-label text-left">Glucose</label></div>
                                <div className="col-sm-2">
                                    <select  onChange={(e)=>this.setState({glucose:e.target.value})} value={this.state.glucose}>
                                         <option value=""></option>
                                        <option value="Trace">Trace</option>
                                        <option value="+">+</option>
                                        <option value="++">++</option>
                                        <option value="+++">+++</option>
                                        <option value="Nil">Nil</option>
                                    </select>
                                </div>
                                <div className="col-sm-2"><label className="col-form-label text-left">Other</label></div>
                                <div className="col-sm-2"><input type="text" name="search_condition" className="form-control" value={this.state.other} onChange={(e)=>this.setState({other:e.target.value})}/></div>
                            </div>
                            
                            <div className="form-group row">
                                <div className="col-sm-2"><label className="col-form-label text-left">Size(Weeks) Calc:</label></div>
                                <div className="col-sm-2">
                                <input type="text" name="search_condition" className="form-control" value={this.state.calc} onChange={(e)=>this.setState({calc:e.target.value})}/>
                                </div>
                                <div className="col-sm-2"><label className="col-form-label text-left">Clin</label></div>
                                <div className="col-sm-2"><input type="text" name="search_condition" className="form-control" value={this.state.clin} onChange={(e)=>this.setState({clin:e.target.value})} /></div>
                            
                                <div className="col-sm-2"><label className="col-form-label text-left">Fundal height (cm)</label></div>
                                <div className="col-sm-2">
                                    <input type="text" name="search_condition" className="form-control" value={this.state.fundal} onChange={(e)=>this.setState({fundal:e.target.value})}/>
                                </div>
                                
                            </div>

                            <div className="form-group row">
                                <div className="col-sm-2"><label className="col-form-label text-left">Foetal heart:</label></div>
                                <div className="col-sm-2">
                                    <select onChange={(e)=>this.setState({foetal:e.target.value})} value={this.state.foetal}>
                                       
                                        <option value=""></option>    
                                        <option value="Heard">Heard</option>
                                        <option value="Not heard">Not heard</option>
                                    </select>
                                </div>
                                <div className="col-sm-2"><label className="col-form-label text-left">Rate</label></div>
                                <div className="col-sm-2"><input type="text" name="search_condition" className="form-control" value={this.state.rate} onChange={(e)=>this.setState({rate:e.target.value})}/></div>
                            
                                <div className="col-sm-2"><label className="col-form-label text-left">Foetal movements</label></div>
                                <div className="col-sm-2">
                                    <select onChange={(e)=>this.setState({movement:e.target.value})} value={this.state.movement}>
                                    <option value=""></option>
                                        <option value="Present">Present</option>
                                        <option value="Absent">Absent</option>
                                    </select>
                                </div>
                               </div>

                               <div className="form-group row">
                                <div className="col-sm-2"><label className="col-form-label text-left">Presentation</label></div>
                                <div className="col-sm-2">
                                    <select onChange={(e)=>this.setState({presentation:e.target.value})} value={this.state.presentation}>
                                    <option value=""></option>
                                        <option value="Unknown">Unknown</option>
                                        <option value="Cephalic">Cephalic</option>
                                        <option value="Breech">Breech</option>
                                        <option value="Transverse">Transverse</option>
                                        <option value="Oblique">Oblique</option>
                                    </select>
                                </div>
                               
                                <div className="col-sm-2"><label className="col-form-label text-left">Position</label></div>
                                <div className="col-sm-2">
                                    <select onChange={(e)=>this.setState({pos:e.target.value})} value={this.state.pos}>
                                    <option value=""></option>
                                        <option value="LOT">LOT</option>
                                        <option value="ROT">ROT</option>
                                        <option value="LOA">LOA</option>
                                        <option value="ROA">ROA</option>
                                        <option value="LOP">LOP</option>
                                        <option value="ROP">ROP</option>
                                        <option value="OA">OA</option>
                                        <option value="OP">OP</option>
                                        <option value="LST">LST</option>
                                        <option value="RST">RST</option>
                                        <option value="LSA">LSA</option>
                                        <option value="RSA">RSA</option>
                                        <option value="LSP">LSP</option>
                                        <option value="RSP">RSP</option>
                                        <option value="SA">SA</option>
                                        <option value="SP">SP</option>
                                    </select>
                                </div>
                               </div>
                               <div className="form-group row">
                                    <div className="col-sm-2"><label className="col-form-label text-left">Engagement</label></div>
                                    <div className="col-sm-2">
                                        <select onChange={(e)=>this.setState({engag:e.target.value})} value={this.state.engag}>
                                        <option value=""></option>
                                        <option value="Unknown">Unknown</option>
                                        <option value="Not engaged">Not engaged</option>
                                        <option value="1/5">1/5</option>
                                        <option value="2/5">2/5</option>
                                        <option value="3/5">3/5</option>
                                        <option value="4/5">4/5</option>
                                        <option value="5/5">5/5</option>
                                        <option value="0/5">0/5</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-2"><label className="col-form-label text-left">Comments</label></div>

                                    <div className="col-sm-12 mt-3">
                                        <textarea style={{width: '100%', height: 80, float: 'left', border: '1px solid rgb(221, 221, 221)', padding: 10}} defaultValue={""} value={this.state.comments}
                                        onChange={(e)=>this.setState({comments:e.target.value})}/></div>
                                </div>

                       </div>
                </div>
                <div className="modal-footer">
                    <button type="button"  onClick={() => this.saveAllData()} className="btn btn-primary btn-xs" data-dismiss="modal">
                            Save
                    </button>
                    <button type="button"  onClick={() => this.props.changeKey()} className="btn btn-primary btn-xs" data-dismiss="modal">
                        Cancel
                    </button>
                </div>   
            </div>
        )}
    </Popup>
   )}
}   

const mapDispatchToProps = dispatch => {
    return {
            getDoctors: data => dispatch(api_getDoctors(data)),
            saveAnte:data=>dispatch(api_add_antenantal(data))
           
            };
  };
  const mapStateToProps = state => {
   console.log(">>> doc data",state.getDrAppointments.logged_info)
     let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
 
   return {
     redux_patientId,redux_docId
   };
 };
  export default connect(mapStateToProps,mapDispatchToProps)(Antenatal);