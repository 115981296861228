import React, { Component } from 'react';
import './style.css';
import {api_tobacco_history,api_get_history} from '../../../DS/DS.FamilyHistory';
import { connect } from 'react-redux';
import YearPicker from "react-year-picker";
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
class tobaccoHistory extends Component {
  constructor(props)
  { 
    super(props);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.handleStartChange = this.handleStartChange.bind(this);
    this. state = {
      disable1:true,
      isSmoker:1,
      key:0,
      disable2:true,
      smoking_comments:'',
      smoke_type:1,
      quantity:0,
      end_date:'',
      started_date:'',
      advice1:false,
      advice2:false,
      advice3:false,
      advice4:false,
      want_advice:0,
      disableadivce:true,
      started_year1:'',
      years:''
    }
  }
  componentWillMount=()=>
  {
    const pdata={id:this.props.redux_patientId}
    this.props.getHistory(pdata).then(
      req=>
      {
        if(req.data.content!=null)
        {
        this.setState({
          isSmoker:req.data.content.smoking_type,
          smoking_comments:req.data.content.smoking_comments,
          smoke_type:req.data.content.smoke_type,
          quantity:req.data.content.per_day,
          advice1:req.data.content.advice1,
          advice2:req.data.content.advice2,
          advice3:req.data.content.advice3,
          advice4:req.data.content.advice4,
          want_advice:req.data.content.want_advice,
          started_date:req.data.content.started_date,
          end_date:req.data.content.end_date,
          started_year1:req.data.content.started_year1
       
        })
        if(req.data.content.smoking_type==2)
        {
          this.setState({disable2:false,disable1:true,disableadivce:true})
        }
        if(req.data.content.smoking_type==1)
        {
          this.setState({disable2:true,disable1:true,disableadivce:true})
        }
        if(req.data.content.smoking_type==3)
        {
          this.setState({disable2:true,disable1:false,end_date:'',disableadivce:true})
        }
        if(req.data.content.advice1==0)
          {
            this.state.advice1=false
          }
          else{
            this.state.advice1=true
          }
          
          if(req.data.content.advice2==0)
          {
            this.state.advice2=false
          }
          else{
            this.state.advice2=true
          }
          
          if(req.data.content.advice3==0)
          {
            this.state.advice3=false
          }
          else{
            this.state.advice3=true
          }
          
          if(req.data.content.advice4==0)
          {
            this.state.advice4=false
          }
          else{
            this.state.advice4=true
          }

          if(req.data.content.want_advice==1)
          {
            this.setState({disableadivce:false})
          }
       
      }
     }
    )
    const year =(new Date()).getFullYear() - 1925;
    
    this.state.years = Array.from(new Array(year+1),(val, index) => index + 1925);
  }
  saveClickHandler=()=>
  {
    if(this.state.advice1==false)
    {
      this.state.advice1=0
    }
    else{
      this.state.advice1=1
    }
    
    if(this.state.advice2==false)
    {
      this.state.advice2=0
    }
    else{
      this.state.advice2=1
    }
    
    if(this.state.advice3==false)
    {
      this.state.advice3=0
    }
    else{
      this.state.advice3=1
    }
    
    if(this.state.advice4==false)
    {
      this.state.advice4=0
    }
    else{
      this.state.advice4=1
    }
    const values={
      smoking_type:this.state.isSmoker,
      patient_id:this.props.redux_patientId,
      doc_id:this.props.redux_docId,
      smoking_comments:this.state.smoking_comments,
      per_day:this.state.per_day,
      started_date:this.state.started_date,
      end_date:this.state.end_date,
      smoke_type:this.state.smoke_type,
      advice1:this.state.advice1,
      advice2:this.state.advice2,
      advice3:this.state.advice3,
      advice4:this.state.advice4,
      want_advice:this.state.want_advice,
      started_year1:this.state.started_year1
    }
    this.props.apitobaccohistory({values}).then(
      req=>{
        NotificationManager.success(
          'Tobacco history save successfully',
          //this.changeKey()
          )
      }
    )
  }
  reloadPage=()=>
  {
    this.setState({key:Math.random()})
  }
  handleReminderRadio=(val,e)=>
  {
    if(val==2)
    {
      this.setState({disable2:false,disable1:true})
    }
    if(val==1)
    {
      this.setState({disable2:true,disable1:true})
    }
    if(val==3)
    {
      this.setState({disable2:true,disable1:false,end_date:''})
    }
    this.setState({isSmoker:val})
  }
  handleQuantityRadio=(val,e)=>
  {
    this.setState({quantity:val})
  }
  handleStartChange(date) {
    this.setState({started_date:date})
  }
  handleEndChange(date)
  {
    this.setState({end_date:date})
  }
  getAdvice=(e)=>
  {
    this.setState({want_advice:e.target.value})
    if(e.target.value==1)
    {
      this.setState({disableadivce:false})
    }
    else
    {
      this.setState({disableadivce:true,
        advice1:'',
        advice2:'',
        advice3:'',
        advice4:''})

    }
  }
 render() { 
  return ( 
    <div className="col-sm-12 pl-0 pr-0 mb-1 mt-1">
      {/*<div className="col-sm-12 pl-0 pr-0">
        <h5 class="sheadtitle">Tobacco History</h5>
  </div>*/}

<div className="col-sm-12">
  <div className="col-sm-12 mt-2 mb-3 pl-0">
    <label style={{fontWeight: 'bold', fontSize: 16}}>Current Smoking History:</label>
  </div>
  <div className="col-sm-12 pl-0">
    <div className="row">
      <div className="col-sm-12 pr-0">
      <label className="radio-inline" onClick={(e)=>{this.handleReminderRadio(1,e)}}>  
             <input type="radio" name="nsmoking" value="1" style={{}} checked={this.state.isSmoker==1 ?'checked':''}/> Non-smoker  </label>
             <label className="radio-inline" onClick={(e)=>{this.handleReminderRadio(2,e)}}> 
              <input type="radio" name="nsmoking" value="2" style={{}} checked={this.state.isSmoker==2 ?'checked':''}/> Ex-smoker   </label>
              <label className="radio-inline" onClick={(e)=>{this.handleReminderRadio(3,e)}}> 
              <input type="radio" name="nsmoking" value="3" style={{}} checked={this.state.isSmoker==3 ?'checked':''}/> Smoker   </label>
      </div>
    </div>
  </div>
  <div className="row mt-3">
    <div className="col-sm-2">
    <select  class="form-control"  disabled={this.state.disable1} 
            onChange={(e)=>this.setState({smoke_type:e.target.value})} value={this.state.smoke_type}>
              <option value="1"> Cigrattes</option>
              <option value="3"> Pipe</option>
              <option value="2"> Cigars</option>
            </select>
    </div>
    <div className="col-sm-1 col-sm-01 pr-0 text-right">
      <label> Quantity/day : </label>
    </div>
    <div className="col-sm-2">
    <label style={{marginTop: ''}}  >Quantity/day</label>
            <input type="text" class="form-control"  disabled={this.state.disable1} value={this.state.quantity} onChange={(e)=>this.setState({quantity:e.target.value})}/>
    </div>
    <div className="col-sm-1 col-sm-01 pr-0 text-right">
      <label> Year started : </label>
    </div>
    <div className="col-sm-2">
 {/*}    <YearPicker onChange={this.handleStartChange} disabled={this.state.disable1} value={this.state.started_date}/>*/}
 <select class="form-control"  onChange={(e)=>this.setState({started_year1:e.target.value})} value={this.state.started_year1}  disabled={this.state.disable1}>
                                    {
                                    this.state.years.map((year, index) => {
                                        return <option key={`year${index}`} value={year}>{year}</option>
                                    })
                                    }
              </select>    </div>
  </div>
  <div className="col-sm-12 pl-0 mb-3">
    <label style={{fontWeight: 'bold', fontSize: 16}}>Past Smoking History:</label>
  </div>
  {/* <div className="">
          <div className="col-sm-2" style={{float: ''}}>
            <label style={{margin: '10px 5px', fontSize: ''}}>Ouantity/day:</label>
          </div>
         <div className="col-sm-10">
            <div className="col-sm-2" style={{marginTop: '5px', paddingLeft: '0px', marginRight: '0px', float: 'left'}}>
            <label style={{marginTop: '5px', paddingLeft: '0px', marginRight: '0px', float: 'left'}} onClick={(e)=>{this.handleQuantityRadio(1,e)}} >
              <input type="radio" name="quantity" value="1" style={{margin: '8px 0px 3px 5px', float: 'left'}}
               disabled={this.state.disable2} checked={this.state.quantity==1 ?'checked':''}/>
              Unknown</label>
            </div>
            <div className="col-sm-2">
            <label style={{marginTop: '5px', paddingLeft: '5px', marginRight: '5px', float: 'left'}} onClick={(e)=>{this.handleQuantityRadio(2,e)}} >
              <input type="radio" name="quantity" value="2"style={{margin: '8px 0px 3px 5px', float: 'left'}}  
              disabled={this.state.disable2}  checked={this.state.quantity==2 ?'checked':''}/>
              &lt;1</label>
            </div>
            <div className="col-sm-2">
            <label style={{marginTop: '5px', paddingLeft: '5px', marginRight: '5px', float: 'left'}}  onClick={(e)=>{this.handleQuantityRadio(3,e)}} >
              <input type="radio" name="quantity" value="3" style={{margin: '8px 0px 3px 5px', float: 'left'}} 
              disabled={this.state.disable2}  checked={this.state.quantity==3 ?'checked':''}/>
              1-9</label>
            </div>
            <div className="col-sm-2">
            <label style={{marginTop: '5px', paddingLeft: '5px', marginRight: '5px', float: 'left'}} onClick={(e)=>{this.handleQuantityRadio(4,e)}}  >
              <input type="radio" name="quantity" value="4" style={{margin: '8px 0px 3px 5px', float: 'left'}}  
              disabled={this.state.disable2}  checked={this.state.quantity==4 ?'checked':''}/>
              10-19</label>
            </div>
            <div className="col-sm-2">
            <label style={{marginTop: '5px', paddingLeft: '5px', marginRight: '5px', float: 'left'}}  onClick={(e)=>{this.handleQuantityRadio(5,e)}} >
              <input type="radio"name="quantity" value="5" style={{margin: '8px 0px 3px 5px', float: 'left'}} 
              disabled={this.state.disable2}  checked={this.state.quantity==5 ?'checked':''}/>
             20-39</label>
            </div>
            <div className="col-sm-2">
            <label style={{marginTop: '5px', paddingLeft: '5px', marginRight: '5px', float: 'left'}} onClick={(e)=>{this.handleQuantityRadio(6,e)}}  >
              <input type="radio" name="quantity" value="6" style={{margin: '8px 0px 3px 5px', float: 'left'}} 
              disabled={this.state.disable2}  checked={this.state.quantity==6 ?'checked':''}/>
              40+</label>
            </div>
          </div>
        </div>*/}

  <div className="row">
  <div className="col-sm-1 pr-0 text-right">
      <label> Year started: </label>
    </div>
    <div className="col-sm-2">
{/*  <YearPicker onChange={this.handleStartChange} disabled={this.state.disable2} value={this.state.started_date}/>*/}
<select  onChange={(e)=>this.setState({started_date:e.target.value})} value={this.state.started_date}  disabled={this.state.disable2}>
                                    {
                                    this.state.years.map((year, index) => {
                                        return <option key={`year${index}`} value={year}>{year}</option>
                                    })
                                    }
              </select>    </div>
    <div className="col-sm-1 pr-0 text-right">
      <label> Year stop: </label>
    </div>
    <div className="col-sm-2">
{/*} <YearPicker onChange={this.handleEndChange} disabled={this.state.disable2} value={this.state.end_date}/>*/}
<select class="form-control"  onChange={(e)=>this.setState({end_date:e.target.value})} value={this.state.end_date}  disabled={this.state.disable2}>
                                    {
                                    this.state.years.map((year, index) => {
                                        return <option key={`year${index}`} value={year}>{year}</option>
                                    })
                                    }
              </select>    </div>
  </div>
  <div className="row mt-3">
    <div className="col-sm-4">
      <label> Patient would like cassation advice/support </label>
    </div>
    <div className="col-sm-8 pr-0">
    <label className="radio-inline">  
                <input type="radio" name="smoking" value="1" style={{marginTop: '10px'}}  disabled={this.state.disable1} 
                onClick={(e)=>this.getAdvice(e)} checked={this.state.want_advice==1 ? 'checked' :''}
                /> 
                &nbsp; Yes </label>
                <label className="radio-inline"> <input type="radio" name="smoking" value="2" style={{marginTop: '10px'}}  disabled={this.state.disable1}
              onClick={(e)=>this.getAdvice(e)} checked={this.state.want_advice==2 ? 'checked' :''}/> &nbsp; No </label>
    </div>
  </div>
  <div className="row mt-3">
    <div className="col-sm-10 pr-0">
    <label className="checkbox-inline"> <input type="checkbox"  style={{marginTop: '10px'}}  disabled={this.state.disableadivce} onClick={(e)=>this.setState({advice1:e.target.checked})}
              checked={this.state.advice1==1 ? 'checked' : ''}/> Brief advice to stop smoking given   </label> 
      <label className="checkbox-inline">
      <label className="checkbox-inline"> <input type="checkbox"  style={{marginTop: '10px'}}  disabled={this.state.disableadivce} 
                checked={this.state.advice2==1 ? 'checked' : ''} onClick={(e)=>this.setState({advice2:e.target.checked})}/> Prescribed cessation medication  </label>
      </label>
    </div>
  </div>
  <div className="row mt-3">
    <div className="col-sm-10 pr-0">
    <label className="checkbox-inline">  <input type="checkbox"  style={{marginTop: '10px'}}  disabled={this.state.disableadivce} onClick={(e)=>this.setState({advice3:e.target.checked})}
                checked={this.state.advice3==1 ? 'checked' : ''}/> Provided cessation behavioural support    </label>
      <label className="checkbox-inline">  <input type="checkbox"  style={{marginTop: '10px'}}  disabled={this.state.disableadivce} onClick={(e)=>this.setState({advice4:e.target.checked})}
                  checked={this.state.advice4==1 ? 'checked' : ''}/> Reffered tocessation support    </label>
    </div>
  </div>
  <div className="row mt-3">
    <div className="col-sm-1 mt-3 pl-0">
      <label className="checkbox-inline">Comments:</label>
    </div>
    <div className="col-sm-10 pr-0">
    <textarea rows="4" cols="" style={{padding:'15px',border:'1px solid rgb(221, 221, 221)',marginTop: '10px',width:'100%'}} value={this.state.smoking_comments} onChange={(e)=>this.setState({smoking_comments:e.target.value})}></textarea>
    </div>
  </div>
  <div className="row mb-4">
    <div className="col-sm-12 text-right" style={{marginLeft: '-35px'}}>
      <button className="btn btn-primary btn-xs" onClick={(e)=>this.saveClickHandler()}>Save</button>
      <button className="btn btn-primary btn-xs" onClick={(e)=>this.reloadPage()}>Cancel</button>
    </div>
  </div>
</div>

            </div>
          );
        }
    }
    const mapDispatchToProps = dispatch => {
      return {
               apitobaccohistory: (data) => dispatch(api_tobacco_history(data)),
               getHistory:data=>dispatch(api_get_history(data))
              };
    };
    const mapStateToProps = state => {
     console.log(">>> doc data",state.getDrAppointments.logged_info)
       let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
       let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
   
     return {
       redux_patientId,redux_docId
     };
   };

export default connect(
  mapStateToProps,
mapDispatchToProps
) (tobaccoHistory);