import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {api_get_inbox_patient} from '../../../DS/DS.Inbox'
import { data, timers } from 'jquery';
import './inbox.css';
import Reminder from '../reminders/reminders';
import Diagonsis from '../visitNotes/diagonsis'
import AddCorrespondence from '../Correspondence/addCorrespondence'
import Showhelp from './../../showHelp';

class inbox extends Component {
    constructor(props)
    {
      super(props);
      this.state=
      {
        open:this.props.open,
        inbox_list:[],
        showImg:false,
        imgname:'',
        showReminder:false,
        selectedUser:'',
        key:0,
        showpast:false,
        result:'',
        action:'',
        storein:'',
        disable:true,
        disableBtn:true,
        showDetails:false,
        selectedIndex:0,
        selectedId:0,
        disablenext:false,
        disableprev:true,
        comments:'',
        commentList:[]
      }
    }



    
    componentWillMount=()=>
    {
        console.log('&&&&&&&&&&&&&&&&&&&&',this.props)

        this.props.getPatient({doc:this.props.redux_docId[0].doctor_id}).then(res=>
            {
                if(res.data.content!='')
                {
                this.setState({inbox_list:res.data.content})
                this.loadImage(res.data.content[0].inbox[0],this.state.selectedIndex,this.state.selectedId)
                }
            })
    }
    loadImage=(imgdata,i,index)=>
    {
        console.log('&&&&&&&&&&&&&&&&&&&&',index)
        console.log('&&&&&&&&&&&&&&&&&&&&',i)
        this.setState({showImg:true,imgname:imgdata['document_name'],selectedUser:imgdata['patient_id']})
        
    }
   closeAll=()=>
   {
       this.setState({key:Math.random(),showReminder:false,showpast:false,showDetails:false})
   }
   noactionbtn=()=>
   {
       this.setState({result:'Normal',action:'No action'})
       this.nextBtn()
   }
   handleRadio=(val)=>
   {
       this.setState({result:val})
   }
   handleAction=(val)=>
   {
       this.setState({action:val})
   }
   nonactionbtn=()=>
   {
    this.setState({result:'Abnormal',action:'Non-urgent appointment'}) 
    this.nextBtn()
    
   }
   handleStore=(val)=>
   {
      
       this.setState({storein:val})
       if(val!='Correpondence')
       {
           this.setState({disableBtn:true,key:Math.random()})
       }
       else
       {
           this.setState({disableBtn:false,key:Math.random()})
       }
   }
   nextBtn=()=>
   {
       if(this.state.selectedIndex === this.state.inbox_list.length)
       {
        this.setState({disablenext:true,disableprev:false}) 
       }
       else
       {
       
        console.log("&&&&&&&&&&&&&&&&&&&&&&1",this.state.selectedIndex)
        console.log("&&&&&&&&&&&&&&&&&&&&&&2",this.state.inbox_list[this.state.selectedIndex].inbox.length)
            if(this.state.selectedId == this.state.inbox_list[this.state.selectedIndex].inbox.length)
            {
                this.state.selectedIndex=this.state.selectedIndex +1;
                this.state.selectedId=0;
            }
            else
            {
               
                this.setState({showImg:true,imgname:this.state.inbox_list[this.state.selectedIndex].inbox[this.state.selectedId]['document_name'],selectedUser:this.state.inbox_list[this.state.selectedIndex].inbox[this.state.selectedId]['patient_id']})
                this.state.selectedId=this.state.selectedId+1
            }
        }
       
   }
   addComment=()=>
   {
       this.state.commentList.push(this.state.comments)
       this.setState({comments:''})
   }
    render(){
        return(
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div style={{ width:'1800px', marginLeft:'-430px', backgroundColor:'#FFF', float:'left' }}>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                        <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px', fontSize:'16px',marginBottom:'0px'}}>Inbox <Showhelp gotohelp="inbox_help"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.closeAll()} style={{color:'black'}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ fontSize: "14px",overflow: 'none',height:'850px', width: '99.2%' }}>
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-xl-3 m-b-0" style={{paddingLeft:'25px' }}>
	                            <div className="card" style={{paddingLeft: '0px',border:'1px solid #ccc'}}>
		                            <div className="card-block">
		                                <div className="well no-padding" style={{ marginBottom:'0px'}}>
                                            <div style={{overflowY:'scroll', height:'300px'}}>
                                                <ul className="navpat nav-list nav-menu-list-style">
                                                    <li style={{marginLeft: '-48px'}}>
                                                        <ul className="navpat nav-list bullets">
                                                        {this.state.inbox_list.length > 0 && this.state.inbox_list.map((item, index) => (
                                                              
                                                            <li>
                                                                <label className="tree-toggle nav-header"  style={{padding:'0px'}}>
                                                                   
                                                                    <div className="newdesign">
                                                                        <a title="" onclick="inboxclose1();" style={{fontWeight:'bold', cursor:'pointer', color:'#333333'}}>
                                                                            <input type="checkbox" name="invest" value=""/> {item.patient_name}
                                                                        </a>
                                                                        <div style={{width:'auto', height:'30px', marginTop:'0px', float:'right'}}>
                                                                            <span className="makescreen">
                                                                                <a href="#" title="Mark As Given"  style={{padding:'0px'}}>
                                                                                    <i className="fa fa-pencil" aria-hidden="true" style={{fontSize:'16px',marginRight:'5px'}}></i>
                                                                                </a>
                                                                            </span>
                                                                            <a href="#" className="inboxappsearch" title="Patient details" style={{padding:'0px'}}>
                                                                                <i className="fas fa-list" aria-hidden="true" style={{fontSize:'16px',marginRight:'5px'}}></i>
                                                                            </a>
                                                                            <a href="#" title="Summary" onclick="inboxopen();"  style={{padding:'0px'}}>
                                                                                <i className="fas fa-file-alt" aria-hidden="true" style={{fontSize:'16px',marginRight:'5px'}}></i>
                                                                            </a>
                                                                            <a href="#" title="Open" onclick="inboxclose();"  style={{padding:'0px'}}>
                                                                                <i className="fa fa-envelope-open" aria-hidden="true" style={{fontSize:'16px', marginRight:'5px'}}></i>
                                                                            </a>
                                                                            <a href="#" title="Move" data-toggle="modal" data-target="#movedoctor" style={{padding:'0px'}}>
                                                                                <i className="fa fa-compress" aria-hidden="true"style={{fontSize:'16px',marginRight:'5px'}}></i>
                                                                            </a>
                                                                            <a href="#" title="Delete"  style={{padding:'0px'}}>
                                                                                <i className="fas fa-trash-alt" aria-hidden="true" style={{fontSize:'16px',marginRight:'5px'}}></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
				                                                </label>
                                                                {item.inbox.map((item1, i) => (
					                                            <ul className="navpat nav-list">
                                                                    <li onClick={()=>this.loadImage(item1,i,index)}>
                                                                        <a >
                                                                        {item1.description}
                                                                        </a>
                                                                    </li>
                                                                </ul>))}
				                                            </li>))}
				                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

		                            </div>
	                            </div>
                                <label className="col-sm-12 col-form-label text-left" style={{lineHeight:'.5', fontSize:'12px', padding: '10px 0px 10px 0px'}}>
                                    <span style={{marginTop:'10px', float:'left'}}>
                                        <input type="checkbox" value="" id="check_allin" style={{marginLeft:'10px'}}/>  Outstanding requests:
                                    </span>
                                    <div style={{width:'auto', height:'auto', marginTop:'5px', float:'right'}}>
					                    <span className="makescreen">
                                            <a href="#" title="Mark as Received" style={{padding:'0px'}}>
                                                <i className="fa fa-pencil" aria-hidden="true" style={{fontSize:'12px', marginRight:'5px'}}></i>
                                            </a>
                                        </span>	
					                    <a href="#" title="Delete" style={{padding:'0px'}}>
                                            <i className="fas fa-trash-alt" aria-hidden="true" sstyle={{fontSize:'12px', marginRight:'5px'}}></i>
                                        </a>
					                </div>
                                </label>
	                            <div className="card" style={{paddingLeft: '10px', overflowY:'scroll', height:'60px', fontSize:'12px', border:'1px solid #ccc'}}>
		                            <div className="card-block">
		                                <div className="col-sm-12" style={{paddingLeft:'0px', paddingRight:'0px'}}> 
			                                <div className="col-sm-6" style={{paddingLeft:'0px'}}>
                                                <input type="checkbox" value=""/>01/04/2019
                                            </div>
			                                <div className="col-sm-6" style={{paddingRight:'0px'}}>TFT
			                                    <div style={{width:'auto', height:'20px', marginTop:'0px', float:'right'}}>
					                                <span className="makescreen">
                                                        <a href="#" title="Mark as Received" style={{padding:'0px'}}>
                                                            <i className="fa fa-pencil" aria-hidden="true" style={{fontSize:'12px', marginRight:'5px'}}></i>
                                                        </a>
                                                    </span>	
					                                <a href="#" title="Delete" style={{padding:'0px'}}>
                                                        <i className="fas fa-trash-alt" aria-hidden="true" style={{fontSize:'12px', marginRight:'5px'}}></i>
                                                    </a>
					                            </div>
			                                </div>
		                                </div>
		                                <div className="col-sm-12" style={{paddingLeft:'0px', paddingRight:'0px'}}>
			                                <div className="col-sm-6" style={{paddingLeft:'0px'}}>
                                                <input type="checkbox" value=""/>20/03/2017
                                            </div>
			                                <div className="col-sm-6" style={{paddingRight:'0px'}}>Modified rast
			                                    <div style={{width:'auto',height:'20px', marginTop:'0px', float:'right'}}>
					                                <span className="makescreen">
                                                        <a href="#" title="Mark as Received" style={{padding:'0px'}}>
                                                            <i className="fa fa-pencil" aria-hidden="true" style={{fontSize:'12px', marginRight:'5px'}}></i>
                                                        </a>
                                                    </span>	
					                                <a href="#" title="Delete" style={{padding:'0px'}}>
                                                        <i className="fas fa-trash-alt" aria-hidden="true" style={{fontSize:'12px', marginRight:'5px'}}></i>
                                                    </a>
					                            </div>
			                                </div>
		                                </div>
		                            </div>
	                            </div>
                            </div>
                            
                            <div className="col-sm-12 col-md-9 col-xl-9 m-b-0">
	                            <div className="row">
	                                <div id="inboxopendetails" className="modal-body" style={{padding:'0px', marginLeft:'0px'}}>
		                                <div className="m-t-0" style={{overflowY:'scroll', paddingLeft:'0px', height:'520px'}}>
                                        { this.state.showImg ?
                                        <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}Inbox/`+this.state.imgname} style={{width: '100%'}} />:'No Preview' }
                                        </div>
		                            </div>
	                            </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-xl-3 m-b-0" style={{ paddingLeft: '25px'}}>
		                        <label className="col-sm-12 col-form-label text-left" style={{lineHeight:'.5', fontSize:'12px', padding: '10px 0px 10px 0px'}}>Comment:</label>
			                        <textarea value={this.state.comments} onChange={(e)=>this.setState({comments:e.target.value})}></textarea>
			                        <div className="col-sm-12" style={{paddingRight:'0px'}}>
                                        <div style={{width:'auto', height:'auto', marginTop:'5px', float:'right'}}>
                                            <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male" onClick={(e)=>this.addComment()}> Add</button>
                                            <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male" onClick={(e)=>this.addComment(e)}> Delete</button>
                                        </div>
					                </div>
                                    <div className="card" style={{paddingLeft: '10px', overflowY:'scroll', height:'60px', border:'1px solid #ccc'}}>
                                        <table>
                                        {this.state.commentList!=undefined && this.state.commentList.map((item, i) => (
                                            <tr>
                                                    <td> {item}</td>
                                            </tr>
                                        ))}
                                        </table>
			                    </div>
	                        </div>
                            <div className="col-sm-12 col-md-9 col-xl-9 m-b-0" style={{marginTop:'0px'}}>
		                        <div className="row">
			                        <div className="col-sm-12 col-md-3 col-xl-3 m-b-0 listbox">
			                            <label className="label-input100 listbox-title">This result is</label>
				                            <label className="label-input100 listinbox" onClick={()=>{this.handleRadio('Normal')}}>
                                                <input id="normal" type="radio" name="radio2"  value={this.state.result}  checked={this.state.result=='Normal' ?'checked':''}/>
                                                <span style={{color:'#000'}}> Normal</span>
                                            </label>
                                            <label className="label-input100 listinbox" onClick={()=>{this.handleRadio('Abnormal')}}>
                                                <input type="radio" name="radio2" value={this.state.result}  checked={this.state.result=='Abnormal' ?'checked':''}/>
                                                    <span style={{color:'#000'}}> Abnormal</span>
                                                </label>
                                            <label className="label-input100 listinbox" onClick={()=>{this.handleRadio('Stable')}}>
                                                <input type="radio" name="radio2" value={this.state.result}  checked={this.state.result=='Stable' ?'checked':''}/>
                                                    <span style={{color:'#000'}}> Stable</span>
                                            </label>
                                            <label className="label-input100 listinbox" onClick={()=>{this.handleRadio('Acceptable')}}>
                                                <input type="radio" name="radio2" value={this.state.result}  checked={this.state.result=='Acceptable' ?'checked':''}/>
                                                    <span style={{color:'#000'}}> Acceptable</span>
                                             </label>
                                            <label className="label-input100 listinbox" onClick={()=>{this.handleRadio('Unacceptable')}}>
                                                <input type="radio" name="radio2" value={this.state.result}  checked={this.state.result=='Unacceptable' ?'checked':''}/>
                                                <span style={{color:'#000'}}> Unacceptable</span>
                                            </label>
                                            <label className="label-input100 listinbox" onClick={()=>{this.handleRadio('Being treated')}}>
                                                <input type="radio" name="radio2" value={this.state.result}  checked={this.state.result=='Being treated' ?'checked':''}/>
                                                <span style={{color:'#000'}}> Being treated</span>
                                            </label>
                                            <label className="label-input100 listinbox" onClick={()=>{this.handleRadio('Under specialist care')}}>
                                                <input type="radio" name="radio2"  value={this.state.result}  checked={this.state.result=='Under specialist care' ?'checked':''}/>
                                                <span style={{color:'#000'}}> Under specialist care</span>
                                            </label>
				                            <div className="col-sm-12 col-md-12 col-xl-12" style={{marginTop:'30px'}}>
                                                <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male" onClick={()=>this.noactionbtn()}>Normal + No Action + Next</button>
                                                <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male" onClick={()=>this.nonactionbtn()}>Abnormal + Non Urgent + Next</button>
				                            </div>
			                            </div>
			                            <div className="col-sm-12 col-md-3 col-xl-3 m-b-0 listbox" style={{paddingLeft:'0px', paddingRight:'0px'}}>
			                                <label className="label-input100 listbox-title">Action to be taken</label>
				                            <label className="label-input100 listinbox"  onClick={()=>{this.handleAction('No action')}}>
                                                <input id="action" type="radio" name="radio1"  value={this.state.action}  checked={this.state.action=='No action' ?'checked':''}/>
                                                    <span style={{color:'#000'}}> No action</span>
                                            </label>
                                            <label className="label-input100 listinbox"  onClick={()=>{this.handleAction('Send routine reminder')}}>
                                                <input type="radio" name="radio1" value={this.state.action}  checked={this.state.action=='Send routine reminder' ?'checked':''}/>
                                                <span style={{color:'#000'}} > Send routine reminder</span>
                                            </label>
                                            <label className="label-input100 listinbox"  onClick={()=>{this.handleAction('Non-urgent appointment')}}>
                                                <input type="radio" name="radio1" value={this.state.action}  checked={this.state.action=='Non-urgent appointment' ?'checked':''}/>
                                                <span style={{color:'#000'}}> Non-urgent appointment</span>
                                            </label>
                                            <label className="label-input100 listinbox"  onClick={()=>{this.handleAction('Urgent appointment')}}>
                                                <input type="radio" name="radio1" value={this.state.action}  checked={this.state.action=='Urgent appointment' ?'checked':''}/>
                                                <span style={{color:'#000'}}> Urgent appointment</span>
                                            </label>
				                            <div className="col-sm-12 col-md-12 col-xl-12" style={{marginTop:'80px'}}>
                                                <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male" onClick={()=>this.setState({showReminder:true})}> Add Reminder</button>
                                                <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male" onClick={()=>this.setState({showpast:true})}>Add Past History</button>
                                                <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male">Add Action</button>
				                            </div>
			                            </div>
			                            <div className="col-sm-12 col-md-6 col-xl-6 m-b-0 listbox">
				                            <div className="col-sm-12 col-md-6 col-xl-6 m-b-0 listbox">
								                <label className="label-input100 listbox-title">Store result in</label>
                                                <label className="label-input100 listinbox" onClick={()=>{this.handleStore('Investigation')}}>
                                                    <input type="radio" name="radio" value={this.state.storein}  checked={this.state.storein=='Investigation' ?'checked':''}/>
                                                    <span style={{color:'#000'}}> Investigations</span>
                                                </label>
                                                
                                                <label className="label-input100 listinbox" onClick={()=>{this.handleStore('Correpondence')}}>
                                                    <input type="radio" name="radio" value={this.state.storein}  checked={this.state.storein=='Correpondence' ?'checked':''}/>
                                                    <span style={{color:'#000'}}> Correspondence in</span>
                                                </label>
                                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary check-male chkdet" disabled={this.state.disableBtn}
                                                onClick={()=>this.setState({showDetails:true})}>Details</button>
				                         
                                                    <label className="label-input100 listinbox" onClick={()=>{this.handleStore('Clinical')}}>
                                                    <input type="radio" name="radio"  disabled={this.state.disable} value={this.state.storein}  checked={this.state.storein=='Clinical' ?'checked':''}/>
                                                    <span style={{color:'#000'}} > Clinical images</span>
                                                </label>
					                            <div className="col-sm-12 col-md-12 col-xl-12" style={{marginTop:'80px'}}>
                                                    <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male">Prev</button>
                                                    <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male">Move to Another</button>
                                                    <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male" onClick={()=>this.nextBtn() } disabled={this.state.disablenext}>Next</button>
                                                   {/*} <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male">Orphaned  Reports</button>*/}
                                                    <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male">Skip</button>
                                                    <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male" onClick={()=>this.props.closeAll()}>Finish</button>
				                                </div>
				                            </div>
				                            <div className="col-sm-12 col-md-6 col-xl-6 m-b-0 listbox">
							                    <label className="label-input100 listbox-title">Store for location</label>
					                            <label className="label-input100 listinbox">
                                                    <input type="radio" name="radio"/>
                                                    <span style={{color:'#000'}}> Include header</span>
                                                </label>
					                            <div className="col-sm-12 col-md-12 col-xl-12" style={{marginTop:'130px'}}>
					                                <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} onclick="inboxmore();" type="button" className="btn btn-primary check-male">More</button>
					                                <div id="inmore">
                                                        <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male">Add INR</button>
                                                        <button id="session-timeout-dialog-keepalive" style={{marginBottom:'5px'}} type="button" className="btn btn-primary check-male">Add CST</button>
				                                    </div>
				                                </div>
				                            </div>
			                            </div>
	                                </div>
                                </div>
                        </div>
                    </div>
                    {this.state.showReminder ?
                    <Reminder changeKey={this.closeAll} showModal={this.state.showReminder} patient={this.state.selectedUser}/> :''}
                    {this.state.showpast ?
                    <Diagonsis openDiagonsis={this.state.showpast} closeDiagonsis={this.closeAll} patient={this.state.selectedUser}/> :''}
                     {this.state.showDetails ?
                    <AddCorrespondence showmodal={this.state.showDetails} changekey={this.closeAll} patient_id={this.state.selectedUser} sid=''/> :''}
                </div>
            )}
            </Popup>
        )}           
}
const mapDispatchToProps = (dispatch) => {
	return {
        getPatient: (data) => dispatch(api_get_inbox_patient(data)),
     
  }
}
const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details:'';
  
  return {
    redux_patientId,redux_docId
  };
  };
export default connect (mapStateToProps, mapDispatchToProps) (inbox);