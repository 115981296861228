import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import AccountDetails from './AccountDetails';


class Editpatient extends Component {
  constructor(props){
    super(props);
    this.state = {
     
        
    };
   
    
  }
 
  
  render() { 
  return ( 
      <Popup trigger={ <a title="Editpatient"  className="collapsed btn btn-default" aria-expanded="false">Patient Details</a>} position="right center" modal
      closeOnDocumentClick={false} contentStyle={{overflowY:'scroll'}}>
       {close => (
      <div> 
           <div className="modal-header" style={{width: '100%', backgroundColor: '#f4f4f4', padding: '5px 15px'}}>
    <h5 style={{backgroundColor: '#f4f4f4', width: '100%', padding: '5px 10px', fontSize: '16px', marginBottom: '0px'}}>Edit Patient</h5>
    <button type="button" className="close" data-dismiss="modal" onClick={()=>close()} aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
       <div style={{float:'left', width:'100%', height:'430px'}}>
                        <div className="row" style={{marginLeft:'0px'}}
                        >
				 

					<div className="col-sm-12 col-xl-6 m-b-10">
						<div className="form-group row">
							<label className="col-sm-2 col-form-label text-left">Title:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-left">Health identifier</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">Family Name:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>	
							<label className="col-sm-2 col-form-label text-left">HI Status</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">Given Name:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-left">Medicare No.</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
					
							<label className="col-sm-2 col-form-label text-left">Middle Name</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-left">Pension/HCC No</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">Preferred Name</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
	                        <label className="col-sm-2 col-form-label text-left">Pension Card type</label>
		       					<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>	
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">Date of Birth:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						<label className="col-sm-2 col-form-label text-left">DVA No</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>	
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">Sex:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-left">Sefety Net No</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						
						
						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">Ethnicity</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-left">Record No</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">Address Line 1:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-left">Usual Doctor:</label>

							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">Address Line2:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							
							<label className="col-sm-2 col-form-label text-right">Deny Access to Users</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						
						
						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">City/Suburb:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							
							<label className="col-sm-2 col-form-label text-right">Usual visit type:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">Postal Adrress</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							
							<label className="col-sm-2 col-form-label text-right">Usual visit account:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>

						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">City/Suburb:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							
							<label className="col-sm-2 col-form-label text-right">Health Ins.fund:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>

						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">Home Phone:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							
							<label className="col-sm-2 col-form-label text-right">Health Ins. No.:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						
						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">Mobile Phone:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-left">Religion:</label>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>

						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">Contacts to:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-left">Head of Family:</label>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">Email:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-left">Next of kin:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
							<label className="col-sm-2 col-form-label text-left">Appointment Notes:</label>
							<div className="col-sm-2">
							<textarea rows="3" cols="5" className="form-control" placeholder="Default textarea"></textarea>
							</div>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>

						<div className="form-group row">
							<label className="col-sm-12 col-form-label text-left alert-danger" style={{marginTop:'10px'}} >* These name fields are used for Health Identifier lookups.</label>
						</div>
						
					</div>
					
					<div className="col-sm-12 col-xl-6 m-b-10">
						<div className="form-group row">
							<label className="col-sm-3 col-form-label text-left">Health Identifier:</label>
							<div className="col-sm-9">
							<div className="input-group input-group-button">
							<input type="text" className="form-control form-control-sm" placeholder="Right Button"/>
							 </div>
							</div>
						</div>
						
						<div className="form-group row">
							<label className="col-sm-3 col-form-label text-left">HI Status:</label>
							<div className="col-sm-5">
							<div className="input-group">
								<select name="user" className="form-control"  >
								<option>Select HI Status</option>
								</select>
								<span className="md-line"></span>
								</div>
							</div>
							<div className="col-sm-4">
							<div className="input-group">
                                <select name="user" className="form-control" 
                                >
								<option>Select HI Status</option>
								</select>
								<span className="md-line"></span>
								</div>
							</div>
							
						</div>
						
						<div className="form-group row">
							<label className="col-sm-3 col-form-label text-left">Medicare No:</label>
							<div className="col-sm-3">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-1 col-form-label text-left">IRN:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-1 col-form-label text-left">Expiry:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
							<label className="col-sm-2 col-form-label text-left">Pension/HCC No</label>
							<div className="col-sm-4">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-left">Expiry:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
							<label className="col-sm-2 col-form-label text-left">Pension card</label>
							<div className="col-sm-9">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						
						
						<div className="form-group row">
							<label className="col-sm-2 col-form-label text-left">DVA No:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<div className="col-sm-2">
							<div className="input-group">
								<select name="user" className="form-control" >
								<option>--</option>
								</select>
								<span className="md-line"></span>
								</div>
							</div>
							<div className="col-sm-2" style={{paddingLeft:'15px'}}>
							<div className="input-group">
								<select name="user" className="form-control" >
								<option>--</option>
								</select>
								<span className="md-line"></span>
								</div>
							</div>
						</div>
						
						<div className="form-group row">
							<label className="col-sm-2 col-form-label text-left">Sefety Net No:</label>
							<div className="col-sm-9">
							<input type="text" className="form-control form-control-sm"/>
							</div>							
						</div>
						
						<div className="form-group row">
							<label className="col-sm-2 col-form-label text-left">Record No:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-left"style={{paddingLeft:'15px'}}>Patient ID:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
							<label className="col-sm-2 col-form-label text-left">Usual doctor:</label>
							<div className="col-sm-2">
							<div className="input-group">
								<select name="user" className="form-control" >
								<option>--</option>
								</select>
								<span className="md-line"></span>
								</div>
							</div>
							<label className="col-sm-2 col-form-label text-left" style={{paddingLeft:'15px'}}>Usual visit type:</label>
							<div className="col-sm-2">
							<div className="input-group">
								<select name="user" className="form-control"  >
								<option>--</option>
								</select>
								<span className="md-line"></span>
								</div>
							</div>
						</div>
						
						
						<div className="form-group row">
							<label className="col-sm-2 col-form-label text-left">Usual Account:</label>
							<div className="col-sm-9">
							<div className="input-group">
								<select name="user" className="form-control" >
								<option>--</option>
								</select>
								<span className="md-line"></span>
								</div>
							</div>
						</div>

						
						<div className="form-group row">
							<label className="col-sm-2 col-form-label text-left">Health Ins. No:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-right">Postcode:</label>
							<div className="col-sm-2" style={{paddingLeft:'15px'}}>
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
							<label className="col-sm-2 col-form-label text-left">Home Phone:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-right">Work Phone:</label>
							<div className="col-sm-2" style={{paddingLeft:'15px'}}>
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
							<label className="col-sm-2 col-form-label text-left">Religion:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-right">Contact Via:</label>
							<div className="col-sm-2" style={{paddingLeft:'15px'}}>
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						
						<div className="form-group row">
							<label className="col-sm-2 col-form-label text-left">Head of Family:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-right">Next to kin:</label>
							<div className="col-sm-2" style={{paddingLeft:'15px'}}>
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
						
						<div className="form-group row">
							<label className="col-sm-2 col-form-label text-left">Emergency Contact:</label>
							<div className="col-sm-2" style={{paddingLeft:'15px'}}>
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-right">Occuption:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>							
						</div>
						
						<div className="form-group row">
						<label className="col-sm-2 col-form-label text-left">Healthcare Home:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							
						</div>
												
						<div className="form-group row">
							<div className="col-sm-12" style={{marginTop:'10px'}}>
							<button id="session-timeout-dialog-logout" type="button" className="btn btn-default">Referral Details</button>
							<button id="session-timeout-dialog-logout" type="button" className="btn btn-default">Bank Account</button>
							<button id="session-timeout-dialog-logout" type="button" className="btn btn-default">Medicare/DVA Eligibility Check</button>
							</div>
						</div>
						
						
						<div className="form-group row">
                            <div className="col-sm-12" style={{marginTop:'10px',textAlign:'center'}}
                            >
							<button id="session-timeout-dialog-logout" type="button" className="btn btn-default">Submit</button>
							</div>
						</div>
						
					</div>
				</div>
                    </div>
                    
       
  </div>
     )}
     </Popup>
  );
}
}
 
export default Editpatient;