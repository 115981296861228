import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import { api_getDoctors } from '../../../DS/DS.Appointments';
import { api_contact_category,get_category_contact,api_search_contact } from '../../../DS/DS.Correspondence';
import NewContact from './newContact';
import EditContact from './editContact';
//import "react-datepicker/dist/react-datepicker.css"
import "./correspondence.css";
import Showhelp from './../../showHelp';

class SearchDoctors extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            contact_cat:[],
            newSearch:'',
            showmodal:false,
            selectedCat:this.props.default!=undefined?this.props.default:'',
            contactData:[],
            showSelectedContent:[],
            key:0,
        }
    }
    componentDidMount(){
        this.setState({open:this.props.searchDoc})
        this.props.getContactCategory().then(res => {
            this.setState({
                contact_cat: res.data.content ,
            })}
        
        )

        if(this.props.default != undefined){
          //  alert("default"+this.props.default);
            const catid={catid:this.props.default}
            this.props.getContactnames(catid).then(noteStat=>{
                this.setState({contactData:noteStat.data.content})
               });
        }
    } 
    openNewContact(){
        this.setState({showmodal:true})
    }
     

    getCategoryContact=(e)=>
    {
        console.log(">>>>>",e.target.value)
        const catid={catid:e.target.value}
        this.setState({selectedCat:e.target.value});
       // this.props.getContactnames(catid)
       this.props.getContactnames(catid).then(noteStat=>{
        this.setState({contactData:noteStat.data.content})
       });
    }
    showSelectedContent(e, item, i) {
        this.setState({ showSelectedContent: item });
       
      }
      setTrClass = (id) => {
        let classs = "";
        classs +=
          id == this.state.showSelectedContent.contact_id
            ? " selectedBg "
            : "";
        return classs;
      };
      refreshPage=()=>
      {
        this.setState({key:Math.random()});
        this.setState({showeditmodal:false});
        this.setState({showmodal:false});
        this.state.showSelectedContent=[];
      }
      openEditContact=()=>
      {
          this.setState({showeditmodal:true})
      }
      searchRecord=(e)=>
      {
          console.log(e)
          const searchtext={
              searchText:e.target.value
          }
        this.props.getsearchcontact(searchtext).then(
            req=>{
               if(req.data.content!='')
               {
                this.setState({contactData:req.data.content})}
               
               else
               {
                    if(this.state.selectedCat!='')
                    {
                        const catid={catid:this.state.selectedCat}
                    
                        this.props.getContactnames(catid).then(noteStat=>{
                            this.setState({contactData:noteStat.data.content})
                           });
                           
                    }
                    else{    
                    this.setState({contactData:[]})}
               }
            }
        )
      }
    render() {
        return (
        <Popup
        open={this.state.open}
        position="right center"
        modal
        closeOnDocumentClick={false}
       
        onClose={this.closeModal}>
         {close => (
                    <div style={{ width:'1000px', margin:'0px auto'}}>
                    <div style={{ width:'1000px', marginLeft:'20px', backgroundColor:'#FFF', float:'left'}}> 
                        <div className="modal-header" style={{width: '100%', padding: '0px 15px'}}>
		                    <h6 style={{float:'left', fontSize: '16px'}}>Select Contact <Showhelp gotohelp="search_contact_help"/></h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{marginTop:'10px'}}>
                                    <span aria-hidden="true" >×</span>
                                </button>
                        </div>
                        <div className="modal-body" style={{ float:'left' }}>
                            <div className="col-sm-12" style={{width: '100%',paddingLeft:'0px', overFlow:'auto'}}>
				                <div className="row ml-2">				
							        <label className="col-sm-1 pt-3 pr-0 text-left">New Search</label>
							        <div className="col-sm-3">
							            <input type="text" className="form-control form-control-sm" onChange={(e)=>this.searchRecord(e)}/>
							        </div>
				                    <label className="col-sm-1 pt-3 text-right">Category</label>
							        <div className="col-sm-3">
							          
							         
                                    <select value={this.state.selectedCat} name="select" className="form-control" onChange={(e) => this.getCategoryContact(e)}>
                                        <option value="">Select Category</option>
                                            {this.state.contact_cat != undefined && this.state.contact_cat.length> 0 && this.state.contact_cat.map((cntcat) => <option key={cntcat.category_id} value={cntcat.category_id}>{cntcat.contact_name}</option>)}
                                    </select>
                                    </div>
						        </div>
				                <div className="row ml-2">				
							        <label className="col-sm-12 text-left">Location Directory:</label>
						        </div>
				                <div className="row ml-2">
				                    <div className="col-sm-12">				
							            <div className="col-sm-12" style={{padding:'0px',float:'left',height:'300px', border:'1px solid #ddd'}}>

                                        <table style={{width:'inherit'}}>
                                            <tr>
                                            <th>&nbsp; Name</th>
                                            <th>&nbsp; Address</th>
                                           </tr>			
							           {this.state.contactData!=undefined && this.state.contactData.map((foundData,i)=>
                                           <tr  onClick={e =>
                                            this.showSelectedContent(e, foundData, i)

                                          }
                                          className={this.setTrClass(foundData.contact_id)}
                                          >
                                                <td>{foundData.display}.{foundData.first_name}.{foundData.surname} </td>
                                                <td>{foundData.address}&nbsp;{foundData.address}</td>
                                           </tr>

                                       )}
                                       </table>
                                        </div>
						            </div>
						        </div>
				                <div className="form-group row">				
							        <div className="col-sm-3 mt-3" style={{paddingRight:'0px'}}>
							            <button type="button" className="btn btn-primary btn-xs" data-toggle="modal" data-target="#newsearchcontact" style={{marginRight:'10px'}} onClick={()=>this.openNewContact()}>New Contact</button>
							            <button type="button" className="btn btn-primary btn-xs" data-toggle="modal" data-target="#editsearchcontact" onClick={()=>this.openEditContact()}>Edit Contact</button>
							        </div>
                                    { (this.state.showmodal)    ?
                                        <NewContact  showmodal={this.state.showmodal} changeKey={this.refreshPage}/>
                                    :''}
                                     { (this.state.showeditmodal)    ?
                                        <EditContact  showmodal={this.state.showeditmodal} changeKey={this.refreshPage} cid={this.state.showSelectedContent.contact_id}/>
                                    :''}
				                    <div className="col-sm-8 mt-4">
							            <span className="col-sm-7  text-left" style={{paddingLeft:'0px', paddingRight:'0px',fontSize:'12px',fontWeight:'100'}}>Synchronised with Online Directory</span>
							            <span className="col-sm-5  text-left" style={{paddingLeft:'0px', paddingRight:'0px',fontSize:'12px',fontWeight:'100'}}>Secure eReferrals available</span>
							        </div>
						        </div>
                            </div>
                            <div className="modal-footer" style={{float:'left', width:'100%', marginTop:'25px'}}>
                                <button type="button" className="btn btn-primary btn-xs" onClick={()=>{this.props.changeKey(this.state.showSelectedContent);close();}}>Use Contact</button>
		                        <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" onClick={()=>{this.props.changeKey('');}} >Cancel</button>
                            </div>
                        </div>
                    </div>
                    </div>
          )}
          </Popup>
          )
    }
}
const mapDispatchToProps = (dispatch) => {
	return {
        getContactCategory:()=>dispatch(api_contact_category()),
        getContactnames:data=> dispatch(get_category_contact(data)),
        getsearchcontact:data=> dispatch(api_search_contact(data))
  }
}
export default connect (null, mapDispatchToProps) (SearchDoctors);
