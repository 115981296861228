import React, { Component } from "react";
import Form from "react-jsonschema-form";
import {NotificationContainer, NotificationManager} from 'react-notifications';
//import axios from "axios";
import { addForm } from "../../../DS/services";
class jsonform extends Component {
  state = {
    schema: {}
  };
  
  onSubmit = ({ formData }, e) => {
    let postBodyData = {
      formData: formData,
      prop: this.props.form
    };
    addForm(postBodyData)
    .then(res => {
     if(res.status === 200){
      NotificationManager.success("Successfully Added");
      this.setState({
        formData:{}
      });
      this.props.backtogrid();
     }
       else {
        NotificationManager.error("Something went to Wrong, Try again later");
       }
    })
  };

  render() {
    console.log(this.props);
    const uiSchema = this.props.form.ui_schema;
    const log = type => console.log.bind(console, type);
    let formData = {gender: "1", name: "1", medical_center_id: "2", key_contact: "2", mobile: "2"};
    return (

      <div>
         <NotificationContainer/>
        <Form
          schema={this.props.form.schema}
          uiSchema={uiSchema}
          onChange={log("changed")}
          onSubmit={this.onSubmit}
          onError={log("errors")}
        />
      </div>
    );
  }
}

export default jsonform;
