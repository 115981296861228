import React, { Component } from "react";

import "./add-patient-form.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { api_list_options, api_getTitle,api_banknames,api_getGender } from "./../../DS/DS.Appointments";
import { connect } from "react-redux";
import {add_patient_account,get_patient_account} from './../../DS/DS.BankAccount';
import Addappointment from './add-appointment';
class AddPatientForm extends React.Component {
  constructor() {
    super();
    this.state = {
      title: "",
      family_name: "",
      given_name: "",
      middle_name: "",
      Perferred_name: "",
      date_of_birth: "",
      age: 0,
      sex: 1,
      ethnicity: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      postcode: "",
      post_address: "",
      postal_address_city: "",
      postal_address_postcode: "",
      home_phone: "",
      work_phone: "",
      mobile_phone: "",
      contact_via: "",
      email: "",
      general_notes: "",
      appointment_notes: "",
      health_identifier: "",
      hi_status: "",
      medicare_no: "",
      medicare_irn: "",
      medicare_expiry: "",
      pension: "",
      pension_expiry: "",
      pension_card: "",
      dva_no: "",
      sefety_net_no: "",
      record_no: "",
      patient_id: "",
      usual_doctor: "",
      usual_visit_type: "",
      usual_account: "",
      health_ins_no: "",
      religion: "",
      head_of_family: "",
      next_to_kin: "",
      emerygency_contact: "",
      occuption: "",
      healthcare_home: "",
      hi_status_1: "",
      hi_status_2: "",
      dva_no_1: "",
      dva_no_2: "",
      submitdisbled: false,
      list_options: {},
      bank_name: "",
      account_name: "",
      account_number: "",
      bsb_number: "",
      referral_date: "",
      referral_expiry: "",
      referral_type: "", 
      referral_to: "",
      referral_by: "",
      referral_gp_specialist: "",
      show_tab : "bank", 
      showotherEthinicity:false,
      otherEthnicity:'',
      list_title:[],
      list_bank_name:[],
      list_gender:[],
      account_id:'',
      openappoin:false

      
    };
  }
 
componentWillMount=()=>
{
  this.props.getTitle().then(res=>
    {
      this.setState({list_title: res.data.content})
    })
    
    this.props.getBankNames().then(res=>
      {
        this.setState({list_bank_name: res.data.content})
      })

      this.props.getGender().then(res=>
        {
          this.setState({list_gender: res.data.content})
        })
      
        
}
  handleDate = date => {
    if (new Date() > new Date(date)) {
      //alert(1);
  } else {
      alert("Cannot set Future Date!");
      date = new Date();
  }
    this.setState({ date_of_birth: date });
    this.setState({ age: this.getAge(date) });
  };

  handleDate_referral_expiry = date => {
    this.setState({ referral_expiry: date });
  };

  handleDate_referral_date = date => {
    this.setState({ referral_date: date });
  };
   

  getAge = dateString => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    // alert(age);
    return age;
  };

  handleDate2 = date => {
    this.setState({ medicare_expiry: date });
  };
  handleDate3 = date => {
    this.setState({ pension_expiry: date });
  };
  handleDate4 = date => {
    this.setState({ dva_no_2: date });
  };

  componentDidMount() {
    document.getElementById("addPatientForm").scrollIntoView();
    this.props.list_options().then(res => {
      console.log("addPatientForm addPatientForm",res);
      this.setState({ list_options: res.data.content });
      console.log(this.state.list_options);
    });
  }

  handleFieldValueChanges(key, value) {
    this.setState({
      [key]: value
    });

    if(key=='ethnicity')
    {
      if(value=='Other')
      {
        this.setState({showotherEthinicity:true})
      }
      else
      {
        this.setState({showotherEthinicity:false,otherEthnicity:''})
      }
    }
    
  }
  addPatientData() {
//validation list
    let validation_check_passed = true;
    let content = '';
    if (this.state.given_name == "") {
      validation_check_passed = false;
      content += " Given Name, ";
    }
    if (this.state.date_of_birth == "") {
      validation_check_passed = false;
      content += " DOB, ";
    }
if (this.state.family_name == "") {
  validation_check_passed = false;
  content += " Family Name, ";
}
if (this.state.sex == "") {
  validation_check_passed = false;
  content += " Sex, ";
}
if (this.state.medicare_no == "") {
  validation_check_passed = false;
  content += " Medicare No, ";
}
    
if(validation_check_passed){
  this.props.addPatientAction(this.state);
  console.log(this.state);

  this.props.thenaddpatient();
} else {
  alert("Following Fields are required: "+ content); 
}

const values={
  provide:this.state.patient_id,
  bank:this.state.bank_name,
  accnm:this.state.account_name,
  accno:this.state.account_number,
  bsb:this.state.bsb_number,
  mid:'',
  logby:'',
  account:3,
  ids:this.state.account_id
}

  this.props.saveRecord(values).then(
     req=>{
        if(req.data.status=="Success")
        {
      //     NotificationManager.success('New provider account created successfully')
        }
        if(req.data.status=="Duplicate")
        {
        //   NotificationManager.error("Duplicate record cannot be created")
        }
        if(req.data.status=="Error")
        {
          // NotificationManager.error("'Error encountered while creating record")
        }
    //    this.props.changeKey();
     }

  )
this.setState({openappoin:true})
    
  }
  render() {
    return (
      <div id="addPatientForm" className="new-patient-wrapper">
        {/* <div className="col-md-2">
          <ul class="navpat nav-list nav-menu-list-style">
            <li>
              <label class="tree-toggle nav-header glyphicon-icon-rpad">
                <span class="glyphicon glyphicon-folder-close m5"></span>
               
                <span class="menu-collapsible-icon glyphicon glyphicon-chevron-down"></span>
              </label>
              <ul class="navpat nav-list bullets">
                <li>
                  <label class="tree-toggle nav-header">
                    <div class="newdesign">New Patient</div>
                  </label>
                </li>
                  <li>
                  <label class="tree-toggle nav-header">
                    <div class="newdesign1">Add To Family</div>
                  </label>
                </li>
                <li>
                  <label class="tree-toggle nav-header">
                    <div class="newdesign3">Billing History</div>
                  </label>
                </li>
                <li>
                  <label class="tree-toggle nav-header">
                    <div class="newdesign4">Previours Visit</div>
                  </label>
                </li>  
              </ul>
            </li>
          </ul>
        </div> */}
        <div className="col-md-12" style={{paddingRight:'0px', paddingLeft: '0px'}}>
          <div className="panel panel-default">
            <div className="panel-heading">
              <div className="panel-title">New Patient</div>
            </div>
              <div className="col-sm-6 pt-2 pb-5" style={{backgroundColor: '#f3fbf0', paddingTop:'15px'}}>

              <div className="row row-distance">
                                    <div class="col-sm-12">
                                        <h4 class="add-acute2" style={{marginTop:'-10px'}}> <i class="fa fa-info-circle"></i> &nbsp;  Patient Info </h4>
                                    </div>
                                </div>

                <div className="row row-distance">
                <div className="col-sm-1 cus-wid pr-0">
                  <label>
                    Title
                  </label></div>
                  <div className="col-sm-2 pr-0">
                    <select  className="form-control"  value={this.state.title} onChange={e =>
                        this.handleFieldValueChanges("title", e.target.value)
                      } >
                        {
                        this.state.list_title.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.title === item.value}
                            key={index}
                          >
                            {item.display}
                          </option>
                        ))}
                    </select>
                    {/*<input
                      type="text"
                      value={this.state.title}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges("title", e.target.value)
                      }
                    />*/}
                  </div>
                  <div className="col-sm-1 pr-0"><label style={{ letterSpacing: '-0.07em'}}>Family Name</label></div>
                  <div className="col-sm-3 pr-0">
                    <input
                      type="text"
                      value={this.state.family_name}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "family_name",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="col-sm-1 pr-0"><label style={{ letterSpacing: '-0.07em'}}>Given Name</label></div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      value={this.state.given_name}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "given_name",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div class="row row-distance">
                 
                <div className="col-sm-1 cus-wid pr-0">
                  <label>
                    Date of Birth: (Age{(this.state.age!=0) ?this.state.age :''})
                  </label></div>
                  <div class="col-sm-2 pr-0">
                    <DatePicker className="form-control"
                      selected={this.state.date_of_birth}
                      onChange={this.handleDate}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />
                  </div>
     
                  <div className="col-sm-1 pr-0"><label>Sex:</label></div>
                  <div class="col-sm-3 pr-0">
                    <select
                      type="text"
                      value={this.state.sex}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges("sex", e.target.value)
                      }
                    >
                     {
                        this.state.list_gender.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.sex === item.value}
                            key={index}
                          >
                            {item.display}
                          </option>
                        ))}
                    </select>
                  </div>
                  
                
                  
                  <div className="col-sm-1 pr-0"><label>Ethnicity:</label></div>
                  <div class="col-sm-3">
                    <select className="form-control" value={this.state.ethnicity} onChange={e =>
                        this.handleFieldValueChanges(
                          "ethnicity",
                          e.target.value
                        )
                      } >
                        <option value=""> Select </option>
                        <option value="Australian" > Australian</option>
                        <option value="Aboriginal" >  Aboriginal</option>
                        <option value="Torres Strait Islander" > Torres Strait Islander</option>
                        <option value="Other" > Other </option>
                    </select>
                      
                    {/*<input
                      type="text"
                      value={this.state.ethnicity}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "ethnicity",
                          e.target.value
                        )
                      }
                    />*/}
                  </div>
                  
                </div>
                { (this.state.showotherEthinicity) ? 
                <div class="form-group row">
                  <div class="col-sm-2" style={{float:'right'}}>
                    <input type="text"
                    value={this.state.otherEthnicity}
                    class="form-control form-control-sm"
                    onChange={(e)=>this.setState({otherEthnicity:e.target.value})}
                    placeholder="Ethinicity"/>
                  </div>
                  <label class="col-sm-2 col-form-label" style={{float:'right'}} >
                    Ethnicity: (Other)
                  </label>
                  
                </div>  

                :''} 

                <div class="row row-distance">
                                    <div class="col-sm-12">
                                    <h4 class="add-acute2"><i class="fa fa-address-book"> </i>  &nbsp; Basic Contact info   </h4>
                                    </div>
                                </div>

                <div class="row row-distance">
                <div class="col-sm-1 pr-0"><label style={{ letterSpacing: '-0.07em'}}>Address Line</label></div>
                  <div class="col-sm-3 pr-0">
                    <input
                      type="text"
                      value={this.state.address_line_1}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "address_line_1",
                          e.target.value
                        )
                      }
                    />
                  </div>
                 
                  <div class="col-sm-1 pr-0"><label>City/Suburb</label></div>

                  <div class="col-sm-2 pr-0">
                    <input
                      type="text"
                      value={this.state.city}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges("city", e.target.value)
                      }
                    />
                  </div>

                  <div class="col-sm-1 cus-wid pr-0"><label>Postcode</label></div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      value={this.state.postcode}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges("postcode", e.target.value)
                      }
                    />
                  </div>
                </div>

               

                <div class="row row-distance">
                <div class="col-sm-1 pr-0"><label>Email</label></div>
                  <div class="col-sm-3 pr-0">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.email}
                      onChange={e =>
                        this.handleFieldValueChanges("email", e.target.value)
                      }
                    />
                  </div>
                      
                  <div class="col-sm-1 pr-0"><label>Next of kin</label></div>
                  <div class="col-sm-2 pr-0">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.next_to_kin}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "next_to_kin",
                          e.target.value
                        )
                      }
                    />
                  </div>

                  <div class="col-sm-1 cus-wid pr-0"><label>Emergency Contact</label></div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.emerygency_contact}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "emerygency_contact",
                          e.target.value
                        )
                      }
                    />
                  </div>

                </div>

                <div class="row row-distance">
                <div class="col-sm-1 pr-0"><label style={{ letterSpacing: '-0.07em'}}>Home Phone</label></div>
                  <div class="col-sm-3 pr-0">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.home_phone}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "home_phone",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div class="col-sm-1 pr-0"><label style={{ letterSpacing: '-0.07em'}}>Work Phone</label></div>
                  <div class="col-sm-2 pr-0">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.work_phone}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "work_phone",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div class="col-sm-1 cus-wid pr-0"><label>Mobile Phone</label></div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.mobile_phone}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "mobile_phone",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
                 

                
                

                

                <div class="form-group row">
                  <label class="col-sm-12 col-form-label text-left alert-danger">
                   <span style={{color: '#000'}} >  * These name fields are used for Health Identifier lookups. </span>
                  </label>
                </div>
              </div>


              <div class="col-md-6" style={{backgroundColor: '#f8fdf7', paddingTop:'15px'}}>
              <div class="row row-distance">
                                    <div class="col-sm-12">
                                        <h4 class="add-acute2" style={{marginTop:'-5px'}}> <i class="fa fa-medkit"></i> &nbsp;Healthcare Info  </h4>
                                    </div>
                                </div>
                
               
                <div class="row row-distance">
                  <div class="col-sm-1 cus-wid pr-0"><label>Medicare No</label></div>
                  <div class="col-sm-4 pr-0">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.medicare_no}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "medicare_no",
                          e.target.value
                        )
                      }
                    />
                  </div>


                  <div class="col-sm-1 cus-widf"><label>IRN</label></div>
                  <div class="col-sm-2 pr-0">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.medicare_irn}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "medicare_irn",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div class="col-sm-1 cus-widf">
                  <label>
                    Expiry
                  </label></div>
                  <div class="col-sm-2 pr-0">
                    <DatePicker
                      selected={this.state.medicare_expiry}
                      onChange={this.handleDate2}
                      dateFormat="MM/yyyy"
                      showMonthDropdown
                      showMonthYearPicker
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />

                     </div>
                  </div>



                <div class="row row-distance">
                <div class="col-sm-1 cus-wid pr-0">
                  <label>DVA No</label></div>

                  <div class="col-sm-4 pr-0">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.dva_no}
                      onChange={e =>
                        this.handleFieldValueChanges("dva_no", e.target.value)
                      }
                    />
                  </div>
                  <div class="col-sm-1 cus-widf"><label>Type</label></div>

                  <div class="col-sm-2 pr-0" style={{maxWidth:'152px!important'}}>
                    <div class="input-group">
                      <select
                        name="user"
                        class="form-control"
                        onChange={e =>
                          this.handleFieldValueChanges(
                            "dva_no_1",
                            e.target.value
                          )
                        }
                      > 
                      <option value=""> Select </option>
                        {this.state.list_options.dva_no_1_options != null && this.state.list_options.dva_no_1_options.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.dva_no_1 === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select>
                      <span class="md-line"></span>
                    </div>
                  </div>
                  <div class="col-sm-1 cus-widf">
                  <label>Expiry</label>
                  </div>
                  <div class="col-sm-2 pr-0">
                    <DatePicker
                      selected={this.state.dva_no_2}
                      onChange={this.handleDate4}
                      dateFormat="MM/yyyy"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      showMonthYearPicker
                    />
                     
                  </div>
                    
                      {/* <select
                        name="user"
                        class="form-control"
                        onChange={e =>
                          this.handleFieldValueChanges(
                            "dva_no_2",
                            e.target.value
                          )
                        }
                      >
                        <option value=""> Select </option>
                        {this.state.list_options.dva_no_2_options != null && this.state.list_options.dva_no_2_options.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.dva_no_2 === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select> */}
                   
                </div>

                <div class="row row-distance">
                  <div class="col-sm-1 cus-wid pr-0">
                  <label>Pension/HCC No</label></div>
                  <div class="col-sm-4 pr-0">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.pension}
                      onChange={e =>
                        this.handleFieldValueChanges("pension", e.target.value)
                      }
                    />
                  </div>
                    <div class="col-sm-1 cus-widf">
                  <label>Expiry</label></div>
                  <div class="col-sm-2 pr-0 shrink">
                    <DatePicker
                      selected={this.state.pension_expiry}
                      onChange={this.handleDate3}
                      dateFormat="MM/yyyy"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      showMonthYearPicker
                    />
                     
                  </div>
                  <div class="col-sm-1 pr-0">
                  <label style={{ letterSpacing: '-0.07em'}}>Health Ins.No</label></div>
                  <div class="col-sm-2">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.health_ins_no}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "health_ins_no",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  </div>
               

               
                <div class="row row-distance">
                  <div class="col-sm-12" style={{marginTop:'15px'}}>
                    
                    <button
                      id="session-timeout-dialog-logout"
                      type="button"
                      class="btn btn-primary"
                      onClick= {e => this.setState({show_tab: "bank"})}
                      style={{marginRight: '10px'}}
                    >
                      Bank Account
                    </button>
                    <button
                      id="session-timeout-dialog-logout"
                      type="button"
                      class="btn btn-primary"
                      onClick= {e => this.setState({show_tab: "referral"})}
                      style={{marginRight: '10px'}}
                    >
                      Referral Details
                    </button>
                    <button
                      id="session-timeout-dialog-logout"
                      type="button"
                      class="btn btn-primary"
                      style={{marginRight: '10px'}}
                    >
                      Medicare/DVA Eligibility Check
                    </button>
                  </div>
                </div>

                    
                {this.state.show_tab=="bank" && <div>
                <div class="row row-distance">
                <div class="col-sm-2">
                  <label>
                    Bank Name: 
                  </label></div>
                  <div class="col-sm-3">
                    <select className="form-control" onChange={(e)=>this.setState({bank_name:e.target.value})}> <option value=""> Select </option>
                    {this.state.list_bank_name != null && this.state.list_bank_name.map((item, index) => (
                          <option
                            value={item.bank_id}
                            selected={this.state.bank_name === item.value}
                            key={index}
                          >
                            {item.bank_name}
                          </option>
                        ))}
                    </select>
                    
                  </div>

                  <div class="col-sm-2">
                  <label>
                    Account Name: 
                  </label></div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.account_name}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "account_name",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div class="row row-distance">
                <div class="col-sm-2">
                  <label>
                    Account Number
                  </label></div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.account_number}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "account_number",
                          e.target.value
                        )
                      }
                    />
                  </div>
    
                  <div class="col-sm-2">
                  <label>
                      BSB Number
                  </label></div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.bsb_number}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "bsb_number",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  </div></div>}

                  {this.state.show_tab=="referral" && <div>

                  <div class="row row-distance">
                  <div class="col-sm-3">
                  <label>
                  Referred To
                  </label></div>
                  <div class="col-sm-3">
                    <select onChange={(e)=>this.setState({referral_to:e.target.value})}>
                    {this.state.list_options.referral_to != null && this.state.list_options.referral_to.map((item, index) => (
                          <option
                            value={item.referral_to}
                            selected={this.state.referral_to === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                    </select>
                    
                  </div>
                </div>

                  <div class="row row-distance">
                  <div class="col-sm-3">
                  <label>
                  Referred By
                  </label></div>
                  <div class="col-sm-3">
                    <select onChange={(e)=>this.setState({referral_by:e.target.value})}>
                    {this.state.list_options.referral_by != null && this.state.list_options.referral_by.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.referral_by === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                    </select>
                    
                  </div>
                </div>
                
                  <div class="row row-distance">
                  <div class="col-sm-3">
                  <label>
                  GP / Specialist
                  </label></div>
                  <div class="col-sm-3">
                    <select onChange={(e)=>this.setState({referral_gp_specialist:e.target.value})}>
                    {this.state.list_options.referral_gp_specialist != null && this.state.list_options.referral_gp_specialist.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.referral_gp_specialist === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                    </select>
                    
                  </div>
                </div>
                
                <div class="row row-distance">
                <div class="col-sm-3">
                  <label>
                    Referral Date: 
                  </label></div>
                  <div class="col-sm-3">

                  <DatePicker
                      selected={this.state.referral_date}
                      onChange={this.handleDate_referral_date}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />
                    
                  </div>
                </div>
              
                
                <div class="row row-distance">
                <div class="col-sm-3">
                  <label>
                  Referred Type
                  </label></div>
                  <div class="col-sm-3">
                    <select onChange={(e)=>this.setState({referral_type:e.target.value})}>
                    {this.state.list_options.referral_type != null && this.state.list_options.referral_type.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.referral_type === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                    </select>
                    
                  </div>
                </div>

                
              

                <div class="row row-distance">
                <div class="col-sm-3">
                  <label>
                    Referral Expiry: 
                  </label></div>
                  <div class="col-sm-3 shrink">

                  <DatePicker
                      selected={this.state.referral_expiry}
                      onChange={this.handleDate_referral_expiry}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      showMonthYearPicker
                    />
                    
                  </div>
                </div>
                </div>}

                <div class="row row-distance">
                                    <div class="col-sm-12">
                                        <h4 class="add-acute2"> <i class="fa fa-edit"></i> &nbsp; Notes </h4>
                                    </div>
                                </div>


                                <div class="row row-distance">
                                <div class="col-sm-2">
                  <label>
                    General Notes:
                  </label></div>
                  <div class="col-sm-4">
                    <textarea style={{height:'76px'}}
                      rows="3"
                      cols="5"
                      placeholder=""
                      value={this.state.general_notes}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "general_notes",
                          e.target.value
                        )
                      }
                    ></textarea>
                  </div>

                  <div class="col-sm-2">
                  <label>
                    Appointment Notes:
                  </label></div>
                  <div class="col-sm-4">
                    <textarea style={{height:'76px'}}
                      rows="3"
                      cols="5"
                      placeholder=""
                      value={this.state.appointment_notes}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "appointment_notes",
                          e.target.value
                        )
                      }
                    ></textarea>
                  </div>
                </div>

                    


                <div class="form-group row">
                  <div class="col-sm-12">
                    <button
                      id="session-timeout-dialog-logout"
                      type="button"
                      class="btn btn-primary" style={{ float:'right', marginTop:'10px' }}
                      disabled={this.state.submitdisbled}
                      onClick={() => this.addPatientData()}
                    >
                      Submit
                    </button>
                    {this.state.openappoin ?
                    <Addappointment showapp={this.state.openappoin} showmod={this.state.openappoin}/> :''}
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

//export default AddPatientForm;
const mapDispatchToProps = dispatch => {
  return {
    list_options: data => dispatch(api_list_options(data)),
    getTitle:()=> dispatch(api_getTitle()),
    getBankNames:()=>dispatch(api_banknames()),
    getGender :()=>dispatch(api_getGender()),
    getBankAccount:(data)=>dispatch(get_patient_account(data)),
    saveRecord:(data)=>dispatch(add_patient_account(data)),

  };
};

const mapStateToProps = state => {
  let selected_appointment =
    state.getDrAppointments.selected_appointment != undefined
      ? state.getDrAppointments.selected_appointment
      : [];
  let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : [];
  let selectedPatientDetails;
  if (patientDetails.length > 0) {
    selectedPatientDetails = patientDetails[0];
  } else {
    selectedPatientDetails = "";
  }
  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";

  return {
    selectedPatientDetails,
    logged_info,
    selected_appointment,
    logged_info
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(AddPatientForm);
