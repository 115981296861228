import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {api_add_contact_note,api_list_reason,api_update_contact_note} from '../../../DS/DS.ListPage';
import AddContactType from './contactType';
class CodeMapping extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        reason_name:'',
        errors:[],
        openContactType:false,
        key:0
       
    }
  }
  componentWillMount=()=>
  {
    if(this.props.popFor=='Edit')
    {
        const getData={tbl:'contact_note_reason',pk:'reason_id',ids:this.props.ids}
        this.props.listContactNote(getData).then(
            res=>{
                this.setState({reason_name:res.data.content.reason_name})
            }
        )
    }
  }
  handleValidation(){
    let errors = {};
    let formIsValid = true;
  
    //Reason name
    if(!this.state.reason_name){
       formIsValid = false;
       errors["reason_name"] = "Cannot be empty";
    }
     this.setState({errors: errors});
    return formIsValid;
  }
  onClickHandler=(e)=>
  {
    e.preventDefault();
    if(this.handleValidation()){
        const reasonData={
                reason:this.state.reason_name,
              }
                this.props.addContactNote(reasonData).then(
                  req=>{
                         NotificationManager.success('Record Added',)
                         this.props.reloadPage()
                      }
              );
    }
} 
updateRecord=(e)=>
{
    e.preventDefault();
    if(this.handleValidation()){
        const reasonData={
                reason:this.state.reason_name,
                ids:this.props.ids,
              }
                this.props.UpdateContactNote(reasonData).then(
                  req=>{
                         NotificationManager.success('Record Updated',)
                         this.props.reloadPage()
                      }
              );
    }
}
reloadPage=()=>
{
    this.setState({openContactType:false});
    this.setState({key:Math.random()})
}
 render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
            <div>
                <div className="modal-header">
                    <span className="modal-title-2" id="exampleModalLabel"> Contact Type Mappings - </span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={()=>this.props.reloadPage()}>
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="modal-footer">
                        <div className="row">
                            <div className="col-sm-">
                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#contact-category-code-mapping-add" onClick={(e)=>this.setState({openContactType:true})}> Add </button>
                                <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close">Remove</button> 
                            </div>
                            <div className="col-sm-5"> </div>
                        </div>
                    </div>
                    <div className="row row-distance">
                        <div className="col-sm-12"> 
                            <textarea id="accute" className="contact-mapping-textarea maping-textarea" placeholder=""> </textarea>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-sm-6"> </div>
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Ok</button>
                            <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close"  onClick={()=>this.props.reloadPage()}>Cancel</button> 
                        </div>
                    </div>
            </div>
            {this.state.openContactType?
            <AddContactType reloadPage={this.reloadPage} showModal={this.state.openContactType}/>:''}
        </div>
        )}
        </Popup>
        )}
    }
    const mapDispatchToProps = dispatch => {
        return {
            addContactNote: data => dispatch(api_add_contact_note(data)),
            listContactNote : data => dispatch(api_list_reason(data)),
            UpdateContactNote: data => dispatch(api_update_contact_note(data))
         };
      };
      const mapStateToProps = state => {
      
        let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
        
        return {
            redux_userId
        };
      };
      export default connect(mapStateToProps,mapDispatchToProps)(CodeMapping);
    