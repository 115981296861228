import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {api_add_appoinment_type,api_list_reason,api_update_appoinment_type} from '../../../DS/DS.ListPage';
class AddAppoinmentType extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:this.props.showModal,
        description:'',
        errors:[],
        def_interval:''
    }
  }
  componentWillMount=()=>
  {
   //   alert(this.props.popFor)
    if(this.props.popFor=='Edit')
    {
        const getData={tbl:'appoinments_type',pk:'appoinment_type_id',ids:this.props.ids}
        this.props.listappoinmentType(getData).then(
            res=>{
                this.setState({description:res.data.content.name,def_interval:res.data.content.appointment_interval})
            }
        )
    }
  }
  handleValidation(){
    let errors = {};
    let formIsValid = true;
  
    //Description name
    if(this.state.description==''){
       formIsValid = false;
       errors["description"] = "Cannot be empty";
    }
    //
    if(this.state.def_interval==''){
        formIsValid = false; 
        errors["def_int"] = "Cannot be empty";
     }
     this.setState({errors: errors});
    return formIsValid;
  }
  onClickHandler=(e)=>
  {
    e.preventDefault();
    if(this.handleValidation()){
        const reasonData={
                reason:this.state.description,
                def_intv:this.state.def_interval,

              }
                this.props.addAppoinmenttype(reasonData).then(
                  req=>{
                    
                  if(req.data.status=="Duplicate"){
                           
                    NotificationManager.error('Duplicate appointment type record cannot be created',)
               }
               else if(req.data.status=="Success")
               {
                  
                    NotificationManager.success('New appointment type created',)
               }
               else{
                   
                   NotificationManager.console.error('Error encountered while creating record',)
               }
               this.props.reloadPage()
            }        
              );
            
    }
} 
updateRecord=(e)=>
{
    e.preventDefault();
    if(this.handleValidation()){
        const reasonData={
                reason:this.state.description,
                def_intv:this.state.def_interval,
                ids:this.props.ids
              }
                this.props.UpdateappoinmentType(reasonData).then(
                  req=>{
                    if(req.data.status=="Duplicate"){
                       
                         NotificationManager.error('Duplicate appointment type record cannot be created',)
                    }
                    else if(req.data.status=="Success")
                    {
                      
                         NotificationManager.success('Appointment type modified',)
                    }
                    else{
                        
                        NotificationManager.error('Record Added',)
                    }
                    this.props.reloadPage()  
              }
              );
    }
}
 render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
            <div>
                <div className="modal-header">
                        <span className="modal-title-2" id="exampleModalLabel"> Appoinment Type </span>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.reloadPage()}>
                                <span aria-hidden="true">×</span>
                            </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <div className="col-sm-3"> 
                                        <label> Description : </label>
                                    </div>
                                    <div className="col-sm-9 cursor">
                                        <input type="text" name="text" className="form-control" value={this.state.description}
                                        onChange={(e)=>this.setState({description:e.target.value})}/>
                                    <span className="error" style={{color:'red'}}>{this.state.errors["description"]}</span>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <div className="col-sm-3"> 
                                        <label> Length : </label>
                                    </div>
                                    <div className="col-sm-9 cursor">
                                        <select className="dropdown-box" value={this.state.def_interval} onChange={(e)=>this.setState({def_interval:e.target.value})}>
                                            <option value="">Select Interval</option> 
                                            <option value="15 min"> 15 min</option>
                                            <option value="30 min"> 30 min</option>
                                            <option value="45 min"> 45 min</option>
                                            <option value="1 hr"> 1 hr</option>
                                            <option value="2 hr"> 2 hr</option>
                                        </select>
                                        <span className="error" style={{color:'red'}}>{this.state.errors["def_int"]}</span>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                       {/*<div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <div className="col-sm-3"> 
                                        <label> Icon : </label>
                                    </div>
                        <div className="col-sm-9">
                            <div className="card appointment-type-add-inner-table">
                                <div className="card-block">
                                    <table className="calendar table table-bordered tablestyle">
                                        <tbody>
                                            <tr>
                                                <td> <i className="fa fa-search"> </i></td>
                                                <td> <i className="fa fa-search"> </i></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-3"> </div>
                <div className="col-sm-9">
                    <div className="border-checkbox-group border-checkbox-group-default">
                        <input className="border-checkbox" type="checkbox" id="checkbox1"/>
                        <label className="border-checkbox-label" for="checkbox1"> &nbsp; Default icon</label>
                    </div>
                </div>
            </div>*/}
            </div>
            <div className="modal-footer">
            <div className="row">
                <div className="col-sm-8"> </div>
                <div className="col-sm-4">
                {this.props.popFor=='Add' ?
                                        
                    <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.onClickHandler(e)}>Save</button>:
                    <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.updateRecord(e)}>Update</button>} 
                    <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.reloadPage()}>Cancel</button> 
                </div>
            </div>
            </div>
    </div>)}
    </Popup>)
    }
}


const mapDispatchToProps = dispatch => {
    return {
        addAppoinmenttype: data => dispatch(api_add_appoinment_type(data)),
        listappoinmentType : data => dispatch(api_list_reason(data)),
        UpdateappoinmentType: data => dispatch(api_update_appoinment_type(data))
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(AddAppoinmentType);
  

