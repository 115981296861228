import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { api_getTitle,api_getGender} from "../../../DS/DS.Appointments";
import DatePicker from "react-datepicker";
import {get_selected_states} from '../../../DS/DS.EPC'
import Popup from "reactjs-popup";
import moment from 'moment';
import Showhelp from './../../showHelp';

class pastMedical extends Component {
    constructor(props)
    {
        super(props)
        this.state={ 
            open:this.props.open,
            remdate:new Date(),
            showVariable:[],
            selectedindex:''

        }
    }
    handleDate=date=>
    {

    }
    setTrClass = (id) => {
        let classs = "";
        classs +=
          id == this.state.selectedindex
            ? " selectedBg "
            : "";
        return classs;
      };
      showSelectedContent(e, i) {
        this.setState({selectedindex: i });
       
      }
    render() {
        return (
            <Popup
            open={this.state.open}
            closeOnDocumentClick={false}
             position="right center"   modal
           >
             {close => (
                <div>
                    <div class="modal-header">
                        <div className="popup-title">
                            <span> Add Goal <Showhelp gotohelp="add_goal_other_help"/></span> 
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" onClick={() => this.props.close()}>
                                        ×
                                    </span>
                                </button>
                        </div>
                    </div>
                    <div className="modal-body" style={{marginBottom: '10', height:'500px', overflowY:'scroll'}}>
                            <div className="col-md-6" style={{margin:'10px'}}>
                                <div className="col-md-1">
                                    <label>Date</label>
                                </div>
                                <div className="col-md-1">
                                    <input type="text" style={{width:'25px'}}/> 
                                </div>
                                <div className="col-md-1">
                                    <input type="text" style={{width:'25px'}}/> 
                                </div>
                                <div className="col-md-2">
                                    <input type="text" style={{width:'75px'}}/> 
                                </div>
                            </div>
                            <div className="col-md-4" >
                                <div className="col-md-2">
                                    <label><input type="checkbox" id="today" name="today" /> Today</label>
                                </div>
                                <div className="cold-md-2">
                                    <DatePicker
                                        className="datePicker"
                                        onChange={this.handleDate}
                                        dateFormat="dd/MM/yyyy"
                                        id="clinicalcalendar" 
                                        selected={
                                            this.state.dateShow
                                            ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                            : Date.parse(this.state.remdate)
                                            }
                                        />  
                                </div>
                            </div>  
                            <div className="col-md-12">
                                <div className="col-sm-5">
                                    <label>Search</label>
                                    <input type="text"  className="form-control form-control-sm rq-form-elemen" ></input>
                                </div>
                                <div className="col-sm-2">
                                <input type="text"  className="form-control form-control-sm rq-form-elemen" ></input>
                                </div>
                             </div>  
                            <div className="col-md-12">
                                <div className="col-sm-5 cursor">
                                    <div className="invoicenotes2-body"> 
                                        <div className="box" style={{border:'1px solid'}}>
                                            {this.state.showVariable != undefined && this.state.showVariable.length> 0 && this.state.showVariable.map((vari,i) =>
                                                <label  onClick={e =>this.showSelectedContent(e, i) } className={this.setTrClass(i)} >{vari}</label>
                                            )}   
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                    <div className="form-group row">
                                        <div className="col-sm-1"><label className="col-form-label text-left">Waist:</label></div>
                                            <div class="col-sm-2">
                                                <label>
                                                    <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.setState({fasting:e.target.checked})} style={{marginTop:'25px'}}/> Fasting
                                                </label>
                                            </div>
                                            <div className="col-sm-1">
                                                <label>
                                                    <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.setState({fasting:e.target.checked})} style={{marginTop:'25px'}}/> Fasting
                                                </label>
                                            </div>
                                            <div class="col-sm-2">
                                                <label>
                                                    <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.setState({fasting:e.target.checked})} style={{marginTop:'25px'}}/> Fasting
                                                </label>
                                            </div>
                                            <div class="col-sm-2">
                                                <label>
                                                    <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.setState({fasting:e.target.checked})} style={{marginTop:'25px'}}/> Fasting
                                                </label>
                                            </div>
                                       </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                 )}
            </Popup>
        
        )}
}

export default pastMedical;