import React, { Component } from 'react';
import { connect } from "react-redux";
import Popup from 'reactjs-popup';
import './addPatientHistory.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import {
    NotificationContainer,
    NotificationManager,
  } from 'react-notifications';
import moment from 'moment';
import cicon from "./../../../assets/img/cicon.png";
import Showhelp from './../../showHelp';

class EditPatientHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            general_comments: this.props.pastHistoryComments,
            userId: props.patientId,
            patient_condition_id: 1,
            pickList: '',
            conditions: '',
            left: 0,
            right: 0,
            active: 0,
            confidential: 0,
            summary: 0,
            comment: '',
            differential_diagnosis: 0,
            save_past_medical_distory: 0,
            startDate: moment(new Date()).format("DD/MM/YYYY"),
        };
    }
    handleUpdate = e => {
        this.props.editPaitentData(this.state).then(() => this.props.changeKey());
        
    };
    handleDate = date => {
        if (new Date() > new Date(date)) {
            //alert(1);
        } else {
            alert("Cannot set Future Date!");
            date = new Date();
        }

        
        

        this.setState({
            startDate: date,
        });
    };
    focusTextInput_dd() {
        document.getElementById("datejumperdd").focus();
      }
    handleCheckbox = (e, key) => {
        if(e.target.checked){
            this.setState({ [key]: 1 }); 
        } else {
            this.setState({ [key]: 0 });
        }
        console.log("editpatienthistory", this.state);
    }
    componentWillReceiveProps(nextProps) {
        if (Object.keys(nextProps).length !== 0) {
            let editData = nextProps.editData;
            console.log("editdata",editData);
            if (nextProps.pastHistoryComments !== this.props.pastHistoryComments) {
                this.setState({general_comments: nextProps.pastHistoryComments});
              }
            this.setState({
                left: editData.left_side,
                right: editData.right_side,
                active: editData.active,
                summary: editData.summary,
                confidential: editData.confidential,
                differential_diagnosis: editData.differential_diagnosis,
                save_past_medical_distory: editData.save_past_medical_distory,
               // startDate: moment(editData.date).format("DD/MM/YYYY,hh:mm:ss"),
                startDate: editData.date,
                conditions: editData.name,
                comment: editData.comment,
                patient_condition_id: editData.patients_conditions_id

            });
            console.log("editpatienthistory:", this.state);
        }
    }

    render() {
        const { editData } = this.props
        return (
            <Popup
            open={this.props.open}
               // trigger={
               
        //             <button
        //                 id="session-timeout-dialog-logout"
        //                 data-toggle="modal"
        //                 data-target="#pastmedical"
        //                 type="button"
        //                 className="btn btn-default past-history-action-button"
        //                 style={{marginTop:'20px', padding:'5px 10px'}}
        //             >
        //                 Edit
        //   </button>
             //   }
                modal
                closeOnDocumentClick={false}
            >

              
                    <div>
                    <div className="modal-header">
                        <NotificationContainer />
                        <div class="popup-title">
                            <span>Edit Past Medical History <Showhelp gotohelp="edit_past_medical_history_help"/></span>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true" onClick={() => this.props.close()}>
                                    ×
                </span>
                            </button>
                        </div>
                        </div>
                        <div className="modal-body newpostpopup phistory">
                            <div
                                className="col-sm-12"
                                style={{ paddingRight: 0, marginTop: 10, marginBottom: 10 }}
                            >
                                <div className="form-group row" style={{ marginTop: 10 }}>
                                    <label className="col-sm-3 col-form-label text-right">
                                        Date:
                  </label>
                  <div className="col-sm-9 pl-2">
                                    <DatePicker
                                    id="datejumperdd"
                                        className="datePicker"
                                        onChange={this.handleDate}
                                        selected={Date.parse(this.state.startDate)}
                                    />
                                    
                                    <label
                      className="label-input100"
                      style={{
                        marginRight: "0px",
                        marginBottom: "0px",
                        fontSize: "12px",
                        paddingBottom: "0px",
                        paddingTop: "0px",
                        marginTop: "0px",
                      }}
                    >
                      <a onClick={this.focusTextInput_dd} href="#">
                        {<img src={cicon} />}
                      </a>
                    </label>
                                    </div>
                                </div>
                                <div className="col-sm-12 m-b-0">
                                    <div className="form-group row m-b-10">
                                        {/* <label className="col-sm-4 col-form-label text-right">
                                            <input
                                                type="radio"
                                                defaultChecked
                                                style={{ padding: '5px 5px' }}
                                            />{' '}
                                            Pick from list (coded)
                    </label> */}<label className="col-sm-3"></label>
                                        <div className="col-sm-8 pl-0">
                                            <div className="autocomplete" style={{ width: '100%' }}>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    value={this.state.conditions}
                                                    disabled="disabled"
                                                    style={{
                                                        padding: '5px 10px',
                                                        width: '92%',
                                                        float: 'left',
                                                    }}
                                                />
                                            </div>
                                             
                                        </div>
                                    </div>
                                    <div className="form-group row m-b-10">
                                        <label className="col-sm-3 col-form-label text-left">
                                            &nbsp;
                            </label>
                                        <div className="col-sm-8 pl-0">
                                            <input
                                                type="checkbox"
                                                onChange={e => this.handleCheckbox(e, 'left')}
                                                style={{ margin: '8px 0px 3px 0px', float: 'left' }}
                                                checked={this.state.left == '1' ? true : false}
                                            />
                                            <span
                                                style={{ marginTop: 5, marginRight: 10, float: 'left' }}
                                            >
                                                Left
                      </span>{' '}
                                            <input
                                                type="checkbox"
                                                onChange={e => this.handleCheckbox(e, 'right')}
                                                checked={this.state.right == '1' ? true : false}

                                                style={{ margin: '8px 0px 3px 0px', float: 'left' }}
                                            />
                                            <span
                                                style={{ marginTop: 5, marginRight: 10, float: 'left' }}
                                            >
                                                Right
                      </span>
                                        </div>
                                    </div>
                                    <div className="form-group row m-b-10">
                                        <label className="col-sm-3 col-form-label text-left">
                                            {' '}
                                            &nbsp;
                    </label>
                                        <div className="col-sm-8 pl-0">
                                            {/* <input
                                                type="checkbox"
                                                onChange={e => {
                                                    if (e.target.checked) this.setState({ active: 1 });
                                                }}
                                                style={{ margin: '8px 0px 3px 0px', float: 'left' }}
                                                defaultChecked={this.state.active === '1' ? true : false}
                                            />
                                            <span
                                                style={{ marginTop: 5, marginRight: 10, float: 'left' }}
                                            >
                                                Active
                      </span> */}
                                            <input
                                                type="checkbox"
                                                onChange={e => {
                                                    this.handleCheckbox(e, 'confidential')
                                                }}
                                                checked={this.state.confidential == '1' ? true : false}
                                                style={{ margin: '8px 0px 3px 0px', float: 'left' }}
                                            />
                                            <span
                                                style={{ marginTop: 5, marginRight: 10, float: 'left' }}
                                            >
                                                Confidential
                      </span>
                                            {/* <input
                                                type="checkbox"
                                                onChange={e => {
                                                    if (e.target.checked) this.setState({ summary: 1 });
                                                }}
                                                defaultChecked={this.state.summary === '1' ? true : false}
                                                style={{ margin: '8px 0px 3px 0px', float: 'left' }}
                                            />
                                            <span
                                                style={{ marginTop: 5, marginRight: 10, float: 'left' }}
                                            >
                                                Summary
                      </span> */}
                                        </div>
                                    </div>
                                    <div className="form-group row m-b-10 mt-2">
                                        <label className="col-sm-3 col-form-label text-right">
                                            {' '}
                                            General Comments
                    </label>
                                        <div className="col-sm-7 pl-0 pr-0">
                                            <textarea
                                                style={{
                                                    float: 'left',
                                                    width: '100%',
                                                    height: 100,
                                                    border: '1px solid #cccccc',
                                                }}
                                                onChange={e =>
                                                    this.setState({ general_comments: e.target.value })
                                                }
                                                value={this.state.general_comments}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="form-group row m-b-10">
                                        <div className="col-sm-4 col-form-label text-right">
                                            {' '}
                                            &nbsp;
                    </div>
                                        <div className="col-sm-8">
                                            <input
                                                type="checkbox"
                                                style={{ margin: '8px 0px 3px 0px', float: 'left' }}
                                                onChange={e => {
                                                    if (e.target.checked)
                                                        this.setState({ differential_diagnosis: 1 });
                                                }}
                                                checked={this.state.differential_diagnosis === '1' ? true : false}
                                            />
                                            <span
                                                style={{ marginTop: 5, marginRight: 10, float: 'left' }}
                                            >
                                                Differential diagnosis
                      </span>
                                            <input
                                                type="checkbox"
                                                onChange={e => {
                                                    if (e.target.checked)
                                                        this.setState({ save_past_medical_distory: 1 });
                                                }}
                                                checked={this.state.save_past_medical_distory === '1' ? true : false}
                                                style={{ margin: '8px 0px 3px 0px', float: 'left' }}
                                            />
                                            <span
                                                style={{ marginTop: 5, marginRight: 10, float: 'left' }}
                                            >
                                                Save in Past Medical History
                      </span>
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div
                                    className="col-sm-4 col-md-4 col-xl-4 m-b-0"
                                    style={{ paddingLeft: 0 }}
                                >
                                    <div
                                        className="panel panel-default"
                                        style={{ float: 'left', width: '100%' }}
                                    >
                                        <div
                                            className="panel-heading"
                                            style={{ float: 'left', width: '100%', padding: 5 }}
                                        >
                                            <div
                                                className="col-lg-12 col-xl-12"
                                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                            >
                                                <h6
                                                    className="panel-title"
                                                    style={{
                                                        float: 'left',
                                                        width: '100%',
                                                        fontWeight: 'bold',
                                                        display: 'inline',
                                                        marginRight: 10,
                                                    }}
                                                >
                                                    <a
                                                        href="#"
                                                        style={{ float: 'left', fontSize: 12 }}
                                                        className="collapsed"
                                                        aria-expanded="false"
                                                    >
                                                        Condition
                          </a>
                                                </h6>
                                                <div
                                                    style={{
                                                        width: 'auto',
                                                        height: 30,
                                                        position: 'absolute',
                                                        right: 0,
                                                        top: 2,
                                                    }}
                                                >
                                                    <a
                                                        href="#"
                                                        title="Save"
                                                        className="collapsed"
                                                        aria-expanded="false"
                                                    >
                                                        <i
                                                            className="fa fa-save"
                                                            aria-hidden="true"
                                                            style={{ fontSize: 12, marginRight: 5 }}
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <textarea
                                        placeholder="For Doctor's Use"
                                        style={{
                                            height: 248,
                                            width: '100%',
                                            marginTop: '-20px',
                                            border: '1px solid #cccccc',
                                            marginBottom: 0,
                                            padding: 10,
                                        }}
                                        value={editData.name}
                                    />
                                </div> */}
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary btn-xs"
                                onClick={() => {
                                    this.handleUpdate();
                                    this.props.close();
                                }}
                            >
                                Update
              </button>
                            <button
                                type="button"
                                className="btn btn-primary btn-xs"
                                data-dismiss="modal"
                                onClick={() => this.props.close()}
                            >
                                Cancel
              </button>
                        </div>
                    </div>
                
            </Popup>
        );
    }
}

//export default EditPatientHistory;
const mapStateToProps = state => {
   
    let pastHistoryComments =
      state.getDrAppointments.getPatientDetails != undefined
        ? state.getDrAppointments.getPatientDetails[0].pastHistoryComments
        : "";
  
    return {
       pastHistoryComments
    };
  };
export default connect(mapStateToProps)(EditPatientHistory);