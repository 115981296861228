import axios from 'axios';
//Add new cancellation reason
export const api_add_cancel_reason = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=add_cancel_reason`, data);
}
//List all cancellation reason
export const api_list_reason = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=list_reason`, data);
}
//Update cancellation reason
export const api_update_cancel_reason = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=update_cancel_reason`, data);
}
//Delete
export const api_delete_selection = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=delete_selection`, data);
}
//Add new appoinment type
export const api_add_appoinment_type = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=add_appoinment_type`, data);
}
//Update appoinment type
export const api_update_appoinment_type = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=update_appoinment_type`, data);
}
//Add new cease medication reason
export const api_add_cease_reason = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=add_cease_reason`, data);
}
//Update cease medication reason
export const api_update_cease_reason = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=update_cease_reason`, data);
}
//Add new contact
export const api_add_contact = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=add_contact`, data);
}
//Update contact
export const api_update_contact = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=update_contact`, data);
}
//Add new document type
export const api_add_document = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=add_document`, data);
}
//Update contact
export const api_update_document = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=update_document`, data);
}
//Add new contact note reason
export const api_add_contact_note = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=add_contact_note_reason`, data);
}
//Update contact note reason
export const api_update_contact_note = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=update_contact_note_reason`, data);
}
export const api_get_contact_cleanup = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=lists&action=get_contact_cleanup`, data);
}
export const api_cancel_reason = () => dispatch => {
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=lists&action=cancel_reason`);
}
