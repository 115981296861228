import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
//import './addPatientHistory.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import {wflow} from './../../../DS/general';
import axios from 'axios';
import
{
  api_addClinical,
  api_upload_image
} from '../../../DS/DS.Clinical';
import cicon from "../../../assets/img/cicon.png";
import Showhelp from './../../showHelp';

class AddClinicalRecord extends Component {
  constructor(props) {
    super(props);
    this.handleClose=this.handleClose.bind(this);
    console.log(">>>"+JSON.stringify(props))
    this.state = {
      userId:1 ,//props.patientId,
      confidential: 0,
      startDate: '',
      dateShow: false,
      selectedFile: null,
      open:false,
      calendardate:new Date(),
      description:'',
      details:'',
      patient_id:this.props.redux_patientId,
      isChecked: false,
      errors:[],
     
    };
  }
 componentWillMount()
 {
    this.setState({open:this.props.showform})
    //let now= new Date();
   // now.setDate(now.getDate()+1);
   // this.setState({calendardate:now})
//   console.log(">>>",now)
 }

  handleChange(event) {
    console.log(event.target.value)
    //this.setState({description: event.target.value});
  }
  onChangeHandler=event=>{
      this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })
}
handleValidation(){
  let fields = this.state.fields;
  let errors = {};
  let formIsValid = true;

  //File
  if(!this.state.selectedFile){
     formIsValid = false;
     errors["file"] = "Cannot be empty";
  }

  //Date
  if(!this.state.calendardate){
     formIsValid = false;
     errors["calendardate"] = "Cannot be empty";
  }

  //Description
  if(!this.state.description){
    formIsValid = false;
    errors["description"] = "Cannot be empty";
 }

 //Description
 if(!this.state.details){
  formIsValid = false;
  errors["details"] = "Cannot be empty";
}

 this.setState({errors: errors});
 return formIsValid;
}
onClickHandler=(e)=>
{

     e.preventDefault();
    if(this.handleValidation()){
     
      const data = new FormData() 
      data.append('image',this.state.selectedFile)
     console.log(this.state.selectedFile.name)
     if(this.state.isChecked==true)
     {
      this.state.isChecked=1
     }
     else
     {
      this.state.isChecked=0  
     }
      this.props.uploadimage(data).then(
        res=>{
         // if(res.status=="Success")
          //{
            //alert(res.data.content)
            this.state.calendardate.setDate(this.state.calendardate.getDate())
            const clinicalData={
              image: res.data.content,
              description:this.state.description,
              record_date:this.state.calendardate,
              details:this.state.details,
              confidential:this.state.isChecked,
              patient_id:this.state.patient_id,
            }
              this.props.apiAddClinical(clinicalData).then(
                req=>{
                        this.props.changeKey()
                       NotificationManager.success('New clinical record created',)
                    }
            );
          /*}
          else{
            this.props.changeKey()
            NotificationManager.success('Unable to add the record at this moment')
          }*/
            
        }
      )
        
             
       /*   axios.post(`${process.env.REACT_APP_BASE_URL}api=clinical&action=upload_image`, data, { 
                // receive two    parameter endpoint url ,form data
         })*/
    }else{
      //alert("Form has errors.")
    }

  /*
   */
  //this.props.changeKey();
}
handleDate = date => {
  console.log(">>>>>>>>",date)
    this.setState({ calendardate: date });
    
  };
  handleClose = () => {
    this.setState({open:false})
    console.log(this.state.open)
  }

  handleFieldChange = (key, value) => {
    console.log(key, value);

    // let data = this.state.data;
    // data.eval(key) = value;
    // this.setState({data});
   this.setState({ key: value});
    /*
    this.setState({
      data: {
        [key]: value,
      },
    });
    */
  };
   toggleChange = () => {
        this.setState({
          isChecked: !this.state.isChecked,
        });
      }
      focusTextInput() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        // this.textInput.current.focus();
        document.getElementById("clinicalcalendar").focus();
        // this.textInput.focus();
      }
  render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
          <div className="clinicin"   style={{ width:'650px', marginLeft:'75px', backgroundColor:'#FFF', float:'left'}}>
            <div className="modal-header">
                <button type="button" className="close"  onClick={() =>this.props.changeKey()} data-dismiss="modal" aria-label="Close" style={{position: 'inherit', right: '10px', fontSize: '25px'}}>
                  <span aria-hidden="true">×</span>
                </button>
                <span>Clinical Images</span>
                <Showhelp gotohelp="clinical_add_help"/>
              </div>
              <div className="modal-body newpostpopup" style={{border:'0px'}}>
              <div
                className="col-sm-12"
                style={{
                  paddingRight: "0px",
                  marginTop: "10px",
                  marginBottom: "10px"
                }}
              >
               <div className="row">

                <div className="col-sm-2 cus-wid">
                  <label>Image :</label>
                </div>
                <div className="col-md-5">
                  <input type="file" name="file" onChange={this.onChangeHandler} required/>
                  <span className="error" style={{color:'red'}}>{this.state.errors["file"]}</span>
                </div>
               
               </div>
               <div className="row mt-4">
                <div className="col-sm-2 cus-wid">
                <label>Date :</label>
                </div>
                <div className="col-md-4 newdate">
                   {/*} <DatePicker
                      //ref={this.textInput}
                      ref={input => {
                        this.textInput = input;
                      }}
                      id="date-jumper"
                      inputRef={ref => {
                        this.textInput = ref;
                      }}
                      selected={this.state.calendardate}
                      onChange={this.handleDate}
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      required
                    />*/}
                    
                    <DatePicker
                      className="datePicker"
                      selected={this.state.calendardate}
                      onChange={this.handleDate}
                      dateFormat="dd/MM/yyyy"
                      id="clinicalcalendar" 
                    />    
                  <span className="error" style={{color:'red'}}>{this.state.errors["calendardate"]}</span>
                  <label
                      className="label-input100"
                      style={{ marginRight: "0px", marginBottom: "0px", fontSize: "12px", paddingBottom: "0px", paddingTop: "0px", position: "absolute", marginLeft: "-30px" }}
                    >
                      <a onClick={this.focusTextInput} href="#" style={{float:'left', marginTop:'0px'}}>
                        {<img src={cicon} />}
                      </a>
                    </label>
                </div>
                
               </div>
               <div className="row">
                <div className="col-sm-2 pr-0 mt-3 cus-wid">
                <label>Description :</label>
                </div>
                <div className="col-md-9">
                   <input type="text" style={{width:"40%"}} class="form-control" onChange={(e) => this.setState({ description: e.target.value })} required/>
                   <span className="error" style={{color:'red'}}>{this.state.errors["description"]}</span>   
               </div>
               
               </div>
              <div className="row">
                <div className="col-sm-2 cus-wid">
                <label>Details :</label>
                </div>
                <div className="col-md-9">
                  <textarea
                        style={{
                          float: 'left',
                          width: '100%',
                          height: 100,
                          border: '1px solid #cccccc',
                          padding:'10px'
                        }}
                        onChange={e =>
                          this.setState({ details: e.target.value })
                        }
                        value={this.state.details}
                      required/>
                      <span className="error" style={{color:'red'}}>{this.state.errors["details"]}</span> 
                </div>
               
               </div>
               <div className="row">
               <div className="col-sm-2 cus-wid"> </div>
                   <div className="col-sm-10 pl-0">
							        {/*<label><input type="checkbox" value="" style={{margin: '8px 0px 3px 0px', float:'left'}}
                      value={this.state.confidential}/>
                      <span style={{marginTop:'5px', marginRight:'10px', float:'left'}}>Confidential</span></label>*/}
                      <label
                          className="col-sm-4 col-form-label form-check-label"
                          
                        >
                                 <input type="checkbox" checked={this.state.isChecked} onChange={this.toggleChange} />
                   
                       
                  {/*}    <input
                            type="checkbox"
                            className="form-check-input"
                            id="confidential"
                            checked={this.state.condidential}
                            onChange={e =>
                              this.handleFieldChange(
                                'confidential',
                                e.target.checked
                              )
                            }
                  />*/}&nbsp;Confidential 
                           </label>
							    </div>
              </div>
              </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary btn-xs"
                  onClick={(e)=>{this.onClickHandler(e) ;}}
              >
                Save
              </button>
              
              <button
                type="button"
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
                onClick={() =>this.props.changeKey()}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    apiAddClinical: data => dispatch(api_addClinical(data)),
    uploadimage : data => dispatch(api_upload_image(data))
   };
};

const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';

  return {
    redux_patientId
  };
};
export default connect(
    mapStateToProps,
  mapDispatchToProps
)(AddClinicalRecord);

//export default AddClinicalRecord;
