import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import {
  acute,
  ChangeDragData,
  api_searchConditions,
  api_addConditions,
  deleteData,
  addPaitentData,
  //deletePatientId,
  editPaitentData,
} from './../../../DS/DS.Appointments';
import {
  patientConditions,
  drConditions,
  deletePatientId,
  deletePatientFavId,
  deleteDoctorFavId,
} from './../../../DS/DS.VisitnotesDiagnosis';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';

class Allergies extends Component {
  state = {
    patientId: this.props.redux_patientId,
    drId: 1,
    acuteConditions: {},
    chronicConditions: {},
    patientFavouritesConditions: {},
    drFavouritesCondtions: {},

    acuteSearchText: '',
    chronicSearchText: '',
    patientFavouritesSearchText: '',
    drFavouritesSearchText: '',

    conditionsIdCheckd: {},
    conditionsChecked: {},

    acuteCheckedConditions: {},
    acuteCheckedConditionsId: {},
    chronicCheckedConditions: {},
    chronicCheckedConditionsId: {},
    patientFavCheckedConditions: {},
    patientFavCheckedConditionsId: {},
    drFavCheckedConditions: {},
    drFavCheckedConditionsId: {},
    add_acute_fav: false,
    add_chronic_fav: false,

    acuteSelectedOption: 'Mild',
    chronicSelectedOption: 'Mild',
    patientFavSelectedOption: 'Mild',
    drFavSelectedOption: 'Mild',

    acuteData: [],
  };

  componentDidMount() {
    this.props.patientConditionsLocal({ searchTxt: '', patientId: this.state.patientId });
    this.props.drFavConditionsLocal({drId: this.state.drId});
  }

  componentWillReceiveProps(nextProps) {  
    if (nextProps.redux_patientId!==this.props.redux_patientId) {
            this.setState({ patientId: nextProps.redux_patientId });
        }
  }

  handleOptionChange = (changeEvent, type) => {
    if (type === 'acute') {
      this.setState({
        acuteSelectedOption: changeEvent.target.value,
      });
    } else if (type === 'chronic') {
      this.setState({
        chronicSelectedOption: changeEvent.target.value,
      });
    }

    if (type === 'patientFavourites') {
      this.setState({
        patientFavSelectedOption: changeEvent.target.value,
      });
    }
    if (type === 'drFavourites') {
      this.setState({
        drFavSelectedOption: changeEvent.target.value,
      });
    }
  };

  handleChangeText = (e, type) => {
    if (type === 'acute') {
      this.setState({ acuteSearchText: e.target.value });
      this.props.searchConditions({ searchText: e.target.value }).then(list => {
        this.setState({ acuteConditions: list.data.content });
      });
    } else if (type === 'chronic') {
      this.setState({ chronicSearchText: e.target.value });
      this.props.searchConditions({ searchText: e.target.value }).then(list => {
        this.setState({ chronicConditions: list.data.content });
      });
    } else if (type === 'patientFavourites') {
      console.log(e.target.value);
      this.props.patientConditionsLocal({ searchTxt: e.target.value, patientId: this.state.patientId });
    } else if (type === 'drFavourites') {
      this.props.drFavConditionsLocal({ searchTxt: e.target.value, drId: this.state.drId });
    }
    //this.searchCondition(type);
    console.log(this.state);
  };
  searchCondition = type => {
    switch (type) {
      case 'acute': {
        // var searchParam = { searchText: this.state.acuteSearchText };
        // this.props.searchConditions(searchParam).then(list => {
        //   this.setState({ acuteConditions: list.data.content });
        // });
        break;
      }
      case 'chronic': {
        // var searchParam = { searchText: this.state.chronicSearchText };
        // this.props.searchConditions(searchParam).then(list => {
        //   this.setState({ chronicConditions: list.data.content });
        // });
        break;
      }
      case 'patientFavourites': {
        //this.props.patientConditionsLocal(searchParam);
        break;
      }
      case 'drFavourites': {
        // var searchParam = { searchText: this.state.drFavouritesSearchText };
        // this.props.drFavConditionsLocal(searchParam).then(list => {
        //   this.setState({ drFavouritesCondtions: list.data.content });
        // });
        break;
      }
      default:
        alert('Something went wrong!');
    }
  };
  handleCheckbox = (e, data, id, type) => {
    if (type === 'acute') {
      let itemChecked = this.state.acuteCheckedConditions;
      let itemCheckedId = this.state.acuteCheckedConditionsId;
      if (!e.target.checked) {
        delete itemChecked[data];
        delete itemCheckedId[id];
      } else {
        itemChecked[data] = e.target.checked;
        itemCheckedId[id] = e.target.checked;
      }
      this.setState({
        acuteCheckedConditions: itemChecked,
        acuteCheckedConditionsId: itemCheckedId,
      });
    }
    if (type === 'chronic') {
      let itemChecked = this.state.chronicCheckedConditions;
      let itemCheckedId = this.state.chronicCheckedConditionsId;
      if (!e.target.checked) {
        delete itemChecked[data];
        delete itemCheckedId[id];
      } else {
        itemChecked[data] = e.target.checked;
        itemCheckedId[id] = e.target.checked;
      }
      this.setState({
        chronicCheckedConditions: itemChecked,
        chronicCheckedConditionsId: itemCheckedId,
      });
    }
    if (type === 'patientsFavourites') {
      let itemChecked = this.state.patientFavCheckedConditions;
      let itemCheckedId = this.state.patientFavCheckedConditionsId;
      if (!e.target.checked) {
        delete itemChecked[data];
        delete itemCheckedId[id];
      } else {
        itemChecked[data] = e.target.checked;
        itemCheckedId[id] = e.target.checked;
      }
      this.setState({
        patientFavCheckedConditions: itemChecked,
        patientFavCheckedConditionsId: itemCheckedId,
      });
    }
    if (type === 'drFavourites') {
      let itemChecked = this.state.drFavCheckedConditions;
      let itemCheckedId = this.state.drFavCheckedConditionsId;
      if (!e.target.checked) {
        delete itemChecked[data];
        delete itemCheckedId[id];
      } else {
        itemChecked[data] = e.target.checked;
        itemCheckedId[id] = e.target.checked;
      }
      this.setState({
        drFavCheckedConditions: itemChecked,
        drFavCheckedConditionsId: itemCheckedId,
      });
    }
  };
  handleAddText = e => {
    this.setState({
      AddConditionText: e.target.value,
    });
  };

  acuteAddCondition = data => {
    var AddParam = {
      AddConditionText: this.state.acuteSearchText,
    };
    this.props.apiAddConditions(AddParam);
    // this.searchCondition();
  };
  chronicAddCondition = data => {
    var AddParam = {
      AddConditionText: this.state.chronicSearchText,
    };
    this.props.apiAddConditions(AddParam);
    // this.searchCondition();
  };
  handleSave = e => {
    this.props.diagonsisSave(this.state);
  };
  handleDelete = (e, data) => {
    if (data !== undefined && data !== null) {
      this.props.deletePatientId({
        condition_id: data,
      });
      // .then(res => {
      //   resetForm()
      // })
    }
  };

  handlePatientDelete = data => {
    if (data !== undefined && data !== null) {
      this.props.deletePatientFavId({
        condition_id: data,
      });
    }
  };
  handleDoctorDelete = data => {
    if (data !== undefined && data !== null) {
      this.props.deleteDoctorFavId({
        condition_id: data,
      });
    }
  };
  render() {
    return (
      <Popup
        trigger={
          <button
            type="button"
            className="btn btn-default"
            style={{
              float: '',
              /*margin: ' -4px  5px 0px 0px',*/
              width: 'auto',
            }}
          >
            Add
          </button>
        }
        modal
        closeOnDocumentClick={false}
      >
        {close => (
          <div>
            <div class="popup-title">
              <span>{this.props.popupName}</span>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => close()}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <NotificationContainer />
              
              <div className="col-sm-12">
               {/*<Popup
                                      style={{ minHeight: "350px" }}
                                      open={this.state.showModal}
                                      close={() => this.close()} 
                                      position="right center"
                                      modal
                                      closeOnDocumentClick={false}
                                      contentStyle={{}} >
                                      <div>
                                              <div className="modal-header">
                                                <button
                                                  type="button"
                                                  className="close"
                                                  onClick={() => this.close()}
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                >
                                                  {" "}
                                                  ×
                                                </button>
                                                <span>Allergies/Reactions</span>
                                                </div>
                                                <div className="modal-body newpostpopup"
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    <div
                                                      className="col-md-12"
                                                      style={{
                                                        marginTop: "0px",
                                                        paddingLeft: "0px",
                                                        paddingRight: "0px"
                                                      }}
                                                    >

                                                    {/* <CommonGrid
                                          style={gridstyle}
                                          handleButton={this.handleButton}
                                          crud={this.state.allergies_tb}
                                          key={this.state.key}
                                        /> 


</div>
                                      <div className="col-sm-12" >
                                          <div className="col-sm-6" >                                      
                                            <div className="form-group row" 
                                              style={{position:'relative',overflow:'visible'}}
                                              >
                                              <label className="col-form-label text-left" >Allergies:</label>
                                                <input type="text" id="searchAllergy" style={{padding:'6px 12px'}} className="form-control" autocomplete="off"
                                                ref={input => this.search = input}
                                                onChange={this.handleInputChange}
                                                />
                                              {/*}  <div id="allergies_list"
                                                  style={{
                                                    background:'#fff',
                                                    maxHeight:'90px',
                                                    width:'100%',
                                                    position:'absolute',
                                                    left:'0',
                                                    top:'50px',
                                                    overflow:'auto',
                                                    border:'solid 1px #ccc',
                                                    padding:'20px',
                                                    display:'none',
                                                    zIndex:'1'                    
                                                  }}
                                                  >
                                                  {allergies.filter(allergy => allergy.allergy_name.toLowerCase().includes(this.state.al_query)).map(allergy=>(
                                                      <p 
                                                      style={{
                                                        borderBottom:'solid 1px #dfdfdf',
                                                        padding:'5px',
                                                        margin:'0',
                                                      }}
                                                      ><a href="javascriptt:void(0)" onClick={event => this.handleMenuItemClick(event)} id={allergy.allergy_id} >{allergy.allergy_name} </a></p>                                                                       
                                                            ))}
                                                    </div>
                                              </div>
                                        </div>
                                        <div className="col-sm-6" >                                      
                                        <div className="form-group row"
                                        style={{position:'relative',overflow:'visible'}}
                                        >
                                        <label className="col-form-label text-left" >Reactions:</label>
                                        <input type="text" id="searchReaction" className="form-control" style={{padding:'6px 12px'}} autocomplete="off"
                                        ref={input => this.search1 = input}
                                        onChange={this.handleInputChange1}
                                        />
                                        <div id="reactions_list"
                                        style={{
                                          background:'#fff',
                                          maxHeight:'90px',
                                          width:'100%',
                                          position:'absolute',
                                          left:'0',
                                          top:'50px',
                                          overflow:'auto',
                                          border:'solid 1px #ccc',
                                          padding:'20px',
                                          display:'none',
                                          zIndex:'1'                    
                                        }}
                                        >
                                        {reactions.filter(reaction => reaction.reaction_name.toLowerCase().includes(this.state.re_query)).map(reaction=>(
                                            <p 
                                            style={{
                                              borderBottom:'solid 1px #dfdfdf',
                                              padding:'5px',
                                              margin:'0',
                                            }}
                                            ><a href="javascriptt:void(0)" onClick={event => this.handleMenuItemClick1(event)} >{reaction.reaction_name} </a></p>                                                                       
                                                          ))}
                                        </div>
                                        </div>
                                      </div>

                                      <div className="col-sm-6" >                                      
                                        <div className="form-group row">
                                        <label className="col-form-label text-left pb-2" >Severity:</label>
                                        {/* <select className="form-control" style={{padding:'6px 12px'}}  id="severity"
                                       onChange={(e) => this.setState({ severity: e.target.value })}
                                        >
                                        <option value="">Select</option>
                                        <option value="0">Low</option>
                                        <option value="1">Mild</option>
                                          <option value="2">High</option>
                                          
                                          </select> 
                                          <input type="radio"  name="severity"  
                                onChange={e =>
                                  this.handleFieldValueChanges(
                                    "severity",
                                    "Low"
                                  )
                                }
                                value="Low"
                                checked={this.state.severity == "Low"}
                                />&nbsp;Low&nbsp; 
                                <input type="radio"  name="severity" 
                                onChange={e =>
                                  this.handleFieldValueChanges(
                                    "severity",
                                    "Mild"
                                  )
                                }
                                value="Mild"
                                checked={this.state.severity == "Mild"}
                                />&nbsp;Mild&nbsp; 
                                <input type="radio"  name="severity" 
                                onChange={e =>
                                  this.handleFieldValueChanges(
                                    "severity",
                                    "High"
                                  )
                                }
                                value="High"
                                checked={this.state.severity == "High"}
                                />&nbsp;High&nbsp; 
                                        </div>
                                      </div>

                                      <div className="col-sm-6" >                                      
                                        <div className="form-group row">
                                        <label className="col-form-label text-left" >Comments:</label>
                                        <textarea id="comments" className="form-control" style={{padding:'6px 12px'}} 
                                         onChange={(e) => this.setState({ comments: e.target.value })}
                                         ></textarea>
                                        </div>
                                      </div>

                                      

                                      </div>
                                   </div>
                                   <div className="modal-footer">
                                   <button
                                          type="button"  onClick={e => this.selAll("", "save", "")}
                                          className="btn btn-primary btn-xs"
                                          data-dismiss="modal"
                                        >
                                          Save
                                        </button>
                                        <button
                                          type="button"  onClick={() => this.close()}
                                          className="btn btn-primary btn-xs"
                                          data-dismiss="modal"
                                        >
                                          Cancel
                                        </button>
                                   </div>   
                        </div>          
                        </Popup>
*/}
              
              </div>
             
            <div className="modal-footer">
              <div className="row">
                <div className="col-sm-6">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                      this.handleSave();
                      close();
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                    onClick={() => close()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          </div>
        )}
      </Popup>
    );
  }
}

//export default Diagonsis;
const mapDispatchToProps = dispatch => {
  return {
    patientConditionsLocal: data => dispatch(patientConditions(data)),
    drFavConditionsLocal: data => dispatch(drConditions(data)),
    //  ChangeDragData: data => dispatch(ChangeDragData(data)),
    //  deleteData: data => dispatch(deleteData(data)),
    searchConditions: searchParam =>
      dispatch(api_searchConditions(searchParam)),
    apiAddConditions: data => dispatch(api_addConditions(data)),
    // addPaitentData: data => dispatch(addPaitentData(data)),
    // editPaitentData: data => dispatch(editPaitentData(data)),
    deletePatientId: data => dispatch(deletePatientId(data)),
    deletePatientFavId: data => dispatch(deletePatientFavId(data)),
    deleteDoctorFavId: data => dispatch(deleteDoctorFavId(data)),
  };
};

const mapStateToProps = state => {
  let patient_fav_localdata = state.getDrAppointments.patient_fav_data
    ? state.getDrAppointments.patient_fav_data
    : [];
  let drFavData = state.getDrAppointments.dr_fav_data
    ? state.getDrAppointments.dr_fav_data
    : [];
    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
  return {
    patient_fav_localdata,
    drFavData,redux_patientId
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Allergies);
