import React, { Component } from "react";
import Header from "../../header";
import Footer from "../../footer";
import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
import { connect } from 'react-redux';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import {NotificationContainer,NotificationManager} from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import AddInvoiceNote from "./addInvoiceText";
import {api_delete_selection} from '../../../DS/DS.ListPage';
class InvoiceText extends Component {
    constructor(props)
    {
        super(props);
        this.state={
            showadd:false,
            key:0,
            selectedId:'',
            selectedLength:0,
            noteCrud: {
                jsonGridVersion: "v2.0",
                grid: true,
                viewgrid: {
                  isVisible:true,
                  isPagination: true,
                  isFiltering: true,
                  isSelection:true,
                  deleteFunction:this.removeInvoice,
                  isDateGroup:false,
                  isYearSeparator:false,
                  dateCol:'',
                  groupDateSelection:false,
                  cellClicked:'',
                  baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                  query: {
                    tb: "invoice_notes_tb",
                    cols: [
                      "notes_id",
                      "note_text",
                    ],
                   where: [
                      {
                        type: "and",
                        key: "isDeleted",
                        operator: "=",
                        value: 0
                   }
                   ],
                    primary_key:"notes_id",
                  },
                  viewtabletitle: "",
                  columns: [
                    {name: "note_text", title: "Invoice Notes" },
                  ],
                  rows: [],
                  sortinit: [{ columnName: "notes_id", direction: "desc" }],
                  pagesize: 10,
                  getAllIds:false,
                  getallId:''
                }
              }
        }
    }

    reloadPage=()=>
    {
        this.setState({key:Math.random(),showadd:false})
    }
    checkAdd=(actionfor)=>
    {   
        if(actionfor=='Add')
        {
            if(this.state.selectedLength>0)
            {
                alert("Please deselect the records to proceed")
            }
            else
            {
                this.setState({showadd:true})
            }
        }
        if(actionfor=='Edit')
        {
            if(this.state.selectedLength == 1)
            {
                this.setState({showadd:true})
            }
            else
            {
                alert("Please select single records to proceed")
                
            }
        }

    }
    removeInvoice=(ids,action,totalLength )=>
    {
       if(action=='selection')
       {
         this.state.selectedId=ids;
         this.state.selectedLength=totalLength;
       }
       if(action=='delete')
       {
           if (this.state.selectedLength> 0) {
                 const cancellationData={tb_name:'invoice_notes_tb',ids:this.state.selectedId,primary_key:'notes_id'}
                 confirmAlert({
                   title: '',
                   message: 'You are about to delete invoice note(s). Do you want proceed?',
                   buttons: [
                   {
                       label: 'Yes',
                       onClick: () => {
                       this.props.apiDelete(cancellationData).then(
                       res => this.reloadPage() ,
                       NotificationManager.success(
                       'Invoice note(s) deleted successfully',
                       )
                     );
                   },
               },
               {
                 label: 'No',
                 onClick: () => { this.reloadPage()},
               },
             ],
           });
         } 
         else 
         {
           NotificationManager.error('Please select invoice note(s) to delete');
         }
       }
    }
    render()
    {
        return(
                <div class="invosetex">
                    <AdminHeader /><Leftsidemenu />
                    <NotificationContainer />
                    <section className="content-header" style={{marginLeft: '240px'}}>
                        <h1>
                        Invoice notes
                        </h1>
                        <ol className="breadcrumb">
                            <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                            <li className="active">Invoice notes</li>
                        </ol>
                        </section>
                        {/* main section start */}
                        <section className="content"  style={{marginLeft: '240px'}}>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="box">
                                        <div className="col-xs-12" style={{margin:'0px auto', float:'none'}}>
                                            <div className="box-body">
                                                <div className="col-sm-12 col-md-12 col-xl-12">
                                                    <div id="pageContent">
                                                    <div className="form-group row m-t-10">
                                                            <div className="col-lg-12 col-md-5 col-xl-5 m-t-60" style={{marginBottom:'-10px'}}>
                                                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary btn-xs" data-toggle="modal" data-target="#add-btn" style={{width:'100px', margin:'5px 7px 0px 0'}}
                                                                onClick={()=>this.checkAdd('Add')}>Add</button>
                                                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary btn-xs" data-toggle="modal" data-target="#edit-btn"style={{width:'100px', margin:'5px 7px 0px 0'}}
                                                                 onClick={()=>this.checkAdd('Edit')}>Edit</button>
                                                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary btn-xs" style={{width:'100px', margin:'5px 0 0 0'}}
                                                                onClick={()=>this.removeInvoice('','delete','')}>Delete</button>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row m-b-10" style={{marginTop:'-150px'}}>
                                                            <div className="col-sm-12 col-md-6 col-xl-6 m-t-10">
                                                                <label className="col-sm-12 col-form-label text-left" style={{fontSize:'14px'}}>Invoice notes</label>
                                                                <div className="card" style={{paddingLeft: '', height:'', overFlowY:'scroll', border:''}}>
                                                                    <div className="card-block">
                                                                       {/*} <span style={{fontSize:'16px'}}> Text of note<br/> </span>
                                                                            721+723 review done<br/>
                                                                            Both ears<br/>*/}
                                                                            <CommonGrid
                                                                    crud={this.state.noteCrud}
                                                                    key={this.state.key}
                                                                />      
                                                                              </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer modal-footer-bottom-margin">
                                                <div className="row">
                                                    <div className="col-sm-10"> </div>
                                                    <div className="col-sm-2">
                                               {/*}         <button type="button" className="btn btn-default" data-dismiss="modal">Save</button>
                                                        <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close">Cancel</button> 
                                                */}  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(this.state.showadd) ?
                                <AddInvoiceNote showModal={this.state.showadd} sid={this.state.selectedId} changekey={this.reloadPage}/> : ''
                            }
                            
                        </section>
                        <Footer/>
                </div>
      )
    }
}
const mapDispatchToProps = dispatch => {
    return {
        apiDelete:data =>dispatch(api_delete_selection(data))
     };
  };
export default connect(null,mapDispatchToProps)(InvoiceText);