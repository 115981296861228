import React, { Component } from "react";
import Header from "../../header";
import Footer from "../../footer";
import Grid from "../../crudcomponent/combinedcrud";
import { dropDown, dropdown1 } from "../../../DS/services";
import axios from 'axios';
import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
import { Link } from "react-router-dom";
import AccountDetails from "./AccountDetails";

function onSelectRow(row, isSelected, e) {
  if (isSelected) {
    alert(`You just selected '${row['name']}'`)
  }
}
 
const selectRowProp = {
  mode: 'checkbox',
  clickToSelect: true,
  unselectable: [2],
  selected: [1],
  onSelect: onSelectRow,
  bgColor: 'gold'
};

const AgeSelector = props => {
  const { value, onChange } = props
  const handleChange = val => evt => {
    evt.preventDefault()
    onChange(val)
  }
 
  return (
    <div>
      <button
        style={{ backgroundColor: value === 20 ? 'red' : 'white' }}
        onClick={handleChange(20)}
      >
        20
      </button>
      <button
        style={{ backgroundColor: value === 30 ? 'red' : 'white' }}
        onClick={handleChange(30)}
      >
        30
      </button>
      <button
        style={{ backgroundColor: value === 40 ? 'red' : 'white' }}
        onClick={handleChange(40)}
      >
        40
      </button>
   
    </div>
  )
}
const widgets = {
  ageSelector: AgeSelector
}

class NonpatientAccount extends Component {
  
  constructor(props) {
    super(props);
  
  this.state = {
    size:'',
    showMyComponent: true,
    checked: false,
    crud: {
      jsonGridVersion: "v2.0",
      pagetitle: "Select Account Holder",
      subtitle: "sub title",
      grid: true,
      breadcrumb: {
        link: "./NonpatientAccount",
        text: "NonpatientAccount"
      },
      add_form: {
       
        isBacktoGridOnSubmit: false,
        destination: "patients",
        pk: "patient_id",
        successMessage: "Successfully Added",
        errorMessage: "Something went to Wrong, Try again later",
        schema: {
          title: "Add patients",
          type: "object",
          required: ["Firstname","branch_id"],
          properties: {
            Title: { type: "string", title: "Title" },
            Firstname: { type: "string", title: "First Name" },
            Middlename: { type: "string", title: "Middle Name"},
            Surname:{ type: "string", title: "Surname"},
            Preferredname:{ type: "string", title: "Preferredname"},
            Address1: { type: "string", title: "Address1" },
            Address2: { type: "string", title: "Address2"},
            Postcode: { type: "string", title: "Post Code"},
            PostalAddress: { type: "string", title: "Postal Address"},
            //PostalCity: { type: "string", title: "PostalCity"},
            PostalPostcode: { type: "string", title: " Postal Postcode"},
            DOB: { type: "string", title: "Date Of Birth" },
            Age: { type: "number", title: "Age"},
            Ethnicity: { type: "string", title: "Ethnicity"},
            Phone: { type: "number", title: "Phone"},
            medicare_no: { type: "string", title: "medicare_no" },
            pensionNo	: { type: "string", title: "pensionNo	" },
            occupation	: { type: "string", title: "occupation	" },
            City: dropDown("city_tb", "city_id", "city_name", ""),
            Sex: dropDown("lookup_tb", "value", "display", "where field_name='Sex'"),
            branch_id: dropDown("branches_tb", "branch_id", "name", ""),
          }
        },

      },
      edit_form: {
        isVisible: true,
        isBacktoGridOnSubmit: true,
        destination: "patients",
        pk: "patient_id",
        successMessage: "Successfully Updated",
        errorMessage: "Something went to Wrong, Try again later",
        schema: {
          title: "Edit patient",
          type: "object",
         required: ["Firstname","branch_id"],
          properties: {
            Title: { type: "string", title: "Title" },
            Firstname: { type: "string", title: "Firstname" },
            Middlename: { type: "string", title: "Middlename"},
            Surname:{ type: "string", title: "Surname"},
            Preferredname:{ type: "string", title: "Preferredname"},
            Address1: { type: "string", title: "Address1" },
            Address2: { type: "string", title: "Address2"},
            Postcode: { type: "string", title: "Postcode"},
            PostalAddress: { type: "string", title: "PostalAddress"},
            //PostalCity: { type: "string", title: "PostalCity"},
            PostalPostcode: { type: "string", title: " PostalPostcode"},
            DOB: { type: "string", title: "Date Of Birth" },
            Age: { type: "number", title: "Age"},           
            Ethnicity: { type: "string", title: "Ethnicity"},          
            Phone: { type: "number", title: "Phone"},
            medicare_no: { type: "string", title: "medicare_no" },
            pensionNo	: { type: "string", title: "pensionNo	" },
            occupation	: { type: "string", title: "occupation	" },
            City: dropDown("city_tb", "city_id", "city_name", ""),
            Sex: dropDown("lookup_tb", "value", "display", "where field_name='Sex'"),
            branch_id: dropDown("branches_tb", "branch_id", "name", ""),
            ID: { type: "number" }
          }
        },
        ui_schema: {

          ID: {
            "ui:widget": "hidden"
          },
        }
       
      },
      delete: {
        destination: "patients",
        pk: "patient_id",
        isConfirmBeforeDelete: true,
        successMessage: "Successfully Deleted..!",
        errorMessage: "Something went to Wrong, Try again later",
        confirmationTitle: "Do you really want to delete?",
        confirmationMessage: "Are you sure to do this."
      },
      viewgrid: {
        isVisible:true,
        isPagination: true,
        isFiltering: true,
        isActions: true,
        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "patients",
          cols: [
            "patient_id",
            "Firstname",
            "DOB",
            "City",
            "Phone",
            "branch_id",
            "medicare_no"
          ],
          customCols: [
        //  "CONCAT(Firstname,' ',Middlename,' ', Surname) AS patientname",
         // "CONCAT(Address1,' ',Address2) AS Address"
          ],
         /* joins: [
            {
              joinType: "inner",
              joinTable: "branches_tb",
              joinCondition: "branches_tb.branch_id = patients.branch_id"
            },
         
            {
              joinType: "inner",
              joinTable: "lookup_tb",
              joinCondition: "lookup_tb.value = patients.Sex"
            }

          ],
          where: [
            {
              type: "and",
              key: "patients.isDeleted",
              operator: "=",
              value: "0"
            },
            {
              type: "and",
              key: "lookup_tb.field_name",
              operator: "=",
              value: "Sex"
            }
           
          ]*/
        },
        viewtabletitle: "",
        columns: [
          //{ name: "Title", title: "Title"}, 
          { name: "Firstname", title: "Patient Name" },
          { name: "Age", title: "Age" },
          { name: "Address1", title: "Address" },
         // { name: "Preferredname", title: "Preferred Name"}, 
          { name: "DOB", title: "DOB" },         
          { name: "medicare_no", title: "Medicare No" }, 
          { name: "action", title: "Action"}
        ],
        rows: [],
        sortinit: [{ columnName: "patient_id", direction: "desc" }],
        pagesize: 5,
        actions: [
          {
            buttonClass: "info",
            icon: "fa-pencil",
            text: "",
            function: "edit",
            passParam: "patient_id",
            isDefault: true
          },
          {
            buttonClass: "danger",
            icon: "fa-trash",
            text: "",
            function: "delete",
            passParam: "patient_id",
            isDefault: true
          },
          {
            buttonClass: "primary",
            icon: "fa-eye",
            text: "",
            function: "view",
            passParam: "start_date",
            isDefault: false
          },
          {
            buttonClass: "warning",
            text: "Account Details",
            function: "view",
            passParam: "start_date",
            isDefault: false
          }
        ]
      }
    }
  };
 
  this.handleChange = this.handleChange.bind(this);
  this.handleChange1 = this.handleChange1.bind(this);
  this.handleChange2 = this.handleChange2.bind(this);
  this.handleCheck = this.handleCheck.bind(this);

  }

  handleChange(event){   
    this.setState({
      size: event.target.value,
      handleChanged:true,
      handleClicked: false,
      onChanged:false,
      showMyComponent:false,
    });
  }
  handleChange1(event){
    this.setState({
      size1: event.target.value,   
      handleClicked:true,
      res1:false,
      handleChanged: false,
      onChanged:false,
      showMyComponent:false,

    });
  }
  handleChange2(event){
    this.setState({
      size2: event.target.value,  
      onChanged:true,
      handleChanged: false,
      handleClicked: false,  
      showMyComponent:false,
  
    });

  }
  toggle(position){
    if (this.state.active === position) {
      this.setState({active : null})
    } else {
      this.setState({active : position})
    }
  }
  handleCheck() {
    this.setState({checked: !this.state.checked});
  }
 
  render() {
   
    var gridstyle = {
      padding: "10px"
    };
  
    var res;
   
    if(this.state.handleChanged){
      res=<Patient/>
     
     }
     else if(this.state.handleClicked){
       res=<AccountHolder/>
     }
     else if(this.state.onChanged){
      res=<Contact/>
     
     }
  

    return (
      <div>
        <body
          className="skin-blue sidebar-mini"
          data-gr-c-s-loaded="true"
          style={{ height: "auto", minHeight: "100%" }}
        >
          <div
            className="wrapper"
            style={{ height: "auto", minHeight: "100%" }}
          >
            <AdminHeader />
            <Leftsidemenu />
            {/* Content Header (Page header) */}

            <div className="content-wrapper" style={{ minHeight: 314 }}>
              <section className="content-header">
                <h1>
                  {this.state.crud.pagetitle}
                  <small> {this.state.crud.subtitle} </small>
                </h1>
                <ol className="breadcrumb">
                  <li>
                    <a href="#">
                      <i className="fa fa-dashboard" /> Home
                    </a>
                  </li>
      
                  <li className="active">
                    <Link to={this.state.crud.breadcrumb.link}>
                      {this.state.crud.breadcrumb.text}
                    </Link>
                  </li>
                </ol>
              </section>  
          <div className="row">
    
           <div className="col-lg-3"></div>
           
            <div className="col-lg-6">
          
            <div style={{display: 'flex',  justifyContent:'space-around', alignItems:'center',fontSize:'16px'}}>
            
            <div>
              <input id="patient"  value="patient" name="patient" type="radio" style={{ width: 16, height: 16 }} 
          onChange={this.handleChange} style={{ width: 16, height: 16 }}
          //  onClick={this._refreshPage}
          />Patient
           </div>
    
         <div>
          <input id="patient" value="non-patient" name="patient" type="radio" style={{ width: 16, height: 16 }}
            onChange={this.handleChange1}
          />Account Holder
        </div>          
 
         <div>
          <input id="patient" value="complex" name="patient" type="radio" style={{ width: 16, height: 16 }}
            onChange={this.handleChange2}
          />Contact
         </div>
         </div>
           <div className="col-lg-3"></div>    
         </div>
         </div>
         <div className="row"></div>
           <div className="row" style={{marginTop:'10px'}}>
             <div className="col-lg-3"></div>
           
              <div className="col-lg-6">
         
               <div style={{display: 'flex', justifyContent:'space-around', alignItems:'center',fontSize:'20px'}}>
         
               <label style={{fontWeight:'normal'}}>
                 <input type="checkbox" 
                style={{ width: 16, height: 16}} onChange={this.handleCheck} defaultChecked={this.state.checked}/>
                <span>Include Inactive</span>
               </label>  
               <label style={{fontWeight:'normal'}}>
                 <input type="checkbox"  style={{ width: 16, height: 16 }} 
                 />
                <span>Include Deceased</span>
              </label> 
             </div>
            </div>       
          <div>
         {res} 
     </div>
   
  <div className="col-lg-3"></div>
  </div>
              {/* Main content */}
              <section className="content">
                <div className="row">
                  <div className="col-lg-12">
                  { this.state.showMyComponent ?
                  <Grid
                      style={gridstyle}
                      handleButton={this.handleButton}
                      crud={this.state.crud}
                    />
                    : null }
                  </div>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </body>
      </div>
    );
  }
}
class Patient extends Component {
  
  constructor(props) {
    super(props);
     this.state = {
   
    crud: {
      jsonGridVersion: "v2.0",
      pagetitle: "Select Account Holder",
      subtitle: "sub title",
      grid: true,
      breadcrumb: {
        link: "./patientDetails",
        text: "patientDetails"
      },
      add_form: {
        isVisible: false,
        isBacktoGridOnSubmit: true,
        destination: "patients",
        pk: "patient_id",
        successMessage: "Successfully Added",
        errorMessage: "Something went to Wrong, Try again later",
        schema: {
          title: "Add patients",
          type: "object",
          required: ["Firstname","branch_id"],
          properties: {
            Title: { type: "string", title: "Title" },
            Firstname: { type: "string", title: "First Name" },
            Middlename: { type: "string", title: "Middle Name"},
            Surname:{ type: "string", title: "Surname"},
            Preferredname:{ type: "string", title: "Preferredname"},
            Address1: { type: "string", title: "Address1" },
            Address2: { type: "string", title: "Address2"},
            Postcode: { type: "string", title: "Post Code"},
            PostalAddress: { type: "string", title: "Postal Address"},
            //PostalCity: { type: "string", title: "PostalCity"},
            PostalPostcode: { type: "string", title: " Postal Postcode"},
            DOB: { type: "string", title: "Date Of Birth" },
            Age: { type: "number", title: "Age"},
           isActive:{ type: "number", title: "Age"},
            Ethnicity: { type: "string", title: "Ethnicity"},
            Phone: { type: "number", title: "Phone"},
            medicare_no: { type: "string", title: "medicare_no" },
            pensionNo	: { type: "string", title: "pensionNo	" },
            occupation	: { type: "string", title: "occupation	" },
            City: dropDown("city_tb", "city_id", "city_name", ""),
            Sex: dropDown("lookup_tb", "value", "display", "where field_name='Sex'"),
          branch_id: dropDown("branches_tb", "branch_id", "name", ""),
          }
        },

      },
      edit_form: {
        isVisible: true,
        isBacktoGridOnSubmit: true,
        destination: "patients",
        pk: "patient_id",
        successMessage: "Successfully Updated",
        errorMessage: "Something went to Wrong, Try again later",
        schema: {
          title: "Edit patient",
          type: "object",
         required: ["Firstname","branch_id"],
          properties: {
            Title: { type: "string", title: "Title" },
            Firstname: { type: "string", title: "Firstname" },
            Middlename: { type: "string", title: "Middlename"},
            Surname:{ type: "string", title: "Surname"},
            Preferredname:{ type: "string", title: "Preferredname"},
            isActive:{ type: "number", title: "Age"},
            Address1: { type: "string", title: "Address1" },
            Address2: { type: "string", title: "Address2"},
            Postcode: { type: "string", title: "Postcode"},
            PostalAddress: { type: "string", title: "PostalAddress"},
            //PostalCity: { type: "string", title: "PostalCity"},
            PostalPostcode: { type: "string", title: " PostalPostcode"},
            DOB: { type: "string", title: "Date Of Birth" },
            Age: { type: "number", title: "Age"},          
            Ethnicity: { type: "string", title: "Ethnicity"},         
            Phone: { type: "number", title: "Phone"},
            medicare_no: { type: "string", title: "medicare_no" },
            pensionNo	: { type: "string", title: "pensionNo	" },
            occupation	: { type: "string", title: "occupation	" },
            City: dropDown("city_tb", "city_id", "city_name", ""),
            Sex: dropDown("lookup_tb", "value", "display", "where field_name='Sex'"),
          branch_id: dropDown("branches_tb", "branch_id", "name", ""),
            ID: { type: "number" }
          }
        },
        ui_schema: {
         
          ID: {
            "ui:widget": "hidden"
          },
          
        }
       
      },
      delete: {
        destination: "patients",
        pk: "patient_id",
        isConfirmBeforeDelete: true,
        successMessage: "Successfully Deleted..!",
        errorMessage: "Something went to Wrong, Try again later",
        confirmationTitle: "Do you really want to delete?",
        confirmationMessage: "Are you sure to do this."
      },
      viewgrid: {
        isVisible:true,       
        isFiltering: true,
        isActions: true,
        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "patients",
          cols: [
            "patient_id",       
            "Firstname",
            "DOB",
            "City",
            "Phone",
            "branch_id",
            "medicare_no",
            "isActive",
          ],
          customCols: [
        //  "CONCAT(Firstname,' ',Middlename,' ', Surname) AS patientname",
         // "CONCAT(Address1,' ',Address2) AS Address"
            
          ],
        
        },
        viewtabletitle: "",
        columns: [
          //{ name: "Title", title: "Title"}, 
          { name: "Firstname", title: "Patient Name" },
          { name: "Age", title: "Age" },
          { name: "Address1", title: "Address" },
         // { name: "Preferredname", title: "Preferred Name"}, 
          { name: "DOB", title: "DOB" },         
          { name: "medicare_no", title: "Medicare No" },
          { name: "isActive", title: "isActive" },
        
          { name: "action", title: "Action"}
        ],
        rows: [],
        sortinit: [{ columnName: "patient_id", direction: "desc" }],
        pagesize: 3,
        actions: [
          {
            buttonClass: "info",
            icon: "fa-pencil",
            text: "",
            function: "edit",
            passParam: "patient_id",
            isDefault: true
          },
          {
            buttonClass: "danger",
            icon: "fa-trash",
            text: "",
            function: "delete",
            passParam: "patient_id",
            isDefault: true
          },
          {
            buttonClass: "primary",
            icon: "fa-eye",
            text: "",
            function: "view",
            passParam: "start_date",
            isDefault: false
          }
         
        ]
      }
    }
    
  };
  }
  
  render() {
    var gridstyle = {
      padding: "10px"
    };
  
    return (
      <div>
        
              <div className="row">
    
           <div className="col-lg-3"></div>
           </div>
            <div className="col-lg-6" style={{ width: "100%" }}>
          
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          </div>
       
  <div className="col-lg-3"></div>
  </div>

              {/* Main content */}
              <section className="content">
                <div className="row">
                  <div className="col-lg-12">
                    <Grid
                      style={gridstyle}
                      selectRow={selectRowProp}
                      handleCheck={this.handleCheck}
                      crud={this.state.crud}
                    />
                  </div>
                </div>
              </section>
              <div  style={{ float:'right' }}>
              <AccountDetails  patient_id='19'  />
                              <button  type="button" className="btn btn-default">Cancel</button>
                </div>
          </div>
     
    );
  }
}
class AccountHolder extends Component {
  
  constructor(props) {
    super(props);
  
  this.state = {
   
    crud: {
      jsonGridVersion: "v2.0",
      pagetitle: "Select Account Holder",
      subtitle: "sub title",
      grid: true,
      breadcrumb: {
        link: "./patientDetails",
        text: "patientDetails"
      },
      add_form: {
        isVisible: false,
        isBacktoGridOnSubmit: true,
        destination: "patients",
        pk: "patient_id",
        successMessage: "Successfully Added",
        errorMessage: "Something went to Wrong, Try again later",
        schema: {
          title: "Add patients",
          type: "object",
          required: ["Firstname","branch_id"],
          properties: {
            Title: { type: "string", title: "Title" },
            Firstname: { type: "string", title: "First Name" },
            Middlename: { type: "string", title: "Middle Name"},
            Surname:{ type: "string", title: "Surname"},
            Preferredname:{ type: "string", title: "Preferredname"},
            Address1: { type: "string", title: "Address1" },
            Address2: { type: "string", title: "Address2"},
            Postcode: { type: "string", title: "Post Code"},
            PostalAddress: { type: "string", title: "Postal Address"},
            //PostalCity: { type: "string", title: "PostalCity"},
            PostalPostcode: { type: "string", title: " Postal Postcode"},
            DOB: { type: "string", title: "Date Of Birth" },
            Age: { type: "number", title: "Age"},
           
            Ethnicity: { type: "string", title: "Ethnicity"},
          
            Phone: { type: "number", title: "Phone"},
            medicare_no: { type: "string", title: "medicare_no" },
            pensionNo	: { type: "string", title: "pensionNo	" },
            occupation	: { type: "string", title: "occupation	" },
            City: dropDown("city_tb", "city_id", "city_name", ""),
            Sex: dropDown("lookup_tb", "value", "display", "where field_name='Sex'"),

          branch_id: dropDown("branches_tb", "branch_id", "name", ""),
          }
        },

      },
      edit_form: {
        isVisible: true,
        isBacktoGridOnSubmit: true,
        destination: "patients",
        pk: "patient_id",
        successMessage: "Successfully Updated",
        errorMessage: "Something went to Wrong, Try again later",
        schema: {
          title: "Edit patient",
          type: "object",
         required: ["Firstname","branch_id"],
          properties: {
            Title: { type: "string", title: "Title" },
            Firstname: { type: "string", title: "Firstname" },
            Middlename: { type: "string", title: "Middlename"},
            Surname:{ type: "string", title: "Surname"},
            Preferredname:{ type: "string", title: "Preferredname"},

            Address1: { type: "string", title: "Address1" },
            Address2: { type: "string", title: "Address2"},
            Postcode: { type: "string", title: "Postcode"},
            PostalAddress: { type: "string", title: "PostalAddress"},
            //PostalCity: { type: "string", title: "PostalCity"},
            PostalPostcode: { type: "string", title: " PostalPostcode"},
            DOB: { type: "string", title: "Date Of Birth" },
            Age: { type: "number", title: "Age"},
            
            Ethnicity: { type: "string", title: "Ethnicity"},
           
            Phone: { type: "number", title: "Phone"},
            medicare_no: { type: "string", title: "medicare_no" },
            pensionNo	: { type: "string", title: "pensionNo	" },
            occupation	: { type: "string", title: "occupation	" },
            City: dropDown("city_tb", "city_id", "city_name", ""),
            Sex: dropDown("lookup_tb", "value", "display", "where field_name='Sex'"),
          branch_id: dropDown("branches_tb", "branch_id", "name", ""),
            ID: { type: "number" }
          }
        },
        ui_schema: {
         
          ID: {
            "ui:widget": "hidden"
          },
          
        }
       
      },
      delete: {
        destination: "patients",
        pk: "patient_id",
        isConfirmBeforeDelete: true,
        successMessage: "Successfully Deleted..!",
        errorMessage: "Something went to Wrong, Try again later",
        confirmationTitle: "Do you really want to delete?",
        confirmationMessage: "Are you sure to do this."
      },
      viewgrid: {
        isVisible:true,
        isPagination: true,
        isFiltering: true,
        isActions: true,

        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "patients",
          cols: [
            "patient_id",
            
            "Firstname",
            "DOB",
            "City",
            "Phone",
            "branch_id",
            "medicare_no"
          ],
          customCols: [
        //  "CONCAT(Firstname,' ',Middlename,' ', Surname) AS patientname",
         // "CONCAT(Address1,' ',Address2) AS Address"
            
          ],
         /* joins: [
            {
              joinType: "inner",
              joinTable: "branches_tb",
              joinCondition: "branches_tb.branch_id = patients.branch_id"
            },
         
            {
              joinType: "inner",
              joinTable: "lookup_tb",
              joinCondition: "lookup_tb.value = patients.Sex"
            }

          ],
          where: [
            {
              type: "and",
              key: "patients.isDeleted",
              operator: "=",
              value: "0"
            },
            {
              type: "and",
              key: "lookup_tb.field_name",
              operator: "=",
              value: "Sex"
            }
           
          ]*/
        },
        viewtabletitle: "",
        columns: [
          //{ name: "Title", title: "Title"}, 
          { name: "Firstname", title: "Patient Name" },

         { name: "action", title: "Action"}
        ],
        rows: [],
        sortinit: [{ columnName: "patient_id", direction: "desc" }],
        pagesize: 5,
        actions: [
          {
            buttonClass: "info",
            icon: "fa-pencil",
            text: "",
            function: "edit",
            passParam: "patient_id",
            isDefault: true
          },
          {
            buttonClass: "danger",
            icon: "fa-trash",
            text: "",
            function: "delete",
            passParam: "patient_id",
            isDefault: true
          },
          {
            buttonClass: "primary",
            icon: "fa-eye",
            text: "",
            function: "view",
            passParam: "start_date",
            isDefault: false
          }
        ]
      }
    }
    
  };
  }
  
  render() {
    var gridstyle = {
      padding: "10px"
    };
  
    return (
      <div>
         
            {/* Content Header (Page header) */}

           
           <div className="row">
    
           <div className="col-lg-3"></div>
           </div>
            <div className="col-lg-6" style={{ width: "100%" }}>   
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          </div>
       
  <div className="col-lg-3"></div>
  </div>

              {/* Main content */}
              <section className="content">
                <div className="row">
                  <div className="col-lg-12">
                    <Grid
                      style={gridstyle}
                      handleButton={this.handleButton}
                      crud={this.state.crud}
                    />           
                  </div>           
                </div>
                </section>
                <div  style={{ float:'right' }}>
                <AccountDetails  patient_id='19'  />
                              <button  type="button" className="btn btn-default">Cancel</button>
                </div>         
          </div>
        
    );
  }
}
class Contact extends Component {
  
  constructor(props) {
    super(props);
  
  this.state = {
   
    crud: {
      jsonGridVersion: "v2.0",
      pagetitle: "Select Account Holder",
      subtitle: "sub title",
      grid: true,
      breadcrumb: {
        link: "./patientDetails",
        text: "patientDetails"
      },
      add_form: {
        isVisible: false,
        isBacktoGridOnSubmit: true,
        destination: "patients",
        pk: "patient_id",
        successMessage: "Successfully Added",
        errorMessage: "Something went to Wrong, Try again later",
        schema: {
          title: "Add patients",
          type: "object",
          required: ["Firstname","branch_id"],
          properties: {
            Title: { type: "string", title: "Title" },
            Firstname: { type: "string", title: "First Name" },
            Middlename: { type: "string", title: "Middle Name"},
            Surname:{ type: "string", title: "Surname"},
            Preferredname:{ type: "string", title: "Preferredname"},
            Address1: { type: "string", title: "Address1" },
            Address2: { type: "string", title: "Address2"},
            Postcode: { type: "string", title: "Post Code"},
            PostalAddress: { type: "string", title: "Postal Address"},
            //PostalCity: { type: "string", title: "PostalCity"},
            PostalPostcode: { type: "string", title: " Postal Postcode"},
            DOB: { type: "string", title: "Date Of Birth" },
            Age: { type: "number", title: "Age"},          
            Ethnicity: { type: "string", title: "Ethnicity"},          
            Phone: { type: "number", title: "Phone"},
            medicare_no: { type: "string", title: "medicare_no" },
            pensionNo	: { type: "string", title: "pensionNo	" },
            occupation	: { type: "string", title: "occupation	" },
            City: dropDown("city_tb", "city_id", "city_name", ""),
            Sex: dropDown("lookup_tb", "value", "display", "where field_name='Sex'"),
          branch_id: dropDown("branches_tb", "branch_id", "name", ""),
          }
        },

      },
      edit_form: {
        isVisible: true,
        isBacktoGridOnSubmit: true,
        destination: "patients",
        pk: "patient_id",
        successMessage: "Successfully Updated",
        errorMessage: "Something went to Wrong, Try again later",
        schema: {
          title: "Edit patient",
          type: "object",
         required: ["Firstname","branch_id"],
          properties: {
            Title: { type: "string", title: "Title" },
            Firstname: { type: "string", title: "Firstname" },
            Middlename: { type: "string", title: "Middlename"},
            Surname:{ type: "string", title: "Surname"},
            Preferredname:{ type: "string", title: "Preferredname"},

            Address1: { type: "string", title: "Address1" },
            Address2: { type: "string", title: "Address2"},
            Postcode: { type: "string", title: "Postcode"},
            PostalAddress: { type: "string", title: "PostalAddress"},
            //PostalCity: { type: "string", title: "PostalCity"},
            PostalPostcode: { type: "string", title: " PostalPostcode"},
            DOB: { type: "string", title: "Date Of Birth" },
            Age: { type: "number", title: "Age"},
            
            Ethnicity: { type: "string", title: "Ethnicity"},
           
            Phone: { type: "number", title: "Phone"},
            medicare_no: { type: "string", title: "medicare_no" },
            pensionNo	: { type: "string", title: "pensionNo	" },
            occupation	: { type: "string", title: "occupation	" },
            City: dropDown("city_tb", "city_id", "city_name", ""),
            Sex: dropDown("lookup_tb", "value", "display", "where field_name='Sex'"),
          branch_id: dropDown("branches_tb", "branch_id", "name", ""),
            ID: { type: "number" }
          }
        },
        ui_schema: {
         
          ID: {
            "ui:widget": "hidden"
          },
          
        }
       
      },
      delete: {
        destination: "patients",
        pk: "patient_id",
        isConfirmBeforeDelete: true,
        successMessage: "Successfully Deleted..!",
        errorMessage: "Something went to Wrong, Try again later",
        confirmationTitle: "Do you really want to delete?",
        confirmationMessage: "Are you sure to do this."
      },
      viewgrid: {
        isVisible:true,
        isPagination: true,
        isFiltering: true,
        isActions: true,

        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "patients",
          cols: [
            "patient_id",
            
            "Firstname",
            "DOB",
            "City",
            "Phone",
            "branch_id",
            "medicare_no"
          ],
          customCols: [
        //  "CONCAT(Firstname,' ',Middlename,' ', Surname) AS patientname",
         // "CONCAT(Address1,' ',Address2) AS Address"
            
          ],
         /* joins: [
            {
              joinType: "inner",
              joinTable: "branches_tb",
              joinCondition: "branches_tb.branch_id = patients.branch_id"
            },
         
            {
              joinType: "inner",
              joinTable: "lookup_tb",
              joinCondition: "lookup_tb.value = patients.Sex"
            }

          ],
          where: [
            {
              type: "and",
              key: "patients.isDeleted",
              operator: "=",
              value: "0"
            },
            {
              type: "and",
              key: "lookup_tb.field_name",
              operator: "=",
              value: "Sex"
            }
           
          ]*/
        },
        viewtabletitle: "",
        columns: [
          //{ name: "Title", title: "Title"}, 
         
        ],
        rows: [],
        sortinit: [{ columnName: "patient_id", direction: "desc" }],
        pagesize: 5,
        actions: [
          {
            buttonClass: "info",
            icon: "fa-pencil",
            text: "",
            function: "edit",
            passParam: "patient_id",
            isDefault: true
          },
          {
            buttonClass: "danger",
            icon: "fa-trash",
            text: "",
            function: "delete",
            passParam: "patient_id",
            isDefault: true
          },
          {
            buttonClass: "primary",
            icon: "fa-eye",
            text: "",
            function: "view",
            passParam: "start_date",
            isDefault: false
          }
        ]
      }
    }
    
  };
  }
  
  render() {
    var gridstyle = {
      padding: "10px"
    };
  
    return (
      <div>
       
            {/* Content Header (Page header) */}
  
            <div className="row"> 
              <div className="col-lg-3"></div>
            </div>
            <div className="col-lg-6" style={{ width: "100%" }}>
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          </div>
                <div className="col-lg-3"></div>
        </div>
              {/* Main content */}
              <section className="content">
                <div className="row">
                  <div className="col-lg-12">
                    <Grid
                      style={gridstyle}
                      handleButton={this.handleButton}
                      crud={this.state.crud}
                    />
                  </div>
                </div>
              </section>
              <div  style={{ float:'right' }}>
              <AccountDetails  patient_id='19'  />
               <button  type="button" className="btn btn-default">Cancel</button>
                </div>     
          </div>
       
    );
  }
}
export default NonpatientAccount
;

