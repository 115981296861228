import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Mainpage from './components/main';

import UserManagement from '../src/components/user';
//import AddUser from './components/vanilla/adduser';

import Viewcenters from './components/superadmin/managecenters/viewcenters';
import Addcenter from './components/superadmin/managecenters/addcenter';
import Managepatients from './components/superadmin/managecenters/managepatients';
import Centers from './components/superadmin/managecenters/centers';
import NonpatientAccount from './components/superadmin/managecenters/NonpatientAccount';
import PatientAccount from './components/superadmin/managecenters/patientAccount';
import ComplexAccount from './components/superadmin/managecenters/ComplexAccount';
import AccountDetails from './components/superadmin/managecenters/AccountDetails';

import 'react-notifications/lib/notifications.css';
import Sessions from './components/superadmin/managecenters/sessions';
//import addradio from './components/superadmin/managecenters/addradio';
import Login from './components/login/login';

import Summary from 'components/patientHistory/summary/summary';
//import myList from "components/myList";
import VisitsNotes1 from 'components/patientHistory/working-visitsNotes';
import AuthRequired from 'components/AuthRequired';
import Reminders from 'components/patientHistory/reminders/reminders';
import ListPage from './components/superadmin/managelists/listPage';
import RemindersPage from './components/superadmin/manageReminders/remindersPage';
import CarePlans from './components/superadmin/manageCarePlans/carePlans';
import MessagesPage from './components/superadmin/manageMessages/messages';
import AppointmentSetting from './components/superadmin/manageAppointment/appointmentSetting';
import AccountText from './components/superadmin/manageAccoutText/accountText';
import InvoiceText from './components/superadmin/manageInvoiceText/invoiceText';
import BankAccount from './components/superadmin/manageBankAccount/bankAccount';
import AppointmentReminders from './components/superadmin/manageAppointmentReminders/appointmnetReminder';
import AddGeneral from './components/superadmin/manageGeneral/addGeneral';
import Templates from './components/superadmin/manageTemplate/templateList'
// css
// css


// import './assets/bower_components/bootstrap/dist/css/bootstrap.min.css';
// import './assets/bower_components/font-awesome/css/font-awesome.min.css';
// import './assets/bower_components/Ionicons/css/ionicons.min.css';
// import './assets/bower_components/jvectormap/jquery-jvectormap.css';
// import './assets/dist/css/AdminLTE.css';
// import './assets/dist/css/skins/_all-skins.css';

const App = ({ location }) => (
 
  <Router>
    {/*</Router><Switch basename={'https://dev46.fiveminutes.in/supadoc/ui/'}>*/}
    
    <Switch basename={'/'}>
      <Route exact path="">
        <Route exact location={location} path="/" component={Login} />
        
        <Route
          path="/appointments"
          render={() => (
            <AuthRequired
              redirectTo="/appointments"
              orRender={<Mainpage location={location} />}
            />
          )}
        />

        <Route
          path="/appointments/:id"
          render={() => (
            <AuthRequired
              redirectTo="/appointments/:id"
              orRender={<Mainpage location={location} />}
            />
          )}
        />
        <Route
          path="/patientHistory/reminders/reminders"
          render={() => (
            <AuthRequired
              redirectTo="/patientHistory/reminders/reminders"
              orRender={<Reminders location={location} />}
            />
          )}
        />
        <Route
          path="/user"
          render={() => (
            <AuthRequired
              redirectTo="/user"
              orRender={<UserManagement location={location} />}
            />
          )}
        />

        <Route
          path="/superadmin/viewcenters"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/viewcenters"
              orRender={<Viewcenters location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/addcenter"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/addcenter"
              orRender={<Addcenter location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/centers"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/centers"
              orRender={<Centers location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/sessions"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/sessions"
              orRender={<Sessions location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/managepatients"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/managepatients"
              orRender={<Managepatients location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/PatientAccount"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/PatientAccount"
              orRender={<PatientAccount location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/NonpatientAccount"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/NonpatientAccount"
              orRender={<NonpatientAccount location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/ComplexAccount"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/ComplexAccount"
              orRender={<ComplexAccount location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/AccountDetails"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/AccountDetails"
              orRender={<AccountDetails location={location} />}
            />
          )}
        />
        <Route
          path="/patientHistory/summary"
          render={() => (
            <AuthRequired
              redirectTo="/patientHistory/summary"
              orRender={<Summary location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/lists"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/lists"
              orRender={<ListPage location={location} />}
            />
          )}
        /> 
        <Route
          path="/superadmin/reminders"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/reminders"
              orRender={<RemindersPage location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/careplan"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/careplan"
              orRender={<CarePlans location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/messages"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/messages"
              orRender={<MessagesPage location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/appointment"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/appointment"
              orRender={<AppointmentSetting location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/accounttext"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/accounttext"
              orRender={<AccountText location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/invoicenotes"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/invoicenotes"
              orRender={<InvoiceText location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/bankaccount"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/bankaccount"
              orRender={<BankAccount location={location} />}
            />
          )}
        />
         <Route
          path="/superadmin/appointmentreminder"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/appointmentreminder"
              orRender={<AppointmentReminders location={location} />}
            />
          )}
        />
        <Route
          path="/superadmin/general"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/general"
              orRender={<AddGeneral location={location} />}
            />
          )}
        />
         <Route
          path="/superadmin/templates"
          render={() => (
            <AuthRequired
              redirectTo="/superadmin/templates"
              orRender={<Templates location={location} />}
            />
          )}
        />
        {/*<Route location={location} path="/myList" component={myList } />*/}
        <Route
          path="oldVisit"
          render={() => (
            <AuthRequired
              redirectTo="oldVisit"
              orRender={<VisitsNotes1 location={location} />}
            />
          )}
        />
      </Route>
      
    </Switch>
  </Router>
);

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default App;
