import React, { Component } from 'react';

import './womenhistory.css';
import axios from 'axios';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import Popup from "reactjs-popup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cicon from "./../../../assets/img/cicon.png";
import moment from 'moment';
//import logo from './logo.svg';
//import './App.css';

import { connect } from "react-redux";
import {
  api_appointment_types_booking_sources,
  api_getDoctors
} from "./../../../DS/DS.Appointments";

import AddHistory from './addhistory';
import{api_delete_history} from '../../../DS/DS.WomenHistory'
import Showhelp from './../../showHelp';

class App extends Component {

    constructor(probs) {
        super(probs);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this); 
        this.state={
          items:[],          
          billing_providers: [],
          doctors:[],
          showModal: false,
          billing_provider: "",
          selectedInId:"",
          selectedInLength:"",
          performed_date:new Date(),

          wh_id :"",
          other_info : "",

          pstable: {
            jsonGridVersion: "v2.0",
            grid: true,
            viewgrid: {
              isVisible:true,
              isPagination: true,
              isFiltering: true,
              isSelection:true,
              deleteFunction:this.delPap,
              isDateGroup:false,
              isYearSeparator:false,
              dateCol:'',
              cellClicked:this.onRowClicked,
              baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
              query: {
                tb: "womenhistory_tb",
                cols: [
                  'id',
                  "DATE_FORMAT(performed_date,'%d-%m-%Y') as performed_date",
                  "LEFT(result,20) AS result",
                  "CASE WHEN endoservical = 1 THEN 'Yes' ELSE 'No' END AS endoservical",
                  "CASE WHEN hpv_changes = 1 THEN 'Yes' ELSE 'No' END AS hpv_changes",
                  "performed_by",
                  "doctor_tb.Firstname as fname",
                  "other_info"
                ],
                joins: [
                  {
                  joinType: "left",
                  joinTable: "doctor_tb",
                  joinCondition:                    
                    "womenhistory_tb.performed_by = doctor_tb.doctor_id"
                }
                ],
               where: [
                  {
                    type: "and",
                    key: "patient_id",
                    operator: "=",
                    value: this.props.redux_patientId
               },{
                type: "and",
                key: "test",
                operator: "like",
                value: "Pap%"
                // type: "and",
                // key: "isDeleted",
                // operator: "=",
                // value: 0
           },{
            type: "and",
            key: "womenhistory_tb.isDeleted",
            operator: "=",
            value: 0
             }],
                primary_key:"id",
              },
              viewtabletitle: "",
              columns: [
                { name: "performed_date", title: "Screen Date" },
                { name: "result", title: "Smear Result" },
                { name: "endoservical", title: "Endocervical cells" },
                { name: "hpv_changes", title: "HPV changes" },
                { name: "fname", title: "Performed By" },
                { name: "other_info", title: "Comment" },
             
              ],
              rows: [],
              sortinit: [{ columnName: "performed_date", direction: "desc" }],
              pagesize: 10
            }
          },

        
          cstable: {
            jsonGridVersion: "v2.0",
            grid: true,
            viewgrid: {
              isVisible:true,
              isPagination: true,
              isFiltering: true,
              isSelection:true,
              deleteFunction:this.delcerv,
              isDateGroup:false,
              isYearSeparator:false,
              dateCol:'',
              cellClicked:this.onRowClicked,
              baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
              query: {
                tb: "womenhistory_tb",
                cols: [
                  "id",
                  "DATE_FORMAT(performed_date,'%d-%m-%Y') as performed_date",
                  "CASE WHEN hpv16 = 1 THEN 'Detected' ELSE 'No Detected' END AS hpv16",
                  "CASE WHEN hpv18 = 1 THEN 'Detected' ELSE 'No Detected' END AS hpv18",
                  "CASE WHEN hpv = 1 THEN 'Detected' ELSE 'No Detected' END AS hpv",
                  "LEFT(lbc_result,16) AS lbc_result",
                  "CASE WHEN endoservical = 1 THEN 'Yes' ELSE 'No' END AS endoservical",
                  "performed_by",                  
                  "doctor_tb.Firstname as fname",
                  "other_info"
                ],
                joins: [
                  {
                  joinType: "left",
                  joinTable: "doctor_tb",
                  joinCondition:                    
                    "womenhistory_tb.performed_by = doctor_tb.doctor_id"
                }
                ],
               where: [
                  {
                    type: "and",
                    key: "patient_id",
                    operator: "=",
                    value: this.props.redux_patientId
               },{
                type: "and",
                key: "test",
                operator: "like",
                value: "Cst%"
                // type: "and",
                // key: "isDeleted",
                // operator: "=",
                // value: 0
           },
           {
            type: "and",
            key: "womenhistory_tb.isDeleted",
            operator: "=",
            value: 0
       }],
                primary_key:"id",
              },
              viewtabletitle: "",
              columns: [
                { name: "performed_date", title: "Screen Date" },
                { name: "hpv16", title: "HPV 16" },
                { name: "hpv18", title: "HPV 18" },
                { name: "hpv", title: "HPV other" },
                { name: "lbc_result", title: "LBC Result" },
                { name: "endoservical", title: "Endocervical cells" },                
                { name: "fname", title: "Performed By" },
                { name: "other_info", title: "Comment" },
             
              ],
              rows: [],
              sortinit: [{ columnName: "performed_date", direction: "desc" }],
              pagesize: 10
            }
          },
          showmodal:false,
          key:0,
          viewEdit:false,
          selectedcervId:'',
          selectedcevLength:"",
          viewEditCerv:false
        }
      }      
      
      open() {
        this.setState({showModal: true});
      }

      close() {
        this.setState({showModal:false,key:Math.random(),viewEdit:false,viewEditCerv:false});
      }

      handleDate2 = date => {
        this.setState({ reminder_date: date });
      };
      handleDate3 = date => {
        this.setState({ performed_date: date });               
      };

      onRowClicked = clickedId => {        
        console.log("wh", clickedId);        
      };

      delPap=(GselectedInId,inAction,GselectedInlength)=>{
         if(inAction=='selection')
         {
           this.state.selectedInId=GselectedInId;
           this.state.selectedInLength=GselectedInlength;
     
         }
         else if(inAction=="delete")
         {
                 if (this.state.selectedInLength> 0) {
                   confirmAlert({
                     title: 'Delete record',
                     message: 'Do you want to delete the record?',
                     buttons: [
                     {
                         label: 'Yes',
                         onClick: () => {
                          this.props.deleteHistory({id:this.state.selectedInId}).then(res=>{
                            this.setState({ key: Math.random() });  
                            NotificationManager.success('Record deleted successfully'); 
                           })
                          
                     },
                 },
                 {
                   label: 'No',
                  onClick: () => {}
                 },
               ],
             });
           } else {
             NotificationManager.error('Please select a record to delete');
           }
           
         } 
         
       }

       delcerv=(selecId,inAction,selectedlength)=>{
        if(inAction=='selection')
        {
          this.state.selectedcervId=selecId;
          this.state.selectedcevLength=selectedlength;
          console.log("&&&&&&&&&&&&&&&&&&&",this.state.selectedcervId)
    
        }
        else if(inAction=="delete")
        {
                if (this.state.selectedcevLength> 0) {
                  confirmAlert({
                    title: 'Delete record',
                    message: 'Do you want to delete the record?',
                    buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                         /* const data = new FormData();
                          data.set('id', this.state.selectedcervId);
                           fetch(`${process.env.REACT_APP_BASE_URL}api=womenHistory&action=delete_history`, {
                             method: 'POST',   
                             body: data,
                           });*/
                           this.props.deleteHistory({id:this.state.selectedcervId}).then(res=>{
                            this.setState({ key: Math.random() });  
                            NotificationManager.success('Record deleted successfully'); 
                           })
                         
                    },
                },
                {
                  label: 'No',
                 onClick: () => {}
                },
              ],
            });
          } else {
            NotificationManager.error('Please select a record to delete');
          }
          
        } 
        
      }


      componentDidMount(){                   
         }

      componentWillReceiveProps(nextProps) {
        this.setState({ logged_info: nextProps.logged_info });
        this.setState({ drId: nextProps.logged_info.content.details[0].doctor_id });
        if (nextProps.redux_patientId !== this.props.redux_patientId) {
          this.setState({ patientId: nextProps.redux_patientId });
        }
      }

      
      editData=(selectedId,action)=>{
        this.setState({viewEdit:false})
        if(action=='edit')
        {
        
          if(this.state.selectedInLength == 1)
          {
            this.setState({viewEdit:true})
          }
          else
          {
            alert("Please select single record to edit")
          }
        }
      }
      editDatacer=(selectedId,action)=>{
        this.setState({viewEditCerv:false})
        if(action=='edit')
        {
        
          if(this.state.selectedcevLength == 1)
          {
            this.setState({viewEditCerv:true})
          }
          else
          {
            alert("Please select single record to edit")
          }
        }
      }
  render() {
    
    var {items} = this.state;
    var gridstyle = {
      padding: "10px",
      height: "500px"
    };
    return (
        
        <div id="notesdiv12" className="col-md-12" style={{paddingLeft: '0px', paddingRight: '0px', display: 'block'}}>
          <div className="panel panel-default">
            <div className="panel-heading panspace">
              <div className="col-lg-12 col-xl-12">
                <h4 className="panel-title">
                  <a href="#" className="collapsed" aria-expanded="false">Women's History</a><Showhelp gotohelp="women_history_help"/>
                </h4>
                <div style={{ width: "auto", height: 30, position: "absolute", right: 15, top: 0}}>
                  <span className="makescreen"><a href="#" title="Make This Default Screen" className="collapsed" aria-expanded="false"><i className="fa fa-star panelicon" aria-hidden="true" /></a></span>
                  <a href="#" title="Email" className="collapsed" aria-expanded="false"><i className="fas fa-envelope panelicon" aria-hidden="true" /></a>
                  <a href="#" title="Print" className="collapsed" aria-expanded="false"><i className="fas fa-print panelicon" aria-hidden="true" /></a>
                  {/*<a href="#" title="Export XLs"><i class="fas fa-file-excel-o" aria-hidden="true" style="font-size:12px; margin-right:5px;"></i></a>*/}
                  <a title="Export PDF" onclick="recentVisitmini();" className="collapsed" aria-expanded="false"><i className="fa fa-file-pdf-o panelicon" aria-hidden="true" /></a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 classic-tabs" style={{float: 'left', width: '100%', marginTop: '5px'}}>
            <div className="row">
              <div className="col-xs-12">
                <nav>
                  <div className="nav nav-tabs nav-fill" id="nav-tab1" role="tablist">
                    <a className="nav-item nav-link collapsed active" id="nav-cervical-tab" data-toggle="tab" href="#nav-cervical" role="tab" aria-controls="nav-cervical" aria-selected="true" aria-expanded="true">Cervical screening</a>
                {/*    <a className="nav-item nav-link collapsed" id="nav-obstetric-tab" data-toggle="tab" href="#nav-obstetric" role="tab" aria-controls="nav-obstetric" aria-selected="false" aria-expanded="false">Obstetric history</a>
                  */}</div>
                </nav>
                <div className="tab-content px-3 px-sm-0 mt-3" id="nav-tabContent">
                  <div className="tab-pane fade active in show" id="nav-cervical" role="tabpanel" aria-labelledby="nav-cervical-tab" aria-expanded="true">
                    <div style={{float: 'left', width: '100%'}}>                      
                      <div style={{float: 'left', width: '50%', marginBottom: '10px', marginTop: '0px'}}>
                        <button type="button" className="btn btn-primary btn-xs collapsed" data-toggle="modal" data-target="#addcervical1" aria-expanded="false" onClick={e => this.setState({showModal:true})}>Add</button>
                        <button type="button" className="btn btn-primary btn-xs collapsed" data-toggle="modal" data-target="#addcervical1"  aria-expanded="false" onClick={e => this.editData('','edit')}>Edit</button>
                        <button type="button" className="btn btn-primary btn-xs collapsed" data-toggle="modal"  aria-expanded="false" onClick={e => this.delPap("", "delete", "")}>Delete</button>
                       
                        <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal">Print</button>
                      </div>
                      <div>
                      </div>
                      <div className="col-sm-12 pl-0 pr-0">
                        <h5 class="sheadtitle">Pap Smears:</h5>
                      </div>
                      <div className="col-sm-12 pl-0 pr-0">
                          <CommonGrid
                            style={gridstyle}
                            handleButton={this.handleButton}
                            crud={this.state.pstable}
                            key={this.state.key}

                          />
                      </div>
                    </div>
                    <div style={{float: 'left', width: '100%', marginTop: '10px'}}>
                    <div style={{float: 'left', width: '50%', marginBottom: '10px', marginTop: '0px'}}>
                        <button type="button" className="btn btn-primary btn-xs collapsed" data-toggle="modal" data-target="#addcervical1"  aria-expanded="false" onClick={e => this.editDatacer('','edit')}>Edit</button>
                        <button type="button" className="btn btn-primary btn-xs collapsed" data-toggle="modal"  aria-expanded="false" onClick={e => this.delcerv("", "delete", "")}>Delete</button>
                       
                      </div>
                      <div className="col-sm-12 pl-0 pr-0">
                        <h5 class="sheadtitle">Cervical Screening Tests:</h5>
                      </div>
                      <div className="col-sm-12 pl-0 pr-0">
                          <CommonGrid
                            style={gridstyle}
                            handleButton={this.handleButton}
                            crud={this.state.cstable}
                            key={this.state.key}
                          />
                      </div>
                    </div>
                    
                  {this.state.showModal ?
                  <AddHistory showmodal={this.state.showModal} changeKey={this.close} sid=''/>:''
                  }
                   
                   {this.state.viewEdit ?
                  <AddHistory showmodal={this.state.viewEdit} changeKey={this.close} sid={this.state.selectedInId}/>:''
                  }

                  {this.state.viewEditCerv ?
                  <AddHistory showmodal={this.state.viewEditCerv} changeKey={this.close} sid={this.state.selectedcervId}/>:''
                  }
                    <div className="modal fade" id="addcervical"  show={this.state.showModal}  tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" style={{zIndex: 2147483647, fontWeight: 'normal', display: 'none'}} aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content" style={{width: '700px', marginLeft: '-100px', marginTop: '50px'}}>
                          <div className="modal-header" style={{width: '698px', backgroundColor: '#f4f4f4', padding: '5px 15px'}}>
                            <h5 style={{backgroundColor: '#f4f4f4', float: 'left', width: '100%', padding: '5px 10px', fontSize: '16px', marginBottom: '0px'}}>Cervical screening result</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <form onSubmit={this.handleSubmit}>
                          <div className="modal-body" style={{padding:'0',background:'#fff'}}>




                          
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">Date performed:</label>
                              </div>
                              <div className="col-sm-3">
                              


                              <input id="username" name="performed_date" type="date" 
                              value={this.state.performed_date} 
                              onChange={e =>
                                this.handleFieldValueChanges(
                                  "performed_date12",
                                  e.target.value
                                )
                              }
                                />
                              </div>
                              <div className="col-sm-6">
                               &nbsp;
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">Performed by:</label>
                              </div>
                              <div className="col-sm-3">
                                {/* <select name="performed_by">
                                  <option value="">---select one---</option>
                                  <option value="Not Perferred here">Not Perferred here</option>
                                </select> */}
                                <select
                      name="performed_by"
                      style={{ width: "100%", float: "left" }}
                      value={this.state.performed_by}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "performed_by",
                          e.target.value
                        )
                      }
                    >
                      <option>Select One</option>
                      {this.state.billing_providers.length != 0 &&
                        this.state.billing_providers.map(b => (
                          <option value={b.name}>{b.name} </option>
                        ))}
                    </select>
                              </div>
                              <div className="col-sm-5">
                                <input type="checkbox" name="inactive" />&nbsp;Include inactive providers
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">&nbsp;</label>
                              </div>
                              <div className="col-sm-4">
                                <input type="radio" name="test" onChange={this.handleChange} value="Cervical screening test" />&nbsp;Cervical screening test
                              </div>
                              <div className="col-sm-4">
                                <input type="radio"  name="test" onChange={this.handleChange} value="Pap smear"/>&nbsp;Pap smear
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">&nbsp;</label>
                              </div>
                              <div className="col-sm-4">
                                <input type="checkbox" name="unsatisfactory_spec" />&nbsp;Unsatisfactory specimen
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                              <label className="col-form-label text-left">&nbsp;</label>
                              </div>
                              <div className="col-sm-4">
                              <input type="checkbox" name="endoservical" value='1' />&nbsp;Endoservical Cells
                              </div>
                              <div className="col-sm-4">
                              <input type="checkbox" name="hpv_changes" value='1'/>&nbsp;HPV Changes
                              </div>
                            </div>
                            <div id="cs">                            
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">HPV PCR:</label>
                              </div>
                              <div className="col-sm-3">
                                HPV 16
                              </div>
                              <div className="col-sm-3">
                                <input type="radio" name="hpv16" id="hpv16" value="1" />&nbsp;Detected
                              </div>
                              <div className="col-sm-3">
                                <input type="radio" name="hpv16" id="hpv16" value="0" />&nbsp;Not Detected
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left"></label>
                              </div>
                              <div className="col-sm-3">
                                HPV 18
                              </div>
                              <div className="col-sm-3">
                                <input type="radio" name="hpv18" value="1" />&nbsp;Detected
                              </div>
                              <div className="col-sm-3">
                                <input type="radio" name="hpv18" value="0" />&nbsp;Not Detected
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left"></label>
                              </div>
                              <div className="col-sm-3">
                                HPV not 16/18
                              </div>
                              <div className="col-sm-3">
                                <input type="radio" name="hpv" value="1" />&nbsp;Detected
                              </div>
                              <div className="col-sm-3">
                                <input type="radio" name="hpv" value="0" />&nbsp;Not Detected
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">LBC Result</label>
                              </div>
                              <div className="col-sm-6">
                                <select name="lbc_result"
                                value={this.state.lbc_result} 
                                onChange={e =>
                                  this.handleFieldValueChanges(
                                    "lbc_result",
                                    e.target.value
                                  )
                                }
                                >
                                  <option value="">---select one---</option>
                                  <option value="Negative">Negative</option>
                                </select>
                              </div>
                            </div>
                            </div>
                            <div id="ps" style={{display:'none'}} >
                            
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">Result</label>
                              </div>
                              <div className="col-sm-6">
                                <select name="result"
                                  value={this.state.result} 
                                  onChange={e =>
                                    this.handleFieldValueChanges(
                                      "result",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">---select one---</option>
                                  <option value="Negative">Negative</option>
                                </select>
                              </div>
                            </div>
                            </div>
                            <div className="form-group row" style={{margin:'0'}}>
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">&nbsp;Other information</label>
                              </div>
                              <div className="col-sm-9">
                                <textarea name="other_info" style={{width: '100%', height: '50px', border: '1px solid #ddd'}} defaultValue={this.state.other_info} />
                              </div>
                            </div>  
                         

                          </div>
                          <div className="modal-footer" style={{background:'#fff',margin:'0'}}>
                            {/* <button type="button" className="btn btn-primary" data-dismiss="modal" style={{float:'left'}} >Add reminder</button> */}
                            <input type="submit" className="btn btn-primary"  value="Save"  />                            
                            <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                          </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="nav-obstetric" role="tabpanel" aria-labelledby="nav-obstetric-tab" aria-expanded="false">
                    <div style={{float: 'left', width: '100%'}}>
                      <div className="col-md-8">
                        <p style={{float: 'left', width: '100%'}}>Pregnancies:</p>
                        <div style={{float: 'left', width: '50%', marginBottom: '10px', marginTop: '0px'}}>
                          <button type="button" className="btn btn-primary collapsed" data-toggle="modal" data-target="#addobstetric" aria-expanded="false">Add</button>
                          <button type="button" className="btn btn-default" data-dismiss="modal">Edit</button>
                          <button type="button" className="btn btn-default" data-dismiss="modal">Delete</button>
                          <button type="button" className="btn btn-default" data-dismiss="modal">Print</button>
                          <button type="button" className="btn btn-primary collapsed" data-toggle="modal" data-target="#pastpregnancy" aria-expanded="false" style={{float: 'right'}}>Past pregnancy</button>
                        </div>
                        <table style={{width: '100%'}}>
                          <tbody><tr>
                              <td>
                                <div style={{width: '100%', overflowY: 'scroll', height: '280px'}}>
                                  <table className="calendar table table-bordered" style={{float: 'left', width: '100%'}}>
                                    <thead>
                                      <tr style={{backgroundColor: '#f4f4f4'}}>
                                        <th>No.</th>
                                        <th>LMP</th>
                                        <th>Ended</th>
                                        <th>Weeks</th>
                                        <th>Outcome</th>
                                        <th>Delivery</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </tbody></table>
                      </div>
                      <div className="col-md-4">
                        Pregnancies details:
                        <textarea style={{width: '100%', height: '280px', border: '1px solid #f1f1f1'}} defaultValue={""} />
                      </div>
                    </div>
                    <div style={{float: 'left', width: '100%', marginTop: '10px'}}>
                      Antenatal visits:
                      <table style={{width: '100%'}}>
                        <tbody><tr>
                            <td>
                              <div style={{width: '100%', height: '280px', overflowY: 'auto'}}>
                                <table className="calendar table table-bordered" style={{float: 'left', width: '100%'}}>
                                  <thead>
                                    <tr style={{backgroundColor: '#f4f4f4'}}>
                                      <th>Date</th>
                                      <th>Weight</th>
                                      <th>BP</th>
                                      <th>Urine</th>
                                      <th>Oedema</th>
                                      <th>Calc.size</th>
                                      <th>Cin.size</th>
                                      <th>Fundus</th>
                                      <th>FH</th>
                                      <th>Notes</th>
                                      <th>Movements</th>
                                      <th>Presentation</th>
                                      <th>Position</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </tbody></table>
                    </div>
                    <div className="modal fade" id="addobstetric" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{zIndex: 999999999999, fontWeight: 'normal'}}>
                      <div className="modal-dialog" role="document">
                        <div className="modal-content" style={{width: '700px', marginLeft: '-100px', marginTop: '150px'}}>
                          <div className="modal-header" style={{width: '698px', backgroundColor: '#f4f4f4', padding: '5px 15px'}}>
                            <h5 style={{backgroundColor: '#f4f4f4', float: 'left', width: '100%', padding: '5px 10px', fontSize: '16px', marginBottom: '0px'}}>Gestational age</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="form-group row">
                              <div className="col-sm-2">
                                <label className="col-form-label text-left">Date of LMP:</label>
                              </div>
                              <div className="col-sm-2">
                                <input type="text" defaultValue="11-09-2019" name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                              </div>
                              <div className="col-sm-2">
                                <label className="col-form-label text-left">Cycle length:</label>
                              </div>
                              <div className="col-sm-1">
                                <input type="text" defaultValue name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                              </div>
                              <div className="col-sm-2">
                                <label className="col-form-label text-left">days:</label>
                              </div>
                              <div className="col-sm-1">
                                <input type="text" defaultValue name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-2">
                                <label className="col-form-label text-left">Date of scan:</label>
                              </div>
                              <div className="col-sm-2">
                                <input type="text" defaultValue="11-09-2019" name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                              </div>
                              <div className="col-sm-2">
                                <label className="col-form-label text-left">Weeks:</label>
                              </div>
                              <div className="col-sm-1">
                                <input type="text" defaultValue name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                              </div>
                              <div className="col-sm-2">
                                <label className="col-form-label text-left">days:</label>
                              </div>
                              <div className="col-sm-1">
                                <input type="text" defaultValue name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-12">
                                <textarea style={{width: '100%', height: '50px', border: '1px solid #ddd'}} defaultValue={""} />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-12">
                                <input type="checkbox" className="form-control form-control-sm" style={{float: 'left', width: 'auto'}} />&nbsp;use scan date
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-12">
                                <table style={{width: '100%'}}>
                                  <tbody><tr>
                                      <th>Week</th>
                                      <th>Start date</th>
                                      <th>Week</th>
                                      <th>Start date</th>
                                      <th>Week</th>
                                      <th>Start date</th>
                                      <th>Week</th>
                                      <th>Start date</th>
                                    </tr>
                                    <tr>
                                      <td>1</td>
                                      <td>&nbsp;</td>
                                      <td>11</td>
                                      <td>&nbsp;</td>
                                      <td>21</td>
                                      <td>&nbsp;</td>
                                      <td>31</td>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td>&nbsp;</td>
                                      <td>12</td>
                                      <td>&nbsp;</td>
                                      <td>22</td>
                                      <td>&nbsp;</td>
                                      <td>32</td>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td>&nbsp;</td>
                                      <td>13</td>
                                      <td>&nbsp;</td>
                                      <td>23</td>
                                      <td>&nbsp;</td>
                                      <td>33</td>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>4</td>
                                      <td>&nbsp;</td>
                                      <td>14</td>
                                      <td>&nbsp;</td>
                                      <td>24</td>
                                      <td>&nbsp;</td>
                                      <td>34</td>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>5</td>
                                      <td>&nbsp;</td>
                                      <td>15</td>
                                      <td>&nbsp;</td>
                                      <td>25</td>
                                      <td>&nbsp;</td>
                                      <td>35</td>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>6</td>
                                      <td>&nbsp;</td>
                                      <td>16</td>
                                      <td>&nbsp;</td>
                                      <td>26</td>
                                      <td>&nbsp;</td>
                                      <td>36</td>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>7</td>
                                      <td>&nbsp;</td>
                                      <td>17</td>
                                      <td>&nbsp;</td>
                                      <td>27</td>
                                      <td>&nbsp;</td>
                                      <td>37</td>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>8</td>
                                      <td>&nbsp;</td>
                                      <td>18</td>
                                      <td>&nbsp;</td>
                                      <td>28</td>
                                      <td>&nbsp;</td>
                                      <td>38</td>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>9</td>
                                      <td>&nbsp;</td>
                                      <td>19</td>
                                      <td>&nbsp;</td>
                                      <td>29</td>
                                      <td>&nbsp;</td>
                                      <td>39</td>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>10</td>
                                      <td>&nbsp;</td>
                                      <td>20</td>
                                      <td>&nbsp;</td>
                                      <td>30</td>
                                      <td>&nbsp;</td>
                                      <td>40</td>
                                      <td>&nbsp;</td>
                                    </tr>
                                  </tbody></table>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Save</button>
                            <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal fade" id="pastpregnancy" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{zIndex: 999999999999, fontWeight: 'normal'}}>
                      <div className="modal-dialog" role="document">
                        <div className="modal-content" style={{width: '700px', marginLeft: '-100px', marginTop: '150px'}}>
                          <div className="modal-header" style={{width: '698px', backgroundColor: '#f4f4f4', padding: '5px 15px'}}>
                            <h5 style={{backgroundColor: '#f4f4f4', float: 'left', width: '100%', padding: '5px 10px', fontSize: '16px', marginBottom: '0px'}}>Pregnancy outcome</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">Pregnancy No:</label>
                              </div>
                              <div className="col-sm-3">
                                <input type="text" defaultValue="11-09-2019" name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                              </div>
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">Managed by:</label>
                              </div>
                              <div className="col-sm-3">
                                <select>
                                  <option>---select one---</option>
                                  <option>Not Perferred here</option>
                                </select>
                              </div>
                            </div>
                            <div className="form-group row" style={{marginBottom: '5px'}}>
                              <div className="col-sm-3">
                                <label className="col-form-label text-left" />
                              </div>
                              <div className="col-sm-5">
                                <input type="checkbox" className="form-control form-control-sm" style={{float: 'left', width: 'auto'}} />&nbsp;IVF Pregnancy
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">&nbsp;Actual LMP</label>
                              </div>
                              <div className="col-sm-3">
                                <input type="text" defaultValue="11-09-2019" name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                              </div>
                              <div className="col-sm-3">
                                <input type="radio" className="form-control form-control-sm" style={{float: 'left', width: 'auto'}} />&nbsp;EDC by LMP
                              </div>
                              <div className="col-sm-3">
                                <input type="text" defaultValue="11-09-2019" name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">&nbsp;Scan date</label>
                              </div>
                              <div className="col-sm-3">
                                <input type="text" defaultValue="11-09-2019" name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                              </div>
                              <div className="col-sm-5">
                                <input type="checkbox" className="form-control form-control-sm" style={{float: 'left', width: 'auto'}} />&nbsp;Use scan days 
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">&nbsp;Finish date</label>
                              </div>
                              <div className="col-sm-3">
                                <input type="text" defaultValue="11-09-2019" name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                              </div>
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">&nbsp;Gestation(wks)</label>
                              </div>
                              <div className="col-sm-1">
                                <input type="text" defaultValue="11-09-2019" name="search_condition" className="form-control form-control-sm rq-form-elemen" />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">Outcome:</label>
                              </div>
                              <div className="col-sm-6">
                                <select style={{width: '100%'}}>
                                  <option>---select one---</option>
                                  <option>Not Perferred here</option>
                                </select>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-12">
                                <label className="col-form-label text-left">Complication</label>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">&nbsp;</label>
                              </div>
                              <div className="col-sm-4">
                                <input type="checkbox" className="form-control form-control-sm" style={{float: 'left', width: 'auto'}} />&nbsp;Gestational diabetes
                              </div>
                              <div className="col-sm-4">
                                <input type="checkbox" className="form-control form-control-sm" style={{float: 'left', width: 'auto'}} />&nbsp;Hypertension
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <label className="col-form-label text-left">&nbsp;</label>
                              </div>
                              <div className="col-sm-4">
                                <input type="checkbox" className="form-control form-control-sm" style={{float: 'left', width: 'auto'}} />&nbsp;Pre-Eclampsia
                              </div>
                              <div className="col-sm-4">
                                <input type="checkbox" className="form-control form-control-sm" style={{float: 'left', width: 'auto'}} />&nbsp;Renal disease
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">&lt; Back</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Save</button>
                            <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </div>					</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

const mapStateToProps = state => {
  
  let redux_patientId =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].patient_id
      : "";
  let patient_age =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].age
      : "";
      let logged_info =
        state.getDrAppointments.logged_info != undefined
          ? state.getDrAppointments.logged_info
          : "";   

  return {
    redux_patientId,
    patient_age, logged_info
  };
};

const mapDispatchToProps = dispatch => {
  return {    
    billing_providers_master: data =>
    dispatch(api_appointment_types_booking_sources(data)),    
    doctors: data => dispatch(api_getDoctors(data)),
    deleteHistory:data=>dispatch(api_delete_history(data))
  };
};



export default connect(mapStateToProps,mapDispatchToProps)(App);