import React, { Component } from 'react';
import Header from './header';
import ReactDOM from "react-dom";
import Navigation from './navigation';
import Calendarall from './appointment/Calendar-all';
import Calendarind from './appointment/Calendar-individual';
import PatientDetails from './PatientDetails';
import {connect} from 'react-redux';
import { api_getAppointments, api_getDrAppointments } from '../DS/DS.Appointments';
import queryString from 'query-string';
import {wflow} from './../DS/general';

import movetotop from "./../assets/img/movetop.png";


import LoadingBar from 'react-redux-loading-bar'


class Mainpage extends Component {
    //**// constructor - starts
    constructor() {
        super();
        this.onChildClicked = this.onChildClicked.bind(this);
        this.loadDateCalendar = this.loadDateCalendar.bind(this);
        this.loadDrCalendar = this.loadDrCalendar.bind(this);
        this.loadDrWeekCalendar = this.loadDrWeekCalendar.bind(this);
    }
    //**// constructor - ends

    //**// state - starts
    state = { 
        path: '',
        cutAppointmentId: "",
        cutAppointmentType: "",
        cutAppointmentFromTo: "",
        
        CalendarType : "date",
        childWasClicked : "Not Clicked",
        DrId : 1,
        DrName : "Dr",
        minutes_per_appointments: '5',
        startDate : true ? new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate() : '2018-08-14',
        drCalendarProps: {
            doctor_guid : 1,
            start_datetime : new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate()+" 06:00:00",
            end_datetime : new Date(new Date().setDate(new Date().getDate() + 6))
        }
     }
     
    //**// state - ends
    componentWillMount(){
       console.log(this.props.location);
       let path = this.props.location.hash;
       this.setState({
           path: path
          });
        //    console.log(this.state.path);
    }
    


    //**// Load caldendar functions - starts
  
    loadDrCalendar = (dr) => {
        //alert("doctor cal"+dr.minutes_per_appointment);
         this.setState({CalendarType : "individual"});
         this.setState({DrId: dr.doctor_id});
         this.setState({DrName: dr.Firstname});
         this.setState({minutes_per_appointments: dr.minutes_per_appointment<5?5:dr.minutes_per_appointment});
         
    }

    format = function date2str(x, y) {
            var z = {
                M: x.getMonth() + 1,
                d: x.getDate(),
                h: x.getHours(),
                m: x.getMinutes(),
                s: x.getSeconds()
            };
            y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
                return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
            });
        
            return y.replace(/(y+)/g, function(v) {
                return x.getFullYear().toString().slice(-v.length)
            });
    }
     
    init_loadenddate = () => {
        let start = new Date();
        let enddate = new Date(start.setDate(start.getDate() + 7));
        let formatted_end = this.format(new Date(enddate), 'yyyy-MM-dd');
        return formatted_end + ' 20:00:00'
    }
    
    loadDrWeekCalendar = (dr, startdate, enddate, type) => {
        let start = new Date(startdate) ;
        let end = new Date(enddate) ;
        if(type == "prev"){
            startdate = new Date(start.setDate(start.getDate() - 7));
            enddate = new Date(end.setDate(end.getDate() - 7));
        }
        if(type == "next"){
            startdate = new Date(start.setDate(start.getDate() + 7));
            enddate = new Date(end.setDate(end.getDate() + 7));
        }
        let formatted_start = this.format(new Date(startdate), 'yyyy-MM-dd');
        let formatted_end = this.format(new Date(enddate), 'yyyy-MM-dd');
        let doctorSearchParam = {
            doctor_guid : dr,
            start_datetime : formatted_start+" 06:00:00",
            end_datetime : formatted_end+" 18:16:00"
        } 
        
        this.setState({drCalendarProps : doctorSearchParam });
        this.setState({CalendarType : "individual"});
        this.setState({DrId: dr});
        this.props.loadDrData(doctorSearchParam);
    }
        
    loadDateCalendar = (date) => {
        
         this.setState({ CalendarType : "date", startDate : date} );
         let dateSearchParam =  {
            start_datetime: date,
          };
          this.props.loadDateData(dateSearchParam);
    }
    //**// Load caldendar functions - ends
    
    //**// Sample function - starts
    onChildClicked = (a) => {
        this.setState({childWasClicked : "clicked" });
    }
    //**// Sample function - ends

    //**// Change Calendar Type - starts
    changeCaldendarType(typeinfo, doctor_id, doctor_name, fromdate, enddate){
        let drid = doctor_id;
        wflow("Setting Calendar Type",this.state.CalendarType);
        if(typeinfo=="individual"){
            return (<div><Calendarind path={this.state.path} drCalendarProps = {this.state.drCalendarProps} ctype={this.state.CalendarType} loadDrWeekCalendar={this.loadDrWeekCalendar} loadDateCalendar={this.loadDateCalendar} loadDrCalendar = {this.loadDrCalendar}  onClicked={this.onChildClicked} doctor_id={drid} doctorName = {doctor_name} doctor_name = {doctor_name} minutes_per_appointments = {this.state.minutes_per_appointments} startDate = {fromdate}/></div>);
        } else {
            return (<Calendarall path={this.state.path} ctype={this.state.CalendarType} loadDateCalendar={this.loadDateCalendar} loadDrCalendar = {this.loadDrCalendar} startDate = {fromdate} />);
        }
     }
    //**// Change Calendar Type - ends
    movetotop = (e) => {
        const top = ReactDOM.findDOMNode(this.refs.top_position);
        
             
                 top.scrollIntoView({
                   behavior: 'smooth'
                 });
    }
    //**// Render starts
    render() { 
        return ( 
        <div ref="top_position">
            <LoadingBar style={{ backgroundColor: 'blue', height: '5px' }} />
            <Header/> 
            {this.changeCaldendarType(this.state.CalendarType, this.state.DrId, this.state.DrName, this.state.startDate, "2019-08-20")}
            <img style={{zIndex: '9999999', right: '10px', bottom: '10px', position: 'fixed', width: '30px', cursor: 'pointer'}} onClick={e=>this.movetotop(e)} src={movetotop} />
        </div> );
    }
    //**// Render ends
}

//**// Redux Props 
const mapDespatchToProps = (dispatch) => {
    return {
      loadDateData : (searchParam) => dispatch(api_getAppointments(searchParam)),
      loadDrData : (doctorSearchParam) => dispatch(api_getDrAppointments(doctorSearchParam)),
    }
  }
  
 //**//  Export
export default connect(null, mapDespatchToProps) (Mainpage);
