import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import {
    NotificationContainer,
    NotificationManager,
  } from 'react-notifications';
  import { confirmAlert } from 'react-confirm-alert';
  import Footer from "../../footer";
  import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
import AddProviderAccount  from './addProviderAccount';
import {api_delete_provider,api_save_setting} from '../../../DS/DS.BankAccount';
import AddPracticeAccount from './addPracticeAccount';
import {api_get_settings} from '../../../DS/DS.Reminders';
class BankAccount extends Component {
    constructor(props)
    {
        super(props);
        this.state={
            key:0,
            addPractice:false,
            addProvider:false,
            crudProvider:{
               jsonGridVersion: "v2.0",
               grid: true,
               viewgrid: {
                 isVisible:true,
                 isPagination: true,
                 isFiltering: true,
                 isSelection:true,
                 deleteFunction:this.deleteProviderFunc,
                 isDateGroup:false,
                 isYearSeparator:false,
                 dateCol:'',
                 groupDateSelection:false,
                 cellClicked:'',
                 baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                 query: {
                   tb: "bankaccount_tb",
                   cols: [
                     "account_id",
                     "account_name",
                     "bank_name",
                     "account_no",
                     "bsb_no",
                     "provider",
                     "mid"


                   ],
                  where: [
                  {
                   type: "and",
                   key: "bankaccount_tb.isDeleted",
                   operator: "=",
                   value: 0
                 },{
                  type: "and",
                  key: "account_for",
                  operator: "=",
                  value: 2
                }],
                joins:[
                   {
                     joinType: "inner",
                     joinTable: "doctor_tb",
                     joinCondition: "doctor_tb.doctor_id = bankaccount_tb.provider"
                   }],
                   customCols: [
                    "CONCAT(Firstname,' ',Surname) AS docname"

                  ],
                   primary_key:"account_id",
                 },
                 viewtabletitle: "",
                 columns: [
                   {name: "docname", title: "Provider" },
                   {name: "account_name", title: "Account name" },
                   {name: "account_no" , title: "Account No."},
                   {name: "bsb_no" , title: "BSB No."},
                   {name: "mid" , title: "MID"}

                 ],
                 rows: [],
                 sortinit: [{ columnName: "account_id", direction: "desc" }],
                 pagesize: 10,
                 getAllIds:false,
                 getallId:'',
                 highlightprev:false
               }

            },
            crudPracice:{

               jsonGridVersion: "v2.0",
               grid: true,
               viewgrid: {
                 isVisible:true,
                 isPagination: true,
                 isFiltering: true,
                 isSelection:true,
                 deleteFunction:this.deletePracticeFunc,
                 isDateGroup:false,
                 isYearSeparator:false,
                 dateCol:'',
                 groupDateSelection:false,
                 cellClicked:'',
                 baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
                 query: {
                   tb: "bankaccount_tb",
                   cols: [
                     "account_id",
                     "account_name",
                     "bank_name",
                     "account_no",
                     "bsb_no",
                     "provider",
                     "mid"


                   ],
                  where: [
                  {
                   type: "and",
                   key: "bankaccount_tb.isDeleted",
                   operator: "=",
                   value: 0
                 },{
                  type: "and",
                  key: "account_for",
                  operator: "=",
                  value: 1
                }],
              /*  joins:[
                   {
                     joinType: "inner",
                     joinTable: "doctor_tb",
                     joinCondition: "doctor_tb.doctor_id = bankaccount_tb.provider"
                   }],
                   customCols: [
                    "CONCAT(Firstname,' ',Surname) AS docname"

                  ],*/
                   primary_key:"account_id",
                 },
                 viewtabletitle: "",
                 columns: [
                   {name: "account_name", title: "Account name" },
                   {name: "account_no" , title: "Account No."},
                   {name: "bsb_no" , title: "BSB No."},
                   {name: "mid" , title: "MID"}

                 ],
                 rows: [],
                 sortinit: [{ columnName: "account_id", direction: "desc" }],
                 pagesize: 10,
                 getAllIds:false,
                 getallId:'',
                 highlightprev:false
               }


            },
            selected_provider_length:0,
            selected_provider_id:'',
            addProvider:false,
            showfor:'',
            selected_practice_id:'',
            selected_practice_length:0,
            addPractice:false,
            setting_data:'',
            setting_id:''
        }
    }
    componentWillMount=()=>
    {const settname={setting_name:'print_twice'}
    this.props.apigetsettings(settname).then(res=>{
     this.setState({setting_data:res.data.content[0].setting_value,setting_id:res.data.content[0].setting_id})
    })
  }
    reloadPage=()=>
    {
        this.setState({addPractice:false,addProvider:false,key:Math.random(),showfor:''})
    }
    checkProvider=(actionfor)=>
    {
      if(actionfor=='Add')
      {
          if(this.state.selected_provider_length>0)
          {
           alert("Please deselect the record to proceed")
          }
          else
          {
               this.setState({addProvider:true,showfor:actionfor})
          }
      }
      if(actionfor=='Edit')
      {
          if(this.state.selected_provider_length==1)
          {

           this.setState({addProvider:true,showfor:actionfor})
          }
          else
          {
           alert("Please select single record to proceed")
          }
      }
    }
    deleteProviderFunc=(id,action,lenght)=>
    {
      if(action=='selection')
        {
            this.state.selected_provider_id=id;
            this.state.selected_provider_length=lenght;
        }
        if(action=='delete')
        {
            if (this.state.selected_provider_length> 0) {
                confirmAlert({
                  title: '',
                  message: 'You are about to delete provider account details. Do you want to proceed ? ',
                  buttons: [
                  {
                      label: 'Yes',
                      onClick: () => {
                        this.props.deleteProvider({ids:this.state.selected_provider_id}).then(
                          req=>this.reloadPage(),
                      NotificationManager.success(
                      'Provider account details deleted successfully',
                      //this.changeKey()
                      )
                    );
                  },
              },
              {
                label: 'No',
                onClick: () => { this.updateInGrid()},
              },
            ],
          });
        } else {
          NotificationManager.error('Please select provider bank account record(s) to be removed from the list');
        }
        }
    }
    checkPractice=(actionfor)=>
    {
      if(actionfor=='Add')
      {
          if(this.state.selected_practice_length>0)
          {
           alert("Please deselect the record to proceed")
          }
          else
          {
               this.setState({addPractice:true,showfor:actionfor})
          }
      }
      if(actionfor=='Edit')
      {
          if(this.state.selected_practice_length==1)
          {

           this.setState({addPractice:true,showfor:actionfor})
          }
          else
          {
           alert("Please select single record to proceed")
          }
      }
    }
    deletePracticeFunc=(id,action,lenght)=>
    {
      if(action=='selection')
        {
            this.state.selected_practice_id=id;
            this.state.selected_practice_length=lenght;
        }
        if(action=='delete')
        {
            if (this.state.selected_practice_length> 0) {
                confirmAlert({
                  title: '',
                  message: 'You are about to delete practice account details. Do you want to proceed ? ',
                  buttons: [
                  {
                      label: 'Yes',
                      onClick: () => {
                        this.props.deleteProvider({ids:this.state.selected_practice_id}).then(
                          req=>this.reloadPage(),
                      NotificationManager.success(
                      'Practice account details deleted successfully',
                      //this.changeKey()
                      )
                    );
                  },
              },
              {
                label: 'No',
                onClick: () => { this.updateInGrid()},
              },
            ],
          });
        } else {
          NotificationManager.error('Please select practice account detail record(s) to be removed from the list');
        }
        }
    }
    saveSetting=()=>
    {
     // alert(val)
      if(this.state.setting_data==true)
      {
        this.state.setting_data=1
      }
      else
      {
        this.state.setting_data=0
      }
      const data={
        id:this.state.setting_id,
        val:this.state.setting_data
      }
      this.props.savesetting(data).then(
        req=>
        {
          if(req.data.status=="Success")
          {
            NotificationManager.success("Setting updated successfully")
          }
          else{
            NotificationManager.error("Error encountered while saving the setting")
          }
        }
      )
    }
render()
{
 return (
     <div>
             <AdminHeader /><Leftsidemenu />
        <NotificationContainer />
    <section className="content-header" style={{marginLeft: '240px'}}>
          <h1>
          Bank Account
          </h1>
          <ol className="breadcrumb">
              <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
              <li className="active">Bank account</li>
          </ol>
      </section>
    <section className="content" style={{marginLeft: '240px'}}>
      <div className="row">
         <div className="col-xs-12">
            <div className="box">
               <div className="col-xs-12" style={{margin:'0px auto', float:'none'}}>
                  <div className="box-body">
                     <div className="col-sm-12 col-md-10 col-xl-10">
                        <div id="pageContent">
                        <div className="form-group row m-t-10">
                           <div className="col-lg-12 m-t-60">
                           <label className="col-sm-12 col-form-label" style={{fontSize:'14px', margin:'0 0 5px -10px'}}>Practice accounts</label>
                              <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#add-btn1" style={{width:'100px', margin:'5px'}}  onClick={()=>this.checkPractice('Add')}>Add</button>
                              <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#edit-btn1" style={{width:'100px', margin:'5px'}}  onClick={()=>this.checkPractice('Edit')}>Edit</button>
                              <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px',margin:'5px'}}  onClick={()=>this.deletePracticeFunc('','delete','')}>Delete</button>
                           </div>
                        </div>
                           <div className="form-group row m-b-10">
                              <div className="col-sm-12 m-t-10">
                                 <div className="card" style={{height:'250px', overFlowY:'scroll',/*border:'1px solid #ccc'*/}}>
                                    <div className="card-block">
                                    <CommonGrid
                                          crud={this.state.crudPracice}
                                          key={this.state.key}
                                       />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="form-group row m-t-10">
                              <div className="col-lg-12 m-t-60">
                               <label className="col-sm-12 col-form-label" style={{fontSize:'14px', margin:'0 0 5px -10px'}}>Provider accounts</label>
                                 <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#add-btn2" style={{width:'100px', margin:'5px'}}  onClick={()=>this.checkProvider('Add')}>Add</button>
                                 <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#edit-btn2" style={{width:'100px',margin:'5px'}}  onClick={()=>this.checkProvider('Edit')}>Edit</button>
                                 <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px',margin:'5px'}}  onClick={()=>this.deleteProviderFunc('','delete','')}>Delete</button>
                              </div>
                           </div>
                           <div className="form-group row m-b-10">
                              <div className="col-sm-12 m-t-10">

                                 <div className="card" style={{height:'250px', overFlowY:'scroll',/* border:'1px solid #ccc'*/}}>
                                    <div className="card-block">

                                       <CommonGrid
                                          crud={this.state.crudProvider}
                                          key={this.state.key}
                                       />

                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="form-group row m-t-10">
                              <div className="col-sm-12 col-md-10 col-xl-10">
                                 <div className="border-checkbox-section" style={{marginTop:'10px', marginBottom:'10px'}}>
                                    <div className="border-checkbox-group border-checkbox-group-default">

                                       <label className="border-checkbox-label" for="checkbox0" ><input className="border-checkbox" type="checkbox" id="checkbox0" value={this.state.setting_data} onChange={(e)=>this.setState({setting_data:e.target.checked})}
                                       checked={this.state.setting_data==1 ? 'checked' : ''}/>&nbsp; Print 2 copies of banking slips</label>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="modal-footer modal-footer-bottom-margin">
                     <div className="row">
                        <div className="col-sm-10"> </div>
                        <div className="col-sm-2">
                           <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>this.saveSetting()}>Save</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {(this.state.addProvider) ?
                <AddProviderAccount showmodal={this.state.addProvider} showfor={this.state.showfor} sid={this.state.selected_provider_id} changeKey={this.reloadPage}/> : ''}
          {(this.state.addPractice) ?
                <AddPracticeAccount showmodal={this.state.addPractice} showfor={this.state.showfor} sid={this.state.selected_practice_id} changeKey={this.reloadPage}/> : ''}

        </div>
   </section>
     <Footer/>
   </div>

   )}
   }
   const mapDispatchToProps = dispatch => {
      return {
               deleteProvider:(data)=>dispatch(api_delete_provider(data)),
               apigetsettings:(data)=>dispatch(api_get_settings(data)),
               savesetting:(data)=> dispatch(api_save_setting(data))

              };
    };
    const mapStateToProps = state => {
       let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';

     return {
     redux_docId
     };
   };
   export default connect(mapStateToProps,mapDispatchToProps) (BankAccount);
