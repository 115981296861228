import React, { Component,useState } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import {wflow} from './../../../DS/general';
import axios from 'axios';
 import Viewer from 'react-viewer';
import
{
  api_getClinical,
  api_updateClinical
} from '../../../DS/DS.Clinical';
import {api_get_image} from '../../../DS/DS.Correspondence';
import {
  api_get_type
} from "./../../../DS/DS.Investigation";

{/*}
export default props => {

    let visible=props.showImg
   // const [setVisible ] = React.useState(false);
    const setVisible=(stat)=>
    {
        visible=stat;
      //  console.log(stat);
       //    console.log(props.showImg)  ;  
    }

  return (
    <div>
     
      <Viewer
      visible={visible}
      onClose={() => { setVisible(false); } }
      images={[{src: 'https://homepages.cae.wisc.edu/~ece533/images/airplane.png', alt: ''}]}
      />
    </div>
  );
}
 */}


class ViewClinicalRecord extends Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
        visible:false,
        recordId:this.props.sid,
        selectedClinical:[],
        appendPath:''
        
    };
  }
 componentWillMount()
 {
    //const [ visible, setVisible ] = React.useState(false);
    this.state.visible=this.props.showImg
    if(this.props.showfor=='clinical'){
      this.props.getClinicalRecord({ params: {recordid: this.state.recordId}}).then(clinicalrec => {
        this.setState(
            { selectedClinical: clinicalrec.data.content}
            );
            this.setState({appendPath:'/Clinical/'})
       });
      
    }
   else if(this.props.showfor=='correspondence'){
      this.props.getCorrespondenceRecord({ params: {recordid: this.state.recordId}}).then(clinicalrec => {
        this.setState(
            { selectedClinical: clinicalrec.data.content}
            );
            this.setState({appendPath:'/Correspondence/'})
       });
      
    }
    else if(this.props.showfor=='investigation')
    {
      const movedata={id: this.state.recordId}
      this.props.gettype(movedata).then(clinicalrec => {
        this.setState(
            { selectedClinical: clinicalrec.data.content}
            );
            this.setState({appendPath:'/Correspondence/'})
       });
    }
 }

  handleChange(event) {
    
  }
  onChangeHandler=event=>{
    
}
onClickHandler=()=>
{
    this.state.visible=false;
}
handleDate = date => {
     
      };
toggleChange = () => {
       
      }
  saveData=()=>
  {
    //alert("clicked")
  }
  render() {
     
    return (
        <div>
       <div className="row">
                  
                    <div className="col-md-12">
                      { this.state.selectedClinical !=undefined && this.state.selectedClinical.map((item,i) => (
                             <Viewer
                              visible={this.state.visible}
                              images={[{src: `${process.env.REACT_APP_IMAGE_BASE_URL}`+this.state.appendPath+item.image_name, alt: ''}]}
                              onClose={()=>{this.setState({visible:false}) ; this.props.changeKey()}}
                              onClick={()=>this.saveData()}
                              />
                      ))}
                    </div>
                </div>
         </div>       
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    getClinicalRecord: recordid =>
      dispatch(api_getClinical(recordid)),
      getCorrespondenceRecord:data => dispatch(api_get_image(data)),
      gettype:data =>dispatch(api_get_type(data))
      
   };
};


const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';

  return {
    redux_patientId
  };
};
export default connect(
    mapStateToProps,
  mapDispatchToProps
)(ViewClinicalRecord);

//export default AddClinicalRecord;