import {GET_APPOINTMENTS, GET_DR_APPOINTMENTS, PATIENT_DETAILS_RESULT,GET_ACUTE, GET_TAGS, REMOVE_TAGS, SET_CUT_COPY_DETAILS, SET_SELECTED_APP} from '../DS/DS.Appointments';
import { PATIENT_CONDITIONS, DR_FAV_DIAG, DR_FAV_DIAG_CH } from '../DS/DS.VisitnotesDiagnosis';
import { SET_VISITNOTES_DETAILS} from '../DS/DS.VisitsNotes';
import { GET_LOGGED_INFO } from '../DS/DS.Users';
const initialState = {
  getAppointments: [],
  getDrAppointments: [], 
  getDrDates : [],
  getAcute: [],
  getPatientDetails : [],
  dr_fav_data: [],
  dr_fav_data2: [],
  patient_fav_data: [],
  patientTags: [],
  cut_copy_details: [],
  logged_info: [],
  selected_appointment: [],
  visitnotes_details: []
} 
//setting up data to state
export default function auth(state = initialState, action = {}) {
  console.log("initial state",state);
  console.log("reducer", action);
  switch (action.type) {
    case SET_SELECTED_APP: {
      console.log("SET_SELECTED_APP",state);
      return {...state, selected_appointment: action.thisapp}
    }
    case GET_LOGGED_INFO: {
      console.log("cutcopy",state);
      return {...state, logged_info: action.loggedData}
    }
    case SET_VISITNOTES_DETAILS: {
      console.log("vnotes",state);
      return {...state, visitnotes_details: action.VisitNotesDetails}
    }
    case SET_CUT_COPY_DETAILS: {
      console.log("cutcopy",state);
      return {...state, cut_copy_details: action.CutCopyDetails}
    }
      case GET_APPOINTMENTS: {
        return {...state, getAppointments: action.getAppointmentsData}
      }
      case GET_TAGS: {
        console.log("addtag reducer",state);
        return {...state, patientTags: action.addTagsRedux}
      }
      break;
      case REMOVE_TAGS: {
        console.log("removetag reducer", action.data, state, state.patientTags.filter((patientTag) => patientTag.patient_id !== action.removeTagsRedux));
        return {...state, patientTags: 
          state.patientTags.filter((patientTag) => patientTag.patient_id !== action.data)}
      }
      break;
       case PATIENT_DETAILS_RESULT: {
        return {...state, getPatientDetails: action.patientDetailsResult}
      }
      break;
      case GET_ACUTE: {
        return {...state , getAcute:action.acute}
      }
      break;
      case DR_FAV_DIAG:
        {
          return { ...state, dr_fav_data: action.dr_fav_data };
        }
        break;
      case DR_FAV_DIAG_CH:
        {
          return { ...state, dr_fav_data2: action.dr_fav_data2 };
        }
        break;
      case PATIENT_CONDITIONS:
        {
          //4
          return { ...state, patient_fav_data: action.getPatientConditionsData };
        }
        break;
      case GET_DR_APPOINTMENTS: {
        return {...state, getDrAppointments: action.getDrAppointmentsData.appointments, getDrDates : action.getDrAppointmentsData.dates}
      }
    break;

    default:
      return state;
    }
}