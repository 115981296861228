import React, { Component } from 'react';

class Navigation extends Component {
    state = {  }
    render() { 
        return ( 
            <div>
              <section>
              <div className="col-md-12 p-0">
                <aside className="main-sidebar">
  {/* sidebar: style can be found in sidebar.less */}
  <section className="sidebar">
    {/* Sidebar user panel */}
        
    {/* sidebar menu: : style can be found in sidebar.less */}
    <ul className="sidebar-menu" data-widget="tree" >
      <li className="header">R.Murugan</li>
      <li className="treeview menu-open">
        <a href="#"><i className="fa fa-dashboard" /> <span>Visit Notesdfdg</span></a>
      </li>
      <li className="treeview menu-open">
        <a href="#"><i className="fa fa-dashboard" /> <span>Medications</span></a>
      </li>
      <li className="treeview menu-open">
        <a href="#"><i className="fa fa-dashboard" /> <span>Observations</span></a>
      </li>
      <li className="treeview menu-open">
        <a href="#"><i className="fa fa-dashboard" /> <span>Immunisations</span></a>
      </li>
      <li className="treeview menu-open">
        <a href="#"><i className="fa fa-dashboard" /> <span>Past History</span></a>
      </li>
      <li className="treeview menu-open">
        <a href="#"><i className="fa fa-dashboard" /> <span>Investigation reports</span></a>
      </li>
      <li className="treeview menu-open">
        <a href="#"><i className="fa fa-dashboard" /> <span>Correspondence</span></a>
      </li>
      <li className="active treeview menu-open">
        <a href="#"><i className="fa fa-dashboard" /> <span>Family/Social history</span></a>
      </li>
      <li className="treeview menu-open">
        <a href="#"><i className="fa fa-dashboard" /> <span>Clinical Images</span></a>
      </li>
      <li className="treeview menu-open">
        <a href="#"><i className="fa fa-dashboard" /> <span>Obstetric History</span></a>
      </li>
      <li className="treeview menu-open">
        <a href="#"><i className="fa fa-dashboard" /> <span>Women's History</span></a>
      </li>
      <li className="treeview menu-open">
        <a href="#"><i className="fa fa-dashboard" /> <span>Enhanced Primary Care</span></a>
      </li>
    </ul>
  </section>
  {/* /.sidebar */}
</aside>
</div>
</section>

            </div>

         );
    }
}
 
export default Navigation;