import React, { Component } from "react";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

import { confirmAlert } from "react-confirm-alert";
import Showhelp from './../showHelp';
 
import {
    api_cancel_reason,
} from "./../../DS/DS.ListPage";
import {api_cancel_appointment} from './../../DS/DS.Appointments';
 class cancelAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
        open:this.props.open,
        cancelList:[],
        cancelReason:'',
        cancelReasonTxt:''
    }
}
  
  componentWillMount=()=>
  {
    this.props.getReason().then(list => {
        this.setState({ cancelList: list.data.content });
      });
  }
  cancelAppointment=()=>
  {
      console.log('***********************',this.state.cancelReasonTxt)
    let app = this.props.appId;
    let modifyAppointmentParam = {
        app: app,
        action: 'cancel',
        ctype: this.props.ctype,
        dateSearchParam: {
          start_datetime: this.props.sDate
        },
        doctorSearchParam: {
          doctor_guid: this.state.drId,
          start_datetime:
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            new Date().getDate() +
            " 06:00:00",
          end_datetime: new Date(
            new Date().setDate(new Date().getDate() + 6)
          )
        }
      };
      this.props.cancelApp({appid:this.props.appId.appoinment_id,rsn:this.state.cancelReasonTxt,mod:modifyAppointmentParam});    
      NotificationManager.success(
        "Appointment Cancelled Successfully!"
      );        
      this.props.onClose();
      
  }
    render(){
        return(
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                        <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px', fontSize:'16px',marginBottom:'0px'}}> Cancel Appointment <Showhelp gotohelp="appointment_move_popup"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.onClose()} style={{color:'black'}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ fontSize: "14px" }}>
                        <div className="col-md-12">
                            <div className="col-md-2"></div>
                            <div className="col-md-6">
                                <label></label>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                        <div className="col-md-12">
                        <div className="col-md-4 pl-0">
                        <select className="form-control" onChange={e => this.setState({cancelReason:e.target.value,cancelReasonTxt:e.target.value})} value={this.state.cancelReason}> 
                                <option value=""> </option>
                                {this.state.cancelList!=undefined && this.state.cancelList.map(cancelList => (
                                  <option key={cancelList.cancel_reason_id} value={cancelList.reason_name}>
                                  {cancelList.reason_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                                
                        </div>  
                    </div>
                    <div className="modal-footer">
                    <button type="button"   className="btn btn-primary btn-xs"  onClick={()=>this.cancelAppointment()}>Submit</button>
                            <button type="button"  onClick={() => this.props.onClose()} className="btn btn-primary btn-xs" data-dismiss="modal">Back</button>
                        </div>   
                      </div>        
                    )}     
                </Popup>
        )}
    } 
    
   
const mapDispatchToProps = dispatch => {
  return {
    getReason: () => dispatch(api_cancel_reason()),
    cancelApp:(data)=>dispatch(api_cancel_appointment(data))
  };
};


const mapStateToProps = state => {
   
  let all_appointments = state.getDrAppointments.getAppointments
    ? state.getDrAppointments.getAppointments
    : [];
 
  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";

  return {
    all_appointments,
  
    logged_info
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(cancelAppointment);



