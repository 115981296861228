
import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import {api_add_new_Pregnancy,api_get_pregnancy_no,api_get_pregnancy_data,api_update_pregnancy} from '../../../DS/DS.ObstetricHistory'
import { api_getDoctors } from '../../../DS/DS.Appointments';
import PregnancyOutcomeDetails from './pregnancyOutcomeDetails';
import Showhelp from './../../showHelp';

class PregnancyOutcome extends Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            open:this.props.showmodal,
            pregoutcome:false,
            key:0,
            pregno:'',
            docs:[],
            ivf:'',
            scanweeks:'',
            scandays:'',
            gestation:'',
            weeks:'',
            usedscan:'',
            outcome:'',
            hyper:'',
            eclam:'',
            renal:'',
            actuallmp: new Date(),
            edcbylmp: new Date(),
            scandate: new Date(),
            edcbyScan: new Date(),
            finishdate: new Date(),
            pregnancy_id:this.props.id.patients_women_obstetric_pregnancies_id,
            outcometxt:'',
            dateShow:false,
            showbtn:true,
            enabledd:true,
            enablefi:true,
            scandis:false,
            lmpdis:false,
        

        }
    }

    componentWillMount=()=>{
        this.props.getDoctors().then(res => {
            this.setState({
              docs: res.data.content ,
            })});
            if(this.props.id!='')
            {
        this.props.getPregnancyData({id:this.props.id.patients_women_obstetric_pregnancies_id}).then(res=>{
          //  alert(res.data.content[0].EDCByScan)
            this.setState({pregno:res.data.content[0].number
                ,doc:res.data.content[0].recoreded_by_guid
                ,ivf:res.data.content[0].IVF
            ,actuallmp: moment(new Date(res.data.content[0].ActualLMP)).format('YYYY/MM/DD')
            ,scandate: moment(new Date(res.data.content[0].ScanDate)).format('YYYY/MM/DD')
            ,edcbyScan: moment(new Date(res.data.content[0].EDCByScan)).format('YYYY/MM/DD')
            ,edcbylmp: moment(new Date(res.data.content[0].EDCByDate)).format('YYYY/MM/DD'),
            finishdate: moment(new Date(res.data.content[0].finaldate)).format('YYYY/MM/DD')
            ,scanweeks:res.data.content[0].Scanweeks,
            scandays:res.data.content[0].Scandays,
            gestation:res.data.content[0].Diabetes,
            weeks:res.data.content[0].GestationLength,
            usedscan:res.data.content[0].UseScan,
            outcome:res.data.content[0].outcome,
            hyper:res.data.content[0].Hypertension,
            eclam:res.data.content[0].PreEclampsia,
            renal:res.data.content[0].RenalDisease,
            })

            if(res.data.content[0].IVF=='No')
             {
                 this.setState({ivf:0})
             }
             else{
                 this.setState({ivf:1})
             }  
             
             if(res.data.content[0].UseScan=='Yes')
             {
                 this.setState({lmpdis:true,scandis:false})
             }
             else
             {
                 this.setState({scandis:true,lmpdis:false})
             }
        })
    }
    }
    closePopUp=()=>
    {
        this.setState({key:Math.random(),pregoutcome:false})
    }

    closeAll=()=>
    {
        this.setState({pregoutcome:false})
        this.props.changeKey();
    }

    handleDateac = date => {
        this.setState({ actuallmp: date });
      };
      handleDateed = date => {
        this.setState({ edcbylmp: date });
      };
      handleDatesc = date => {
        this.setState({ scandate: date });
      };
      handleDateedsc = date => {
        this.setState({ edcbyscan: date });
      }; 
      handleDatefi = date => {
        this.setState({ finishdate: date });
      };
      changebtn=(val)=>
      {
        if(val=='Miscarriage' || val=='Termination' || val=='Ectopic pregnancy' || val=='Hydatidiform mole')
        {
            this.setState({showbtn:false,enablefi:false})
        }
        else{
            this.setState({showbtn:true,enablefi:false})
        }
        if(val=='')
        {
            this.setState({enablefi:true})
        }
      } 
      enableDates=(chk)=>
      {
          if(chk==true)
          {
              this.setState({enabledd:false})
          }
          else{
            this.setState({enabledd:true})
          }
      }
      actlmp=(chk)=>
      {
          if(chk==true)
          {
            this.setState({lmpdis:false})
          }
          else{
              this.setState({lmpdis:true})
          }
      }
      actscan=(chk)=>
      {
          if(chk==true)
          {
            this.setState({scandis:false})
          }
          else{
              this.setState({scandis:true})
          }
      }
      saveDate=()=>
      {
          const allData={
                number:this.state.pregno,
                edcbydate:this.state.edcbylmp,
                actuallmp:this.state.actuallmp,
                ivf:this.state.ivf,
                finaldate:this.state.finishdate,
                scanday:this.state.scandays,
                weeks:this.state.scanweeks,
                gsl:this.state.weeks,
                usedscan:this.state.usedscan, 
                outcome:this.state.outcome,
                dbts:this.state.gestation,
                hypertension:this.state.hyper,
                ecla:this.state.eclam,
                renaldisease:this.state.renal,
                edcbyscan:this.state.edcbyScan,
                scandate:this.state.scandate,
                s1:'',
                s2:'',
                s3:'',
                delivery:'',
                onset:'',
                augmentation:'',
                analg:'',
                peri:'',
                plac:'',
                bloodloss:'',
                antid:'',
                pregnote:'',
                pregnancy_id:this.state.pregnancy_id
          }
          this.props.updatePregnancyData(allData).then(res=>
            {
                NotificationManager.success(
                    'Details save successfully',
                    this.props.changeKey()
                    )
            })
      }
     
    render(){
        return(
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div style={{ width:'700px', marginLeft:'170px', backgroundColor:'#FFF', float:'left'}}>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '0px 15px',color:'black'}}>
                        <h6 className="popup-title">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()} style={{ position: "inherit", right: "10px", fontSize: '25px' }}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <span>Pregnancy outcome <Showhelp gotohelp="pregnancy_outcome_help"/></span>
                        </h6>
                    </div>
                    <div className="modal-body" style={{ fontSize: "14px" }}>



                    <div className="col-sm-12 gesdetails">
  <div className="row">
    <div className="col-sm-2 pr-0 mt-3">
      <label>Pregnancy No. :</label>
    </div>
    <div className="col-sm-3">
    <input type="text" name="search_condition" className="form-control form-control-sm" value={this.state.pregno} onChange={(e)=>this.setState({pregno:e.target.value})}  /> 
    </div>
    <div className="col-sm-2  mt-3">
      <label> Managed by :</label>
    </div>
    <div className="col-sm-3">
    <select style={{width:"50%",height:"25px"}} onChange={(e) => this.setState({ from_guid: e.target.value })}>
                                        <option value={this.state.from_guid}></option>
                                        { this.state.docs !=undefined && this.state.docs.map((item,i) => (
                                        <option value={item.doctor_id}> {item.Firstname} </option>
                                        ))}
                                    </select>
    </div>
  </div>
  <div className="row">
    <div className="col-sm-2"></div>
    <div className="col-sm-3" style={{marginTop:"-5px", marginBottom:"10px" }}>
      <label className="checkbox-inline">
      <input type="checkbox" name="search_condition"  value={this.state.ivf} onChange={(e)=>this.setState({ivf:e.target.checked})}/> IVF Pregnancy
      </label>
    </div>
    <div className="col-sm-7" />
  </div>
  <div className="row">
    <div className="col-sm-2 mt-1">
      <label> Actual LMP :</label>
    </div>
    <div className="col-sm-3 pr-0">
    <input type="checkbox" name="lmp_box" id="lmp_box" onChange={(e)=>this.actlmp(e.target.checked)} className="dateic1" />
                                
                                <DatePicker
                                     className="datePicker"
                                     onChange={this.handleDateac}
                                     dateFormat="dd/MM/yyyy"
                                     id="clinicalcalendar" 
                                     selected={
                                            this.state.dateShow
                                                ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                : Date.parse(this.state.actuallmp)
                                            }
                                        disabled={this.state.lmpdis}    
                                       
                                    />
    </div>
    <div className="col-sm-2 mt-1">
      <label> EDC by LMP :</label>
    </div>
    <div className="col-sm-3 pr-0">
    <input type="checkbox" name="lmp_box" id="lmp_box"   className="dateic1"/>

                               
<DatePicker
         className="datePicker"
             onChange={this.handleDateed}
            dateFormat="dd/MM/yyyy"
            id="clinicalcalendar" 
            selected={
                this.state.dateShow
                    ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                    : Date.parse(this.state.edcbylmp)
                    
                } 
                disabled={this.state.lmpdis}  
        />
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-sm-2 mt-1">
      <label> Scan date :</label>
    </div>
    <div className="col-sm-3 pr-0">
    <input type="checkbox" name="lmp_box" id="lmp_box"   className="dateic1" onChange={(e)=>this.actscan(e.target.checked)}/>

<DatePicker
         className="datePicker"
             onChange={this.handleDatesc}
            dateFormat="dd/MM/yyyy"
            id="clinicalcalendar" 
            selected={
                this.state.dateShow
                    ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                    : Date.parse(this.state.scandate)
                } 
                disabled={this.state.scandis} 
        />
    </div>
    <div className="col-sm-2 pr-0 mt-2">
      <label> Scan weeks :</label>
    </div>
    <div className="col-sm-1"> 
    <input type="text" name="search_condition" className="form-control mt-1"  value={this.state.scanweeks} onChange={(e)=>this.setState({scanweeks:e.target.value})}/>
    </div>
    <div className="col-sm-1 cus-wid pr-0 mt-2">
      <label> Scan days :</label>
    </div>
    <div className="col-sm-1"> 
    <input type="text" name="search_condition" className="form-control mt-1" value={this.state.scandays} onChange={(e)=>this.setState({scandays:e.target.value})}/> 
    </div>
  </div>
  <div className="row">
    <div className="col-sm-2 mt-2">
      <label> EDC by scan :</label>
    </div>
    <div className="col-sm-3 pr-0">
    <input type="checkbox" name="lmp_box" id="lmp_box"  className="dateic1" onChange={(e)=>this.actscan(e.target.checked)}/>

<DatePicker
         className="datePicker"
         onChange={this.handleDateedsc}
            dateFormat="dd/MM/yyyy"
            id="clinicalcalendar" 
            selected={
                this.state.dateShow
                    ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                    : Date.parse(this.state.edcbyScan)
                }
                disabled={this.state.scandis}     
        />
    </div>
    <div className="col-sm-3 mt-1">
      <label className="checkbox-inline">
      <input type="checkbox" name="search_condition"  checked={this.state.usedscan=='Yes' ? 'checked' :'' } onChange={(e)=>this.setState({usedscan:e.target.checked})}/> Use scan dates
      </label>
    </div>
    <div className="col-sm-7">
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-sm-2 mt-1">
      <label> Finish date :</label>
    </div>
    <div className="col-sm-3 pr-0">
    <input type="checkbox" name="lmp_box" id="lmp_box" onChange={(e)=>this.enableDates(e.target.checked)}  className="dateic1" />

<DatePicker
         className="datePicker"
         selected={
            this.state.dateShow
                ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                : Date.parse(this.state.finishdate)
            }
             onChange={this.handleDatefi}
            dateFormat="dd/MM/yyyy"
            id="clinicalcalendar" 
       
        />
    </div>
    <div className="col-sm-2 pr-0 mt-1">
      <label> Gestation (wks):</label>
    </div>
    <div className="col-sm-1">
    <input type="text" name="search_condition" className="form-control mt-0" value={this.state.weeks} onChange={(e)=>this.setState({weeks:e.target.value})}/>
    </div>
    <div className="col-sm-7" />
  </div>
  <div className="row">
    <div className="col-sm-2">
      <label> Outcome :</label>
    </div>
    <div className="col-sm-8">
    <select className="form-control mt-0" style={{width:"50%",height:"25px"}} value={this.state.outcome} 
                                onChange={(e)=>{this.setState({outcome:e.target.value,outcometxt:e.target.text});this.changebtn(e.target.value)}}
                                disabled={this.state.enabledd}>
                                    <option value="0">Select</option>
                                    <option value="Live birth">Live birth</option>
                                    <option value="Miscarriage">Miscarriage</option>
                                    <option value="Termination">Termination</option>
                                    <option value="Ectopic pregnancy">Ectopic pregnancy</option>
                                    <option value="IUFD">IUFD</option>
                                    <option value="Stillbirth">Stillbirth</option>
                                    <option value="Hydatidiform mole">Hydatidiform mole</option>
                                    
                                </select>
    </div>
    <div className="col-sm-2" />
  </div>
  <div className="row">
    <div className="col-sm-2">
      <label> Complications :</label>
    </div>
    <div className="col-sm-3">
    <label className="checkbox-inline" >
        <input disabled={this.state.enablefi} type="checkbox" name="search_condition" checked={this.state.gestation==1 ? 'checked' :'' } onChange={(e)=>this.setState({gestation:e.target.checked})}/> 
                                    Gestational diabetes</label>
    </div>
    <div className="col-sm-3">
      <label className="checkbox-inline">
                                    <input disabled={this.state.enablefi} type="checkbox" name="search_condition" checked={this.state.hyper==1 ? 'checked' :'' } onChange={(e)=>this.setState({hyper:e.target.checked})}/>
                                     Hypetension</label>
    </div>
    <div className="col-sm-4">
    </div>
  </div>
  <div className="row">
    <div className="col-sm-2"> </div>
    <div className="col-sm-3">
      <label className="checkbox-inline">
      <input disabled={this.state.enablefi} type="checkbox" name="search_condition" checked={this.state.eclam==1 ? 'checked' :'' } onChange={(e)=>this.setState({eclam:e.target.checked})}/> 
                                Pre-Eclampsia
      </label>
    </div>
    <div className="col-sm-3">
      <label className="checkbox-inline">
      <input disabled={this.state.enablefi} type="checkbox" name="search_condition" checked={this.state.renal==1 ? 'checked' :'' } onChange={(e)=>this.setState({renal:e.target.checked})}/> 
                                    Renal disease
      </label>
    </div>
    <div className="col-sm-4">
    </div>
  </div>
</div>
{this.state.pregoutcome ?
                            <PregnancyOutcomeDetails showmodal={this.state.pregoutcome} changeKey={this.closePopUp} followup={this.state} closeAll={this.closeAll}/>:''}


                    </div>
                    <div className="modal-footer">
                        {this.state.showbtn ?
                        <button type="button"  onClick={() =>this.setState({pregoutcome:true})} className="btn btn-primary btn-xs" data-dismiss="modal">Next</button>
                       : <button type="button"   onClick={() => this.saveDate()} className="btn btn-primary btn-xs" data-dismiss="modal">Finish</button>
                         } <button type="button"  onClick={() => this.props.changeKey()} className="btn btn-primary btn-xs" data-dismiss="modal">Cancel</button>
                    </div>   
                </div>        
            )}     
        </Popup>
        )}
    }   

    const mapDispatchToProps = dispatch => {
    return {
        getPregnancyData: (data) => dispatch(api_get_pregnancy_data(data)),
        getDoctors: data => dispatch(api_getDoctors(data)),
        updatePregnancyData: (data) => dispatch(api_update_pregnancy(data)),

           };
    };
const mapStateToProps = state => {
    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
 
  return {
    redux_patientId,redux_docId
  };
 };
 
 export default connect(
 mapStateToProps,mapDispatchToProps
 )(PregnancyOutcome) 