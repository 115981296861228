import React, { Component } from "react";
import Header from "../../header";
import Footer from "../../footer";
import Grid from "../../crudcomponent/combinedcrud";
import { dropDown, dropdown1 } from "../../../DS/services";
import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
import { Link } from "react-router-dom";
const AgeSelector = props => {
  const { value, onChange } = props
  const handleChange = val => evt => {
    evt.preventDefault()
    onChange(val)
  }

  return (
    <div>
      <button
        style={{ backgroundColor: value === 20 ? 'red' : 'white' }}
        onClick={handleChange(20)}
      >
        20
      </button>
      <button
        style={{ backgroundColor: value === 30 ? 'red' : 'white' }}
        onClick={handleChange(30)}
      >
        30
      </button>
      <button
        style={{ backgroundColor: value === 40 ? 'red' : 'white' }}
        onClick={handleChange(40)}
      >
        40
      </button>
    </div>
  )
}
const widgets = {
  ageSelector: AgeSelector
}
class Centers extends Component {
  state = {
    crud: {
      jsonGridVersion: "v2.0",
      pagetitle: "Center Primary Title",
      subtitle: "Center sub title",
      grid: true,
      breadcrumb: {
        link: "./centers",
        text: "Centers"
      },
      add_form: {
        isVisible: true,
        isBacktoGridOnSubmit: true,
        destination: "center_tb",
        pk: "center_id",
        successMessage: "Successfully Added",
        errorMessage: "Something went to Wrong, Try again later",
        schema: {
          title: "Add Center",
          type: "object",
          required: ["name", "city"],
          properties: {
            name: { type: "string", title: "Name" },
            medical_center_id: { type: "string", title: "Medical Center ID" },
            key_contact: { type: "string", title: "Key Contact" },
            mobile: { type: "number", title: "Mobile" },
            email: { type: "string", title: "Email" },
            city: dropDown("patients", "patient_id", "Firstname", "")
          }
        },

        ui_schema: {
          mobile: {
            "ui:help": "Ex: 0255515678",
             'ui:widget': 'ageSelector'
          },
          email: {
            "ui:widget": "email",
            "ui:help": "Enter primary contact email"
          }
        }
      },

      edit_form: {
        isVisible: true,
        isBacktoGridOnSubmit: true,
        destination: "center_tb",
        pk: "center_id",
        successMessage: "Successfully Updated",
        errorMessage: "Something went to Wrong, Try again later",
        schema: {
          title: "Edit Center",
          type: "object",
          required: ["name", "city"],
          properties: {
            name: { type: "string", title: "Name" },
            medical_center_id: { type: "string", title: "Medical Center ID" },
            key_contact: { type: "string", title: "Key Contact" },
            mobile: { type: "string", title: "Mobile" },
            email: { type: "string", title: "Email" },
            city: dropDown("patients", "patient_id", "Firstname", ""),
            ID: { type: "number" }
          } 
        },

        ui_schema: {
          mobile: {
            "ui:help": "Ex: 0255515678"
          },
          email: {
            "ui:widget": "email",
            "ui:help": "Enter primary contact email"
          },
          ID: {
            "ui:widget": "hidden"
          }
        }
      },
      delete: {
        destination: "center_tb",
        pk: "center_id",
        isConfirmBeforeDelete: true,
        successMessage: "Successfully Deleted..!",
        errorMessage: "Something went to Wrong, Try again later",
        confirmationTitle: "Do you really want to delete?",
        confirmationMessage: "Are you sure to do this."
      },
      viewgrid: {
        isVisible:true,
        isPagination: true,
        isFiltering: true,
        isActions: true,

        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "center_tb",
          cols: [
            "center_id",
            "name",
            "medical_center_id",
            "key_contact",
            "status"
          ],
          customCols: [  
            //"CONCAT(Firstname,' ',Middlename) AS patientname"
            
          ],
         /*  joins: [
            {
              joinType: "inner",
              joinTable: "patients",
              joinCondition: "center_tb.center_id = patients.branch_id"
            }
          ],
         where: [
            {
              type: "and",
              key: "patients.Sex",
              operator: "=",
              value: "1"
            }
          ]*/
        },
        viewtabletitle: "",
        columns: [
          { name: "name", title: "Name" },
          { name: "medical_center_id", title: "Medical Center ID" },
          { name: "key_contact", title: "Key Contact" },
       //   { name: "patientname", title: "Patient Name" },
          { name: "action", title: "Action" }
        ],
        rows: [],
        sortinit: [{ columnName: "name", direction: "asc" }],
        pagesize: 3,
        actions: [
          {
            buttonClass: "info",
            icon: "fa-pencil",
            text: "",
            function: "edit",
            passParam: "center_id",
            isDefault: true
          },
          {
            buttonClass: "danger",
            icon: "fa-trash",
            text: "",
            function: "delete",
            passParam: "center_id",
            isDefault: true
          },
          {
            buttonClass: "primary",
            icon: "fa-eye",
            text: "",
            function: "view",
            passParam: "name",
            isDefault: false
          }
        ]
      }
    }
  };
  componentDidMount() {}
  render() {
    var gridstyle = {
      padding: "10px"
    };
    return (
      <div>
        <body
          className="skin-blue sidebar-mini"
          data-gr-c-s-loaded="true"
          style={{ height: "auto", minHeight: "100%" }}
        >
          <div
            className="wrapper"
            style={{ height: "auto", minHeight: "100%" }}
          >
            <AdminHeader />
            <Leftsidemenu />
            {/* Content Header (Page header) */}

            <div className="content-wrapper" style={{ minHeight: 314 }}>
              <section className="content-header">
                <h1>
                  {this.state.crud.pagetitle}
                  <small> {this.state.crud.subtitle} </small>
                </h1>
                <ol className="breadcrumb">
                  <li>
                    <a href="#">
                      <i className="fa fa-dashboard" /> Home
                    </a>
                  </li>
      
                  <li className="active">
                    <Link to={this.state.crud.breadcrumb.link}>
                      {this.state.crud.breadcrumb.text}
                    </Link>
                  </li>
                </ol>
              </section>
              {/* Main content */}
              <section className="content">
                <div className="row">
                  <div className="col-lg-12">
                    <Grid
                      style={gridstyle}
                      handleButton={this.handleButton}
                      crud={this.state.crud}
                    />
                  </div>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </body>
      </div>
    );
  }
}

export default Centers;
