import React, { Component } from "react";
import {
  api_patient_investigations,
  api_modify_investigations,
  api_addInvestigation,
  api_get_type
} from "./../../../DS/DS.Investigation";
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import { wflow, wip } from "./../../../DS/general";
import { connect } from "react-redux";
import moment from "moment";
import "./investigationReports.css";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import Investigationtable from "./investigationsTable";
import Investigationaddvalues from "./investigationAddValues";
import Addinvestigation from "./addinvestigation";
import ViewCorrespondenceRecord from "./../Correspondence/viewCorrespondenceRecord";
import Editinvestigation from "./editinvestigation";
import ViewClinicalRecord from './../clinical/viewClinicalRecord';
import CorrespondenceEditor from './../Correspondence/correspondenceEditor';
import Showhelp from './../../showHelp';

class InvestigationReports extends Component {
  constructor(props) {
    super(props);

    this.handleInvestigationCrud = this.handleInvestigationCrud.bind(this);
    this.handleInvestigationRowClick = this.handleInvestigationRowClick.bind(
      this
    );
    this.getAllId = this.getAllId.bind(this);
    this.state = {
      showNote: false,
      clickedId: "",
      key: 1,
      canEdit: false,
      selectedRec: "",
      selectedRecLength: "",
      patientId: this.props.redux_patientId,
      patient_investigations: [],
      selectedInvestigations: [],
      selectedDate: "",
      selectedInvestigationsFull: [],
      showSelectedContent: [],
      showSelectedContentIndex: 0,

      show_tab: "", //table_graph
      search: {
        date: "",
        test_name: "",
        action_to_be_taken: "",
        status: "",
        given_time: "",
        date_checked: "",
        docFirstname: ""
      },
      show_confidential_only: 0,
      grid_options: {
        sort_order_new: "",
        sort_by: "date",
        
        total_numbers: "",
        records_per_page: "10",
        current_page: "1",
        total_pages: "",
        previous_button: false,
        next_button: false
      },
      investigation_crud: {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.handleInvestigationCrud,
          isDateGroup: false,
          isYearSeparator: false,
          dateCol: "",
          cellClicked: this.handleInvestigationRowClick,
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "patients_reports",
            cols: [
              "patients_report_id",
              "date",
              "test_name",
              "content",
              "status",
              "given_time",
              "checked_by_guid"
            ],
            where: [
              {
                type: "and",
                key: "isDeleted",
                operator: "!=",
                value: 1
              },
              {
                type: "and",
                key: "patient_id",
                operator: "=",
                value: this.props.redux_patientId
              }
            ],
            joins: [
              {
                joinType: "inner",
                joinTable: "doctor_tb",
                joinCondition:
                  "patients_reports.checked_by_guid = doctor_tb.doctor_id"
              }
            ],
            customCols: ["CONCAT(Firstname,' ',Middlename) AS docname"],
            primary_key: "patients_report_id"
          },
          viewtabletitle: "",
          columns: [
            { name: "date", title: "Date" },
            { name: "test_name", title: "Test Name" },
            { name: "content", title: "Content" },
            { name: "status", title: "Status" },
            { name: "given_time", title: "Given Time" },
            { name: "date_checked", title: "Date Checked" },
            { name: "docname", title: "Given By" }
          ],
          rows: [],
          sortinit: [{ columnName: "date", direction: "asc" }],
          pagesize: 5,
          getAllIds: true,
          getallId: this.getAllId
        }
      },
      showmovedimage:false,
      showmovednote:false
    };
  }
  closeAddValuesPopup = e => {
    // alert(1);
    this.setState({ show_tab: "" });
  };

  //pagination
  gotoPage = (e, pageno) => {
    e.preventDefault();
    this.setState(
      { grid_options: { ...this.state.grid_options, current_page: pageno } },
      () => {
        this.loadinvestigations();
      }
    );
  };

  calculateTotalPages = totalrecords => {
    let totalpages = 0;
    if (totalrecords % this.state.grid_options.records_per_page == 0) {
      totalpages = totalrecords / this.state.grid_options.records_per_page;
    } else {
      totalpages = totalrecords / this.state.grid_options.records_per_page + 1;
    }
    return totalpages;
  };

  toggleConfidential = (e, val) => {


    this.setState(
      { grid_options: { ...this.state.grid_options, current_page: 1 } }, ()=> {

        this.setState({show_confidential_only: val}, ()=>this.loadinvestigations());

      });
    
  }

  //sort
  soryBy = a => {
    //  alert(a.sort_by);

    if (this.state.grid_options.sort_by == a.sort_by) {
      
      //this.setState({  });
      let sort_order = this.state.grid_options.sort_order_new;
      let new_sort_order = "";
      if(sort_order=="2"||sort_order==""){
        new_sort_order = "1";
      
      }
      if(sort_order=="2"){
        new_sort_order = "1";
      }
      
      this.setState({search: { ...this.state.search, sort_order_new: new_sort_order }}, () => console.log("new order set"));
      this.setState(
        {
          search: { ...this.state.search, sort_order_new: new_sort_order },
          grid_options: { ...this.state.grid_options, sort_by: a.sort_by }
        },
        () => this.loadinvestigations()
      );
    } else {
      // this.setState({ });
      this.setState(
        {
          search: { ...this.state.search, sort_order_new: "2" },
          grid_options: { ...this.state.grid_options, sort_by: a.sort_by }
        },
        () => this.loadinvestigations()
      );
    }
  };

  //filter/search
  handleSearch = (e, key) => {
    //this.setState({[key]:e.target.value});
    this.setState(
      { search: { ...this.state.search, [key]: e.target.value } },
      () => this.loadinvestigations()
    );
  };

  toggleEdit = e => {
    this.setState({ canEdit: !this.state.canEdit });
  };
  toggleAdd = e => {
    // alert(1);
    if (this.state.selectedInvestigations.length >= 1) {
      alert("Please unselect before adding");
    } else {
      this.setState({ addNew: !this.state.addNew });
    }
    
  };
  canResultNote = () => {
     
    
    if (this.state.selectedInvestigationsFull.length == 1) {
      this.cout_inv("investigation ", this.state.selectedInvestigations[0]);
      this.setState({ showNote: true });
    } else {
      alert("Please, Select one record to record note.");
    }
  };
  canEdit = () => {
    console.log(
      "immunisation selected length",
      this.state.selectedInvestigations
    );
    if (this.state.selectedInvestigationsFull.length == 1) {
      this.setState({ canEdit: true });
    } else {
      alert("Please, Select one record to Edit");
    }
  };
  loadinvestigations = () => {
    this.props
      .patient_investigations({
        patientId: this.state.patientId,
        grid_options: this.state.grid_options,
        search: this.state.search,
        show_confidential_only: this.state.show_confidential_only
      })
      .then(res => {
        this.setState({ patient_investigations: res.data.content });

        this.setState({
          grid_options: {
            ...this.state.grid_options,
            total_numbers: res.data.totalCount
          }
        });
        this.setState({
          grid_options: {
            ...this.state.grid_options,
            total_pages: this.calculateTotalPages(res.data.totalCount)
          }
        });
        this.setState({ showSelectedContent: res.data.content[0] });
      });
    console.log(this.state);
  };

  cout_inv = (...args) => {
    console.log("[INVESTIGATION_REPORT___", args, "____]");
  };

  componentDidMount() {
    console.clear();

    //this.props.patient_investigations();
    this.props
      .patient_investigations({
        patientId: this.state.patientId,
        grid_options: this.state.grid_options,
        search: this.state.search,
        show_confidential_only: this.state.show_confidential_only
      })
      .then(res => {
        this.setState({ patient_investigations: res.data.content });

        this.setState({
          grid_options: {
            ...this.state.grid_options,
            total_numbers: res.data.totalCount
          }
        });

        this.setState({
          grid_options: {
            ...this.state.grid_options,
            total_pages: this.calculateTotalPages(res.data.totalCount)
          }
        });
        this.setState({ showSelectedContent: res.data.content[0] });
      });
    console.log(this.state);
  }

  componentWillReceiveProps(nextProps) {
    //    console.log('---------recieve props changed',nextProps);

    if (nextProps.redux_patientId !== this.props.redux_patientId) {
      this.setState({ patientId: nextProps.redux_patientId });

      this.props
        .patient_investigations({
          patientId: nextProps.redux_patientId,
          grid_options: this.state.grid_options,
          search: this.state.search,
          show_confidential_only: this.state.show_confidential_only
        })
        .then(res => {
          this.setState({ patient_investigations: res.data.content });
          this.setState({
            grid_options: {
              ...this.state.grid_options,
              total_numbers: res.data.totalCount
            }
          });
          this.setState({
            grid_options: {
              ...this.state.grid_options,
              total_pages: this.calculateTotalPages(res.data.totalCount)
            }
          });
          this.setState({ showSelectedContent: res.data.content[0] });
        });
    }
  }
  // renderPagination = () => {
  //   let total = this.state.grid_options.total_numbers;
  //   let pageno = this.state.grid_options.current_page;

  //   let totalpages = total/pageno;
  //   this.setState({ grid_options: { ...this.state.grid_options, total_numbers:  totalpages } });
  //   this.setState({total_pages: totalpages});

  // }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.grid_options.total_numbers = this.state.grid_options.total_numbers)
    ) {
    } else {
      // this.renderPagination();
    }
  }
  handleInvestigationRowClick = (id, action) => {
    console.log("rowclicked", action, id);
  };

  changeKey = () => {
    this.setState({ showNote: false,showmovedimage:false,showmovednote:false });
    this.setState({ key: Math.random() });
    this.getAllId();
  };

  handleInvestigationCrud = (selectedId, action, checklength) => {
    if (action == "delete") {
      //console.log
      if (this.state.selectedRecLength_acute > 0) {
        const immData = { immunisation_id: this.state.selectedRec_acute };
        confirmAlert({
          title: "Delete record",
          message: "Do you want to delete the record?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                // this.changeKey();
              }
            },
            {
              label: "No",
              onClick: () => {
                //this.changeKey();
              }
            }
          ]
        });
      } else {
        NotificationManager.error("Please select a record to delete");
      }
    }
    if (action == "selection") {
      // this.setState({selectedRec: selectedId});
      // this.setState({selectedRecLength: checklength});
      this.state.selectedRec_acute = selectedId;
      this.state.selectedRecLength_acute = checklength;
      //  this.mapOld('acute');
      let item = [];
      item.push(selectedId.split(","));
      this.state.selectedAcute = item;
      // this.setState({selectedAcute: item});
    }

    console.log("pasthistorystate", this.state);
  };

  getAllId = allids => {
    console.log("adllids", allids);
  };

  checkboxstatus = (id, date) => {
    let found = false;
    for (let i = 0; i < this.state.selectedInvestigationsFull.length; i++) {
      if (this.state.selectedInvestigationsFull[i].patients_report_id === id) {
        found = true;
      }
    }
    return found;
  };
  setTrClass = (y, z, id) => {
    let classs = "";
    classs += z % 2 == 0 ? " firstColor " : " secondColor ";
    classs +=
      id ==
      (this.state.showSelectedContent != undefined &&
        this.state.showSelectedContent.patients_report_id)
        ? " selectedBg "
        : "";
    classs += y ? " yearSeperator " : "";
    return classs;
  };
  showSelectedContent(e, item, i) {
    this.setState({ showSelectedContent: item });
    this.setState({ showSelectedContentIndex: i });
    console.log(this.state);
  }
  handleCheckAllbox = (e, ids) => {
    console.log(this.state, ids);
    this.setState({
      selectedInvestigations: e.target.checked ? ids : [],
      selectedInvestigationsFull: e.target.checked
        ? this.state.patient_investigations
        : []
    });
  };
  next_prev = (e, typ) => {
    e.preventDefault();
    let index = this.state.showSelectedContentIndex;
    if (typ == "next") {
      index = index + 1;
      this.setState({ showSelectedContentIndex: index });
      this.setState({
        showSelectedContent: this.state.patient_investigations[index]
      });
    }
    if (typ == "prev") {
      index = index - 1;
      this.setState({ showSelectedContentIndex: index });
      this.setState({
        showSelectedContent: this.state.patient_investigations[index]
      });
    }
  };
  modifySelected = (e, type) => {
    e.preventDefault();
    if (this.state.selectedInvestigationsFull.length > 0) {
      let params = {
        patient_id: this.state.patient_id,
        type: type,
        ids: this.state.selectedInvestigationsFull
      };

      if (params.type == "delete") {
        confirmAlert({
          title: "",
          message: "You are about to delete a investigation record. Do you want to proceed?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                this.props.modify_investigations(params);
                this.setState({
                  selectedInvestigations: [],
                  selectedInvestigationsFull: []
                });
                this.props
                  .patient_investigations({
                    patientId: this.state.patientId,
                    grid_options: this.state.grid_options,
                    search: this.state.search,
                    show_confidential_only: this.state.show_confidential_only
                  })
                  .then(res => {
                    this.setState({ patient_investigations: res.data.content });
                    this.setState({
                      grid_options: {
                        ...this.state.grid_options,
                        total_numbers: res.data.totalCount
                      }
                    });
                    this.setState({
                      grid_options: {
                        ...this.state.grid_options,
                        total_pages: this.calculateTotalPages(
                          res.data.totalCount
                        )
                      }
                    });
                  });
                NotificationManager.success("Successfully Deleted");
              }
            },
            {
              label: "No",
              onClick: () => {}
            }
          ]
        });
      } else {
        this.props.modify_investigations(params).then(() => {
          this.setState({
            selectedInvestigations: [],
            selectedInvestigationsFull: []
          });
          this.props
            .patient_investigations({
              patientId: this.state.patientId,
              grid_options: this.state.grid_options,
              search: this.state.search,
              show_confidential_only: this.state.show_confidential_only
            })
            .then(res => {
              this.setState({ patient_investigations: res.data.content });
              this.setState({
                grid_options: {
                  ...this.state.grid_options,
                  total_numbers: res.data.totalCount
                }
              });
              this.setState({
                grid_options: {
                  ...this.state.grid_options,
                  total_pages: this.calculateTotalPages(res.data.totalCount)
                }
              });
            });NotificationManager.success("Marked as Given");
        });
        
        
      }
    } else {
      alert("Select any record");
    }
  };

  handleCheckbox = (e, data, id) => {
    let idd = e.target.value;
    let date = data.date;
    //    if (this.state.selectedInvestigations.includes(idd)) {
    //this.state.selectedInvestigations.patients_report_id
    if (
      this.state.selectedInvestigationsFull.filter(
        i => i.patients_report_id === id
      ).length > 0
    ) {
      console.log("already checked - now removing");
      //removing
      this.setState({
        selectedInvestigations: [
          ...this.state.selectedInvestigations.filter(
            patients_report_id => patients_report_id !== idd
          )
        ],
        selectedInvestigationsFull: [
          ...this.state.selectedInvestigationsFull.filter(
            data => data.patients_report_id !== idd
          )
        ],
        selectedDate: ""
        //editData: {},
      });
    } else {
      //setting checkboxes
      console.log("checking new - now adding");
      this.setState({
        selectedInvestigations: [...this.state.selectedInvestigations, idd],
        selectedDate: data.date
      });

      //having existing checkboxes + only selected date when any of the date already exist
      //having existing checkboxes + all dates of selected date
      let found = false;
      for (let i = 0; i < this.state.selectedInvestigationsFull.length; i++) {
        if (this.state.selectedInvestigationsFull[i].date === date) {
          found = true;
        }
      }

      if (found == true) {
        this.setState({
          selectedInvestigationsFull: this.state.selectedInvestigationsFull.concat(
            this.state.patient_investigations.filter(function(investigation) {
              return investigation.patients_report_id == id;
            })
          )
        });
      } else {
        this.setState({
          selectedInvestigationsFull: this.state.selectedInvestigationsFull
            .filter(function(investigation) {
              return investigation.date !== date;
            })
            .concat(
              this.state.patient_investigations.filter(function(investigation) {
                return investigation.date == date;
              })
            )
        });
      }
    }
    // console.log(id, data, this.state.selectedInvestigations, this.state.selectedInvestigationsFull)
  };
  checkImg=()=>
  {
    if(this.state.selectedInvestigationsFull.length  == 1)
    {
      console.log(this.state.selectedInvestigationsFull)
      const typeid={id:this.state.selectedInvestigationsFull[0].patients_report_id}
      this.setState({clickedId:this.state.selectedInvestigationsFull[0].patients_report_id})
       this.props.gettype(typeid).then(req=>
          {
            
            if(req.data.content[0].has_image==1)
            {
              this.setState({showmovedimage:true})
            }
            else
            {
              this.setState({showmovednote:true})
            }
          }
        )
        
    }
   else{
       alert("Plese select single record to view document")
   }
  }
  render() {
    var gridstyle = {
      padding: "10px"
    };
    let z = 0;
    return (
      <div
        id="notesdiv5"
        className="col-md-12"
        style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "#ffffff" }}
      >
        <div className="panel panel-default mb-0">
          <div
            className="panel-heading panspace">
            <NotificationContainer />
            <div
              className="col-lg-12 col-xl-12">
              <h4 className="panel-title">
                <a href="#" className="collapsed" aria-expanded="false">
                  Investigation reports  <Showhelp gotohelp="investigation_report_top_help"/>
                </a>
              </h4>
              <div style={{ width: "auto", height: 30, position: "absolute", right: 15, top: 0}} >
                <span className="makescreen">
                  <a
                    href="#"
                    title="Make This Default Screen"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    <i
                      className="fa fa-star panelicon"
                      aria-hidden="true" />
                  </a>
                </span>
                <a
                  href="#"
                  title="Send SMS"
                  data-toggle="modal"
                  data-target="#sendsms"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-sms panelicon"
                    aria-hidden="true" />
                </a>
                <a
                  href="#"
                  title="Email"
                  data-toggle="modal"
                  data-target="#sendmail"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-envelope panelicon"
                    aria-hidden="true" />
                </a>
                <a
                  href="#"
                  title="Print"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-print panelicon"
                    aria-hidden="true" />
                </a>
                {/*<a href="#" title="Export XLs"><i class="fas fa-file-excel-o" aria-hidden="true" style="font-size:12px; margin-right:5px;"></i></a>*/}
                <a
                  title="Export PDF"
                  onclick="recentVisitmini();"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fa fa-file-pdf-o panelicon"
                    aria-hidden="true" style={{ marginTop: "-15px", fontSize: "16px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6" style={{ marginBottom: '5px', marginTop: '5px' }}>
          <button
            // id="session-timeout-dialog-logout"
            // data-toggle="modal"
            // data-target="#pastmedical"
            type="button"
            className="btn btn-primary btn-xs" style={{margin:"0px 5px 0px 0px"}}
            onClick={e => this.toggleAdd(e)}
          >
            Add
          </button>

          <button
            id="session-timeout-dialog-logout"
            // data-toggle="modal"
            // data-target="#pastmedical"
            type="button"
            className="btn btn-primary btn-xs" style={{margin:"0px 5px 0px 0px"}}
            onClick={e => this.canEdit(e)}
          >
            Edit
          </button>
          <Addinvestigation
            open={this.state.addNew}
            close={this.toggleAdd}
            patientId={this.state.patientId}
            addInvestigations={this.props.add_investigations}
            loadinvestigations={this.loadinvestigations}
            changeKey={this.changeKey}
          />
          <Addinvestigation
            open={this.state.canEdit}
            close={this.toggleEdit}
            patientId={this.state.patientId}
            loadinvestigations={this.loadinvestigations}
            editData={this.state.selectedInvestigations}
            addInvestigations={this.props.add_investigations}
          />

          <button
            onClick={e => this.modifySelected(e, "delete")}
            id="session-timeout-dialog-logout"
            type="button"
            className="btn btn-primary btn-xs" style={{margin:"0px 5px 0px 0px"}}
          >
            Delete
          </button>
          <button
            onClick={e => this.modifySelected(e, "mark_as_given")}
            id="session-timeout-dialog-logout"
            type="button"
            className="btn btn-primary btn-xs" style={{margin:"0px 5px 0px 0px"}}
          >
            Mark result as given
          </button>
          {/* <button
            onClick={() => this.props.navToVisitNotes()}
            id="session-timeout-dialog-logout"
            type="button"
            className="btn btn-default"
            style={{ margin: "7px auto 0px", padding: "2px 10px" }}
          >
            Visit Notes
          </button>    */}

          <button
            id="session-timeout-dialog-logout"
            type="button"
            className="btn btn-primary btn-xs" style={{margin:"0px 5px 0px 0px"}}
            onClick={e => this.canResultNote()}
          >
            Record Note
          </button>
          {this.state.showNote ? (
            <ViewCorrespondenceRecord
             
              sid={this.state.selectedInvestigations[0]}
              showNote={this.state.showNote}
              changeKey={this.changeKey}
              correspondencetype="Investigations"
            />
          ) : (
            ""
          )}
         
          <button
            onClick={e => this.setState({ show_tab: "add_give_values" })}
            id="session-timeout-dialog-logout"
            type="button"
            className="btn btn-primary btn-xs" style={{margin:"0px 5px 0px 0px", float: "right"}}
          >
            Result Values
          </button>

         
        </div>
        <div
          className="col-sm-6 pl-0"
          style={{ marginBottom: 5, marginTop: 5, textAlign: "right" }}
        >
          <button
            onClick={e => this.setState({ show_tab: "table_graph" })}
            id="session-timeout-dialog-logout"
            onclick="atomised();"
            type="button"
            className="btn btn-primary btn-xs" style={{margin:"0px 5px 0px 0px", float: "left"}}
          >
            Graph
          </button>
          <button
            id="doc-img"
            type="button"
            className="btn btn-primary btn-xs" style={{margin:"0px 5px 0px 0px", float: "left"}} 
            onClick={e => this.checkImg()}
          >
            View Document
          </button>
        <label htmlFor="showconf"  >   <input id="showconf" type="checkbox" onChange={e => this.toggleConfidential(e, e.target.checked?1:0)} />  Show Confidential Only </label>
          {/* <select
            style={{
              float: "right",
              width: "25%",
              marginRight: 5,
              marginTop: 7,
              marginBottom: 0,
              padding: "7px 10px",
              fontSize: "14px"
            }}
          >
            <option>Filter</option>
            <option>Select One</option>
          </select> */}
        </div>
        <div className="col-sm-6">
          <div style={{ paddingLeft:"0px", overflow: "scroll", height: "570px" }}>
            {/* <CommonGrid
              style={gridstyle}
              key={this.state.key}
              handleButton={this.handleButton}
              crud={this.state.investigation_crud}
            /> */}

            <table>
              <tbody>
                <tr>
                  <td>
                    <div>
                      <table
                        className="investigationTable table-bordered    tablestyle newcolor "
                        style={{ float: "left", width: 1100 }}
                      >
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "#f4f4f4",
                              curser: "pointer"
                            }}
                            onclick="Investigationview();"
                          >
                            <th
                              style={{
                                textAlign: "left",
                                fontSize: "16px",
                                color: "#333",
                                padding: "10px"
                              }}
                              onClick={e =>
                                this.soryBy({
                                  sort_by: "patients_reports.date"
                                })
                              }
                            >
                              <input
                                onChange={e =>
                                  this.handleCheckAllbox(
                                    e,
                                    this.state.patient_investigations
                                      // .filter(item => item.acute_chronic == 'acute')
                                      .map(item => item.patients_report_id)
                                  )
                                }
                                checked={
                                  this.state.patient_investigations.length > 0
                                    ? this.state.selectedInvestigations
                                        .length ===
                                      this.state.patient_investigations.length
                                    : false
                                }
                                type="checkbox"
                                style={{
                                  float: "left",
                                  marginRight: "5px",
                                  width: "50"
                                }}
                              />{" "}
                              Date
                            </th>
                            <th
                              style={{
                                width: "250",
                                color: "#333",
                                fontSize: "16px",
                                padding: "10px"
                              }}
                              onClick={e =>
                                this.soryBy({
                                  sort_by: "patients_reports.test_name"
                                })
                              }
                            >
                              Test name
                            </th>
                            <th
                              style={{
                                color: "#333",
                                fontSize: "16px",
                                padding: "10px"
                              }}
                              onClick={e =>
                                this.soryBy({ sort_by: "doctor_tb.Firstname" })
                              }
                            >
                              {" "}
                              Checked by
                            </th>
                            <th
                              style={{
                                width: "94",
                                color: "#333",
                                fontSize: "16px",
                                padding: "10px"
                              }}
                              onClick={e =>
                                this.soryBy({
                                  sort_by: "patients_reports.date_checked"
                                })
                              }
                            >
                              {" "}
                              Date Checked
                            </th>
                            <th
                              style={{
                                color: "#333",
                                fontSize: "16px",
                                padding: "10px"
                              }}
                              onClick={e =>
                                this.soryBy({
                                  sort_by: "patients_reports.status"
                                })
                              }
                            >
                              {" "}
                              Status
                            </th>
                            <th
                              style={{
                                color: "#333",
                                fontSize: "16px",
                                padding: "10px"
                              }}
                              onClick={e =>
                                this.soryBy({
                                  sort_by: "patients_reports.given_time"
                                })
                              }
                            >
                              {" "}
                              Given at
                            </th>
                            {/* <th
                              style={{
                                color: "#333",
                                fontSize: "16px",
                                padding: "10px"
                              }}
                              onClick={e=> this.soryBy({sort_by: "Comment" })}
                            >
                              Comment
                            </th> */}
                            {/* <th
                              style={{
                                color: "#333",
                                fontSize: "16px",
                                padding: "10px"
                              }}
                              onClick={e =>
                                this.soryBy({ sort_by: "Provider" })
                              }
                            >
                              Provider
                            </th> */}
                          </tr>

                          <tr>
                            <th>
                              <input
                                type="text"
                                onChange={e => this.handleSearch(e, "date")}
                              />
                            </th>
                            <th>
                              <input
                                type="text"
                                onChange={e =>
                                  this.handleSearch(e, "test_name")
                                }
                              />
                            </th>
                            <th>
                              <input
                                type="text"
                                onChange={e =>
                                  this.handleSearch(e, "docFirstname")
                                }
                              />
                            </th>
                            <th>
                              <input
                                type="text"
                                onChange={e =>
                                  this.handleSearch(e, "date_checked")
                                }
                              />
                            </th>
                          
                            <th>
                              <input
                                type="text"
                                onChange={e => this.handleSearch(e, "status")}
                              />
                            </th>
                          
                            <th>
                              <input
                                type="text"
                                onChange={e => this.handleSearch(e, "given_time")}
                              />
                            </th>
                            {/* <th>
                              <input
                                type="text"
                                onChange={e =>
                                  this.handleSearch(e, "date_checked")
                                }
                              />
                            </th> */}
                            {/* <th>
                              <input
                                type="text"
                                onChange={e => this.handleSearch(e, "doctor_tb.Firstname")}
                              />
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.patient_investigations.map((item, i) => (
                            <tr
                              onClick={e =>
                                this.showSelectedContent(e, item, i)
                              }
                              rec={
                                i > 0 &&
                                item.date ===
                                  this.state.patient_investigations[i - 1].date
                                  ? z
                                  : z++
                              }
                              className={this.setTrClass(
                                i > 0 &&
                                  moment(item.date).format("YYYY") ===
                                    moment(
                                      this.state.patient_investigations[i - 1]
                                        .date
                                    ).format("YYYY")
                                  ? false
                                  : true,
                                z,
                                item.patients_report_id
                              )}
                            >
                              <td>
                                <input
                                  type="checkbox"
                                  name={item.patients_report_id}
                                  id="check_allin"
                                  value={item.patients_report_id}
                                  checked={this.checkboxstatus(
                                    item.patients_report_id,
                                    item.date
                                  )}
                                  onChange={e =>
                                    this.handleCheckbox(
                                      e,
                                      item,
                                      item.patients_report_id
                                    )
                                  }
                                />
                                &nbsp;{moment(item.date).format("DD/MM/YYYY")}{" "}
                              </td>
                              <td style={{ fontSize: "14px" }}>
                                {" "}
                                {item.test_name}
                              </td>
                              <td style={{ fontSize: "14px" }}>
                                {" "}
                                {"Dr " + item.Firstname}
                              </td>
                              <td style={{ fontSize: "14px" }}>
                                {item.date_checked != "0000-00-00" &&
                                  moment(item.date_checked).format(
                                    "DD/MM/YYYY"
                                  )}
                              </td>
                              <td style={{ fontSize: "14px" }}>
                                {item.status === null ? "-" : item.status}
                              </td>
                              <td style={{ fontSize: "14px" }}>
                                {/* {item.status === null
                                  ? "-"
                                    
                                    " " } */}
                                {moment(item.given_time).format("DD/MM/YYYY")}
                              </td>
                              {/* <td style={{ fontSize: "14px" }}>Normal</td> */}
                              {/* <td style={{ fontSize: "14px" }}>
                                {item.provider}
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
            <div className="clearfix card-footer dx-g-bs4-paging-panel">
              <nav className="float-right d-none d-sm-flex">
                <ul className="pagination m-0">
                  <li className={this.state.grid_options.current_page>1?"page-item":"page-item disabled"}>
                    <a className="page-link" aria-label="Previous" onClick={e => this.state.grid_options.current_page>1 && this.gotoPage(e, parseInt(this.state.grid_options.current_page)-1)} href="#">
                      <span aria-hidden="true">«</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>

                  {Array.apply(null, {
                    length: this.state.grid_options.total_pages
                  }).map((e, i) => (
                    <li
                      className={
                        this.state.grid_options.current_page == i + 1
                          ? "page-item active"
                          : "page-item  "
                      }
                    >
                      <a
                        className="page-link"
                        aria-label
                        href="#"
                        onClick={e => this.gotoPage(e, i + 1)}
                      >
                        {" "}
                        {i + 1}
                      </a>
                    </li>
                  ))}

                  <li className={this.state.grid_options.current_page<this.state.grid_options.total_pages? "page-item":"page-item disabled"}>
                    <a className="page-link" aria-label="Next" href="#" onClick={e => this.state.grid_options.current_page<this.state.grid_options.total_pages && this.gotoPage(e, parseInt(this.state.grid_options.current_page)+1)}>
                      <span aria-hidden="true">»</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
              <nav className="float-right d-sm-none">
                <ul className="pagination m-0">
                  <li className="page-item disabled">
                    <a className="page-link" aria-label="Previous" href="#">
                      <span aria-hidden="true">«</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                  &nbsp;
                  <li className="page-item disabled">
                    <a className="page-link" aria-label="Next" href="#">
                      <span aria-hidden="true">»</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>

              <span className="float-right d-sm-none mr-4">
                <span className="d-inline-block align-middle">1 of 1</span>
              </span>
            </div>
          
        </div>
        <div
          className="col-sm-6"
          style={{
            paddingLeft: "10px",
            overflow: "scroll",
            height: "605px",
            border: "1px solid #ddd"
          }}
          id="Investigationdetails"
        >
          {this.state.showSelectedContentIndex > 0 && (
            <a
              title="Previous"
              onClick={e => this.next_prev(e, "prev")}
              style={{ paddingRight: 5, marginRight: "-10px", float: "right" }}
              className="collapsed"
              aria-expanded="false"
            >
              <i
                className="fa fa-arrow-circle-up"
                aria-hidden="true"
                style={{ float: "right", marginTop: 10, fontSize: 16 }}
              />
            </a>
          )}{" "}

          {this.state.patient_investigations.length - 1 !=
            this.state.showSelectedContentIndex && (
            <a
              onClick={e => this.next_prev(e, "next")}
              title="Next"
              onclick="detailsvisit1();"
              style={{ paddingRight: 5, float: "right" }}
              className="collapsed"
              aria-expanded="false"
            >
              <i
                className="fa fa-arrow-circle-down"
                aria-hidden="true"
                style={{
                  float: "right",
                  marginTop: 10,
                  fontSize: 16,
                  marginRight: -10
                }}
              />{" "}
            </a>
          )}
          <span>
            {(this.state.showSelectedContent != undefined &&
              this.state.patient_investigations.length) > 0 && (
              <h5
                style={{
                  backgroundColor: "#44514E",
                  padding: "10px",
                  marginLeft: "-10px",
                  width: "105%",
                  color:"#ffffff",
                  marginTop: '0px',
                  marginBottom: 32
                }}
              >
                {moment(this.state.showSelectedContent.date).format(
                  "DD/MM/YYYY"
                )}{" "}
                - {this.state.showSelectedContent.test_name}{" "}
              </h5>
            )}

            <div
              style={{
                backgroundColor: "white",
                minHeight: "50px",
                marginTop: "-30px",
                whiteSpace: "pre-line"
              }}
            >
              {this.state.showSelectedContent != undefined &&
                this.state.patient_investigations.length > 0 &&
                this.state.showSelectedContent.content}
            </div>
          </span>
        </div>
        {this.state.show_tab == "table_graph" && <Investigationtable />}
        {this.state.show_tab == "add_give_values" && (
          <Investigationaddvalues
            open={this.state.show_tab}
            close={this.closeAddValuesPopup}
          />
        )}
        {(this.state.showmovedimage) ?
          <ViewClinicalRecord sid={this.state.clickedId} showImg={this.state.showmovedimage} changeKey={this.changeKey} showfor='investigation'/> : ''}
       {(this.state.showmovednote) ? 
           <CorrespondenceEditor showmodal={this.state.showmovednote}  changeKey={this.changeKey} patient_id={this.props.redux_patientId} sid={this.state.clickedId} showfor='investigation'/>
           :''}
  
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    patient_investigations: data => dispatch(api_patient_investigations(data)),
    modify_investigations: data => dispatch(api_modify_investigations(data)),
    add_investigations: data => dispatch(api_addInvestigation(data)),
    gettype:data =>dispatch(api_get_type(data))
  };
};

const mapStateToProps = state => {
  let redux_patientId =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].patient_id
      : "";
  return {
    redux_patientId
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestigationReports);
