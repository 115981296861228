import React, { useState, useEffect, Component, useCallback } from "react";  
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import { loadEditform, deleteItem } from "./../../../DS/services";
import { confirmAlert } from "react-confirm-alert";

import moment from "moment";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import {
  SelectionState,
  PagingState,
  CustomPaging,
  SortingState,
  FilteringState,
  IntegratedSelection,
  IntegratedSorting,
  
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  VirtualTable,
  TableFilterRow,
} from "@devexpress/dx-react-grid-bootstrap4";
//import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
//import { Loading } from '../../../theme-sources/bootstrap4/components/loading';
import './bgridServer.css';

//const URL = 'https://js.devexpress.com/Demos/Mvc/api/DataGridWebApi/Orders';
 
const useForceUpdate2 = () =>   useState()[1];
function onSelectRow(row, isSelected, e) {
  if (isSelected) {
    alert(`You just selected '${row['name']}'`)
  }
}
 
const selectRowProp = {
  mode: 'checkbox',
  clickToSelect: true,
  unselectable: [2],
  selected: [1],
  onSelect: onSelectRow,
  bgColor: 'gold'
};
const getRowId = row => row.id;
const styles = {
  firstColor: {
    backgroundColor: '#f4f4f4 !important',
  },
  secondColor: {
    backgroundColor: 'red !important',
  },
};

  
export default props => {
  
  //let dateColumnprev=props.viewgrid.dateCol;
  //let actionPeriod=new Date().getFullYear() +"-" +(new Date().getMonth() + 1) +"-" +new Date().getDate();
  let dateformatfix=new Date().getMonth() + 1
  dateformatfix=dateformatfix < 10 ? '0' + dateformatfix : '' + dateformatfix;
  let actionPeriod= new Date().getDate() +"-" +(dateformatfix) +"-"+new Date().getFullYear() 
  let selectedId=''; 
  const setSelectedId=(clickedid,e)=>
    {
     //let classs='';
     //console.log(clickedid)
     // selectedId=clickedid;
      
     if(props.viewgrid.cellClicked!='')
     {
     // alert(e.target.className)
      selectedId=clickedid.clinical_id;
      //e.target.className += ' selectedBg ';
    //  alert(selectedId)
      props.viewgrid.cellClicked(clickedid,'rowclicked');
     }
    // return selectedId;
      //classs += 'selectedBg ';
      //return classs;
    }
     
      const  setTrClass = (y,z, id,r,det) =>{
       // alert(setSelectedId(det))
        let classs = '';
        if(props.viewgrid.isDateGroup==true)
        {
        classs += z%2==0?'firstColor ':'secondColor ';
        }
        if(props.viewgrid.isYearSeparator==true)
        {
        classs += y ? ' yearSeperator ': '';
        }
        classs += id == selectedId ? ' selectedBg ': '';
       if(props.viewgrid.highlightprev==true)
       {
         console.log(props.viewgrid.highlightprev)
            classs +=r==true ? 'highlightprev':'';
        }
        return classs;
    }
    
    
  const forceUpdate =  useForceUpdate2();
  
  
  const URL = props.viewgrid.baseurl;
  const [columns] = useState(props.viewgrid.columns);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  let pSize = 0;
  if (props.viewgrid.isPagination) {
    pSize = props.viewgrid.pagesize;
  } else {
    pSize = 1000;
  }
  const [pageSize] = useState(pSize);

  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lastQuery, setLastQuery] = useState();
  const [filters, setFilters] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "name", align: "right" },
    { columnName: "name", align: "right" }
  ]);
  const [sorting, setSorting] = useState(props.viewgrid.sortinit);
  
  //CHECKBOX LOGIC
  //const [selection, setSelection,toggleSelection] = useState([1]);

  //if selection happens
  //const [selection, setSelection,toggleSelection] = useState([4]);
  const [selection, setSelection,toggleSelection] = useState([]);
  //const [selection, setSelection] = useState([3,2]);
  let selectedid='';
  let pk=props.viewgrid.query.primary_key;
  let dateColumn=props.viewgrid.dateCol;
  let selecteddate=''
 
  if(selection!='') 
  {
    
    for (const [index, value] of selection.entries()) {
      console.log(">>>>index",selection[index], value, rows, rows.length>0?rows[value][pk]+','+selectedid:'');
       // selectedid=rows[value][pk]+','+selectedid
        selectedid=rows.length>0?rows[value][pk]+','+selectedid:'';
      //  selecteddate=rows[index][dateColumn]
      }
        if(props.viewgrid.groupDateSelection="abc")
        {
          console.log("if");
          for (const [index, value] of rows.entries()) {
          
            //console.log("Date",selecteddate)
          //  console.log(index,value)
            //console.log("date column",rows[index][dateColumn])
           if(selecteddate==rows[index][dateColumn])
            {
              console.log("In if")
              selectedid=rows[index][pk]+','+selectedid
            //  setSelection([{5:{clinical_id: "20", description: "e", details: "d", image_date: "2019-12-31"}}]);
             /* const allowedState = [
                { index: 5, value: "24" }
              ];*/
            
            }
          }
        //  selection=new Set(selection)
         
        }
        else
        {
          console.log("else");
          for (const [index, value] of selection.entries()) {
             // selectedid=rows[value][pk]+','+selectedid
              //selecteddate=rows[value][dateColumn]
              
           }
        }
       // checkboxstatus();
          console.log(">>>> selected ids",selectedid);
          console.log(">>>>length",selection.length);
        selectedid = selectedid.replace(/,\s*$/, "");
        props.viewgrid.deleteFunction(selectedid,'selection',selection.length,selection);
  }
  else
  {
        selectedid = '';
        props.viewgrid.deleteFunction(selectedid,'selection',selection.length);
  }
  

   let z=0;
  const TableRow = ({value, row, ...restProps }) => (
  
      <Table.Row
        {...restProps}
          // eslint-disable-next-line no-alert
        onClick={(e) => setSelectedId(row,e)}
    //   className={secondColor}
          rec1={rows.indexOf(row)>0  && row[dateColumn]===rows[rows.indexOf(row)-1][dateColumn] ? z: z++}
           className={setTrClass(rows.indexOf(row)>0  && moment(row[dateColumn]).format('YYYY')===moment(rows[rows.indexOf(row)-1][dateColumn]).format('YYYY') ? false: true,z, row.pk,((row[dateColumn] < actionPeriod) && (row[dateColumn] !== actionPeriod)) ? true : false,row) } 
          
  />
  
 
  );
  
  const HighlightedCell = ({ value, style, ...restProps }) => (
    <Table.Cell
      {...restProps}
      style={{
        backgroundColor: value < 5000 ? "red" : undefined,
        ...style
      }}
    >
      {console.log(restProps.row.name)}
      <span
        style={{
          color: value < 5000 ? "white" : undefined
        }}
      >
        {props.viewgrid.actions.map((action, index) => (
          <button
            style={{marginRight:'5px'}}
            key={action.icon}
            type="button"
            className={`btn btn-xs btn-${action.buttonClass}`}
            onClick={() => {
              var custom_var = "restProps.row."+action.passParam;
            
              handleButtonItem(
                eval(custom_var),
                action.function,
                action.isDefault
              );
            }}
          >
            <i className={`fa ${action.icon}`} aria-hidden="true"></i>{" "}
            {action.text}
          </button>
        ))}
        {/*onClick = {() => this.props.handleButton(1, "toDO")} */}
      </span>
    </Table.Cell>
  );

  const Cell = props => {
    const { column } = props;
    if (column.name === "action") {
       
      return <HighlightedCell {...props} />;
    } else {
       
    }
    return <Table.Cell {...props} />;
  };

  const getQueryString_page = () =>
    `${URL}?take=${pageSize}&skip=${pageSize * currentPage}`;

  const getQueryString = () => {
    //let queryString = `${URL}?`;

    let queryString = `${URL}&`;

    const columnSorting = sorting[0];
    if (columnSorting) {
      const sortDirectionString =
        columnSorting.direction === "desc" ? " desc" : "";
      queryString = `${queryString}orderby=${columnSorting.columnName}${sortDirectionString}`;
    }
    console.log(rows);
    let filter = filters
      .reduce((acc, { columnName, value}) => {
        for (const [index, value] of props.viewgrid.query.cols.entries()) {
           let newcolname=value.split(" as ")
           if(columnName== newcolname[1])
           {
            columnName=newcolname[0]
           }
       }
        acc.push(
          `["${columnName}", "contains", "${encodeURIComponent(value)}"]`
        );
       
        return acc;
      }, [])
      .join(',"and",');

    if (filters.length > 1) {
      filter = `[${filter}]`;
       const currentPage = 0;
    }

    //    return queryString;
    return `${queryString}&filter=${filter}&take=${pageSize}&skip=${pageSize *
      currentPage}`;
  };

  const [count, setCount] = useState(0);

  const loadData = () => {
   
    const queryString = getQueryString();
    if (queryString !== lastQuery && !loading) {
      console.log("inside if");
      setLoading(true);
      
      
      fetch(queryString, {
        method: "post",
        body: JSON.stringify(props.viewgrid.query)
      })
        .then(response => response.json())
        .then(data => {
         
          setRows(data.content);
          setTotalCount(data.totalCount);
          setLoading(false);
        })
        .catch(() => { setLoading(false)});
      setLastQuery(queryString);
      
    }
    else {
      console.log("inside else");
    }
   // alert(props.viewgrid.getAllIds);
    if(props.viewgrid.getAllIds==true)
    {
      let allids=[];
      for (const [index, value] of rows.entries()) {
       console.log(rows[index])
        allids.push(rows[index])
        }
        if(props.viewgrid.getallId!='')
        {
          props.viewgrid.getallId(allids);
        }
      }
  };

  useEffect(() => {
   
    console.log("use effect"); 
    if(selection!='')
  {
    console.log("use effect loop")
   // setSelection(selection, { rowIds: selection, state: true });
   // setSelection(selection,0,true);
   // setSelection([5])
   console.log("******",selection)
  }
    loadData()
  });
  
  const handleButtonItem = (selectedId, todo, isDefault) => {
    
    if (isDefault == true) {
      
      if (todo == "edit") {
        let editPostData = {
          prop: props.edit_form,
          selectedId: selectedId
        };
        loadEditform(editPostData).then(res => {
          props.showeditform(res.data.content);
        });
      }

      if (todo == "delete") {
        let deletePostData = {
          prop: props.delete,
          selectedId: selectedId
        };

        if(props.delete.isConfirmBeforeDelete == true){

        confirmAlert({
          title: props.delete.confirmationTitle,
          message: props.delete.confirmationMessage,
          buttons: [
            {
              label: "Yes",
              onClick: () => {
               deleting(deletePostData);
              }
            },
            {
              label: "No",
              onClick: () => {}
            }
          ]
        });
      } else {
        deleting(deletePostData);



      }
      
         
      }
    } else {
      //this.props.handleButton(selectedId, todo);
    }
  };

 const deleting = (deletePostData) => {
    deleteItem(deletePostData).then(res => {
      if (res.status === 200) {
        
        //forceUpdate();
       // useForceUpdate();
       // loadData();

       //loading new records
       const queryString = getQueryString();
       setLoading(true);

      
      fetch(queryString, {
        method: "post",
        body: JSON.stringify(props.viewgrid.query)
      })
        .then(response => response.json())
        .then(data => {
        
          setRows(data.content);
          setTotalCount(data.totalCount);
          setLoading(false);
        })
        .catch(() => { setLoading(false)});
      setLastQuery(queryString);
      
      NotificationManager.success(props.delete.successMessage);
       //  setCount(count + 1);
       //render();
       
      } else {
        NotificationManager.error(
          props.delete.errorMessage
        );
      }
    });
  }

 

  return (
    <div className="box">
   {/*} <div className="row" align="right" style={{marginRight:'100px',marginBottom: '10px'}}>
        <select  onChange={(e) => this.setState({selected_patient_visit_reason: e.target.value })}>
          <option value="5" key="5">Show 5 Entries</option>
          <option value="10" key="10">Show 10 Entries</option>
          <option value="50" key="50">Show 50 Entries</option>
          <option value="100" key="100">Show 100 Entries</option>
        </select>
        </div>*/}
    <div className="card" style={{ position: "relative" }}>
     
      <b> {props.viewgrid.viewtabletitle} </b>
      <NotificationContainer />
      <Grid rows={rows} selectRow={selectRowProp} columns={columns} className={'table', 'table-bordered', 'table-striped', 'dataTable', 'no-footer'}
      >
        {/*columnFilteringEnabled = {false}*/}
        <FilteringState
          columnFilteringEnabled={props.viewgrid.isFiltering}
          onFiltersChange={setFilters}
        />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
        />
      
        <SelectionState  
            selection={selection}
            onSelectionChange={setSelection}
            toggleSelection={toggleSelection}
          />
        <CustomPaging totalCount={totalCount} />
        {/*<SortingState sorting={sorting} onSortingChange={setSorting} />*/}
        <SortingState
          defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
        />
        <IntegratedSorting />
        <IntegratedSelection /> 
      
        <VirtualTable columnExtensions={tableColumnExtensions} className={'table-striped'} />
        {props.viewgrid.isActions ? <VirtualTable className={'table-striped'} cellComponent={Cell} style={{color:'yellow'}} /> : <VirtualTable  rowComponent={TableRow} style={{color:'red'}}/>}

        <TableHeaderRow showSortingControls />
        {props.viewgrid.isFiltering && <TableFilterRow />}
        {props.viewgrid.isPagination && <PagingPanel />}
        {props.viewgrid.isSelection && <TableSelection showSelectAll  /> }
        
      </Grid>
      {/*loading && <Loading />*/}
    </div>
    </div>
  );
}; 
