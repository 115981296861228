import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import { 
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import {wflow} from './../../../DS/general';
import axios from 'axios';
import  {api_patientdetails_savecomments, api_patientdetails_saveBookingDetailsapi, 
    api_patientdetails_savePatientDetails } from '../../../DS/DS.Appointments';
    import Allergies from "./allergies";
class ListAllergy extends Component {
  constructor(props) {
    super(props);
   // console.log(">>>"+JSON.stringify(props))
   {/* this.state = {
     patient_id : this.props.selectedPatientDetails.patient_id,
      detailsAboutPatient: this.props.selectedPatientDetails.detailsAboutPatient,
      comments: this.props.selectedPatientDetails.comments,
      detailsAboutBooking: this.props.selectedPatientDetails.detailsAboutBooking,
      
    };*/}
      this.state = {
     crud: {
      jsonGridVersion: "v2.0",
      grid: true,
      viewgrid: {
        isVisible:true,
        isPagination: true,
        isFiltering: true,
        isSelection:true,
        deleteFunction:this.deleteClick,
        isDateGroup:false,
        isYearSeparator:false,
        dateCol:'image_date',
        cellClicked:this.onRowClicked,
        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "clinical_images_tb",
          cols: [
            "clinical_id",
            "description",
            "details",
            "image_date"
            
          ],
         where: [
            {
              type: "and",
              key: "patient_id",
              operator: "=",
              value: this.props.redux_patientId
         }],
          primary_key:"clinical_id",
        },
        viewtabletitle: "",
        columns: [
          { name: "image_date", title: "Date" },
          { name: "description", title: "Description" },
           { name: "details", title: "Details" }
        
        ],
        rows: [],
        sortinit: [{ columnName: "details", direction: "asc" },{ columnName: "description", direction: "asc" }],
        pagesize: 5
      }
    },
    selectedRec:'',
    viewEdit:false,
    selectedRecLength:0,
    key:"03",
    recordNote:false,
    clickedId:'',
    showImg:false
  };
  }
 componentWillMount()
 {
   
 }

  render() {
    return (
      <Popup
      modal
       closeOnDocumentClick={false}
         trigger={<button id="session-timeout-dialog-logout" data-toggle="modal" data-target="#autofill" type="button" className="btn btn-default" style={{margin: '0px 10px 0px',padding:'5px 10px'}}>Add Reactions</button>} position="right center"   modal
      >
        {close => (
          <div>
            <div className="modal-header " style={{ backgroundColor: '#ddd', padding: '5px 10px'}}>
              <h5 style={{backgroundColor: '#ddd', float: 'left', width: '50%', padding: '5px 10px', fontSize: '16px'}}>Allergies</h5>
                <button type="button" className="close"  onClick={() => close()} data-dismiss="modal" aria-label="Close" style={{margin:'10px 0 0 0'}}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body past-history-wrapper">
               <div className="row" style={{marginTop: '30px'}}>
                <div className="col-md-12">
                  <table style={{width: '100%'}}>
                        <tbody>
                            <tr style={{backgroundColor: '#ddd'}}>
                             <th style={{padding: '5px', width: '30%'}}>&nbsp;Item</th>
                             <th style={{padding: '5px 0px', width: '30%'}}>&nbsp;Reaction</th>
                             <th style={{padding: '5px 0px', width: '40%'}}>&nbsp;Severity</th>
                            </tr>
                            {this.props.selectedPatientDetails.allergies != undefined ? this.props.selectedPatientDetails.allergies.map((allergy) => (
                                    <tr key={allergy.allergy_name}>
                                        <td style={{ width: '30%'}}>&nbsp;{allergy.allergy_name}</td>
                                        <td style={{ width: '30%'}}>&nbsp;{allergy.reaction}</td>
                                        <td style={{ width: '30%'}}>&nbsp;{allergy.severity}</td>
                                    </tr>
                                )) : <tr><td colSpan='3' style={{padding: '5px', width: '30%'}}>No Allergies</td></tr>}
                        </tbody>
                    </table>
                </div>
               </div>
              <div className="row" style={{marginTop: '30px'}}>
                <div className="col-md-2">
                  Comment
                </div>
                <div className="col-md-10">
                  <textarea
                        style={{
                          float: 'left',
                          width: '100%',
                          height: 100,
                          padding:'10px',
                          border: '1px solid #cccccc',
                          marginBottom:'20px'
                        }}
                        onChange={e =>
                          this.setState({ details: e.target.value })
                        }
                        value={this.state.details}
                      />
                </div>
               
               </div>
              
              </div>
              <div className="modal-footer">
               {/*<button
                  type="button"
                  className="btn btn-default"
                  onClick={()=>{this.onClickHandler() ;close(); }}>Add</button>
               */}
                 <Allergies popupName="Allergies/Reactions"  />
               <button
                  type="button"
                  className="btn btn-default"
                  onClick={()=>{this.onClickHandler() ;close(); }}
              >
                Edit
              </button>
               <button
                  type="button"
                  className="btn btn-default"
                  onClick={()=>{this.onClickHandler() ;close(); }}
              >
                Delete
              </button>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={()=>{this.onClickHandler() ;close(); }}
              >
                Save
              </button>
              
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => close()}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state);
	 let patientDetails = (state.getDrAppointments.getPatientDetails) ? (state.getDrAppointments.getPatientDetails) : [];
   let selectedPatientDetails;
   if(patientDetails.length>0){
   selectedPatientDetails = patientDetails[0];
   } else {
   selectedPatientDetails = '';
   }
   return {
     selectedPatientDetails
   }

}

export default connect(
    mapStateToProps
)(ListAllergy);
//export default ListAllergy;
