import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { api_getTitle,api_getGender} from "../../../DS/DS.Appointments";
import DatePicker from "react-datepicker";
import {get_selected_states} from '../../../DS/DS.EPC'
import Showhelp from './../../showHelp';

class dailyLiving extends Component {
    constructor(props)
    {
        super(props)
        this.state={

        }
    }

    render() {
        return (
            <div className="col-sm-12 mb-1 mt-1">
                <div style={{padding:'10px 10px 10px 0', overflowY: 'auto' }}>
                    <h6>Have you noticed a change in the patient's daily living and behaviour?</h6>
                    <h6>Tick only those boxes which are relevant to this patient.</h6>
                    <h4 style={{}}>Basic activities od daily living:</h4>
                    <div className="col-sm-12">
                        <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Feeding</label>
                        </div>
                        <div className="col-sm-8" style={{float: 'left',marginTop: '15px'}}>
                            <input type="checkbox" value="Right" 
                                onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Normal 
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="No"
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Slightly impaired
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="Both Right Left"
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Significantly impaired
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Bathing</label>
                        </div>
                        <div className="col-sm-8" style={{float: 'left',marginTop: '15px'}}>
                            <input type="checkbox" value="Right" 
                                onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Normal 
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="No"
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Slightly impaired
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="Both Right Left"
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Significantly impaired
                        </div>
                    </div>



                    <div className="col-sm-12">
                        <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Dressing</label>
                        </div>
                        <div className="col-sm-8" style={{float: 'left',marginTop: '15px'}}>
                            <input type="checkbox" value="Right" 
                                onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Normal 
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="No"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Slightly impaired
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="Both Right Left"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Significantly impaired
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Toileting</label>
                        </div>
                        <div className="col-sm-8" style={{float: 'left',marginTop: '15px'}}>
                            <input type="checkbox" value="Right" 
                                onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Normal 
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="No"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Slightly impaired
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="Both Right Left"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Significantly impaired
                        </div>
                    </div>


                <div className="col-sm-12">
                        <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Mobility</label>
                        </div>
                        <div className="col-sm-8" style={{float: 'left',marginTop: '15px'}}>
                            <input type="checkbox" value="Right" 
                                onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Normal 
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="No"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Slightly impaired
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="Both Right Left"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Significantly impaired
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Peronal care</label>
                        </div>
                        <div className="col-sm-8" style={{float: 'left',marginTop: '15px'}}>
                            <input type="checkbox" value="Right" 
                                onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Normal 
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="No"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Slightly impaired
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="Both Right Left"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Significantly impaired
                        </div>
                    </div>

                &nbsp;
                <hr style={{backgroundColor:'#ddd', height:'1px', border:'none'}}/>
                <h4 style={{}}>Instrumental activities od daily living:</h4>
                <div className="col-sm-12">
                        <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Using telephone</label>
                        </div>
                        <div className="col-sm-8" style={{float: 'left',marginTop: '15px'}}>
                            <input type="checkbox" value="Right" 
                                onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Normal 
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="No"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Slightly impaired
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="Both Right Left"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Significantly impaired
                        </div>
                    </div>               
                    
                    <div className="col-sm-12">
                        <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Managing finances</label>
                        </div>
                        <div className="col-sm-8" style={{float: 'left',marginTop: '15px'}}>
                            <input type="checkbox" value="Right" 
                                onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Normal 
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="No"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Slightly impaired
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="Both Right Left"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Significantly impaired
                        </div>
                    </div>  

                    <div className="col-sm-12">
                        <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Managing medications</label>
                        </div>
                        <div className="col-sm-8" style={{float: 'left',marginTop: '15px'}}>
                            <input type="checkbox" value="Right" 
                                onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Normal 
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="No"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Slightly impaired
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="Both Right Left"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Significantly impaired
                        </div>
                    </div>


                    <div className="col-sm-12">
                        <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Shopping</label>
                        </div>
                        <div className="col-sm-8" style={{float: 'left',marginTop: '15px'}}>
                            <input type="checkbox" value="Right" 
                                onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Normal 
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="No"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Slightly impaired
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="Both Right Left"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Significantly impaired
                        </div>
                    </div>




                <div className="col-sm-12">
                        <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Housekeeping</label>
                        </div>
                        <div className="col-sm-8" style={{float: 'left',marginTop: '15px'}}>
                            <input type="checkbox" value="Right" 
                                onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Normal 
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="No"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Slightly impaired
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="Both Right Left"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Significantly impaired
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Cooking</label>
                        </div>
                        <div className="col-sm-8" style={{float: 'left',marginTop: '15px'}}>
                            <input type="checkbox" value="Right" 
                                onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Normal 
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="No"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Slightly impaired
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="Both Right Left"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Significantly impaired
                        </div>
                    </div>


                    <div className="col-sm-12">
                        <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Transportation and driving</label>
                        </div>
                        <div className="col-sm-8" style={{float: 'left',marginTop: '15px'}}>
                            <input type="checkbox" value="Right" 
                                onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Normal 
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="No"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Slightly impaired
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="Both Right Left"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Significantly impaired
                        </div>
                    </div>


                    <div className="col-sm-12">
                        <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Work</label>
                        </div>
                        <div className="col-sm-8" style={{float: 'left',marginTop: '15px'}}>
                            <input type="checkbox" value="Right" 
                                onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Normal 
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="No"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Slightly impaired
                            &nbsp; &nbsp;
                            <input
                            type="checkbox"
                            value="Both Right Left"
                            
                            onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                            /> Significantly impaired
                        </div>
                    </div>
                </div>
                <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                            marginRight: "5px",
                            width: "70px",
                            height: "35px",
                            }}
                            onClick={(e) => {
                            this.savePathology(1);
                            }}
                            data-dismiss="modal"
                        >
                            Save
                        </button>
            </div>


         );
    }
}

export default dailyLiving ;
