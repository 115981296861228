import React, { Component } from 'react';
import {api_loadhelplink} from './../DS/DS.Help';
import { connect } from "react-redux";
class showhelp extends Component {
    state = { linkprops: ""  }
    componentDidMount(){
        this.props.loadhelplink({short_text: this.props.gotohelp}).then(res => {
            this.setState({ linkprops: res.data.content });
          });
          console.log("help", this.state);
    }
    showalert = (e) => {
        e.preventDefault();
        alert('Help Document Not Found');
    }
    render() { 
        return ( <span> <a href={this.state.linkprops[0]!=undefined?this.state.linkprops[0].link:'#'} 
        onClick={e=>
            this.state.linkprops[0]==undefined && this.showalert(e)
        }
        target="_blank"><i class="fa fa-question-circle"></i></a></span> );
    }
}


const mapDispatchToProps = dispatch => {
    return {
      
      loadhelplink: id =>
        dispatch(api_loadhelplink(id)),
      
    };
  };
export default connect(null, mapDispatchToProps)(showhelp);
 
//export default showhelp;