import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {api_list_reason} from '../../../DS/DS.ListPage';
import {api_add_group} from '../../../DS/DS.MessageGroup';
class AddUserToGroup extends Component {
  constructor(props) {
    super(props);
    this.state={
                open:this.props.showModal,
                errors:[],
                groupname:''
            }
    }
    componentWillMount=()=>
    {
       
    }
    handleValidation(){
        let errors = {};
        let formIsValid = true;
    
        //Reason name
        if(!this.state.groupname){
        formIsValid = false;
        errors["grpname"] = "Cannot be empty";
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    onClickHandler=(e)=>
    {
        e.preventDefault();
        if(this.handleValidation()){
            const reasonData={
                    reason:this.state.groupname,
                    user_id:this.props.redux_userId
                }
                    this.props.addGroup(reasonData).then(
                    req=>
                    {
                        if(req.data.status=="Duplicate"){
                                
                           NotificationManager.error('Duplicate group cannot be created',)
                        }
                        else if(req.data.status=="Success")
                        {
                            
                            NotificationManager.success('New group created',)
                        }
                        else{
                            
                            NotificationManager.error('Error encountered while creating group',)
                        }
                    this.props.reloadPage()
                }        
                
                        
                );
        }
    } 
    
  render() {
    return (
      <Popup open={this.state.open} closeOnDocumentClick={false} position="right center"   modal>
        {close => (
            <div>
                <div className="modal-header">
                    <span className="modal-title-2" id="exampleModalLabel"> Message group   </span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">

                    <div className="row row-distance">
                        <div className="col-sm-12"> 
                            <label style={{marginTop:'15px'}}> &nbsp;  &nbsp; Enter the name of the group : </label>
                        </div>
                    </div>
                    <div className="row row-distance">
                        <div className="col-sm-12">
                            <div className="form-group">                                                   
                                <input type="text" name="text" className="form-control" value={this.state.groupname} onChange={(e)=>this.setState({groupname:e.target.value})}/>
                                <span className="error" style={{color:'red'}}>{this.state.errors["grpname"]}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-sm-8"> </div>
                        <div className="col-sm-4">
                            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={(e)=>this.onClickHandler(e)}> Save </button>
                            <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.reloadPage()}>Cancel</button> 
                        </div>
                    </div>
                </div>
            </div>
        )}
     </Popup>
    )}
}
const mapDispatchToProps = dispatch => {
    return {
        listCategoryReason : data => dispatch(api_list_reason(data)),
        addGroup: data =>dispatch(api_add_group(data))
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(AddUserToGroup);
