import React, { Component } from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchDoctors from './../Correspondence/searchDoctors';
import CommonGrid from "./../../crudcomponentV2/combinedcrud";
import { confirmAlert } from 'react-confirm-alert';
import AddCareTask from './../../superadmin/manageCarePlans/addCareTask';
import { api_list_tasks, api_addenchance } from "./../../../DS/DS.EPC";
import Showhelp from './../../showHelp';
// import cicon from "./../../../assets/img/cicon.png";
class TaskPopup extends Component {
    constructor(props)
    {
        super(props)
        this.state={
            from_guid: "",
            selected_doc_id: "",
            selected_doc_mobile: "",
            selected_doc_address: "",
            selected_doc_category: "",

            no_of_visits: "",
            addTask:false,
            editTask:false,
            taskSelected:'',
            taskSelectedLength:-1,
            searchDoc: "",
            duedate: new Date(),

    taskCrud:
    {
      jsonGridVersion: "v2.0",
      grid: true,
      viewgrid: {
        isVisible:true,
        isPagination: true,
        isFiltering: true,
        isSelection:true,
        deleteFunction:this.removeTask,
        isDateGroup:false,
        isYearSeparator:false,
        dateCol:'',
        cellClicked:'',
        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "care_plan_task_tb",
          cols: [
            "task_id",
            "task_name"
          ],
          where: [
                {
                   type: "and",
                   key: "isDeleted",
                   operator: "=",
                   value: 0
                },
       ],
          primary_key:"task_id",
        },
        viewtabletitle: "",
        columns: [
          { name: "task_name", title: "Care plan task" }
        ],
        rows: [],
        sortinit: [{ columnName: "task_id", direction: "desc" }],
        pagesize: 10,
        getAllIds:false,
        getallId:''
      }

    },
  };
    }
    serachDoctor=()=>
    {
        this.setState({searchDoc:true})
    }
    reloadPage=()=>{
        this.setState({
            addGoal:false, 
            editGoal:false,
            addTask:false,
            editTask:false,
            openTemplate:false,
            addProblem:false,
            editProblem:false
            
           })
        this.setState({
            goalSelected:'',
            goalSelectedLength:-1,
            taskSelected:'',
            taskSelectedLength:-1,
            problemSelected:'',
            problemSelectedLength:-1
  
           })
        this.setState({reloadKey:Math.random()});
        this.list_tasks();
     }
componentDidMount(){
    this.list_tasks();
}

list_tasks = () => {
    this.props.list_tasks().then((res) => {
        this.setState({all_tasks: res.data.content});
    })
}


addenchance = () => {
    this.props.addenchance(this.state).then((res)=>{
        alert("Details Saved");
    })
}
     handleDate = date => {
        this.setState({ duedate: date });
        
      };


     removeTask=(ids,action,totalLength)=>
     {
     if(action=='selection')
     {
       this.state.taskSelected=ids;
       this.state.taskSelectedLength=totalLength;
     }
     if(action=='delete')
     {
         if (this.state.taskSelectedLength> 0) {
               const deleteData={tb_name:'care_plan_task_tb',ids:this.state.taskSelected,primary_key:'task_id'}
               confirmAlert({
                 title: '',
                 message: 'you are about to delete care plan task(s). Do you want to proceed?',
                 buttons: [
                 {
                     label: 'Yes',
                     onClick: () => {
                     this.props.apiDelete(deleteData).then(
                     res => this.reloadPage() ,
                     NotificationManager.success(
                     'Care plan task(s) deleted',
                     )
                   );
                 },
             },
             {
               label: 'No',
               onClick: () => { this.reloadPage()},
             },
           ],
         });
       } 
       else 
       {
         NotificationManager.error('Please select a care plan task to be deleted');
       }
     }
  }
   //edit task 
   editTask=()=>
   {
     // alert("IN")
       if(this.state.taskSelectedLength ==1 )
       {
          this.setState({editTask:true})
       }
       else
       {
           alert("Please select a record to modify")
       }
   }
   addCheck=()=>
   {
     alert("Please deselect the record to proceed")
   }
   apiRestoreDefault=(tbl)=>
   {
      const restorData={tb:tbl}
      this.props.apiRestoreDefault(restorData).then(
        res=> this.reloadPage(),
        NotificationManager.success(
         'Previous records restored Successfully',
         )
      )
   }
   handleInput = (e, key, value) => {
    this.setState({ [key]: value });
  }

   addTask = () => {


    //alert(this.state.custom_text);
    console.log("All Problems",this.state.all_tasks);
    //alert(this.state.goalSelected);
    let tasks_selected_arr = this.state.taskSelected.split(',');
    

   // if(tasks_selected_arr.length==1){

  


    let total_selected_tasks = (this.state.custom_text!="" && this.state.custom_text!=undefined) ? this.state.custom_text+", ": "";

    for(let i = 0; i< this.state.all_tasks.length; i++){
        for(let j =0; j<tasks_selected_arr.length; j++){
          if(this.state.all_tasks[i].task_id == tasks_selected_arr[j]){
              total_selected_tasks += this.state.all_tasks[i].task_name+ ", ";
          }
        }
        
    }
    //alert("categ"+this.state.selected_doc_category);
    this.props.addTasksEPC(total_selected_tasks, this.state.duedate, this.state.from_guid, this.state.selected_doc_id, this.state.selected_doc_address,  this.state.selected_doc_mobile, this.state.selected_doc_category, this.state.no_of_visits);
// }else {
//     alert("Select anyone Task");
// }
    //alert(total_selected_goals);
}
addSelectedDoc=(id)=>
{
    this.setState({searchDoc:false})
    if(id!='')
    {
    this.setState({
      selected_doc_id:id.contact_id,
      from_guid:id.display+''+id.first_name+'.'+id.surname,
      selected_doc_address: id.address,
      selected_doc_mobile: id.ah_phone+ " "+ id.mobile+ " " + id.work_phone,
      selected_doc_category: id.category_name
    })
    }
}

  render() {
    return (
      <div class="invesvalue">
         <Popup open={this.props.open} modal closeOnDocumentClick={false}>
        <div style={{ width:'800px', marginLeft:'250px', backgroundColor:'#FFF', float:'left'}}>
        <div class="modal-header">
          <div className="popup-title">
            <span> Add Task <Showhelp gotohelp="add_task_help"/></span> 
            {/* <Showhelp gotohelp="investigation_give_values"/> */}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => this.props.close()}>
                ×
              </span>
            </button>
            </div>
            </div>

            <div className="modal-body addinvestigation "
                style={{ height: "750px" }}
            >
            <div
          className="col-sm-12"
       
          style={{overflow: "auto" }}
          id="Graphdetails"
        >
          {/* <h5
            style={{
              backgroundColor: "#f4f4f4",
              padding: "5px 10px",
              marginBottom: 10
            }}
          >
             
          </h5> */}
          <div    id="ivntableadd" className="form-group row">
       
            {/* <label className="col-sm-3 col-form-label text-left">
              Family Name:
            </label>
            <div className="col-sm-3">
              <input type="text" className="form-control form-control-sm" />
            </div> */}
          </div>

          <div className="row">
          <div className="col-sm-3 mt-3 pr-0">
          <label>Enter Task or Select From List:</label>
          </div>
          <div className="col-sm-9 pl-0">
              <input className="form-control form-control-sm rq-form-elemen" type="text" onChange={e => this.handleInput(e, "custom_text", e.target.value)}/ >
              </div>
          </div>

              <div className="form-group row">
                                             <div className="col-lg-12 m-t-60 mb-3" style={{textAlign:'right'}}>
		                                             <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary btn-xs" data-toggle="modal" data-target="#care-plan-goals-add-btn"  onClick={(e)=>{this.state.taskSelectedLength >0 ?this.addCheck():this.setState({addTask:true})}}>Add</button>
		                                             <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary btn-xs" data-toggle="modal" data-target="#care-plan-goals-edit-btn" onClick={(e)=>this.editTask()}>Edit</button>
                                                   <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary btn-xs" onClick={(e)=>this.removeTask('','delete','')}>Remove</button>
                                                   {/* <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{marginRight:'7px'}} onClick={()=>this.apiRestoreDefault('care_plan_task_tb')}>Restore defaults</button> */}
	                                             </div>
	                                                <div className="col-sm-12 col-md-12 col-xl-12 m-t-10 table-border">
	                                                   <div className="card" style={{paddingLeft: '0px', height:'150px', overFlowY:'scroll' }}>
	                                                      <div className="card-block tasktable">
                                                         <CommonGrid
                                                                    crud={this.state.taskCrud}
                                                                    key={this.state.reloadKey}
                                                        />   
	                                                      </div>
	                                                   </div>
	                                                </div>
                                                 
                                                    
                                                  </div>                  
                                                   

                                                   




                                                    {(this.state.searchDoc) ?
                                             <SearchDoctors searchDoc={this.state.searchDoc} changeKey={this.addSelectedDoc}/> : ''}
    <div className="row">
    <div className="col-sm-12">
    <div className="col-sm-4 mt-3">
    <label>No of Visits: </label>
        </div>
        <div className="col-sm-3">
        <input className="form-control" type="number" onChange={e => this.handleInput(e, "no_of_visits", e.target.value)} value={this.state.no_of_visits} />

        </div>
        </div>
        </div>

        <div className="row">
        <div className="col-sm-12">
        <div className="col-sm-4 mt-2 pr-0">
        <label>Enter Provider or Selected Provider :</label>
            </div>

    <div className="col-sm-3">
    <input type="text" className="form-control" onChange={e => this.handleInput(e, "from_guid", e.target.value)} value={this.state.from_guid} /> 
            </div>
            <div className="col-sm-4 mt-1">
            <button className="btn btn-primary btn-xs" onClick={()=> this.serachDoctor()} >Select Provider</button>
                                                    <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"   onClick={e => this.setState({from_guid: ""})}>Clear</button>
                </div>
                </div>
    </div>
    <div className="row">
    <div className="col-sm-12">
    <div className="col-sm-4">
    <label>Due Date:</label>
            </div>
            <div className="col-sm-3">
            <DatePicker className="form-control mt-0"
                      //ref={this.textInput}
                      ref={input => {
                        this.textInput = input;
                      }}
                      id="date-jumper"
                      inputRef={ref => {
                        this.textInput = ref;
                      }}
                      todayButton="Go to Today"
                      selected={this.state.duedate}
                      onChange={this.handleDate}
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      dateFormat="dd/MM/yyyy"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />

            </div>
            </div>

        </div>

                                                    

                                                   {this.state.addTask ?
                                                      <AddCareTask showModal={this.state.addTask} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                   {this.state.editTask ?
                                                    <AddCareTask showModal={this.state.editTask} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.taskSelected}/> :''}
                                               
                                            
        
        
        

               
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-xs"
                onClick={(e) => {
                  this.addTask(e);
                }}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
                onClick={() => this.props.close()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popup>
        
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
    return {
        list_tasks: data => dispatch(api_list_tasks(data)),
        addenchance: data => dispatch(api_addenchance(data))
      };
};
const mapStateToProps = state => {
  
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskPopup);
