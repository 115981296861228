import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { connect } from "react-redux";
import {
  api_getDrAppointments,
  api_getTimeslots,
  api_modifyAppointments,
  api_getPatientDetails,
  api_set_cut_copy_details
} from "./../../DS/DS.Appointments";
import Addappointment from "./add-appointment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Popup from "reactjs-popup";
import { wflow, wip } from "../../DS/general";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Viewcelldetails from "./viewcelldetails";
import cicon from "./../../assets/img/cicon.png";
import "./calendar.css";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import MoveItem from "./MoveItem";
import ViewAppointmentText from "./ViewAppointmentText";

class Calendarind extends Component {
  //**// State - Starts
  state = {
    tabshow: false,
    addAppointmentTab: false,
    showPatientDetails: false,
    editAppointmentDetails: false,
    calendardate: new Date(this.props.startDate),
    drId: this.props.doctor_id,
    drName: this.props.doctor_name,
    cutAppointmentId: "",
    timeslots: [],
    dataCollespe: false,
    selectedTableProp: {
      date: "",
      time: "",
      dr: ""
    },
    doctorProperties: {
      doctor_id: this.props.doctor_id,
      Firstname: this.props.doctor_name
    },
    stateSearchParam: {
      doctor_guid: this.props.doctor_id,
      start_datetime: this.props.drCalendarProps.start_datetime,
      end_datetime: this.props.drCalendarProps.end_datetime
    },
    modifyAppointments: "",
    cutAppointmentType: "",
    selected: {
      thisappointment: [],
      startDate: "",
      starttime: "",
      doctor_id: ""
    },
    selected_thisappointment: [],
    selected_startDate: "",
    selected_starttime: "",
    selected_doctor_id: "",
    is_move_item_open: false,
    is_view_text_open: false,
    is_view_cell_open: false
  };
  //**// State - ends

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
  }
  closeTabshow = e => {
    this.setState({ tabshow: "" });
  };
  isSkipable = (currentTimeSlot, minutes_per_appointment) => {
    let timeslots = this.state.timeslots;
    let split = minutes_per_appointment>5 ? minutes_per_appointment/5: 0;
    let position = 0;
    let isProceed=true;
    for(let i=0; i<timeslots.length; i++){
      position = i;
      if(timeslots[i].full == currentTimeSlot){

        console.log("POSITION individual ", currentTimeSlot, "mpa"+minutes_per_appointment, position, (split!=0? position%split: 0));
        let d = split!=0? position%split: 0;
        isProceed = d==0? true: false;
        break;
      }
      
    }

    return isProceed;
  }
  toggleViewCell = d => {
    this.setState({ tabshow: d });
    this.setState({
      is_view_cell_open: !this.state.is_view_cell_open
    });
    // alert(d);
  };

  handlecontext = () => {
    console.log(this.state);
    setTimeout(
      function() {
        //Start the timer
        console.log(this.state);
      }.bind(this),
      3000
    );
  };
  focusTextInput() {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    // this.textInput.current.focus();
    document.getElementById("date-jumper").focus();
    // this.textInput.focus();
  }

  //**// Component Did Mount starts
  componentDidMount() {
    var searchParam = {
      doctor_guid: this.state.drId,
      start_datetime: this.state.stateSearchParam.start_datetime,
      end_datetime: this.state.stateSearchParam.end_datetime
    };
    this.props.getTimeslots().then(timeslots => {
      this.setState({ timeslots: timeslots.data.content });
    });
    this.props.getDrAppointments(searchParam);
  }
  //**// Component Did Mount ends
  setRightClickOptions = (thisappointment, startDate, starttime, doctor_id) => {
    this.setState({ selected_thisappointment: thisappointment });
    this.setState({ currentcellappointments: thisappointment });
    this.setState({ selected_startDate: startDate });
    this.setState({ selected_starttime: starttime });
    this.setState({ selected_doctor_id: doctor_id });

    wflow(
      "setting rightclick",
      thisappointment,
      startDate,
      starttime,
      doctor_id
    );
  };

  handleScrollToElement2(event) {
    setTimeout(
      function() {
        //Start the timer
        wflow("scrolling to addmoredetail");
        const tesNode2 = ReactDOM.findDOMNode(this.refs.refaddmore);
        if (1) {
          tesNode2.scrollIntoView();
        }
      }.bind(this),
      2000
    );
  }

  setRightClickOptions2 = (
    thisappointments,
    startDate,
    starttime,
    doctor_id
  ) => {
    this.setState({ selected_thisappointment: thisappointments });
    this.setState({ currentcellappointments: thisappointments });
    this.setState({ selected_startDate: startDate });
    this.setState({ selected_starttime: starttime });
    this.setState({ selected_doctor_id: doctor_id });

    wflow(
      "setting rightclick",
      thisappointments,
      startDate,
      starttime,
      doctor_id
    );
  };
  toggle = this.toggle.bind(this);

  handleDate = date => {
    this.setState({ calendardate: date });

    var searchParam = {
      doctor_guid: this.state.drId,
      start_datetime: moment(date).format("YYYY-MM-DD") + "06:00:00",
      end_datetime: moment(date)
        .add(7, "days")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss")
    };

    this.props.getDrAppointments(searchParam);
    //this.props.loadDateCalendar(moment(date).format('YYYY-MM-DD'));
  };

  toggleMoveItem(e) {
    setTimeout(
      function() {
        //Start the timer
        this.setState({
          is_move_item_open: !this.state.is_move_item_open
        });
      }.bind(this),
      2000
    );
  }
  toggleViewText(e) {
    setTimeout(
      function() {
        //Start the timer

        wflow("currentcelldata", this.state.currentcellappointments);

        this.setState({
          is_view_text_open: !this.state.is_view_text_open
        });
      }.bind(this),
      1000
    );
  }
  // toggleViewCell(e) {
  //   this.setState({
  //     is_view_cell_open: !this.state.is_view_cell_open
  //   });
  // }

  //**// Helper : any date format to ddmmyyyy
  ddmmyyyy(date) {
    let current_datetime = new Date(date);
    let formatted_date =
      current_datetime.getDate() +
      "-" +
      (current_datetime.getMonth() + 1) +
      "-" +
      current_datetime.getFullYear();
    //console.log(formatted_date);
    return formatted_date;
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  addMoreAppointment = () => {
    confirmAlert({
      message: "You are creating multiple appointment in single slot.",
      title: "Are you sure?",

      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setTimeout(
              function() {
                //Start the timer
                this.getColumnProp(
                  [],
                  this.state.selected_startDate,
                  this.state.selected_starttime,
                  this.state.selected_doctor_id
                );
              }.bind(this),
              1000
            );
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    });
  };

  triggerPatientDetails = () => {
    setTimeout(
      function() {
        //Start the timer
        wflow(
          "trigger patient details appointment format",
          this.state.selected_thisappointment
        );
        this.getColumnProp(
          this.state.selected_thisappointment,
          this.state.selected_startDate,
          this.state.selected_starttime,
          this.state.selected_doctor_id
        );
      }.bind(this),
      1000
    );
  };
  triggerEditAppointments = () => {
   // alert(1);
    setTimeout(
      function() {


        if (
          new Date(moment().format("YYYY/MM/DD")) <
          new Date(this.state.selected_startDate)
        ) {
        //Start the timer
        this.editAppointmentDetails(
          this.state.selected_thisappointment,
          this.state.selected_startDate,
          this.state.selected_starttime,
          this.state.selected_doctor_id
        );
      } else {
        alert("Cannot perform this action.");
      }


      }.bind(this),
      1000
    );
  };

  editAppointmentDetails(thisappointment, date, time, doctor_id) {
  //  alert(2);
    if (thisappointment.length != 0) {
     // alert("if");
      this.setState({ editAppointmentDetails: true });
      this.setState({ showPatientDetails: false });
      this.setState({ addAppointmentTab: true });

      this.props.getPatientDetails({ patient_id: thisappointment });
    } else {
    //  alert("else");
      this.setState({ editAppointmentDetails: false });
      this.setState({ showPatientDetails: false });
      this.setState({ addAppointmentTab: true });
    }

    wflow("EDIT MODE", thisappointment, date, time, doctor_id);
  }
  //**// Get selected column Properties
  getColumnProp(thisappointment, date, time, doctor_id) {
    if (thisappointment.length != 0) {
      this.state.showPatientDetails = true;
      this.state.addAppointmentTab = false;
      this.state.editAppointmentDetails = false;
      this.setState({ selected_thisappointment: thisappointment });

      this.setState({ tabshow: "" });
      this.props.getPatientDetails({ patient_id: thisappointment });
    } else {
      this.state.showPatientDetails = false;
      this.state.addAppointmentTab = true;
    }

    var selectedTableProp = { ...this.state.selectedTableProp };
    selectedTableProp.date = date;
    selectedTableProp.time = time;
    selectedTableProp.dr = doctor_id;
    this.setState({ selectedTableProp });
    console.log("selected Column Prop");
    console.log(selectedTableProp);
    wflow(
      "CTYPE:DR selected cell item properties",
      thisappointment,
      date,
      time,
      doctor_id
    );
  }

  //**// On click collapse - tried but not working properly
  onClick = e => {
    this.setState({ dataCollespe: !this.state.dataCollespe });
  };

  //**// Helper : concatinate time and date => slot start time
  startdate(timeslot_starttime, date) {
    let slot_starttime = new Date(date + " " + timeslot_starttime);
    return slot_starttime;
  }

  //**// Helper : concatinate time and date => slot end time (15 mins added staticly)
  enddate(timeslot_starttime, date) {
    let slot_starttime = new Date(date + " " + timeslot_starttime);
    let slot_endtime = new Date(slot_starttime.getTime() + 15 * 60000);
    console.log(slot_endtime);
    return slot_endtime;
  }

  //**// Helper : removing time from startdate
  removeTime(start_date) {
    start_date = start_date.split(" ")[0];
    return start_date;
  }

  //**// Helper : string to js date format
  appointmentdatetime(startdatetime) {
    return new Date(startdatetime);
  }

  //**// Helper : rightclick add, edit - right now it is unused.
  handleClick(e, modifyAppointmentParam) {
    this.props.modifyAppointments(modifyAppointmentParam).then(a => {
      this.setState({ modifyAppointments: a.data.content });
    });
  }

  //**// Helper : Selecting a slot - starts
  toggleActive = j => {
    if (j === this.state.isActive) {
      this.setState({
        isActive: null
      });
    } else {
      this.setState({
        isActive: j
      });
    }
  };

  //**// Helper : Selecting a slot - ends

  //**// Helper : Scrolling to add appointment section
  handleScrollToElement(event) {
    const tesNode = ReactDOM.findDOMNode(this.refs.test);
    if (1) {
      tesNode.scrollIntoView();
    }
  }

  //**// Helper : RIGHTCLICK OPERATIONS - STARTS
  //**// Helper : Rightclick
  rightClick(app, action) {
    setTimeout(
      function() {
        //Start the timer
        console.log(this.state);
        app = this.state.selected_thisappointment;

        let modifyAppointmentParam = {
          app: app,
          action: action,
          ctype: this.props.ctype,
          dateSearchParam: {
            start_datetime: this.props.cdate
          },
          doctorSearchParam: {
            doctor_guid: this.props.doctor_id,
            start_datetime:
              new Date().getFullYear() +
              "-" +
              (new Date().getMonth() + 1) +
              "-" +
              new Date().getDate() +
              " 06:00:00",
            end_datetime: new Date(new Date().setDate(new Date().getDate() + 6))
          }
        };
        if (modifyAppointmentParam.action == "delete") {
          confirmAlert({
            title: "Do you really want to delete",
            message: "Are you sure to do this.",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  this.props.modifyAppointments(modifyAppointmentParam);
                  NotificationManager.success(
                    "Appointment Deleted Successfully!"
                  );
                }
              },
              {
                label: "No",
                onClick: () => {}
              }
            ]
          });
        } else {
          this.props.modifyAppointments(modifyAppointmentParam);
          NotificationManager.success(
            "Appointment Status Updated Successfully!"
          );
        }

        wflow("right click action", modifyAppointmentParam);
      }.bind(this),
      3000
    );
  }

  //**// Helper : RightclickCut - keep appointmentId in state
  rightClickCut(app, date, time, drId, type) {
    setTimeout(
      function() {
        //Start the timer
        app = this.state.selected_thisappointment.appoinment_id;
        this.setState({ cutAppointmentId: app });
        this.setState({ cutAppointmentType: type });
        this.props.set_cut_copy_details({cutAppointmentId: app, cutAppointmentType: type });
        wflow("rightclick cut", app, type);
        if(type=="cut"){
          NotificationManager.success(
            "Appointment Cut Successfully! Please Paste it on destination cell"
          );
        }
        if(type=="copy"){
          NotificationManager.success(
            "Appointment Copied Successfully! Please Paste it on destination cell"
          );
        }
        
      }.bind(this),
      1000
    );
    //set state cutAppointmentId
  }
  rightClickPaste(appId, date, time, drId, type) {
    if (appId != "") {
      setTimeout(
        function() {
          let canBook = true;
          if (new Date() > new Date(date + " " + time)) {
            alert("Couldn't Paste appointment in Past Date/time.");
            canBook = false;
          } else {
            canBook = true;
            if (this.props.drAppointments.length > 0) {
              for (let i = 0; i < this.props.drAppointments.length; i++) {
                if (
                  this.props.drAppointments[i].doctor_id ==
                    this.state.selected_doctor_id &&
                  this.props.drAppointments[i].patient_id ==
                    this.state.selected_thisappointment.patient_id &&
                  this.state.selected_startDate +
                    " " +
                    this.state.selected_starttime +
                    ":00" ==
                    this.props.drAppointments[i].start_time
                ) {
                  canBook = false;
                  alert(
                    "Couldn't book appointment, Already same person found on timeslot."
                  );
                }
              }
            }
          }

          if (canBook) {
            //  alert(time+"   "+ date);
            //Start the timer
            let modifyAppointmentParam = {
              cut_copy: type,
              action: "cut_copy_paste",
              appId: appId,
              date: date,
              time: this.state.selected_starttime,
              drId: this.state.selected_doctor_id,
              ctype: this.props.ctype,
              dateSearchParam: {
                start_datetime: this.props.startDate
              },
              doctorSearchParam: {
                doctor_guid: this.state.drId,
                start_datetime:
                  new Date().getFullYear() +
                  "-" +
                  (new Date().getMonth() + 1) +
                  "-" +
                  new Date().getDate() +
                  " 06:00:00",
                end_datetime: new Date(
                  new Date().setDate(new Date().getDate() + 6)
                )
              }
            };

            this.props.modifyAppointments(modifyAppointmentParam);

            wflow(
              "rightclick paste",
              this.state.cutAppointmentId,
              modifyAppointmentParam
            );
            NotificationManager.success("Appointment Pasted Successfully! ");
          }
        }.bind(this),
        4000
      );
    } else {
      alert("Please cut/copy to perform Paste");
    }
  }
  //**// Helper : RIGHTCLICK OPERATIONS - ENDS

  //**// RENDERING APPOINTMENT DATA FOR SLOTS - STARTS
  renderTableColumnData(current_date, starttime, doctor_id, j, minutes_per_appointment) {
    let startDate = current_date;
    let tableDataContent = "";
    let multiClrtableDataContent = [];
    let existTableData = 0;
    let classContent = "";
    let tableDataDoctorId = "";
    let tableDataStartTime = "";
    let thisappointment = [];
    let thiscellappointments = [];
    let status = "blacktext";
    if (this.props.drAppointments.length > 0) {
      for (let i = 0; i < this.props.drAppointments.length; i++) {
        if (
          this.removeTime(this.props.drAppointments[i].start_time) ==
            current_date &&
          this.startdate(starttime, this.props.drAppointments[i].date) <=
            this.appointmentdatetime(this.props.drAppointments[i].start_time) &&
          this.enddate(starttime, this.props.drAppointments[i].date) >
            this.appointmentdatetime(this.props.drAppointments[i].start_time)
        ) {
          wflow("inside cell drappointments ", this.props.drAppointments[i]);
          existTableData = 1;

          tableDataContent +=
            tableDataContent == ""
              ? this.props.drAppointments[i].Firstname
              : ", " + this.props.drAppointments[i].Firstname;
          status = this.props.drAppointments[i].status
            ? this.props.drAppointments[i].status
            : "blacktext";
          multiClrtableDataContent.push(
            <span style={{ padding: "5px" }} className={status}>
              {this.props.drAppointments[i].Firstname.slice(0, 3)}{" "}
            </span>
          );

          tableDataDoctorId =
            this.props.drAppointments[i].doctor_id != ""
              ? this.props.drAppointments[i].doctor_id
              : "x";

          tableDataStartTime = this.props.drAppointments[i].start_time;
          thisappointment = this.props.drAppointments[i];

          thiscellappointments.push(this.props.drAppointments[i]);
          if (this.props.drAppointments[i].status === "label-unavailable") {
            classContent = "label-unavailable";
          }
          if (this.props.drAppointments[i].status === "label-Ontheday") {
            classContent = "label-Ontheday";
          }
          if (this.props.drAppointments[i].status === "label-warning") {
            classContent = "label-warning";
          }
          if (this.props.drAppointments[i].status === "label-primary") {
            classContent = "label-primary";
          }
          if (this.props.drAppointments[i].status === "label-success") {
            classContent = "label-success";
          }
          if (this.props.drAppointments[i].status === "label-completed") {
            classContent = "label-completed";
          }
          if (this.props.drAppointments[i].status === "label-attend") {
            classContent = "label-attend";
          }
          if (this.props.drAppointments[i].status === "label-danger") {
            classContent = "label-danger";
          }
          if (this.props.drAppointments[i].status === "label-elsewhere") {
            classContent = "label-elsewhere";
          }
          //break;
        } else {
          existTableData = 0;
          //tableDataContent = putspace();
        }
      }
    } else {
      tableDataContent = putspace();
    }
    wflow("dr calendar currentdate", current_date);
    //**// Helper : Put empty space on slots to enable rightclick
    function putspace() {
      return <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>;
    }

    let tabledata = (
      <td
        className={thiscellappointments.length > 1 ? '' :classContent}
        style={
          this.state.isActive === j
            ? {
                outlineStyle: "solid",
                outlineColor: "blue",
                padding: "5px 10px",
                fontSize: "14px"
              }
            : { background: "", padding: "5px 10px", fontSize: "14px" }
        }
        key={j}
        //key={tableDataStartTime}
        rowSpan={minutes_per_appointment>5?minutes_per_appointment/5:1}
        // onClick={() => {
        //   this.getColumnProp(
        //     thisappointment,
        //     current_date,
        //     starttime,
        //     doctor_id
        //   );
        //   this.toggleActive(j);
        //   this.handleScrollToElement();
        // }}
        onDoubleClick={e => this.toggleViewText(e)}
        onClick={() => {
          thiscellappointments.length < 2 &&
            this.getColumnProp(
              thisappointment,
              current_date,
              starttime,
              doctor_id
            );

          thiscellappointments.length < 2 && this.toggleActive(j);

          thiscellappointments.length < 2 && this.handleScrollToElement();

          //  thiscellappointments.length<2 &&
          //  this.toggleViewCell();
        }}
      >
        <div className="ColumnContainer">
          {thiscellappointments.length == 0 && (
            <ContextMenuTrigger
              collect={() =>
                this.setRightClickOptions(
                  [],
                  current_date,
                  starttime,
                  doctor_id
                )
              }
              id="1"
            >
              {/*Parser(insideTable)*/} {putspace()}
            </ContextMenuTrigger>
          )}

          {thiscellappointments.length > 1 && (
            <ContextMenuTrigger
              collect={() =>
                this.setRightClickOptions(
                  thiscellappointments,
                  current_date,
                  starttime,
                  doctor_id
                )
              }
              id="2"
            >
              <span
                onClick={e =>
                  this.toggleViewCell(
                    "is_view_cell_open" +
                      startDate +
                      "_" +
                      starttime +
                      "_" +
                      doctor_id
                  )
                }
              >
                {" "}
                {multiClrtableDataContent}
              </span>
              <Popup
                closeOnDocumentClick={false}
                open={
                  this.state.tabshow ==
                  "is_view_cell_open" +
                    startDate +
                    "_" +
                    starttime +
                    "_" +
                    doctor_id
                }
                //   trigger={<span> {tableDataContent.slice(0, 10) + "..."} </span>}
                modal
              >
                {close => (
                  <div>
                    <div class="popup-title">
                      <span style={{ color: "#000", fontSize: 17 }}>
                        {" "}
                        View Slot Details{" "}
                      </span>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span
                          aria-hidden="true"
                          onClick={e => {
                            close();
                            this.closeTabshow(e);
                          }}
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    {this.state.saving ? (
                      <div>Saving</div>
                    ) : (
                      <div>
                        <div className="modal-body addinvestigation">
                          <div className="col-sm-12">
                            <table
                              class="calendar"
                              style={{ fontSize: 17, marginTop: 30 }}
                            >
                              {thiscellappointments.map(
                                thissingleappointment => (
                                  <tr>
                                    <td
                                      className={thissingleappointment.status}
                                      style={{ width: 300, height: 30 }}
                                      onClick={() => {
                                        this.getColumnProp(
                                          thissingleappointment,
                                          startDate,
                                          starttime,
                                          doctor_id
                                        );

                                        this.toggleActive(j);

                                        this.handleScrollToElement();

                                        //  thiscellappointments.length<2 &&
                                        //  this.toggleViewCell();
                                      }}
                                    >
                                      {" "}
                                      <ContextMenuTrigger
                                        collect={() =>
                                          this.setRightClickOptions(
                                            thissingleappointment,
                                            startDate,
                                            starttime,
                                            doctor_id
                                          )
                                        }
                                        id="1"
                                      >
                                        {thissingleappointment.Firstname}{" "}
                                      </ContextMenuTrigger>
                                    </td>
                                  </tr>
                                )
                              )}
                            </table>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            className="btn btn-default"
                            data-dismiss="modal"
                            onClick={e => {
                              close();
                              this.closeTabshow(e);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Popup>
            </ContextMenuTrigger>
          )}

          {thiscellappointments.length > 1 && (
            <div className="hiddenMore">
              <table class="calendar">
                {thiscellappointments.map(thissingleappointment => (
                  <tr>
                    <td className={thissingleappointment.status}>
                      {" "}
                      {thissingleappointment.Firstname}{" "}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          )}

          {thiscellappointments.length == 1 && (
            <ContextMenuTrigger
              collect={() =>
                this.setRightClickOptions(
                  thisappointment,
                  startDate,
                  starttime,
                  doctor_id
                )
              }
              id="1"
            >
              {/*Parser(insideTable)*/}{" "}
              {tableDataContent.length > 0 ? tableDataContent : "ssssss"}
            </ContextMenuTrigger>
          )}
        </div>
      </td>
    );
    return tabledata;
  }
  //**// RENDERING APPOINTMENT DATA FOR SLOTS - STARTS

  //**// COMPONENT RENDER STARTS
  render() {
    let j = 0;
    // alert(this.props.minutes_per_appointments);
    return (
      <div>
        {console.log(this.state.drId, +"DoctorID")}
        <div className="col-md-12 p-0" style={{ top: '-10px'}}>
          {/* Box Comment */}
          <div className="box box-widget m-0">
            <div className="box-header with-border" 
              style={{ marginTop: '10px',
              padding: '10px 0px',
              background: '#ffffff'}}
            >
              <div className="user-block">
                <div className="col-lg-10 col-xl-4 align-left">
                  <span
                    onClick={() => this.props.onClicked(5)}
                    className="username"
                  >
                    {" "}
                    <a
                      href="#"
                      onClick={() =>
                        this.props.loadDrCalendar(this.state.doctorProperties)
                      }
                    >
                      Doctor123 Appointments -{" "}
                    </a>{" "}
                    Dr. {this.props.doctorName}
                  </span>
                </div>

                <div className="col-lg-2 col-xl-4 align-right"> 
                  <DatePicker
                     id="date-jumper"
                    selected={this.state.calendardate}
                    onChange={this.handleDate}
                    todayButton="Show Week from Today"
                    showMonthDropdown
                    useShortMonthInDropdown
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                  />
                  <label
                      className="label-input100"
                      style={{
                        marginRight: "0px",
                        marginBottom: "0px",
                        fontSize: "12px",
                        paddingBottom: "0px",
                        paddingTop: "0px"
                      }}
                    >
                      <a onClick={this.focusTextInput} href="#">
                        {<img src={cicon} />}
                      </a>
                    </label>
                  <a
                    href="#"
                    onClick={() =>
                      this.props.loadDrWeekCalendar(
                        this.state.stateSearchParam.doctor_guid,
                        this.props.drCalendarProps.start_datetime,
                        this.props.drCalendarProps.end_datetime,
                        "prev"
                      )
                    }
                  >
                    <img className="img_np" src="dist/img/left.jpg" />
                  </a>
                  <a
                    href="#"
                    onClick={() =>
                      this.props.loadDrWeekCalendar(
                        this.state.stateSearchParam.doctor_guid,
                        this.props.drCalendarProps.start_datetime,
                        this.props.drCalendarProps.end_datetime,
                        "next"
                      )
                    }
                  >
                    <img className="img_np" src="dist/img/right.jpg" />
                  </a>
                </div>
              </div>
              {/* /.user-block */}
              <div className="box-tools" style={{top:'8px'}}>
                <button
                  type="button"
                  className="btn btn-box-tool"
                  data-widget="collapse"
                  style={{    backgroundColor: 'unset'}}
                >
                  <i className="fa fa-minus fa-2x" />
                </button>
              </div>
              {/* /.box-tools */}
            </div>
            {/* /.box-header */}
            <div className="box-body" style={{background: 'rgb(245, 245, 245)', padding: '0px 10px 0px 15px'}}>
              <table style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td>
                      <div
                        style={{
                          width: "100%",
                          height: "74vh",
                          overflow: "scroll"
                        }}
                      >
                        <table className="calendar table table-bordered"   style={{background: 'rgb(255, 255, 255)'}}>
                          <thead style={{background: 'antiquewhite',position: 'sticky', zIndex: '99999999'}}>
                            <tr>
                              <th style={{ width: "5%" }}>
                                <a href="#">Time</a>
                              </th>
                              {/* Loop through Specific Doctor Appointments DATES*/}
                              {this.props.drAppointmentdates.map(date => (
                                <th
                                  style={{ cursor: "pointer" }}
                                  key={date}
                                  onClick={() =>
                                    this.props.loadDateCalendar(date)
                                  }
                                >
                                  {this.ddmmyyyy(date)}
                                </th>
                              ))}
                            </tr>
                          </thead>

                          <tbody>
                            {/* First Loop through TIMESLOT*/}
                            {this.state.timeslots.map(timeslot => (
                              <tr
                                key={parseInt(
                                  Math.random() * 10000000000000,
                                  11
                                )}
                              >
                                <td>{timeslot.ampm} </td>
                                {/* First Loop through DATES*/}
                                
                                {this.props.drAppointmentdates.map(date => (this.isSkipable(timeslot.full, this.props.minutes_per_appointments) &&
                                  this.renderTableColumnData(
                                    date,
                                    timeslot.full,
                                    this.props.doctor_id,
                                    j++,
                                    10
                                  )))
                                }
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                style={{
                  position: "absolute",
                  bottom: "18px",
                  height: "auto",
                  float: "left",
                  width: "97.8%",
                  backgroundColor: "#d3d3d3"
                }}
              >
                <ul
                  className="recentdate"
                  style={{
                    float: "left",
                    width: "100%",
                    color: "",
                    marginTop: 10,
                    height: 30,
                    marginLeft: 0
                  }}
                >
                  <li
                    className="label label-unavailable"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a>{/*<img src="/dist/img/circle.png" />*/} Unavailable</a>
                  </li>
                  <li
                    className="label label-Ontheday"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a>{/*<img src="/dist/img/heart.png" />*/} On The Day</a>
                  </li>
                  <li
                    className="label label-warning"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a>{/*<img src="/dist/img/hexagon.png" />*/} Waiting</a>
                  </li>
                  <li
                    className="label label-primary"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a>
                      {/*<img src="/dist/img/rectangle.png" />*/} With Doctor
                    </a>
                  </li>
                  <li
                    className="label label-success"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a> {/*<img src="/dist/img/square.png" />*/} At Billing</a>
                  </li>
                  <li
                    className="label label-completed"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a> {/*<img src="/dist/img/star.png" />*/} Completed</a>
                  </li>
                  <li
                    className="label label-attend"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a>
                      {/*<img src="/dist/img/triangle.png" />*/} Did Not Attend
                    </a>
                  </li>
                  <li
                    className="label label-danger"
                    style={{ padding: "12px", margin: "1px 5px 1px 1px" }}
                  >
                    <a>{/*<img src="/dist/img/rectangle.png" />*/} Urgent</a>
                  </li>
                  <li
                    className="label label-elsewhere"
                    style={{ padding: "12px", margin: "1px" }}
                  >
                    <a>{/*<img src="/dist/img/heart.png" />*/} Elsewhere</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* /.box-body */}
          </div>
          {/* /.box */}
          <span ref="test"> </span>
        </div>
        <ContextMenu onShow={() => this.handlecontext()} id="2">
          <MenuItem
            data={{ action: "add_appintment" }}
            onClick={() => {
              this.addMoreAppointment();
              this.handleScrollToElement();
            }}
          >
            Add One More Appointment
          </MenuItem>

          <MenuItem
            data={{ action: "bar" }}
            onClick={e => this.toggleViewText(e)}
          >
            View Text
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={e =>
              //this.toggleViewText(e)
              this.toggleViewCell(
                "is_view_cell_open" +
                  this.state.selected_startDate +
                  "_" +
                  this.state.selected_starttime +
                  "_" +
                  this.state.selected_doctor_id
              )
            }
          >
            View Slot Details
          </MenuItem>
        </ContextMenu>
        <ContextMenu onShow={() => this.handlecontext()} id="1">
          <MenuItem
            data={{ action: "add_appintment" }}
            onClick={() => {
              this.addMoreAppointment();
              this.handleScrollToElement();
            }}
          >
            Add One More Appointment
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.triggerEditAppointments();
              this.handleScrollToElement();
            }}
          >
            Edit Appointment
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.triggerPatientDetails();
              this.handleScrollToElement();
            }}
          >
            Patient Details
          </MenuItem>
          <MenuItem data={{ action: "bar" }} onClick={() => wip()}>
            Create Account
          </MenuItem>
          <MenuItem divider />
          <MenuItem
            data={{ action: "unavailable" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-unavailable"
              );
            }}
          >
            Unavailable
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-Ontheday"
              );
            }}
          >
            On the Day
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-warning"
              );
            }}
          >
            Waiting
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-primary"
              );
            }}
          >
            With Doctor
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-success"
              );
            }}
          >
            At Billing
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-completed"
              );
            }}
          >
            Completed
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-attend"
              );
            }}
          >
            Did Not Attend
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-danger"
              );
            }}
          >
            Urgent
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(
                this.state.selected_thisappointment,
                "label-elsewhere"
              );
            }}
          >
            Elsewhere
          </MenuItem>
          <MenuItem divider />
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClickCut(
                this.state.selected_thisappointment.appoinment_id,
                this.state.selected.startDate,
                this.state.selected.starttime,
                this.state.selected.doctor_id,
                "cut"
              );
            }}
          >
            Cut
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClickCut(
                this.state.selected_thisappointment.appoinment_id,
                this.state.selected.startDate,
                this.state.selected.starttime,
                this.state.selected.doctor_id,
                "copy"
              );
            }}
          >
            Copy
          </MenuItem>
          <MenuItem
          disabled={this.state.cutAppointmentId==""?true:false}
            data={{ action: "bar" }}
            onClick={() =>
              this.rightClickPaste(
                this.props.get_cut_copy_details.cutAppointmentId,

                this.state.selected_startDate,
                this.state.selected_starttime,
                this.state.selected_doctor_id,
                this.props.get_cut_copy_details.cutAppointmentType
              )
            }
          >
            Paste
          </MenuItem>

          <MenuItem
            data={{ action: "bar" }}
            onClick={e => this.toggleMoveItem(e)}
          >
            Move
          </MenuItem>
          <MenuItem divider />
          <MenuItem
            data={{ action: "bar" }}
            onClick={e => this.toggleViewText(e)}
          >
            View Detail
          </MenuItem>
          <MenuItem
            data={{ action: "bar" }}
            onClick={() => {
              this.rightClick(this.state.selected_thisappointment, "delete");
            }}
          >
            Delete / Remove
          </MenuItem>
        </ContextMenu>
        <Addappointment
          selected_thisappointment={this.state.selected_thisappointment}
          path={this.props.path}
          key={
            this.state.addAppointmentTab +
            "-" +
            this.state.showPatientDetails +
            "-" +
            this.state.editAppointmentDetails
          }
          addAppointmentTab={this.state.addAppointmentTab}
          showPatientDetails={this.state.showPatientDetails}
          editAppointmentDetails={this.state.editAppointmentDetails}
          cdoctor={this.props.doctor_id}
          ctype={this.props.ctype}
          selectedTableProp={this.state.selectedTableProp}
        />
      </div>
    );
  }
}

//**// REDUX DESPATCH TO PROPS
const mapDespatchToProps = dispatch => {
  return {
    getDrAppointments: searchParam =>
      dispatch(api_getDrAppointments(searchParam)),
    modifyAppointments: modifyAppointmentParam =>
      dispatch(api_modifyAppointments(modifyAppointmentParam)),
    getTimeslots: () => dispatch(api_getTimeslots()),
    getPatientDetails: patient_id => dispatch(api_getPatientDetails(patient_id)),
    set_cut_copy_details: data => dispatch(api_set_cut_copy_details(data))
  };
};

//**// REDUX STATE TO PROPS
const mapStateToProps = state => {
  console.log(state);

  let drAppointmentdates = state.getDrAppointments.getDrDates
    ? state.getDrAppointments.getDrDates
    : [];
  let drAppointments = state.getDrAppointments.getDrAppointments
    ? state.getDrAppointments.getDrAppointments
    : [];
    let get_cut_copy_details = state.getDrAppointments.cut_copy_details
    ? state.getDrAppointments.cut_copy_details
    : {};
  console.log(drAppointmentdates);
  return {
    drAppointmentdates,
    drAppointments,
    get_cut_copy_details
  };
};

//**// EXPORT WITH REDUX CONNECT
export default connect(mapStateToProps, mapDespatchToProps)(Calendarind);
