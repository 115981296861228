import axios from 'axios';
//Add reminder reason
export const api_add_reminder_reason = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=remindersList&action=add_reminder_reason`, data);
}
//Update reminder reason
export const api_update_reminder_reason = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=remindersList&action=update_reminder_reason`, data);
}
//Update reminder reason
export const api_get_reminder_type = () => dispatch => {
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=remindersList&action=get_reminder_type`);
}
//Add reminder type
export const api_add_reminder_type = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=remindersList&action=add_reminder_type`, data);
}
//Update reminder type
export const api_update_reminder_type = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=remindersList&action=update_reminder_type`, data);
}
//Add reminder type
export const api_add_reminder_list = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=remindersList&action=add_reminder_list`, data);
}
//Update reminder type
export const api_update_reminder_list = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=remindersList&action=update_reminder_list`, data);
}
export const api_get_print = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=remindersList&action=get_print_text`, data);
}
export const api_default_interval =()=> dispatch=>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=remindersList&action=get_default_interval`);
}
export const api_update_settings =(data)=> dispatch=>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=remindersList&action=update_settings`,data);
}

