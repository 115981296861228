import React, { Component } from "react";
import Popup from "reactjs-popup";
import { api_visitnotes_list_options } from "./../../../../DS/DS.VisitsNotes";
import { connect } from "react-redux";
import Showhelp from './../../../showHelp';

class general extends Component {
  state = {
    list_options: {},
  };
  componentDidMount() {
    this.props.visitnotes_list_options().then((res) => {
      //   console.log("addPatientForm addPatientForm",res);
      this.setState({ list_options: res.data.content });
      console.log(this.state.list_options);
    });
  }
  render() {
    return (
      <Popup
        style={{ minHeight: "500px" }}
        trigger={
          <a title="General" className="collapsed" aria-expanded="false">
            <span className="general"></span>
          </a>
        }
        position="right center"
        modal
        closeOnDocumentClick={false}
      >
        {(close) => (

<div style={{ width:'800px', margin:'0px auto'}} >
          <div style={{ width:'800px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>
            <div
              className="modal-header"  style={{ backgroundColor:'#f4f4f4', padding: '5px 15px' }}>
              <h5 class="col-sm-12 sheadtitle"> General <Showhelp gotohelp="general_help"/></h5>
              <button
                type="button"
                className="close"
                style={{
                  marginTop: '-28px',fontSize: '25px'
                }}
                onClick={() => close()}
                data-dismiss="modal"
                aria-label="Close"
              >
                ×
              </button>
              
              {/* <h4
                style={{
                  backgroundColor: "#ddd",
                  float: "left",
                  width: "100%",
                  fontSize: "25px",
                  margintop: "-5px",
                }}
              >
                General
              </h4> */}
            </div>
            <div className="modal-body" style={{ border:'0px' }}>
              <h4 class="ml-3">History:</h4>
              <div className="col-sm-12">
                <div className="col-sm-1 cus-wid">
                  <label>Fever</label>
                </div>
                <div
                  className="col-sm-2 pr-0">
                  <label class="checkbox-inline">
                  <input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.Fever === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Fever")
                    }
                  />{" "}
                  Yes
                  </label>
                  <label class="checkbox-inline">
                  <input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.Fever === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Fever")
                    }
                  />{" "}
                  No
                  </label>
                </div>

                <div className="col-sm-1 cus-wid">
                  <label>Lathargy</label>
                </div>
                <div
                  className="col-sm-2 pr-0">
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.Lathargy === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Lathargy")
                    }
                  />{" "}
                  Yes</label>
                  <label class="checkbox-inline">
                  <input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.Lathargy === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Lathargy")
                    }
                  />{" "}
                  No</label>
                </div>
                <div className="col-sm-1 cus-wid">
                  <label>Malaise</label>
                </div>
                <div
                  className="col-sm-2 pr-0">
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.Malaise === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Malaise")
                    }
                  />{" "}
                  Yes</label>
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.Malaise === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Malaise")
                    }
                  />{" "}
                  No</label>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="col-sm-1 cus-wid">
                  <label>Anorexia</label>
                </div>
                <div
                  className="col-sm-2">
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.Anorexia === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Anorexia")
                    }
                  />{" "}
                  Yes</label>
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.Anorexia === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Anorexia")
                    }
                  />{" "}
                  No</label>
                </div>

                <div className="col-sm-1 cus-wid">
                  <label>Nausea</label>
                </div>
                <div
                  className="col-sm-2">
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.Nausea === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Nausea")
                    }
                  />{" "}
                  Yes</label>
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.Nausea === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Nausea")
                    }
                  />{" "}
                  No</label>
                </div>
                <div className="col-sm-1 cus-wid">
                  <label>Giddiness</label>
                </div>
                <div
                  className="col-sm-2">
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.Giddiness === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Giddiness")
                    }
                  />{" "}
                  Yes</label>
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.Giddiness === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Giddiness")
                    }
                  />{" "}
                  No</label>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="col-sm-1 cus-wid">
                  <label>Weight loss</label>
                </div>
                <div
                  className="col-sm-2">
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.WeightLoss === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "WeightLoss")
                    }
                  />{" "}
                  Yes </label>
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.WeightLoss === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "WeightLoss")
                    }
                  />{" "}
                  No</label>
                </div>

                <div className="col-sm-1 cus-wid">
                  <label>Weight gain</label>
                </div>
                <div
                  className="col-sm-2">
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.WeightGain === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "WeightGain")
                    }
                  />{" "}
                  Yes</label>
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.WeightGain === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "WeightGain")
                    }
                  />{" "}
                  No</label>
                </div>
                <div className="col-sm-1 cus-wid">
                  <label>Recent travel</label>
                </div>
                <div
                  className="col-sm-2">
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.RecentTravel === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general",
                        "RecentTravel"
                      )
                    }
                  />{" "}
                  Yes</label>
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.RecentTravel === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general",
                        "RecentTravel"
                      )
                    }
                  />{" "}
                  No</label>
                </div>
              </div>
              
              <h4 class="ml-3">Examination:</h4>
              <div className="col-sm-12">
                <div className="col-sm-2 pr-0">
                  <label>Clinically anaemic:</label>
                </div>
                <div className="col-sm-2 pr-0">
                <label class="checkbox-inline"><input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals_examination.Clinically === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "Clinically"
                      )
                    }
                  />{" "}
                  Yes</label>
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals_examination.Clinically === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "Clinically"
                      )
                    }
                  />{" "}
                  No</label>
                </div>

                <div className="col-sm-1 cus-wid">
                  <label>Jaundiced</label>
                </div>
                <div className="col-sm-2 pl-0">
                <label class="checkbox-inline"><input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals_examination.Jaundiced === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "Jaundiced"
                      )
                    }
                  />{" "}
                  Yes </label>
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals_examination.Jaundiced === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "Jaundiced"
                      )
                    }
                  />{" "}
                  No</label>
                </div>
                <div className="col-sm-4 "></div>
              </div>
              <div className="col-sm-12 mt-1">
                <div className="col-sm-2 mt-3">
                  <label>Pulse:</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text" class="form-control form-control-sm"
                    style={{ width: "70px", float:'left' }}
                    value={this.props.vals_examination.pulse}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "pulse"
                      )
                    }
                  />
                  <select class="form-control form-control-sm"
                    style={{ width: '137px', float:'left', marginLeft:'20px' }}
                    value={this.props.vals_examination.PulseUnit}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "PulseUnit"
                      )
                    }
                  >
                    {this.state.list_options != undefined &&
                      this.state.list_options.pulse_option != null &&
                      this.state.list_options.pulse_option.map(
                        (item, index) => (
                          <option value={item.value}> {item.label} </option>
                        )
                      )}
                  </select>
                </div>
                <div className="col-sm-4 "></div>
              </div>
              <div className="col-sm-12 ">
                <div className="col-sm-2 pr-0 mt-1">
                  <label>Blood Pressure:</label>
                </div>
                <div className="col-sm-1 pr-0 mt-2" style={{ textAlign: "left" }}>
                  <label>Sitting</label>
                </div>

                <div className="col-sm-2 pr-0">
                  <input class="form-control form-control-sm mt-0"
                    type="text"
                    style={{ width: "40px", float:'left' }}
                    value={this.props.vals_examination.BloodPressureSitting}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "BloodPressureSitting"
                      )
                    }
                  />
 

                <label style={{textAlign: 'center', width:'20px', float: 'left'}} className="mt-1">/</label>
                  <input class="form-control form-control-sm mt-0"
                    type="text"
                    style={{ width: '40px', float:'left' }}
                    value={
                      this.props.vals_examination.BloodPressureSittingRight
                    }
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "BloodPressureSittingRight"
                      )
                    }
                  />
                </div>

                <div className="col-sm-1 mt-2">
                  <label>Standing</label>
                </div>

                <div className="col-sm-2 pr-0">
                  <input type="text" className="form-control form-control-sm mt-0" style={{ width: "40px", float: 'left' }} />
                <label style={{textAlign: 'center', width:'20px', float: 'left'}} className="mt-1">/</label>
                  <input type="text" className="form-control form-control-sm mt-0" style={{ width: "40px", float: 'left' }} />
                </div>

                <div className="col-sm-1 mt-2">
                  <label>Lying</label>
                </div>

                <div className="col-sm-2 pr-0">
                <input type="text" className="form-control form-control-sm mt-0" style={{ width: "40px", float: 'left' }} />
                <label style={{textAlign: 'center', width:'20px', float: 'left'}} className="mt-1">/</label>
                <input type="text" className="form-control form-control-sm mt-0" style={{ width: "40px", float: 'left' }} />
                </div>
                <div className="col-sm-4 "></div>
              </div>
              <div className="col-sm-12 ">
                <div className="col-sm-2 pr-0 mt-1">
                  <label>Temp:</label>
                </div>
                <div className="col-sm-2">
                  <input style={{ marginTop: "15px" }}
                    type="text" value={this.props.vals_examination.Temp}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "Temp"
                      )
                    }
                  />
                </div>
                
                <div
                  className="col-sm-2 "
                  style={{ marginTop: "15px",textAlign:'left' }}
                >
                  <select style={{ width: "100%" }}
                   value={this.props.vals_examination.TempUnit}
                   onChange={(e) =>
                     this.props.handleOptionChange(
                       e,
                       "general_examination",
                       "TempUnit"
                     )
                   }>
                    <option value="Oral">Oral</option>
                    <option value="Axillary">Axillary</option>
                    <option value="Tympanic">Tympanic</option>
                    <option value="Rectal">Rectal</option>
                    <option value="Temporal artery">Temporal artery</option>
                  </select>
                </div>
                <div className="col-sm-1 mt-1" style={{ textAlign: "left", marginTop: "15px" }}>
                  <input
                    type="text"
                    style={{ width: "70px", display:'none' }}
                    value={this.props.vals_examination.pulse}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "pulse"
                      )
                    }
                  />
                  Resp.rate
                </div>
                <div className="col-sm-3 mt-0">
                  <input class="form-control form-control-sm mt-0"
                    type="text"
                    value={this.props.vals_examination.RespRate}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "RespRate"
                      )
                    }
                  />
                </div>
                
              </div>
              <div className="col-sm-12 ">
                <div className="col-sm-2 pr-0 mt-1">
                  <label>Weight(kg): </label>
                </div>
                <div className="col-sm-1 pr-0">
                  <input className="form-control form-control-sm mt-0"
                    type="text"
                    value={this.props.vals_examination.WeightKG}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "WeightKG"
                      )
                    }
                  />
                </div>

                <div className="col-sm-1 mt-1">
                  <label>Height(cm): </label>
                </div>
                <div className="col-sm-1 pr-0">
                  <input class="form-control form-control-sm mt-0"
                    type="text"
                    value={this.props.vals_examination.HeightCM}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "HeightCM"
                      )
                    }
                  />
                </div>
                <div className="col-sm-1 mt-1">
                  <label>BSL:</label>
                </div>
                <div className="col-sm-1 pl-0">
                  <input class="form-control form-control-sm mt-0"
                    type="text"
                    value={this.props.vals_examination.BSL}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "BSL"
                      )
                    }
                  />
                </div>

                <div className="col-sm-3 pl-0">
                  <select class="form-control form-control-sm mt-0"
                   value={this.props.vals_examination.BSLUnit}
                   onChange={(e) =>
                     this.props.handleOptionChange(
                       e,
                       "general_examination",
                       "BSLUnit"
                     )
                   }>
                    <option value="Fasting">Fasting</option>
                    <option value="Non-Fasting">Non-Fasting</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-12 ">
                <div className="col-sm-2 col-sm-02 mt-1">
                  <label>
                    Waist measurement:
                  </label>
                </div>
                <div
                  className="col-sm-1 pl-0"
                  style={{ float: "left", marginLeft: "-12px" }}
                >
                  <input class="form-control form-control-sm mt-0"
                    type="text"
                    value={this.props.vals_examination.WaistMeasurement}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "WaistMeasurement"
                      )
                    }
                  />
                </div>

                <div className="col-sm-2 pr-0 mt-1">
                  <label>Hip measurement:</label>
                </div>
                <div
                  className="col-sm-1 ">
                  <input class="form-control form-control-sm mt-0"
                    type="text"
                    style={{ width: "70px" }}
                    value={this.props.vals_examination.HeadMeasurement}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "HeadMeasurement"
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-12 ">
                <div className="col-sm-2 col-sm-02">
                  <label>Dehydration</label>
                </div>
                <div className="col-sm-2">
                <label class="checkbox-inline"><input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals_examination.Dehydration == "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "Dehydration"
                      )
                    }
                  />{" "}
                  Yes</label>
                  <label class="checkbox-inline"><input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals_examination.Dehydration == "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "Dehydration"
                      )
                    }
                  />{" "}
                  No</label>
                </div>

                <div
                  className="col-sm-2">
                  <input type="text" className="form-control form-control-sm mt-0" style={{ width: '60px', float: 'left' }} /> 
                  <label style={{textAlign: 'center', width:'20px', float: 'left'}} className="mt-1">%</label>
                </div>

                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label>02 Saturation:</label>
                </div>
                <div className="col-sm-2">
                  <input
                    type="text" className="form-control form-control-sm mt-0"
                    style={{ width: '60px', float: 'left'}}
                    value={this.props.vals_examination.O2Saturation}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "O2Saturation"
                      )
                    }
                  />{" "}
                 <label style={{textAlign: 'center', width:'20px', float: 'left'}} className="mt-1">%</label>
                </div>

              </div>
            </div>
            <div className="modal-footer" 
            // style={{ marginTop: "450px" }}
            >
              <button
                type="button"
                className="btn btn-primary btn-xs"  data-dismiss="modal"
                onClick={(e) => {
                  this.props.handleSaveOptions(e, "general_popup");
                  close();
                }}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-primary btn-xs"  onClick={() => close()}
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
          </div>
        )}
      </Popup>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    visitnotes_list_options: (data) =>
      dispatch(api_visitnotes_list_options(data)),
  };
};

export default connect(null, mapDispatchToProps)(general);
