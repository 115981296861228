import React, { Component }  from 'react';
import { render } from 'react-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
class Highchart extends Component {
    state = {  }
    render() { 
        
const options = {
    chart: {
      type: 'line'
    },
    title: {
      text: 'My chart'
    },
    series: [
      {
        data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]]
      }
    ]
  };

  if(this.props.selectedChart.length > 0){
    //   data[0].data = props.selectedChart; 
       options.series[0].data = [['ddddd', 1], ['dd', 2], ['ddddddd', 4], ['ddd', 2], ['d', 7]];
   }
   else {
    options.series[0].data = [['ccc', 1], ['cc', 2], ['cccc', 4], ['cc', 2], ['cd', 7]];
   }

    

return (
    <div>
        <h1> HIgh Chart</h1>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
    
    }
}
 
export default Highchart;
 