import axios from 'axios';


export const api_add_correspondence = data => dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=correspondence&action=add_correspondence`,
        data
      )
  };
  export const api_get_sent = data => dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=correspondence&action=list_sent`,
        data
      )
  };
  export const api_get_recieved = data => dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=correspondence&action=list_recieved`,
        data
      )
  };
  export const api_delete_correspondence = data => dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=correspondence&action=delete_correspondence`,
        data
      )
  };
  export const api_update_correspondence = data => dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=correspondence&action=update_correspondence`,
        data
      )
  };
  export const api_list_selected = data => dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=correspondence&action=list_slected_correspondence`,
        data
      )
  };
  export const api_list_filtered_data = data => dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=correspondence&action=list_filtered_data`,
        data
      )
  };
  export const api_list_dist_category = data => dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=correspondence&action=list_dist_category`,
        data
      )
  };
  export const api_list_dist_subject = data => dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=correspondence&action=list_dist_subject`,
        data
      )
  };
  export const api_list_dist_from = data => dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=correspondence&action=list_dist_from`,
        data
      )
  };
  export const api_move_to = data => dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=correspondence&action=move_to`,
        data
      )
  };
  export const api_contact_category = () => dispatch => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}api=correspondence&action=get_contact_category`)
  };
  
  export const api_get_title = () => dispatch => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}api=correspondence&action=get_title`)
  };
  export const api_new_contact = data => dispatch => {
    return axios.post( `${process.env.REACT_APP_BASE_URL}api=correspondence&action=add_contact`,data)
  };
  export const api_contact_address = data => dispatch => {
    return axios.post( `${process.env.REACT_APP_BASE_URL}api=correspondence&action=add_contact_address`,data)
  };
  
  export const get_category_contact = data => dispatch => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}api=correspondence&action=get_category_contact`,data);
     
    };
  export const get_category_for_contact = data => dispatch => {
      return axios
        .post(`${process.env.REACT_APP_BASE_URL}api=correspondence&action=get_contact_for_update`,data);
       
      };
  export const get_address = data => dispatch => {
        return axios
          .post(`${process.env.REACT_APP_BASE_URL}api=correspondence&action=get_address`,data);
         
        };
      
 export const update_contact = data => dispatch => {return axios.post(`${process.env.REACT_APP_BASE_URL}api=correspondence&action=update_contact`,data);};
 export const update_address = data => dispatch => {return axios.post(`${process.env.REACT_APP_BASE_URL}api=correspondence&action=update_contact_address`,data);};
 export const api_get_correspondence = data => dispatch => {return axios.post(`${process.env.REACT_APP_BASE_URL}api=correspondence&action=api_get_correspondence`,data);};
export const api_upload_image = data => dispatch => {
return axios.post( `${process.env.REACT_APP_BASE_URL}api=correspondence&action=upload_image`,data);};
export const api_get_image = data => dispatch => {return axios.get(`${process.env.REACT_APP_BASE_URL}api=correspondence&action=api_get_image`,data);};
export const api_search_contact = data => dispatch => {return axios.post(`${process.env.REACT_APP_BASE_URL}api=correspondence&action=api_search_contact`,data);};
export const api_getCorrespondence = data => dispatch => {return axios.post(`${process.env.REACT_APP_BASE_URL}api=correspondence&action=get_correspondence`,data);};
export const api_move_to_clinical = data => dispatch => {return axios.post(`${process.env.REACT_APP_BASE_URL}api=correspondence&action=move_to_clinical`,data);};
export const api_get_type = data => dispatch => {return axios.post(`${process.env.REACT_APP_BASE_URL}api=correspondence&action=get_type`,data);};
export const api_move_to_investigation = data => dispatch => {return axios.post(`${process.env.REACT_APP_BASE_URL}api=correspondence&action=move_to_investigation`,data);};

