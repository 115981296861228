import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import { time } from 'highcharts';
import SearchDoctors from '../Correspondence/searchDoctors'
import {api_add_diabetic} from '../../../DS/DS.EPC'
import Showhelp from './../../showHelp';

class AnotherValues extends Component {
   constructor(props)
   {
       super(props);
       this.state={
           open:this.props.showmodal,
           observations:new Date(),
           disableObservation:true,
           footExam:true,
           fundusShow:true,
           hb1ac:true,
           cholesterol:true,
           hdl:true,
           ldl:true,
           creati:true,
           trigly:true,
           egfr:true,
           albumin:true,
           micro:true,
           endo:true,
           dieti:true,
           ophtha:true,
           pedia:true,
           diabe:true,
           height:'',
           waist:'',
           weight:'',
           bsl:'',
           bp_sitting:'',
           bp_sleeping:'',
           fasting:0,
           foot_date:new Date(),
           right_deform:0,
           left_deform:0,
           right_ulcer:0,
           left_ulcer:0,
           right_neuro:0,
           left_neuro:0,
           right_pulses:0,
           left_pulses:0,
           fundus_date:new Date(),
           fundus_right:'',
           fundus_left:'',
           hbac_val:'',
           hbac_date:new Date(),
           hbac_select:'mmol/mol',
           cholesterol_val:'',
           cholesterol_date:new Date(),
           ldl_date:new Date(),
           ldl_val:'',
           trigly_val:'',
           trigly_date:new Date(),
           hdl_val:'',
           hdl_date:new Date(),
           creati_val:'',
           creati_date:new Date(),
           egfr_val:'',
           egfr_date:new Date(),
           albumin_val:'',
           albumin_date:new Date(),
           micro_val:'',
           micro_date:new Date(),
           micro_select:'mg/mmol',
           endo_date:new Date(),
           endo_doc:'',
           dieti_date:new Date(),
           dieti_doc:'',
           ophtha_date:new Date(),
           ophtha_doc:'',
           pedia_date:new Date(),
           pedia_doc:'',
           diabe_date:new Date(),
           diabe_doc:'',
           searchDoc:false,
           selected_doc_id:'',
           from_guid:'',
           clicked:'',
           doctor_id:this.props.redux_docId,
           patient_id:this.props.redux_patientId



           
       }
   }

   SaveAll=()=>
   {
    this.props.closeAll()
   }

 

   enableDates=(valFor,val)=>
    {
        if(valFor=='observation' && val==true){
            this.setState({disableObservation:false})
        }
        if(valFor=='observation' && val==false){
            this.setState({disableObservation:true})
        }

        if(valFor=='footExam' && val==true){
            this.setState({footExam:false})
        }
        if(valFor=='footExam' && val==false){
            this.setState({footExam:true})
        }

        if(valFor=='fundus' && val==true){
            this.setState({fundusShow:false})
        }
        if(valFor=='fundus' && val==false){
            this.setState({fundusShow:true})
        }

        if(valFor=='hb1ac' && val==true){
            this.setState({hb1ac:false})
        }
        if(valFor=='hb1ac' && val==false){
            this.setState({hb1ac:true})
        }

        if(valFor=='cholesterol' && val==true){
            this.setState({cholesterol:false})
        }
        if(valFor=='cholesterol' && val==false){
            this.setState({cholesterol:true})
        }

        if(valFor=='hdl' && val==true){
            this.setState({hdl:false})
        }
        if(valFor=='hdl' && val==false){
            this.setState({hdl:true})
        }

        if(valFor=='ldl' && val==true){
            this.setState({ldl:false})
        }
        if(valFor=='ldl' && val==false){
            this.setState({ldl:true})
        }

        if(valFor=='creati' && val==true){
            this.setState({creati:false})
        }
        if(valFor=='creati' && val==false){
            this.setState({creati:true})
        }

        if(valFor=='trigly' && val==true){
            this.setState({trigly:false})
        }
        if(valFor=='trigly' && val==false){
            this.setState({trigly:true})
        }

        if(valFor=='egfr' && val==true){
            this.setState({egfr:false})
        }
        if(valFor=='egfr' && val==false){
            this.setState({egfr:true})
        }

        if(valFor=='albumin' && val==true){
            this.setState({albumin:false})
        }
        if(valFor=='albumin' && val==false){
            this.setState({albumin:true})
        }

        if(valFor=='micro' && val==true){
            this.setState({micro:false})
        }
        if(valFor=='micro' && val==false){
            this.setState({micro:true})
        }

        if(valFor=='endo' && val==true){
            this.setState({endo:false})
        }
        if(valFor=='endo' && val==false){
            this.setState({endo:true})
        }

        if(valFor=='diabe' && val==true){
            this.setState({diabe:false})
        }
        if(valFor=='diabe' && val==false){
            this.setState({diabe:true})
        }

        if(valFor=='ophtha' && val==true){
            this.setState({ophtha:false})
        }
        if(valFor=='ophtha' && val==false){
            this.setState({ophtha:true})
        }

        if(valFor=='pedia' && val==true){
            this.setState({pedia:false})
        }
        if(valFor=='pedia' && val==false){
            this.setState({pedia:true})
        }

        if(valFor=='dieti' && val==true){
            this.setState({dieti:false})
        }
        if(valFor=='dieti' && val==false){
            this.setState({dieti:true})
        }
    }

    addSelectedDoc=(id)=>
    {
        if(this.state.clicked=='dieti')
        {
            this.setState({selected_doc_id:id.contact_id,dieti_doc:id.display+''+id.first_name+'.'+id.surname})
        }
        if(this.state.clicked=='endo')
        {
            this.setState({selected_doc_id:id.contact_id,endo_doc:id.display+''+id.first_name+'.'+id.surname})
        }
        if(this.state.clicked=='optha')
        {
            this.setState({selected_doc_id:id.contact_id,ophtha_doc:id.display+''+id.first_name+'.'+id.surname})
        }
        if(this.state.clicked=='pedi')
        {
            this.setState({selected_doc_id:id.contact_id,pedia_doc:id.display+''+id.first_name+'.'+id.surname})
        }
        if(this.state.clicked=='diabe')
        {
            this.setState({selected_doc_id:id.contact_id,diabe_doc:id.display+''+id.first_name+'.'+id.surname})
        }
        
        this.setState({searchDoc:false})
       
    }

    handleDate=(valfor,val)=>
    {
        console.log("&&&&&&&&&&&&&&&&&&&&&&&&",val)
        if(valfor=='observation')
        {
            this.setState({observations:val})
        }
        if(valfor=='foot')
        {
            this.setState({foot_date:val})
        }
        if(valfor=='fundus')
        {
            this.setState({fundus_date:val})
        }
        if(valfor=='hba')
        {
            this.setState({hbac_date:val})
        }
        if(valfor=='cholestrol')
        {
            this.setState({cholesterol_date:val})
        }
        if(valfor=='trigly')
        {
            this.setState({trigly_date:val})
        }
        if(valfor=='hdl')
        {
            this.setState({hdl_date:val})
        }
        if(valfor=='ldl')
        {
            this.setState({ldl_date:val})
        }
        if(valfor=='creati')
        {
            this.setState({creati_date:val})
        }
        if(valfor=='egfr')
        {
            this.setState({egfr_date:val})
        }
        if(valfor=='albumi')
        {
            this.setState({albumin_date:val})
        }
        if(valfor=='micro')
        {
            this.setState({micro_date:val})
        }
        if(valfor=='endo')
        {
            this.setState({endo_date:val})
        }
        if(valfor=='dieti')
        {
            this.setState({dieti_date:val})
        }
        if(valfor=='optha')
        {
            this.setState({ophtha_date:val})
        }
        if(valfor=='pedia')
        {
            this.setState({pedia_date:val})
        }
        if(valfor=='diabe')
        {
            this.setState({diabe_date:val})
        }

    }
    saveData=()=>
    {
        this.props.saveDiabetic(this.state).then(res=>
            {
                this.props.closeAll();
            })
    }
    render() {
        return ( 
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div style={{ width:'1050px', margin:'0px auto'}}> 
                <div style={{ width:'1050px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                        <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px', fontSize:'16px',marginBottom:'0px'}}> Diabetic cycle care <Showhelp gotohelp="another_values_help"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.closeAll()} style={{color:'black'}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ fontSize: "14px" }}>
                        <div className="col-sm-12" style={{marginBottom: '10', height:'500px', overflowY:'scroll'}}>
                            <div className="row">
                                <div className="col-sm-2 mt-3">
                                    <label className="col-form-label text-left" style={{fontWeight:'bold', fontSize:'16px'}}>Observation:</label>
                                </div>
                                <div className="col-sm-10">
                                    <div className="row">
                                        <div className="col-sm-1 mt-3">
                                            <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('observation',e.target.checked)} style={{marginTop:'25px'}}/>
                                        </div>

                                        <div className="col-sm-2 mt-3">
                                        <DatePicker
                                         className="datePicker"
                                         onChange={(e)=>this.handleDate('observation',e)}
                                         dateFormat="dd/MM/yyyy"
                                         id="clinicalcalendar" 
                                         selected={
                                                this.state.dateShow
                                                    ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                    : Date.parse(this.state.observations)
                                                }
                                                disabled={this.state.disableObservation}
                                        />  
                                        </div>

                                        <div className="col-sm-1 mt-3"><label className="col-form-label text-left">BP:</label></div>
                                        <div class="col-sm-2 pl-0">
                                            <input type="text" class="form-control " style={{width: '40px', float:'left'}}
                                            value={this.state.bp_sitting} onChange={(e)=>this.setState({bp_sitting:e.target.value})}/>
                                            <label style={{textAlign: 'center',width:'20px',float: 'left'}} className="mt-2">/</label>
                                            <input type="text" class="form-control "  style={{width: '40px', float:'left'}}
                                            value={this.state.bp_sleeping} onChange={(e)=>this.setState({bp_sleeping:e.target.value})}/>
                                        </div>

                                        <div className="col-sm-1 mt-3"><label className="col-form-label text-left">Weight:</label></div>
                                        <div class="col-sm-1 pl-0">
                                            <input type="text" class="form-control " style={{width: '40px', float:'left'}}
                                            value={this.state.weight} onChange={(e)=>this.setState({weight:e.target.value})}/>
                                         </div>

                                         <div className="col-sm-1 mt-3 pr-0"><label className="col-form-label text-left">Height:</label></div>
                                        <div class="col-sm-2 pl-0">
                                            <input type="text" class="form-control "  style={{width: '40px', float:'left'}}
                                            value={this.state.height} onChange={(e)=>this.setState({height:e.target.value})}/>
                                         </div>

                                    </div>
                                    <div className="row">
                                      
                                      <div className="col-sm-1 mt-3"><label className="col-form-label text-left">Waist:</label></div>
                                        <div class="col-sm-2">
                                            <input type="text" class="form-control "  style={{width: '40px', float:'left'}}
                                            value={this.state.waist} onChange={(e)=>this.setState({waist:e.target.value})}/>
                                         </div>

                                         <div className="col-sm-1 mt-3"><label className="col-form-label text-left">BSL:</label></div>
                                        <div class="col-sm-2 pl-0">
                                            <input type="text" class="form-control "style={{width: '40px', float:'left'}}
                                            value={this.state.bsl} onChange={(e)=>this.setState({bsl:e.target.value})}/>
                                         </div>
                                        <div class="col-sm-2 mt-3">
                                        <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.setState({fasting:e.target.checked})} style={{marginTop:'25px'}}/> Fasting
                                         </div>
                                       
                                       </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2">
                                    <label className="col-form-label text-left" style={{fontWeight:'bold', fontSize:'16px'}}>Foot examination:</label>
                                </div>
                                <div className="col-sm-10">
                                    <div className="row">
                                    <div className="col-sm-1">
                                            <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('footExam',e.target.checked)} style={{marginTop:'25px'}}
                                            />
                                        </div>

                                        <div className="col-sm-2">
                                        <DatePicker
                                         className="datePicker"
                                         onChange={(e)=>this.handleDate('foot',e)}
                                         dateFormat="dd/MM/yyyy"
                                         id="clinicalcalendar" 
                                         selected={
                                                this.state.dateShow
                                                    ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                    : Date.parse(this.state.foot_date)
                                                }
                                                disabled={this.state.footExam}                                                
                                        />  
                                        </div>
                                    </div>
                                    <div className="row">
                                    <div class="col-sm-12 mb-2">
                                            <div class="col-sm-2 col-sm-02"><label>Right</label></div>
                                            <div class="col-sm-2 col-sm-02"></div>
                                            <div class="col-sm-2 col-sm-02"><label>Left</label></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div class="col-sm-12">
                                        <div class="col-sm-2"><label>Deformity</label></div>
                                        <div class="col-sm-2 pr-0" style={{display:'flex'}}>
                                            <label class="checkbox">
                                                <input type="radio" style={{ marginRight: '5px' }} name="rdefor" value="1" onChange={()=>this.setState({right_deform:1})}/> Yes
                                            </label>
                                            <label class="checkbox" style={{marginTop: '2px', marginLeft: '10px'}}>
                                                <input type="radio" name="rdefor" style={{ marginRight: '5px' }} value="0" onChange={()=>this.setState({right_deform:0})}/> No
                                            </label>
                                        </div>
                                        <div class="col-sm-2"><label>Deformity</label></div>
                                        <div class="col-sm-2 pr-0" style={{display:'flex'}}>
                                            <label class="checkbox">
                                                <input type="radio" name="ldefor" style={{ marginRight: '5px' }} value="1" onChange={()=>this.setState({left_deform:1})}/> Yes
                                            </label>
                                            <label class="checkbox" style={{marginTop: '2px', marginLeft: '10px'}}>
                                                <input type="radio" name="ldefor" style={{ marginRight: '5px' }} value="0" onChange={()=>this.setState({left_deform:0})}/> No
                                            </label>
                                        </div>
                                       
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div class="col-sm-12">
                                        <div class="col-sm-2"><label>Ulcer</label></div>
                                        <div class="col-sm-2 pr-0" style={{display:'flex'}}>
                                            <label class="checkbox">
                                                <input type="radio" name="rulcer" style={{ marginRight: '5px' }} value="1" onChange={()=>this.setState({right_ulcer:1})}/> Yes
                                            </label>
                                            <label class="checkbox" style={{marginTop: '2px', marginLeft: '10px'}}>
                                                <input type="radio" name="rulcer" style={{ marginRight: '5px' }} value="0" onChange={()=>this.setState({right_ulcer:0})}/> No
                                            </label>
                                        </div>
                                        <div class="col-sm-2"><label>Ulcer</label></div>
                                        <div class="col-sm-2 pr-0" style={{display:'flex'}}>
                                            <label class="checkbox">
                                                <input type="radio" name="lulcer" value="1" style={{ marginRight: '5px' }} onChange={()=>this.setState({left_ulcer:1})}/> Yes
                                            </label>
                                            <label class="checkbox" style={{marginTop: '2px', marginLeft: '10px'}}>
                                                <input type="radio" name="lulcer" value="0" style={{ marginRight: '5px' }} onChange={()=>this.setState({left_ulcer:0})}/> No
                                            </label>
                                        </div>
                                       
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div class="col-sm-12">
                                        <div class="col-sm-2"><label>Neuropathy</label></div>
                                        <div class="col-sm-2 pr-0" style={{display:'flex'}}>
                                            <label class="checkbox">
                                                <input type="radio" name="rneuro" value="1" style={{ marginRight: '5px' }} onChange={()=>this.setState({right_neuro:1})}/> Yes
                                            </label>
                                            <label class="checkbox" style={{marginTop: '2px', marginLeft: '10px'}}>
                                                <input type="radio" name="rneuro" value="0" style={{ marginRight: '5px' }} onChange={()=>this.setState({right_neuro:0})}/> No
                                            </label>
                                        </div>
                                        <div class="col-sm-2"><label>Neuropathy</label></div>
                                        <div class="col-sm-2 pr-0" style={{display: 'flex'}}>
                                            <label class="checkbox">
                                                <input type="radio" name="lneuro" value="1" style={{ marginRight: '5px' }} onChange={()=>this.setState({left_neuro:1})}/> Yes
                                            </label>
                                            <label class="checkbox" style={{marginTop: '2px', marginLeft: '10px'}}>
                                                <input type="radio" name="lneuro" value="0" style={{ marginRight: '5px' }} onChange={()=>this.setState({left_neuro:0})}/> No
                                            </label>
                                        </div>
                                       
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div class="col-sm-12">
                                        <div class="col-sm-2"><label>Pulses</label></div>
                                        <div class="col-sm-2 pr-0" style={{display: 'flex'}}>
                                            <label class="checkbox">
                                                <input type="radio" name="rpulse" style={{ marginRight: '5px' }} onChange={()=>this.setState({right_pulses:1})} value="1"/> Yes
                                            </label>
                                            <label class="checkbox" style={{marginTop: '2px', marginLeft: '10px'}}>
                                                <input type="radio" name="rpulse" style={{ marginRight: '5px' }} onChange={()=>this.setState({right_pulses:0})} value="0"/> No
                                            </label>
                                        </div>
                                        <div class="col-sm-2"><label>Pulses</label></div>
                                        <div class="col-sm-2 pr-0" style={{display: 'flex'}}>
                                            <label class="checkbox">
                                                <input type="radio" name="lpulse" style={{ marginRight: '5px' }} value="1" onChange={()=>this.setState({left_pulses:1})}/> Yes
                                            </label>
                                            <label class="checkbox" style={{marginTop: '2px', marginLeft: '10px'}}>
                                                <input type="radio" name="lpulse" style={{ marginRight: '5px' }} value="0" onChange={()=>this.setState({left_pulses:0})}/> No
                                            </label>
                                        </div>
                                       
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <label className="col-form-label text-left" style={{fontWeight:'bold', fontSize:'16px'}}>Fundus examination:</label>
                                    </div>
                                     <div className="col-sm-10">
                                        <div className="row">
                                            <div className="col-sm-1">
                                                <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('fundus',e.target.checked)} 
                                                style={{marginTop:'25px'}} />
                                            </div>

                                            <div className="col-sm-2">
                                            <DatePicker
                                                className="datePicker"
                                                onChange={(e)=>this.handleDate('fundus',e)}
                                                dateFormat="dd/MM/yyyy"
                                                id="clinicalcalendar" 
                                                selected={
                                                        this.state.dateShow
                                                            ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                            : Date.parse(this.state.fundus_date)
                                                        }
                                                        disabled={this.state.fundusShow}
                                                />  
                                            </div>
                                            <div class="col-sm-9">
                                                    <div class="col-sm-2 col-sm-02"><label>Right</label></div>
                                                    <div class="col-sm-2 col-sm-02"></div>
                                                    <div class="col-sm-2 col-sm-02"><label>Left</label></div>
                                                </div>
                                    </div>
                                <div className="row">
                                    <div className="col-sm-3 pr-0"></div>
                                    <div className="col-sm-2 pr-0">
                                        <select value={this.state.fundus_right} onChange={(e)=>this.setState({fundus_right:e.target.value})}>
                                            <option value=""></option>
                                            <option value="No retinopathy">No retinopathy</option>
                                            <option value="Minimal retinopathy">Minimal retinopathy</option>
                                            <option value="Mild retinopathy">Mild retinopathy</option>
                                            <option value="Moderate retinopathy">Moderate retinopathy</option>
                                            <option value="Severe retinopathy">Severe retinopathy</option>
                                            <option value="Proliferative retinopathy">Proliferative retinopathy</option>
                                            <option value="Background retinopathy">Background retinopathy</option>
                                            <option value="Macular oedema">Macular oedema</option>
                                            

                                        </select>
                                    </div>
                                    <div class="col-sm-1 col-sm-02"></div>
                                    <div className="col-sm-2 pr-0">
                                        <select value={this.state.fundus_left} onChange={(e)=>this.setState({fundus_left:e.target.value})}>
                                            <option value=""></option>
                                            <option value="No retinopathy">No retinopathy</option>
                                            <option value="Minimal retinopathy">Minimal retinopathy</option>
                                            <option value="Mild retinopathy">Mild retinopathy</option>
                                            <option value="Moderate retinopathy">Moderate retinopathy</option>
                                            <option value="Severe retinopathy">Severe retinopathy</option>
                                            <option value="Proliferative retinopathy">Proliferative retinopathy</option>
                                            <option value="Background retinopathy">Background retinopathy</option>
                                            <option value="Macular oedema">Macular oedema</option>
                                        </select>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2">
                                <label className="col-form-label text-left" style={{fontWeight:'bold', fontSize:'16px'}}>Investigations :</label>
                            </div>
                            <div className="col-sm-10">
                                <div className="row">
                                <div className="col-sm-2 pl-0 pr-0"><label>HbA1c :</label></div>      
                                <div className="col-sm-1"><input type="text" class="form-control form-control-sm mt-0" value={this.state.hbac_val} onChange={(e)=>this.setState({hbac_val:e.target.value})} style={{width: '60px', float:'left'}}/></div>    
                                <div className="col-sm-1"> <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('hb1ac',e.target.checked)} 
                                                style={{marginTop:'25px'}} /></div>

                                    <div className="col-sm-2">
                                    <DatePicker
                                         className="datePicker"
                                         onChange={(e)=>this.handleDate('hba',e)}
                                         dateFormat="dd/MM/yyyy"
                                         id="clinicalcalendar" 
                                         selected={
                                                this.state.dateShow
                                                    ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                    : Date.parse(this.state.hbac_date)
                                                }
                                                disabled={this.state.hb1ac}
                                        />  
                                    </div>
                                    <div className="col-sm-2 pr-0">
                                        <select value={this.state.hbac_select} onChange={(e)=>this.setState({hbac_select:e.target.value})}>
                                            <option value="mmol/mol">mmol/mol</option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-2 pr-0">
                                        <button  className="btn btn-primary btn-xs">All same date</button>
                                    </div>
                                </div>
                                <div className="row">
                                        <div className="col-sm-2 pl-0 pr-0"><label>Total Cholesterol :</label></div>      
                                        <div className="col-sm-1"><input type="text" class="form-control form-control-sm mt-0" value={this.state.cholesterol_val} onChange={(e)=>this.setState({cholesterol_val:e.target.value})} style={{width: '60px', float:'left'}}/></div>    
                                        <div className="col-sm-1"> <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('cholesterol',e.target.checked)} 
                                                style={{marginTop:'25px'}} /></div>

                                        <div className="col-sm-2">
                                            <DatePicker
                                                className="datePicker"
                                                onChange={(e)=>this.handleDate('cholestrol',e)}
                                                dateFormat="dd/MM/yyyy"
                                                id="clinicalcalendar" 
                                                selected={
                                                        this.state.dateShow
                                                            ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                            : Date.parse(this.state.cholesterol_date)
                                                        }
                                                        disabled={this.state.cholesterol}
                                                />  
                                        </div>
                               
                                        <div className="col-sm-2 pl-0 pr-0"><label>Triglycerides :</label></div>      
                                        <div className="col-sm-1"><input type="text" class="form-control form-control-sm mt-0" value={this.state.trigly_val} onChange={(e)=>this.setState({trigly_val:e.target.value})} style={{width: '60px', float:'left'}}/></div>    
                                        <div className="col-sm-1"> <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('trigly',e.target.checked)} 
                                                style={{marginTop:'25px'}} /></div>

                                        <div className="col-sm-2">
                                            <DatePicker
                                            className="datePicker"
                                            onChange={(e)=>this.handleDate('trigly',e)}
                                            dateFormat="dd/MM/yyyy"
                                            id="clinicalcalendar" 
                                            selected={
                                                    this.state.dateShow
                                                        ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                        : Date.parse(this.state.trigly_date)
                                                    }
                                                    disabled={this.state.trigly}
                                            />  
                                        </div>
                                    </div>


                                    
                                <div className="row">
                                        <div className="col-sm-2 pl-0 pr-0"><label>HDL Cholesterol :</label></div>      
                                        <div className="col-sm-1"><input type="text" class="form-control form-control-sm mt-0" value={this.state.hdl_val} onChange={(e)=>this.setState({hdl_val:e.target.value})} style={{width: '60px', float:'left'}}/></div>    
                                        <div className="col-sm-1"> <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('hdl',e.target.checked)} 
                                                style={{marginTop:'25px'}} /></div>

                                        <div className="col-sm-2">
                                            <DatePicker
                                                className="datePicker"
                                                onChange={(e)=>this.handleDate('hdl',e)}
                                                dateFormat="dd/MM/yyyy"
                                                id="clinicalcalendar" 
                                                selected={
                                                        this.state.dateShow
                                                            ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                            : Date.parse(this.state.hdl_date)
                                                        }
                                                        disabled={this.state.hdl}
                                                />  
                                        </div>
                               
                                        <div className="col-sm-2 pl-0 pr-0"><label>LDL Cholesterol :</label></div>      
                                        <div className="col-sm-1"><input type="text" class="form-control form-control-sm mt-0" value={this.state.ldl_val} onChange={(e)=>this.setState({ldl_val:e.target.value})} style={{width: '60px', float:'left'}}/></div>    
                                        <div className="col-sm-1"> <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('ldl',e.target.checked)} 
                                                style={{marginTop:'25px'}} /></div>

                                        <div className="col-sm-2">
                                            <DatePicker
                                            className="datePicker"
                                            onChange={(e)=>this.handleDate('ldl',e)}
                                            dateFormat="dd/MM/yyyy"
                                            id="clinicalcalendar" 
                                            selected={
                                                    this.state.dateShow
                                                        ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                        : Date.parse(this.state.ldl_date)
                                                    }
                                                    disabled={this.state.ldl}
                                            />  
                                        </div>
                                    </div>
                                    
                                <div className="row">
                                        <div className="col-sm-2 pl-0 pr-0"><label>Creatinine :</label></div>      
                                        <div className="col-sm-1"><input type="text" class="form-control form-control-sm mt-0" value={this.state.creati_val} onChange={(e)=>this.setState({creati_val:e.target.value})} style={{width: '60px', float:'left'}}/></div>    
                                        <div className="col-sm-1"> <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('creati',e.target.checked)} 
                                                style={{marginTop:'25px'}} /></div>

                                        <div className="col-sm-2">
                                            <DatePicker
                                                className="datePicker"
                                                onChange={(e)=>this.handleDate('creati',e)}
                                                dateFormat="dd/MM/yyyy"
                                                id="clinicalcalendar" 
                                                selected={
                                                        this.state.dateShow
                                                            ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                            : Date.parse(this.state.creati_date)
                                                        }
                                                        disabled={this.state.creati}
                                                />  
                                        </div>
                               
                                        <div className="col-sm-2 pl-0 pr-0"><label>eGFR :</label></div>      
                                        <div className="col-sm-1"><input type="text" class="form-control form-control-sm mt-0" value={this.state.egfr_val} onChange={(e)=>this.setState({egfr_val:e.target.value})}  style={{width: '60px', float:'left'}}/></div>    
                                        <div className="col-sm-1"> <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('egfr',e.target.checked)} 
                                                style={{marginTop:'25px'}} /></div>

                                        <div className="col-sm-2">
                                            <DatePicker
                                            className="datePicker"
                                            onChange={(e)=>this.handleDate('egfr',e)}
                                            dateFormat="dd/MM/yyyy"
                                            id="clinicalcalendar" 
                                            selected={
                                                    this.state.dateShow
                                                        ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                        : Date.parse(this.state.egfr_date)
                                                    }
                                                    disabled={this.state.egfr}
                                            />  
                                        </div>
                                    </div>

                                    
                                <div className="row">
                                        <div className="col-sm-2 pl-0 pr-0"><label>Albumin/Creatinine ration :</label></div>      
                                        <div className="col-sm-1"><input type="text" class="form-control form-control-sm mt-0" value={this.state.albumin_val} onChange={(e)=>this.setState({albumin_val:e.target.value})} style={{width: '60px', float:'left'}}/></div>    
                                        <div className="col-sm-1"> <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('albumin',e.target.checked)} 
                                                style={{marginTop:'25px'}} /></div>

                                        <div className="col-sm-2">
                                            <DatePicker
                                                className="datePicker"
                                                onChange={(e)=>this.handleDate('albumi',e)}
                                                dateFormat="dd/MM/yyyy"
                                                id="clinicalcalendar" 
                                                selected={
                                                        this.state.dateShow
                                                            ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                            : Date.parse(this.state.albumin_date)
                                                        }
                                                        disabled={this.state.albumin}
                                                />  
                                        </div>
                                     </div>
                                    
                                    <div className="row">
                                        <div className="col-sm-2 pl-0 pr-0"><label>Micro-albuminuria :</label></div>      
                                        <div className="col-sm-1"><input type="text" class="form-control form-control-sm mt-0" value={this.state.micro_val} onChange={(e)=>this.setState({micro_val:e.target.value})} style={{width: '60px', float:'left'}}/></div>    
                                        <div className="col-sm-1"> <input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('micro',e.target.checked)} 
                                                style={{marginTop:'25px'}} /></div>

                                        <div className="col-sm-2">
                                            <DatePicker
                                                className="datePicker"
                                                onChange={(e)=>this.handleDate('micro',e)}
                                                dateFormat="dd/MM/yyyy"
                                                id="clinicalcalendar" 
                                                selected={
                                                        this.state.dateShow
                                                            ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                            : Date.parse(this.state.micro_date)
                                                        }
                                                        disabled={this.state.micro}
                                                />  
                                        </div>
                               
                                        <div className="col-sm-2">
                                            <select value={this.state.micro_select} onChange={(e)=>this.setState({micro_select:e.target.value})}>
                                            <option value="mg/mmol">mg/mmol</option>
                                            <option value="g/day">g/day</option>
                                            <option value="mcg/min">mcg/min</option>
                                            <option value="mg/L">mg/L</option>
                                            
                                            </select>
                                        </div>      
                                        
                                    </div>
                                </div>
                            </div>
                        <div className="row">
                        
                            <div className="col-sm-2">
                                <label className="col-form-label text-left" style={{fontWeight:'bold', fontSize:'16px'}}>Last visit to:</label>
                            </div>
                        
                            <div className="col-sm-10">
                                      <div className="row">
                                            <div className="col-sm-2"><label>Endocrinologist:</label></div>   
                                            <div className="col-sm-1"><input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('endo',e.target.checked)} 
                                                    style={{marginTop:'25px'}} /></div>
                                            <div className="col-sm-2">
                                                <DatePicker
                                                className="datePicker"
                                                onChange={(e)=>this.handleDate('endo',e)}
                                                dateFormat="dd/MM/yyyy"
                                                id="clinicalcalendar" 
                                                selected={
                                                        this.state.dateShow
                                                            ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                            : Date.parse(this.state.endo_date)
                                                        }
                                                        disabled={this.state.endo}
                                                />  
                                            </div>
                                            <div className="col-sm-2">
                                            <input type="text" class="form-control " value={this.state.endo_doc} style={{width: '140px', marginTop: '4px', float:'left'}}/>
                                            </div>
                                            <div  className="col-sm-2">
                                                <button type="button"  className="btn  btn-primary btn-xs"  onClick={()=>this.setState({clicked:'endo',searchDoc:true})}>...</button>
                                            </div>
                                        </div>
                                        <div  className="row">
                                            <div className="col-sm-2"><label>Dietitian:</label></div>   
                                            <div className="col-sm-1"><input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('dieti',e.target.checked)} 
                                            style={{marginTop:'25px'}} /></div>
                                            <div className="col-sm-2">
                                                <DatePicker
                                                    className="datePicker"
                                                    onChange={(e)=>this.handleDate('dieti',e)}
                                                    dateFormat="dd/MM/yyyy"
                                                    id="clinicalcalendar" 
                                                    selected={
                                                            this.state.dateShow
                                                                ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                                : Date.parse(this.state.dieti_date)
                                                            }
                                                            disabled={this.state.dieti}
                                                    />  
                                            </div>
                                            <div className="col-sm-2">
                                            <input type="text" class="form-control " value={this.state.dieti_doc} style={{width: '140px', marginTop: '4px', float:'left'}}/>
                                            </div>
                                            <div  className="col-sm-2">
                                                <button type="button"  className="btn  btn-primary btn-xs" onClick={()=>this.setState({clicked:'dieti',searchDoc:true})}>...</button>
                                            </div>
                                    
                                        </div>
                                    <div className="row">
                                        <div className="col-sm-2"><label>Ophthalmologist:</label></div>   
                                        <div className="col-sm-1"><input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('ophtha',e.target.checked)} 
                                        style={{marginTop:'25px'}}  /></div>
                        
                                        <div className="col-sm-2">
                                        <DatePicker
                                            className="datePicker"
                                            onChange={(e)=>this.handleDate('optha',e)}
                                            dateFormat="dd/MM/yyyy"
                                            id="clinicalcalendar" 
                                            selected={
                                                    this.state.dateShow
                                                        ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                        : Date.parse(this.state.ophtha_date)
                                                    }
                                                    disabled={this.state.ophtha}
                                            />  
                                        </div>
                                        <div className="col-sm-2">
                                        <input type="text" class="form-control " value={this.state.ophtha_doc} style={{width: '140px', marginTop: '4px', float:'left'}}/>
                                        </div>
                                        <div  className="col-sm-2">
                                            <button type="button"  className="btn  btn-primary btn-xs"  onClick={()=>this.setState({clicked:'optha',searchDoc:true})}>...</button>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-2"><label>Pediatrist:</label></div>   
                                        <div className="col-sm-1"><input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('pedia',e.target.checked)} 
                                        style={{marginTop:'25px'}} /></div>
                        
                                        <div className="col-sm-2">
                                        <DatePicker
                                            className="datePicker"
                                            onChange={(e)=>this.handleDate('pedia',e)}
                                            dateFormat="dd/MM/yyyy"
                                            id="clinicalcalendar" 
                                            selected={
                                                    this.state.dateShow
                                                        ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                        : Date.parse(this.state.pedia_date)
                                                    }
                                                    disabled={this.state.pedia}
                                            />  
                                        </div>
                                        <div className="col-sm-2">
                                        <input type="text" class="form-control " value={this.state.pedia_doc} style={{width: '140px', marginTop: '4px', float:'left'}}/>
                                        </div>
                                        <div  className="col-sm-2">
                                            <button type="button"  className="btn  btn-primary btn-xs"  onClick={()=>this.setState({clicked:'pedi',searchDoc:true})}>...</button>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-2"><label>Diabetes Educator:</label></div>   
                                        <div className="col-sm-1"><input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('diabe',e.target.checked)} 
                                        style={{marginTop:'25px'}} /></div>
                        
                                        <div className="col-sm-2">
                                        <DatePicker
                                            className="datePicker"
                                            onChange={(e)=>this.handleDate('diabe',e)}
                                            dateFormat="dd/MM/yyyy"
                                            id="clinicalcalendar" 
                                            selected={
                                                    this.state.dateShow
                                                        ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                        : Date.parse(this.state.diabe_date)
                                                    }
                                                    disabled={this.state.diabe}
                                            />  
                                        </div>
                                        <div className="col-sm-2">
                                        <input type="text" class="form-control " value={this.state.diabe_doc} style={{width: '140px', marginTop: '4px', float:'left'}}/>
                                        </div>
                                        <div  className="col-sm-2">
                                            <button type="button"  className="btn btn-primary btn-xs"  onClick={()=>this.setState({clicked:'diabe',searchDoc:true})}>...</button>
                                        </div>
                                    </div>


                             </div>



                        </div>
                    </div>
                    {(this.state.searchDoc) ?
                    <SearchDoctors searchDoc={this.state.searchDoc} changeKey={this.addSelectedDoc}/> : ''}
                </div>
                <div className="modal-footer">
                    <button type="button"   className="btn btn-primary btn-xs" data-dismiss="modal" 
                    onClick={()=>this.saveData()}>
                            Save
                    </button>
                    <button type="button"  className="btn btn-primary btn-xs" data-dismiss="modal"  onClick={()=>this.props.closeAll()}>
                        Cancel
                    </button>
                </div>   
            </div>
            </div>
        )}
    </Popup>
   )}
}   

const mapDispatchToProps = (dispatch) => {
	return {
        saveDiabetic: data => dispatch(api_add_diabetic(data)),
  }
}
const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
  
  return {
    redux_patientId,redux_docId
  };
  };
export default connect (mapStateToProps, mapDispatchToProps) (AnotherValues);

