import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager} from 'react-notifications';
import {add_keyword,update_Keyword,get_Keyword} from '../../../DS/DS.AppointmentReminder';
class AddConfirmKeyword extends Component {
    constructor(props) {
      super(props);
      this.state={
        open:false,
        keyval:'',
        errors:[]
      }
    }
    componentWillMount=()=>
    {
        this.setState({open:this.props.showModal})
        if(this.props.sid!='')
        {
            const ids={sid:this.props.sid}
            this.props.getKeyword(ids).then(

                req=>{this.setState({keyval:req.data.content[0].keyword_name})}
            )

        }
    }
    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
      
           //file
           if(!this.state.keyval){
              formIsValid = false;
              errors["keyval"] = "Cannot be empty";
           }
           this.setState({errors: errors});
            return formIsValid;
     }  
    saveClickHandler=()=>
    {
        if(this.handleValidation())
        {
            if(this.props.sid=='')
            {
                const keydata={
                    keyval:this.state.keyval,
                    keytype:1
                }
                
                this.props.addkeyword(keydata).then(
                    req=>
                    {
                        if(req.data.status=='Success')
                        {
                            NotificationManager.success("New Keyword added successfully")
                        }
                        else if(req.data.status=='Error')
                        {
                            NotificationManager.error("Error encountered whlie adding the keyword")

                        }
                        else if(req.data.status=='Duplicate')
                        {
                            NotificationManager.error("Duplicate Keyword cannot be added")

                        }
                        this.props.changeKey();
    
                    }
                )
            }
            else if(this.props.sid!='')
            {
                const keydata={
                    keyval:this.state.keyval,
                    keytype:1,
                    sid:this.props.sid
                } 
                
                this.props.updateKeyword(keydata).then(
                    req=>
                    {
                        if(req.data.status=='Success')
                        {
                            NotificationManager.success("Keyword modified successfully")

                        }
                        else if(req.data.status=='Error')
                        {
                            NotificationManager.error("Error encountered whlie modifying the keyword")

                        }
                        else if(req.data.status=='Duplicate')
                        {
                            NotificationManager.error("Duplicate Keyword cannot be added")

                        }
                        this.props.changeKey();
    
                    }
                )
            }
           
        }
    }
   
    render() {
        return (
          <Popup
           open={this.state.open}
           closeOnDocumentClick={false}
            position="right center"   modal
          >
            {close => (
                <div>
                    <div className="modal-header">
                        <span className="modal-title-2" id="exampleModalLabel"> Add Keyword  </span>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        
                    <div className="row row-distance">
                    <div className="col-sm-2"> 
                                <label style={{margin:'10px 0 0 5px', fontSize:'14px'}}> Keyword : </label>
                            </div>
                        <div className="col-sm-10">
                            <div className="form-group">                                                   
                                <input type="text" name="text" className="form-control" value={this.state.keyval}
                                onChange={(e)=>this.setState({keyval:e.target.value})}/>
                                <span className="error" style={{color:'red'}}>{this.state.errors["keyval"]}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-sm-6"> </div>
                            <div className="col-sm-6">
                                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>this.saveClickHandler()}> ok </button>
                                <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}>Cancel</button> 
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Popup>
        )}
    }
    const mapDispatchToProps = dispatch => {
        return {
            addkeyword: data =>dispatch(add_keyword(data)),
            updateKeyword:data =>dispatch(update_Keyword(data)),
            getKeyword:data=>dispatch(get_Keyword(data))
            
         };
      };
      const mapStateToProps = state => {
      
        let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
        
        return {
            redux_userId
        };
      };
      export default connect(mapStateToProps,mapDispatchToProps)(AddConfirmKeyword);
        