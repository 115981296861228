import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../../showHelp';


class recordnote extends Component {
    state = {  }
    render() {
        return (
            <Popup  trigger={<button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs">Record Note </button>} position="right center"   modal
            closeOnDocumentClick={false}
            >
             {close => (
                    <div style={{ width:'100%', backgroundColor:'#FFF', float:'left'}}>

                    <div className="modal-header" style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}>

                    <button type="button" className="close" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                    ×
                    </button>
                    <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>EPC notes-Annie Jones</h4>
                    </div>

					
					<div className="modal-body" style={{height: 'auto!important'}}>
  <div className="row">
    <div className="col-sm-12">
      <h5 style={{backgroundColor: '#f4f4f4', float: 'left', width: '100%', padding: 10, fontSize: 16, marginBottom: 0}}>Patient contact details</h5>
    </div>
  </div>
  <div className="col-sm-12">
    <div className="row pl-2">
      <div className="col-sm-12 pr-0">
        <div className="row">
          <div className="col-sm-1 mt-3 pr-0">
            <label> Patient Name </label>
          </div>
          <div className="col-sm-3 pr-0">
            <input type="text" className="form-control" defaultValue="R. Murugan " />
          </div>
          <div className="col-sm-1 mt-3 pr-0">
            <label> Age </label>
          </div>
          <div className="col-sm-2 pr-0">
            <input type="text" className="form-control" defaultValue="37yrs" />
          </div>
          <div className="col-sm-2 mt-3 pr-0">
            <label> Preferred contact via</label>
          </div>
          <div className="col-sm-2 pr-0">
            <input type="text" className="form-control" defaultValue />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-1 mt-3 pr-0">
            <label> Home phone </label>
          </div>
          <div className="col-sm-3 pr-0">
            <input type="text" className="form-control" defaultValue />
          </div>
          <div className="col-sm-1 mt-3 pr-0">
            <label> Work phone </label>
          </div>
          <div className="col-sm-2 pr-0">
            <input type="text" className="form-control" defaultValue />
          </div>
          <div className="col-sm-2 mt-3 pr-0">
            <label> Mobile phone </label>
          </div>
          <div className="col-sm-2 pr-0">
            <input type="text" className="form-control" defaultValue />
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <h5 style={{backgroundColor: '#f4f4f4', float: 'left', width: '100%', padding: 10, fontSize: 16, marginBottom: 0}}>Other contact's details</h5>
    </div>
    <div className="row">
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-1 pr-0">
            <label> Contact type: </label>
          </div>
          <div className="col-sm-3 pr-0">
            <select name="select" className="form-control">
              <option value="opt1"></option>
              <option value="opt1"> Next of kin </option>
              <option value="opt1"> Carel </option>
              <option value="opt1"> Emergency Contact </option>
            </select>
          </div>
          <div className="col-sm-7 col-sm-07 pr-0">
            <input type="text" className="form-control" defaultValue />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-1 mt-3 pr-0">
            <label> Home phone </label>
          </div>
          <div className="col-sm-3 pr-0">
            <input type="text" className="form-control" defaultValue="R. Murugan " />
          </div>
          <div className="col-sm-1 mt-3 pr-0">
            <label> Work phone </label>
          </div>
          <div className="col-sm-2 pr-0">
            <input type="text" className="form-control" defaultValue />
          </div>
          <div className="col-sm-1 mt-3 pr-0">
            <label> Mobile phone </label>
          </div>
          <div className="col-sm-3">
            <input type="text" className="form-control" defaultValue />
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <h5 style={{backgroundColor: '#f4f4f4', float: 'left', width: '100%', padding: 10, fontSize: 16, marginBottom: 0}}>Communication details</h5>
    </div>
    <div className="row">
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-2 mt-3 pr-0">
            <label> Contact reason: </label>
          </div>
          <div className="col-sm-2 pr-0">
            <select name="select" className="form-control">
              <option value="opt1"></option>
              <option value="opt1"> Next of kin </option>
              <option value="opt1"> Carel </option>
              <option value="opt1"> Emergency Contact </option>
            </select>
          </div>
          <div className="col-sm-2 mt-3 pr-0">
            <label> Contact with</label>
          </div>
          <div className="col-sm-2 pr-0">
            <select name="select" className="form-control">
              <option value="opt1"> Patient </option>
              <option value="opt1"> Not specified </option>
              <option value="opt1"> Head of family </option>
              <option value="opt1"> Next of kin </option>
              <option value="opt1"> Emergency Contact </option>
            </select>	
          </div>
          <div className="col-sm-1 mt-3 col-sm-01 pr-0">
            <label> Contact method </label>
          </div>
          <div className="col-sm-2 pr-0">
            <select name="select" className="form-control">
              <option value="opt1"> Email </option>
              <option value="opt1"> Face-to-face </option>
              <option value="opt1"> Letter </option>
              <option value="opt1"> Phone </option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2 mt-3 pr-0">
            <label> Contact date </label>
          </div>
          <div className="col-sm-2 pr-0">
            <input type="date" className="form-control" defaultValue="16-12-2019" style={{padding: '0px 5px!important'}} />
          </div>
          <div className="col-sm-2 mt-3 pr-0">
            <label> Contact attempt no </label>
          </div>
          <div className="col-sm-2 pr-0">
            <input type="text" className="form-control" defaultValue />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mt-3 pr-0">
            <label> User comments </label>
          </div>
          <div className="col-sm-12">
            <textarea style={{padding: 10, border: '1px solid #ddd', width: '95%'}} defaultValue={""} />
          </div>
        </div>
        <div className="row" style={{marginRight: '-50px'}}>
          <div className="col-sm-9 mt-3 pr-0" style={{textAlign: 'right'}}>
            <label> Message status </label>
          </div>
          <div className="col-sm-2">
            <select name="select" className="form-control">
              <option value="opt1"></option>
              <option value="opt1"> Sent </option>
              <option value="opt1"> Sending Failed </option>
              <option value="opt1"> Delivery Failed </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div className="modal-footer"  style={{marginTop:'10px'}}>
<div className="col-sm-2">
<button type="button" className="btn btn-primary btn-xs">View document</button>
</div>
<div className="col-sm-7"></div>
<div className="col-sm-3">
<button type="button" className="btn btn-primary btn-xs">Save </button>
  <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal" aria-label="Close">Cancel</button>
  </div>
</div>





</div>



)}
</Popup>
         );
    }
}

export default  recordnote ;
