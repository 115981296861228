import axios from 'axios';

//2
export const PATIENT_CONDITIONS = 'PATIENT_CONDITIONS';

//3
export const getPatientConditions = getPatientConditionsData => ({
  type: PATIENT_CONDITIONS,
  getPatientConditionsData,
});

export const patientConditions = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=list_conditions`,
      data
    )
    .then(res => res.data.content)
    .then(data => dispatch(getPatientConditions(data)));
};

export const patientConditions2 = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=list_conditions2`,
      data
    )
    .then(res => res.data.content)
    .then(data => dispatch(getPatientConditions(data)));
};


export const DR_FAV_DIAG = 'DR_FAV_DIAG';
export const DR_FAV_DIAG_CH = 'DR_FAV_DIAG_CH';

export const getDrFav = dr_fav_data => ({
  type: DR_FAV_DIAG,
  dr_fav_data,
});

export const getDrFav2 = dr_fav_data2 => ({
  type: DR_FAV_DIAG_CH,
  dr_fav_data2,
});

export const drConditions = data => dispatch => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=list_drfav_conditions`,
      data
    )
    .then(res => res.data.content)
    .then(data => dispatch(getDrFav(data)));
};
export const drConditions2 = data => dispatch => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=list_drfav_conditions2`,
      data
    )
    .then(res => res.data.content)
    .then(data => dispatch(getDrFav2(data)));
};

export const deletePatientId = data => dispatch => {
  console.log(data);
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=delete_master_conditions`,
      data
    )
    .then(res => {
      //  dispatch(api_searchConditions());
    });
};
export const deletePatientFavId = data => dispatch => {
  console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=past_history&action=delete_patientFav_conditions`,
    data
  );
};
export const deleteDoctorFavId = data => dispatch => {
  console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=past_history&action=delete_drFav_conditions`,
    data
  );
};
export const diagonsisSave = data => dispatch => {
  console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=past_history&action=add_diagonsis_data`,
    data
  );
};
export const api_updateitemprice = data => dispatch => {
  console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=past_history&action=api_updateitemprice`,
    data
  );
};
export const api_savepaypopup = data => dispatch => {
  console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=past_history&action=api_savepaypopup`,
    data
  );
};
export const update_general_comments = data => dispatch => {
  console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=past_history&action=update_general_comments`,
    data
  );
};



export const api_getSummaryData = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=summary`,
      data
    );
    //.then(res => res.data.content)
    //.then(data => dispatch(getPatientConditions(data)));
};

export const api_getSummaryInvestigations = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=summary_investigations`,
      data
    );
    //.then(res => res.data.content)
    //.then(data => dispatch(getPatientConditions(data)));
};
export const api_getSummaryObservations = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=summary_observations`,
      data
    );
    //.then(res => res.data.content)
    //.then(data => dispatch(getPatientConditions(data)));
};

export const api_getSummaryCareplan = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=summary_careplan`,
      data
    );
    //.then(res => res.data.content)
    //.then(data => dispatch(getPatientConditions(data)));
};

export const api_summary_billing = data => dispatch => {
  //first
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=api_summary_billing`,
      data
    );
    //.then(res => res.data.content)
    //.then(data => dispatch(getPatientConditions(data)));
};